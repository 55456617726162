import { UPLOAD_FILE, GET_EMOJI_IMAGE } from 'constants';
import { call, takeLatest } from 'redux-saga/effects';
import { makeAttach } from './attachUtils';

/**
 * upload file
 * @param payload
 * @returns {IterableIterator<*>}
 */
function* upload({ payload }) {
  const { files, withFileFilter, callback } = payload || {};
  // Create attachments
  const attachments = yield call(makeAttach, files, withFileFilter);
  const uploadedFiles = attachments.filter((f) => !!f.fileName);
  if (callback) callback(uploadedFiles);
}

/**
 * Fetch emoji image
 * @param emoji
 * @returns {Promise}
 */
function createEmojiImage(emoji) {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const fontSize = 64;
    const canvasSize = fontSize * 1.5;
    const verticalAlignmentOffset = canvasSize * 0.1;

    canvas.width = canvasSize;
    canvas.height = canvasSize;

    context.font = `${fontSize}px serif`;
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText(
      emoji.native,
      canvas.width / 2,
      canvas.height / 2 + verticalAlignmentOffset
    );

    canvas.toBlob((blob) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        const file = new File([blob], `${emoji.id}.png`, { type: 'image/png' });
        resolve({
          fileSource: file,
          id: emoji.id,
          label: `${emoji.id}.png`,
          size: blob.size,
          type: 'image/png',
          value: base64data,
        });
      };
      reader.readAsDataURL(blob);
    });
  });
}

/**
 * Get emoji image
 * @param payload
 * @returns {IterableIterator<*>}
 */
function* getEmojiImage({ payload }) {
  const { emoji, callback, errorCallback } = payload || {};
  try {
    const emojiObject = yield call(createEmojiImage, emoji);
    if (callback) callback(emojiObject);
  } catch (error) {
    if (errorCallback) errorCallback(error);
  }
}

function* uploadFile() {
  yield takeLatest(UPLOAD_FILE.REQUEST, upload);
  yield takeLatest(GET_EMOJI_IMAGE.REQUEST, getEmojiImage);
}

export default uploadFile;
