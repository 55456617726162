import { useEffect, useState, useRef } from 'react';

/**
 * Generates a function comment for the given function body.
 *
 * @param {RefObject} ref - A reference to the DOM element being observed.
 * @param {object} options - An object containing optional configuration options.
 * @param {function} options.onShowUp - A callback function to be executed when the element comes into view.
 * @param {function} options.onVanish - A callback function to be executed when the element goes out of view.
 * @param {RefObject} options.rootRef - A reference to the root element for the intersection observer.
 * @param {IntersectionObserverInit} options.settings - Additional settings for the intersection observer.
 * @return {boolean} The current visibility state of the element.
 */
export function useOnScreen(ref, { onShowUp, onVanish, rootRef, ...settings }) {
  const [isOnScreen, setIsOnScreen] = useState(false);
  const observerRef = useRef(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => setIsOnScreen(entry.isIntersecting),
      {
        root: rootRef?.current || null,
        ...settings,
      }
    );
    if (ref.current) observerRef.current.observe(ref.current);

    return () => {
      if (ref.current) observerRef.current.disconnect();
    };
  }, [ref.current]);

  useEffect(() => {
    if (isOnScreen && onShowUp) {
      onShowUp(ref.current);
    }
    if (!isOnScreen && onVanish) {
      onVanish(ref.current);
    }
  }, [isOnScreen]);

  return isOnScreen;
}

export default useOnScreen;
