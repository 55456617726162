import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  fActorTrigger: {
    id: 'fActorTrigger',
    defaultMessage: 'Actor Trigger:',
  },
  fActors: {
    id: 'fActors',
    defaultMessage: 'Actors:',
  },
});

export default { ...globalIntl, ...m };
