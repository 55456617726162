import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';
import { Checkbox as CheckMW } from 'mw-style-react';
import './Check.scss';

/**
 * Компонент Check
 */
class Check extends PureComponent {
  render() {
    const { id, title, value, visibility, error, onChange, getErrorMsg } =
      this.props;
    return (
      <CheckMW
        id={id}
        styleName={cn('check')}
        visibility={visibility}
        value={value}
        error={error}
        helperText={getErrorMsg(this.props)}
        onChange={onChange}
      >
        {title}
      </CheckMW>
    );
  }
}

Check.defaultProps = {
  value: false,
};

Check.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  error: PropTypes.bool,
  visibility: PropTypes.string,
  onChange: PropTypes.func,
  getErrorMsg: PropTypes.func,
};

export default Check;
