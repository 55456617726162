import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Modal,
  ModalButtons,
  ModalContent,
  Button,
  ProgressBar,
  Label,
} from 'mw-style-react';
import { CLOSE_LAYER } from '@control-front-end/common/constants/graphLayers';
import { SUBSCRIBE_GRAPH } from '@control-front-end/common/constants/graphRealtime';
import useIntl from 'useIntl';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import sModal from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import sLocal from './LayerDeleted.scss'; // NOSONAR

function LayerDeleted(props) {
  const {
    data: { layerId, layerName },
    visibility,
    onClose,
    isSubmit,
  } = props;
  const t = useIntl();
  const dispatch = useDispatch();

  /**
   * Создание связи для слоя
   */
  const handleSubmit = () => {
    dispatch({ type: CLOSE_LAYER.REQUEST, payload: { layerId } });
    dispatch({
      type: SUBSCRIBE_GRAPH.REQUEST,
      payload: { id: layerId, obj: 'layer', status: 'off' },
    });
    onClose();
  };

  return (
    <Modal
      styleName="sModal.modal"
      visibility={visibility}
      onClose={onClose}
      label={t(mes.layerDeleted)}
    >
      <ModalContent styleName="sModal.modal__content sLocal.content">
        <Label value={t(mes.layerDeletedInfo, { name: layerName })} />
      </ModalContent>
      <ModalButtons styleName="sModal.modal__buttons">
        <Button
          label={t(mes.ok)}
          size="large"
          onClick={handleSubmit}
          visibility={isSubmit ? 'disabled' : 'visible'}
        />
        <ProgressBar
          styleName="sModal.modal__loader"
          type="circle"
          size="small"
          visibility={isSubmit ? 'visible' : 'hidden'}
        />
      </ModalButtons>
    </Modal>
  );
}

LayerDeleted.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  dispatch: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  isSubmit: PropTypes.bool,
  data: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
};

export default LayerDeleted;
