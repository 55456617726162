import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  availableCurrencies: {
    id: 'availableCurrencies',
    defaultMessage: 'Available currencies:',
  },
  freqUsedNames: {
    id: 'freqUsedNames',
    defaultMessage: 'Frequently used names:',
  },
  fActors: {
    id: 'fActors',
    defaultMessage: 'Actors:',
  },
  transactionSearch: {
    id: 'transactionSearch',
    defaultMessage: 'Search transactions',
  },
  transferSearch: {
    id: 'transferSearch',
    defaultMessage: 'Search transfers',
  },
  selectAccName: {
    id: 'selectAccName',
    defaultMessage: 'Select account name',
  },
  selectCurrency: {
    id: 'selectCurrency',
    defaultMessage: 'Select currency',
  },
  transferId: {
    id: 'transferId',
    defaultMessage: 'Transfer ID',
  },
  csv: {
    id: 'csv',
    defaultMessage: 'csv',
  },
  xls: {
    id: 'xls',
    defaultMessage: 'xls',
  },
  exporting: {
    id: 'exporting',
    defaultMessage: 'Exporting',
  },
  exportHasStarted: {
    id: 'exportHasStarted',
    defaultMessage: `Export has started, please don't close this page and wait for file to download`,
  },
  exportCancelled: {
    id: 'exportCancelled',
    defaultMessage: 'Export has been canceled',
  },
  exportError: {
    id: 'exportError',
    defaultMessage: 'Export error',
  },
});

export default { ...globalIntl, ...m };
