import { defineMessages } from 'react-intl';

const m = defineMessages({
  addNewParent: {
    id: 'addNewParent_valency',
    defaultMessage: 'Add parent',
  },
  addNewChild: {
    id: 'addNewChild_valency',
    defaultMessage: 'Add child',
  },
});

export default m;
