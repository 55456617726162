import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Yup from 'yup';
import useIntl from 'useIntl';
import {
  TextField,
  Radio,
  RadioItem,
  Button,
  Modal,
  ModalButtons,
  ModalContent,
  ProgressBar,
  Divider,
  withForm,
  DateUtils,
  Label,
  Stack,
  Banner,
  Calendar,
  Space,
} from 'mw-style-react';
import {
  CREATE_TRANSACTION,
  ADD_TRANSACTION,
  GET_ACCOUNT,
} from '@control-front-end/common/constants/actorAccounts';
import AppUtils from '@control-front-end/utils/utils';
import { DATE_FORMAT_4, NOTIFY_LEVEL, SHOW_NOTIFY } from 'constants';
import SelectActors from '@control-front-end/common/components/SelectActors';
// eslint-disable-next-line max-len
import SelectActorsAccountPair from '@control-front-end/app/src/components/SelectActorsAccountPair/SelectActorsAccountPair'; // NOSONAR
import Toggle from '../../../../../../common/components/Toggle';
import ExtraParams from './components/ExtraParams';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import m from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import l from './CreateTransaction.scss'; // NOSONAR

function CreateTransactionModal(props) {
  const {
    data,
    values,
    handleSubmit,
    handleOnChange,
    visibility,
    onClose,
    isSubmit,
    errors,
  } = props;
  const t = useIntl();
  const dispatch = useDispatch();
  const MAX_AMOUNT_LENGTH = 17;

  const setSymbolLimit = (e) => {
    if (e.target.type === 'number') {
      e.target.value = e.target.value.slice(0, 17);
    } else {
      e.target.value = e.target.value.slice(0, 160);
    }
  };

  /**
   * Get acc+cur pair privs to check access rights
   */
  const extendAccountWithPrivs = (id) => {
    const { account, incomeType } = values;
    dispatch({
      type: GET_ACCOUNT.REQUEST,
      payload: { id, withPrivs: true },
      callback: ({ privs }) => {
        handleOnChange({
          id: 'account',
          value: {
            ...account,
            [incomeType]: {
              ...account[incomeType],
              privs,
            },
          },
          error: false,
        });
      },
    });
  };

  useEffect(() => {
    const { account, incomeType } = values;
    if (!account) return;
    const acc = account[incomeType];
    if (!acc.privs) extendAccountWithPrivs(acc.id);
  }, [values.account, values.incomeType]);

  /**
   * Check right to edit acc+cur pair
   */
  const hasEditAccess = useMemo(() => {
    const { account, incomeType } = values;
    if (!account) return true;
    const { privs } = account[incomeType];
    return !privs || (privs && privs.modify);
  }, [values.account, values.incomeType]);

  const scrollToElement = (elementId) => {
    const elementToShow = document.getElementById(elementId);
    if (!elementToShow) return;
    elementToShow.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };

  useEffect(() => {
    if (errors['range.startDate']) {
      scrollToElement('date');
      return;
    }
    if (errors.amount) {
      scrollToElement('amount');
    }
  }, [errors.amount, errors['range.startDate']]);

  const handleChangeAmount = ({ id, value, error }) => {
    if (value.length > MAX_AMOUNT_LENGTH) return;
    handleOnChange({ id, value, error });
  };

  const scrollModalToBottom = () => {
    setTimeout(() => {
      const modalContent = document.getElementById('modalContent');
      if (!modalContent || !modalContent.children) return;
      const scrollArea = modalContent.children[0];
      scrollArea.scrollTo({
        top: scrollArea.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    }, 100);
  };

  /**
   * Get formatted account balance
   */
  const getAccBalance = (amountKey = 'amount') => {
    const { account, incomeType } = values;
    const { currencyType, precision, symbol, currencyName } =
      account[incomeType];
    const currencyParams = { type: currencyType, symbol, precision };
    return `${AppUtils.simpleFormattedAmount(
      account[incomeType][amountKey],
      currencyParams
    )} ${currencyName}`;
  };

  const resetLimitError = () => {
    const errMes = errors.amount;
    if (!errMes || errMes.includes('Limit')) return;
    setTimeout(
      () =>
        handleOnChange({ id: 'amount', value: values.amount, error: false }),
      10
    );
  };

  const getAmountErrorText = () => {
    const errMes = errors.amount;
    if (!errMes) return '';
    let errText = t(mes[errMes]);
    const { account, incomeType } = values;
    if (account && account[incomeType] && errMes.includes('Limit') !== -1) {
      const acc = account[incomeType];
      if (acc) {
        errText = t(mes[errMes], {
          minLimit: getAccBalance('minLimit'),
          maxLimit: getAccBalance('maxLimit'),
        });
      }
    }
    return errText;
  };

  const noAccountAccessError = () => {
    return (
      <Banner
        styleName="l.transaction__noAccess"
        size="medium"
        error={true}
        value={t(mes.noPairManageAccess)}
      />
    );
  };

  return (
    <Modal
      styleName="m.modal l.modal"
      size="medium"
      onClose={onClose}
      label={t(mes.makeTransaction)}
      visibility={visibility}
    >
      <ModalContent
        id="modalContent"
        styleName="m.modal__content l.transaction"
      >
        <Space bottom size={Space.SIZE.large}>
          <Stack
            styleName="l.transaction__actor"
            horizontal
            fullWidth
            alignItems="center"
            justifyContent="spaceBetween"
          >
            <SelectActors
              size="large"
              bordered={true}
              multiselect={false}
              label={t(mes.actor)}
              placeholder={t(mes.selectActor)}
              value={values.actor}
              error={!!errors.actor}
              helperText={t(mes[errors.actor])}
              manageLayer={false}
              hideCreateBtn={true}
              fullModel={true}
              isDisabled={!!data.id}
              onChange={({ value }) => {
                handleOnChange({ id: 'actor', value, error: false });
                setTimeout(
                  () => handleOnChange({ id: 'account', value: undefined }),
                  10
                );
              }}
            />
          </Stack>
        </Space>
        <Space bottom size={Space.SIZE.large}>
          <Stack styleName="l.transaction__account">
            <SelectActorsAccountPair
              actorId={values.actor ? values.actor.id : null}
              nameId={(values.account || {}).nameId}
              currencyId={(values.account || {}).currencyId}
              error={errors.account}
              disableSystemAccounts
              helperText={values.actor && errors.account}
              visibility={
                values.actor && !values.actor?.accessDenied
                  ? 'visible'
                  : 'disabled'
              }
              unspaced
              label={t(mes.account)}
              withPrivs={true}
              onChange={(value) => {
                handleOnChange({ id: 'account', value, error: false });
                resetLimitError();
              }}
              preselectedAccount={values.account}
            />
          </Stack>
        </Space>
        {!hasEditAccess ? noAccountAccessError() : null}
        <Divider />
        <div styleName="l.transaction__type">
          <Label fontWeight="semibold" value={t(mes.type)} />
          <Radio
            id="incomeType"
            value={values.incomeType}
            align="horizontal"
            onChange={(val) => {
              handleOnChange(val);
              resetLimitError();
            }}
            visibility={data.id ? 'disabled' : 'visible'}
          >
            <RadioItem label={t(mes.debit)} value="debit" />
            <RadioItem label={t(mes.credit)} value="credit" />
          </Radio>
        </div>
        <div styleName="l.transaction__amount">
          <TextField
            id="amount"
            styleName="m.textfield l.transaction__amount__field"
            size="large"
            type="float"
            label={t(mes.amount)}
            placeholder={t(mes.enterAmount)}
            bordered={true}
            autoFocus={values.actor && values.account}
            value={values.amount || ''}
            error={!!errors.amount}
            helperText={getAmountErrorText()}
            onChange={handleChangeAmount}
          />
          <Label
            styleName="l.transaction__amount__currency"
            value={values.account ? values.account.currencyName : ''}
          />
        </div>
        <TextField
          id="comment"
          styleName="m.textfield l.transaction__comment"
          size="large"
          label={t(mes.comment)}
          bordered={true}
          multiline={true}
          multilineType="text"
          rows={2}
          value={values.comment || ''}
          error={!!errors.comment}
          helperText={t(mes[errors.comment])}
          onChange={handleOnChange}
          onKeyPress={setSymbolLimit}
        />
        <div styleName="l.transaction__date">
          <TextField
            styleName="m.textfield"
            size="large"
            bordered={true}
            label={t(mes.originalDate)}
            value={
              values.originalDate
                ? DateUtils.toDate(values.originalDate, DATE_FORMAT_4)
                : t(mes.sameCreatedAt)
            }
            calendar={() => (
              <Calendar
                id="originalDate"
                size="small"
                time={true}
                value={{ startDate: values.originalDate }}
                onChange={({ id, value }) =>
                  handleOnChange({ id, value: value.startDate })
                }
              />
            )}
          />
          <Button
            type="text"
            size="smallplus"
            fontWeight="normal"
            label={t(mes.resetDate)}
            onClick={() => handleOnChange({ id: 'originalDate', value: null })}
            visibility={values.originalDate ? 'visible' : 'hidden'}
          />
        </div>
        <Toggle
          theme="dark"
          isOpen={false}
          title={t(mes.extraParams)}
          onChange={(value) => {
            if (!value) return;
            scrollModalToBottom();
          }}
        >
          <ExtraParams
            extraError={values.extraError}
            data={values.extra || { '': '' }}
            onChange={handleOnChange}
            scrollModalToBottom={scrollModalToBottom}
          />
        </Toggle>
      </ModalContent>
      <ModalButtons styleName="m.modal__buttons">
        <Button
          label={t(mes.makeTransaction)}
          size="large"
          onClick={handleSubmit}
          visibility={isSubmit || !hasEditAccess ? 'disabled' : 'visible'}
        />
        <ProgressBar
          styleName="m.modal__loader"
          type="circle"
          size="small"
          visibility={isSubmit ? 'visible' : 'hidden'}
        />
      </ModalButtons>
    </Modal>
  );
}

CreateTransactionModal.propTypes = {
  visibility: PropTypes.bool,
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleOnChange: PropTypes.func,
  isSubmit: PropTypes.bool,
  values: PropTypes.object,
  errors: PropTypes.object,
};

const CreateTransaction = withForm(
  {
    mapPropsToValues(props) {
      const formData = props.data || {};
      return {
        actor: formData.actor,
        account: formData.account,
        amount: formData.amount,
        value: formData.value || [],
        incomeType: formData.incomeType || 'debit',
        accountType: formData.accountType || 'fact',
        comment: '',
        range: {},
        extra: formData.extra || {},
        extraError: false,
      };
    },
    validate: ({ account, incomeType, amount }) => {
      if (!account || !account[incomeType]) return {};
      const acc = account[incomeType];
      if (
        AppUtils.isNumeric(acc.maxLimit) &&
        acc.amount + +amount > acc.maxLimit
      )
        return { amount: 'maxLimitError' };
      if (
        AppUtils.isNumeric(acc.minLimit) &&
        acc.amount + +amount < acc.minLimit
      )
        return { amount: 'minLimitError' };
      if (!amount)
        return {
          amount: 'field_required',
        };
      return {};
    },
    yup: Yup.object().shape({
      actor: Yup.object().required('field_required'),
      account: Yup.object().required('field_required'),
      extraError: Yup.boolean().oneOf([false], 'Extra parameters JSON error'),
    }),
    handleSubmit: (values, dispatch, formActions, props) => {
      const { actor, account, accountType, incomeType, amount, comment } =
        values;
      const { debit, credit, nameId, currencyId } = account;
      const id = values.incomeType === 'debit' ? debit.id : credit.id;
      const key = `${actor.id}+${nameId}+${currencyId}`;
      const transaction = {
        comment,
        originalDate: values.originalDate * 1000,
      };
      transaction.amount = +amount;

      if (values.extra) {
        if ('' in values.extra) {
          delete values.extra[''];
        }
        if (Object.keys(values.extra).length) {
          transaction.data = { ...transaction.data, ...values.extra };
        }
      }
      dispatch({
        type: CREATE_TRANSACTION.REQUEST,
        payload: { id, key, accountType, incomeType, transaction },
        callback: (newTransaction) => {
          dispatch({
            type: SHOW_NOTIFY.REQUEST,
            payload: {
              id: AppUtils.createRid(),
              type: NOTIFY_LEVEL.SUCCESS,
              label: 'Transaction has been successfully created.',
            },
          });
          dispatch({
            type: ADD_TRANSACTION.REQUEST,
            payload: {
              incomeType,
              accountType,
              transaction: newTransaction,
              actor,
              account,
            },
            callback: () => props.callback(newTransaction),
          });
        },
      });
      props.onClose();
    },
  },
  CreateTransactionModal
);

export default CreateTransaction;
