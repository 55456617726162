import React from 'react';
import PropTypes from 'prop-types';
import { Label, Tooltip } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { useGetGraphCoord } from 'hooks';
import { makeGetActiveLayer } from '../../../selectors/graphs';
import './AreaTooltip.scss';

/**
 * Зоны на слое
 */
function AreaTooltip(props) {
  const { graph, handleSelectNode } = props;
  const coord = useGetGraphCoord(graph);
  const activeLayer = useSelector(makeGetActiveLayer) || {};

  const renderTooltip = (el) => {
    const { id, position, data, status } = el;
    return (
      <Tooltip key={id} value="Open stream">
        <div
          styleName={cn('at__item', { selected: status === 'updated' })}
          style={{ top: position.y - 50, left: position.x - 50 }}
          onClick={() => {
            const target = graph.$(`#${id}`);
            handleSelectNode({
              e: { target },
              graph,
              extra: { openStream: true },
            });
          }}
        >
          <Label
            size="small"
            fontWeight="semibold"
            value={data.commentsCount + 1}
          />
        </div>
      </Tooltip>
    );
  };

  if (!coord || !activeLayer.nodes) return null;
  return (
    <div
      styleName="at"
      style={{
        transform: `translate(${coord.pan.x}px, ${coord.pan.y}px) scale(${coord.zoom})`,
      }}
    >
      {activeLayer.nodes
        .filter(
          (i) => i.isLayerArea && AppUtils.isNumeric(i.data.commentsCount)
        )
        .map((el) => renderTooltip(el))}
    </div>
  );
}

AreaTooltip.propTypes = {
  graph: PropTypes.object.isRequired,
  handleSelectNode: PropTypes.func,
};

export default AreaTooltip;
