import React from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  MenuItem,
  Label,
  TextField,
  Calendar,
  DateUtils,
  useObjectState,
} from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import { DEFAULT_RANGES } from '@control-front-end/common/constants/graphActors';
import { MAX_FILTER_PERIOD } from '@control-front-end/common/constants/transactionsFilters';
import { DATE_FORMAT_3, TIMEFRAME_NAME } from 'constants';
import { useIntl } from 'hooks';
import mes from './intl';
import '../../CreateTransactionsFilter.scss';

const ORDER_BY = {
  created_at: 'created_at',
  original_date: 'original_date',
};

function DateRange(props) {
  const {
    orderBy = ORDER_BY.created_at,
    from,
    to,
    range,
    errors,
    onChange,
  } = props;
  const t = useIntl();
  const [state, setState] = useObjectState(
    AppUtils.transformRange({ from, to })[AppUtils.TRANSFORM_TYPE.msToSec]
  );

  /**
   * Handle change from or to date for custom range
   */
  const handleChangeDate = ({ id, value }) => {
    onChange({ id: 'range', value: TIMEFRAME_NAME.custom });
    const newValue = value.startDate;
    setState[id](newValue);
    const isFrom = id === 'from';
    const rangeInvalid = isFrom ? state.to < newValue : state.from > newValue;
    const diff = isFrom ? state.to - newValue : newValue - state.to;
    const rangeExceeded = diff * 1000 > MAX_FILTER_PERIOD;
    let error = false;
    if (rangeInvalid) {
      error = 'invalidRange';
    } else if (rangeExceeded) {
      error = 'rangeExceedsYear';
    }
    onChange({
      id,
      value: AppUtils.transform[AppUtils.TRANSFORM_TYPE.secToMs](newValue),
      error,
    });
    const oppositeDateValue = isFrom ? state.to : state.from;
    const oppositeDateId = isFrom ? 'to' : 'from';
    if (rangeInvalid || errors[oppositeDateId] !== 'invalidRange') return;
    onChange({
      id: oppositeDateId,
      value:
        AppUtils.transform[AppUtils.TRANSFORM_TYPE.secToMs](oppositeDateValue),
      error: false,
    });
  };

  const handleChangeRange = (value) => {
    onChange({ id: 'range', value });
    if (value === TIMEFRAME_NAME.custom) return;
    const { from, to } = AppUtils.getRangeDate(value);
    setState.from(from);
    setState.to(to);
  };

  return (
    <div styleName="filter__row">
      <div styleName="filter__item">
        <Select
          id="orderBy"
          styleName="filter__select"
          size="medium"
          bordered={true}
          value={orderBy}
          label={t(mes.orderBy)}
          onChange={onChange}
        >
          {Object.keys(ORDER_BY).map((item) => (
            <MenuItem key={item} value={item} label={t(mes[`order_${item}`])} />
          ))}
        </Select>
      </div>
      <div styleName="filter__item">
        <Select
          id="range"
          styleName="filter__select"
          size="medium"
          bordered={true}
          value={range}
          label={t(mes.creationDate)}
          onChange={({ value }) => handleChangeRange(value)}
          popoverOnTop
        >
          {DEFAULT_RANGES.map((item) => (
            <MenuItem key={item} value={item} label={t(mes[`range_${item}`])} />
          ))}
        </Select>
      </div>
      <div styleName="filter__item">
        <TextField
          styleName="filter__textfield"
          size="medium"
          bordered={true}
          value={state.from ? DateUtils.toDate(state.from, DATE_FORMAT_3) : ''}
          error={!!errors.to || !!errors.from}
          label={t(mes.from)}
          calendar={() => (
            <Calendar
              id="from"
              styleName="filter__calendar"
              size="small"
              time={true}
              defaultStartTime={{ hours: 0, min: 0, sec: 0 }}
              value={{ startDate: state.from }}
              onChange={handleChangeDate}
            />
          )}
        />
      </div>
      <Label styleName="filter__dash" value="—" />
      <div styleName="filter__item">
        <TextField
          styleName="filter__textfield"
          size="medium"
          bordered={true}
          value={state.to ? DateUtils.toDate(state.to, DATE_FORMAT_3) : ''}
          error={!!errors.to || !!errors.from}
          label={t(mes.to)}
          calendar={() => (
            <Calendar
              id="to"
              styleName="filter__calendar dateTo"
              size="small"
              time={true}
              defaultStartTime={{ hours: 23, min: 59, sec: 59 }}
              value={{ startDate: state.to }}
              onChange={handleChangeDate}
            />
          )}
        />
      </div>
      {!!errors.to || !!errors.from ? (
        <Label
          styleName="filter__calendar__error"
          error={true}
          fontSize="small"
          value={t(mes[errors.to || errors.from])}
        />
      ) : null}
    </div>
  );
}

DateRange.propTypes = {
  range: PropTypes.oneOf(DEFAULT_RANGES),
  from: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orderBy: PropTypes.oneOf(Object.keys(ORDER_BY)),
  errors: PropTypes.object,
  onChange: PropTypes.func,
};

export default DateRange;
