import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Radio,
  RadioItem,
  Stack,
  Space,
  TextField,
  ToggleSwitch,
  Button,
  Label,
  useObjectState,
} from 'mw-style-react';
import { useIntl } from 'hooks';
import { GET_SCRIPT } from '@control-front-end/common/constants/scripts';
import SelectUsersAndGroups from '@control-front-end/common/components/SelectUsersAndGroups';
import SelectCDUScript from '@control-front-end/common/components/SelectCDUScript';
import mes from './intl';
import DateTimeSelector from './components/DateTimeSelector';
import scss from './PersonalScript.scss';

const EXPIRATION_TYPE = {
  TIMEOUT: 'TIMEOUT',
  TIMESTAMP: 'TIMESTAMP',
};

function SpacedTitle({ ...props }) {
  return (
    <Space bottom size={Space.SIZE.xsmall}>
      <Label color={Label.COLOR.black} fontWeight="semibold" {...props} />
    </Space>
  );
}

const omitBy = (obj, predicate) =>
  Object.fromEntries(
    Object.entries(obj).filter(([, value]) => !predicate(value))
  );

const DEFAULT_TIMEOUT = 3600;

const getSharedParticipants = (access) =>
  access
    ? access.reduce(
        (acc, partcipant) => {
          acc[partcipant.userType === 'group' ? 'groups' : 'users'].push({
            ...partcipant,
            nick: partcipant.name,
            id: partcipant.userId,
          });
          return acc;
        },
        { users: [], groups: [] }
      )
    : null;

function PersonalScript({
  appId,
  appSettings = {},
  access: accessProp,
  onSubmit,
}) {
  const scriptEditing = Object.keys(appSettings).length;
  const access = useSelector((state) => state.actorView?.access);

  const t = useIntl();
  const dispatch = useDispatch();

  const availableToShare = useMemo(
    () => getSharedParticipants(accessProp || access),
    [access, accessProp]
  );

  const initialState = useMemo(() => {
    return {
      script: null,
      users: appSettings?.users?.length
        ? availableToShare.users.filter(({ id }) =>
            appSettings.users.includes(id)
          )
        : [],
      groups: appSettings?.groups?.length
        ? availableToShare.groups.filter(({ id }) =>
            appSettings.groups.includes(id)
          )
        : [],
      autorun: appSettings.autorun === undefined ? true : appSettings.autorun,
      expirationEnabled: Boolean(appSettings.expired),
      expirationType: EXPIRATION_TYPE.TIMEOUT,
      expirationTimeout: appSettings.expired
        ? Math.round((appSettings.expired * 1000 - Date.now()) / 1000)
        : DEFAULT_TIMEOUT,
      expirationTimestamp: appSettings.expired
        ? appSettings.expired
        : new Date(Date.now() + DEFAULT_TIMEOUT * 1000) / 1000,
      triedToSubmit: false,
    };
  }, []);

  const [state, setState] = useObjectState(initialState, {
    transformValue: ({ value }) => value,
  });

  useEffect(() => {
    if (appId) {
      dispatch({
        type: GET_SCRIPT.REQUEST,
        payload: appId,
        callback: ({ ref, ...script }) => setState.script({ value: script }),
      });
    }
  }, []);

  const handleUsersAndGroupsSelect = useCallback((items) => {
    const newItems = items.reduce(
      (result, item) => {
        const fieldName =
          (item.userType || item.type) === 'group' ? 'groups' : 'users';
        result[fieldName].push(item);
        return result;
      },
      { users: [], groups: [] }
    );
    setState.users({ value: newItems.users });
    setState.groups({ value: newItems.groups });
  });

  return (
    <Stack>
      <div className={scss.scriptSelect}>
        <SelectCDUScript
          error={
            state.triedToSubmit &&
            (!state.script || !Object.keys(state.script).length)
          }
          label={<SpacedTitle value={t(mes.script)} />}
          value={state.script}
          onChange={(...args) => setState.script(...args)}
        />
      </div>
      <Stack size={Stack.SIZE.xsmall}>
        <Label
          color={Label.COLOR.black}
          fontWeight="semibold"
          value={t(mes.users)}
        />
        <SelectUsersAndGroups
          preselectedItems={state.users.concat(state.groups)}
          handleSelect={handleUsersAndGroupsSelect}
          editMode
          users={availableToShare?.users}
          groups={availableToShare?.groups}
        />
      </Stack>
      <Stack size={Stack.SIZE.xsmall}>
        <Label
          color={Label.COLOR.black}
          value={t(mes.settings)}
          fontWeight="semibold"
        />
        <Stack size={Stack.SIZE.small}>
          <ToggleSwitch
            value={state.autorun}
            onChange={setState.autorun}
            label={<Label color={Label.COLOR.black} value={t(mes.autorun)} />}
          />
          <ToggleSwitch
            value={state.expirationEnabled}
            onChange={setState.expirationEnabled}
            label={
              <Label color={Label.COLOR.black} value={t(mes.expirationDate)} />
            }
          />
          {state.expirationEnabled ? (
            <Space
              left
              size={Space.SIZE.xxlarge}
              className={scss.exparationSettings}
            >
              <Radio
                label={<SpacedTitle value={t(mes.expirationDate)} />}
                value={state.expirationType}
                onChange={setState.expirationType}
                className={scss.expirationTypeChoise}
              >
                <RadioItem
                  value={EXPIRATION_TYPE.TIMEOUT}
                  label={t(mes.timeout)}
                />
                <RadioItem
                  value={EXPIRATION_TYPE.TIMESTAMP}
                  label={t(mes.dateTime)}
                />
              </Radio>
              <div className={scss.timeoutField}>
                {state.expirationType === EXPIRATION_TYPE.TIMESTAMP ? (
                  <DateTimeSelector
                    value={state.expirationTimestamp}
                    onChange={setState.expirationTimestamp}
                  />
                ) : (
                  <TextField
                    color={TextField.COLOR.white}
                    unspaced
                    bordered
                    type="int"
                    label={<SpacedTitle value={t(mes.timeoutSec)} />}
                    leftIcon="clock"
                    value={state.expirationTimeout}
                    onChange={setState.expirationTimeout}
                  />
                )}
              </div>
            </Space>
          ) : null}
        </Stack>
      </Stack>
      <Button
        label={t(scriptEditing ? mes.editScript : mes.addScript)}
        size="large"
        fullWidth
        onClick={() => {
          setState.triedToSubmit({ value: true });
          if (!state.script) return;

          let expired = null;
          if (state.expirationEnabled) {
            if (state.expirationType === EXPIRATION_TYPE.TIMESTAMP) {
              expired = Math.floor(state.expirationTimestamp);
            } else {
              expired = Math.floor(
                Date.now() / 1000 + Number(state.expirationTimeout)
              );
            }
          }

          onSubmit({
            app: state.script,
            appId: state.script.id,
            appSettings: omitBy(
              {
                groups: state.groups.map(({ id }) => id),

                users: state.users.map(({ id }) => id),
                autorun: state.autorun,
                expired,
              },
              (value) =>
                value === null || value === undefined || value?.length === 0
            ),
          });
        }}
      />
    </Stack>
  );
}

PersonalScript.propTypes = {
  appId: PropTypes.string,
  appSettings: PropTypes.shape({
    autorun: PropTypes.bool,
    expired: PropTypes.number,
    users: PropTypes.arrayOf(PropTypes.number),
    groups: PropTypes.arrayOf(PropTypes.number),
  }),
  onSubmit: PropTypes.func.isRequired,
};

export default PersonalScript;
