import { call, put, select } from 'redux-saga/effects';
import { DateUtils } from 'mw-style-react';
import { NOTIFY_LEVEL, RequestStatus, SHOW_NOTIFY } from 'constants';
import AppUtils from '@control-front-end/utils/utils';
import api from '@control-front-end/common/sagas/api';

// загрузка файла на сервер
function* uploadFile(file, accId, withFileFilter = false) {
  const path = withFileFilter ? '/upload/file' : '/upload';
  const { result, data } = yield call(api, {
    method: 'post',
    url: `${path}/${accId}`,
    body: { file },
    reqType: 'multipart',
  });
  if (result !== RequestStatus.SUCCESS) return false;
  return data.data;
}

export function* uploadBase64({ payload, callback }) {
  const accounts = yield select((state) => state.accounts);

  const { result, data } = yield call(api, {
    method: 'post',
    url: `/upload/base64/${accounts.active}`,
    body: payload,
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback(data.data);
  return data.data;
}

// управление аттачами
function* attachActions(method, attachments, actorId) {
  if (!attachments.length) return { result: 'success' };
  const accounts = yield select((state) => state.accounts);
  const body = attachments.map((i) => ({ attachId: i.id, actorId }));
  const { result, data } = yield call(api, {
    method,
    url: `/attachments/${accounts.active}`,
    body,
  });
  return { result, data };
}

/**
 * Формируем массив аттачей при отправке на сервер
 * @param files
 * @param withFileFilter
 * @returns {IterableIterator<*>}
 */
export function* makeAttach(files, withFileFilter) {
  if (!files) return [];
  const attachments = [];
  const config = yield select((state) => state.config);
  const accounts = yield select((state) => state.accounts);
  const auth = yield select((state) => state.auth);
  for (const file of files) {
    if (file.fileSource) {
      if (file.size > config.maxFileSize) {
        yield put({
          type: SHOW_NOTIFY.REQUEST,
          payload: {
            id: AppUtils.createRid(),
            type: NOTIFY_LEVEL.ERROR,
            label: `Exceeds the maximum upload size ${Math.round(
              config.maxFileSize / (1024 * 1024)
            )}MB`,
          },
        });
        continue;
      }
      const uploadedFile = yield call(
        uploadFile,
        file.fileSource,
        accounts.active,
        withFileFilter
      );
      if (!uploadedFile) continue;
      const { id, fileName, size } = uploadedFile;
      attachments.push({
        id,
        status: 'new',
        accId: accounts.active,
        fileName,
        type: file.type,
        title: file.label,
        size,
        createdAt: DateUtils.unixtime(),
        userId: auth.id,
        userAvatar: auth.avatar,
        user: {
          nick: auth.nick,
        },
        filePath: yield call(AppUtils.makeAppUrl, `/download/${fileName}`),
      });
    } else {
      attachments.push(file);
    }
  }
  return attachments;
}

/**
 * Подключаем массив аттачей к событию или реакции
 * @param attachments
 * @param actorId
 * @returns {IterableIterator<*>}
 */
export function* bindAttach(attachments, actorId) {
  return yield call(attachActions, 'post', attachments, actorId);
}

/**
 * Отключаем массив аттачей от события или реакции
 * @param attachments
 * @param actorId
 * @returns {IterableIterator<*>}
 */
export function* unBindAttach(attachments, actorId) {
  return yield call(attachActions, 'delete', attachments, actorId);
}
