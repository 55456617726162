import { SET_DEFAULT_ACTOR_TPL } from '@control-front-end/common/constants/graphActors';
import { createReducer } from '@reduxjs/toolkit';

// ------------------------------------
// Reducer
// ------------------------------------

const initState = {};

export default createReducer(initState, {
  [SET_DEFAULT_ACTOR_TPL](state, action) {
    return { ...state, ...action.payload };
  },
});
