import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl';

const m = defineMessages({
  createWorkspace: {
    id: 'createWorkspace',
    defaultMessage: 'Create workspace',
  },
  newWorkspace: {
    id: 'newWorkspace',
    defaultMessage: 'New workspace',
  },
  workspace: {
    id: 'workspace',
    defaultMessage: 'Workspace',
  },
  workspaces: {
    id: 'workspaces_WorkspacesListWidget',
    defaultMessage: 'Workspaces:',
  },
  searchBy: {
    id: 'searchBy_WorkspacesListWidget',
    defaultMessage: 'Search by name or ID',
  },
  searchNoResults: {
    id: 'searchNoResults',
    defaultMessage:
      'There are no workspaces\n' +
      'according to your search request.\n' +
      'Please try another request',
  },
});

export default { ...globalIntl, ...m };
