import PropTypes from 'prop-types';
import React from 'react';
import { Upload as UploadMW } from 'mw-style-react';
import Edit from '../Edit/Edit';
import './Upload.scss';

/**
 * Компонент Upload
 */
function Upload({ id, title, visibility, error, extra, onChange }) {
  return (
    <div styleName="upload">
      <div>
        <Edit id={id} title={title} onChange={onChange} />
      </div>
      <UploadMW
        id={id}
        browseLinkLabel={extra.extraText || 'Click here to attach a file'}
        label=""
        visibility={visibility}
        error={error}
        accept={extra.accept}
        onChange={() => {}}
      />
    </div>
  );
}

Upload.defaultProps = {
  extra: {},
};

Upload.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  visibility: PropTypes.string,
  error: PropTypes.bool,
  extra: PropTypes.object,
  onChange: PropTypes.func,
};

export default Upload;
