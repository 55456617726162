import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  addPair: {
    id: 'addPair',
    defaultMessage: 'Add pair',
  },
});

export default { ...globalIntl, ...m };
