import { createReducer } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';
import { VALENCY_GRAPH } from '@control-front-end/common/constants/graphActors';

// ------------------------------------
// Reducer
// ------------------------------------
const initState = {
  list: [], // List of actors to show on valency graph
  reqStatus: 'success',
};

export default createReducer(initState, {
  [VALENCY_GRAPH.GET.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [VALENCY_GRAPH.REMOVE_NODES.SUCCESS](state, action) {
    const listMap = keyBy(action.payload, 'id');
    return {
      ...state,
      // Remove deleted nodes and edges linked to them
      list: state.list.filter(({ id, data }) => {
        if (
          listMap[id] ||
          listMap[data.actorId] ||
          listMap[data.source] ||
          listMap[data.target]
        ) {
          return false;
        }
        return true;
      }),
    };
  },
});
