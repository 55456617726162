import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import useIntl from 'useIntl';
import { useDispatch } from 'react-redux';
import {
  Modal,
  ModalContent,
  ModalButtons,
  Button,
  Banner,
  Label,
  Icon,
  Utils,
} from 'mw-style-react';
import {
  GET_ACTORS_ACCOUNTS,
  TRANSFER_ACCOUNTS,
} from '@control-front-end/common/constants/actorAccounts';
import SelectActors from '@control-front-end/common/components/SelectActors';
import AppUtils from '@control-front-end/utils/utils';
import { useNotifications } from 'hooks';
import { AccountAmountValue } from 'components';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import m from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import l from './TransferActorAccounts.scss'; // NOSONAR

function TransferActorAccounts({ onClose, data, visibility }) {
  const t = useIntl();
  const dispatch = useDispatch();
  const [targetActor, setTargetActor] = useState();
  const [targetActorAccount, setTargetActorAccount] = useState();
  const { showNotification } = useNotifications();

  const actorAccessError = useMemo(
    () => targetActor?.privs?.view && !targetActor?.privs?.modify,
    [targetActor]
  );

  /**
   * Find already exist account
   */
  const findTargetActorAccount = (accounts) => {
    const find = accounts.find(
      (i) =>
        i.nameId === data.accData.nameId &&
        +i.currencyId === +data.accData.currencyId
    );
    setTargetActorAccount(find);
  };

  /**
   * Get target actor accounts to check account existence
   */
  const handleCheckTargetActor = (targetActorId) => {
    dispatch({
      type: GET_ACTORS_ACCOUNTS.REQUEST,
      payload: {
        actorId: targetActorId,
        accountType: 'fact',
        localState: true,
      },
      callback: findTargetActorAccount,
    });
  };

  /**
   * Move account to target actor
   */
  const handleSubmit = () => {
    dispatch({
      type: TRANSFER_ACCOUNTS.REQUEST,
      payload: {
        key: data.accData.debit.key,
        actorId: data.actorId,
        nameId: data.accData.nameId,
        currencyId: data.accData.currencyId,
        targetActorId: targetActor.id,
        replace: !!targetActorAccount,
      },
      callback: () => {
        showNotification('success', t(mes.transferredSuccessfully));
        onClose();
      },
    });
  };

  const renderAmount = useCallback(
    (acc) => {
      const { currencyType: type, precision, symbol } = acc;
      const currencyParams = { type, precision, symbol };
      return (
        <AccountAmountValue
          amount={acc.amount}
          showCommission={false}
          currencyParams={currencyParams}
        />
      );
    },
    [data.accData]
  );

  const renderTransferWarning = () => {
    if (!targetActorAccount) return null;
    return (
      <div>
        <Banner
          styleName="l.content__banner"
          value={t(
            mes.transferBannerError,
            {
              accountName: `[b]${data.accData.accountName}[/b]`,
              currencyName: `[b]${data.accData.currencyName}[/b]`,
            },
            false
          )}
          error={true}
        />
        <div styleName="l.table">
          <div styleName="l.table__row head">
            <div styleName="l.table__cell">{t(mes.name)}</div>
            <div styleName="l.table__cell">{t(mes.currency)}</div>
            <div styleName="l.table__cell">{t(mes.total)}</div>
            <div styleName="l.table__cell">{t(mes.credit)}</div>
            <div styleName="l.table__cell">{t(mes.debit)}</div>
          </div>
          <div styleName="l.table__row">
            <div styleName="l.table__cell">
              {data.accData.accountName}{' '}
              <span styleName="l.red">{t(mes.old)}</span>
            </div>
            <div styleName="l.table__cell">{data.accData.currencyName}</div>
            <div styleName="l.table__cell">
              <Label
                fontWeight="semibold"
                value={AppUtils.toNumberFormat(
                  targetActorAccount.credit.availableAmount -
                    targetActorAccount.debit.availableAmount
                )}
              />
            </div>
            <div styleName="l.table__cell">
              {renderAmount(targetActorAccount.credit)}
            </div>
            <div styleName="l.table__cell">
              {renderAmount(targetActorAccount.debit)}
            </div>
          </div>
          <div>
            <Icon styleName="l.table__arrow" type="arrow_thin" />
          </div>
          <div styleName="l.table__row">
            <div styleName="l.table__cell">
              {data.accData.accountName}{' '}
              <span styleName="l.green">{t(mes.new)}</span>
            </div>
            <div styleName="l.table__cell">{data.accData.currencyName}</div>
            <div styleName="l.table__cell">
              <Label
                fontWeight="semibold"
                value={AppUtils.toNumberFormat(
                  data.accData.credit.availableAmount -
                    data.accData.debit.availableAmount
                )}
              />
            </div>
            <div styleName="l.table__cell">
              {renderAmount(data.accData.credit)}
            </div>
            <div styleName="l.table__cell">
              {renderAmount(data.accData.debit)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      styleName="m.modal"
      size="large"
      onClose={onClose}
      label={t(mes.transferAccounts)}
      visibility={visibility}
    >
      <ModalContent styleName="m.modal__content l.content">
        <div
          dangerouslySetInnerHTML={{
            __html: t(mes.transferAccountInfo, {
              account: Utils.stripHtml(
                `[b]${data.accData.accountName}(${data.accData.currencyName})[/b]`
              ),
              actorTitle: Utils.stripHtml(`[b]${data.actorTitle}[/b]`),
            }),
          }}
        />
        <div>
          <SelectActors
            bordered={true}
            type="modal"
            size="large"
            multiselect={false}
            placeholder={t(mes.selectActor)}
            value={targetActor}
            error={actorAccessError}
            helperText={actorAccessError ? t(mes.cantTransferAccount) : ''}
            exclude={[{ id: data.actorId }]}
            onChange={({ value }) => {
              setTargetActor(value);
              handleCheckTargetActor(value.id);
            }}
          />
          {renderTransferWarning()}
        </div>
      </ModalContent>
      <ModalButtons styleName="m.modal__buttons">
        <Button
          label={
            targetActorAccount ? t(mes.transferAndReplace) : t(mes.transfer)
          }
          size="large"
          onClick={handleSubmit}
          visibility={targetActor && !actorAccessError ? 'visible' : 'disabled'}
        />
      </ModalButtons>
    </Modal>
  );
}

TransferActorAccounts.propTypes = {
  visibility: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object,
};

export default TransferActorAccounts;
