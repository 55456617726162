import { call, all, put, select, takeEvery } from 'redux-saga/effects';
import { keyBy, pick } from 'lodash';
import { Utils } from 'mw-style-react';

import { MAKE_SOUND } from 'constants';
import {
  SET_ACTIVE_ELEMENT,
  BULK_REMOVE_FROM_GRAPH,
  BULK_ADD_TO_GRAPH,
  COPY_FROM_GRAPH,
  PASTE_TO_GRAPH,
  VALENCY_GRAPH,
} from '@control-front-end/common/constants/graphActors';
import AppUtils from '@control-front-end/utils/utils';
import {
  REMOVE_LAYER,
  TYPE_LAYER,
} from '@control-front-end/common/constants/graphLayers';
import apiBulk from '@control-front-end/common/sagas/apiBulk';
import { bulkRemoveForms } from '@control-front-end/common/sagas/formList';
import {
  getSnapNodePosition,
  findNodesPositionsIntersection,
  toCellCoord,
} from '@control-front-end/utils/modules/utilsCellCoords';
import { getGraphEls, sendReducerMsg, getActiveLayer } from './graphHelpers';
import { removeNodesFromGraph } from './graphUtils';
import { getLayer, resubscribeBalances } from './graphRealtime';
import { removeActorsFromList, reviseDefaultNamings } from './graphNodes';
import { manageLayerElements } from '../../routes/ActorsGraph/sagas/layers/layerElements';
import { removeLayerFromStore } from '../../routes/ActorsGraph/sagas/layers/layerManage';
import {
  removeGroupTplActors,
  createNewEdges,
  getGraphElsAfterPaste,
  showNotifyGraphError,
} from './graphCopyHelpers';

const _COPY_GRAPH_KEY_ = 'actors_graph_clipboard';
const ERROR_LOOP_ON_TREE = 'Unable to paste actors with a loop on a tree layer';
const ERROR_ANOTHER_WORKSPACE =
  'Unable to paste actors from another workspace.';
const ERROR_PASTE_ON_GRAPH = 'Error pasting actors on graph';

/**
 * Скопировать элементы слоя графа
 */
function* copyGraphElements({ payload, callback }) {
  const { hasLoops } = payload;
  const { active: accId } = yield select((state) => state.accounts);
  const { nodes, edges } = yield removeGroupTplActors(payload);
  const copyNodes = JSON.parse(JSON.stringify(nodes));
  copyNodes.forEach((el) => {
    el.id = el.actorId;
    el.prevId = el.laId;
    delete el.laId;
  });
  const copiedEdges = JSON.parse(JSON.stringify(edges));
  // исключаем связи, для которых не скопирован source и(или) target актор
  const copyEdges = copiedEdges.filter((edge) => {
    const sourceNode = copyNodes.find((n) => n.actorId === edge.sourceActorId);
    const targetNode = copyNodes.find((n) => n.actorId === edge.targetActorId);
    return sourceNode && targetNode;
  });
  copyEdges.forEach((el) => {
    el.id = el.edgeId;
    el.source = el.sourceActorId;
    el.target = el.targetActorId;
    el.prevId = el.laId;
    el.prevSource = el.laIdSource;
    el.prevTarget = el.laIdTarget;
    delete el.laId;
    delete el.laIdSource;
    delete el.laIdTarget;
  });
  Utils.toStorage(
    _COPY_GRAPH_KEY_,
    JSON.stringify({
      accId,
      nodes: copyNodes,
      edges: copyEdges,
      hasLoops,
    })
  );
  yield put({ type: COPY_FROM_GRAPH.SUCCESS });
  if (callback) callback();
}

/**
 * Получить скопированые элементы из sessionStorage
 */
function getCopiedGraphElements({ position }) {
  const copiedEls = Utils.fromStorage(_COPY_GRAPH_KEY_);
  if (!copiedEls) return null;
  const { accId, nodes, edges, hasLoops } = JSON.parse(copiedEls);
  if (!nodes || !nodes.length) return null;
  const offsetX = position.x - nodes[0].position.x;
  const offsetY = position.y - nodes[0].position.y;
  nodes.forEach((el) => {
    if (el.position) {
      el.position = {
        x: el.position.x + offsetX,
        y: el.position.y + offsetY,
      };
      el.cellPosition = toCellCoord(el.position);
    }
    if (el.polygon) {
      el.polygon = AppUtils.shiftPolygon(el.polygon, {
        x: offsetX,
        y: offsetY,
      });
    }
  });

  return {
    accId,
    nodes: nodes.map((node) => ({
      ...node,
      ...getSnapNodePosition(node),
    })),
    edges,
    hasLoops,
  };
}

/**
 * Вставить скопированные элементы слоя графа
 */
function* pasteGraphElements({ payload, callback }) {
  const { layerId, typeLayer, type, position } = payload;
  const copiedEls = yield call(getCopiedGraphElements, { position });
  if (!copiedEls) return;
  const { active: accId } = yield select((state) => state.accounts);
  const { nodes, edges, accId: accIdPrev, hasLoops } = copiedEls;
  if (type === 'tree' && hasLoops) {
    yield call(showNotifyGraphError, {
      errorId: 'loopsError',
      label: ERROR_LOOP_ON_TREE,
    });
    return;
  }
  if (accId !== accIdPrev) {
    yield call(showNotifyGraphError, {
      errorId: 'pasteActorsError',
      label: ERROR_ANOTHER_WORKSPACE,
    });
    return;
  }

  const copyNodes = yield call(getGraphEls, 'nodes', layerId);

  const intersectedNodes = findNodesPositionsIntersection(nodes, copyNodes);

  if (intersectedNodes.length) {
    yield call(showNotifyGraphError, {
      errorId: 'cellsAreOccupied',
      label: `Error pasting actors on graph: Occupied cells: ${intersectedNodes
        .map(({ position }) => {
          const { x, y } = toCellCoord(position);
          return `(${x}, ${y})`;
        })
        .join(', ')}`,
    });
    return;
  }
  const edgeTypes = yield select((state) => state.edgeTypes);
  const edgeTypeH = edgeTypes.find((i) => i.name === 'hierarchy');
  const edgeTypeId =
    typeLayer === TYPE_LAYER.trees ? layerId.split('_')[1] : edgeTypeH.id;
  // признак, подходят ли скопированные связи по edgeTypeId для слоя
  const sameEdgeType = edges.length && edges[0].edgeTypeId === edgeTypeId;
  const layerEdges = [];
  if (typeLayer === TYPE_LAYER.layers) {
    const layerNodes = nodes.map((node) => ({
      action: 'create',
      data: {
        id: node.actorId,
        type: 'node',
        model: node,
        ...pick(node, ['position', 'areaPicture', 'polygon']),
      },
    }));
    // добавляем на слой акторы и мапим в модель новые laId
    const { nodesMap, errorMessage } = yield call(manageLayerElements, {
      payload: {
        layerId,
        body: layerNodes,
        withReq: true,
        subscribeBalances: false,
        reduxEvent: false,
      },
    });
    if (errorMessage) {
      yield call(showNotifyGraphError, {
        errorId: 'pasteActorsError',
        label: `${ERROR_PASTE_ON_GRAPH}:\n ${errorMessage}`,
      });
      return;
    }
    nodes.forEach((el) => {
      const layerNode = nodesMap.find(
        (i) => i.actorId === el.actorId && !nodes.find((n) => n.laId === i.laId)
      );
      el.laId = layerNode.laId;
    });
    // проставляем в связях laId для source и target
    edges.forEach((el) => {
      const laSource = nodes.find(
        (i) =>
          (i.prevId === el.prevSource || i.prevId === el.prevTarget) &&
          i.id === el.sourceActorId
      );
      const laTarget = nodes.find(
        (i) =>
          (i.prevId === el.prevTarget || i.prevId === el.prevSource) &&
          i.id === el.targetActorId
      );
      layerEdges.push({
        action: 'create',
        data: {
          id: el.edgeId,
          type: 'edge',
          model: el,
          laIdSource: laSource.laId,
          laIdTarget: laTarget.laId,
        },
      });
      el.laIdSource = laSource.laId;
      el.laIdTarget = laTarget.laId;
    });
    // если edgeType соответствует, то добавляем на слой связи и мапим в модель новые laId
    if (layerEdges.length && sameEdgeType) {
      const { edgesMap } = yield call(manageLayerElements, {
        payload: {
          layerId,
          body: layerEdges,
          withReq: true,
          subscribeBalances: false,
          reduxEvent: false,
        },
      });
      edges.forEach((el, index) => {
        el.laId = edgesMap[index].laId;
      });
    }
  }
  const newGraphEls = yield call(getGraphElsAfterPaste, {
    nodes,
    edges,
    typeLayer,
    sameEdgeType,
    isTree: type === 'tree',
  });
  yield put({ type: MAKE_SOUND, payload: { type: 'addActor' } });
  yield sendReducerMsg({
    type: PASTE_TO_GRAPH.SUCCESS,
    payload: newGraphEls,
  });
  // создать новые связи с другим edgeTypeId
  if (edges.length && !sameEdgeType) {
    yield createNewEdges(edges, edgeTypeId);
  }
  yield resubscribeBalances({ layerId });
  Utils.delSessionStorage(_COPY_GRAPH_KEY_);
  if (callback) callback(nodes.concat(edges));
}

/**
 * Нанести элементы на слой графа
 */
function* bulkAddEls({ payload, callback }) {
  const { layerId } = payload;
  const { layer } = yield getLayer(layerId);
  const { nodes, edges } = yield removeGroupTplActors(payload);

  const isFirstLayerActors = layer && !layer.nodes?.length;

  // Добавить акторы на слой
  const layerNodes = [];
  nodes.forEach((el) => {
    const id = el.actorId;
    const data = {
      id,
      model: { ...el, id },
      type: 'node',
    };
    if (el.position) data.position = el.position;
    layerNodes.push({ action: 'create', data });
  });
  const typeLayer = layer ? layer.typeLayer : TYPE_LAYER.layers;
  const { nodesMap } = yield call(manageLayerElements, {
    payload: {
      layerId,
      body: layerNodes,
      withReq: typeLayer === TYPE_LAYER.layers,
      reduxEvent: !!layer?.nodes,
      subscribeBalances: false,
    },
  });
  nodesMap.forEach((i, index) => {
    const node = nodes[index];
    i.prevId = node.id;
  });
  // Добавить связи на слой
  const layerEdges = [];
  edges.forEach((el) => {
    const { source, target } = el;
    const id = el.edgeId;
    const laSource = nodesMap.find((i) => i.prevId === source);
    const laTarget = nodesMap.find((i) => i.prevId === target);
    if (!laSource || !laTarget) return;
    layerEdges.push({
      action: 'create',
      data: {
        id,
        model: { ...el, id },
        type: 'edge',
        laIdSource: laSource.laId,
        laIdTarget: laTarget.laId,
      },
    });
  });
  if (layerEdges.length) {
    yield call(manageLayerElements, {
      payload: {
        layerId,
        body: layerEdges,
        withReq: true,
        subscribeBalances: false,
      },
    });
  }
  // Apply name of the first actor in the list
  const title = nodes && nodes[0]?.title;

  if (title && isFirstLayerActors) {
    yield reviseDefaultNamings({ layer, title });
  }

  if (callback) callback(nodesMap);
}

/**
 * Закрыть удаленные акторы-слои после массового удаления
 */
function* bulkRemoveLayers(list) {
  const systemForms = yield select((state) => state.systemForms);
  const systemFormsLayers = systemForms.layers || {};
  const layerNodes = list.filter(
    (i) => i.type === 'node' && i.formId === systemFormsLayers.id
  );
  for (const i of layerNodes) {
    yield call(removeLayerFromStore, {
      layerId: i.actorId,
      type: REMOVE_LAYER.SUCCESS,
    });
  }
}

/**
 * Get edges with state node which are non-actual after node removed from layer
 * and need to be removed permanently after the main manage-layer request finished
 */
function* getAffectedStateLinks({ layerId, list }) {
  const [edges, nodes] = yield all([
    call(getGraphEls, 'edges', layerId),
    call(getGraphEls, 'nodes', layerId),
  ]);
  const nodeMap = new Map(
    nodes.map(({ data }) => [data.actorId, data.isStateMarkup])
  );
  return list
    .filter((item) => item.type === 'node')
    .flatMap((node) =>
      edges
        .filter(
          ({ data }) =>
            data.sourceActorId === node.actorId ||
            data.targetActorId === node.actorId
        )
        .map(({ data }) => {
          const isSourceStateMarkup = nodeMap.get(data.sourceActorId);
          const isTargetStateMarkup = nodeMap.get(data.targetActorId);
          return isSourceStateMarkup || isTargetStateMarkup
            ? { id: data.edgeId, type: 'edge' }
            : null;
        })
        .filter(Boolean)
    );
}

/**
 * Bulk actors and links removing
 */
function* bulkRemoveEls({ payload, callback }) {
  const { list, manageLayer } = payload;
  const activeLayer = yield getActiveLayer();
  if (activeLayer) {
    const affectedEdgesWithState = manageLayer
      ? yield call(getAffectedStateLinks, { layerId: activeLayer.id, list })
      : [];
    yield call(manageLayerElements, {
      payload: {
        layerId: activeLayer.id,
        activeElement: null,
        activeType: null,
        editableElement: null,
        body: list.map((i) => ({
          action: 'delete',
          data: {
            id: i.actorId,
            model: i,
            type: i.type,
            withDuplicates: !manageLayer,
          },
        })),
        withReq: manageLayer && activeLayer.typeLayer === TYPE_LAYER.layers,
      },
    });
    const listMap = keyBy(list, 'actorId');
    yield removeNodesFromGraph((n) => Boolean(listMap[n.data.actorId]));
    if (affectedEdgesWithState.length) {
      yield call(apiBulk, {
        method: 'delete',
        url: '/actors',
        body: affectedEdgesWithState,
        handleErrCodes: [403, 404],
      });
    }
  }
  if (!manageLayer) {
    // remove actors
    const removeReqBody = list
      .filter((i) => !i.isTemplate)
      .map((i) => ({
        id: i.actorId || i.edgeId,
        type: i.type || 'node',
      }));
    if (removeReqBody.length) {
      yield call(
        removeActorsFromList,
        removeReqBody.map((i) => i.id)
      );
      const { node = [], edge = [] } = AppUtils.groupBy(removeReqBody, 'type');
      const removeApiParams = { method: 'delete', url: '/actors' };
      if (edge.length) yield call(apiBulk, { ...removeApiParams, body: edge });
      if (node.length) {
        yield call(apiBulk, { ...removeApiParams, body: node });
        yield put({
          type: VALENCY_GRAPH.REMOVE_NODES.SUCCESS,
          payload: node,
        });
      }
    }
    // remove templates
    const removeTplBody = list.filter((i) => i.isTemplate);
    if (removeTplBody.length) {
      const forms = removeTplBody.map((i) => i.id);
      const payload = { forms };
      yield call(bulkRemoveForms, { payload });
    }
  }
  yield call(bulkRemoveLayers, list);
  if (callback) callback({ activeLayer });
}

/**
 * Установить активный элемент графа
 */
function* setActiveElement({ payload }) {
  const { id, type } = payload;
  const copyNodes = yield call(getGraphEls, 'nodes');
  const copyEdges = yield call(getGraphEls, 'edges');
  const els = type === 'node' ? copyNodes : copyEdges;
  const findEl = els.find((i) => i.id === id);
  if (findEl) findEl.status = 'updated';
  yield sendReducerMsg({
    type: SET_ACTIVE_ELEMENT.SUCCESS,
    payload: {
      nodes: copyNodes,
      edges: copyEdges,
      activeElement: id,
      activeType: type,
      editableElement: null,
    },
  });
}

function* graphCommon() {
  yield takeEvery(SET_ACTIVE_ELEMENT.REQUEST, setActiveElement);
  yield takeEvery(BULK_REMOVE_FROM_GRAPH.REQUEST, bulkRemoveEls);
  yield takeEvery(BULK_ADD_TO_GRAPH.REQUEST, bulkAddEls);
  yield takeEvery(COPY_FROM_GRAPH.REQUEST, copyGraphElements);
  yield takeEvery(PASTE_TO_GRAPH.REQUEST, pasteGraphElements);
}

export default graphCommon;
