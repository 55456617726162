import { defineMessages } from 'react-intl';

const m = defineMessages({
  initialPhrase: {
    id: 'initialPhrase_InviteMembers',
    defaultMessage:
      'Invite users to your workspace. New user will have access after the log in. Enter login and select role.',
  },
  selectUsers: {
    id: 'selectUsers_InviteMembers',
    defaultMessage: 'Select user',
  },
  enterText: {
    id: 'enterText_InviteMembers',
    defaultMessage: 'Enter email or login',
  },
  role: {
    id: 'role_InviteMembers',
    defaultMessage: 'Role',
  },
  chooseRole: {
    id: 'chooseRole_InviteMembers',
    defaultMessage: 'Choose user`s role',
  },
  submitText: {
    id: 'submitText_InviteMembers',
    defaultMessage: 'Send invitation',
  },
  successNotifText: {
    id: 'successNotifText_InviteMembers',
    defaultMessage: 'User {userLogin} has been invited',
  },
});

export default m;
