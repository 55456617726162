import React, {
  useState,
  useRef,
  useId,
  useEffect,
  useCallback,
  Fragment,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Label,
  Icon,
  Badge,
  Autocomplete,
  AutocompleteItem,
  Chip,
  Button,
  ProgressBar,
  Stack,
  Space,
} from 'mw-style-react';
import cn from 'classnames';
import useOutsideClick from 'useOutsideClick';
import useIntl from 'useIntl';
import AppUtils from '@control-front-end/utils/utils';
import ActorAvatar from '@control-front-end/common/components/ActorAvatar';
import UsersAvatars from '@control-front-end/common/components/UsersAvatars';
import { PERMISSIONS } from '@control-front-end/common/constants/permissions';
import { checkUserPermissions } from '@control-front-end/app/src/selectors';
import mes from './intl';
import scss from './SelectEntityField.scss';

/**
 * Select field for Actors, Forms, Account names, Currencies, Users
 */
function SelectEntityField(props) {
  const {
    objType,
    id,
    value,
    error,
    placeholder,
    helperText,
    bordered,
    isDisabled,
    onChange,
    onSearch,
    onBlur,
    onFocus,
    onScroll,
    onCreate,
    onReset,
    onKeyDown = () => {},
    size = 'medium',
    type = 'default',
    unspaced = false,
    list = [],
    multiselect = false,
    fullModel = false,
    withRelations = false,
    label,
    formId,
    autoFocus,
    resultLabel,
    wrap = false,
    hideCreateBtn = false,
    isLoading = false,
    resettable,
    resetOnBlur,
    fieldClassName = '',
    popoverOptions,
    checkItemVisibility,
    ...restProps
  } = props;
  const t = useIntl();
  const createBtnId = useId();
  const searchBox = useRef();
  const [query, setQuery] = useState('');
  const [focus, setFocus] = useState(false);
  const showDetails = objType === 'actor' && !formId;
  const showBreadCrumbs = objType === 'template' && withRelations;
  const checkPermissions = useSelector(checkUserPermissions);
  const canAddActor = checkPermissions([PERMISSIONS.ACTORS_MANAGEMENT]);
  const showCreateBtn = focus && onCreate && !hideCreateBtn;

  useEffect(() => {
    if (!focus) return;
    setTimeout(() => {
      if (!searchBox.current) return;
      const fieldEl = searchBox.current.querySelector('div.field');
      const inputEl = fieldEl.querySelector('input');
      inputEl.focus();
      inputEl.click();
    }, 100);
  }, [focus]);

  const handleBlur = () => {
    setTimeout(() => {
      setFocus(false);
      if (resetOnBlur) setQuery('');
      if (onBlur) onBlur();
    }, 100);
  };

  useOutsideClick({
    ref: searchBox,
    callback: (e) => {
      const searchListEl = document.getElementById('searchList');
      if (searchListEl && searchListEl.contains(e.target)) return;
      handleBlur();
    },
    triggers: ['mousedown', 'contextmenu'],
  });

  /**
   * Handle search query change
   * @param q
   */
  const handleChange = ({ value: q }) => {
    setQuery(q);
    onSearch(q);
  };

  /**
   * Handle hot keys:
   * Enter - create item (if "Create" button is visible = creating is allowed)
   */
  const handleKeyDown = (e) => {
    if (showCreateBtn && e.key === 'Enter' && query.length) {
      document.getElementById(createBtnId).click();
      return;
    }
    onKeyDown(e);
  };

  /**
   * Delete item
   */
  const handleDelItem = (itemId) => {
    const newVal = AppUtils.removeFromArrayObject(value, 'id', itemId);
    onChange({ id, value: newVal });
    handleBlur();
  };

  const getItemModel = (model) => {
    const {
      value,
      title,
      name,
      createdAt,
      status,
      user,
      pictureUrl,
      allColors,
      ownerId,
      ownerName,
      ownerAvatar,
      formType,
      formTitle,
      privs,
      data,
    } = model;
    const unifiedFields = {
      id: model.id,
      title: title || name || value,
      ownerId: user ? user.id : ownerId,
      ownerName: user ? model.user.nick : ownerName,
      ownerAvatar: user ? user.avatar : ownerAvatar,
    };
    if (fullModel) return { ...model, ...unifiedFields };
    const newModel = {
      id,
      createdAt,
      status,
      pictureUrl,
      allColors,
      formType,
      formTitle,
      privs,
      ...unifiedFields,
    };
    if (objType === 'currency') {
      newModel.precision = model.precision;
      newModel.type = model.type;
      newModel.symbol = model.symbol;
    }
    if (props.withData) {
      newModel.data = data;
    }
    return newModel;
  };

  /**
   * Handle click on item from search result
   * @param item
   */
  const handleItemClick = (item) => {
    const newItem = getItemModel(item);
    let val;
    if (multiselect) {
      val = value.slice();
      val.push(newItem);
    } else {
      val = newItem;
    }
    onChange({ id, value: val });
    handleBlur();
  };

  const handleFocus = () => {
    setFocus(true);
    if (!onFocus) return;
    onFocus();
  };

  const emptyResult = () => (
    <AutocompleteItem
      id="not_found"
      size="small"
      value="Not found"
      onClick={handleBlur}
    />
  );

  const renderUATBreadCrumbs = useCallback(
    (forms) => {
      if (!forms) return null;
      const treeForms = forms.slice();
      treeForms.shift();
      let uatWidth = 200;
      // try to calculate width from real field width
      if (searchBox.current) {
        const fieldEl = searchBox.current.querySelector('div.field');
        const { width } = fieldEl.getBoundingClientRect();
        uatWidth = width;
      }
      const max = Math.ceil(uatWidth / 64);
      const itemsBefore = max - 2;
      const totalItems = treeForms.length;
      if (!totalItems) return null;
      const visibleList =
        totalItems > max
          ? [
              ...treeForms.slice(0, itemsBefore),
              { id: null },
              ...treeForms.slice(totalItems - 1, totalItems),
            ]
          : treeForms;
      return (
        <div styleName="se__item__uat" style={{ width: uatWidth }}>
          {visibleList.map((i) =>
            i.id ? (
              <Fragment key={i.id}>
                <Icon type="arrow_thin" size="micro" />
                <Label fontSize="xsmall" value={i.title} />
              </Fragment>
            ) : (
              <Fragment key="more">
                <Icon type="arrow_thin" size="micro" />
                <Label fontSize="xsmall" value="..." />
              </Fragment>
            )
          )}
        </div>
      );
    },
    [focus, searchBox.current]
  );

  const renderItemCustomEl = (item) => {
    switch (objType) {
      case 'actor':
        return (
          <div styleName="se__item__avatar">
            <ActorAvatar
              size="small"
              type="compact"
              formType={item.formType}
              formTitle={item.formTitle}
              pictureUrl={item.pictureUrl}
              colors={item.allColors}
              status={item.status}
              colorFilled={true}
            />
            {showDetails ? (
              <div styleName="se__item__details">
                <Label fontSize="small" value={item.formTitle} />
              </div>
            ) : null}
          </div>
        );
      case 'user':
        return (
          <div styleName="se__item__avatar">
            <UsersAvatars users={[item]} size="small" />
          </div>
        );
      case 'template':
        return (
          <>
            <Badge
              styleName="se__item__badge"
              bgColor={item.color || '#ffffff'}
              borderColor={item.color || '#ACB3BE'}
            />
            {renderUATBreadCrumbs(item.forms)}
          </>
        );
      default:
        return null;
    }
  };

  const renderSearchResult = () => {
    if (!focus) return;
    const items = list.map((i) => (
      <AutocompleteItem
        styleName={cn('se__item', {
          showDetails,
          showBreadCrumbs: showBreadCrumbs && i.forms && i.forms.length > 1,
          showId: objType === 'template' && fullModel,
        })}
        key={i.id}
        id={i.id}
        value={i.name || i.title || i.value}
        tooltip={i.name || i.title || i.value}
        rightIcon={value?.id === i.id ? 'check' : null}
        onClick={() => handleItemClick(i)}
        children={renderItemCustomEl(i)}
        visibility={checkItemVisibility(i)}
      />
    ));
    if (!query.length && !list.length) return null;
    return <div>{list.length ? items : emptyResult()}</div>;
  };

  const renderChipsItems = () => {
    if (!multiselect) {
      return () => {};
    }
    return value.map((item) => (
      <Chip
        styleName={cn('se__chip', { extra: objType === 'template' })}
        key={item.id}
        label={item.title || item.name}
        size="small"
        type="rectangular"
        fontWeight="semibold"
        bgColor="#F0F6FD"
        borderColor="#BAD5F8"
        closeClick={() => handleDelItem(item.id)}
        children={
          objType === 'template' ? (
            <Badge
              styleName="se__chip__badge"
              size="small"
              bgColor={item.color || '#ffffff'}
              borderColor={item.color || '#ACB3BE'}
            />
          ) : null
        }
      />
    ));
  };

  const renderSearchTitle = () => {
    const defL = resultLabel || t(mes.recent);
    const sLabel = query.length ? t(mes.searchResults) : defL;
    return <Label styleName="se__title" fontWeight="semibold" value={sLabel} />;
  };

  const getValueTitle = () => {
    const valueTitle = value?.name || value?.title || value?.nick;
    if (valueTitle) return valueTitle;
    return value?.value ? String(value.value) : '';
  };

  const autocompleteValue = focus ? query : getValueTitle();

  const searchField = (
    <div ref={searchBox} className={cn(fieldClassName, scss.se)}>
      <Autocomplete
        id={id}
        styleName={cn('se__field', type, {
          template: objType === 'template',
          showCreateBtn,
        })}
        size={size}
        placeholder={placeholder}
        label={label}
        value={autocompleteValue}
        bordered={bordered}
        unspaced={unspaced}
        error={error}
        helperText={helperText}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onFocus={handleFocus}
        visibility={isDisabled ? 'disabled' : 'visible'}
        onSearchListScroll={onScroll}
        scrollBoundary={100}
        searchResult={renderSearchResult}
        searchTitle={renderSearchTitle}
        chipsItems={renderChipsItems}
        popoverOnTop={type === 'modal'}
        popoverOptions={{
          onScroll,
          ...(type === 'modal'
            ? {
                contentClassName: [
                  'currency',
                  'accountName',
                  'template',
                ].includes(objType)
                  ? scss.autocompleteContentShort
                  : scss.autocompleteContentHeight,
              }
            : {}),
          ...(popoverOptions || {}),
        }}
        autoFocus={autoFocus}
        endAdornment={
          <Space size={Space.SIZE.xxsmall} className={scss.endAdorment}>
            <Stack horizontal size="xsmall" alignItems="center">
              {isLoading ? <ProgressBar type="circle" size="small" /> : null}
              {resettable && Boolean(autocompleteValue) ? (
                <Button
                  type="text"
                  size="xsmall"
                  icon={<Icon size="small" type="close" />}
                  onClick={(...args) => {
                    handleChange({ value: '' });
                    if (onReset) {
                      onReset(...args);
                    }
                  }}
                />
              ) : null}
              {showCreateBtn ? (
                <Button
                  id={createBtnId}
                  className={scss.createBtn}
                  size="small"
                  fontWeight="normal"
                  label={t(mes.create)}
                  onClick={() => {
                    onCreate({ value: query });
                    setQuery('');
                    if (!multiselect) handleBlur();
                  }}
                  visibility={
                    isDisabled || !canAddActor ? 'disabled' : 'visible'
                  }
                />
              ) : null}
            </Stack>
          </Space>
        }
        {...restProps}
      />
    </div>
  );

  return wrap ? <div styleName="se__wrap">{searchField}</div> : searchField;
}

SelectEntityField.defaultProps = {
  resetOnBlur: true,
  checkItemVisibility: ({ isDisabled }) =>
    isDisabled ? 'disabled' : 'visible',
};

SelectEntityField.propTypes = {
  id: PropTypes.string,
  objType: PropTypes.oneOf([
    'actor',
    'template',
    'actorFilter',
    'currency',
    'accountName',
    'user',
  ]),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['default', 'modal']),
  list: PropTypes.array,
  label: PropTypes.node,
  wrap: PropTypes.bool,
  fullModel: PropTypes.bool,
  resultLabel: PropTypes.string,
  error: PropTypes.bool,
  multiselect: PropTypes.bool,
  bordered: PropTypes.bool,
  isDisabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  hideCreateBtn: PropTypes.bool,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onScroll: PropTypes.func,
  onCreate: PropTypes.func,
  onReset: PropTypes.func,
  onKeyDown: PropTypes.func,
  isLoading: PropTypes.bool,
  resettable: PropTypes.bool,
  resetOnBlur: PropTypes.bool,
  fieldClassName: PropTypes.string,
  withData: PropTypes.bool,
};

export default SelectEntityField;
