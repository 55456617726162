import { put, select, takeEvery } from 'redux-saga/effects';
import { ADD_FORMS_FILTER, HIDE_FORMS_FILTER } from '../constants/formsFilters';

/**
 * Добавить фильтр в активный список
 */
function* addFormFilter({ payload, callback }) {
  const filters = yield select((state) => state.formsFilters);
  const copyList = filters.list.slice();
  copyList.push(payload);
  yield put({
    type: ADD_FORMS_FILTER.SUCCESS,
    payload: {
      list: copyList,
      offset: filters.offset + 1,
      total: filters.total + 1,
    },
  });
  if (callback) callback(payload);
}

/**
 * Убрать из активных фильтр
 */
function* hideFormFilter({ payload, callback }) {
  const filters = yield select((state) => state.formsFilters);
  const copyList = filters.list.filter((i) => i.id !== payload.id);
  yield put({
    type: HIDE_FORMS_FILTER.SUCCESS,
    payload: {
      list: copyList,
      offset: filters.offset - 1 < 0 ? 0 : filters.offset - 1,
    },
  });
  if (callback) callback(payload);
}

function* formsFilters() {
  yield takeEvery(ADD_FORMS_FILTER.REQUEST, addFormFilter);
  yield takeEvery(HIDE_FORMS_FILTER.REQUEST, hideFormFilter);
}

export default formsFilters;
