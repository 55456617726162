import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from 'mw-style-react';
import cn from 'classnames';
import AppUtils from '@control-front-end/utils/utils';
import FormUtils from '@control-front-end/utils/formUtils';
import FormNavigation from '@control-front-end/common/components/FormNavigation';
import { intlShape } from 'react-intl';
import FormTab from './FormTab';
import './Form.scss';

/**
 * Рендеринг Формы
 */
class Form extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      form: structuredClone(props.form),
      formErrors: [],
      updateTime: props.updateTime,
    };
    this.formTabRef = React.createRef();
    this.formContainerRef = React.createRef();
    this.handleSelectSection = this.handleSelectSection.bind(this);
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  getFormData() {
    if (!this.formTabRef.current.getWrappedInstance()) return;
    return this.formTabRef.current.getWrappedInstance().handleSubmit();
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  getFormErrors() {
    const { formErrors } = this.state;
    return formErrors;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.updateTime !== state.updateTime) {
      return {
        form: props.form,
        updateTime: props.updateTime,
      };
    }
    return null;
  }

  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  handleSelectSection(index) {
    const { formId } = this.props;
    const container = this.formContainerRef.current;
    this.setState({ activeSection: index });
    FormUtils.scrollToFormSection(container, formId, index);
  }

  renderContent() {
    const {
      form,
      formId,
      onChange,
      onSubmit,
      onFormItemLoading,
      type,
      activeSection,
      openAllSections,
      isPreview,
    } = this.props;
    const wrapSections =
      type === 'modal' && form.sections && form.sections.length > 0;
    return (
      <FormTab
        ref={this.formTabRef}
        form={this.state.form}
        formId={formId}
        isPreview={isPreview}
        onFormItemLoading={onFormItemLoading}
        onChange={(formData) => {
          const copyFormData = { ...formData };
          this.setState({ form: copyFormData });
          onChange(copyFormData);
        }}
        onError={({ form, errors, errorToRemove }) => {
          const copyFormData = { ...form };
          if (errors) {
            this.setState({ form: copyFormData, formErrors: errors });
            return;
          }
          const { formErrors } = this.state;
          const formErrorsCopy = formErrors.slice();
          const index = formErrorsCopy.findIndex(
            (i) => i.id === errorToRemove.id
          );
          if (index === -1) return;
          formErrorsCopy.splice(index, 1);
          this.setState({ form: copyFormData, formErrors: formErrorsCopy });
        }}
        onSubmit={onSubmit}
        activeSection={activeSection}
        openAllSections={openAllSections}
        wrapSections={wrapSections}
      />
    );
  }

  render() {
    const { type, showNavigation } = this.props;
    const { form, activeSection } = this.state;
    const isLoaded = form && Object.keys(form).length > 0;
    if (!isLoaded) {
      return (
        <div styleName="formLoader">
          <ProgressBar />
        </div>
      );
    }

    return (
      <>
        {showNavigation ? (
          <div styleName="form__navigation">
            <FormNavigation
              sections={form.sections}
              activeSection={activeSection}
              readOnly={true}
              handleSelect={this.handleSelectSection}
              handleSortSection={() => {}}
              handleRemoveSection={() => {}}
            />
          </div>
        ) : null}
        <div
          ref={this.formContainerRef}
          id={form.id}
          styleName={cn('form', type, { showNavigation })}
        >
          <div styleName="form__wrap">
            {type === 'single' ? (
              <div styleName="form__header">
                <div styleName="form__header__title">{form.title}</div>
                {form.description && form.description.length ? (
                  <div styleName="form__header__description">
                    {form.description}
                  </div>
                ) : null}
              </div>
            ) : null}
            <div styleName="form__content">{this.renderContent()}</div>
          </div>
        </div>
      </>
    );
  }
}

Form.defaultProps = {
  type: 'single',
  onChange: () => {},
  onSubmit: () => {},
  showNavigation: false,
  isPreview: false,
};

Form.propTypes = {
  type: PropTypes.oneOf(['page', 'single', 'panel', 'modal']),
  form: PropTypes.object.isRequired,
  formId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateTime: PropTypes.number,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  activeSection: PropTypes.number,
  isPreview: PropTypes.bool,
  openAllSections: PropTypes.bool,
  showNavigation: PropTypes.bool,
};

Form.contextTypes = { intl: intlShape };

export default Form;
