import React, { PureComponent } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import AppUtils from '@control-front-end/utils/utils';
import Edit from '../Edit/Edit';
import mes from '../../intl';
import './Button.scss';

/**
 * Компонент Button Constructor
 */
class Button extends PureComponent {
  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  render() {
    return (
      <div styleName="button">
        <Edit placeholder={this.i(mes.buttonLabel)} {...this.props} />
      </div>
    );
  }
}

Button.propTypes = {};

Button.contextTypes = {
  intl: intlShape,
};

export default injectIntl(Button);
