import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  triggerDate: {
    id: 'triggerDate',
    defaultMessage: 'Date & Time',
  },
  triggerName: {
    id: 'triggerName',
    defaultMessage: 'Trigger name',
  },
  accCurrency: {
    id: 'accCurrency',
    defaultMessage: 'Account, Currency',
  },
  httpCode: {
    id: 'httpCode',
    defaultMessage: 'Answer Code',
  },
  webhook: {
    id: 'webhook',
    defaultMessage: 'Webhook',
  },
  resend: {
    id: 'resend',
    defaultMessage: 'Resend',
  },
});

export default { ...globalIntl, ...m };
