import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Stack, Space, TextField, Divider } from 'mw-style-react';
import { useFilter, useIntl } from 'hooks';
import ParticipantMappingMaker from './ParticipantMappingMaker';

import m from './intl';

/**
 * Creates a component for selecting and displaying a list of participants.
 *
 * @param {Object} props - The component's properties.
 * @param {string} props.id - The ID of the participant.
 * @param {string} props.nick - The nickname of the participant.
 * @param {string} props.avatar - The URL of the participant's avatar.
 * @param {function} props.onChange - A callback function called when the participant list changes.
 * @return {JSX.Element} A React component that displays the participant list and allows the user to map participants.
 */
function ParticipantsMappingMaker({ participants, onChange }) {
  const t = useIntl();

  const [filteredParticipants, setFilter] = useFilter(
    participants,
    ({ nick }, filter) => nick.toLowerCase().includes(filter.toLowerCase()),
    { debounce: 200 }
  );

  const handleChange = useCallback(({ id, mappedParticipants }) => {
    participants.find((item) => item.id === id).mappedParticipants =
      mappedParticipants;
    onChange(participants);
  });

  return (
    <Stack>
      <TextField
        leftIcon="search"
        bordered={true}
        placeholder={t(m.searchBy)}
        color={TextField.COLOR.white}
        unspaced
        onChange={({ value }) => setFilter(value)}
      />
      <Stack size={Stack.SIZE.xxsmall} divider={<Divider />}>
        {filteredParticipants.map((item) => (
          <Space key={item.id} top bottom size={Space.SIZE.xsmall}>
            <ParticipantMappingMaker {...item} onChange={handleChange} />
          </Space>
        ))}
      </Stack>
    </Stack>
  );
}

ParticipantsMappingMaker.propTypes = {
  participants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      nick: PropTypes.string.isRequired,
      avatar: PropTypes.string,
      mappedParticipants: PropTypes.arrayOf(PropTypes.object),
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ParticipantsMappingMaker;
