import { call, put, takeEvery, select } from 'redux-saga/effects';
import api from '@control-front-end/common/sagas/api';
import { Utils } from 'mw-style-react';
import {
  GET_SCRIPT_STRUCTURE,
  GET_SCRIPT_FOLDER,
  CREATE_SCRIPT_OBJECTS,
  UPDATE_SCRIPT_OBJECTS,
  REMOVE_SCRIPT_OBJECTS,
  SET_SCRIPT_CONTENT_REQ_STATUS,
} from '../constants/scripts';
import { RequestStatus } from '../constants/index';

// Порядок отображения корневых элементов структуры приложения
const STRUCTURE_ORDER = {
  pages: 1,
  definitions: 2,
  'style.css': 3,
  'locale.json': 4,
  'viewModel.json': 5,
};

/**
 * Создать модель структуры
 */
function makeSystemObjectsModel(data) {
  const root = { ...data };
  for (const model of root.children) {
    model.priority = STRUCTURE_ORDER[model.title];
    switch (model.title) {
      case 'pages':
        model.icon = 'page';
        model.priority = 0;
        break;
      case 'definitions':
        model.priority = 1;
        model.icon = 'page_content';
        break;
      case 'style':
        model.priority = 2;
        model.icon = 'palette';
        break;
      case 'locale':
        model.priority = 3;
        model.icon = 'flag';
        break;
      case 'viewModel':
        model.priority = 4;
        model.icon = 'json';
        break;
      default:
        break;
    }
  }
  root.children = Utils.sort(root.children, 'priority');
  return root;
}

/**
 * Рекурсивный поиск объекта в структуре приложения
 */
function recursiveFindObj(list, id, objType) {
  let file = list.find((i) => i.id === id && i.objType === objType);
  if (file) {
    return file;
  }
  for (const item of list) {
    file = recursiveFindObj(item.children || [], id, objType);
    if (file) {
      return file;
    }
  }
}

/**
 * Обновить структуру скрипта
 */
function* updateScriptContent({ action, folderId, data }) {
  const { content } = yield select((state) => state.scriptContent);
  const contentCopy = structuredClone(content);
  switch (action) {
    case 'create':
      data.forEach((item) => {
        const parentFolderId = item.parentId || item.folderId;
        const targetFolder = recursiveFindObj(
          contentCopy.children,
          parentFolderId,
          'folder'
        );
        item.isNew = true;
        if (targetFolder.children) {
          targetFolder.children.push(item);
        } else {
          targetFolder.children = [item];
        }
      });
      break;
    case 'update':
      data.forEach((item) => {
        const findItem = recursiveFindObj(
          contentCopy.children,
          item.id,
          item.objType
        );
        item.isNew = false;
        findItem.title = item.title;
        findItem.isStatic = item.isStatic;
        if (findItem.objType === 'file') {
          findItem.source = item.source;
        }
      });
      break;
    case 'delete':
      const folder = recursiveFindObj(contentCopy.children, folderId, 'folder');
      const ids = data.map((i) => i.id);
      folder.children = folder.children.filter((i) => !ids.includes(i.id));
      break;
    default:
      break;
  }
  return contentCopy;
}

/**
 * Получить все окружение
 */
function* getScriptStructure({ payload, callback }) {
  const { scriptId, envId } = payload;
  yield put({ type: SET_SCRIPT_CONTENT_REQ_STATUS, payload: 'inProgress' });
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/app_content/struct/${scriptId}/${envId}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({ type: SET_SCRIPT_CONTENT_REQ_STATUS, payload: 'success' });
  const root = yield call(makeSystemObjectsModel, data.data);
  yield put({
    type: GET_SCRIPT_STRUCTURE.SUCCESS,
    payload: { content: root },
  });
  if (callback) callback(root);
}

/**
 * Получить список объектов в папке
 */
function* getScriptFolder({ payload, callback }) {
  const { scriptId, folderId } = payload;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/app_content/${scriptId}/${folderId}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({
    type: GET_SCRIPT_FOLDER.SUCCESS,
    payload: { content: data.data },
  });
  if (callback) callback(data.data);
}

/**
 * Создать объекты в папке
 */
function* createScriptObjects({ payload, callback }) {
  const { scriptId, body } = payload;
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/app_content/${scriptId}`,
    body,
  });
  if (result !== RequestStatus.SUCCESS) return;
  const updatedContent = yield call(updateScriptContent, {
    action: 'create',
    data: data.data,
  });
  yield put({
    type: CREATE_SCRIPT_OBJECTS.SUCCESS,
    payload: { content: updatedContent },
  });
  if (callback) callback(data.data);
}

/**
 * Обновить объекты
 */
function* updateScriptObjects({ payload, callback }) {
  const { scriptId, body } = payload;
  const { result, data } = yield call(api, {
    method: 'put',
    url: `/app_content/${scriptId}`,
    body,
  });
  if (result !== RequestStatus.SUCCESS) return;
  const updatedContent = yield call(updateScriptContent, {
    action: 'update',
    data: body,
  });
  yield put({
    type: UPDATE_SCRIPT_OBJECTS.SUCCESS,
    payload: { content: updatedContent },
  });
  if (callback) callback(data.data);
}

/**
 * Удалить объекты
 */
function* removeScriptObjects({ payload, callback }) {
  const { scriptId, folderId, body } = payload;
  const { result, data } = yield call(api, {
    method: 'delete',
    url: `/app_content/${scriptId}`,
    body,
  });
  if (result !== RequestStatus.SUCCESS) return;
  const updatedContent = yield call(updateScriptContent, {
    action: 'delete',
    folderId,
    data: body,
  });
  yield put({
    type: REMOVE_SCRIPT_OBJECTS.SUCCESS,
    payload: { content: updatedContent },
  });
  if (callback) callback(data.data);
}

function* scriptContent() {
  yield takeEvery(GET_SCRIPT_STRUCTURE.REQUEST, getScriptStructure);
  yield takeEvery(GET_SCRIPT_FOLDER.REQUEST, getScriptFolder);
  yield takeEvery(CREATE_SCRIPT_OBJECTS.REQUEST, createScriptObjects);
  yield takeEvery(UPDATE_SCRIPT_OBJECTS.REQUEST, updateScriptObjects);
  yield takeEvery(REMOVE_SCRIPT_OBJECTS.REQUEST, removeScriptObjects);
}

export default scriptContent;
