import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';
import FormUtils from '@control-front-end/utils/formUtils';
import './Label.scss';

/**
 * Компонент Label
 */
class Label extends PureComponent {
  render() {
    const { id, visibility, align, value } = this.props;
    return (
      <div
        id={id}
        styleName={cn('label', {
          hidden: visibility === 'hidden',
          left: align === 'left',
          center: align === 'center',
          right: align === 'right',
        })}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: FormUtils.bbCodeToHtml(value),
          }}
        />
      </div>
    );
  }
}

Label.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  align: PropTypes.string,
  visibility: PropTypes.string,
};

export default Label;
