import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';
import { Button as ButtonMW } from 'mw-style-react';
import './Button.scss';

/**
 * Компонент Button
 */
class Button extends PureComponent {
  render() {
    const { id, type, title, visibility, onSubmit } = this.props;
    return (
      <ButtonMW
        id={id}
        type={type}
        visibility={visibility}
        label={title}
        styleName={cn('button')}
        onClick={() => onSubmit(id)}
      />
    );
  }
}

Button.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  title: PropTypes.string,
  visibility: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default Button;
