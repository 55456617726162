import { put, select, takeEvery } from 'redux-saga/effects';
import {
  ADD_LAYERS_AREA,
  CLEAR_NEW_LAYER_AREAS,
} from '@control-front-end/common/constants/graphLayers';
import { DateUtils } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';

// ============================= LAYER AREA ==============================================

/**
 * Добавить зону на слой
 */
function* addLayersArea({ payload, callback }) {
  const unixtime = DateUtils.unixtime();
  const auth = yield select((state) => state.auth);
  const areas = yield select((state) => state.layersAreas);
  const color = AppUtils.getRandomPastelColor();
  const areaPicture = AppUtils.createImgByPolygon({
    polygon: payload.polygon,
    zoom: payload.zoom,
    color,
  });
  const area = {
    ...payload,
    id: unixtime,
    userId: auth.id,
    createdAt: unixtime,
    updatedAt: unixtime,
    color,
    areaPicture,
    commentsCount: 0,
    status: 'new',
  };
  const areasList = areas.list.slice();
  areasList.push(area);
  yield put({
    type: ADD_LAYERS_AREA.SUCCESS,
    payload: { list: areasList },
  });
  if (callback) callback(area);
}

/**
 * Удалить все новые зоны слоя
 */
function* clearNewAreas() {
  const areas = yield select((state) => state.layersAreas);
  const newAreaIndex = areas.list.findIndex((i) => i.status === 'new');
  if (newAreaIndex === -1) return;
  const newList = areas.list.slice();
  newList.splice(newAreaIndex, 1);
  yield put({
    type: CLEAR_NEW_LAYER_AREAS.SUCCESS,
    payload: { list: newList },
  });
}

// ============================= WS LAYER AREA ==============================================

function* layerAreas() {
  yield takeEvery(ADD_LAYERS_AREA.REQUEST, addLayersArea);
  yield takeEvery(CLEAR_NEW_LAYER_AREAS.REQUEST, clearNewAreas);
}

export default layerAreas;
