import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Chip } from 'mw-style-react';
import useOutsideClick from 'useOutsideClick';
import './FuncSelect.scss';

function FuncSelect({ funcs, onSelect, handleClickOutside }) {
  const funcRef = useRef();
  useOutsideClick({ ref: funcRef, callback: handleClickOutside });

  return (
    <div ref={funcRef} styleName="func">
      {funcs.map((i) => (
        <Chip
          styleName="func__chip"
          key={i.func}
          label={i.title}
          type="rectangular"
          fontWeight="normal"
          closeIcon={false}
          size="small"
          onClick={() => onSelect(i)}
        />
      ))}
    </div>
  );
}

FuncSelect.propTypes = {
  funcs: PropTypes.array,
  onSelect: PropTypes.func,
  handleClickOutside: PropTypes.func,
};

export default FuncSelect;
