import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  showMore: {
    id: 'showMore',
    defaultMessage: 'Show more',
  },
  showLess: {
    id: 'showLess',
    defaultMessage: 'Show less',
  },
});

export default { ...globalIntl, ...m };
