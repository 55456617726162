import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import {
  DateUtils,
  Calendar,
  TextField,
  Stack,
  ProgressBar,
} from 'mw-style-react';
import { SET_MODAL, UPLOAD_FILE } from 'constants';
import { GET_ACTOR } from '@control-front-end/common/constants/graphActors';
import AppUtils from '@control-front-end/utils/utils';
import { useIntl } from 'hooks';
import Header from './components/Header';
import Description from './components/Description';
import People from './components/People/People';
import Additions from './components/Additions/Additions';
import Actors from './components/Actors';
import Controls from './components/Controls';
import mes from './intl';
import scss from './Card.scss';

function Card(props) {
  const {
    id = 0,
    endDate,
    startDate,
    errors = [],
    ownerId,
    ownerAvatar,
    ownerName,
    access,
    isLoading,
    setLoading,
    onChange,
    additionalControls,
  } = props;
  const t = useIntl();
  const dispatch = useDispatch();
  const cardBox = useRef();
  const descrRef = useRef();
  const [openPanel, togglePanel] = useState(false);
  const [eventDetails, setEventDetails] = useState({
    title: props.title || '',
    description: props.description || '',
    attachments: props.attachments || [],
    actors: props.actors || [],
    app: {},
    appSettings: props.appId ? props.appSettings : null,
    cardActor: {},
  });
  const [additionToggles, setAdditionToggles] = useState({});
  const toggleHeights = {
    files: 104,
    scripts: 90,
    actors: 90,
  };

  /**
   * При клонировании необходимо получить детали скрипта/карточки актора
   */
  useEffect(() => {
    if (props.appId) {
      dispatch({
        type: GET_ACTOR.REQUEST,
        payload: { id: props.appId },
        callback: (data) => {
          setEventDetails((prevDetails) => ({
            ...prevDetails,
            app: data,
          }));
        },
      });
    }
    if (props.cardActorId) {
      dispatch({
        type: GET_ACTOR.REQUEST,
        payload: { id: props.cardActorId },
        callback: (data) => {
          setEventDetails((prevDetails) => ({
            ...prevDetails,
            cardActor: data,
          }));
        },
      });
    }
  }, []);

  const checkHeight = (checkParam, obj, key) => {
    if (!checkParam) {
      delete obj[key];
      return;
    }
    if (!(key in obj)) obj[key] = true;
  };

  useEffect(() => {
    const editorDiv = document.getElementById('eventEditor');
    if (!editorDiv) return;
    const newToggles = { ...additionToggles };
    checkHeight(eventDetails.attachments.length, newToggles, 'files');
    checkHeight(eventDetails.app.id, newToggles, 'scripts');
    checkHeight(eventDetails.cardActor.id, newToggles, 'actors');
    let newTotalHeight = 0;
    for (const key in newToggles) {
      newTotalHeight += newToggles[key] ? toggleHeights[key] : 30;
    }
    editorDiv.style.setProperty(
      'height',
      additionToggles ? `calc(100% - ${newTotalHeight - 4}px)` : '100%'
    );
    setAdditionToggles(newToggles);
  }, [
    eventDetails.attachments.length,
    eventDetails.app.id,
    eventDetails.cardActor.id,
    additionToggles.files,
    additionToggles.scripts,
    additionToggles.actors,
  ]);

  const handleToggle = () => {
    togglePanel((prevPanel) => !prevPanel);
  };

  const handleCalendarChange = ({ value }) => {
    onChange(id, { startDate: value.startDate });
    setTimeout(() => onChange(id, { endDate: value.endDate }), 0);
  };

  /**
   * Выбор/удаление скрипта в событии
   */
  const handleAppChange = (value) => {
    setEventDetails((prevDetails) => ({
      ...prevDetails,
      app: value?.app || {},
      appSettings: value?.appSettings || null,
    }));
    onChange(id, {
      appId: value?.appId || null,
      appSettings: value?.appSettings || null,
    });
  };

  /**
   * Выбор/удаление карточки актора в событии
   */
  const handleActorCardChange = (value) => {
    setEventDetails((prevDetails) => ({
      ...prevDetails,
      cardActor: value || {},
    }));
    onChange(id, { cardActorId: value ? value.id : null });
  };

  /**
   * Обработка изменения полей актора
   */
  const handleOnChange = ({ id: key, value }) => {
    setEventDetails((prevDetails) => ({
      ...prevDetails,
      [key]: value,
    }));
    if (key === 'app') {
      handleAppChange(value);
    } else if (key === 'cardActor') {
      handleActorCardChange(value);
    } else {
      onChange(id, { [key]: value });
    }
  };

  const handleUploadFiles = ({ value }, addBbCode) => {
    setLoading(true);
    dispatch({
      type: UPLOAD_FILE.REQUEST,
      payload: {
        files: value,
        withFileFilter: true,
        callback: (files) => {
          setEventDetails((prevDetails) => {
            const newAttachments = prevDetails.attachments.slice();
            newAttachments.unshift(...files);
            onChange(id, { attachments: newAttachments });
            return {
              ...prevDetails,
              attachments: newAttachments,
            };
          });
          setLoading(false);
          if (!addBbCode) return;

          const descriptionInBbCodeWithImages =
            AppUtils.getDescriptionInBbCodeWithImages(descrRef.current, files);

          setTimeout(
            () =>
              handleOnChange({
                id: 'description',
                value: descriptionInBbCodeWithImages,
              }),
            10
          );
          setTimeout(
            () =>
              AppUtils.scrollToElementEndSetCursor(
                files[files.length - 1]?.fileName
              ),
            200
          );
        },
      },
    });
  };

  /**
   * Обработка открытия панелей прикрепления файлов, акторов и скриптов
   */
  const handleAdditionsToggle = ({ key, value }) => {
    const newHeights = { ...additionToggles };
    newHeights[key] = value;
    setAdditionToggles(newHeights);
  };

  /**
   * Открыть модалку для шаринга актора
   */
  const openAccessRulesModal = () => {
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'ManageAccessRules',
        data: {
          objType: 'actor',
          rules: access,
          ownerId,
        },
        callback: (data) => onChange(id, { access: data }),
      },
    });
  };

  useEffect(() => {
    if (
      !eventDetails.appSettings?.users?.length &&
      !eventDetails.appSettings?.groups?.length
    )
      return;

    // Check if there is any users or groups that should be removed from appSettings
    const leftUsers = (eventDetails.appSettings?.users || []).filter((userId) =>
      access.find((item) => item.userId === userId)
    );
    const leftGroups = (eventDetails.appSettings?.groups || []).filter(
      (userId) => access.find((item) => item.userId === userId)
    );

    handleAppChange({
      app: eventDetails.app,
      appId: eventDetails.app.id,
      appSettings: {
        ...eventDetails.appSettings,
        ...(leftUsers.length ? { users: leftUsers } : {}),
        ...(leftGroups.length ? { groups: leftGroups } : {}),
      },
    });
  }, [access]);

  const renderContent = () => {
    const { title, actors } = eventDetails;
    const errorList = Object.values(errors);
    const MIN_DATE = DateUtils.unixtime();
    const MAX_DATE = DateUtils.dateToUnixtime(
      DateUtils.startOf(
        new Date(new Date().setFullYear(new Date().getFullYear() + 10)),
        'day'
      )
    );
    const DEFAULT_DATE = DateUtils.todayEnd();

    return (
      <>
        <Header title={title} ownerAvatar={ownerAvatar} ownerName={ownerName} />
        <div styleName="event__content">
          <div styleName="event__rows">
            <div styleName="event__row">
              <div styleName="event__people" onClick={openAccessRulesModal}>
                <People access={access} />
              </div>
            </div>
            <div styleName="event__row">
              <TextField
                id="endDate"
                styleName="event__title"
                size="large"
                value={AppUtils.getTextDate(startDate, endDate)}
                error={errorList.includes('noEndDate')}
                calendar={() => (
                  <Calendar
                    size="small"
                    dateRange={startDate !== endDate}
                    options={['dateRange']}
                    time={true}
                    timeZone={false}
                    updateOnInputChange
                    minDate={MIN_DATE}
                    maxDate={MAX_DATE}
                    value={{
                      startDate: startDate || DEFAULT_DATE,
                      endDate: endDate || DEFAULT_DATE,
                    }}
                    onChange={handleCalendarChange}
                  />
                )}
              />
            </div>
            <div styleName="event__row">
              <TextField
                id="title"
                styleName="event__title"
                placeholder={t(mes.title)}
                type="text"
                error={errorList.includes('noTitle')}
                value={title || ''}
                onChange={handleOnChange}
              />
            </div>
          </div>
          <div className={cn(scss.event__main, { [scss.loading]: isLoading })}>
            <Description
              refEdit={descrRef}
              description={eventDetails.description}
              attendees={access}
              error={errorList.includes('noDescription')}
              onChange={handleOnChange}
              onUpload={handleUploadFiles}
            />
            <Additions
              {...eventDetails}
              access={access}
              openPanel={openPanel}
              onChange={handleOnChange}
              onToggle={handleAdditionsToggle}
            />
            <Actors
              actors={actors}
              openPanel={openPanel}
              onToggle={handleToggle}
              onChange={handleOnChange}
            />
            {isLoading ? (
              <Stack
                styleName="event__main__loader"
                alignItems="center"
                justifyContent="center"
                fullWidth
                fullHeight
              >
                <ProgressBar size="large" />
              </Stack>
            ) : null}
          </div>
          <Controls
            access={access}
            onChange={handleOnChange}
            onUpload={handleUploadFiles}
            additionalControls={additionalControls}
          />
        </div>
      </>
    );
  };

  return (
    <div ref={cardBox} styleName="event__wrap">
      <div id="eventCard" styleName={cn('event', { error: errors.length })}>
        {renderContent()}
      </div>
    </div>
  );
}

Card.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  description: PropTypes.string,
  title: PropTypes.string,
  endDate: PropTypes.number,
  startDate: PropTypes.number,
  ownerId: PropTypes.number,
  errors: PropTypes.array,
  actors: PropTypes.array,
  attachments: PropTypes.array,
  access: PropTypes.array,
  ownerAvatar: PropTypes.string,
  ownerName: PropTypes.string,
  isLoading: PropTypes.bool,
  setLoading: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  additionalControls: PropTypes.node,
};

export default Card;
