import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  actorName: {
    id: 'actorName',
    defaultMessage: 'Actor name',
  },
  formName: {
    id: 'formName',
    defaultMessage: 'Form name',
  },
  accountName: {
    id: 'accountName',
    defaultMessage: 'Name',
  },
  currencyName: {
    id: 'currencyName',
    defaultMessage: 'Currency',
  },
  itemsSelected: {
    id: 'itemsSelected',
    defaultMessage: '{count} items selected',
  },
  allActorsSelected: {
    id: 'allActorsSelected',
    defaultMessage: 'All items ({count}) selected',
  },
  openAccount: {
    id: 'openAccount',
    defaultMessage: 'Open account',
  },
  deselectAll: {
    id: 'deselectAll',
    defaultMessage: 'Deselect items',
  },
  selectAllOnPage: {
    id: 'selectAllOnPage',
    defaultMessage: 'Select all items ({count}) on page',
  },
  selectAllActors: {
    id: 'selectAllActors',
    defaultMessage: 'Select all items ({count})',
  },
  bulkLimitExceeded: {
    id: 'bulkLimitExceeded',
    defaultMessage:
      'Maximum number of items for bulk actions [{limit}] exceeded.',
  },
});

export default { ...globalIntl, ...m };
