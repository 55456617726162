import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { cr } from 'mw-style-react';
import { SET_MODAL } from 'constants';
import { useIntl, useSearchList } from 'hooks';
import {
  GET_ACCOUNT_NAMES,
  GET_ACCOUNT_NAME,
  SEARCH_ACCOUNT_NAME,
  CREATE_ACCOUNT_NAME,
  NONE_VALUE,
} from '../../constants/actorAccounts';
import SelectEntityField from '../SelectEntityField';
import mes from './intl';

/**
 * Поиск наименований счетов
 */
function SelectAccountName(props) {
  const { exclude = [], allowNoneValue = false } = props;
  const t = useIntl();
  const dispatch = useDispatch();
  const [stateValue, setStateValue] = useState(props.value);
  const [focused, setFocused] = useState(true);

  useEffect(() => {
    const { value } = props;
    if (value?.value && !value.title) {
      dispatch({
        type: GET_ACCOUNT_NAME.REQUEST,
        payload: value.id,
        callback: (data) =>
          setStateValue({ value: data.id, title: data.title || data.name }),
      });
    }
  }, []);

  const {
    list: activeList,
    loading,
    makeSearch,
    onScroll,
  } = useSearchList({
    actionType: (params) =>
      cr(
        [params.query?.length, SEARCH_ACCOUNT_NAME.REQUEST],
        [true, GET_ACCOUNT_NAMES.REQUEST]
      ),
    skip: focused,
    searchParams: { query: '' },
    settings: { step: 15 },
    localState: true,
  });

  const handleSearch = (value) => {
    makeSearch({ query: value.trimStart() });
  };

  /**
   * Создать новое наим.счета
   */
  const handleCreateCurrency = (name) => {
    dispatch({
      type: CREATE_ACCOUNT_NAME.REQUEST,
      payload: name,
      callback: (accName) => {
        setStateValue(accName);
        props.onChange(accName);
      },
    });
  };

  const handleCreateClick = ({ value }) => {
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'SaveWithName',
        data: {
          name: value || '',
          title: t(mes.createAccName),
          btnText: t(mes.save),
          minLength: 1,
        },
        callback: (newName) => handleCreateCurrency(newName),
      },
    });
  };

  const availableList = activeList.filter(
    (i) => !exclude.includes(i.id.toString())
  );
  const itemsList = allowNoneValue
    ? [NONE_VALUE, ...availableList]
    : availableList;

  return (
    <SelectEntityField
      {...props}
      objType="accountName"
      list={itemsList}
      onSearch={handleSearch}
      onFocus={() => setFocused(false)}
      onSearchListScroll={onScroll}
      onChange={({ value }) => {
        setStateValue(value);
        props.onChange(value);
      }}
      value={stateValue}
      onCreate={handleCreateClick}
      isLoading={loading}
    />
  );
}

SelectAccountName.propTypes = {
  value: PropTypes.object,
  exclude: PropTypes.array,
  allowNoneValue: PropTypes.bool,
};

export default SelectAccountName;
