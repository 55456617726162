import { createSelector } from '@reduxjs/toolkit';
import { PERMISSIONS } from '@control-front-end/common/constants/permissions';

export const canViewUsers = createSelector(
  [(state) => state.auth.workspaces, (state) => state.accounts.active],
  (userWs, activeWs) => {
    const permissions = userWs[activeWs].permissions;
    return Boolean(permissions[PERMISSIONS.USERS_READONLY]);
  }
);
