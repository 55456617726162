import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Space,
  Stack,
  Checkbox,
  Icon,
  Label,
  ProgressBar,
  TextField,
  Popover,
  cr,
} from 'mw-style-react';
import { useIntl, useSearchList } from 'hooks';
import EmptyList from '@control-front-end/common/components/EmptyList';
import {
  GET_TEMPLATE_ACTORS,
  SEARCH_ACTORS,
} from '@control-front-end/common/constants/graphActors';
import mes from './intl';
import scss from './CheckboxSelectActors.scss';

/**
 * Popup with selectable actors list
 * is used to mark as starred event stream or actors filters (for Actors Bag, Transactions and Transfers)
 */
function CheckboxSelectActors(props) {
  const { formId, starredActors, defaultListActors = [], handleManage } = props;
  const t = useIntl();
  const [skipSearch, setSkipSearch] = useState(true);

  const {
    list: actors,
    loading,
    searchParams: { query },
    makeSearch,
    onScroll,
  } = useSearchList({
    actionType: ({ query }) =>
      query ? SEARCH_ACTORS.REQUEST : GET_TEMPLATE_ACTORS.REQUEST,
    skip: skipSearch,
    searchParams: {
      formId,
      params: { formId },
      orderBy: 'created_at',
      orderValue: 'ASC',
      query: '',
    },
    settings: { step: 10 },
    localState: true,
  });

  const handleSearch = ({ value }) => {
    makeSearch({ query: value.trim() });
  };

  const defaultList = useMemo(() => {
    return query
      ? defaultListActors.filter((i) =>
          i.title.toLowerCase().includes(query.toLowerCase())
        )
      : defaultListActors;
  }, [query, defaultListActors]);

  const renderActorsList = () => {
    const starredIds = starredActors.map((i) => i.id);
    const list = [...defaultList, ...actors];
    return cr(
      [
        list.length,
        list.map((i) => (
          <Checkbox
            key={i.id}
            id={i.id}
            value={starredIds.includes(i.id)}
            onChange={({ value }) => handleManage(i, value)}
            visibility={i.disabled ? 'disabled' : 'visible'}
          >
            <Label value={i.title} overflow="cut" />
          </Checkbox>
        )),
      ],
      [true, <EmptyList size="small" objType="stream" />]
    );
  };

  return (
    <Popover
      topLevel
      anchors={{
        binding: Popover.ANCHOR.right_top,
        content: Popover.ANCHOR.right_top,
      }}
      content={
        <Card
          style={{ width: 216, height: 286 }}
          color={Card.COLOR.white}
          boxShadow={Card.BOX_SHADOW.light}
          borderRadius={Card.BORDER_RADIUS.large}
        >
          <Space fullHeight fullWidth>
            <Stack.V size={Stack.SIZE.small} fullHeight fullWidth>
              <TextField
                leftIcon="search"
                bordered
                unspaced
                resettable
                value={query}
                placeholder={t(mes.search)}
                onChange={handleSearch}
                onReset={() => handleSearch({ value: '' })}
              />
              <Stack.V
                className={scss.list}
                fullWidth
                fullHeight
                size="none"
                onScroll={onScroll}
              >
                {cr(
                  [
                    loading && !actors.length,
                    <ProgressBar className={scss.loader} />,
                  ],
                  [true, () => renderActorsList()]
                )}
              </Stack.V>
            </Stack.V>
          </Space>
        </Card>
      }
    >
      <Icon
        type="settings"
        className={scss.btn}
        onClick={() => setSkipSearch(false)}
      />
    </Popover>
  );
}

CheckboxSelectActors.propTypes = {
  formId: PropTypes.number.isRequired,
  starredActors: PropTypes.array.isRequired,
  handleManage: PropTypes.func,
};

export default CheckboxSelectActors;
