/**
 * Get hierarchical edges coming out of the nodes
 */
export const getOutputHierarchicalEdges = ({ nodes, edgeTypeId }) =>
  nodes.outgoers(`edge[edgeTypeId = ${edgeTypeId}]`);

/**
 * Get a list of nodes in state, linked with hierarchical edges
 * (because there are other types like transfers edges etc.)
 */
export const getNodesInState = ({ nodes, edgeTypeId }) =>
  getOutputHierarchicalEdges({ nodes, edgeTypeId }).targets();

export default {
  getOutputHierarchicalEdges,
  getNodesInState,
};
