import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { cr } from 'mw-style-react';
import { GRAPH_NODE_SIZE } from '@control-front-end/common/constants/graphActors';
import nodeWidgets from './nodeWidgets';

/**
 * Any widget (defined in nodeWidgets) that could be shown as an expanded node
 */
function NodeWidget({ name, opened: openedProp, ...rest }) {
  const [opened, setOpened] = useState(openedProp ?? true);

  const Widget = nodeWidgets[name];

  return cr(
    [Widget && opened, <Widget onToggle={setOpened} {...rest} />],
    [
      true,
      <div
        onClick={() => setOpened(true)}
        style={{
          width: `${GRAPH_NODE_SIZE}px`,
          height: `${GRAPH_NODE_SIZE}px`,
        }}
      />,
    ]
  );
}

NodeWidget.propTypes = {
  name: PropTypes.string.isRequired,
  opened: PropTypes.bool,
};

export default NodeWidget;
