import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Icon } from 'mw-style-react';
import useGridExpNodesSnap from '@control-front-end/app/src/components/GraphEngine/useGridExpNodesSnap';
import { GET_ACTOR_VIEW_DATA } from '@control-front-end/common/constants/graphActors';
import { ActorCardView } from 'components';
import './ExpandedActorCardView.scss';

function ExpandedActorCardView({
  el,
  actor,
  graph,
  handleSaveActorLayerSettings,
}) {
  const dispatch = useDispatch();
  const rootRef = useRef();
  const dragRef = useRef();
  const [position, setPosition] = useState(el.position());
  const actorId = el.data('actorId');
  const layerSettings = el.data('layerSettings') || {};

  const [viewData, setViewData] = useState(null);

  const handleLoadViewData = useCallback(() => {
    dispatch({
      type: GET_ACTOR_VIEW_DATA.REQUEST,
      payload: { id: actorId },
      callback: (data) => setViewData(data),
    });
  }, [actorId]);

  useEffect(() => {
    if (actorId) {
      handleLoadViewData();
    }
  }, [actorId]);

  const handleMoveNavigator = () => {
    setPosition({ ...el.position() });
  };

  const handleOnMoveUp = () => {
    setPosition({ ...el.position() });
  };

  const handleOnMoveDown = () => {
    const expN = document.querySelectorAll('.nl');
    expN.forEach((node) => node.style.removeProperty('z-index'));
    rootRef.current.style['z-index'] = 1;
  };

  useGridExpNodesSnap({
    cy: graph,
    node: el,
    ref: dragRef,
    isDraggable: !layerSettings.pin,
    onDrag: handleMoveNavigator,
    onUp: handleOnMoveUp,
    onDown: handleOnMoveDown,
  });

  return (
    <div
      style={{
        position: 'absolute',
        transform: `translate(-50%, -50%) translate(${position.x}px, ${position.y}px)`,
      }}
      ref={rootRef}
      className="nl"
    >
      <div styleName="nl__header__actions">
        <div
          styleName="nl__header__item"
          onClick={(e) => {
            e.stopPropagation();
            handleLoadViewData();
          }}
        >
          <Icon type="repeat" />
        </div>
        <div
          styleName="nl__header__item"
          onClick={(e) => {
            e.stopPropagation();
            handleSaveActorLayerSettings({
              id: el.id(),
              settings: { isCardView: false, expand: false },
            });
          }}
        >
          <Icon type="collapse" />
        </div>
      </div>
      <div ref={dragRef} styleName="nl__content">
        <ActorCardView
          loading={Boolean(!viewData || !actor)}
          actor={actor}
          viewData={viewData}
        />
      </div>
    </div>
  );
}

ExpandedActorCardView.propTypes = {
  el: PropTypes.object.isRequired,
  graph: PropTypes.object,
  handleSaveActorLayerSettings: PropTypes.func.isRequired,
};

export default ExpandedActorCardView;
