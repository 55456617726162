import { takeEvery, select, put, call } from 'redux-saga/effects';
import {
  COMMUNICATIONS_PUBLISH,
  COMMUNICATIONS_ACTORS,
  COMMUNICATIONS_CONNECTED,
  COMMUNICATIONS_CONNECT,
} from '@control-front-end/common/constants/communications';
import api from '@control-front-end/common/sagas/api';
import { RequestStatus } from 'constants';
import { getActorViewData } from './graph/graphNodes';

function* communicationsPublish({ payload, callback }) {
  const { id, status } = payload;

  const viewData = yield getActorViewData({
    payload: {
      id,
      status,
    },
  });

  if (!viewData || viewData.error) return viewData;

  const activeAccountId = yield select(
    (state) => state.accounts.activeAccount.id
  );

  const { result, data } = yield call(api, {
    method: 'post',
    url: `/communications/publish/${activeAccountId}`,
    body: { status, viewData },
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({ type: COMMUNICATIONS_PUBLISH.SUCCESS });
  if (callback) callback(data.data);
  return data.data;
}

function* communicationsActors({ payload = {}, callback }) {
  const { search, category, limit, offset, formType } = payload;

  const activeAccId = yield select((state) => state.accounts.activeAccount.id);

  const { result, data } = yield call(api, {
    method: 'get',
    url: `/communications/actors/${activeAccId}`,
    queryParams: {
      formType,
      search,
      category,
      limit,
      offset,
    },
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({ type: COMMUNICATIONS_ACTORS.SUCCESS });
  const actors = data.data.error ? [] : data.data.list;
  callback(actors);
  return actors;
}

function* communicationsConnected({ callback }) {
  const activeAccId = yield select((state) => state.accounts.activeAccount.id);

  const { result, data } = yield call(api, {
    method: 'get',
    url: `/communications/connected/${activeAccId}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({ type: COMMUNICATIONS_ACTORS.SUCCESS });
  callback(data.data.list);
  return data.data.list;
}

function* communicationsConnect({ payload, callback }) {
  const { toRef } = payload;

  const activeAccId = yield select((state) => state.accounts.activeAccount.id);

  const { result, data } = yield call(api, {
    method: 'post',
    url: `/communications/connect/${activeAccId}`,
    body: { toRef },
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({ type: COMMUNICATIONS_ACTORS.SUCCESS });
  callback(data.data);
  return data.data;
}

function* communicationsSaga() {
  yield takeEvery(COMMUNICATIONS_PUBLISH.REQUEST, communicationsPublish);
  yield takeEvery(COMMUNICATIONS_ACTORS.REQUEST, communicationsActors);
  yield takeEvery(COMMUNICATIONS_CONNECTED.REQUEST, communicationsConnected);
  yield takeEvery(COMMUNICATIONS_CONNECT.REQUEST, communicationsConnect);
}

export default communicationsSaga;
