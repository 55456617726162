import { call, put, take, takeLatest } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import {
  PM_START,
  PM_SEND,
  PM_INIT_OPTIONS,
  APP_AUTH_START,
  PM_APP_NAME,
} from 'constants';

// Create a channel to read postMessage events
function createChannel() {
  return eventChannel((emitter) => {
    const handler = (e) => {
      if (e.data.appName !== PM_APP_NAME) return;
      const { type, payload } = e.data;
      emitter({ type, payload });
    };
    window.addEventListener('message', handler);
    return () => {
      window.removeEventListener('message', handler);
    };
  });
}

// Send message to parent script loader
function send({ payload }) {
  if ('parent' in window) {
    // eslint-disable-next-line
    parent.postMessage(payload, '*');
  }
}

// Start processing postMessage events
function* start({ payload }) {
  const channel = yield call(createChannel, payload);
  while (true) {
    // eslint-disable-line no-constant-condition
    try {
      const action = yield take(channel);
      yield put(action);
    } catch (e) {
      break;
    }
  }
}

// Starting the application after receiving settings via postMessage
function* startApp() {
  yield put({ type: APP_AUTH_START.REQUEST });
}

function* postMessage() {
  yield takeLatest(PM_START, start);
  yield takeLatest(PM_SEND, send);
  yield takeLatest(PM_INIT_OPTIONS, startApp);
}

export default postMessage;
