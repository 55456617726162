import { defineMessages } from 'react-intl';

const m = defineMessages({
  rulesMapping: {
    id: 'rulesMapping_ImportSettingsModal',
    defaultMessage: 'Import Settings',
  },
  proceed: {
    id: 'proceed_ImportSettingsModal',
    defaultMessage: 'Proceed',
  },
});

export default m;
