import { call, fork, take } from 'redux-saga/effects';

import {
  keyByPosition,
  snapGeomCoord,
} from '@control-front-end/utils/modules/utilsCellCoords';

/**
 * Creates a saga that listens for actions and executes the provided saga function
 * only if the `canExecute` function returns `true` for the previous and current actions.
 *
 * @param {string|Channel} patternOrChannel - The pattern or channel to listen for actions.
 * @param {function} saga - The saga function to execute for each action.
 * @return {Task} The forked saga.
 */
function takeLeadingBy(patternOrChannel, saga) {
  return fork(function* forkName() {
    const runningActionsPosition = {};

    while (true) {
      const action = yield take(patternOrChannel);

      const currentRunningPositionKey = action?.payload?.position
        ? keyByPosition(snapGeomCoord(action?.payload?.position))
        : null;

      /**
       * TODO: Rewrite as multypurpose action-creator with callback argument "canExecute"
       * Currently it's used just for CREATE_ACTOR.REQUEST saga to avoid nodes creation at the same cell
       */
      if (
        currentRunningPositionKey &&
        runningActionsPosition[currentRunningPositionKey]
      ) {
        continue;
      }

      // eslint-disable-next-line no-loop-func
      yield fork(function* forkCall() {
        try {
          runningActionsPosition[currentRunningPositionKey] = true;
          yield call(saga, action);
        } finally {
          delete runningActionsPosition[currentRunningPositionKey];
        }
      });
    }
  });
}

export default takeLeadingBy;
