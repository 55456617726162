import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'mw-style-react';
import Toggle from '@control-front-end/common/components/Toggle';
import cn from 'classnames';
import './Section.scss';

function Section({
  title,
  sectionId,
  contentItems,
  hidden,
  openSomeSections,
  openAllSections,
  error,
  hideSectionContent,
  toggleWrap,
}) {
  const [isToggle, setToggle] = useState(openSomeSections || openAllSections);
  const [allSectionsOpened, setAllSectionsOpened] = useState(false);
  const section = (
    <div id={`section_${sectionId}`} styleName={cn('section', { hidden })}>
      {!hideSectionContent ? (
        <div styleName="section__content">{contentItems}</div>
      ) : (
        <div styleName="section__content" style={{ display: 'none' }}>
          {contentItems}
        </div>
      )}
    </div>
  );

  useEffect(() => {
    setToggle(openSomeSections);
  }, [openSomeSections]);

  useEffect(() => {
    if (openAllSections) {
      setToggle(openAllSections);
      setAllSectionsOpened(true);
    }
    if (!openAllSections && allSectionsOpened) {
      setToggle(false);
      setAllSectionsOpened(false);
    }
  }, [openAllSections]);

  const handleToggleChange = (value) => {
    setToggle(value);
  };

  return toggleWrap ? (
    <Toggle
      id={`toggle_${sectionId}`}
      theme="dark"
      children={section}
      title={title}
      isOpen={isToggle}
      rightComponent={
        error ? (
          <div styleName="section__error">
            <Icon type="alert" />
          </div>
        ) : null
      }
      onChange={handleToggleChange}
      visible={!hidden}
    />
  ) : (
    section
  );
}

Section.propTypes = {
  title: PropTypes.string,
  sectionId: PropTypes.string,
  contentItems: PropTypes.array.isRequired,
  hideSectionContent: PropTypes.bool.isRequired,
  openSomeSections: PropTypes.bool,
  openAllSections: PropTypes.bool,
  hidden: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  toggleWrap: PropTypes.bool,
};

export default Section;
