import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  meetWith: {
    id: 'meetWith__eventsCalendar',
    defaultMessage: 'Meet with',
  },
  search: {
    id: 'search__eventsCalendar',
    defaultMessage: 'Search for people',
  },
});

export default { ...globalIntl, ...m };
