import { useState, useEffect } from 'react';

/**
 * Хук useDelayLoader
 */
const useDelayLoader = (initState = false, opt = { delay: 500 }) => {
  const [isLoaded, setL] = useState(initState);
  let timer = null;

  useEffect(
    () => () => {
      if (timer) clearTimeout(timer);
    },
    []
  );

  const setLoader = (state) => {
    if (timer) clearTimeout(timer);
    if (state === true) {
      setL(state);
      return;
    }
    timer = setTimeout(() => setL(state), opt.delay);
  };

  return [isLoaded, setLoader];
};

export default useDelayLoader;
