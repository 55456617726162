import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  transferAndReplace: {
    id: 'transferAndReplace',
    defaultMessage: 'Transfer and replace',
  },
  transferAccountInfo: {
    id: 'transferAccountInfo',
    defaultMessage:
      // eslint-disable-next-line max-len
      'Select actor to transfer accounts {account}. These accounts will be removed from {actorTitle} and added to the selected actor.',
  },
  transferBannerError: {
    id: 'transferBannerError',
    defaultMessage:
      // eslint-disable-next-line max-len
      'Selected actor already has accounts with the same name {accountName} and currency {currencyName}. Are you sure you want to replace it?',
  },
  transferredSuccessfully: {
    id: 'transferredSuccessfully',
    defaultMessage: 'Account transferred successfully',
  },
  cantTransferAccount: {
    id: 'cantTransferAccount',
    defaultMessage:
      'There is no access to edit actor, account transfer is not available',
  },
});

export default { ...globalIntl, ...m };
