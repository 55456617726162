import { UUID } from '@control-front-end/common/constants/regExp';
import { ACTORS_COLORS_PALETTE } from '@control-front-end/common/constants/graphActors';

const MAX_UINT32 = 4294967295;

export default {
  // Safe pseudo random number generator
  // https://rules.sonarsource.com/javascript/RSPEC-2245/
  safeRandom() {
    const crypto = window.crypto || window.msCrypto;
    const array = new Uint32Array(1);
    crypto.getRandomValues(array);
    // Divide a random UInt32 by the maximum value (2^32 -1) to get a result between 0 and 1
    return array[0] / MAX_UINT32;
  },

  // udid generation
  udid() {
    return Date.now().toString() + Math.floor(this.safeRandom() * 10000 + 1);
  },

  isUuid(str) {
    const r = new RegExp(UUID, 'i');
    return r.test(str);
  },

  // Генерация rid
  createRid() {
    const min = 1;
    const max = 100000;
    const time = new Date().getTime();
    const random = Math.floor((this.safeRandom() * (max - min) + min) * 10000);
    return `${time}_${random}`;
  },

  // Уникальный id для узла
  guid() {
    const s4 = () => {
      return Math.floor((1 + this.safeRandom()) * 0x10000)
        .toString(16)
        .substring(1);
    };

    return `tmp_${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  },

  getRandomPastelColor() {
    const randomChannel = () => {
      return (Math.round(this.safeRandom() * 127) + 127).toString(16);
    };
    const r = randomChannel();
    const g = randomChannel();
    const b = randomChannel();
    return `#${r}${g}${b}`;
  },

  getRandomActorsColor() {
    const colorsList = Object.values(ACTORS_COLORS_PALETTE);
    const randomIndex = Math.floor(this.safeRandom() * colorsList.length);
    return colorsList[randomIndex];
  },
};
