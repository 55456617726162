import { createReducer } from '@reduxjs/toolkit';
import { CREATE_DELAYED_ACTION, CLEAR_DELAYES_ACTION } from 'constants';

// ------------------------------------
// Reducer
// ------------------------------------
const initState = {};

export default createReducer(initState, {
  [CREATE_DELAYED_ACTION](state, action) {
    const updates = {
      ...state,
      [action.payload.action]: action.payload,
    };
    return {
      ...state,
      ...updates,
    };
  },
  [CLEAR_DELAYES_ACTION]() {
    return initState;
  },
});
