import globalIntl from 'globalIntl';
import { defineMessages } from 'react-intl';

const m = defineMessages({
  more: {
    id: 'more__ExpandedChart',
    defaultMessage: 'More',
  },
  collapse: {
    id: 'collapse__ExpandedChart',
    defaultMessage: 'Collapse',
  },
});

export default { ...globalIntl, ...m };
