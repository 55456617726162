import { SET_SYSTEM_FORMS } from '@control-front-end/common/constants/forms';
import { createReducer } from '@reduxjs/toolkit';
// ------------------------------------
// Reducer
// ------------------------------------
const initState = {};

export default createReducer(initState, {
  [SET_SYSTEM_FORMS](state, action) {
    return action.payload;
  },
});
