import React from 'react';
import { useDispatch } from 'react-redux';
import { SET_MODAL } from 'constants';
import { Icon, Label, Stack, Space } from 'mw-style-react';
import FormUtils from '@control-front-end/utils/formUtils';
import AppUtils from '@control-front-end/utils/utils';
import history from '@control-front-end/app/src/store/history';
import Block from './Block';
import scss from './ActorCardView.scss';

const useShowPreview = (file) => {
  const dispatch = useDispatch();
  return () =>
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'FilePreview',
        data: {
          id: file.id,
          fileName: file.fileName,
          files: [file],
        },
      },
    });
};

function FilePreview({ file }) {
  const showPreview = useShowPreview(file);
  const { title: fileTitle, type: fileType, fileName } = file;
  const filePath = AppUtils.makeAppUrl(`/download/${fileName}`);
  const isImage = fileType && fileType.indexOf('image') !== -1;
  return (
    <Stack horizontal size="xxsmall" onClick={showPreview}>
      {isImage ? <img src={filePath} alt="" /> : <Icon type="file" />}
      <Label value={fileTitle} />
    </Stack>
  );
}

// eslint-disable-next-line no-unused-vars
const uploadedFileValue = (item) => {
  const filesList = Array.isArray(item.value) ? item.value : [];
  return filesList.map((file) => <FilePreview file={file} />);
};

// eslint-disable-next-line no-unused-vars
const imageValue = (item) => {
  const file = {
    id: item.id,
    type: 'image',
    filePath: item.value,
    fileName: item.title,
  };

  const showPreview = useShowPreview(file);
  return (
    <img
      style={{ height: '100%' }}
      src={item.value}
      alt={item.title}
      onClick={showPreview}
    />
  );
};

// TOMAKE: Removed after "class" field will be added to the API /actors_graph/view_data/actorId
const checkFieldClass = (field) => {
  if (typeof field.value === 'string') {
    return 'edit';
  }
  if (field.value?.endDate && field.value?.startDate) {
    return 'calendar';
  }
  if (typeof field.value === 'boolean') {
    return 'check';
  }
  if (Array.isArray(field.value)) {
    return 'select';
  }
  return null;
};

function FieldValueNode({ field, accId }) {
  let value;
  const fieldClass = field.class || checkFieldClass(field);

  // TOMAKE: Uncomment after value will be added for this fields
  // if (field.class === 'upload') {
  //   value = uploadedFileValue(field);
  // } else if (field.class === 'image') {
  //   value = imageValue(field);

  if (fieldClass === 'check') {
    value = `${field.title}: ${
      FormUtils.getFormItemValue({ ...field, class: fieldClass }, accId, {
        interactiveChips: false,
      }) || '-'
    }`;
  } else {
    value =
      FormUtils.getFormItemValue({ ...field, class: fieldClass }, accId, {
        interactiveChips: false,
      }) || '-';
  }

  return (
    <Stack fullWidth horizontal size="small" alignItems="center">
      {field.icon ? (
        <div className={scss.fieldIcon}>
          <span>{field.icon}</span>
        </div>
      ) : null}
      {Array.isArray(value) ? (
        <div className={scss.valuesList}>{value}</div>
      ) : (
        <Label className={scss.textBlock} fontWeight="semibold" value={value} />
      )}
    </Stack>
  );
}

function FormsFields({ formsFields, design }) {
  const accId = AppUtils.getAccountId(history.location.pathname);
  return (
    <Stack size={Stack.SIZE.xxsmall}>
      {formsFields.map((field, index) => (
        <Block key={index} design={design}>
          <Space size="small">
            <FieldValueNode field={field} accId={accId} />
          </Space>
        </Block>
      ))}
    </Stack>
  );
}

export default FormsFields;
