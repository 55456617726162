import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import useIntl from 'useIntl';
import mes from './intl';
import './DetailsList.scss';

function DetailsList({ accounts, currencyName }) {
  const t = useIntl();

  return (
    <Table styleName="table" paddingSize="small">
      <TableHead fixed={true}>
        <TableRow styleName="table__row">
          <TableCell styleName="table__cell actor">
            {t(mes.actorName)}
          </TableCell>
          <TableCell styleName="table__cell">{t(mes.debit)}</TableCell>
          <TableCell styleName="table__cell">{t(mes.credit)}</TableCell>
          <TableCell styleName="table__cell">{t(mes.total)}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {accounts.map((item) => (
          <TableRow styleName="table__row" key={item.id}>
            <TableCell styleName="table__cell actor">
              <div styleName="table__cell actor__title">{item.title}</div>
            </TableCell>
            <TableCell styleName="table__cell">
              {`${AppUtils.simpleFormattedAmount(item.debit.amount, {
                type: item.debit.currencyType,
                precision: item.debit.precision,
              })} ${currencyName}`}
            </TableCell>
            <TableCell styleName="table__cell">
              {`${AppUtils.simpleFormattedAmount(item.credit.amount, {
                type: item.debit.currencyType,
                precision: item.debit.precision,
              })} ${currencyName}`}
            </TableCell>
            <TableCell styleName="table__cell">
              {`${AppUtils.simpleFormattedAmount(
                item.credit.amount - item.debit.amount,
                {
                  type: item.debit.currencyType,
                  precision: item.debit.precision,
                }
              )} ${currencyName}`}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

DetailsList.propTypes = {
  accounts: PropTypes.array,
  currencyName: PropTypes.string,
};

export default DetailsList;
