import { defineMessages } from 'react-intl';

const m = defineMessages({
  myProfile: {
    id: 'myProfile_myVerse',
    defaultMessage: 'My Profile',
  },
  documentation: {
    id: 'documentation_myVerse',
    defaultMessage: 'Documentation',
  },
  graphs: {
    id: 'graphs_myVerse',
    defaultMessage: 'Graphs',
  },
  actorBag: {
    id: 'actorBag_myVerse',
    defaultMessage: 'Actor Bag',
  },
  accounts: {
    id: 'accounts_myVerse',
    defaultMessage: 'Accounts',
  },
  workspaces: {
    id: 'workspaces_myVerse',
    defaultMessage: 'Workspaces',
  },
  connections: {
    id: 'connections_myVerse',
    defaultMessage: 'Connections',
  },
  events: {
    id: 'events_myVerse',
    defaultMessage: 'Events',
  },
  accesses: {
    id: 'accesses_myVerse',
    defaultMessage: 'Accesses',
  },
  more: {
    id: 'more_myVerse',
    defaultMessage: 'More',
  },
  loading: {
    id: 'loading_myVerse',
    defaultMessage: 'Loading...',
  },
});

export default m;
