import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useIntl, useNotifications, useSearchList } from 'hooks';
import { Stack, TextField, ProgressBar, DateUtils, cr } from 'mw-style-react';
import {
  NOTIFY_LEVEL,
  FILTER_HISTORY,
} from '@control-front-end/common/constants';
import {
  GET_TRANSACTIONS,
  SEARCH_TRANSACTIONS,
  CREATE_TRANSACTION,
  GET_COORDINATES_TRANSACTIONS,
  GET_ACCOUNTS_ROLES_TRANSACTIONS,
  TRANSACTION_TYPES,
  ACCOUNT_TYPE,
} from '@control-front-end/common/constants/actorAccounts';
import { MAX_FILTER_PERIOD } from '@control-front-end/common/constants/transactionsFilters';
import EmptyList from '@control-front-end/common/components/EmptyList';
import TransactionsFiltersOptions from '../../../../TransactionsFiltersOptions';
import TransactionsList from './components/TransactionsList';
import scss from './Transactions.scss';
import mes from './intl';

const initState = {
  list: [],
  limit: 15,
  offset: 0,
  endList: false,
};

const MIN_QUERY_LENGTH = 1;

const getDefaultRange = ({ credit, debit }) => {
  const maxUpdatedAt =
    (credit && debit
      ? Math.max(
          credit.updatedAt || credit.createdAt,
          debit.updatedAt || credit.updatedAt
        )
      : DateUtils.unixtime()) * 1000;
  const from = DateUtils.startOf(maxUpdatedAt, 'month').getTime();
  const to = DateUtils.endOf(maxUpdatedAt, 'month').getTime();
  return { from, to };
};

function Transactions(props) {
  const {
    actorId,
    actorTitle,
    laId,
    nameId,
    accountName,
    currencyId,
    currencyName,
    accountType,
    accKey,
    debit,
    credit,
    readOnly,
    canEditAccount = true,
    formField,
    type: transactionType = ACCOUNT_TYPE.number,
  } = props;
  const t = useIntl();
  const dispatch = useDispatch();
  const isFirstRenderRef = useRef(true);
  const [transactionsState, setTransactionsState] = useState(initState);
  const [query, setQuery] = useState('');
  const [filter, setFilter] = useState({
    accountType,
    incomeType: null,
    accounts: [
      {
        nameId,
        accountName,
        currencyId,
        currencyName,
      },
    ],
    ...getDefaultRange({ credit, debit }),
    name: nameId,
  });
  const { currencyType: type, precision, symbol } = debit || {};
  const currencyParams = { type, precision, symbol };
  const { from, to, incomeType } = filter;
  const { showNotification } = useNotifications();

  const {
    list: transactionsList,
    loading,
    makeSearch,
    onScroll,
  } = useSearchList({
    actionType: cr(
      [query.length >= MIN_QUERY_LENGTH, SEARCH_TRANSACTIONS.REQUEST],
      [transactionType === ACCOUNT_TYPE.formField, FILTER_HISTORY.REQUEST],
      [
        transactionType === ACCOUNT_TYPE.coordinates,
        GET_COORDINATES_TRANSACTIONS.REQUEST,
      ],
      [
        transactionType === ACCOUNT_TYPE.roles,
        GET_ACCOUNTS_ROLES_TRANSACTIONS.REQUEST,
      ],
      [true, GET_TRANSACTIONS.REQUEST]
    ),
    searchParams: {},
    skip: transactionType === ACCOUNT_TYPE.formField && !from,
    settings: { step: 15 },
    callback: cr(
      [transactionType === ACCOUNT_TYPE.formField, undefined],
      [
        query.length >= MIN_QUERY_LENGTH,
        () => (data) => {
          setTransactionsState({ ...initState, list: data });
        },
      ],
      [
        true,
        () => (data) => {
          setTransactionsState(data);
        },
      ]
    ),
    ...cr(
      [
        query.length >= MIN_QUERY_LENGTH,
        {
          actorId,
          nameId,
          currencyId,
          incomeType,
          accountType,
          q: query,
        },
      ],
      [
        transactionType === ACCOUNT_TYPE.formField,
        {
          formField,
          from,
          to,
          actors: [actorId],
          isFormFields: true,
        },
      ],
      [
        transactionType === ACCOUNT_TYPE.coordinates,
        {
          laId,
          from,
          to,
          pageState: transactionsState.pageState,
        },
      ],
      [
        transactionType === ACCOUNT_TYPE.roles,
        {
          actorId,
          type: props.roleType,
          name: filter.name,
          transactions: transactionsState,
        },
      ],
      [
        true,
        {
          actorId,
          nameId,
          currencyId,
          incomeType,
          accountType,
          transactions: transactionsState,
          onlyRoot: 'false',
          debit,
          credit,
          from,
          to,
        },
      ]
    ),
  });

  useEffect(() => {
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
      return;
    }
    if (!formField) setTransactionsState(initState);
    if (to - from > MAX_FILTER_PERIOD) {
      showNotification(NOTIFY_LEVEL.ERROR, t(mes.rangeExceedsYear));
      return;
    }
    makeSearch();
  }, [actorId, filter]);

  useEffect(() => {
    if (query.length < MIN_QUERY_LENGTH) return;
    makeSearch({ q: query });
  }, [query]);

  /**
   * Clear search and reload
   */
  const clearSearch = () => {
    if (!query.length) return;
    setQuery('');
    makeSearch();
  };

  /**
   * Create corrective transaction
   */
  const handleCorrect = ({ id, incomeType: incType, transaction }) => {
    dispatch({
      type: CREATE_TRANSACTION.REQUEST,
      payload: {
        id,
        key: accKey,
        incomeType: incType,
        accountType,
        transaction,
      },
      callback: () => {
        makeSearch({ query: '' });
      },
    });
  };

  const nonEmptyList = transactionsList.length > 0;
  const listToShow =
    transactionType === ACCOUNT_TYPE.coordinates ||
    transactionType === ACCOUNT_TYPE.roles
      ? transactionsList.map((item) => ({
          ...item,
          amount:
            transactionType === ACCOUNT_TYPE.coordinates
              ? item[`cell${currencyName.toUpperCase()}`]
              : undefined,
          actorTitle,
          type: TRANSACTION_TYPES.COMPLETED,
          handleClick: false,
          handleDetails: false,
        }))
      : transactionsList;

  return (
    <Stack.V
      size={Stack.SIZE.small}
      styleName="transactions"
      fullWidth
      fullHeight
    >
      <Stack.H justifyContent="spaceBetween" fullWidth>
        {cr(
          [
            filter.from,
            <TransactionsFiltersOptions
              value={filter}
              isCustom={false}
              isHistory={true}
              fixedRange={
                !!query.length || transactionType === ACCOUNT_TYPE.roles
              }
              type={transactionType}
              handleChange={setFilter}
            />,
          ],
          [
            transactionType === ACCOUNT_TYPE.number,
            <TextField
              className={scss.search}
              leftIcon="search"
              bordered
              autoSelect
              unspaced
              resettable
              value={query}
              placeholder={t(mes.searchByAmountOrComment)}
              onChange={({ value }) => {
                setQuery(value);
              }}
              onReset={() => clearSearch()}
            />,
          ],
          true
        )}
      </Stack.H>
      <Stack.V
        className={scss.content}
        size={Stack.SIZE.small}
        alignItems={nonEmptyList ? 'stretch' : 'center'}
        justifyContent={loading ? 'center' : 'flexStart'}
        fullWidth
        fullHeight
        onScroll={onScroll}
      >
        {cr(
          [
            !nonEmptyList && loading,
            <ProgressBar type="circle" size="large" />,
          ],
          [
            nonEmptyList,
            <TransactionsList
              debitId={debit?.id}
              creditId={credit?.id}
              transactions={listToShow}
              currencyName={currencyName}
              currencyParams={currencyParams}
              readOnly={readOnly}
              canEditAccount={canEditAccount}
              formField={formField}
              transactionType={transactionType}
              handleCorrect={handleCorrect}
            />,
          ],
          [true, <EmptyList objType="transaction" />]
        )}
        {nonEmptyList && loading ? <ProgressBar /> : null}
      </Stack.V>
    </Stack.V>
  );
}

Transactions.propTypes = {
  actorId: PropTypes.string,
  laId: PropTypes.number,
  nameId: PropTypes.string,
  accountName: PropTypes.string,
  currencyId: PropTypes.string,
  currencyName: PropTypes.string,
  accKey: PropTypes.string,
  debit: PropTypes.object,
  credit: PropTypes.object,
  accountType: PropTypes.oneOf(['fact', 'plan']),
  type: PropTypes.oneOf(Object.values(ACCOUNT_TYPE)),
  readOnly: PropTypes.bool,
};

export default Transactions;
