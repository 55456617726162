import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  actorsTriggers: {
    id: 'actorsTriggers',
    defaultMessage: 'Triggers of {actorTitle}',
  },
  valueType: {
    id: 'valueType',
    defaultMessage: 'Account value type',
  },
  triggerIncomeType: {
    id: 'triggerIncomeType',
    defaultMessage: 'Account income type',
  },
  triggerEndDate: {
    id: 'triggerEndDate',
    defaultMessage: 'Trigger end date',
  },
  upperValue: {
    id: 'upperValue',
    defaultMessage: 'Upper target value',
  },
  lowerValue: {
    id: 'lowerValue',
    defaultMessage: 'Lower target value',
  },
  periodName: {
    id: 'periodName',
    defaultMessage: 'Period name',
  },
  periodValue: {
    id: 'periodValue',
    defaultMessage: 'Period value',
  },
});

export default { ...globalIntl, ...m };
