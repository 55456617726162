import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  collapse: {
    id: 'collapse',
    defaultMessage: 'Collapse',
  },
  flaskView: {
    id: 'flaskView',
    defaultMessage: 'Flask view',
  },
  flaskViewOn: {
    id: 'flaskViewOn',
    defaultMessage: 'Enable flask view',
  },
  flaskViewOff: {
    id: 'flaskViewOff',
    defaultMessage: 'Disable flask view',
  },
  externalLinks: {
    id: 'externalLinks',
    defaultMessage: 'External links',
  },
  showExtLinks: {
    id: 'showExtLinks',
    defaultMessage: 'Show external links',
  },
  hideExtLinks: {
    id: 'hideExtLinks',
    defaultMessage: 'Hide external links',
  },
  maximize: {
    id: 'maximize',
    defaultMessage: 'Maximize',
  },
  minimize: {
    id: 'minimize',
    defaultMessage: 'Minimize',
  },
  showActorsLayer: {
    id: 'showActorsLayer',
    defaultMessage: 'Show actors layer',
  },
  linkedLayers: {
    id: 'linkedLayers',
    defaultMessage: 'Linked layers',
  },
});

export default { ...globalIntl, ...m };
