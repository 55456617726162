import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  noItemsYet: {
    id: 'noItemsYet',
    defaultMessage: 'There are no {type}s yet.',
  },
  companyEmpty: {
    id: 'companyEmpty',
    defaultMessage: 'There are no companies yet.\nPublish your company first.',
  },
  youCanCreate: {
    id: 'youCanCreate',
    defaultMessage: 'You can create a new {type} below.',
  },
  createItem: {
    id: 'createItem',
    defaultMessage: 'New {type}',
  },
  youCanInvite: {
    id: 'youCanInvite',
    defaultMessage: 'You can invite member below.',
  },
  inviteMembers: {
    id: 'inviteMembers',
    defaultMessage: 'Invite members',
  },
  noResultsFound: {
    id: 'noResultsFound',
    defaultMessage: 'No results found for your search.',
  },
  pleaseTryAnotherSearch: {
    id: 'pleaseTryAnotherSearch',
    defaultMessage: 'Please try another search.',
  },
});

export default { ...globalIntl, ...m };
