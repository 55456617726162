import { call, put, select, takeEvery } from 'redux-saga/effects';
import { RequestStatus } from 'constants';
import {
  GET_ACCOUNT_NAMES,
  GET_ACCOUNT_NAME,
  CREATE_ACCOUNT_NAME,
  UPDATE_ACCOUNT_NAME,
  REMOVE_ACCOUNT_NAME,
  SEARCH_ACCOUNT_NAME,
  GET_ACCOUNT_NAME_CURRENCIES,
  GET_ACCOUNT_CURRENCIES,
  SET_ACCOUNT_CURRENCIES_REQ_STATUS,
  SET_ACCOUNT_NAMES_REQ_STATUS,
} from '@control-front-end/common/constants/actorAccounts';
import api from '@control-front-end/common/sagas/api';
import AppUtils from '@control-front-end/utils/utils';

/**
 * Get account types
 */
function* getAccountNames({ payload, callback }) {
  const accounts = yield select((state) => state.accounts);
  const accId = accounts.active;
  const {
    loadMore,
    localState,
    withStats = false,
    limit: customLimit,
    offset: customOffset,
  } = payload;
  let accNamesState;
  if (localState) {
    accNamesState = localState;
  } else {
    accNamesState = yield select((state) => state.accountNames);
  }
  const {
    list,
    limit: defaultLimit = 20,
    offset: defaultOffset = 0,
    endList,
  } = accNamesState;
  if (loadMore && endList) return;
  const limit = customLimit || defaultLimit;
  const offset = !AppUtils.isUndefined(customOffset)
    ? customOffset
    : defaultOffset;
  // Set account names request status to inProgress
  yield put({ type: SET_ACCOUNT_NAMES_REQ_STATUS, payload: 'inProgress' });
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/account_names/${accId}`,
    queryParams: { limit, offset, withStats },
  });
  // Set account names request status to success
  yield put({ type: SET_ACCOUNT_NAMES_REQ_STATUS, payload: 'success' });
  if (result !== RequestStatus.SUCCESS) return;
  const accountNamesList = loadMore ? structuredClone(list) : [];
  const newList = data.data.list;
  accountNamesList.push(...newList);
  const newPayload = {
    list: accountNamesList,
    limit,
    offset: offset + limit,
    endList: !newList.length,
    total: data.data.total,
  };
  if (!localState) {
    yield put({
      type: GET_ACCOUNT_NAMES.SUCCESS,
      payload: newPayload,
    });
  }
  if (callback) callback(newPayload);
}

/**
 * Get currencies related to the name
 */
function* getAccNameCurrencies({ payload, callback }) {
  const config = yield select((state) => state.config);
  const {
    nameId,
    loadMore,
    limit: customLimit,
    offset: customOffset,
    withStats = false,
    localState,
    q,
  } = payload;
  let currencies;
  if (localState) {
    currencies = localState;
  } else {
    currencies = yield select((state) => state.currencies);
  }
  const {
    list,
    limit: defaultLimit = 20,
    offset: defaultOffset = 0,
    endList,
  } = currencies;
  if (loadMore && endList) return;
  const limit = customLimit || defaultLimit;
  const offset = !AppUtils.isUndefined(customOffset)
    ? customOffset
    : defaultOffset;
  // Set account currencies request status to inProgress
  yield put({ type: SET_ACCOUNT_CURRENCIES_REQ_STATUS, payload: 'inProgress' });
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/account_names/currencies/${nameId}`,
    queryParams: { limit, offset, withStats, q },
  });
  // Set account currencies request status to success
  yield put({ type: SET_ACCOUNT_CURRENCIES_REQ_STATUS, payload: 'success' });
  if (result !== RequestStatus.SUCCESS) return;
  const currenciesList = loadMore ? structuredClone(list) : [];
  const newList = data.data.list;
  newList.forEach((item) => {
    if (!item.access) return;
    for (const a of item.access) {
      a.avatar = AppUtils.makeUserAvatar(a, config);
    }
  });
  currenciesList.push(...newList);
  const newPayload = {
    list: currenciesList,
    limit,
    offset: offset + limit,
    endList: !newList.length,
    total: data.data.total,
  };
  if (localState && callback) {
    callback({ ...localState, ...newPayload });
    return;
  }
  yield put({
    type: GET_ACCOUNT_CURRENCIES.SUCCESS,
    payload: newPayload,
  });
  if (callback) callback(data.data);
}

/**
 * Get account name by ID
 */
function* getAccountName({ payload: accountNameId, callback, errorCallback }) {
  const handleErrCodes = [403, 404];
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/account_names/single/${accountNameId}`,
    handleErrCodes,
  });
  if (handleErrCodes.includes(data.statusCode) && errorCallback) {
    errorCallback();
  }
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback(data.data);
}

/**
 * Create account name
 */
export function* createAccountName({ payload, callback }) {
  const accounts = yield select((state) => state.accounts);
  const accId = accounts.active;
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/account_names/${accId}`,
    body: { name: payload },
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback(data.data);
  return data.data;
}

/**
 * Update account name
 */
function* updateAccountName({ payload, callback }) {
  const { id, name } = payload;
  const accNames = yield select((state) => state.accountNames.list);
  const { result, data } = yield call(api, {
    method: 'put',
    url: `/account_names/${id}`,
    body: { name },
  });
  if (result !== RequestStatus.SUCCESS) return;
  const list = structuredClone(accNames);
  const findIndex = list.findIndex((i) => i.id === id);
  const accName = list[findIndex];
  accName.name = name;
  list.splice(findIndex, 1, accName);
  yield put({
    type: UPDATE_ACCOUNT_NAME.SUCCESS,
    payload: { list },
  });
  if (callback) callback(data.data);
  return data.data;
}

/**
 * Delete account name
 */
function* removeAccountName({ payload: id, callback }) {
  const accNames = yield select((state) => state.accountNames.list);
  const { result } = yield call(api, {
    method: 'delete',
    url: `/account_names/${id}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  const list = accNames.filter((i) => i.id !== id);
  yield put({
    type: REMOVE_ACCOUNT_NAME.SUCCESS,
    payload: { list, total: list.length },
  });
  if (callback) callback();
}

/**
 * Search account name
 */
function* searchAccountName({ payload, callback }) {
  const { query, withStats = false, limit = 15, offset = 0 } = payload;
  const accounts = yield select((state) => state.accounts);
  const accId = accounts.active;
  const searchQ = AppUtils.safeSearchQuery(query);
  if (!searchQ.length) return;

  // Set account names request status to inProgress
  yield put({ type: SET_ACCOUNT_NAMES_REQ_STATUS, payload: 'inProgress' });
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/account_names/search/${accId}/${searchQ}`,
    queryParams: { withStats, limit, offset },
  });
  // Set account names request status to success
  yield put({ type: SET_ACCOUNT_NAMES_REQ_STATUS, payload: 'success' });
  if (result !== RequestStatus.SUCCESS) return;
  const list = data.data;
  if (callback) {
    callback(list);
  } else {
    yield put({
      type: SEARCH_ACCOUNT_NAME.SUCCESS,
      payload: { list },
    });
  }
}

function* accountNames() {
  yield takeEvery(GET_ACCOUNT_NAMES.REQUEST, getAccountNames);
  yield takeEvery(GET_ACCOUNT_NAME.REQUEST, getAccountName);
  yield takeEvery(CREATE_ACCOUNT_NAME.REQUEST, createAccountName);
  yield takeEvery(UPDATE_ACCOUNT_NAME.REQUEST, updateAccountName);
  yield takeEvery(REMOVE_ACCOUNT_NAME.REQUEST, removeAccountName);
  yield takeEvery(SEARCH_ACCOUNT_NAME.REQUEST, searchAccountName);
  yield takeEvery(GET_ACCOUNT_NAME_CURRENCIES.REQUEST, getAccNameCurrencies);
}

export default accountNames;
