import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  sharedWith: {
    id: 'sharedWith',
    defaultMessage: 'Shared withs',
  },
  accessRights: {
    id: 'accessRights',
    defaultMessage: 'Access rights',
  },
  view: {
    id: 'view',
    defaultMessage: 'View',
  },
  sign: {
    id: 'sign',
    defaultMessage: 'Sign',
  },
  execute: {
    id: 'execute',
    defaultMessage: 'Execute',
  },
  mixedRole: {
    id: 'mixedRole',
    defaultMessage: 'Mixed role',
  },
});

export default { ...globalIntl, ...m };
