import { call, put, select, takeLatest } from 'redux-saga/effects';
import { Utils } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import TabNotify from '@control-front-end/utils/tabNotify';
import api from '@control-front-end/common/sagas/api';
import {
  SSO_AUTH_KEY,
  APP_AUTH_START,
  SET_TAB_ID,
  APP_AUTH_KEY,
  AUTH,
  LOGOUT,
  CHECK_VERSION,
  SHOW_SESSION_MODAL,
  SET_MODAL,
  WS_OPEN,
  WS_UPDATE_SESSION,
  RequestStatus,
  PM_APP_NAME,
} from 'constants';
import { GRAPH_REALTIME_PROLONGATION } from '@control-front-end/common/constants/graphRealtime';
import history from '@control-front-end/app/src/store/history';
import { RE_UUID_GLOBAL, RE_UUID_SHORT } from '../constants/regExp';
import { appInit } from './init';

/**
 * Получить идентификатор вкладки браузера
 */
function getTabId() {
  const tabId = Utils.fromSessionStorage('controlTabId');
  if (tabId) return tabId;
  const newTabId = AppUtils.udid();
  Utils.toSessionStorage('controlTabId', newTabId);
  return newTabId;
}

/**
 * Получить id воркспейса из урла во вкладке браузера
 */
function getAccountFromPath(pathname) {
  const pathParts = pathname.split('/');
  return pathParts[2];
}

/**
 * Отправить в виджет токен авторизации
 */
function authInWidgets(config) {
  const frame = document.createElement('iframe');
  frame.src = `${config.widgetUrl}/sid.html`;
  frame.setAttribute('width', '0px');
  frame.setAttribute('height', '0px');
  frame.setAttribute('frameborder', '0px');
  frame.style.display = 'block';
  frame.onload = () => {
    const payload = Utils.fromStorage(APP_AUTH_KEY);
    const packet = { appName: PM_APP_NAME, type: 'APP_AUTH', payload };
    frame.contentWindow.postMessage(packet, config.widgetUrl);
  };
  document.body.appendChild(frame);
}

/**
 * Заменить id воркспейса на актуальный
 */
function actualizeUrlWorkspace(accId) {
  const href = document.location.href;
  const uudidRe = new RegExp(`(${RE_UUID_GLOBAL}|${RE_UUID_SHORT})`);
  const newUrl = href.replace(uudidRe, accId);
  window.history.pushState({}, null, newUrl);
}

function setSSOAuth(jwt) {
  if (!jwt || !AppUtils.isIframe()) return null;
  const sso = `SSO ${jwt}`;
  window[SSO_AUTH_KEY] = sso;
  return sso;
}

/**
 * Авторизация приложения
 */
function* authApp({ payload }) {
  const { jwt } = payload;
  yield put({ type: SET_TAB_ID.SUCCESS, payload: getTabId() });
  const appAuthStr = setSSOAuth(jwt) || Utils.fromStorage(APP_AUTH_KEY);
  if (appAuthStr) {
    yield put({ type: APP_AUTH_START.SUCCESS });
    yield put({ type: AUTH.REQUEST });
    yield put({ type: CHECK_VERSION });
    yield put({ type: GRAPH_REALTIME_PROLONGATION });
    return;
  }
  const queryParams = Utils.getQueryParam(document.location.search);
  const { result, data } = yield call(api, {
    method: 'post',
    url: '/auth/sid',
    body: { tmpsid: queryParams.tmpsid },
  });
  if (result !== RequestStatus.SUCCESS) return;

  if (data && data.action === 'auth') {
    delete queryParams.tmpsid;
    const sSearch = Utils.serialize(queryParams);
    const path = `${document.location.pathname}${
      sSearch.length ? `?${sSearch}` : ''
    }`;
    window.history.replaceState({}, document.title, path);
    Utils.toStorage(APP_AUTH_KEY, data.data);
    yield put({ type: APP_AUTH_START.SUCCESS });
    yield put({ type: AUTH.REQUEST, payload: { setSID: true } });
    yield put({ type: CHECK_VERSION });
    yield put({ type: GRAPH_REALTIME_PROLONGATION });
  } else {
    if (document.location.pathname.indexOf('error') !== -1) return;
    const queryParams = Utils.getQueryParam(location.search);
    const returnUrl =
      queryParams.returnUrl || encodeURIComponent(document.location.href);
    document.location.href = `${data.data}?returnUrl=${returnUrl}`;
  }
}

/**
 * User authorization
 */
function* authMe({ payload = {} }) {
  const { result, data } = yield call(api, {
    method: 'get',
    url: '/auth/me',
  });
  if (result === 'unauth') {
    Utils.delStorage(APP_AUTH_KEY);
    document.location.reload();
    return;
  }
  if (result !== RequestStatus.SUCCESS) {
    yield put({ type: AUTH.FAILURE, payload: { serverError: true } });
    history.push('/app_error');
    return;
  }
  if (data.action === 'redirect') {
    document.location.href = data.data;
    return;
  }
  if (document.location.pathname === '/app_error') history.push('/');
  const accId = getAccountFromPath(document.location.pathname);
  const { config, activeWorkspace } = yield call(appInit, {
    payload: { accId, host: document.location.host },
  });
  data.data.avatar = AppUtils.makeUserAvatar(data.data, config);
  // Send authorization token to widget
  if (payload.setSID) authInWidgets(config);
  yield put({ type: AUTH.SUCCESS, payload: data.data });
  // Replace workspace ID in location path, if it differs from user's active workspace
  // except for script view
  if (
    accId &&
    accId !== activeWorkspace.id &&
    !AppUtils.isScriptView(document.location.pathname)
  ) {
    actualizeUrlWorkspace(activeWorkspace.id);
  }
  yield put({ type: WS_OPEN });
  // Listen tab title changes
  TabNotify.listenTabFocus();
  TabNotify.requestNotifyPopup();
}

/**
 * Обновления данных сессии
 */
function* wsUpdateSession() {
  const { result, data } = yield call(api, {
    method: 'get',
    url: '/auth/me',
  });
  if (result !== RequestStatus.SUCCESS) return;
  const config = yield select((state) => state.config);
  data.data.avatar = AppUtils.makeUserAvatar(data.data, config);
  yield put({ type: AUTH.SUCCESS, payload: data.data });
}

/**
 * Деавторизировать пользователя
 */
function* logout({ payload }) {
  const toRoot = payload ? payload.toRoot : false;
  const { result, data } = yield call(api, {
    method: 'get',
    url: '/auth/logout',
  });
  if (result !== RequestStatus.SUCCESS) return;
  Utils.delStorage(APP_AUTH_KEY);
  const returnUrl = toRoot
    ? encodeURIComponent(
        `${document.location.protocol}//${document.location.host}`
      )
    : encodeURIComponent(document.location.href);
  document.location.href = `${data.data}?redirect_uri=${returnUrl}`;
}

/**
 * Показать модалку с уведомлением о том что сессия закончилась
 */
function* showModal() {
  yield put({
    type: SET_MODAL,
    payload: {
      name: 'InfoModal',
      data: {
        id: 'expiredSession',
        title: 'expiredSession',
        text: 'expiredSessionInfo',
        buttons: [
          {
            type: 'default',
            label: 'Restart session',
            onClick: () => {
              document.location.reload();
            },
          },
        ],
      },
    },
  });
}

function* auth() {
  yield takeLatest(APP_AUTH_START.REQUEST, authApp);
  yield takeLatest(AUTH.REQUEST, authMe);
  yield takeLatest(LOGOUT.REQUEST, logout);
  yield takeLatest(SHOW_SESSION_MODAL, showModal);
  yield takeLatest(WS_UPDATE_SESSION, wsUpdateSession);
}

export default auth;
