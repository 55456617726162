import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Banner,
  Button,
  Modal,
  ModalButtons,
  ModalContent,
  ProgressBar,
} from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import {
  BULK_MANAGE_ACCESS_RULES,
  BULK_MANAGE_PAIRS_ACCESS_RULES,
  NOTIFY_LEVEL,
  SHOW_NOTIFY,
} from 'constants';
import useIntl from 'useIntl';
import AccessRules from '@control-front-end/common/components/AccessRules';
import { ACTORS_ACCESS } from '@control-front-end/common/constants/graphActors'; // NOSONAR
import { FORMS_ACCESS } from '@control-front-end/common/constants/forms';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import m from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import l from './BulkManageAccessRules.scss';

/**
 * Модальное окно массового предоставления доступа к объектам
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function BulkManageAccessRules(props) {
  const {
    data: { items, objType },
    callback,
    visibility,
    onClose,
  } = props;
  const t = useIntl();
  const dispatch = useDispatch();
  const systemFormsGraphs =
    useSelector((state) => state.systemForms.graphs) || {};
  const [isLoading, setLoading] = useState(false);
  const [access, setAccess] = useState([]);
  const [noSubmit, setNoSubmit] = useState(false);

  /**
   * Делаем сводку по доступам для каждого юзера,
   * помечаем смешанные права и овнера (хотя бы одного объекта)
   */
  const makeSummaryRules = () => {
    const allRules = items.reduce((arr, obj) => arr.concat(obj.access), []);
    const groupedByUser = AppUtils.groupBy(allRules, 'userId');
    const access = Object.keys(groupedByUser).map((userId) => {
      const userRules = groupedByUser[userId];
      const hasAccessToAll = userRules.length === items.length;
      const hasEqualPrivsToAll = AppUtils.isEqualPrivs(userRules);
      const rule = { ...userRules[0] };
      return hasAccessToAll && hasEqualPrivsToAll
        ? rule
        : {
            ...rule,
            isMixed: true,
            privs: {
              view: false,
              modify: false,
              remove: false,
              sign: false,
              execute: false,
            },
          };
    });
    setAccess(access);
  };

  useEffect(() => {
    if (objType === 'accName') return;
    makeSummaryRules();
  }, []);

  /**
   * Уведомление об успешном сохранении доступа
   */
  const notifySuccess = () => {
    dispatch({
      type: SHOW_NOTIFY.REQUEST,
      payload: {
        id: AppUtils.createRid(),
        type: NOTIFY_LEVEL.SUCCESS,
        label: t(mes.accessSaved),
      },
    });
  };

  /**
   * Обновить доступы для объектов в списке
   */
  const updateObjectsAccess = (rules) => {
    const actionType =
      objType === 'actor' ? ACTORS_ACCESS.REQUEST : FORMS_ACCESS.REQUEST;
    dispatch({
      type: actionType,
      payload: { rules },
    });
  };

  /**
   * Сохранение всех изменений в доступах
   */
  const handleSubmit = (accessActions) => {
    if (!accessActions.length) {
      onClose();
      return;
    }
    const newAccess = accessActions.map(
      ({ action, userType, userId, saId, privs }) => {
        const noPrivs = Object.keys(privs).every((level) => !privs[level]);
        const data = { privs };
        if (userType === 'group') {
          data.groupId = saId;
        } else {
          data.userId = userId;
        }
        return { action: noPrivs ? 'delete' : action, data };
      }
    );
    const newRules = items.map((item) => ({
      objId: item.id,
      objType,
      recursive: item.formId && item.formId === systemFormsGraphs.id,
      rules: newAccess,
    }));
    setLoading(true);
    const pairAccess = objType === 'accName';
    dispatch({
      type: pairAccess
        ? BULK_MANAGE_PAIRS_ACCESS_RULES.REQUEST
        : BULK_MANAGE_ACCESS_RULES.REQUEST,
      payload: newRules,
      callback: (newAccess) => {
        notifySuccess();
        if (pairAccess) {
          onClose();
          return;
        }
        const mergedRules = newRules.map((i, index) => ({
          id: i.objId,
          access: newAccess[index],
        }));
        updateObjectsAccess(mergedRules);
        if (callback) callback(mergedRules);
        setLoading(false);
        onClose();
      },
    });
  };

  const changedAccess = access.filter((i) => !!i.action);
  const noChanges = !changedAccess.length;
  return (
    <Modal
      styleName="m.modal l.modal"
      size={objType === 'actor' ? 'xlarge' : 'large'}
      onClose={onClose}
      label={t(mes.manageAccess)}
      visibility={visibility}
    >
      <ModalContent styleName="m.modal__content l.content">
        <Banner
          styleName="l.content__banner"
          size="medium"
          value={t(mes.accessToSelected, { count: items.length })}
        />
        {access ? (
          <AccessRules
            objType={objType}
            rules={access}
            allPrivs={objType === 'actor'}
            allowInvite={false}
            onChange={setAccess}
            onSelect={(onSelect) => setNoSubmit(onSelect)}
          />
        ) : null}
      </ModalContent>
      <ModalButtons styleName="m.modal__buttons">
        <Button
          label={noChanges ? t(mes.done) : t(mes.saveChanges)}
          size="large"
          onClick={() => handleSubmit(changedAccess)}
          visibility={noSubmit || isLoading ? 'disabled' : 'visible'}
        />
        <ProgressBar
          styleName="m.modal__loader"
          type="circle"
          size="small"
          visibility={isLoading ? 'visible' : 'hidden'}
        />
      </ModalButtons>
    </Modal>
  );
}

BulkManageAccessRules.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.array.isRequired,
    objType: PropTypes.oneOf(['actor', 'formTemplate', 'account', 'accName']),
  }),
  visibility: PropTypes.bool,
  onClose: PropTypes.func,
};

export default BulkManageAccessRules;
