import { defineMessages } from 'react-intl';

const m = defineMessages({
  manageAccessGraph: {
    id: 'manageAccessGraph',
    defaultMessage: 'Access Graph',
  },
  formGraph: {
    id: 'formGraph',
    defaultMessage: 'Form Graph ({title})',
  },
  actorFormsGraph: {
    id: 'actorFormsGraph',
    defaultMessage: 'Actor Forms Graph ({title})',
  },
});

export default m;
