import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../../../styles/main.scss';

function ErrorLayout({ children }) {
  const theme = useSelector((state) => state.theme);
  return (
    <div className={`theme-${theme}`}>
      <div styleName="err">{children}</div>
    </div>
  );
}

ErrorLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withRouter(ErrorLayout);
