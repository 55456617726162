import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import pickBy from 'lodash/pickBy';

import { useIntl, useActorFields } from 'hooks';
import { Stack, Label, Select, cr } from 'mw-style-react';
import SelectActors from '@control-front-end/common/components/SelectActors';
import {
  TRANSFERABLE_FIELD_TYPE,
  FORM_TYPE,
} from '@control-front-end/common/constants/forms';
import { NEW_TRANSFER } from '@control-front-end/common/constants/actorAccounts';
import FormFieldSelector from '@control-front-end/app/src/components/FormFieldSelector';
import FieldValues from '@control-front-end/app/src/components/FieldValues';
import FieldValue from './FieldValue';
import mes from './intl';

const isFieldFilled = (item) =>
  Boolean(Array.isArray(item.value) ? item.value.length : item.value);

function TransferSourceSettings({ initialFieldId, ...rest }) {
  const t = useIntl();

  const dispatch = useDispatch();

  const sourceState = useSelector((state) => state.newFormFieldTransfer.from);
  const targetsState = useSelector((state) => state.newFormFieldTransfer.to);

  const targetsActors = useMemo(
    () => targetsState.map(({ actor }) => actor).filter(Boolean),
    [targetsState]
  );

  const actorFields = useActorFields(sourceState.actor?.id);

  const fieldsAbleForTransfer = useMemo(() => {
    return pickBy(
      actorFields,
      (item) => TRANSFERABLE_FIELD_TYPE[item.class] && isFieldFilled(item)
    );
  }, [actorFields]);

  useEffect(() => {
    if (initialFieldId) {
      dispatch({
        type: NEW_TRANSFER.FORM_FIELD.SOURCE.SET_ACCOUNT,
        payload: fieldsAbleForTransfer[initialFieldId],
      });
    }
  }, [initialFieldId, fieldsAbleForTransfer]);

  return (
    <Stack.V fullWidth {...rest}>
      <SelectActors
        size="large"
        type="modal"
        bordered
        unspaced
        resettable
        onReset={() => {
          dispatch({
            type: NEW_TRANSFER.FORM_FIELD.SOURCE.RESET,
          });
        }}
        multiselect={false}
        placeholder={t(mes.selectActor)}
        value={sourceState.actor}
        exclude={targetsActors}
        manageLayer={false}
        hideCreateBtn={true}
        fullModel={true}
        label={<Select.Label value={t(mes.actor)} color={Label.COLOR.gray} />}
        onChange={({ value }) =>
          dispatch({
            type: NEW_TRANSFER.FORM_FIELD.SOURCE.SET_ACTOR,
            payload: value,
          })
        }
        // Transfers are not allowed for actors based on "system" forms
        filter={({ formType }) => formType !== FORM_TYPE.system}
      />
      <FormFieldSelector
        onChange={(value) =>
          dispatch({
            type: NEW_TRANSFER.FORM_FIELD.SOURCE.SET_ACCOUNT,
            payload: value,
          })
        }
        label={<Select.Label value={t(mes.account)} color={Label.COLOR.gray} />}
        visibility={sourceState.actor?.id ? 'visible' : 'disabled'}
        fields={fieldsAbleForTransfer}
        value={sourceState.field}
      />
      <FieldValue
        fieldClass={sourceState.field?.class}
        value={sourceState.value}
        options={sourceState.field?.value}
        visibility={sourceState.field?.id ? 'visible' : 'disabled'}
        label={<Select.Label value={t(mes.value)} color={Label.COLOR.gray} />}
        onChange={({ value }) =>
          dispatch({
            type: NEW_TRANSFER.FORM_FIELD.SOURCE.SET_VALUE,
            payload: Array.isArray(value) ? value : [value],
          })
        }
      />
      {cr([
        sourceState.field,
        <Stack.H>
          <Label value={t(mes.balance)} fontWeight="semibold" />
          <FieldValues value={sourceState.field?.value} />
        </Stack.H>,
      ])}
    </Stack.V>
  );
}

TransferSourceSettings.propTypes = {
  initialFieldId: PropTypes.string,
};

export default TransferSourceSettings;
