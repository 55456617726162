import { Utils } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import { URL_REGEXP } from '@control-front-end/common/constants/regExp';
import FIELDS from './Fields';

/**
 * Получить доступные поля для item
 * @param item
 * @returns {[]}
 */
export function getItemAvailableFields(item) {
  let exFields = [];
  const className = item.class;
  const itemFields = FIELDS[className];
  Object.keys(itemFields).forEach((i) => {
    exFields = exFields.concat(itemFields[i].map((f) => f.id));
  });
  return exFields;
}

/**
 * Установить поле title
 * @param item
 * @param activeItem
 */
function setTitleField(item, activeItem) {
  const exFields = getItemAvailableFields(item);
  if (exFields.includes('title') && !item.title) {
    item.title = `Title ${activeItem + 1}`;
  }
}

/**
 * Установить поле id
 * @param item
 */
function setIdField(item) {
  if (!item.id) item.id = `item_${AppUtils.udid()}`;
}

/**
 * Установить поле options
 * @param item
 */
function setOptionField(item) {
  const exFields = getItemAvailableFields(item);
  if (!exFields.includes('options')) return;
  const options = item.options || [];
  if (typeof options !== 'string') {
    options.forEach((i, index) => {
      if (!i.title) i.title = `Option ${index + 1}`;
    });
    if (!options.length) {
      options.push({ value: AppUtils.createRid(), title: 'Option 1' });
      item.options = options;
    }
  } else {
    item.options = [];
  }
}

/**
 * Установить поле extra
 * @param item
 */
function setExtraField(item) {
  const exFields = getItemAvailableFields(item);
  if (!exFields.includes('extra')) return;
  if (typeof item.extra === 'string') {
    item.extra = {};
  }
}

/**
 * Установить поле value для элементов ссылок
 * @param item
 */
function setLinkValue(item) {
  const linkClasses = ['image', 'link'];
  const { class: className, value } = item;
  if (!linkClasses.includes(className)) return;
  const urlReg = new RegExp(URL_REGEXP);
  if (!urlReg.test(value)) item.value = '';
}

/**
 * Установить поле value
 * @param item
 * @param activeItem
 */
function setValueField(item, activeItem) {
  const valueClasses = ['label'];
  const { class: className, value } = item;
  if (!valueClasses.includes(className)) return;
  if (!value) return `${Utils.capitalize(className)} ${activeItem + 1}`;
}

/**
 * Установить полям значения по умолчанию
 * @param form
 * @param activeSection
 * @param activeItem
 * @returns {*}
 */
export function setDefaultValue({ form, activeSection, activeItem }) {
  if (!form.sections.length) return form;
  const section = form.sections[activeSection];
  if (!section.content.length) return form;
  const item = { ...section.content[activeItem] };
  // Установить поле ID
  setIdField(item);
  // Установить поле title
  setTitleField(item, activeItem);
  // Установить поле options
  setOptionField(item);
  // Установить поле extra
  setExtraField(item);
  // Установить поле value для item с ссылками в value
  setLinkValue(item);
  // Установить value
  setValueField(item, activeItem);
  // Удалить ошибки из item
  delete item.errors;
  section.content.splice(activeItem, 1, item);
  return form;
}
