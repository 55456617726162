import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { DateUtils, Label, Utils, cr } from 'mw-style-react';
import cn from 'classnames';

import AppUtils from '@control-front-end/utils/utils';
import { DATE_FORMAT_1 } from 'constants';
import { useIntl } from 'hooks';

import mes from '../../intl';
import '../../EventListItem.scss';

function LastReaction({ lastReaction, isActive, compactView }) {
  const t = useIntl();
  const text = lastReaction?.description || '';
  let reactionType = lastReaction?.data?.type;
  const textLen = Utils.removeHtmlTags(text || '').trim().length;
  const { active: accId } = useSelector((state) => state.accounts);
  const formatText = AppUtils.makeDescription({
    str: text,
    accId,
    noNewLines: true,
    noHtmlAttributes: true,
    highlightLinks: !compactView,
  });
  if (!textLen && lastReaction?.appId) {
    reactionType = 'script';
  } else if (!textLen && reactionType === 'comment') {
    reactionType = 'attachments';
  }
  const date = DateUtils.toDate(lastReaction?.createdAt, DATE_FORMAT_1);
  const user = lastReaction?.metaInfo?.originalUser || lastReaction?.user || {};

  return cr(
    [
      lastReaction,
      <div
        styleName={cn('le__item__update__info', {
          active: isActive,
          compact: compactView,
        })}
      >
        <div
          styleName={cn('le__item__update__info__nick', {
            compact: compactView,
          })}
        >
          <Label
            fontWeight="semibold"
            duplicatingTooltip
            value={compactView ? user.nick : `${date} ${user.nick}`}
          />
        </div>
        {textLen && reactionType === 'comment' ? '' : <>&nbsp;</>}
        <Label
          fontWeight="semibold"
          value={t(mes[`reaction_${reactionType}`])}
        />
        {textLen ? ': ' : ' '}
        <span
          styleName="le__item__update__reaction"
          dangerouslySetInnerHTML={{
            __html: textLen ? formatText : '',
          }}
        />
      </div>,
    ],
    [compactView, <div styleName="le__item__blank" />]
  );
}

LastReaction.propTypes = {
  lastReaction: PropTypes.object,
  isActive: PropTypes.bool,
  compactView: PropTypes.bool,
};

export default LastReaction;
