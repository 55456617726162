import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Label,
  Icon,
  Stack,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  DateUtils,
  Menu,
  MenuItem,
} from 'mw-style-react';
import { DATE_FORMAT, DATE_FORMAT_3 } from 'constants';
import { useModal, useIntl } from 'hooks';
import UserAvatar from '@control-front-end/common/components/UserAvatar';
import '../../Additions.scss';
import mes from './intl';

function ActorScript({ app, appSettings, access, onChange }) {
  const t = useIntl();
  const [menu, toggleMenu] = useState(false);

  const { open: openScriptModal } = useModal('PersonalScriptModal', {
    onSubmit: (value) => onChange({ id: 'app', value }),
  });

  const handleMenuClick = ({ value }) => {
    switch (value) {
      case 'replace':
        openScriptModal({ appId: app.id, appSettings, access });
        break;
      case 'remove':
        onChange({ id: 'app', value: null });
        break;
      default:
        break;
    }
    toggleMenu(false);
  };

  const renderMenu = () => {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Menu
          styleName="table__menu"
          size="small"
          width={200}
          onClick={handleMenuClick}
          onClose={() => toggleMenu(false)}
        >
          <MenuItem size="small" label={t(mes.replace)} value="replace" />
          <MenuItem size="small" label={t(mes.remove)} value="remove" />
        </Menu>
      </div>
    );
  };

  return (
    <Table paddingSize="small" styleName="table">
      <TableHead styleName="table__head">
        <TableRow styleName="table__row" rowSize="small">
          <TableCell width="32" />
          <TableCell width="auto" />
          <TableCell width="180" />
          <TableCell width="50" />
          <TableCell width="20" />
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow styleName="table__row" rowSize="small">
          <TableCell>
            <div styleName="attaches__general__icon">
              <Icon type="form_pole" />
            </div>
          </TableCell>
          <TableCell styleName="table__cell title">
            <Stack size={Stack.SIZE.none}>
              <Label color={Label.COLOR.black} value={app.title} />
              {appSettings?.users && (
                <Label
                  color={Label.COLOR.gray}
                  value={t(mes.usersAmount, {
                    amount: appSettings?.users.length,
                  })}
                />
              )}
              {appSettings?.expired && (
                <Label
                  color={Label.COLOR.gray}
                  value={t(mes.exparationDate, {
                    date: DateUtils.toDate(appSettings.expired, DATE_FORMAT_3),
                  })}
                />
              )}
            </Stack>
          </TableCell>
          <TableCell>
            <Label
              color={Label.COLOR.black}
              value={DateUtils.toDate(app.createdAt, DATE_FORMAT)}
            />
          </TableCell>
          <TableCell>
            {app.ownerAvatar ? (
              <UserAvatar
                size="small"
                src={app.ownerAvatar}
                label={app.ownerName}
              />
            ) : null}
          </TableCell>
          <TableCell styleName="table__menu__wrap">
            <Icon
              type="more"
              styleName="table__row__icon"
              onClick={(e) => {
                e.stopPropagation();
                toggleMenu(true);
              }}
            />
            {menu ? renderMenu() : null}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

ActorScript.propTypes = {
  app: PropTypes.object,
  appSettings: PropTypes.object,
  access: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
};

export default ActorScript;
