import { call, put, select, takeEvery } from 'redux-saga/effects';
import AppUtils from '@control-front-end/utils/utils';
import { Utils, DateUtils } from 'mw-style-react';
import {
  NOTIFY_LEVEL,
  RequestStatus,
  SET_MODAL,
  SHOW_NOTIFY,
  DATE_FORMAT_6,
  DATE_FORMAT_7,
} from 'constants';
import {
  CREATE_TRANSACTION,
  GET_CHILD_TRANSACTIONS,
  GET_TRANSACTIONS,
  SEARCH_TRANSACTIONS,
  GET_SINGLE_TRANSACTION,
  GET_TRANSACTION_FULL,
  FILTER_TRANSACTIONS,
  EXPORT_TRANSACTIONS,
  FILTER_TRANSACTIONS_REQ_STATUS,
  ADD_TRANSACTION,
  WS_DELETE_TRANSACTION,
  UPDATE_TRANSACTION,
  GET_FORM_FIELD_TRANSACTION,
  GET_COORDINATES_TRANSACTIONS,
} from '@control-front-end/common/constants/actorAccounts';
import api, { chunks } from '@control-front-end/common/sagas/api';
import { PERMISSIONS } from '@control-front-end/common/constants/permissions';
import history from '@control-front-end/app/src/store/history';
import { makeActorPicture } from './graph/graphHelpers';

/**
 * Обновить счет
 */
export function* updateAccount({ accountType, key, incomeType, data }) {
  const actorsAccs = yield select((state) => state.actorsAccounts[accountType]);
  const copyAccounts = structuredClone(actorsAccs);
  const accIndex = copyAccounts.findIndex((i) => i.key === key);
  const accModel = copyAccounts[accIndex];
  if (incomeType === 'all') {
    Object.assign(accModel, data.main);
    Object.assign(accModel.debit, data.debit);
    Object.assign(accModel.credit, data.credit);
  } else Object.assign(accModel[incomeType], data);
  copyAccounts.splice(accIndex, 1, accModel);
  return copyAccounts;
}

/**
 * Создать модель транзакций
 * @param list
 * @param entity
 * @param incomeType
 */
function makeTransactionModel({ list, debit, credit, incomeType }) {
  const accounts = { debit, credit };
  let total = incomeType
    ? accounts[incomeType].amount
    : -debit.amount + credit.amount;

  list.forEach((item) => {
    item.total = total;
    if (!incomeType) {
      const diff = item.incomeType === 'credit' ? -item.amount : item.amount;
      total += diff;
    } else {
      total -= item.amount;
    }
  });
}

/**
 * Создать полную модель транзакции
 */
function* makeFullTransactionModel(model) {
  const config = yield select((state) => state.config);
  const { transaction, actor } = model;
  const { userId, saId, name, userType } = transaction;
  model.transaction.user = { id: userId, saId, nick: name, userType };
  model.transaction.user.avatar = AppUtils.makeUserAvatar(
    model.transaction.user,
    config
  );
  model.actor.pictureUrl = yield makeActorPicture(actor);
  model.account.key = `${model.actor.id}+${model.account.nameId}+${model.account.currencyId}`;
  return model;
}

/**
 * Создать полную модель form field транзакции
 */

function* fillDataWithImages(model) {
  const config = yield select((state) => state.config);
  const { historyItem, obj } = model;
  model.historyItem.user.avatar = AppUtils.makeUserAvatar(
    historyItem.user,
    config
  );
  model.obj.pictureUrl = yield makeActorPicture(obj);
  return model;
}

/**
 * Создать транзакцию
 */
function* createTransaction({ payload, callback, formActions }) {
  const { id, transaction, key, incomeType, accountType } = payload;
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/transactions/${id}`,
    body: transaction,
  });
  if (formActions) formActions.setSubmitting(false);
  if (result !== RequestStatus.SUCCESS) return;
  if (data.data.type === 'declined') {
    yield put({
      type: SHOW_NOTIFY.REQUEST,
      payload: {
        id: AppUtils.createRid(),
        type: NOTIFY_LEVEL.ERROR,
        label: 'Transaction has been declined',
      },
    });
  }
  const actorsAccountsState = yield select((state) => state.actorsAccounts);
  const actorsAccs = actorsAccountsState[accountType];
  const { filter = {} } = actorsAccountsState;
  const matchRange =
    !filter.to || (filter.to && +filter.to >= +data.data.createdAt);
  if (actorsAccs && matchRange) {
    const copyAccounts = structuredClone(actorsAccs);
    const accIndex = copyAccounts.findIndex((i) => i.key === key);
    const accModel = copyAccounts[accIndex];
    if (accModel) {
      accModel[incomeType].amount += +transaction.amount;
      accModel[incomeType].availableAmount += +transaction.amount;
      accModel[incomeType].updatedAt = Math.round(data.data.createdAt / 1000);
      accModel[incomeType].value = data.data.value;
      copyAccounts.splice(accIndex, 1, accModel);
      yield put({
        type: CREATE_TRANSACTION.SUCCESS,
        payload: { [accountType]: copyAccounts },
      });
    }
  }
  if (formActions) formActions.onClose();
  if (callback) callback(data.data);
}

/**
 * Получить транзакции счета
 */
function* getTransactions({ payload, callback }) {
  const {
    actorId,
    nameId,
    currencyId,
    transactions,
    loadMore,
    accountType,
    incomeType,
    debit,
    credit,
    from,
    to,
    onlyRoot = true,
    recursive = true,
  } = payload;
  const { endList, list, limit, offset } = transactions;
  if (endList) return;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/transactions/${actorId}`,
    queryParams: {
      limit,
      offset,
      incomeType,
      accountType,
      nameId,
      currencyId,
      from,
      to,
      onlyRoot,
      recursive,
    },
  });
  if (result !== RequestStatus.SUCCESS) return;
  const oldList = list.slice();
  const newList = loadMore ? oldList.concat(data.data) : data.data;
  makeTransactionModel({ list: newList, debit, credit, incomeType });
  if (callback) {
    callback({
      list: newList,
      offset: offset + limit,
      limit,
      endList: !data.data.length,
    });
  }
}
function* getCoordinatesTransactions({ payload, callback }) {
  const { laId, from, to, limit, pageState: prevPageState } = payload;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/transactions/coordinates/${laId}`,
    queryParams: {
      from,
      to,
      limit,
      pageState: prevPageState,
    },
  });
  if (result !== RequestStatus.SUCCESS) return;
  const { list, pageState } = data.data;
  if (callback) callback({ list, limit, pageState, endList: !pageState });
}

/**
 * Получить дочерние транзакции
 */
function* getChildTransactions({ payload, callback }) {
  const { transactionId } = payload;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/transactions/children/${transactionId}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback(data.data);
}

/**
 * Получить транзакцию
 */
function* getSingleTransaction({ payload, callback, errorCallback }) {
  const { transactionId } = payload;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/transactions/single/${transactionId}`,
    handleErrCodes: errorCallback ? [404] : [],
  });
  if (result !== RequestStatus.SUCCESS) {
    if (data.statusCode === 404 && errorCallback) errorCallback();
    return;
  }
  if (callback) callback(data.data);
}

/**
 * Получить транзакцию с данными об акторе и счете
 */
function* getTransaction({ payload, callback, errorCallback }) {
  const { transactionId } = payload;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/transactions/single/full/${transactionId}`,
    handleErrCodes: errorCallback ? [404, 403] : [],
  });
  if (result !== RequestStatus.SUCCESS) {
    if (errorCallback) errorCallback();
    return;
  }
  const fullModel = yield call(makeFullTransactionModel, data.data);
  if (callback) callback(fullModel);
}

/**
 * Получить транзакцию по field form с данными об акторе и счете
 */

function* getFormFieldTransactions({ payload, callback, errorCallback }) {
  const { id } = payload;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/history/single/full/${id}`,
    handleErrCodes: errorCallback ? [404, 403] : [],
  });
  if (result !== RequestStatus.SUCCESS) {
    if (errorCallback) errorCallback();
    return;
  }
  const fullModel = yield call(fillDataWithImages, data.data);
  if (callback) callback(fullModel);
}

/**
 * Поиск транзакций
 */
function* searchTransactions({ payload, callback }) {
  const { actorId, q, incomeType, accountType, nameId, currencyId } = payload;
  const searchQ = AppUtils.safeSearchQuery(actorId);
  if (!searchQ.length) return;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/transactions/search/${searchQ}`,
    queryParams: {
      incomeType,
      accountType,
      nameId,
      currencyId,
      q,
      recursive: true,
    },
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) {
    callback(data.data);
  }
}

/**
 * Добавить транзакцию в список по фильтру
 */
function* addTransaction({ payload, callback }) {
  const { actor, account, transaction, incomeType, accountType } = payload;
  const { list, limit, total, filter } = yield select(
    (state) => state.transactions
  );
  if (!filter) return;
  const { from, to, accounts, actors } = filter;
  const matchAccount = accounts.some(
    (item) =>
      item.nameId === account.nameId && +item.currencyId === +account.currencyId
  );
  const mismatchedActor =
    actors && actors.length && actors.every((item) => item !== actor.id);
  const isOutOfRange =
    transaction.createdAt < from || transaction.createdAt > to;
  if (
    !matchAccount ||
    mismatchedActor ||
    isOutOfRange ||
    (filter.incomeType && filter.incomeType !== incomeType) ||
    (filter.accountType && filter.accountType !== accountType)
  )
    return;
  const copyList = list.slice();
  const { accountName, currencyName, currencyType, precision } =
    account[incomeType];
  copyList.unshift({
    ...transaction,
    actorTitle: actor.title,
    actorPicture: actor.picture,
    actorPictureUrl: actor.pictureUrl,
    color: actor.color,
    accountName,
    currencyName,
    currencyType,
    precision,
    incomeType,
  });
  const sortedList = Utils.sort(copyList, 'createdAt', 'desc').slice(0, limit);
  yield put({
    type: ADD_TRANSACTION.SUCCESS,
    payload: {
      list: sortedList,
      total: total + 1,
    },
  });
  if (callback) callback();
}

/**
 * Удаление транзакции по WS
 */
function* wsDeleteTransaction({ payload }) {
  const { model, tabId } = payload;
  const appInit = yield select((state) => state.appInit);
  const { activeAccount } = yield select((state) => state.accounts);
  const canViewTransactions =
    activeAccount.permissions &&
    activeAccount.permissions[PERMISSIONS.TRANSACTIONS_READONLY];
  if (
    tabId &&
    appInit.tabId !== tabId &&
    document.location.href.includes(`/transactions/view/${model.id}`)
  ) {
    yield put({
      type: SET_MODAL,
      payload: {
        name: 'InfoModal',
        data: {
          title: 'transactionNotAvailable',
          text: 'transactionNotAvailableInfo',
        },
        callback: () => {
          const redirectUrl = canViewTransactions
            ? AppUtils.makeUrl(
                `/actors_graph/${activeAccount.id}/transactions/list`
              )
            : '/';
          history.push(redirectUrl);
        },
      },
    });
    return;
  }
  const { list } = yield select((state) => state.transactions);
  const findIndex = list.findIndex((i) => i.id === model.id);
  if (findIndex === -1) return;
  const newList = structuredClone(list);
  const removedTransaction = list[findIndex];
  newList.splice(findIndex, 1, { ...removedTransaction, type: 'removed' });
  yield put({
    type: UPDATE_TRANSACTION.SUCCESS,
    payload: { list: newList },
  });
}

/**
 * Отфильтровать транзакции по критериям
 */
function* filterTransactions({ payload, callback }) {
  const { active: accId } = yield select((state) => state.accounts);
  const { limit: defaultLimit, offset: defaultOffset } = yield select(
    (state) => state.transactions
  );
  const {
    total,
    accounts,
    actors,
    accountType = 'fact',
    incomeType,
    from,
    to,
    orderBy,
    type,
    amount,
    oper,
    ownerId,
    query,
    ref,
    transferId,
    limit: customLimit,
    offset: customOffset,
  } = payload;
  const limit = customLimit || defaultLimit;
  const offset = !AppUtils.isUndefined(customOffset)
    ? customOffset
    : defaultOffset;
  // Ставим флаг прогресса загрузки
  if (!total)
    yield put({ type: FILTER_TRANSACTIONS_REQ_STATUS, payload: 'inProgress' });
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/transactions/filter/${accId}`,
    queryParams: { total },
    body: {
      accounts,
      actors,
      accountType,
      incomeType,
      type,
      from,
      to,
      orderBy,
      amount,
      oper,
      ownerId,
      query,
      ref,
      transferId,
      limit,
      offset,
    },
  });
  // Ставим флаг окончания загрузки
  if (!total)
    yield put({ type: FILTER_TRANSACTIONS_REQ_STATUS, payload: 'success' });
  if (result !== RequestStatus.SUCCESS) return;
  const newPayload = {};
  if (total) {
    newPayload.total = data.data.total;
  } else {
    for (const obj of data.data) {
      obj.actorPictureUrl = yield makeActorPicture({
        picture: obj.actorPicture,
        systemObjSAId: obj.systemObjSAId,
      });
    }
    newPayload.list = data.data;
    newPayload.filter = { ...payload };
  }
  yield put({
    type: FILTER_TRANSACTIONS.SUCCESS,
    payload: newPayload,
  });
  if (callback) callback(data.data);
}

const addDateFormatsToTransactions = (transactionsList) => {
  if (!Array.isArray(transactionsList) || transactionsList.length === 0) {
    return transactionsList;
  }

  const dateFormat = `${DATE_FORMAT_6} ${DATE_FORMAT_7}`;
  return transactionsList.map((item) => ({
    ...item,
    originalDateFormatted: item.originalDate
      ? DateUtils.toDate(item.originalDate / 1000, dateFormat)
      : '',
    createdAtFormatted: item.createdAt
      ? DateUtils.toDate(item.createdAt / 1000, dateFormat)
      : '',
  }));
};

function* exportTransactions({ payload, controller, callback, errorCallback }) {
  const { active: accId } = yield select((state) => state.accounts);
  let transactionsList;
  const handler = ({ value, error, errorDescription }) => {
    if (error && errorCallback) {
      errorCallback({ errorDescription });
      return;
    }
    transactionsList = addDateFormatsToTransactions(value);
  };
  yield call(chunks, {
    method: 'post',
    url: `/transactions/export/${accId}`,
    body: payload,
    handler,
    controller,
  });
  if (transactionsList && callback) callback(transactionsList);
}

function* transactions() {
  yield takeEvery(CREATE_TRANSACTION.REQUEST, createTransaction);
  yield takeEvery(GET_TRANSACTIONS.REQUEST, getTransactions);
  yield takeEvery(
    GET_COORDINATES_TRANSACTIONS.REQUEST,
    getCoordinatesTransactions
  );
  yield takeEvery(GET_SINGLE_TRANSACTION.REQUEST, getSingleTransaction);
  yield takeEvery(GET_TRANSACTION_FULL.REQUEST, getTransaction);
  yield takeEvery(GET_FORM_FIELD_TRANSACTION.REQUEST, getFormFieldTransactions);
  yield takeEvery(GET_CHILD_TRANSACTIONS.REQUEST, getChildTransactions);
  yield takeEvery(SEARCH_TRANSACTIONS.REQUEST, searchTransactions);
  yield takeEvery(FILTER_TRANSACTIONS.REQUEST, filterTransactions);
  yield takeEvery(EXPORT_TRANSACTIONS.REQUEST, exportTransactions);
  yield takeEvery(ADD_TRANSACTION.REQUEST, addTransaction);
  yield takeEvery(WS_DELETE_TRANSACTION, wsDeleteTransaction);
}

export default transactions;
