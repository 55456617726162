import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Space,
  Stack,
  Label,
  Icon,
  Avatar,
  Divider,
  ProgressBar,
  TextField,
  cr,
} from 'mw-style-react';
import { useIntl, useOutsideClick, useSearchList } from 'hooks';
import { GET_ACTOR_GLOBAL_LINKS } from '@control-front-end/common/constants/graphActors';
import AppUtils from '@control-front-end/utils/utils';
import mes from './intl';
import scss from './ActorLayersPopup.scss';

/**
 * Popup with all layers with actor
 * @returns {*}
 * @constructor
 */
function ActorLayersPopup({ actorId, title, onSelect, onClose }) {
  const t = useIntl();
  const popupRef = useRef();
  const [query, setQuery] = useState('');

  const {
    list: layers,
    loading,
    makeSearch,
    onScroll,
  } = useSearchList({
    actionType: GET_ACTOR_GLOBAL_LINKS.REQUEST,
    searchParams: { actorId, q: '' },
    settings: { step: 15 },
  });

  useOutsideClick({ ref: popupRef, callback: onClose });

  const handleSearch = ({ value }) => {
    const q = value.trimStart();
    setQuery(q);
    makeSearch({ q });
  };

  const renderLayersList = () => {
    const uniqueLayers = AppUtils.uniqueObjArray(layers, 'layerId');
    if (!query || title.toLowerCase().includes(query.toLowerCase())) {
      uniqueLayers.unshift({
        layerId: actorId,
        layerName: `${t(mes.actorsLayer)}: ${title}`,
        graphMode: 'actors',
        icon: 'dcircle',
      });
    }
    const result = uniqueLayers.map((item) => (
      <Stack.H
        key={`${item.layerId}_${item.graphFolderId}`}
        className={scss.layerItem}
        size={Stack.SIZE.xsmall}
        alignItems="center"
        fullWidth
        onClick={() => onSelect(item)}
      >
        <Avatar bgColor="#f6f7f8">
          <Icon type={item.icon || 'layer_filled'} />
        </Avatar>
        <Stack.V size="none" justifyContent="center" fullHeight>
          <Label overflow="cut" duplicatingTooltip value={item.layerName} />
          {item.graphName ? (
            <Label
              fontSize="small"
              overflow="cut"
              color={Label.COLOR.gray}
              value={item.graphName}
            />
          ) : null}
        </Stack.V>
      </Stack.H>
    ));
    return (
      <Stack.V
        fullWidth
        fullHeight
        size={Stack.SIZE.none}
        divider={<Divider />}
        style={{ overflowY: 'auto' }}
        onScroll={onScroll}
      >
        {result}
        {loading ? <ProgressBar size="small" /> : null}
      </Stack.V>
    );
  };

  return (
    <Card forwardRef={popupRef} style={{ width: 312, height: 300 }}>
      <Space size={Space.SIZE.small} fullHeight>
        <Stack.V size={Stack.SIZE.xsmall} fullWidth fullHeight>
          <TextField
            leftIcon="search"
            bordered
            unspaced
            resettable
            value={query}
            placeholder={t(mes.searchLayerByNameId)}
            onChange={handleSearch}
            onReset={() => handleSearch({ value: '' })}
          />
          <Label
            fontWeight="semibold"
            space={Space.SIZE.small}
            value={t(mes.actorsLayers)}
          />
          {cr(
            [loading && !layers.length, <ProgressBar />],
            [true, renderLayersList]
          )}
        </Stack.V>
      </Space>
    </Card>
  );
}

ActorLayersPopup.propTypes = {
  title: PropTypes.string,
  actorId: PropTypes.string,
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
};

export default ActorLayersPopup;
