import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { GET_USERS, SEARCH_USERS } from 'constants';
import { useSearchList, useIntl } from 'hooks';
import { canViewUsers } from './selectors';
import SelectEntityField from '../SelectEntityField';
import mes from './intl';

/**
 * Select user(-s) field
 */
function SelectUsers(props) {
  const { multiselect = true, exclude = [], fullModel = false, onBlur } = props;
  const t = useIntl();
  const canGetUsers = useSelector(canViewUsers);
  const [query, setQuery] = useState('');
  const excludeSet = exclude.map((i) => i.id);

  const [skipSearch, setSkipSearch] = useState(true);

  const { list, loading, makeSearch, onScroll } = useSearchList({
    actionType: ({ query }) =>
      query ? SEARCH_USERS.REQUEST : GET_USERS.REQUEST,
    skip: skipSearch,
    searchParams: { query: '' },
    settings: { step: 15 },
  });

  /**
   * Обработка поискового запроса
   */
  const handleSearch = (newQuery) => {
    setQuery(newQuery);
    makeSearch({ query: newQuery });
  };

  return (
    <SelectEntityField
      {...props}
      objType="user"
      multiselect={multiselect}
      list={list.filter((i) => !excludeSet.includes(i.id))}
      fullModel={fullModel}
      isDisabled={props.isDisabled || !canGetUsers}
      onSearch={handleSearch}
      onFocus={() => setSkipSearch(false)}
      onBlur={() => onBlur && onBlur()}
      onScroll={query ? null : onScroll} // There is no backend pagination when make search by query
      hideCreateBtn={true}
      isLoading={loading}
      resultLabel={t(mes.users)}
      resetOnBlur={false}
    />
  );
}

SelectUsers.propTypes = {
  exclude: PropTypes.array,
  fullModel: PropTypes.bool,
  onBlur: PropTypes.func,
  onClose: PropTypes.func,
};

export default SelectUsers;
