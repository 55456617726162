import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  createScriptApp: {
    id: 'createScriptApp',
    defaultMessage: 'Create script',
  },
  editScriptApp: {
    id: 'editScriptApp',
    defaultMessage: 'Edit script',
  },
  scriptAccess: {
    id: 'scriptAccess',
    defaultMessage: 'Script access',
  },
  customBarSettings: {
    id: 'scriptcustomBarSettings',
    defaultMessage: 'Custom bar',
  },
});

export default { ...globalIntl, ...m };
