import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  layerDeleted: {
    id: 'layerDeleted',
    defaultMessage: 'Layer deleted',
  },
  layerDeletedInfo: {
    id: 'layerDeletedInfo',
    defaultMessage: "Layer '{name}' has been deleted.",
  },
});

export default { ...globalIntl, ...m };
