/* eslint-disable no-console */
import { put, call, take, select, takeLatest, delay } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import {
  WS_CLOSE,
  WS_SEND,
  WS_OPEN,
  WS_ERROR,
  WS_RECONNECTION,
  WS_RECONNECTION_SUCCESS,
  SHOW_NOTIFY,
  NOTIFY_LEVEL,
  HIDE_NOTIFY,
  APP_AUTH_KEY,
} from 'constants';
import { Utils } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import { WS_SYNC_REACTIONS } from '@control-front-end/common/constants/reactions';

//
let ws = null;
const CONNECTING = 0;
const OPEN = 1;
const DEBUG = __DEV__ || AppUtils.isDebug(document.location.href);
const MAX_RECONNECTION = 10000000000;
const RECONNECTION_TIMEOUT = 10000;
const RECONNECTION_ERROR_ID = 'connectionError';

let RECONNECTION_COUNT = 0;
let PING_INTERVAL;
let STOP_RECONNECT = false;

/**
 * Reconnection to WS
 * @returns {IterableIterator<*>}
 */
function* reconnect() {
  yield put({
    type: HIDE_NOTIFY.REQUEST,
    payload: { id: RECONNECTION_ERROR_ID },
  });
  if (STOP_RECONNECT) {
    yield put({
      type: SHOW_NOTIFY.REQUEST,
      payload: {
        id: 'finalConnErrorStop',
        type: NOTIFY_LEVEL.ERROR,
        label: 'Please reload the page to connect to the server',
      },
    });
    return;
  }
  if (RECONNECTION_COUNT >= MAX_RECONNECTION) {
    yield put({
      type: SHOW_NOTIFY.REQUEST,
      payload: {
        id: 'finalConnError',
        type: NOTIFY_LEVEL.ERROR,
        label: 'Connection error. Something went wrong, please try later.',
      },
    });
    return;
  }
  yield put({
    type: SHOW_NOTIFY.REQUEST,
    payload: {
      id: RECONNECTION_ERROR_ID,
      type: NOTIFY_LEVEL.ERROR,
      label: 'Connection error. Trying to connect...',
    },
  });
  yield delay(RECONNECTION_TIMEOUT);
  yield put({ type: WS_OPEN });
  RECONNECTION_COUNT += 1;
}

/**
 * RUN WebSocket and async read messages
 */
function initWebsocket(config) {
  return eventChannel((emitter) => {
    const sid = Utils.fromStorage(APP_AUTH_KEY);
    const decodeSID = decodeURIComponent(atob(sid)); // NOSONAR
    const jsonSID = AppUtils.safeJSONParse(decodeSID, null);
    if (!jsonSID) return;
    const url = `${config.realtimeApi}/ws`;
    ws = new WebSocket(url, `${jsonSID.userId}|${jsonSID.token}`);

    ws.onopen = () => {
      if (DEBUG) console.log('%c[WS OPEN] - SUCCESS', 'color: green');
      emitter({
        type: HIDE_NOTIFY.REQUEST,
        payload: { id: RECONNECTION_ERROR_ID },
      });
      if (RECONNECTION_COUNT > 0 && !STOP_RECONNECT) {
        emitter({
          type: SHOW_NOTIFY.REQUEST,
          payload: {
            id: 'finalConnSuccess',
            type: NOTIFY_LEVEL.SUCCESS,
            label: 'Connection restored.',
          },
        });
        emitter({ type: WS_RECONNECTION_SUCCESS });
        emitter({ type: WS_SYNC_REACTIONS });
      }
      RECONNECTION_COUNT = 0;
      STOP_RECONNECT = false;
      // Send ping
      PING_INTERVAL = setInterval(() => {
        emitter({ type: WS_SEND, payload: { type: 'ping' } });
      }, 10000);
    };

    ws.onerror = (error) => {
      if (DEBUG)
        console.log(
          `%c[WS ERROR]: ${error.message || 'undef error'}`,
          'color: red'
        );
      clearInterval(PING_INTERVAL);
      return emitter({ type: WS_RECONNECTION });
    };

    ws.onclose = () => {
      if (DEBUG)
        console.log('%c[WS ERROR]: connection has been closed', 'color: red');
      clearInterval(PING_INTERVAL);
      return emitter({ type: WS_RECONNECTION });
    };

    ws.onmessage = (event) => {
      try {
        if (DEBUG) console.log('[WS RECEIVE]: ', event.data);
        const message = JSON.parse(event.data);
        const type = `WS_${Utils.toUnderscoreCase(message.type).toUpperCase()}`;
        if (!message.payload) return;
        return emitter({
          type,
          payload: JSON.parse(JSON.stringify(message.payload)),
        });
      } catch (e) {
        console.log(`%c[WS RECEIVE ERROR]: ${e.message}, 'color: red'`);
      }
    };

    window.addEventListener('online', () => {
      RECONNECTION_COUNT = 0;
      STOP_RECONNECT = false;
      emitter({ type: WS_CLOSE });
    });

    // unsubscribe function
    return () => {
      if (DEBUG) console.log('%c[WS CLOSE] - SUCCESS', 'color: green');
    };
  });
}

/**
 * Open WebSocket
 */
function* openWS() {
  const config = yield select((state) => state.config);
  const channel = yield call(initWebsocket, config);
  while (true) {
    // eslint-disable-line no-constant-condition
    try {
      const action = yield take(channel);
      yield put(action);
    } catch (e) {
      if (DEBUG) console.log(`%c[WS EXCEPTION]: ${e}`);
      break;
    }
  }
}

/**
 * Send message to WebSocket
 */
function* sendWS({ payload }, attempt = 1) {
  if (!ws || ws.readyState === CONNECTING) {
    if (DEBUG)
      console.log(
        `%c[WS SEND] - ERROR: socket has not opened yet. attempt: ${attempt}`,
        'color: red'
      );
    if (attempt < 6) {
      yield delay(200);
      // eslint-disable-next-line no-plusplus
      sendWS({ payload }, ++attempt); // NOSONAR
    } else if (DEBUG) {
      console.log(
        '%c[WS SEND] - ERROR: socket has not opened yet. The attempts ended',
        'color: red'
      );
    }
  } else if (ws.readyState !== OPEN) {
    if (DEBUG)
      console.log(
        '%c[WS SEND] - ERROR: socket is already closed',
        'color: red'
      );
  } else {
    ws.send(JSON.stringify(payload));
    if (DEBUG) console.log('[WS SEND]: ', payload);
  }
}

/**
 * Close WebSocket
 */
function closeWS() {
  if (!ws || ws.readyState !== OPEN) {
    if (DEBUG)
      console.log(
        '%c[WS CLOSE] - ERROR: socket has not opened yet',
        'color: red'
      );
    return;
  }
  ws.close();
}

/**
 * Остановить рекконекты при ошибке от сервера realtime
 */
function errorWS() {
  STOP_RECONNECT = true;
}

function* wsConnection() {
  yield takeLatest(WS_OPEN, openWS);
  yield takeLatest(WS_CLOSE, closeWS);
  yield takeLatest(WS_ERROR, errorWS);
  yield takeLatest(WS_SEND, sendWS);
  yield takeLatest(WS_RECONNECTION, reconnect);
}

export default wsConnection;
