import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Label, ToggleSwitch } from 'mw-style-react';

import { useIntl } from 'hooks';
import { COMMUNICATIONS_PUBLISH } from '@control-front-end/common/constants/communications';

import CreateActor from './CreateActor';
import m from './intl';

/*
 * Actors cards are available if there is at least some initial, empty viewData
 * And for CompanyCard actros - viewData is required, so setting up
 * at least stub for basict actor-card displaying
 */
const basicViewDataStub = {
  accounts: [],
  actorFields: [],
  actors: {},
  formsFields: [],
};

function CreateCompanyCardActor({ data, onSuccess, ...props }) {
  const t = useIntl();
  const dispatch = useDispatch();
  const [publicCompany, setPublicCompany] = useState(
    data.status === 'verified'
  );

  const accounts = useSelector((state) => state.accounts);

  const handleCompanyStatusToggle = useCallback(
    ({ value }) => setPublicCompany(value),
    []
  );

  // The case is that we don't selecet widget inside card-view editor but should set it to the viewData
  const handleModifyViewData = useCallback(({ viewData, formData }) => {
    const modifiedViewData = { ...viewData };
    if (formData.widget && formData.widget[0]) {
      modifiedViewData.actors = {
        ...(viewData.actors || {}),
        widgetChat: { actorId: formData.widget[0].value },
      };
    }
    return modifiedViewData;
  }, []);

  return (
    <CreateActor
      {...props}
      data={{
        ...data,
        ref: accounts.active,
        viewData: data.viewData ? data.viewData : basicViewDataStub,
        onSuccess: ({ actor }) => {
          dispatch({
            type: COMMUNICATIONS_PUBLISH.REQUEST,
            payload: {
              id: actor.id,
              status: publicCompany ? 'verified' : 'pending',
            },
            callback: data.onSuccess,
          });
        },
        modifyViewData: handleModifyViewData,
      }}
      /*
       * CreateActor modal customization to suit CompanyCard form needs.
       * TOMAKE: CreateActor modal should be refactored and splited into
       * different components to avoid lots of if-else statements
       */
      settings={{
        UI: {
          ...CreateActor.defaultProps.settings.UI,
          sectionsPanel: false,
          actorsMapTab: false,
          optionalFields: false,
          createAnotherActorCheckbox: false,
          description: true,
          bottomToggle: (
            <div>
              <ToggleSwitch
                value={publicCompany}
                onChange={handleCompanyStatusToggle}
                visibility={data.allowPublishing ? 'visible' : 'hidden'}
              />
              <Label value={publicCompany ? t(m.public) : t(m.private)} />
            </div>
          ),
        },
        actorCardViewEditor: {
          // Widget is required for CompanyCard form actors, so force it to display on the card preview of the editor
          forceDisplayWidgetSelector: true,
        },
      }}
      mainData={{
        status: publicCompany ? 'verified' : 'pending',
      }}
      modalName="CreateCompanyCardActor"
    />
  );
}

CreateCompanyCardActor.propTypes = {
  data: PropTypes.object,
  onSuccess: PropTypes.func,
};

export default CreateCompanyCardActor;
