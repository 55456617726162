import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, Space, Card, PopoverBoundary } from 'mw-style-react';
import { useIntl } from 'hooks';
import ContentWithStickyActions from '@control-front-end/common/components/ContentWithStickyActions';
import { ImportSettings } from 'components';

import m from './intl';
import scss from './ImportSettingsModal.scss';

/**
 * Renders a modal component for mapping access rules for participants.
 *
 * @param {object} props - The props object.
 * @param {function} props.onClose - The function to close the modal.
 * @param {object} props.data - The data object.
 * @param {function} props.data.onSubmit - The function to submit the mapping.
 * @param {array} props.data.participants - The array of participants.
 * @return {JSX.Element} The rendered modal.
 */
function ImportSettingsModal({
  onClose,
  data: { onSubmit, onCancel, participants: participantsPorp, scripts },
}) {
  const t = useIntl();

  const { boundaryRef } = useContext(PopoverBoundary.Context);

  const [importSettings, setImportSettings] = useState({});

  const handleSubmit = useCallback(
    (...args) => {
      onSubmit(importSettings);
      onClose(...args);
    },
    [importSettings]
  );

  const handleClose = useCallback((...args) => {
    onClose(...args);
    onCancel(...args);
  });

  return (
    <Modal
      size="xlarge"
      className={scss.modal}
      onClose={handleClose}
      label={t(m.rulesMapping)}
      strict
    >
      <ContentWithStickyActions
        content={
          <div ref={boundaryRef}>
            <ImportSettings
              participants={participantsPorp}
              scripts={scripts}
              onChange={setImportSettings}
            />
          </div>
        }
        actions={
          <Card>
            <Space>
              <Button
                size="large"
                fontWeight="normal"
                label={t(m.proceed)}
                fullWidth
                onClick={handleSubmit}
              />
            </Space>
          </Card>
        }
      />
    </Modal>
  );
}

ImportSettingsModal.propTypes = {
  data: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    participants: PropTypes.array.isRequired,
  }),
  onClose: PropTypes.func.isRequired,
};

export default ImportSettingsModal;
