import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  enterAccName: {
    id: 'enterAccName',
    defaultMessage: 'Enter account name',
  },
  enterAccCurrencyName: {
    id: 'enterAccCurrencyName',
    defaultMessage: 'Enter currency name',
  },
  selectedAccountName: {
    id: 'selectedAccountName',
    defaultMessage: 'Selected account',
  },
  selectedAccountCurrency: {
    id: 'selectedAccountCurrency',
    defaultMessage: 'Selected currency',
  },
  createNewAccount: {
    id: 'createNewAccount',
    defaultMessage: 'Create new account',
  },
  createAccountCurrencyPair: {
    id: 'createAccountCurrencyPair',
    defaultMessage: 'Create account & currency pair',
  },
  editAccount: {
    id: 'editAccount',
    defaultMessage: 'Edit account',
  },
  access: {
    id: 'access',
    defaultMessage: 'Access',
  },
  selectAccActor: {
    id: 'selectAccActor',
    defaultMessage: 'Select actor to create account',
  },
  formField: {
    id: 'formField',
    defaultMessage: 'Form field',
  },
  accActorAccessDenied: {
    id: 'accActorAccessDenied',
    defaultMessage: 'Access to the actor is denied',
  },
  containsValue: {
    id: 'containsValue',
    defaultMessage: 'Contains value',
  },
  multivalueDisabled: {
    id: 'multivalueDisabled',
    defaultMessage:
      'Cannot be changed to Single. Account balance already has multiple values.',
  },
  newFormFieldWarning: {
    id: 'newFormFieldWarning_CreateActorAccount',
    defaultMessage:
      'The account will be added to the form settings, meaning it will be added to all actors using this form.',
  },
  formSelectTitle: {
    id: 'formSelectTitle_CreateActorAccount',
    defaultMessage: 'Select name of form',
  },
  formSelectHelpText: {
    id: 'formSelectHelpText_CreateActorAccount',
    defaultMessage: 'The form to which the new account should be added',
  },
  sectionSelectTitle: {
    id: 'sectionSelectTitle_CreateActorAccount',
    defaultMessage: 'Select section',
  },
  sectionSelectHelpText: {
    id: 'sectionSelectHelpText_CreateActorAccount',
    defaultMessage: 'Section to which a new account should be added',
  },
  fieldTitle: {
    id: 'fieldTitle_CreateActorAccount',
    defaultMessage: 'Field title',
  },
});

export default { ...globalIntl, ...m };
