import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import api from '@control-front-end/common/sagas/api';
import {
  CREATE_FORM,
  GET_FORM,
  GET_FORM_ITEM_OPTIONS,
  GET_FORM_ITEMS_TITLES,
  UPDATE_FORM,
} from '@control-front-end/common/constants/forms';
import AppUtils from '@control-front-end/utils/utils';
import {
  DEL_MODAL,
  MANAGE_ACCESS_RULES,
  RequestStatus,
} from '@control-front-end/common/constants/index';
import { manageFormAccounts } from './formAccounts';
import { manageAccessRules } from './accessRules';

/**
 * Создать модель формы
 */
export function* makeFormModel(models) {
  const config = yield select((state) => state.config);
  models = Array.isArray(models) ? models : [models];
  for (const model of models) {
    model.ownerAvatar = AppUtils.makeUserAvatar(model, config);
    if (!model.access) {
      model.access = [];
    }
    for (const a of model.access) {
      a.avatar = AppUtils.makeUserAvatar(a, config);
    }
  }
}

/**
 * Получение списка options для элемента формы
 */
function* getFormItemOptions({ payload, callback, errorCallback }) {
  const { formId, formData, itemId, queryString, limit, offset } = payload;
  const encodedItemId = encodeURIComponent(itemId);
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/form_items/option_data/${formId}/${encodedItemId}`,
    body: formData,
    queryParams: { limit, offset, q: queryString },
    handleErrCodes: [400, 403],
  });
  if (result !== RequestStatus.SUCCESS) {
    if (errorCallback) errorCallback();
    return;
  }
  if (callback) callback(data.data);
}

/**
 * Получить форму по id
 */
export function* getForm({ payload, formActions, afterReq, callback }) {
  const { formId, withRelations = false, handleAccessDenied = false } = payload;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/forms/${formId}`,
    queryParams: { withRelations },
    handleErrCodes: [handleAccessDenied ? 403 : undefined],
  });
  if (result !== RequestStatus.SUCCESS) {
    if (formActions) formActions.setSubmitting(false);
    if (handleAccessDenied && callback)
      callback({ id: formId, title: 'Access Denied', accessDenied: true });
    return;
  }
  yield call(makeFormModel, data.data);
  if (callback) {
    callback(data.data);
  } else {
    yield put({
      type: GET_FORM.SUCCESS,
      payload: { ...data.data },
    });
  }
  if (afterReq) afterReq(data.data);
  return data.data;
}

/**
 * Сохранить форму
 */
export function* saveForm({ action, payload, formActions }) {
  const {
    form,
    formId,
    isTemplate,
    withRelations,
    access,
    actorsAccess,
    formAccounts,
  } = payload;
  const accounts = yield select((state) => state.accounts);
  const accId = accounts.active;
  const { result, data } = yield call(api, {
    method: action === 'create' ? 'post' : 'put',
    url:
      action === 'create'
        ? `/forms/${accId}/${isTemplate}`
        : `/forms/${formId}`,
    body: { ...form },
    queryParams: { withRelations },
  });
  if (result !== RequestStatus.SUCCESS) {
    if (formActions && formActions.setSubmitting) {
      formActions.setSubmitting(false);
    }
    return;
  }
  const newForm = { ...data.data };
  yield call(makeFormModel, newForm);
  // Добавить права на темплейт
  newForm.access = yield call(manageAccessRules, {
    payload: { body: access, objId: newForm.id, objType: 'formTemplate' },
  });
  // Добавить дефолтные права на акторы темплейта
  yield put({
    type: MANAGE_ACCESS_RULES.REQUEST,
    payload: {
      body: actorsAccess,
      objId: data.data.id,
      objType: 'templateActors',
    },
  });
  if (formAccounts && formAccounts.length) {
    yield call(manageFormAccounts, {
      payload: { formId: newForm.id, formAccounts },
    });
  }
  // обновление списка
  const formList = yield select((state) => state.formList);
  const list = structuredClone(formList.list);
  if (action === 'create') {
    // If we out of the batch limit than free space for the new item
    if (formList.limit === formList.list.length) {
      list.pop();
    }
    list.unshift(newForm);
  } else {
    const index = list.findIndex((i) => i.id === formId);
    if (index !== -1) {
      const formToUpdate = list[index];
      list.splice(index, 1, { ...formToUpdate, ...newForm });
    }
  }
  yield put({
    type: action === 'create' ? CREATE_FORM.SUCCESS : UPDATE_FORM.SUCCESS,
    payload: { list },
  });
  if (formActions && formActions.setSubmitting) {
    formActions.setSubmitting(false);
  }
  if (formActions && typeof formActions.callback === 'function') {
    yield put({
      type: GET_FORM.SUCCESS,
      payload: { ...newForm, isUpdated: true },
    });
    formActions.callback(newForm);
    return;
  }
  yield put({ type: DEL_MODAL });
  return newForm;
}

/**
 * Создать
 */
function* createForm({ payload, formActions }) {
  yield call(saveForm, { action: 'create', payload, formActions });
}

/**
 * Обновить
 */
function* updateForm({ payload, formActions }) {
  yield call(saveForm, { action: 'update', payload, formActions });
}

/**
 * Получить title по полям формам и их значениях
 */
function* getFormItemsTitles({ payload, callback }) {
  const { formId, items } = payload;
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/form_items/data_list/${formId}`,
    body: { items },
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({
    type: GET_FORM_ITEMS_TITLES.SUCCESS,
    payload: data.data,
  });
  if (callback) callback(data.data);
}

function* formView() {
  yield takeEvery(GET_FORM.REQUEST, getForm);
  yield takeEvery(GET_FORM_ITEM_OPTIONS.REQUEST, getFormItemOptions);
  yield takeEvery(GET_FORM_ITEMS_TITLES.REQUEST, getFormItemsTitles);
  yield takeLatest(CREATE_FORM.REQUEST, createForm);
  yield takeLatest(UPDATE_FORM.REQUEST, updateForm);
}

export default formView;
