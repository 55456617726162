import React, { useEffect, useState, useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Label, Icon, Badge } from 'mw-style-react';
import { useIntl, useViewport } from 'hooks';
import { VIEWPORT } from 'constants';
import cn from 'classnames';
import Menu from '../Menu/Menu';
import scss from './SlideoutMenu.scss';
import mes from './intl';

function SlideoutMenu() {
  const t = useIntl();
  const menuRef = useRef(null);
  const btnRef = useRef(null);
  const match = useRouteMatch();
  const [open, toggleMenu] = useState(false);
  const wsNotify = useSelector((state) => state.wsNotify);

  const actorView = useSelector((state) => state.actorView);

  const isMobile = useViewport() === VIEWPORT.MOB;

  const hideSandwichButton = Boolean(isMobile && actorView?.id);

  const handleClick = ({ target }) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(target) &&
      btnRef.current &&
      !btnRef.current.contains(target)
    ) {
      toggleMenu(false);
    }
  };
  useEffect(() => {
    const rootNode = document.getElementById('root');
    rootNode.addEventListener('click', handleClick, true);
    return () => rootNode.removeEventListener('click', handleClick);
  }, []);
  useEffect(() => toggleMenu(false), [match]);

  return (
    <>
      <div styleName={cn('slideout__bg', { open })}>
        <div ref={menuRef} styleName={cn('slideout__menu', { open })}>
          {open ? <Menu toggleMenu={toggleMenu} isSlideout={true} /> : null}
        </div>
      </div>
      <div className={scss.onTop}>
        <div
          ref={btnRef}
          className={cn(scss.slideout__bottom, {
            [scss.hidden]: hideSandwichButton,
          })}
          onClick={() => toggleMenu(!open)}
        >
          <div styleName="slideout__btn">
            <Icon type={open ? 'close' : 'list'} />
            {!open && wsNotify.length ? (
              <Badge styleName="slideout__btn__badge" />
            ) : null}
          </div>
          <Label fontWeight="semibold" value={t(mes.menu)} />
        </div>
      </div>
    </>
  );
}

export default SlideoutMenu;
