import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  copyActorId: {
    id: 'copyActorId',
    defaultMessage: 'Copy actor ID',
  },
  actorIdCopied: {
    id: 'actorIdCopied',
    defaultMessage: 'Actor ID has been copied to clipboard',
  },
  copyEdgeTypeId: {
    id: 'copyEdgeTypeId',
    defaultMessage: 'Copy link type ID',
  },
  copyEdgeId: {
    id: 'copyEdgeId',
    defaultMessage: 'Copy link ID',
  },
  edgeIdCopied: {
    id: 'edgeIdCopied',
    defaultMessage: 'Link ID has been copied',
  },
  edgeTypeIdCopied: {
    id: 'edgeTypeIdCopied',
    defaultMessage: 'Link type ID has been copied',
  },
  copyTemplateId: {
    id: 'copyTemplateId',
    defaultMessage: 'Copy form ID',
  },
  templateIdCopied: {
    id: 'templateIdCopied',
    defaultMessage: 'Form ID has been copied',
  },
  copyRefId: {
    id: 'copyRefId',
    defaultMessage: 'Copy ref',
  },
  refIdCopied: {
    id: 'refIdCopied',
    defaultMessage: 'Ref has been copied to clipboard',
  },
});

export default { ...globalIntl, ...m };
