import {
  GET_ACCOUNT_CURRENCIES,
  CREATE_ACCOUNT_CURRENCIES,
  UPDATE_ACCOUNT_CURRENCY,
  REMOVE_ACCOUNT_CURRENCY,
  SEARCH_ACCOUNT_CURRENCY,
  CLEAR_ACCOUNT_CURRENCIES,
  SET_ACCOUNT_CURRENCIES_REQ_STATUS,
} from '@control-front-end/common/constants/actorAccounts';
import { createReducer } from '@reduxjs/toolkit';

// ------------------------------------
// Reducer
// ------------------------------------

const initState = {
  list: [],
  limit: 20,
  offset: 0,
  endList: false,
  reqStatus: 'success',
};

export default createReducer(initState, {
  [GET_ACCOUNT_CURRENCIES.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [CREATE_ACCOUNT_CURRENCIES.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [UPDATE_ACCOUNT_CURRENCY.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [REMOVE_ACCOUNT_CURRENCY.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SEARCH_ACCOUNT_CURRENCY.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SET_ACCOUNT_CURRENCIES_REQ_STATUS](state, action) {
    return { ...state, reqStatus: action.payload };
  },
  [CLEAR_ACCOUNT_CURRENCIES]() {
    return initState;
  },
});
