import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  startEndDate: {
    id: 'startEndDate',
    defaultMessage: 'Start / End date',
  },
  rejected: {
    id: 'rejected',
    defaultMessage: 'Rejected',
  },
});

export default { ...globalIntl, ...m };
