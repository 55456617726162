import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const useDispatchEffect = ({ skip = false, ...props }, deps = []) => {
  const [data, setData] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    if (skip) return;
    dispatch({ ...props, callback: setData });
  }, [skip, ...deps]);
  return data;
};

export default useDispatchEffect;
