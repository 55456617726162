import { defineMessages } from 'react-intl';

const m = defineMessages({
  searchBy: {
    id: 'searchBy_ParticipantsMappingMaker',
    defaultMessage: 'Search by name or email',
  },
});

export default m;
