/**
 * Each defined element - is one of the UI elements form CreateActor modal window.
 * This elements can be turned on or disabled. Also there is opportunity to set some custom node instead
 */
export const FORM_UI = {
  sectionsPanel: 'sectionsPanel',
  actorsMapTab: 'actorsMapTab',
  optionalFields: 'optionalFields',
  createAnotherActorCheckbox: 'createAnotherActorCheckbox',
  bottomToggle: 'bottomToggle',
  description: 'description',
};
