import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Space, Tab, TabItem, useObjectState } from 'mw-style-react';

import { useIntl } from 'hooks';
import ParticipantsMappingMaker from './ParticipantsMappingMaker';
import CredentialsMappingMaker from './CredentialsMappingMaker';
import m from './intl';

const TAB = { users: 'users', credentials: 'credentials' };

function ImportSettings({ participants, scripts, onChange }) {
  const t = useIntl();

  const [tab, setTab] = useState(TAB.users);

  const [state, setState] = useObjectState(
    () => ({
      participants: participants.map((participant) => ({
        ...participant,
        mappedParticipants: [],
      })),
      scripts,
    }),
    {
      onChange,
    }
  );

  return (
    <Space size="large">
      {scripts.length ? (
        <Space bottom>
          <Tab
            value={tab}
            type="auto"
            underline={true}
            onChange={({ value }) => setTab(value)}
          >
            <TabItem label={t(m.users)} value={TAB.users} />
            <TabItem
              label={t(m.credentials)}
              value={TAB.credentials}
              rightIcon="alert"
            />
          </Tab>
        </Space>
      ) : null}
      {
        {
          [TAB.users]: (
            <ParticipantsMappingMaker
              participants={state.participants}
              onChange={setState.participants}
            />
          ),
          [TAB.credentials]: (
            <CredentialsMappingMaker
              scripts={state.scripts}
              onChange={setState.scripts}
            />
          ),
        }[tab]
      }
    </Space>
  );
}

ImportSettings.propTypes = {
  participants: PropTypes.array,
  scripts: PropTypes.array,
};

export default ImportSettings;
