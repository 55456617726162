import cn from 'classnames';
import getTranslation from '@control-front-end/utils/getTranslation';
import EXPAND_TYPE from '@control-front-end/app/src/components/GraphEngine/ExpandedActors/expandType';
import { NODE_WIDGET } from '@control-front-end/app/src/components/GraphEngine/ExpandedActors/NodeWidget/constants';
import { GRAPH_NODE_SIZE } from '@control-front-end/common/constants/graphActors';
import scss from './styles.scss';
import m from './myVerseIntl';

const STEP = {
  L: 365,
  M: 320,
  MS: 206,
  S: 62,
};

const WORKSPACE_MENU_HEIGHT = 304;

const NODES_STRUCTURE = {
  // ---------------- ROOT ----------------
  actor_profile: {
    title: getTranslation(m.myProfile),
    position: { x: 0, y: 0 },
    layerSettings: {
      height: 200,
      expand: false,
      expandType: EXPAND_TYPE.profile,
    },
    iconClassName: scss.actorIcon,
    nodes: {
      // ---------------- TOP BRANCH ----------------
      documentation: {
        title: getTranslation(m.documentation),
        position: { x: 0, y: -STEP.M },
        outerLink: 'https://doc.corezoid.com/docs/navigation-panel',
        className: cn(scss.menuNode, scss.documentation),
      },

      // ---------------- LEFT BRANCHES ----------------
      graphs: {
        title: getTranslation(m.graphs),
        position: { x: -STEP.L, y: -STEP.M },
        className: cn(scss.menuNode, scss.plusAdornment),
        iconClassName: scss.graphIcon,
        nodes: {
          graphs_0: {
            title: getTranslation(m.loading),
            position: { x: -STEP.MS, y: -STEP.S },
          },
          graphs_1: {
            title: getTranslation(m.loading),
            position: { x: -STEP.MS, y: 0 },
          },
          more_graphs: {
            title: getTranslation(m.more),
            position: { x: -STEP.MS, y: STEP.S },
            link: '/actors_graph/{{accId}}/graphs',
            className: cn(scss.menuNode, scss.innerTitle),
          },
        },
      },
      actor_bag: {
        title: getTranslation(m.actorBag),
        position: { x: -STEP.L, y: 0 },
        className: cn(scss.menuNode, scss.plusAdornment),
        iconClassName: scss.actorIcon,
        nodes: {
          actor_bag_0: {
            title: getTranslation(m.loading),
            position: { x: -STEP.MS, y: -STEP.S },
          },
          actor_bag_1: {
            title: getTranslation(m.loading),
            position: { x: -STEP.MS, y: 0 },
          },
          more_actor_bag: {
            title: getTranslation(m.more),
            position: { x: -STEP.MS, y: STEP.S },
            link: '/actors_graph/{{accId}}/list',
            className: cn(scss.menuNode, scss.innerTitle),
          },
        },
      },
      accounts: {
        title: getTranslation(m.accounts),
        position: { x: -STEP.L, y: STEP.M },
        className: cn(scss.menuNode, scss.plusAdornment),
        iconClassName: scss.accountIcon,
        nodes: {
          accounts_0: {
            title: getTranslation(m.loading),
            position: { x: -STEP.MS, y: -STEP.S },
          },
          accounts_1: {
            title: getTranslation(m.loading),
            position: { x: -STEP.MS, y: 0 },
          },
          more_accounts: {
            title: getTranslation(m.more),
            position: { x: -STEP.MS, y: STEP.S },
            link: '/actors_graph/{{accId}}/accounts',
            className: cn(scss.menuNode, scss.innerTitle),
          },
        },
      },

      // ---------------- BOTTOM BRANCH ----------------
      workspaces: {
        title: getTranslation(m.workspaces),
        position: {
          x: 0,
          y: STEP.M + WORKSPACE_MENU_HEIGHT / 2 - GRAPH_NODE_SIZE / 2,
        },
        widget: {
          name: NODE_WIDGET.workspaceSelector,
          opened: true,
        },
      },
      // ---------------- RIGHT BRANCHES ----------------
      connections: {
        title: getTranslation(m.connections),
        position: { x: STEP.L, y: -STEP.M },
        className: cn(scss.menuNode, scss.plusAdornment),
        iconClassName: scss.groupIcon,
        nodes: {
          connections_0: {
            title: getTranslation(m.loading),
            position: { x: STEP.MS, y: -STEP.S },
          },
          connections_1: {
            title: getTranslation(m.loading),
            position: { x: STEP.MS, y: 0 },
          },
          more_connections: {
            title: getTranslation(m.more),
            position: { x: STEP.MS, y: STEP.S },
            className: cn(scss.menuNode, scss.innerTitle),
          },
        },
      },
      events: {
        title: getTranslation(m.events),
        position: { x: STEP.L, y: 0 },
        className: cn(scss.menuNode, scss.plusAdornment),
        iconClassName: scss.eventsIcon,
        nodes: {
          events_0: {
            title: getTranslation(m.loading),
            position: { x: STEP.MS, y: -STEP.S },
          },
          events_1: {
            title: getTranslation(m.loading),
            position: { x: STEP.MS, y: 0 },
          },
          more_events: {
            title: getTranslation(m.more),
            position: { x: STEP.MS, y: STEP.S },
            link: '/events/{{accId}}/list/all',
            className: cn(scss.menuNode, scss.innerTitle),
          },
        },
      },
      accesses: {
        title: getTranslation(m.accesses),
        position: { x: STEP.L, y: STEP.M },
        className: cn(scss.menuNode, scss.plusAdornment),
        iconClassName: scss.starLayerIcon,
        nodes: {
          accesses_0: {
            title: getTranslation(m.loading),
            position: { x: STEP.MS, y: -STEP.S },
          },
          accesses_1: {
            title: getTranslation(m.loading),
            position: { x: STEP.MS, y: 0 },
          },
          more_accesses: {
            title: getTranslation(m.more),
            position: { x: STEP.MS, y: STEP.S },
            link: '/actors_graph/{{accId}}/list',
            className: cn(scss.menuNode, scss.innerTitle),
          },
        },
      },
    },
  },
};

export default NODES_STRUCTURE;
