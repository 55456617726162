import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Calendar as CalendarMW, DateUtils, TextField } from 'mw-style-react';
import { DATE_FORMAT_2, DATE_FORMAT_4 } from 'constants';
import '../Panel.scss';

/**
 * Компонент Calendar
 */
function Calendar({
  id,
  value = {},
  title,
  errors = {},
  onChange,
  time = false,
  timeZone = false,
}) {
  // Получить значение поля
  const getValue = () => {
    if (!value) return '';
    if (typeof value === 'string') return value;
    const { startDate } = value;
    const displayDateFormat = time ? DATE_FORMAT_4 : DATE_FORMAT_2;
    return startDate ? DateUtils.toDate(startDate, displayDateFormat) : '';
  };

  const error = errors[id];
  return (
    <div styleName={cn('panel__el', 'calendar')}>
      <TextField
        id={id}
        bordered={true}
        label={title}
        value={getValue()}
        error={!!error}
        helperText={error || ''}
        resettable
        onReset={() => onChange({ id, value: {} })}
        calendar={() => (
          <CalendarMW
            id={id}
            size="small"
            time={time}
            timeZone={timeZone}
            value={value}
            onChange={onChange}
          />
        )}
      />
    </div>
  );
}

Calendar.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  time: PropTypes.bool,
  timeZone: PropTypes.bool,
  errors: PropTypes.object,
  onChange: PropTypes.func,
};

export default Calendar;
