import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  GET_ACCOUNT_CURRENCY,
  GET_ACCOUNT_NAME,
} from '@control-front-end/common/constants/actorAccounts';

const emptyResult = { accountName: null, currencyName: null };

function useAccountPairNames({ nameId, currencyId, callback, skipRequest }) {
  const dispatch = useDispatch();

  const [accountName, setAccountName] = useState();
  const [currencyName, setCurrencyName] = useState();

  useMemo(() => {
    if (!nameId || !currencyId || skipRequest) return;

    dispatch({
      type: GET_ACCOUNT_NAME.REQUEST,
      payload: nameId,
      callback: ({ name }) => setAccountName(name),
    });

    dispatch({
      type: GET_ACCOUNT_CURRENCY.REQUEST,
      payload: currencyId,
      callback: ({ name }) => setCurrencyName(name),
    });
  }, [nameId, currencyId, skipRequest]);

  if (skipRequest || !accountName || !currencyName || !nameId || !currencyId) {
    return emptyResult;
  }

  if (callback) callback({ accountName, currencyName });
  return { accountName, currencyName };
}

export default useAccountPairNames;
