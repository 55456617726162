import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import {
  Icon,
  Label,
  Select,
  Stack,
  MenuItem,
  Button,
  Badge,
} from 'mw-style-react';
import { useIntl } from 'hooks';
import EmojiTextField from './EmojiTextField';
import { ViewDataPropType } from '../../propTypes';
import m from './intl';
import scss from './FormsFieldsSelector.scss';

function FieldSelector({ value, index, items, onChange, onRemoveItem }) {
  const t = useIntl();
  const [icon, setIcon] = useState(value?.icon);

  const handleChange = useCallback(
    ({ value }) => {
      onChange({ value: { ...value, icon }, index });
    },
    [onChange, value, index]
  );

  const handleIconChange = useCallback(
    ({ value: newIcon }) => {
      setIcon(newIcon);
      onChange({
        value: {
          ...value,
          icon: newIcon,
        },
        index,
      });
    },
    [onChange, value, index]
  );

  const handleRemoveItem = useCallback(() => {
    onRemoveItem({ index });
  }, [onRemoveItem, index]);

  useEffect(() => {
    setIcon(value?.icon);
  }, [value?.icon]);

  return (
    <Stack horizontal fullWidth alignItems="center" size={Stack.SIZE.small}>
      <div style={{ flexGrow: 1 }}>
        <Select
          size="medium"
          bordered
          unspaced
          className={scss.select}
          value={value?.title || value?.value || t(m.none)}
          onChange={handleChange}
        >
          {[null, ...items].map((i, index) => (
            <MenuItem
              key={index}
              label={i?.title || i?.value || t(m.none)}
              value={i}
            >
              {i ? (
                <Stack horizontal size="xxsmall" alignItems="center">
                  <Badge
                    size="small"
                    bgColor={i?.formColor || '#ffffff'}
                    borderColor={i?.formColor || '#ACB3BE'}
                  />
                  <Label fontSize="small" value={i?.formTitle || t(m.none)} />
                </Stack>
              ) : null}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={scss.emojiSelect}>
        <EmojiTextField value={icon} onChange={handleIconChange} />
      </div>
      <Button
        type="text"
        size="xsmall"
        icon={<Icon type="trash" />}
        onClick={handleRemoveItem}
      />
    </Stack>
  );
}

const ALLOWED_FIELD_TYPES = [
  'edit',
  'select',
  'multiSelect',
  'calendar',
  'check',
];

const getFlattenFormsFields = (forms) => {
  const formsFields = [];
  forms.forEach(({ form, id: formId }) => {
    form.sections.forEach((section) => {
      section.content.forEach(
        ({ id: itemId, title, value, class: fieldClass }) => {
          if (ALLOWED_FIELD_TYPES.includes(fieldClass)) {
            formsFields.push({
              id: formId + itemId,
              formId,
              itemId,
              formColor: form.color,
              formTitle: form.title,
              title,
              value,
              class: fieldClass,
            });
          }
        }
      );
    });
  });

  return formsFields;
};

const MAX_ITEMS_AMOUNT = 2;

function FormsFieldsSelector({ formsFields, onChange, actorForms }) {
  const t = useIntl();
  const isFirstRenderRef = useRef(true);
  const flattenFormsFields = useMemo(
    () => getFlattenFormsFields(actorForms),
    [actorForms]
  );
  const { allFields, preselectedFields } = useMemo(() => {
    return flattenFormsFields.reduce(
      (result, formField) => {
        const preselectedField = formsFields.find(
          (item) => item.formId + item.itemId === formField.id
        );

        const field = {
          ...formField,
          ...(preselectedField || { icon: '' }),
        };

        result.allFields.push(field);
        if (preselectedField) {
          result.preselectedFields.push(field);
        }
        return result;
      },
      { allFields: [], preselectedFields: [] }
    );
  }, [flattenFormsFields]);

  const [slots, setSlots] = useState(preselectedFields);

  const unselectedFields = useMemo(
    () =>
      allFields.filter(
        (field) =>
          !slots.find((selectedField) => selectedField?.id === field.id)
      ),
    [slots]
  );

  const handeleChange = useCallback(({ value, index }) => {
    setSlots((prevSlots) => {
      const newSlots = [...prevSlots];
      newSlots[index] = value;
      return newSlots;
    });
  }, []);

  const handleRemoveItem = useCallback(({ index }) => {
    setSlots((prevSlots) => {
      const newSlots = [...prevSlots];
      newSlots.splice(index, 1);
      return newSlots;
    });
  }, []);

  const handleAddItem = useCallback(() => {
    setSlots((prevSlots) => {
      return [...prevSlots, null];
    });
  }, []);

  useEffect(() => {
    // Skipp first render and trigger call-back when something is really changed
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
      return;
    }
    onChange({ value: slots.filter((slot) => Boolean(slot?.id)) });
  }, [slots]);

  return (
    <Stack>
      {slots.length ? (
        <Stack horizontal fullWidth className={scss.fieldsHeading}>
          <div className={scss.fieldLabel}>
            <Label color={Label.COLOR.black} value={t(m.field)} />
          </div>
          <div className={scss.iconLabel}>
            <Label color={Label.COLOR.black} value={t(m.icon)} />
          </div>
        </Stack>
      ) : null}
      {slots.map((slot, index) => {
        return (
          <FieldSelector
            key={index}
            value={slot}
            index={index}
            items={unselectedFields}
            onChange={handeleChange}
            onRemoveItem={handleRemoveItem}
          />
        );
      })}
      {slots.length < MAX_ITEMS_AMOUNT ? (
        <div>
          <Button
            size="small"
            icon="add"
            label={t(m.add)}
            type="text"
            onClick={handleAddItem}
          />
        </div>
      ) : null}
    </Stack>
  );
}

FormsFieldsSelector.propTypes = {
  formsFields: ViewDataPropType.formsFields,
};

export default FormsFieldsSelector;
