import { defineMessages } from 'react-intl';

const m = defineMessages({
  createLink: {
    id: 'createLink_Valency',
    defaultMessage: 'Create link',
  },
  helperTextParent: {
    id: 'helperTextParent_Valency',
    defaultMessage: 'Specify the parent actor for this graph',
  },
  helperTextChild: {
    id: 'helperTextChild_Valency',
    defaultMessage: 'Specify the child actor for this graph',
  },
});

export default m;
