import { defineMessages } from 'react-intl';

const m = defineMessages({
  users: {
    id: 'users_ImportSettings',
    defaultMessage: 'Users',
  },
  credentials: {
    id: 'credentials_ImportSettings',
    defaultMessage: 'Corezoid Credentials',
  },
});

export default m;
