import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

/**
 * Hook for handling search functionality.
 *
 * @param {Object} options - An object containing the following properties:
 *   - actionType: The type of action to dispatch.
 *   - skip: A boolean indicating whether to skip the search.
 *   - searchParams: An object containing search parameters.
 *   - strictEndCheck: check if list length < limit
 *   TODO: remove after refactor of createAccountsModels in packages/common/sagas/actorsAccounts.js
 *   - ...props: Additional props.
 * @return {Object} An object containing the following properties:
 *   - loading: A boolean indicating if the search is currently loading.
 *   - error: A boolean indicating if an error occurred during the search.
 *   - list: An array containing the search results.
 *   - isEnd: A boolean indicating if the end of the list has been reached.
 */
const useSearchLoader = ({
  actionType,
  skip,
  searchParams,
  strictEndCheck = true,
  callback: callbackProp,
  errorCallback: errorCallbackProp,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (skip) return;
    let isCancelled = false;
    setLoading(true);
    dispatch({
      type: actionType,
      payload: {
        ...searchParams,
        ...props,
      },
      callback: (data) => {
        if (isCancelled) return;
        setLoading(false);
        setError(false);
        setTotal(typeof data.total === 'number' ? data.total : null);
        setList(data.list || data);
        callbackProp?.(data);
      },
      errorCallback: (data) => {
        if (isCancelled) return;
        setLoading(false);
        setError(true);
        errorCallbackProp?.(data);
      },
    });

    return () => {
      isCancelled = true; // This will prevent setting the state if component unmounts or a new request is made
    };
  }, [actionType, skip, searchParams]);

  return {
    loading,
    error,
    list,
    total,
    /*
     * If there is no new items or if last batch size less than limit
     * - then we have reached the end of the list according to the given search params
     */
    isEnd:
      list.length === 0 || strictEndCheck
        ? list.length < (searchParams?.limit || 0)
        : false,
  };
};

export default useSearchLoader;
