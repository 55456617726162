import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Utils } from 'mw-style-react';

import { CLEAR_FORM } from '@control-front-end/common/constants/forms';
import { PM_APP_NAME } from 'constants';

import CreateForm from './components/CreateForm';

function FormSelection(props) {
  const { data = {}, onContentChange = () => {} } = props;
  const dispatch = useDispatch();
  const { search } = useLocation();
  const params = Utils.getQueryParam(search);

  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_FORM });
    };
  }, []);

  // Обработчик callback
  const handleCallback = (funcName, dataObj = {}) => {
    if (props[funcName]) {
      props[funcName](dataObj);
    }
  };

  const iFrameData = params.iFrame
    ? {
        parentId: params.parentId ? parseInt(params.parentId, 10) : null,
        formId: params.formId ? parseInt(params.formId, 10) : null,
      }
    : null;

  return (
    <CreateForm
      data={params.iFrame ? iFrameData : data}
      isIframe={params.iFrame === 'true' || false}
      onClose={() => {
        handleCallback('onClose');
        if (window.frameElement) {
          window.parent.postMessage(
            { appName: PM_APP_NAME, type: 'REMOVE_FORM_IFRAME' },
            '*'
          ); // NOSONAR
        }
      }}
      onFormSave={(res) => {
        handleCallback('onFormSave', res);
        if (window.frameElement) {
          window.parent.postMessage(
            { appName: PM_APP_NAME, type: 'FORM_SAVED_IFRAME', data: res },
            '*'
          ); // NOSONAR
        }
      }}
      onContentChange={onContentChange}
    />
  );
}

FormSelection.propTypes = {
  data: PropTypes.object,
  onContentChange: PropTypes.func,
};

export default FormSelection;
