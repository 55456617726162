import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Icon, Tooltip } from 'mw-style-react';
import getTranslation from '@control-front-end/utils/getTranslation';
import SelectActorsAccountPair from '../../../../../../SelectActorsAccountPair';
import './SelectActorsAccountWithNotify.scss';
import m from './intl';

const WARNING = {
  absent: 'absentAccount',
  wrongAccountType: 'wrongAccountType',
  accessDeniedActor: 'accessDeniedActor',
};

const WARNING_MESSAGES = {
  [WARNING.absentAccount]: getTranslation(m.absentAccount),
  [WARNING.accessDeniedActor]: getTranslation(m.accessDeniedActor),
};

function SelectActorsAccountWithNotify({
  nameId,
  currencyId,
  onChange,
  ...props
}) {
  const [activeAccount, setActiveAccount] = useState();
  const [dataError, setDataError] = useState(null);

  const warning = useMemo(() => {
    if (dataError?.statusCode === 403) {
      return WARNING.accessDeniedActor;
    }
    if (nameId && currencyId && !activeAccount) {
      return WARNING.absentAccount;
    }
    return null;
  }, [activeAccount, nameId, currencyId, dataError]);

  return (
    <>
      <SelectActorsAccountPair
        nameId={nameId}
        currencyId={currencyId}
        errorCallback={setDataError}
        onChange={(value) => {
          setActiveAccount(value);
          if (onChange) onChange(value);
        }}
        {...props}
        visibility={
          dataError && props.visibility === 'visible'
            ? 'disabled'
            : props.visibility
        }
      />
      {warning ? (
        <Tooltip styleName="warning__text" value={WARNING_MESSAGES[warning]}>
          <Icon type="info" color="#F8AB03" />
        </Tooltip>
      ) : null}
    </>
  );
}

SelectActorsAccountWithNotify.propTypes = {
  nameId: PropTypes.string,
  currencyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
};

export default SelectActorsAccountWithNotify;
