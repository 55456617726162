import { useState, useEffect, useCallback } from 'react';
import { Utils } from 'mw-style-react';
import useSearchLoader from './useSearchLoader';

const defaultSettings = {
  step: 10,
  debounce: 500,
  scrollToLoad: 500,
  scrollDebounce: 200,
};

/**
 * Helps to fetch, search, and paginate items.
 * Search params object (including limit and offset options) is passed to the saga.
 * Specific saga is defined by actionType param.
 *
 * @param {Object} options - The options object.
 * @param {Object} options.searchParams - The search parameters.
 * @param {Object} options.settings - The settings object.
 * @param {string} options.actionType - The action type.
 * @param {Object} options.rest - Additional parameters.
 * @return {Object} The search list object.
 */
const useSearchList = ({
  searchParams: searchParamsProp,
  settings: settingsProp,
  actionType,
  ...rest
}) => {
  const settings = { ...defaultSettings, ...(settingsProp || {}) };

  // The list of items that will be expanded after each pagination
  const [itemsToShow, setItemsToShow] = useState([]);

  // Search params object that will trigger new search after each change
  const [searchParams, setSearchParams] = useState({
    ...(searchParamsProp || {}),
    offset: 0,
    limit: settings.step,
  });

  // Load batch of items according to the provided params
  const {
    loading,
    list: searchResultItems,
    total,
    isEnd,
  } = useSearchLoader({
    searchParams,
    // There is cases, when actionType is depending on searchParams
    actionType:
      typeof actionType === 'function' ? actionType(searchParams) : actionType,
    ...rest,
  });

  // Reset items and starts new search with updated search params
  const makeSearch = useCallback(
    Utils.debounce((searchParamsUpdate) => {
      setSearchParams((prevValue) => ({
        ...prevValue,
        ...searchParamsUpdate,
        offset: 0,
      }));
      setItemsToShow([]);
    }, settings.debounce),
    []
  );

  const continueSearch = useCallback(
    Utils.debounce(() => {
      setSearchParams((prevValue) => ({
        ...prevValue,
        offset: prevValue.offset + settings.step,
      }));
    }, settings.debounce),
    []
  );

  // Scroll pagination handler that triggers next items batch fetching
  const handleScroll = Utils.debounce((e) => {
    if (isEnd || loading) return;
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    const scrollBottom = scrollHeight - (scrollTop + clientHeight);
    if (scrollBottom >= settings.scrollToLoad) return;
    setSearchParams((prevValue) => ({
      ...prevValue,
      offset: prevValue.offset + settings.step,
    }));
  }, settings.scrollDebounce);

  // Append new batch of items to the list
  useEffect(() => {
    setItemsToShow((prevValue) => [...prevValue, ...searchResultItems]);
  }, [searchResultItems]);

  return {
    searchParams,
    list: itemsToShow,
    total,
    loading,
    isEnd,
    makeSearch,
    continueSearch,
    onScroll: handleScroll,
  };
};
export default useSearchList;
