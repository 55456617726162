import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { Autocomplete, AutocompleteItem, Label, Popover } from 'mw-style-react';
import { useIntl, useScroll } from 'hooks';
import { SEARCH } from 'constants';
import AppUtils from '@control-front-end/utils/utils';
import { GET_TEMPLATE_ACTORS } from '@control-front-end/common/constants/graphActors';
import mes from './intl';
import scss from './SnippetsPopup.scss';

const initState = {
  list: [],
  limit: 15,
  offset: 0,
  endList: false,
  reqStatus: 'success',
};
const MAX_SEARCH_ATTEMPTS = 3;

/**
 * Выбор акторов быстрых фраз
 */
function SnippetsPopup(props) {
  const {
    messageNode,
    mode,
    message,
    isDisabled = false,
    handleSelect,
    handleSearchCached,
    handleUpdateCache,
  } = props;
  const t = useIntl();
  const dispatch = useDispatch();
  const snippetsBox = useRef();
  const { active: accId } = useSelector((state) => state.accounts) || {};
  const systemFormsSnippets =
    useSelector((state) => state.systemForms.snippets) || {};
  const snippetsFormId = systemFormsSnippets.id;
  const [actors, setActors] = useState(initState);
  const [isLoading, setLoading] = useState(false);
  const [noResultsCounter, setNoResultsCounter] = useState(0);
  const [noSnippetsExist, setNoSnippetsExist] = useState(false);
  const [focus, setFocus] = useState(false);
  const [actorsSearchRes, setActorsSearchRes] = useState([]);
  const [query, setQuery] = useState(message || '');

  const focusSearchField = () => {
    const inputEl = snippetsBox.current.querySelector('input');
    setTimeout(() => {
      inputEl.focus();
      inputEl.click();
    }, 10);
  };

  const handleKeyDown = (e) => {
    const searchInputEl = snippetsBox.current.querySelector('input');
    if (!searchInputEl) return;
    // дублируем событие нажатия кнопок для выбора фраз стрелками кливиатуры
    // (когда поле ввода скрыто в режиме подсказок)
    searchInputEl.dispatchEvent(new KeyboardEvent('keydown', e));
  };

  useEffect(() => {
    if (mode === 'auto') {
      messageNode.addEventListener('keydown', handleKeyDown);
      // autocomplete search field need to be clicked to show popover with search list
      const searchField = snippetsBox.current?.querySelector('.field');
      searchField?.click();
      return () => {
        messageNode.removeEventListener('keydown', handleKeyDown);
      };
    }
    setQuery('');
    focusSearchField();
  }, [mode]);

  useEffect(() => {
    if (mode === 'auto') setQuery(message);
  }, [message]);

  // Длина строки с составными эмоджи
  const getFancyCount = (str) => {
    return Array.from(str.split(/[\ufe00-\ufe0f]/).join('')).length;
  };

  const handleSearch = (fQuery) => {
    // для авторежима смотрим сначала кэш
    if (mode === 'auto') {
      const cachedResults = handleSearchCached(fQuery);
      if (cachedResults) {
        setActorsSearchRes(cachedResults);
        return;
      }
    }
    dispatch({
      type: SEARCH.REQUEST,
      payload: {
        filters: 'fullTextActor',
        formId: snippetsFormId,
        query: fQuery,
      },
      callback: (data) => {
        setActorsSearchRes(data);
        handleUpdateCache(query, data);
        if (mode === 'auto' && !data.length) {
          setNoResultsCounter((prevCounter) => prevCounter + 1);
        } else {
          setNoResultsCounter(0);
        }
      },
    });
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      const fQuery = query.replace(/[\r\n]+/gm, '');
      const qLength = getFancyCount(fQuery);
      const noAutoSearchAttempts =
        mode === 'auto' && noResultsCounter === MAX_SEARCH_ATTEMPTS;
      if (
        noSnippetsExist ||
        noAutoSearchAttempts ||
        qLength < 2 ||
        qLength > 255
      ) {
        return;
      }
      handleSearch(fQuery);
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [query]);

  const getSnippets = (loadMore = 'false') => {
    if (isLoading || actors.endList) return;
    setLoading(true);
    dispatch({
      type: GET_TEMPLATE_ACTORS.REQUEST,
      payload: {
        formId: snippetsFormId,
        loadMore,
        orderBy: 'updated_at',
        orderByKey: 'category',
        localState: actors,
      },
      callback: (data) => {
        setActors(data);
        setLoading(false);
        if (!loadMore && !data.list.length) {
          setNoSnippetsExist(true);
        }
      },
    });
  };

  useEffect(() => {
    getSnippets(false);
  }, []);

  const handleScroll = () => {
    if (query.length) return;
    getSnippets(true);
  };
  useScroll({ ref: snippetsBox, callback: handleScroll });

  /**
   * Обработка фокуса
   */
  const handleFocus = () => {
    setFocus(true);
  };

  const handleBlur = () => {
    setQuery('');
    setFocus(false);
    setActorsSearchRes([]);
    if (props.handleBlur) props.handleBlur();
  };

  const renderNoResults = () => {
    if (mode !== 'manual' || isLoading) return null;
    return (
      <AutocompleteItem
        styleName="snippets__item empty"
        id="not_found"
        size="small"
        value={query.length ? t(mes.noSnippetsFound) : t(mes.noSnippetsExist)}
        onClick={handleBlur}
      />
    );
  };

  const renderSearchResult = () => {
    if (!focus && !message) return null;
    const list = query.length ? actorsSearchRes : actors.list;
    const groupedByCategory = AppUtils.groupBy(list, 'data.category');
    const items = [];
    Object.keys(groupedByCategory).forEach((key) =>
      groupedByCategory[key].forEach((i, index) => {
        const formattedDescr = AppUtils.makeDescription({
          str: i.description,
          accId,
        })
          .replaceAll('&lt;', '<')
          .replaceAll('&gt;', '>');
        const snippetItem = (
          <AutocompleteItem
            key={i.id}
            id={i.id}
            styleName={cn('snippets__item', { titled: index === 0 })}
            value={i.title}
            children={
              <>
                <Label
                  styleName="snippets__item__category"
                  fontWeight="semibold"
                  value={key}
                />
                <Label
                  styleName="snippets__item__text"
                  fontSize="small"
                  value={AppUtils.removeHtmlTags(i.description, true) || '---'}
                />
              </>
            }
            onClick={() =>
              handleSelect(i.description ? formattedDescr : i.title, i.id)
            }
          />
        );
        items.push(snippetItem);
      })
    );
    if ((!query.length && !focus) || !items.length) return null;

    return <div>{list.length ? items : renderNoResults()}</div>;
  };

  return (
    <div ref={snippetsBox} styleName={cn('snippets', mode)}>
      <Autocomplete
        styleName="snippets__field"
        size="large"
        value={focus ? query : ''}
        autoFocus
        bordered
        unspaced
        onChange={({ value: q }) => setQuery(q)}
        onBlur={handleBlur}
        onFocus={handleFocus}
        visibility={isDisabled ? 'disabled' : 'visible'}
        searchResult={renderSearchResult}
        chipsItems={() => {}}
        popoverOnTop
        popoverOptions={{
          anchors: {
            binding: Popover.ANCHOR.left_top,
            content: Popover.ANCHOR.left_bottom,
          },
          contentClassName: scss.snippets__content,
        }}
      />
    </div>
  );
}

SnippetsPopup.propTypes = {
  messageNode: PropTypes.object,
  mode: PropTypes.oneOf(['auto', 'manual']),
  message: PropTypes.string,
  isDisabled: PropTypes.bool,
  handleSelect: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSearchCached: PropTypes.func.isRequired,
  handleUpdateCache: PropTypes.func.isRequired,
};

export default SnippetsPopup;
