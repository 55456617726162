import PropTypes from 'prop-types';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import {
  ThemeProvider,
  CorezoidLightTheme,
  PopoverBoundary,
} from 'mw-style-react';
import { IntlProviderWrapper } from 'IntlProvider';
import history from '../store/history';
import RootLayout from './layouts/RootLayout';

/**
 * Главный контейнер
 */
function AppContainer({ store, messages, locale }) {
  return (
    <Provider store={store}>
      <IntlProvider locale={locale} messages={messages}>
        <IntlProviderWrapper locale={locale} messages={messages}>
          <Router history={history}>
            <ThemeProvider theme={CorezoidLightTheme}>
              <PopoverBoundary.Provider>
                <RootLayout />
              </PopoverBoundary.Provider>
            </ThemeProvider>
          </Router>
        </IntlProviderWrapper>
      </IntlProvider>
    </Provider>
  );
}

AppContainer.propTypes = {
  messages: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
};

export default AppContainer;
