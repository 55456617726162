import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import JSONEditor from 'jsoneditor';
import cn from 'classnames';
import './JSONEditorReact.scss';
import AppUtils from '@control-front-end/utils/utils';
import { HOT_KEYS } from 'constants';

function JSONEditorReact(props) {
  const {
    jsonEditorRef,
    text,
    json,
    mode,
    schema,
    schemaRefs,
    onEditable = () => true,
  } = props;
  const editorRef = useRef();
  const withMenu = onEditable();

  /**
   * Обработка горячих клавиш для форматирования
   */
  const handleHotKeys = (e) => {
    if (AppUtils.isCtrlOrMetaKey(e) && e.keyCode === HOT_KEYS.KeyL) {
      jsonEditorRef.current.format();
    }
  };

  useEffect(() => {
    // copy all properties into options for the editor
    // (except the properties for the JSONEditorReact component itself)
    const options = { ...props };
    delete options.text;
    delete options.json;
    delete options.jsonEditorRef;
    jsonEditorRef.current = new JSONEditor(editorRef.current, options);
    if ('json' in props) {
      jsonEditorRef.current.set(json);
    }
    if ('text' in props) {
      jsonEditorRef.current.setText(text);
    }
    return () => {
      if (jsonEditorRef.current) {
        jsonEditorRef.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (!jsonEditorRef.current) return;
    document.addEventListener('keydown', handleHotKeys);
    return () => {
      document.removeEventListener('keydown', handleHotKeys);
    };
  }, [jsonEditorRef.current]);

  useEffect(() => {
    jsonEditorRef.current.set(json);
  }, [json]);

  useEffect(() => {
    jsonEditorRef.current.setText(text);
  }, [text]);

  useEffect(() => {
    jsonEditorRef.current.setMode(mode);
  }, [mode]);

  useEffect(() => {
    jsonEditorRef.current.setSchema(schema, schemaRefs);
  }, [schema, schemaRefs]);

  return <div styleName={cn('jse', { withMenu })} ref={editorRef} />;
}

JSONEditorReact.propTypes = {
  jsonEditorRef: PropTypes.object,
  json: PropTypes.object,
  text: PropTypes.string,
  mode: PropTypes.oneOf(['tree', 'form', 'view', 'code', 'text']),
  schema: PropTypes.object,
  schemaRefs: PropTypes.object,
};

export default JSONEditorReact;
