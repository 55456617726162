import { call, put, select, takeLatest } from 'redux-saga/effects';
import PropTypes from 'prop-types';
import { pick, omitBy } from 'lodash';
import {
  GET_USERS,
  GET_USERS_BULK,
  SEARCH_USERS,
  GET_USER,
  GET_USERS_ROLES,
  SEND_INVITE,
  GET_INVITES,
  UPDATE_INVITE,
  CANCEL_INVITE,
  SET_USERS_REQ_STATUS,
  UPDATE_USER_SETTINGS,
  RequestStatus,
  SET_GROUPS_REQ_STATUS,
  GET_GROUPS,
  GET_GROUP,
  GET_GROUP_MEMBERS,
  LOGIN_TYPE_NAMES,
  PARTICIPANT_TYPE,
} from 'constants';
import { Utils } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import api from '@control-front-end/common/sagas/api';

/**
 * Создать модель доступа для инвайта
 */
function createInvitesRules(list = []) {
  return list.map((i) => ({
    inviteId: i.id,
    isInvite: true,
    accId: i.accId,
    name: i.email,
    privs: {
      view: i.view,
      modify: i.modify,
      remove: i.remove,
      sign: i.sign,
      execute: i.execute,
    },
    role: i.role,
    userId: AppUtils.udid(),
    userType: 'user',
    ownerUserId: i.userId,
  }));
}

/**
 * Создать модель настроек воркспейса
 */
export function createSettingsModel(list = []) {
  const settingsObj = {};
  list.forEach((item) => {
    settingsObj[item.key] = item.data;
  });
  return settingsObj;
}

/**
 * Создать модель пользователя
 */
export function* createUserModel(list, useSorting = true) {
  list = Array.isArray(list) ? list : [list];
  if (useSorting) list = Utils.sort(list, 'nick');
  const config = yield select((state) => state.config);
  return list.map((user) => {
    if (user.error) return user;
    const loginObj =
      user.logins.find((i) => i.type === LOGIN_TYPE_NAMES.google) ||
      user.logins.find((i) => i.type === LOGIN_TYPE_NAMES.corezoid);
    const email = loginObj ? loginObj.login : '';
    return {
      id: user.id,
      saId: user.saId,
      value: user.nick,
      role: user.roles,
      type: user.type,
      avatar: AppUtils.makeUserAvatar(user, config),
      createdAt: user.createdAt,
      logins: user.logins,
      email,
    };
  });
}

/**
 * TOMAKE:
 *  1 - Describe common proptype for participant (user or group)
 *  2 - Step by step describe proptypes for all models
 *  3 - Use this proptypes anywhere so you can be sure in data validity
 */
const GroupPropType = {
  id: PropTypes.number.isRequired,
  saId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired, // Group name
  type: PropTypes.oneOf([PARTICIPANT_TYPE.group]),
  createdAt: PropTypes.number.isRequired,
};

/**
 * TOMAKE:
 *  1 - Create separate place for models and their proptypes storing.
 *  2 - Model proptypes should be easely imported in UI Components.
 */
export function createGroupModel(data) {
  return (Array.isArray(data) ? data : [data]).map((group) => {
    const groupModel = {
      ...group,
      id: group.id,
      userId: group.id,
      saId: group.saId,
      value: group.nick,
      type: group.type,
      createdAt: group.createdAt,
    };

    PropTypes.checkPropTypes(
      GroupPropType,
      groupModel,
      'group',
      'createGroupModel'
    );

    return groupModel;
  });
}

/**
 * Получить пользователей
 */
function* getUsers({ payload, callback }) {
  const accId = yield select((state) => state.accounts.active);
  const usersState = yield select((state) => state.users);
  if (
    usersState.reqStatus !== RequestStatus.SUCCESS ||
    (payload.loadMore && usersState.endList)
  )
    return;

  // Ставим флаг прогресса загрузки
  yield put({ type: SET_USERS_REQ_STATUS, payload: 'inProgress' });
  const queryParams = pick(
    { ...usersState, ...omitBy(payload, AppUtils.isNil) },
    ['limit', 'offset']
  );
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/users/${accId}`,
    queryParams,
  });
  // Ставим флаг окончания загрузки
  yield put({ type: SET_USERS_REQ_STATUS, payload: 'success' });
  if (result !== RequestStatus.SUCCESS) return;
  const newList = yield call(createUserModel, data.data);
  const resultList = payload.loadMore
    ? usersState.list.concat(newList)
    : newList;
  yield put({
    type: GET_USERS.SUCCESS,
    payload: {
      list: resultList,
      offset: queryParams.offset + queryParams.limit,
      endList: data.data.length < queryParams.limit,
    },
  });
  if (callback) callback(newList);
}

/**
 * Get multiple users by list of users ids
 */
function* getUsersBulk({ payload: { users, groups }, callback }) {
  const accounts = yield select((state) => state.accounts);
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/users/bulk/${accounts.active}`,
    body: {
      users,
      groups,
    },
  });

  if (result !== RequestStatus.SUCCESS) return;

  const usersResult = yield call(createUserModel, data.data.users);
  yield put({
    type: GET_USERS_BULK.SUCCESS,
  });
  if (callback) callback({ users: usersResult, groups: data.data.groups });
}

/**
 * Поиск пользователей
 */
function* searchUsers({ payload, callback }) {
  const accounts = yield select((state) => state.accounts);
  const accId = payload.accId || accounts.active;
  const { query, limit = 15, offset = 0 } = payload;
  const searchQ = AppUtils.safeSearchQuery(query);
  if (!searchQ.length) return;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/users/search/${accId}/${searchQ}`,
    queryParams: { limit, offset },
  });
  if (result !== RequestStatus.SUCCESS) return;
  const list = yield call(createUserModel, data.data);
  yield put({
    type: SEARCH_USERS.SUCCESS,
    payload: { list },
  });
  if (callback) callback(list);
}

/**
 * Получить пользователя по ID
 */
export function* getUser({ payload, callback }) {
  const accounts = yield select((state) => state.accounts);
  const accId = payload.accId || accounts.active;
  const { userId, type } = payload;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/users/${accId}/${userId}`,
    queryParams: { type },
  });
  if (result !== RequestStatus.SUCCESS || !data.data) return;
  const [user] = yield call(createUserModel, data.data);
  if (callback) callback(user);
  yield put({ type: GET_USER.SUCCESS });
  return user;
}

function* getUsersRoles({ callback }) {
  const accounts = yield select((state) => state.accounts);
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/users/roles/list/${accounts.active}`,
  });
  if (result !== RequestStatus.SUCCESS) return;

  if (callback) callback(data.data);
  yield put({ type: GET_USERS_ROLES.SUCCESS });
}

/**
 * Отправить приглашение пользователю
 */
function* sendInvite({ payload, callback }) {
  const { invitations, formActions } = payload;
  const accounts = yield select((state) => state.accounts);
  const invites = yield select((state) => state.invites);
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/invites/${accounts.active}`,
    body: invitations,
  });
  if (formActions) {
    formActions.setSubmitting(false);
  }
  if (result !== RequestStatus.SUCCESS) return;
  if (formActions) formActions.onClose();
  const copyList = invites.slice();
  const newInvites = data.data.map((i) => ({ ...i, isInvite: true }));
  yield put({
    type: SEND_INVITE.SUCCESS,
    payload: copyList.concat(newInvites),
  });
  if (callback) callback(data.data);
}

/**
 * Получить инвайты
 */
function* getInvites({ payload, callback }) {
  const { objId, objType } = payload;
  const accounts = yield select((state) => state.accounts);
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/invites/${accounts.active}`,
    queryParams: { objId, objType },
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (objId) {
    const invites = createInvitesRules(data.data);
    if (callback) callback(invites);
  } else {
    if (callback) callback(data.data);
    const invites = data.data.map((i) => ({ ...i, isInvite: true }));
    yield put({ type: GET_INVITES.SUCCESS, payload: invites });
  }
}

/**
 * Обновить инвайт
 */
function* updateInvite({ payload, callback }) {
  const { inviteId, objId, objType, privs } = payload;
  const { result, data } = yield call(api, {
    method: 'put',
    url: `/invites/${inviteId}`,
    body: [{ objId, objType, privs }],
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({ type: UPDATE_INVITE.SUCCESS, payload: data.data });
  if (callback) callback(data.data);
}

/**
 * Удалить инвайты
 */
function* cancelInvites({ payload, callback }) {
  const invites = yield select((state) => state.invites);
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/invites/cancel/${payload}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  const newInvitesList = invites.filter((i) => i.id !== payload);
  yield put({ type: CANCEL_INVITE.SUCCESS, payload: newInvitesList });
  if (callback) callback(data.data);
}

/**
 * Сохранить персональные настройки воркспейса
 */
export function* saveUserSettings({ payload, callback, formActions }) {
  const request = {
    method: 'post',
    url: `/users/settings`,
    body: payload,
  };
  if (payload.accId !== null) {
    const accounts = yield select((state) => state.accounts);
    const accId = payload.accId || accounts.active;
    request.queryParams = { accId };
  }
  const { result, data } = yield call(api, request);
  if (formActions) formActions.setSubmitting(false);
  if (result !== RequestStatus.SUCCESS) return;
  const prevSettings = yield select((state) => state.settings);
  const newSettings = createSettingsModel(data.data);
  yield put({
    type: UPDATE_USER_SETTINGS.SUCCESS,
    payload: { ...prevSettings, ...newSettings },
  });
  if (formActions) formActions.onClose();
  if (callback) callback();
}

/**
 * Получить группы пользователей
 */
function* getGroups({ payload, callback }) {
  const accounts = yield select((state) => state.accounts);
  const accId = payload.accId || accounts.active;
  const groupsState = yield select((state) => state.groups);
  if (
    groupsState.reqStatus !== RequestStatus.SUCCESS ||
    (payload.loadMore && groupsState.endList)
  )
    return;

  // Ставим флаг прогресса загрузки
  yield put({ type: SET_GROUPS_REQ_STATUS, payload: 'inProgress' });
  const queryParams = pick(
    { ...groupsState, ...omitBy(payload, AppUtils.isNil) },
    ['q', 'limit', 'offset']
  );
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/users/groups/list/${accId}`,
    queryParams,
  });
  // Ставим флаг окончания загрузки
  yield put({ type: SET_GROUPS_REQ_STATUS, payload: 'success' });
  if (result !== RequestStatus.SUCCESS) return;
  const newList = yield call(createGroupModel, data.data);
  const resultList = payload.loadMore
    ? groupsState.list.concat(newList)
    : newList;
  yield put({
    type: GET_GROUPS.SUCCESS,
    payload: {
      list: resultList,
      offset: queryParams.offset + queryParams.limit,
      endList: data.data.length < queryParams.limit,
    },
  });
  if (callback) callback(newList);
}

/**
 * Получить группу пользователей по ID
 */
export function* getGroup({ payload: { saId }, callback, errorCallback }) {
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/users/groups/${saId}`,
    handleErrCodes: errorCallback ? [403] : [],
  });
  if (result !== RequestStatus.SUCCESS) {
    if (errorCallback) errorCallback();
    return;
  }
  if (callback) callback(data.data);
  yield put({ type: GET_GROUP.SUCCESS });
}

/**
 * Получить состав группы
 */
export function* getGroupMembers({
  payload: { saId, userType, limit, offset, query },
  callback,
}) {
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/users/groups/members/${saId}/${userType}`,
    queryParams: { limit, offset, q: query },
  });
  if (result !== RequestStatus.SUCCESS) return;
  const members = yield call(createUserModel, data.data);
  yield put({ type: GET_GROUP.SUCCESS });
  if (callback) callback(members);
}

function* users() {
  yield takeLatest(GET_USERS.REQUEST, getUsers);
  yield takeLatest(GET_USERS_BULK.REQUEST, getUsersBulk);
  yield takeLatest(SEARCH_USERS.REQUEST, searchUsers);
  yield takeLatest(GET_USER.REQUEST, getUser);
  yield takeLatest(GET_USERS_ROLES.REQUEST, getUsersRoles);
  yield takeLatest(SEND_INVITE.REQUEST, sendInvite);
  yield takeLatest(GET_INVITES.REQUEST, getInvites);
  yield takeLatest(CANCEL_INVITE.REQUEST, cancelInvites);
  yield takeLatest(UPDATE_INVITE.REQUEST, updateInvite);
  yield takeLatest(UPDATE_USER_SETTINGS.REQUEST, saveUserSettings);
  yield takeLatest(GET_GROUPS.REQUEST, getGroups);
  yield takeLatest(GET_GROUP.REQUEST, getGroup);
  yield takeLatest(GET_GROUP_MEMBERS.REQUEST, getGroupMembers);
}

export default users;
