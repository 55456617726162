import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { TextField, Button } from 'mw-style-react';
import useIntl from 'useIntl';
import AppUtils from '@control-front-end/utils/utils';
import FormOptionColor from '../../../FormOptionColor/FormOptionColor';
import mes from '../../intl';
import './SelectOptions.scss';

const OPTIONS_LIST_WRAP_LIMIT = 5;

/**
 * Component for managing select options items
 */
function SelectOptions({
  id,
  options = [],
  isActive,
  className,
  onChange,
  handleInitOptions,
}) {
  const t = useIntl();
  const [listWrap, setListWrap] = useState(true);
  const itemsBox = useRef();
  const addAnswerEl = useRef();

  // Add default answer if empty options
  useEffect(() => {
    if (addAnswerEl.current && !options?.length) addAnswerEl.current.click();
  }, []);

  // Delete last selected option
  const focusLastOption = (type) => {
    if (!itemsBox.current) return;
    const inputs = itemsBox.current.getElementsByTagName('input');
    const inLen = inputs.length;
    if (type === 'add' && inLen <= 1) return;
    const lastInput = inputs[inLen - 1];
    if (!lastInput) return;
    lastInput.focus();
  };

  // Add new static answer
  const handleAddAnswer = () => {
    setListWrap(false);
    const newOptions = options.slice();
    newOptions.push({ value: AppUtils.createRid(), title: '' });
    onChange({ fieldId: 'options', id, value: newOptions });
    setTimeout(() => focusLastOption('add'), 10);
  };

  // Delete static answer
  const handleRemoveAnswer = (index, optLen) => {
    if (optLen <= 1) return;
    const newOptions = options.slice();
    newOptions.splice(index, 1);
    onChange({ fieldId: 'options', id, value: newOptions });
    setTimeout(() => focusLastOption('remove'), 10);
  };

  // Change static answer
  const handleAnswersChange = ({ index, value, title, color }) => {
    const newOptions = options.slice();
    newOptions.splice(index, 1, { value, title, color });
    onChange({ fieldId: 'options', id, value: newOptions });
  };

  // Create new option on press Enter
  const handleKeyUp = (e) => {
    if (e.key !== 'Enter') return;
    handleAddAnswer();
  };

  const renderOptionsItems = () => {
    const isRadio = className === 'radio';
    // If options is a variable
    if (typeof options === 'string') {
      return (
        <div styleName="select__item">
          {isRadio ? <div styleName="select__item__radio" /> : <div>1.</div>}
          <TextField
            id="options"
            value={options}
            placeholder={t(mes.answerOption)}
            rightIcon="close"
            onClickRightIcon={handleInitOptions}
            onChange={({ value }) => onChange({ fieldId: 'options', value })}
          />
        </div>
      );
    }
    // If options is an array
    const visibleList = listWrap
      ? options.slice(0, OPTIONS_LIST_WRAP_LIMIT)
      : options;
    return visibleList.map((i, index) => (
      <div key={index} styleName="select__item">
        {isRadio ? (
          <div styleName="select__item__radio" />
        ) : (
          <div>{index + 1}.</div>
        )}
        <TextField
          id={i.value}
          value={i.title}
          placeholder={t(mes.answerOption)}
          rightIcon={isActive ? 'close' : null}
          onClickRightIcon={() => handleRemoveAnswer(index, options.length)}
          onChange={({ id, value }) => {
            handleAnswersChange({
              index,
              value: id,
              title: value,
              color: i.color,
            });
          }}
          onKeyUp={handleKeyUp}
        />
        <FormOptionColor
          value={i.color}
          onChange={({ hex }) =>
            handleAnswersChange({ index, ...i, color: hex })
          }
        />
      </div>
    ));
  };

  return (
    <div styleName="select__options" ref={itemsBox}>
      {renderOptionsItems()}
      {options?.length > OPTIONS_LIST_WRAP_LIMIT ? (
        <div styleName="select__options__wrap">
          <Button
            styleName={cn('select__options__wrap__button', { listWrap })}
            size="xsmall"
            type="tertiary"
            icon="arrow_reverse"
            fontWeight="normal"
            onClick={() => {
              setListWrap((prevListWrap) => !prevListWrap);
            }}
          />
        </div>
      ) : null}
      {typeof options !== 'string' && isActive ? (
        <div
          ref={addAnswerEl}
          styleName="select__options__add"
          onClick={() => handleAddAnswer()}
        >
          {t(mes.addOption)}
        </div>
      ) : null}
    </div>
  );
}

SelectOptions.propTypes = {
  id: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  isActive: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default SelectOptions;
