import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { TextField } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import { URL_REGEXP } from '@control-front-end/common/constants/regExp';
import Edit from '../Edit/Edit';
import mes from '../../intl';
import './Link.scss';

/**
 * Компонент Link Constructor
 */
class Link extends PureComponent {
  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  render() {
    const { id, value, errors, titleLabel, urlLabel, onChange } = this.props;
    const error = errors ? errors.value : null;
    return (
      <div styleName="link">
        <Edit
          {...this.props}
          placeholder={titleLabel || this.i(mes.linkTitle)}
        />
        <TextField
          id={id}
          label={urlLabel || this.i(mes.linkUrl)}
          regexp={URL_REGEXP}
          error={!!error}
          helperText={error || ''}
          value={value}
          onChange={(data) => {
            const err = data.error ? { value: 'Please enter valid URL' } : {};
            onChange({ fieldId: 'value', errors: err, ...data });
          }}
        />
      </div>
    );
  }
}

Link.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  errors: PropTypes.object,
  titleLabel: PropTypes.string,
  urlLabel: PropTypes.string,
  onChange: PropTypes.func,
};

Link.contextTypes = {
  intl: intlShape,
};

export default injectIntl(Link);
