import { call, put, takeEvery } from 'redux-saga/effects';
import { RequestStatus } from 'constants';
import {
  GET_ACTOR_ACCOUNTS_ROLES,
  GET_ACCOUNTS_ROLES_TRANSACTIONS,
} from '../constants/actorAccounts';
import api from './api';
import { createAccountsModels } from './actorsAccounts';

/**
 * Get system accounts "roles" for actor
 */
function* getActorAccountsRoles({ payload, callback }) {
  const { actorId, type = 'actor' } = payload;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/accounts_executors/${type}_roles/${actorId}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({
    type: GET_ACTOR_ACCOUNTS_ROLES.SUCCESS,
    payload: { list: data.data },
  });
  const list = yield call(createAccountsModels, data.data);
  if (callback) callback({ list });
}

/**
 * Get transactions for system accounts "roles"
 */
function* getAccountsRolesTransactions({ payload, callback }) {
  const { actorId, type = 'actor', name, loadMore, transactions } = payload;
  const { endList, list, limit, offset } = transactions;
  if (endList) return;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/accounts_executors/transactions/${type}_roles/${actorId}/${name}`,
    queryParams: { limit, offset },
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({ type: GET_ACCOUNTS_ROLES_TRANSACTIONS.SUCCESS });
  if (callback) {
    callback({
      list: loadMore ? list.concat(data.data) : data.data,
      offset: offset + limit,
      limit,
      endList: data.data.length < limit,
    });
  }
}

function* accountExecutors() {
  yield takeEvery(GET_ACTOR_ACCOUNTS_ROLES.REQUEST, getActorAccountsRoles);
  yield takeEvery(
    GET_ACCOUNTS_ROLES_TRANSACTIONS.REQUEST,
    getAccountsRolesTransactions
  );
}

export default accountExecutors;
