import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalButtons,
  ModalContent,
  Button,
  ProgressBar,
  withForm,
  TextField,
  Tab,
  TabItem,
  Label,
  Checkbox,
  Stack,
} from 'mw-style-react';
import pickBy from 'lodash/pickBy';
import useIntl from 'useIntl';
import { UPDATE_USER_SETTINGS, DEFAULT_EXPORT_GRAPH_SETTINGS } from 'constants';
import AppUtils from '@control-front-end/utils/utils';
import ExportItemsList from './components/ExportItemsList/ExportItemsList';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import sModal from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import sLocal from './EditExportGraph.scss'; // NOSONAR

/**
 * Modal for editing export entities list and settings
 */
function EditExportGraphBase(props) {
  const {
    values,
    visibility,
    onClose,
    handleSubmit,
    handleOnChange,
    isSubmit,
  } = props;
  const t = useIntl();
  const [query, setQuery] = useState('');
  const [activeTab, setActiveTab] = useState('actors');
  const actorsCount = values.actors.length;
  const formsCount = values.forms.length;
  const list = values[activeTab];
  const resultList = query.length
    ? list.filter((i) => {
        const title = i.title.toLowerCase();
        return title.indexOf(query) !== -1;
      })
    : list;

  return (
    <Modal
      styleName="sModal.modal sLocal.modal"
      size="large"
      visibility={visibility}
      onClose={onClose}
      label={`${t(mes.exportGraph)} (${actorsCount + formsCount})`}
    >
      <ModalContent styleName="sModal.modal__content sLocal.content">
        <div styleName="sLocal.content__wrap">
          <TextField
            styleName="sModal.textfield"
            autoFocus={false}
            bordered={true}
            unspaced={true}
            size="large"
            value={query}
            placeholder={t(mes.findExportItem)}
            rightIcon="close"
            onChange={({ value }) => setQuery(value)}
            onClickRightIcon={() => setQuery('')}
            visibility={!actorsCount && !formsCount ? 'disabled' : 'visible'}
          />
          <Stack size={Stack.SIZE.small}>
            <Checkbox
              id="transactions"
              styleName="sLocal.content__checkbox"
              unspaced={true}
              value={values.transactions}
              onChange={handleOnChange}
            >
              <Label value={t(mes.exportAccounts)} />
            </Checkbox>
            <Checkbox
              id="processes"
              styleName="sLocal.content__checkbox"
              unspaced={true}
              value={values.processes}
              onChange={handleOnChange}
            >
              <Label value={t(mes.exportCorezoidEntities)} />
            </Checkbox>
            <Checkbox
              id="users"
              styleName="sLocal.content__checkbox"
              unspaced={true}
              value={values.users}
              onChange={handleOnChange}
            >
              <Label value={t(mes.exportUsers)} />
            </Checkbox>
            <Checkbox
              id="attachments"
              styleName="sLocal.content__checkbox"
              unspaced={true}
              value={values.attachments}
              onChange={handleOnChange}
            >
              <Label value={t(mes.exportAttachments)} />
            </Checkbox>
          </Stack>
          <Tab
            styleName="sLocal.content__tabs"
            value={activeTab}
            type="auto"
            underline={true}
            onChange={({ value }) => setActiveTab(value)}
          >
            <TabItem
              label={`${t(mes.actors)} ${
                actorsCount ? `(${actorsCount})` : ''
              }`}
              value="actors"
            />
            <TabItem
              label={`${t(mes.forms)} ${formsCount ? `(${formsCount})` : ''}`}
              value="forms"
            />
          </Tab>
          <ExportItemsList
            type={activeTab}
            list={resultList}
            handleOnChange={handleOnChange}
          />
        </div>
      </ModalContent>
      <ModalButtons styleName="sModal.modal__buttons">
        <Button
          label={t(mes.save)}
          size="large"
          onClick={handleSubmit}
          visibility={isSubmit ? 'disabled' : 'visible'}
        />
        <ProgressBar
          styleName="sModal.modal__loader"
          type="circle"
          size="small"
          visibility={isSubmit ? 'visible' : 'hidden'}
        />
      </ModalButtons>
    </Modal>
  );
}

const EditExportGraph = withForm(
  {
    mapPropsToValues({ data }) {
      return {
        ...DEFAULT_EXPORT_GRAPH_SETTINGS,
        ...pickBy(data, (i) => !AppUtils.isUndefined(i)),
      };
    },

    handleSubmit: (values, dispatch, formActions, props) => {
      formActions.onClose = props.onClose;
      const { forms, actors, transactions, processes, users, attachments } =
        values;
      dispatch({
        type: UPDATE_USER_SETTINGS.REQUEST,
        payload: {
          accId: props.accounts.active,
          exportGraph: {
            forms,
            actors,
            transactions,
            processes,
            users,
            attachments,
          },
        },
        formActions,
      });
    },
  },
  EditExportGraphBase
);

EditExportGraphBase.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleOnChange: PropTypes.func,
  isSubmit: PropTypes.bool,
  values: PropTypes.object,
};

export default EditExportGraph;
