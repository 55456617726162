import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DateUtils, Icon, Menu, MenuItem, PortalWrapper } from 'mw-style-react';
import cn from 'classnames';
import useIntl from 'useIntl';
import { DEL_MODAL, SET_MODAL } from 'constants';
import {
  ACTORS_FILTER_ACCESS,
  UPDATE_FILTER,
} from '@control-front-end/common/constants/actorsFilters';
import { TRANSACTIONS_FILTER_ACCESS } from '@control-front-end/common/constants/transactionsFilters';
import { STREAM_ACCESS } from '@control-front-end/common/constants/streams';
import {
  REMOVE_ACTOR,
  SYSTEM_ACTOR_CUSTOM_MODALS,
} from '@control-front-end/common/constants/graphActors';
import { PERMISSIONS } from '@control-front-end/common/constants/permissions';
import { checkUserPermissions } from '../../selectors';
import './ActorTabMenu.scss';
import mes from './intl';

/**
 * Контекстное меню для табов потока/фильтра
 */
function ActorTabMenu({ tabNode, actor, isActiveTab = false, handleCloseTab }) {
  const t = useIntl();
  const dispatch = useDispatch();
  const [menu, toggleMenu] = useState(false);
  const [hoverMenu, isHoveredMenu] = useState(false);
  const systemForms = useSelector((state) => state.systemForms);
  const actorFiltersFormId = systemForms.actorfilters.id;
  const transactionsFiltersFormId = systemForms.transactionfilters.id;
  const transfersFiltersFormId = systemForms.transferfilters.id;
  const isActorFilter =
    actor.formId === actorFiltersFormId ||
    actor.formId === transactionsFiltersFormId ||
    actor.formId === transfersFiltersFormId;
  const canEdit = actor.privs?.modify;
  const canRemove = actor.privs?.remove;
  const checkPermissions = useSelector(checkUserPermissions);

  const handleMouseOverTab = () => isHoveredMenu(true);
  const handleMouseOutTab = () => isHoveredMenu(false);

  useEffect(() => {
    if (tabNode) {
      tabNode.addEventListener('mouseover', handleMouseOverTab);
      tabNode.addEventListener('mouseout', handleMouseOutTab);
      return () => {
        tabNode.removeEventListener('mouseover', handleMouseOverTab);
        tabNode.removeEventListener('mouseout', handleMouseOutTab);
      };
    }
  }, [tabNode]);

  // Закрыть меню
  const closeMenu = () => toggleMenu(false);

  /**
   * Открыть модальное окно шаринга
   */
  const handleShare = () => {
    let accessAction;
    if (actor.formId === actorFiltersFormId) {
      accessAction = ACTORS_FILTER_ACCESS.REQUEST;
    } else if (actor.formId === transactionsFiltersFormId) {
      accessAction = TRANSACTIONS_FILTER_ACCESS.REQUEST;
    } else {
      accessAction = STREAM_ACCESS.REQUEST;
    }
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'ManageAccessRules',
        data: {
          objType: 'actor',
          objId: actor.id,
          rules: actor.access,
          ownerId: actor.user.id,
        },
        callback: (access) =>
          dispatch({ type: accessAction, payload: { id: actor.id, access } }),
      },
    });
  };

  const handleRemove = () => {
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'InfoModal',
        data: {
          title: 'removeActorsLinks',
          text: 'removeActorConfirm',
          buttons: [
            {
              label: t(mes.remove),
              onClick: () => {
                handleCloseTab();
                dispatch({
                  type: REMOVE_ACTOR.REQUEST,
                  payload: { id: actor.id, manageLayer: false },
                });
                dispatch({ type: DEL_MODAL });
              },
            },
          ],
        },
      },
    });
  };

  /**
   * Открыть модальное окно редактирования
   */
  const handleEdit = () => {
    const modalName =
      SYSTEM_ACTOR_CUSTOM_MODALS[actor.formTitle] || 'CreateActor';
    dispatch({
      type: SET_MODAL,
      payload: {
        name: modalName,
        data: isActorFilter
          ? { actorId: actor.id, formId: actor.formId }
          : {
              ...actor,
              form: { ...actor.form, id: actor.formId, title: actor.formTitle },
              forms: actor.forms,
              pictureUrl:
                actor.pictureUrl ||
                (actor.picture && `/api/1.0/download/${actor.picture}`),
            },
        callback: isActorFilter
          ? () => {
              dispatch({
                type: UPDATE_FILTER.SUCCESS,
                payload: { savedAt: DateUtils.unixtime() },
              });
            }
          : undefined,
        closeConfirm: true,
      },
    });
  };

  const handleMenuClick = ({ value }) => {
    switch (value) {
      case 'edit':
        handleEdit();
        break;
      case 'share':
        handleShare();
        break;
      case 'close':
        handleCloseTab();
        break;
      case 'remove':
        handleRemove();
        break;
      default:
        break;
    }
    closeMenu();
  };

  const renderCtxMenu = () =>
    tabNode ? (
      <PortalWrapper
        root={document.getElementById('mainRoot')}
        node={tabNode}
        vAlign="top"
        hAlign="right"
        offsetY={32}
      >
        <div styleName="actorTab__menu" onClick={(e) => e.stopPropagation()}>
          <Menu
            size="small"
            width={200}
            onClick={handleMenuClick}
            onClose={closeMenu}
          >
            <MenuItem
              size="small"
              label={t(mes.edit)}
              value="edit"
              visibility={canEdit ? 'visible' : 'disabled'}
            />
            <MenuItem
              size="small"
              label={t(mes.share)}
              value="share"
              visibility={
                canEdit && checkPermissions([PERMISSIONS.ACTORS_MANAGEMENT])
                  ? 'visible'
                  : 'disabled'
              }
            />
            <MenuItem
              size="small"
              label={t(mes.close)}
              value="close"
              visibility={canEdit ? 'visible' : 'disabled'}
            />
            <MenuItem
              size="small"
              label={t(mes.remove)}
              value="remove"
              visibility={canRemove ? 'visible' : 'disabled'}
            />
          </Menu>
        </div>
      </PortalWrapper>
    ) : null;

  return (
    <div styleName="actorTab__menu__wrap">
      <div
        styleName={cn('actorTab__menu__icon', {
          active: isActiveTab || hoverMenu,
        })}
        onClick={(e) => {
          e.stopPropagation();
          toggleMenu(!menu);
        }}
      >
        <Icon size="micro" type="dropdown" />
      </div>
      {menu ? renderCtxMenu() : null}
    </div>
  );
}

ActorTabMenu.propTypes = {
  tabNode: PropTypes.object,
  actor: PropTypes.object.isRequired,
  isActiveTab: PropTypes.bool,
  handleCloseTab: PropTypes.func.isRequired,
};

export default ActorTabMenu;
