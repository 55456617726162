import {
  FILTER_TRANSACTIONS,
  EXPORT_TRANSACTIONS,
  CLEAR_TRANSACTIONS,
  FILTER_TRANSACTIONS_REQ_STATUS,
  ADD_TRANSACTION,
  UPDATE_TRANSACTION,
} from '@control-front-end/common/constants/actorAccounts';
import { createReducer } from '@reduxjs/toolkit';

// ------------------------------------
// Reducer
// ------------------------------------

const initState = {
  list: [],
  limit: 15,
  offset: 0,
  total: 0,
  init: false,
  reqStatus: 'success',
  endList: false,
};

export default createReducer(initState, {
  [FILTER_TRANSACTIONS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [EXPORT_TRANSACTIONS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [UPDATE_TRANSACTION.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [ADD_TRANSACTION.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [FILTER_TRANSACTIONS_REQ_STATUS](state, action) {
    return { ...state, reqStatus: action.payload };
  },
  [CLEAR_TRANSACTIONS]() {
    return initState;
  },
});
