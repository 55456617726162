import React, {
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  createRef,
} from 'react';
import PropTypes from 'prop-types';
import useIntl from 'useIntl';
import cn from 'classnames';
import { Icon, Label, Menu, MenuItem, PortalWrapper } from 'mw-style-react';
import GridLayout from 'react-grid-layout';
import './FormNavigation.scss';
import mes from './intl';

/**
 * Панель навигации по секциям формы с возможностью менять их порядок
 */
function FormNavigation({
  sections,
  activeSection,
  readOnly = false,
  handleSelect,
  handleSortSection,
  handleRemoveSection,
}) {
  const t = useIntl();
  const sectionsRef = useRef([]);
  const [layout, setLayout] = useState([]);
  const [activeMenuIndex, setActiveMenuIndex] = useState(null);
  const [collapsed, setCollapsed] = useState(null);

  useLayoutEffect(() => {
    setCollapsed(!sections.length);
    sectionsRef.current = sections.map(
      (item, i) => sectionsRef.current[i] ?? createRef()
    );
  }, [sections.length]);

  useEffect(() => {
    const tagsLayout = sections.map((i, index) => ({
      i: index,
      x: 0,
      y: index,
      w: 1,
      h: 1,
      static: readOnly,
    }));
    setLayout(tagsLayout);
  }, [sections]);

  const handleDragSection = (newLayout, { y: oldIndex }, { y: newIndex }) => {
    if (oldIndex === newIndex) return;
    setLayout(newLayout);
    handleSortSection(oldIndex, newIndex, activeSection);
  };

  const handleClickSection = (e, sectionIndex) => {
    e.stopPropagation();
    handleSelect(sectionIndex);
  };

  const renderSectionMenu = (index) => (
    <PortalWrapper
      root={document.getElementById('mainRoot')}
      node={sectionsRef.current[index]}
      hAlign="right"
      offsetY={20}
      offsetX={4}
    >
      <div
        styleName="navigation__item__more__menu"
        onClick={(e) => e.stopPropagation()}
      >
        <Menu
          size="small"
          width={200}
          onClick={({ value }) => {
            if (value === 'delete') handleRemoveSection(activeMenuIndex);
            setActiveMenuIndex(null);
          }}
          onClose={() => setActiveMenuIndex(null)}
        >
          <MenuItem size="small" label={t(mes.remove)} value="delete" />
        </Menu>
      </div>
    </PortalWrapper>
  );

  const renderSections = () =>
    sections.map(({ title }, index) => (
      <div
        key={index}
        styleName={cn('navigation__item', { active: index === activeSection })}
        onClick={(e) => handleClickSection(e, index)}
      >
        {readOnly ? (
          <Label
            styleName="navigation__item__label"
            value={title || `Section ${index + 1}`}
          />
        ) : (
          <>
            <div styleName="navigation__item__drag" className="drag">
              <Icon type="drag" size="large" />
            </div>
            <Label
              styleName="navigation__item__label"
              value={title || `Section ${index + 1}`}
            />
            <div
              ref={(el) => (sectionsRef.current[index] = el)}
              styleName="navigation__item__more"
              onClick={(e) => {
                e.stopPropagation();
                setActiveMenuIndex(index);
              }}
            >
              <Icon type="more" />
              {activeMenuIndex === index ? renderSectionMenu(index) : null}
            </div>
          </>
        )}
      </div>
    ));

  return (
    <div styleName={cn('navigation', { collapsed, readOnly })}>
      <Label
        styleName="navigation__title"
        fontWeight="semibold"
        value={t(mes.navigation)}
      />
      <div
        styleName={cn('navigation__arrows', { collapsed })}
        onClick={() => setCollapsed(!collapsed)}
      >
        <Icon size="small" type="arrows" />
      </div>
      <div styleName="navigation__list">
        <GridLayout
          className="layout"
          layout={layout}
          cols={1}
          rowHeight={34}
          width={240}
          isDraggable={!readOnly}
          isBounded={true}
          draggableHandle=".drag"
          onDragStop={handleDragSection}
        >
          {renderSections()}
        </GridLayout>
      </div>
    </div>
  );
}

FormNavigation.propTypes = {
  sections: PropTypes.array,
  activeSection: PropTypes.number,
  readOnly: PropTypes.bool,
  handleSelect: PropTypes.func,
  handleSortSection: PropTypes.func,
  handleRemoveSection: PropTypes.func,
};

export default FormNavigation;
