import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Tab,
  TabItem,
  Chip,
  Tooltip,
  Clipboard,
  ProgressBar,
  forwardRef,
} from 'mw-style-react';
import cn from 'classnames';
import AppUtils from '@control-front-end/utils/utils';
import {
  DEL_MODAL,
  GET_ACCESS_RULES,
  NOTIFY_LEVEL,
  SET_MODAL,
  SHOW_NOTIFY,
} from 'constants';
import { useIntl, useMobileViewport } from 'hooks';
import {
  CLEAR_FORM,
  CREATE_FORM,
  GET_FORM,
  GET_FORM_ACCOUNTS,
  UPDATE_FORM,
} from '@control-front-end/common/constants/forms';
import TabsSelectPopup from '@control-front-end/common/components/TabsSelectPopup';
import FormConstructor from '@control-front-end/app/src/components/FormConstructor';
import { getCanEditForm } from '../../selectors';
import mes from './intl';
import './CreateForm.scss';

const ForwardTab = forwardRef(Tab);

function CreateForm(props) {
  const {
    onContentChange,
    onClose,
    onFormSave,
    data = {},
    isIframe = false,
  } = props;
  const t = useIntl();
  const formConstructor = useRef();
  const portal = useRef();
  const tabsRef = useRef();
  const dispatch = useDispatch();
  const formView = useSelector((state) => state.formView);
  const auth = useSelector((state) => state.auth);
  const canEdit = useSelector(getCanEditForm);
  const isMobile = useMobileViewport();
  const [activeTab, setActiveTab] = useState('constructor');
  const [isLoaded, setIsLoaded] = useState(!data.formId);
  const [errorsChecked, setErrorsChecked] = useState(false);
  const [actorsAccess, setActorsAccess] = useState([]);
  const [formAccounts, setFormAccounts] = useState([]);
  // Создать элементы куда будут вставлены поля для редактирования title и description
  const portalTitle = useRef(document.createElement('div'));

  const constructorTabs = [
    { id: 'constructor', title: t(mes.constr) },
    { id: 'preview', title: t(mes.preview) },
    { id: 'map', title: t(mes.uatMap) },
    { id: 'access', title: t(mes.formAccessRules) },
    { id: 'actorsAccess', title: t(mes.actorsAccessRules) },
    { id: 'accounts', title: t(mes.actorsDefAccounts) },
    { id: 'settings', title: t(mes.settings) },
  ];

  useEffect(() => {
    const { formId } = data;
    if (formId) {
      dispatch({
        type: GET_FORM.REQUEST,
        payload: { formId },
        afterReq: () => setIsLoaded(true),
      });
      dispatch({
        type: GET_ACCESS_RULES.REQUEST,
        payload: {
          objType: 'templateActors',
          objId: formId,
        },
        callback: setActorsAccess,
      });
      dispatch({
        type: GET_FORM_ACCOUNTS.REQUEST,
        payload: { formId },
        callback: setFormAccounts,
      });
    }
    // Динамическая вставка полей редактирования мета данных формы
    portal.current.appendChild(portalTitle.current);

    return () => {
      dispatch({ type: CLEAR_FORM });
    };
  }, []);

  const notifyErrors = (formErrors) => {
    formErrors.forEach((err) => {
      const fTitle = err.title || err.id;
      const fKey = err.key.replace(/:.*/g, '');
      setTimeout(
        () =>
          dispatch({
            type: SHOW_NOTIFY.REQUEST,
            payload: {
              id: AppUtils.createRid(),
              type: NOTIFY_LEVEL.ERROR,
              label: `${fTitle} ${fKey} error: ${err.message}.`,
            },
          }),
        500
      );
    });
  };

  // Получить json формы
  const getFormJson = () => {
    if (!formConstructor.current) return null;
    const formErrors = formConstructor.current.getFormEditorErrors();
    setErrorsChecked(true);
    if (formErrors.length) return { formErrors };
    return formConstructor.current.getForm();
  };

  // Сохранить форму
  const handleFormSave = (formJson) => {
    const { formId } = data;
    formJson.form.sections.forEach((section) => {
      section.content.forEach((item) => {
        if (item.extra && item.extra.optionsSource) {
          const { type } = item.extra.optionsSource;
          const NO_SOURCE_TYPES = [
            'manual',
            'currencies',
            'accountNames',
            'workspaceMembers',
          ];
          if (type && type !== 'manual') {
            item.options = [];
          }
          if (type && NO_SOURCE_TYPES.includes(type)) {
            delete item.extra.optionsSource.value;
          }
        }
      });
    });
    const actions = {
      onClose,
      setSubmitting: (value) => setIsLoaded(!value),
    };
    if (onFormSave) actions.callback = onFormSave;
    setIsLoaded(false);
    // исключаем пары счет+валюта без доступа
    const formAccounts = formJson.formAccounts.filter((acc) => !acc.error);
    dispatch({
      type: formId ? UPDATE_FORM.REQUEST : CREATE_FORM.REQUEST,
      payload: {
        isTemplate: true,
        withRelations: true,
        formId,
        form: formJson.form,
        access: formJson.access,
        actorsAccess: formJson.actorsAccess,
        formAccounts,
      },
      formActions: actions,
    });
  };

  const confirmUAT = (formJson) => {
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'InfoModal',
        data: {
          title: 'createUAT',
          text: 'createUATConfirm',
          buttons: [
            {
              label: t(mes.create),
              onClick: () => {
                handleFormSave(formJson);
                dispatch({ type: DEL_MODAL, payload: 'InfoModal' });
              },
            },
          ],
        },
      },
    });
  };

  const validateFormAccounts = (newAccounts) => {
    if (!newAccounts || !newAccounts.length) return [];
    const errors = [];
    const checkAccountLimits = (minLimit, maxLimit) => {
      if (!AppUtils.isUndefined(minLimit) && isNaN(minLimit))
        return 'Min limit must be a number';
      if (!AppUtils.isUndefined(maxLimit) && isNaN(maxLimit))
        return 'Max limit must be a number';
      if (Number(minLimit) > Number(maxLimit))
        return 'Min limit cannot be greater than max limit';
      return null;
    };
    newAccounts.forEach(({ data }) => {
      const limitsError = checkAccountLimits(data.minLimit, data.maxLimit);
      if (limitsError)
        errors.push({
          key: '',
          id: data.id,
          title: `Form account [${data.accountName} ${data.currencyName}]`,
          message: limitsError,
        });
    });
    return errors;
  };

  const handleSubmit = () => {
    const { formId } = data;
    const formJson = getFormJson();
    if (formJson.formErrors && formJson.formErrors.length) {
      notifyErrors(formJson.formErrors);
      return;
    }
    const formAccountsErrors = validateFormAccounts(
      formJson.formAccounts.filter((i) => i.action === 'create')
    );
    if (formAccountsErrors.length) {
      notifyErrors(formAccountsErrors);
      return;
    }
    if (!formId && !formJson.form.parentId) {
      confirmUAT(formJson);
    } else {
      handleFormSave(formJson);
    }
  };

  const renderTabs = () => (
    <ForwardTab
      ref={tabsRef}
      styleName="cf__tabs"
      value={activeTab}
      type="auto"
      onChange={({ value }) => {
        if (isLoaded) setActiveTab(value);
      }}
      underline={true}
    >
      {constructorTabs.map((tab) => (
        <TabItem
          key={tab.id}
          id={`tab_${tab.id}`}
          styleName="cf__tabs__item"
          label={tab.title}
          value={tab.id}
        />
      ))}
    </ForwardTab>
  );

  const renderIdChip = () => {
    if (!formView.id) return null;
    return (
      <>
        <Tooltip value={t(mes.copyFormId)}>
          <Chip
            styleName="cf__header__actions__chip"
            label={`ID: ${AppUtils.toNumberFormat(formView.id.toString())}`}
            fontWeight="normal"
            icon="copy"
            closeIcon={false}
            type="rectangular"
            onClick={() => {
              Clipboard.copy(formView.id.toString());
              dispatch({
                type: SHOW_NOTIFY.REQUEST,
                payload: {
                  id: AppUtils.createRid(),
                  type: NOTIFY_LEVEL.SUCCESS,
                  label: t(mes.formIdCopied),
                },
              });
            }}
          />
        </Tooltip>
        <div styleName="cf__header__actions__divider" />
      </>
    );
  };

  const renderMainContent = () => (
    <FormConstructor
      key={formView.id}
      formId={+formView.id}
      parentId={props.data.parentId || formView.parentId}
      activeSection={props.data.activeSection}
      activeItem={props.data.activeItem}
      action={canEdit ? activeTab : 'preview'}
      formData={formView.form}
      formAccounts={formAccounts}
      access={formView.access || [AppUtils.makeAuthUserAsOwner(auth)]}
      actorsAccess={actorsAccess}
      ownerId={formView.ownerId || auth.id}
      canEdit={canEdit}
      isNew={!data.formId}
      isIframe={isIframe}
      errorsChecked={errorsChecked}
      defaultTitle={data.title}
      titlePortal={portalTitle.current}
      ref={formConstructor}
      onContentChange={onContentChange}
    />
  );

  return (
    <div styleName="cf__wrap">
      <div styleName={cn('cf', { readOnly: !canEdit })}>
        <div styleName="cf__header">
          <Button
            styleName="cf__header__btn"
            type="text"
            size="smallplus"
            fontWeight="normal"
            label="Cancel"
            visibility={isLoaded ? 'visible' : 'disabled'}
            onClick={onClose}
          />
          <div styleName="cf__header__title" ref={portal}>
            {renderIdChip()}
          </div>
          <div styleName="cf__header__actions">
            {canEdit ? (
              <Button
                size="smallplus"
                fontWeight="normal"
                label={data.formId ? t(mes.update) : t(mes.create)}
                visibility={isLoaded ? 'visible' : 'disabled'}
                onClick={handleSubmit}
              />
            ) : null}
          </div>
        </div>
        {canEdit ? (
          <div styleName="cf__tabs__wrap">
            {renderTabs()}
            {isMobile ? (
              <TabsSelectPopup
                tabsRef={tabsRef}
                tabs={constructorTabs}
                activeItem={activeTab}
                showSearch={false}
                onSelect={(tabId) => setActiveTab(tabId)}
              />
            ) : null}
          </div>
        ) : null}
        {renderMainContent()}
        {!isLoaded ? (
          <div styleName="cf__loader">
            <ProgressBar type="circle" size="large" />
          </div>
        ) : null}
      </div>
    </div>
  );
}

CreateForm.propTypes = {
  data: PropTypes.object,
  isIframe: PropTypes.bool,
  onClose: PropTypes.func,
  onFormSave: PropTypes.func,
  onContentChange: PropTypes.func,
};

export default CreateForm;
