import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Icon, Label, Button } from 'mw-style-react';
import useIntl from 'useIntl';
import cn from 'classnames';
import { GET_ACCOUNT_FORMULA_INFO } from '@control-front-end/common/constants/actorAccounts';
import AppUtils from '@control-front-end/utils/utils';
import { RE_UUID_GLOBAL } from '@control-front-end/common/constants/regExp';
import AccountChip from '../AccountChip';
import FormulaTextInput from '../FormulaTextInput';
import mes from './intl';
import './FormulaField.scss';

const funcs = [
  { title: 'SUM', func: 'sum' },
  { title: 'AVERAGE', func: 'mean' },
];

function FormulaField(props) {
  const {
    accountId,
    error,
    errorMessage,
    incomeType,
    accountType,
    handleOnChange,
    autoFocus = false,
    isDisabled = false,
    formula = '',
  } = props;
  const t = useIntl();
  const dispatch = useDispatch();
  const formulaRef = useRef();
  const [active, setActive] = useState(false);
  const [formulaInfo, setFormulaInfo] = useState(null);
  const [formulaItems, setFormulaItems] = useState([]);
  const hasErrors = error || formula.toLowerCase().indexOf('error') !== -1;
  const formulaItemsLen = formulaItems.length;

  /**
   * Проверка поля на режим редактирования
   */
  const isEditModel = () => {
    if (!formulaRef.current) return false;
    const activeEl = document.activeElement;
    return (
      formulaRef.current.contains(activeEl) && activeEl.tagName === 'INPUT'
    );
  };

  /**
   * Заменить подписи ф-ций на их реальные значения и обратно
   */
  const replaceFuncs = (value, replaceType = 'func') => {
    let copyFormula = value;
    for (const i of funcs) {
      const repl = replaceType === 'func' ? i.func : i.title;
      const nV = replaceType === 'func' ? i.title : i.func;
      const re = new RegExp(repl, 'g');
      copyFormula = copyFormula.replace(re, nV);
    }
    return copyFormula;
  };

  /**
   * Формирование строки формулы
   */
  const makeFormula = (items = []) => {
    if (formula.length && !items.length) return;
    let err = false;
    const formulaArr = [];
    for (const i of items) {
      const v = i.value || {};
      if (i.error) err = true;
      if (i.type === 'text') {
        formulaArr.push(i.value);
      } else if (i.type === 'account' && v.id) {
        formulaArr.push(i.value.id);
      }
    }
    const errLabel = '{{ERROR}}';
    let value = err
      ? `${errLabel}:${formulaArr.join('')}`
      : formulaArr.join('');
    value = replaceFuncs(value, 'title');
    if (formula !== value) {
      handleOnChange({
        id: `${incomeType}Formula`,
        value,
        error: value.includes(errLabel),
      });
    }
  };

  /**
   * Сформировать текстовый item
   */
  const getEmptyTextItem = (value = '') => ({
    id: AppUtils.udid(),
    type: 'text',
    value,
  });

  /**
   * Объеденить текстовые блоки, которые идут подряд
   */
  const mergeSublingTextItems = (items) => {
    const newItems = [];
    let prevItemType = '';
    items.forEach((i) => {
      if (prevItemType === 'text' && i.type === 'text') {
        const prevItem = newItems[newItems.length - 1];
        prevItem.value += i.value;
        prevItem.id = AppUtils.udid();
      } else {
        newItems.push(i);
      }
      prevItemType = i.type;
    });
    return newItems;
  };

  /**
   * Парсим формулу на массив фрагментов
   */
  const getFormulaItems = () => {
    if (!formula.length) return [getEmptyTextItem()];
    const accIdRegex = new RegExp(`(${RE_UUID_GLOBAL})`);
    const correctFormula = replaceFuncs(formula, 'func');
    const tmp = correctFormula.split(accIdRegex);
    const items = tmp.map((i) => {
      if (accIdRegex.test(i)) {
        const value = formulaInfo[i];
        return { id: AppUtils.udid(), type: 'account', value, error: !value };
      }
      return getEmptyTextItem(i);
    });
    items.push(getEmptyTextItem());
    return mergeSublingTextItems(items);
  };

  useEffect(() => {
    dispatch({
      type: GET_ACCOUNT_FORMULA_INFO.REQUEST,
      payload: accountId,
      callback: (result) => setFormulaInfo(result),
    });
  }, []);

  useEffect(() => {
    if (!formulaInfo) return;
    const items = getFormulaItems();
    setFormulaItems(items);
  }, [formulaInfo]);

  useEffect(() => {
    makeFormula(formulaItems);
  }, [formulaItems]);

  /**
   * Изменения элемента в формуле
   */
  const onChange = ({ index, value, error: err }) => {
    setFormulaItems((prev) => {
      const copy = prev.slice();
      const item = formulaItems[index];
      copy.splice(index, 1, { ...item, value, error: err });
      return copy;
    });
  };

  /**
   * Удалить элемент из формулы
   */
  const onRemove = ({ index }) => {
    setFormulaItems((prev) => {
      const copy = prev.slice();
      copy.splice(index, 1);
      return mergeSublingTextItems(copy);
    });
  };

  /**
   * Добавить элемент в формулу
   */
  const onAdd = ({ index, type }) => {
    setFormulaItems((prev) => {
      const copy = prev.slice();
      copy.splice(index + 1, 0, { id: AppUtils.udid(), type, value: {} });
      copy.splice(index + 2, 0, getEmptyTextItem());
      return mergeSublingTextItems(copy);
    });
  };

  /**
   * Фокус на последнем текстовом элементе
   */
  const focusLastTextItem = (e) => {
    if (e.target !== formulaRef.current) return;
    const lastItem = formulaItems[formulaItems.length - 1];
    if (lastItem.type !== 'text') return;
    const input = document.querySelector(`#textInput_${lastItem.id} input`);
    input.focus();
  };

  return (
    <div styleName="formula__wrap">
      <Label
        fontWeight="semibold"
        value={t(mes[`${incomeType}Formula`])}
        visibility={isDisabled ? 'disabled' : 'visible'}
      />
      <div
        styleName={cn(
          'formula',
          { active: active || isEditModel() },
          { error: hasErrors && !isEditModel() },
          { disabled: isDisabled }
        )}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
      >
        <div styleName="formula__left">
          <Icon type="func" />
        </div>
        <div
          ref={formulaRef}
          styleName="formula__field"
          onClick={focusLastTextItem}
        >
          {formulaItems.map((item, index) =>
            item.type === 'account' ? (
              <AccountChip
                key={item.id}
                id={item.id}
                index={index}
                data={item.value || {}}
                error={item.error}
                accountType={accountType}
                onRemove={() => onRemove({ index })}
                onChange={(ch) => onChange({ index, ...ch })}
              />
            ) : (
              <FormulaTextInput
                key={item.id}
                id={item.id}
                index={index}
                text={item.value}
                error={item.error}
                funcs={funcs}
                isLast={formulaItemsLen - 1 === index}
                formulaItems={formulaItems}
                autoFocus={autoFocus}
                onAdd={(type) => onAdd({ index, type })}
                onChange={({ value }) => onChange({ index, value })}
              />
            )
          )}
        </div>
        <Button
          styleName="formula__close"
          type="tertiary"
          size="small"
          rounded={true}
          icon="close"
          visibility={formula.length ? 'visible' : 'hidden'}
          onClick={() => setFormulaItems([getEmptyTextItem()])}
        />
      </div>
      {error ? (
        <Label fontSize="small" error={true} value={errorMessage} />
      ) : null}
    </div>
  );
}

FormulaField.propTypes = {
  accountId: PropTypes.string.isRequired,
  formula: PropTypes.string,
  error: PropTypes.string,
  autoFocus: PropTypes.bool,
  errorMessage: PropTypes.string,
  incomeType: PropTypes.oneOf(['debit', 'credit']),
  accountType: PropTypes.oneOf(['fact', 'plan']),
  isDisabled: PropTypes.bool,
  handleOnChange: PropTypes.func,
};

export default FormulaField;
