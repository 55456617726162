import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  menu: {
    id: 'menu',
    defaultMessage: 'Menu',
  },
});

export default { ...globalIntl, ...m };
