import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import useIntl from 'useIntl';
import useScroll from 'useScroll';
import { useNotifications } from 'hooks';
import {
  GET_TRIGGER_CALLS,
  RESEND_TRIGGER_CALL,
} from '@control-front-end/common/constants/actorAccounts';
import { Modal, ModalContent, ProgressBar } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import EmptyList from '@control-front-end/common/components/EmptyList';
import TriggerCall from './components/TriggerCall';
import TriggerFilters from './components/TriggerFilters';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import sModal from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import sLocal from './TriggerHistory.scss'; // NOSONAR

const initState = {
  list: [],
  limit: 15,
  offset: 0,
  endList: false,
  reqStatus: 'success',
};

/**
 * Trigger calls list
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function TriggerHistory(props) {
  const { data, visibility, onClose, dispatch } = props;
  const t = useIntl();
  const listRef = useRef();
  const defaultRange = AppUtils.getRangeDate('lastMonth');
  const defaultFilter = {
    from: defaultRange.from * 1000,
    to: defaultRange.to * 1000 + 999,
  };
  const [filter, setFilter] = useState({
    ...defaultFilter,
    trigger: data.trigger,
    actors: data.actors,
    accounts: data.accounts,
  });
  const [calls, setCalls] = useState(initState);
  const [isLoading, setLoading] = useState(false);
  const { showNotification } = useNotifications();

  const getTriggerCalls = (loadMore = false) => {
    const { limit, offset, endList } = calls;
    if (isLoading || (loadMore && endList)) return;
    setLoading(true);
    const { from, to, trigger, actors, accounts } = filter;
    const customFilters = {};
    if (trigger) customFilters.actorIdInit = trigger.id;
    if (actors?.length) customFilters.actors = actors.map((i) => i.id);
    if (accounts?.length)
      customFilters.accounts = accounts.map(({ nameId, currencyId }) => ({
        nameId,
        currencyId,
      }));
    dispatch({
      type: GET_TRIGGER_CALLS.REQUEST,
      payload: {
        from,
        to,
        limit,
        offset: loadMore ? offset + limit : 0,
        ...customFilters,
      },
      callback: (newList) => {
        setLoading(false);
        const copyList = calls.list.slice();
        setCalls({
          ...calls,
          list: loadMore ? copyList.concat(newList) : newList,
          offset: loadMore ? offset + limit : offset,
          endList: !newList.length,
        });
      },
    });
  };

  useEffect(() => {
    getTriggerCalls(false);
  }, [filter]);

  useScroll({
    ref: listRef,
    callback: () => getTriggerCalls(true),
  });

  const handleResend = ({ actorId, callId }) => {
    dispatch({
      type: RESEND_TRIGGER_CALL.REQUEST,
      payload: { actorId, callId },
      callback: () => showNotification('success', t(mes.requestResent)),
    });
  };

  const renderContent = () => {
    if (!calls.list.length) return <EmptyList objType="call" />;
    return (
      <>
        {calls.list.map((i) => (
          <TriggerCall key={i.id} {...i} handleResend={handleResend} />
        ))}
        {isLoading ? <ProgressBar size="small" /> : null}
      </>
    );
  };

  return (
    <Modal
      styleName="sModal.modal sLocal.modal"
      visibility={visibility}
      onClose={onClose}
      label={t(mes.triggerCalls)}
      size="xlarge"
    >
      <ModalContent styleName="sModal.modal__content sLocal.calls">
        <div styleName="sLocal.calls__filters">
          <TriggerFilters
            value={filter}
            handleChange={(newFilter) => setFilter(newFilter)}
          />
        </div>
        <div ref={listRef} styleName="sLocal.calls__list">
          {isLoading && !calls.list.length ? (
            <ProgressBar size="large" />
          ) : (
            renderContent()
          )}
        </div>
      </ModalContent>
    </Modal>
  );
}

TriggerHistory.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleOnChange: PropTypes.func,
  dispatch: PropTypes.func,
  isSubmit: PropTypes.bool,
  values: PropTypes.object,
  errors: PropTypes.object,
  data: PropTypes.object,
};

export default TriggerHistory;
