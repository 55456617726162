import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import useIntl from 'useIntl';
import useOutsideClick from 'useOutsideClick';
import cn from 'classnames';
import { Icon, Tooltip, Clipboard, PortalWrapper } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import { NOTIFY_LEVEL, SHOW_NOTIFY } from 'constants';
import { useActorModalActions } from 'hooks';
import Logo from '../../../public/static/logo.svg';
import UserPreview from '../UserPreview';
import ActorPreview from './components/ActorPreview';
import mes from './intl';
import './SmartChipPreview.scss';

const CHIP_TYPES_WITH_PREVIEW = [
  'event',
  'actor',
  'graph',
  'graphLayer',
  'user',
];
const PREVIEW_MIN_HEIGHT = 90;

function SmartChipPreview({ accId, containerRef }) {
  const t = useIntl();
  const dispatch = useDispatch();
  const [popup, setPopup] = useState({ show: false });
  const [popupRealHeight, setPopupRealHeight] = useState(PREVIEW_MIN_HEIGHT);
  const popupRef = useRef();
  const { handleOpenActorModal } = useActorModalActions();

  /**
   * Распарсить ссылку
   */
  const parseChipLink = (html) => {
    let obj = {};
    const linkReplacer = (match, p1, p2, p3, p4) => {
      const parts = p2.replace('"', '').split('/');
      let objId;
      if (p1 === 'graph') {
        objId = parts[4];
      } else if (p1 === 'graphLayer') {
        objId = `${parts[4]}_${parts[6]}`;
      } else {
        objId = parts[parts.length - 1];
      }
      obj = { type: p1, id: objId, title: p4, link: p2.replace('"', '') };
      return `${p1}*${objId}*${p4}`;
    };
    const userTagReplacer = (match, p1, p2) => {
      obj = { id: p1, type: 'user', title: p2 };
      return `${p1}*${p2}`;
    };
    const chipRe = /<a.*?data-chip="(.*?)".*?href="(.*?)"(.*?)>(.*?)<\/a>/gi; // NOSONAR
    html.replace(chipRe, linkReplacer);
    if (obj.id) return obj;
    const userTagRe =
      /<mark.*?id="(.*?)".*?data-chip="user".*?>(.*?)<\/mark>/gi; // NOSONAR
    html.replace(userTagRe, userTagReplacer);
    return obj;
  };

  /**
   * Закрыть превью для объекта
   */
  const handleClosePopup = () => {
    setPopup({ show: false });
  };
  useOutsideClick({ ref: popupRef, callback: handleClosePopup });

  useEffect(() => {
    setTimeout(() => {
      const container = popupRef.current;
      if (!popup.show || !container) {
        setPopupRealHeight(PREVIEW_MIN_HEIGHT);
        return;
      }
      const heightContainer = container.offsetHeight;
      setPopupRealHeight(heightContainer);
    }, 500);
  }, [popup, popupRef.current]);

  /**
   * Проверить, что элемент является смартчипом
   */
  const getIsChipEl = (el) => el.closest('[data-chip]');

  const handleMouseOut = (e) => {
    // если курсор перешел на попап, не закрываем его
    if (
      popupRef.current &&
      popupRef.current.contains(e.relatedTarget || e.target)
    ) {
      popupRef.current.addEventListener('mouseout', handleMouseOut, false);
      return;
    }
    handleClosePopup();
  };

  /**
   * Открыть актор в модальном окне
   */
  const handleClick = (e) => {
    if (!getIsChipEl(e.target)) return;
    const info = parseChipLink(e.target.outerHTML);
    if (!info.id || (info.type !== 'actor' && info.type !== 'event')) return;
    e.preventDefault();
    e.stopPropagation();
    handleOpenActorModal(info.id);
  };

  /**
   * Открыть превью для объекта
   */
  const handleShowPopup = (e) => {
    if (!getIsChipEl(e.target)) return;
    e.preventDefault();
    const { left, bottom } = e.target.getBoundingClientRect();
    const info = parseChipLink(e.target.outerHTML);
    if (!info.id) return;
    const data = e.target.parentNode?.dataset;
    setPopup({
      show: true,
      position: { x: left + 12, y: bottom + 1 },
      info,
      data,
    });
  };

  useEffect(() => {
    if (!containerRef.current) return;
    const containerEl = containerRef.current;
    containerEl.addEventListener('click', handleClick, false);
    containerEl.addEventListener('mouseover', handleShowPopup, false);
    containerEl.addEventListener('mouseout', handleMouseOut, false);
    return () => {
      containerEl.removeEventListener('click', handleClick);
      containerEl.removeEventListener('mouseover', handleShowPopup);
      containerEl.removeEventListener('mouseout', handleMouseOut);
      if (popupRef.current) {
        popupRef.current.removeEventListener('mouseout', handleMouseOut);
      }
    };
  }, [containerRef.current]);

  /**
   * Скопировать ссылку
   */
  const handleCopyLink = (link) => {
    Clipboard.copy(link);
    dispatch({
      type: SHOW_NOTIFY.REQUEST,
      payload: {
        id: AppUtils.createRid(),
        type: NOTIFY_LEVEL.SUCCESS,
        label: t(mes.linkCopied),
      },
    });
  };

  /**
   * Render preview with object info
   */
  const renderPreviewBox = ({ info, data }) => {
    switch (info.type) {
      case 'user':
        return <UserPreview {...info} handleClose={handleClosePopup} />;
      case 'graph':
      case 'graphLayer':
      case 'event':
      case 'actor':
        return (
          <ActorPreview
            accId={accId}
            id={info.id}
            title={info.title}
            rootActor={data}
          />
        );
      default:
        return null;
    }
  };

  if (!popup.show || !CHIP_TYPES_WITH_PREVIEW.includes(popup.info.type))
    return null;

  const wrapped = popup.info && popup.info.type !== 'user';
  return (
    <PortalWrapper
      root={document.getElementById('mainRoot')}
      offsetX={popup.position.x}
      offsetY={popup.position.y}
      width={400}
      height={popupRealHeight}
    >
      <div styleName={cn('scp', { wrapped })} ref={popupRef}>
        <div styleName="scp__header">
          <div styleName="scp__header__title">
            <img
              styleName="sLocal.ch__img"
              src={Logo}
              alt=""
              onClick={(e) => e.stopPropagation()}
              width={150}
            />
          </div>
          <div styleName="scp__header__actions">
            <Tooltip value={t(mes.copyLink)}>
              <Icon
                type="copy"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  const url = `${document.location.origin}${popup.info.link}`;
                  handleCopyLink(url);
                }}
              />
            </Tooltip>
            <div
              onClick={(e) => {
                e.stopPropagation();
                const url = `${document.location.origin}${popup.info.link}`;
                window.open(url);
              }}
            >
              <Icon size="small" type="link_external" />
            </div>
            <Icon
              size="small"
              type="close"
              onClick={(e) => {
                e.stopPropagation();
                handleClosePopup();
              }}
            />
          </div>
        </div>
        <div styleName="scp__content">
          {popup.info ? renderPreviewBox(popup) : null}
        </div>
      </div>
    </PortalWrapper>
  );
}

SmartChipPreview.propTypes = {
  accId: PropTypes.string,
  containerRef: PropTypes.object.isRequired,
};

export default SmartChipPreview;
