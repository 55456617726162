import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Stack,
  TextField,
  Label,
  Button,
  Checkbox,
  Icon,
  Utils,
  PortalWrapper,
  Chip,
} from 'mw-style-react';
import cn from 'classnames';
import { useIntl, useOutsideClick } from 'hooks';
import { DELETED_USER_NAME } from 'constants';
import UserAvatar from '@control-front-end/common/components/UserAvatar';
import AppUtils from '@control-front-end/utils/utils';
import mes from './intl';
import './SelectedUsers.scss';

/**
 * Компонент отображения пользователей с доступом
 */
function SelectedUsers({
  rules,
  ownerId,
  handleDelete,
  handleAccessChange,
  allPrivs,
  disableOwner = true,
}) {
  const t = useIntl();
  const rightsMenuRef = useRef();
  const auth = useSelector((state) => state.auth);
  const [userMenu, toggleUserMenu] = useState(null);
  const privs = ['view', 'edit', 'remove'];
  if (allPrivs) privs.push('sign', 'execute');
  const portalRoot = document.getElementById('mainRoot');

  useOutsideClick({
    ref: rightsMenuRef,
    callback: () => setTimeout(() => toggleUserMenu(null), 10),
  });

  /**
   * Чекбоксы доступных уровней доступа
   */
  const renderCheckboxes = (id, userPrivs, visibility, disabled = false) => {
    // Корректировка для случаев, где галочка была снята (CE-2388)
    const getVisibility = (priv) => (priv ? visibility : 'visible');
    return (
      <>
        <Checkbox
          value={userPrivs.view}
          visibility={disabled ? 'disabled' : getVisibility(userPrivs.view)}
          onChange={({ value }) => handleAccessChange(id, 'view', value)}
        >
          <Label value={t(mes.view)} />
        </Checkbox>
        <Checkbox
          value={userPrivs.modify}
          visibility={disabled ? 'disabled' : getVisibility(userPrivs.modify)}
          onChange={({ value }) => handleAccessChange(id, 'modify', value)}
        >
          <Label value={t(mes.edit)} />
        </Checkbox>
        <Checkbox
          value={userPrivs.remove}
          visibility={disabled ? 'disabled' : getVisibility(userPrivs.remove)}
          onChange={({ value }) => handleAccessChange(id, 'remove', value)}
        >
          <Label value={t(mes.remove)} />
        </Checkbox>
        {allPrivs ? (
          <Checkbox
            value={userPrivs.sign}
            visibility={disabled ? 'disabled' : 'visible'}
            onChange={({ value }) => handleAccessChange(id, 'sign', value)}
          >
            <Label value={t(mes.sign)} />
          </Checkbox>
        ) : null}
        {allPrivs ? (
          <Checkbox
            value={userPrivs.execute}
            visibility={disabled ? 'disabled' : 'visible'}
            onChange={({ value }) => handleAccessChange(id, 'execute', value)}
          >
            <Label value={t(mes.execute)} />
          </Checkbox>
        ) : null}
      </>
    );
  };

  /**
   * Выбор прав доступа в виде выпадающего меню
   */
  const userAccessSelect = (userId, userPrivs, visibility) => {
    const activeRights = Object.keys(userPrivs).filter(
      (key) => !!userPrivs[key]
    );
    const accessString =
      activeRights.length === 1
        ? Utils.toUpperLatter(activeRights[0])
        : `${activeRights.length} rights`;
    return (
      <div styleName="userAccess__select">
        <TextField
          id={`privs_${userId}`}
          styleName="userAccess__select__field"
          bordered={true}
          readOnly={true}
          rightIcon="dropdown"
          value={accessString}
          onFocus={() => {
            if (userMenu === userId) return;
            toggleUserMenu(userId);
          }}
        />
        {userMenu === userId ? (
          <PortalWrapper
            root={portalRoot}
            node={document.getElementById(`privs_${userId}`)}
            width={120}
            vAlign="bottom"
            hAlign="left"
            offsetY={36}
          >
            <div ref={rightsMenuRef} styleName="userAccess__select__menu">
              {renderCheckboxes(userId, userPrivs, visibility)}
              <Button
                styleName="userAccess__select__btn"
                size="small"
                fontWeight="normal"
                label={t(mes.done)}
                onClick={() => toggleUserMenu(null)}
              />
            </div>
          </PortalWrapper>
        ) : null}
      </div>
    );
  };

  const renderBulkActions = (allRules) => {
    const bulkPrivs = {
      view: allRules.every((u) => u.privs.view),
      modify: allRules.every((u) => u.privs.modify),
      remove: allRules.every((u) => u.privs.remove),
      sign: allRules.every((u) => u.privs.sign),
      execute: allRules.every((u) => u.privs.execute),
    };
    return (
      <div styleName="userAccess__item bulk">
        <div styleName="userAccess__item__info">
          <Label value={t(mes.name)} />
        </div>
        <div styleName={cn('userAccess__controls', { allPrivs })}>
          {renderCheckboxes('all', bulkPrivs, 'visible')}
          <div styleName="userAccess__controls__close" />
        </div>
      </div>
    );
  };

  const extraUserChip = (label) => (
    <Chip
      styleName="userAccess__chip"
      closeIcon={false}
      unspaced
      type="rectangular"
      size="small"
      fontSize="small"
      fontWeight="normal"
      label={t(mes[label]) || label}
    />
  );

  const visibleRules = rules.filter(
    ({ userType, action }) => action !== 'delete' && userType !== 'system'
  );
  const activeRules = visibleRules.filter(
    ({ name }) => name !== DELETED_USER_NAME
  );
  const list = visibleRules.map((item) => ({
    ...item,
    isGroup: item.userType === 'group',
  }));
  const sortedRules = Utils.sort(list, 'isGroup', 'asc');
  const items = sortedRules.map(
    ({
      userId,
      saId,
      userType,
      name,
      avatar,
      privs: userPrivs,
      isInvite,
      isMixed,
      ownerUserId,
      role,
      logins = [],
    }) => {
      const newUserPrivs = { ...userPrivs };
      const isWorkspaceOwner = (role || []).find((r) => r.name === 'Owner');
      if (isWorkspaceOwner) {
        newUserPrivs.view = true;
        newUserPrivs.modify = true;
        newUserPrivs.remove = true;
      }
      const id = userId || saId;
      const isDisabledOwner = id === ownerId && disableOwner;
      const isDisabledInvite = ownerUserId !== auth.id && isInvite;
      const isDisabledUserDeleted = name === DELETED_USER_NAME;
      const isNonRemovable = isDisabledOwner || isDisabledInvite;
      const isNonEditable = isDisabledInvite || isDisabledUserDeleted;
      const visibility =
        isNonRemovable || isDisabledUserDeleted || isWorkspaceOwner
          ? 'disabled'
          : 'visible';
      let customIcon = null;
      if (isInvite) {
        customIcon = 'client';
      } else if (userType === 'group') {
        customIcon = 'persons';
      }
      return (
        <div key={`${userType}_${id}`} styleName="userAccess__item">
          <Stack.H
            styleName={cn('userAccess__item__info', { allPrivs })}
            size="small"
            alignItems="center"
            fullWidth
          >
            <div styleName="userAccess__item__avatar__box">
              <UserAvatar
                styleName="userAccess__item__avatar"
                size="medium"
                src={avatar}
                label={!customIcon ? name : ''}
                customIcon={
                  customIcon ? { type: customIcon, size: 'small' } : null
                }
                userType={userType}
              />
            </div>
            <Stack.V size="micro" fullWidth justifyContent="center">
              <Stack.H size="micro" fullWidth alignItems="center">
                <Label color={Label.COLOR.black} overflow="cut" value={name} />
                &nbsp;
                <Stack.H size="micro">
                  {id === ownerId ? extraUserChip('owner') : null}
                  {isInvite ? extraUserChip('Invite') : null}
                  {isMixed ? extraUserChip('mixedRole') : null}
                </Stack.H>
              </Stack.H>
              <Label
                fontSize="small"
                color={Label.COLOR.gray}
                overflow="cut"
                value={userType === 'user' ? AppUtils.getUserLogin(logins) : id}
              />
            </Stack.V>
          </Stack.H>
          {userAccessSelect(id, newUserPrivs, visibility)}
          <div styleName={cn('userAccess__controls', { allPrivs })}>
            {renderCheckboxes(id, newUserPrivs, visibility, isNonEditable)}
            <div
              styleName={cn('userAccess__controls__close', {
                disabled: isNonRemovable,
              })}
              onClick={() => {
                if (isNonRemovable) return;
                handleDelete(id);
              }}
            >
              <Icon
                type="close"
                visibility={isNonRemovable ? 'disabled' : 'visible'}
              />
            </div>
          </div>
        </div>
      );
    }
  );

  const groupsCount = visibleRules.filter((r) => r.userType === 'group').length;
  return (
    <div styleName="userAccess__selected">
      <div styleName="userAccess__selected__header">
        <div styleName="userAccess__selected__header__count">
          <Label
            fontSize="semibold"
            value={`${t(mes.sharedWith)}: ${visibleRules.length}`}
          />
          <Label
            fontSize="semibold"
            value={`${t(mes.groups)}: ${groupsCount}, ${t(mes.users)}: ${
              visibleRules.length - groupsCount
            }`}
          />
        </div>
        <div styleName="userAccess__controls__labels compact">
          <Label value={t(mes.accessRights)} />
        </div>
        <div styleName={cn('userAccess__controls__labels full', { allPrivs })}>
          {privs.map((i) => (
            <Label key={i} value={t(mes[i])} />
          ))}
        </div>
      </div>
      {renderBulkActions(activeRules)}
      <div
        styleName={cn('userAccess__selected__content', {
          customScroll: AppUtils.isSafari() || AppUtils.isFirefox(),
        })}
      >
        {items}
      </div>
    </div>
  );
}

SelectedUsers.propTypes = {
  disableOwner: PropTypes.bool,
  rules: PropTypes.array,
  allPrivs: PropTypes.bool,
  ownerId: PropTypes.number,
  handleDelete: PropTypes.func,
  handleAccessChange: PropTypes.func,
};

export default SelectedUsers;
