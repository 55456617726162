import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  panelTitle_main: {
    id: 'panelTitle_main',
    defaultMessage: 'Main settings',
  },
  panelTitle_options: {
    id: 'panelTitle_options',
    defaultMessage: 'Answers',
  },
  panelTitle_extra: {
    id: 'panelTitle_extra',
    defaultMessage: 'Additional',
  },
  static: {
    id: 'static',
    defaultMessage: 'Static',
  },
  reqField: {
    id: 'reqField',
    defaultMessage: 'Field is required',
  },
  recommendSetUniqueId: {
    id: 'recommendSetUniqueId',
    defaultMessage:
      'We recommend to set custom title for this field for more comfortable work with API.',
  },
  notUniqueId: {
    id: 'notUniqueId',
    defaultMessage: 'ID must be unique',
  },
  notValidMaxDate: {
    id: 'notValidMaxDate',
    defaultMessage: 'Maximum date must be later than minimum',
  },
  addAnswer: {
    id: 'addAnswer',
    defaultMessage: '+ Add answer',
  },
  answer: {
    id: 'answer',
    defaultMessage: 'Answer',
  },
  value: {
    id: 'value',
    defaultMessage: 'Value:',
  },
  color: {
    id: 'color',
    defaultMessage: 'Color:',
  },
});

export default { ...globalIntl, ...m };
