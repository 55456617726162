import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalButtons,
  ModalContent,
  ProgressBar,
  withForm,
} from 'mw-style-react';
import useIntl from 'useIntl';
import AppUtils from '@control-front-end/utils/utils';
import { SHOW_NOTIFY, NOTIFY_LEVEL } from 'constants';
import { SET_FORMULAS } from '@control-front-end/common/constants/actorAccounts';
import FormulaField from './components/FormulaField';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import m from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import l from './AddFormula.scss'; // NOSONAR

function AddFormulaBase(props) {
  const {
    values,
    data,
    errors,
    visibility,
    onClose,
    isSubmit,
    handleSubmit,
    handleOnChange,
  } = props;

  const t = useIntl();

  const setInitialErrors = (type) => {
    if (!data[type].error) return;
    const id = `${type}Formula`;
    const errMsgId = data[type].errMsg;
    handleOnChange({
      id,
      value: values[id],
      error: t(mes[errMsgId]) || errMsgId,
    });
  };

  useEffect(() => {
    setInitialErrors('debit');
    setTimeout(() => setInitialErrors('credit'), 10);
  }, []);

  const markError = (errorResponse) => {
    if (!errorResponse) return null;
    if (!errorResponse.code) return t(mes[errorResponse]);
    const { formula, description: errorText } = errorResponse;
    const re = /char [0-9]+/;
    const match = errorText.match(re);
    if (!match) return errorText;
    let charAt;
    let charCount = 0;
    const errPos = Number(match[0].slice(5));
    if (!Number.isNaN(errPos)) {
      if (errPos > formula.length) {
        charAt = '\n';
        charCount = 1;
      } else {
        charAt = formula.charAt(errPos ? errPos - 1 : errPos);
        for (let i = 0; i < errPos; i += 1) {
          if (formula.charAt(i) === charAt) charCount += 1;
        }
      }
    }
    return `${errorText} in ${charCount} char ${charAt}`;
  };

  const renderFormula = (incomeType) => {
    const formulaValue = values[`${incomeType}Formula`] || '';
    const formulaError = errors[`${incomeType}Formula`];
    const errorMessage =
      markError(formulaError) ||
      t(mes[data[incomeType].errMsg]) ||
      formulaError;
    const focusField = errors.creditFormula ? 'credit' : 'debit';
    return (
      <FormulaField
        key={data[incomeType].id}
        incomeType={incomeType}
        accountId={data[incomeType].id}
        error={formulaError}
        errorMessage={errorMessage}
        formula={formulaValue}
        autoFocus={incomeType === focusField}
        isDisabled={data[incomeType].connector}
        accountType={data.accountType}
        handleOnChange={handleOnChange}
      />
    );
  };

  const action = values.debitFormula || values.creditFormula ? 'edit' : 'add';
  return (
    <Modal
      styleName="m.modal"
      size="xlarge"
      onClose={onClose}
      label={t(mes[`${action}Formulas`])}
      visibility={visibility}
    >
      <ModalContent styleName="m.modal__content l.modal__content">
        <div styleName="l.content">
          {renderFormula('debit')}
          {renderFormula('credit')}
        </div>
      </ModalContent>
      <ModalButtons styleName="m.modal__buttons">
        <Button
          label={t(mes.save)}
          size="large"
          onClick={handleSubmit}
          visibility={isSubmit ? 'disabled' : 'visible'}
        />
        <ProgressBar
          styleName="m.modal__loader"
          type="circle"
          size="small"
          visibility={isSubmit ? 'visible' : 'hidden'}
        />
      </ModalButtons>
    </Modal>
  );
}

AddFormulaBase.propTypes = {
  visibility: PropTypes.bool,
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleOnChange: PropTypes.func,
  isSubmit: PropTypes.bool,
  data: PropTypes.object,
  errors: PropTypes.object,
  values: PropTypes.object,
};

const AddFormula = withForm(
  {
    mapPropsToValues(props) {
      const formData = props.data || {};
      return {
        debitFormula: formData.debit.formula,
        creditFormula: formData.credit.formula,
      };
    },
    handleSubmit: (values, dispatch, formActions, props) => {
      const { debit, credit, accountType, key } = props.data;
      const { debitFormula = null, creditFormula = null } = values;
      dispatch({
        type: SET_FORMULAS.REQUEST,
        payload: {
          accountType,
          key,
          debit: { id: debit.id, formula: debitFormula },
          credit: { id: credit.id, formula: creditFormula },
        },
        callback: () => {
          dispatch({
            type: SHOW_NOTIFY.REQUEST,
            payload: {
              id: AppUtils.createRid(),
              type: NOTIFY_LEVEL.SUCCESS,
              label: 'Formulas has been successfully added.',
            },
          });
          props.onClose();
        },
        formActions,
      });
    },
  },
  AddFormulaBase
);

export default AddFormula;
