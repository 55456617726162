import { defineMessages } from 'react-intl';

const m = defineMessages({
  actorNamePlaceholder: {
    id: 'actorNamePlaceholder_ActorCardView',
    defaultMessage: "'{Actor name}'",
  },
  accountTotalPlaceholder: {
    id: 'accountTotalPlaceholder_ActorCardView',
    defaultMessage: "'{Total}'",
  },
  descriptionPlaceholder: {
    id: 'descriptionPlaceholder_ActorCardView',
    defaultMessage: "'{Description text}'",
  },
  widgetButton: {
    id: 'widgetButton_ActorCardView',
    defaultMessage: 'Connect',
  },
});

export default m;
