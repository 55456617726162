import {
  GET_GRAPH_FOLDERS,
  GET_GRAPH_FOLDER,
  MANAGE_GRAPH_FOLDER,
  CREATE_GRAPH_FOLDER,
  UPDATE_GRAPH_FOLDER,
  CLEAR_GRAPH_FOLDERS,
  SEARCH_GRAPH_FOLDERS,
  GRAPH_FOLDER_ACCESS,
  SET_GRAPH_FOLDERS_REQ_STATUS,
} from '@control-front-end/common/constants/graphFolders';
import { createReducer } from '@reduxjs/toolkit';

// ------------------------------------
// Reducer
// ------------------------------------

const initState = {
  list: [],
  limit: 15,
  offset: 0,
  endList: false,
  reqStatus: 'success',
};

export default createReducer(initState, {
  [GET_GRAPH_FOLDERS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [GET_GRAPH_FOLDER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [MANAGE_GRAPH_FOLDER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [CREATE_GRAPH_FOLDER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [UPDATE_GRAPH_FOLDER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SEARCH_GRAPH_FOLDERS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [GRAPH_FOLDER_ACCESS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SET_GRAPH_FOLDERS_REQ_STATUS](state, action) {
    return { ...state, reqStatus: action.payload };
  },
  [CLEAR_GRAPH_FOLDERS]() {
    return initState;
  },
});
