import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  labelKey: {
    id: 'labelKey',
    defaultMessage: 'Key',
  },
  labelValue: {
    id: 'labelValue',
    defaultMessage: 'Value',
  },
});

export default { ...globalIntl, ...m };
