import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Autocomplete, AutocompleteItem, Chip } from 'mw-style-react';
import cn from 'classnames';
import '../Panel.scss';

/**
 * Компонент MultiSelect
 */
class MultiSelect extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
    };
    this.handleListItemClick = this.handleListItemClick.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.renderChipsItems = this.renderChipsItems.bind(this);
    this.renderSearchResult = this.renderSearchResult.bind(this);
  }

  handleSearchChange({ value }) {
    this.setState({ search: value });
  }

  handleListItemClick({ id, value }) {
    const { value: selected, onChange, id: itemId } = this.props;
    const selectedItem = selected.map((item) => item.value).indexOf(id);
    if (selectedItem !== -1) return;
    const newItems = selected.slice(0);
    newItems.push({ value: id, title: value });
    onChange({ id: itemId, value: newItems });
    this.setState({ search: '' });
  }

  handleChipCloseClick(id) {
    const { value: selected, onChange, id: itemId } = this.props;
    const newItems = selected.slice(0);
    const indexToRemove = newItems.map((item) => item.value).indexOf(id);
    newItems.splice(indexToRemove, 1);
    onChange({ id: itemId, value: newItems });
  }

  renderChipsItems() {
    const { value: selected } = this.props;
    return selected.map((item) => (
      <Chip
        key={item.value}
        label={item.title}
        closeClick={() => this.handleChipCloseClick(item.value)}
        size="small"
        type="rectangular"
      />
    ));
  }

  renderSearchResult() {
    const { options, value: selected } = this.props;
    const { search } = this.state;
    if (!Array.isArray(options) || !search) return;
    const searchRegExp = new RegExp(search, 'gi');
    const items = options
      .filter((item) => item.title.match(searchRegExp))
      .filter(
        (item) => selected.findIndex((i) => i.value === item.value) === -1
      )
      .map((item) => (
        <AutocompleteItem
          value={item.title}
          id={item.value}
          key={item.value}
          onClick={this.handleListItemClick}
        />
      ));
    if (!items.length) return;
    return <div>{items}</div>;
  }

  render() {
    const { id, title, visibility, error } = this.props;
    const { search } = this.state;
    return (
      <div styleName={cn('panel__el')}>
        <Autocomplete
          id={id}
          label={title}
          value={search}
          error={error}
          bordered={true}
          visibility={visibility}
          onChange={this.handleSearchChange}
          chipsItems={this.renderChipsItems}
          searchResult={this.renderSearchResult}
        />
      </div>
    );
  }
}

MultiSelect.defaultProps = {
  value: [],
  visibility: 'visible',
};

MultiSelect.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  value: PropTypes.array,
  error: PropTypes.bool,
  visibility: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onChange: PropTypes.func,
};

export default MultiSelect;
