import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  Label,
  Stack,
  Space,
  Card,
  Button,
  Avatar,
} from 'mw-style-react';

function WidgetModeCardLayout({
  hidden,
  title,
  widgetPlaceholderRef,
  pictureUrl,
  onClose,
}) {
  return (
    <Stack size="none" fullHeight style={hidden ? { display: 'none' } : {}}>
      <Card borderRadius="none" boxShadow="none">
        <Space size="xsmall">
          <Stack
            fullWidth
            horizontal
            alignItems="center"
            justifyContent="spaceBetween"
          >
            <Stack size="xsmall" horizontal alignItems="center">
              {pictureUrl ? (
                <Avatar size="small" src={pictureUrl} />
              ) : (
                <Icon size="large" type="actor" />
              )}
              <Label
                color={Label.COLOR.black}
                fontWeight="semibold"
                overflow="cut"
                value={title}
              />
            </Stack>
            <Button
              type="text"
              size="xsmall"
              icon={<Icon type="close" size="small" />}
              onClick={onClose}
            />
          </Stack>
        </Space>
      </Card>
      <div
        ref={widgetPlaceholderRef}
        style={{ flexGrow: 1, overflow: 'hidden' }}
      />
    </Stack>
  );
}

WidgetModeCardLayout.propTypes = {
  hidden: PropTypes.bool,
  title: PropTypes.string.isRequired,
  widgetPlaceholderRef: PropTypes.object,
  pictureUrl: PropTypes.string,
  onClose: PropTypes.func,
};

export default WidgetModeCardLayout;
