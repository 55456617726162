import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Modal,
  ModalContent,
  ModalButtons,
  Button,
  ProgressBar,
  withForm,
  Utils,
  Space,
} from 'mw-style-react';
import useIntl from 'useIntl';
import AppUtils from '@control-front-end/utils/utils';
import Yup from 'yup';
import { SAVE_SYSTEM_ACTOR } from '@control-front-end/common/constants/graphActors';
import { NOTIFY_LEVEL, SHOW_NOTIFY, DEL_MODAL } from 'constants';
import { Card } from 'components';
// eslint-disable-next-line no-unused-vars
import sModal from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import sLocal from './EventCard.scss'; // NOSONAR
import mes from './intl';

function EventCardBase(props) {
  const {
    onClose,
    errors,
    values,
    visibility,
    isSubmit,
    handleSubmit,
    handleOnChange,
    onContentChange,
  } = props;
  const t = useIntl();

  const [isLoading, setLoading] = useState(false);
  const isNew = values.id === 'tmp_1';
  const canModify = (isNew || values.privs.modify) && !values.readOnly;

  const convertToErrorList = (errObj) => {
    return Object.keys(errObj).map((key) => `no${Utils.capitalize(key)}`);
  };

  return (
    <Modal
      styleName="sLocal.eventModal"
      visibility={visibility}
      onClose={onClose}
      label=""
      size="large"
    >
      <ModalContent styleName="sModal.modal__content sLocal.eventModal__content">
        <Card
          {...values}
          errors={convertToErrorList(errors)}
          isLoading={isLoading}
          setLoading={setLoading}
          onChange={(id, obj) => {
            handleOnChange(
              Object.entries(obj).map(([key, value]) => ({ id: key, value }))
            );
            onContentChange();
          }}
          additionalControls={
            <Space top>
              <ModalButtons styleName="sModal.modal__buttons">
                <ProgressBar
                  type="circle"
                  size="small"
                  visibility={isSubmit ? 'visible' : 'hidden'}
                />
                <Button
                  styleName="sLocal.eventModal__btn"
                  size="smallplus"
                  type="text"
                  label={t(mes.cancel)}
                  onClick={onClose}
                  visibility={isSubmit ? 'disabled' : 'visible'}
                />
                {isNew || canModify ? (
                  <Button
                    size="smallplus"
                    label={isNew ? t(mes.create) : t(mes.update)}
                    onClick={handleSubmit}
                    disabled={isSubmit || isLoading}
                    visibility={isSubmit || isLoading ? 'disabled' : 'visible'}
                  />
                ) : null}
              </ModalButtons>
            </Space>
          }
        />
      </ModalContent>
    </Modal>
  );
}

const EventCard = withForm(
  {
    mapPropsToValues(props) {
      return {
        ...props.data,
      };
    },
    yup: Yup.object().shape({
      title: Yup.string().required('field_required'),
      access: Yup.array().required('field_required'),
    }),
    handleSubmit: (values, dispatch, formActions, props) => {
      const isNew = values.id === 'tmp_1';
      if (values.appId && !values.appSettings) {
        values.appSettings = { autorun: true };
      }
      const descrWithBbCodes = AppUtils.htmlToBbCode(values.description);
      const desctWithOutElementId =
        AppUtils.removeElementIdInBbCode(descrWithBbCodes);
      const description = AppUtils.removeEmptySmartChips(desctWithOutElementId);
      const payload = {
        systemForm: 'events',
        action: isNew ? 'create' : 'update',
        actor: { ...values, description },
        withEdges: false,
      };
      dispatch({
        type: SAVE_SYSTEM_ACTOR.REQUEST,
        payload: {
          ...payload,
          formActions: {
            setSubmitting: formActions.setSubmitting,
            callback: (created) => {
              if (typeof props.callback === 'function') {
                props.callback(created);
              }
              dispatch({
                type: SHOW_NOTIFY.REQUEST,
                payload: {
                  id: AppUtils.createRid(),
                  type: NOTIFY_LEVEL.SUCCESS,
                  label: isNew ? 'Event was created' : 'Event was updated',
                },
              });
              dispatch({ type: DEL_MODAL });
            },
          },
        },
      });
    },
  },
  EventCardBase
);

EventCardBase.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  dispatch: PropTypes.func,
  handleOnChange: PropTypes.func,
  onContentChange: PropTypes.func,
  isSubmit: PropTypes.bool,
  values: PropTypes.object,
  errors: PropTypes.object,
  auth: PropTypes.object,
};

export default EventCard;
