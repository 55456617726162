import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Calendar, TextField, Space, DateUtils, Label } from 'mw-style-react';
import useIntl from 'useIntl';
import { DATE_FORMAT_3 } from 'constants';
import mes from '../intl';

function DateTimeSelector({ value, onChange }) {
  const t = useIntl();
  const [calendarValue, setCalendarValue] = useState({
    startDate: value,
    endDate: value,
  });

  const handleChange = useCallback(({ value }) => {
    setCalendarValue(value);
    onChange({ value: value.startDate });
  });

  const renderCalendar = useCallback(
    () => (
      <Calendar
        size="small"
        value={calendarValue}
        time={true}
        minDate={new Date().getTime() / 1000}
        onChange={handleChange}
      />
    ),
    [calendarValue]
  );

  return (
    <TextField
      color={TextField.COLOR.white}
      unspaced
      bordered
      label={
        <Space bottom size={Space.SIZE.xsmall}>
          <Label
            color={Label.COLOR.black}
            fontWeight="semibold"
            value={t(mes.dateTime)}
          />
        </Space>
      }
      value={DateUtils.toDate(value, DATE_FORMAT_3)}
      popoverOptions={{
        anchors: {
          binding: 'center_top',
          content: 'center_bottom',
        },
      }}
      calendar={renderCalendar}
    />
  );
}

DateTimeSelector.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DateTimeSelector;
