import { Utils } from 'mw-style-react';

// SCRIPTS
export const GET_SCRIPT = Utils.createRequestTypes('GET_SCRIPT');
export const GET_SCRIPTS = Utils.createRequestTypes('GET_SCRIPTS');
export const GET_SCRIPT_ENVS = Utils.createRequestTypes('GET_SCRIPT_ENVS');
export const CREATE_SCRIPT = Utils.createRequestTypes('CREATE_SCRIPT');
export const UPDATE_SCRIPT_ENV = Utils.createRequestTypes('UPDATE_SCRIPT_ENV');
export const DEPLOY_SCRIPT = Utils.createRequestTypes('DEPLOY_SCRIPT');
export const CLEAR_SCRIPTS = 'CLEAR_SCRIPTS';
export const SET_STARRED_ACTOR = Utils.createRequestTypes('SET_STARRED_ACTOR');
export const GET_STARRED_ACTORS =
  Utils.createRequestTypes('GET_STARRED_ACTORS');
export const UPDATE_SCRIPT_BAR_SETTINGS = Utils.createRequestTypes(
  'UPDATE_SCRIPT_BAR_SETTINGS'
);

// SCRIPT CONTENT
export const GET_SCRIPT_STRUCTURE = Utils.createRequestTypes(
  'GET_SCRIPT_STRUCTURE'
);
export const GET_SCRIPT_FOLDER = Utils.createRequestTypes('GET_SCRIPT_FOLDER');
export const CREATE_SCRIPT_OBJECTS = Utils.createRequestTypes(
  'CREATE_SCRIPT_OBJECTS'
);
export const UPDATE_SCRIPT_OBJECTS = Utils.createRequestTypes(
  'UPDATE_SCRIPT_OBJECTS'
);
export const REMOVE_SCRIPT_OBJECTS = Utils.createRequestTypes(
  'REMOVE_SCRIPT_OBJECTS'
);
export const SET_SCRIPT_CONTENT_REQ_STATUS = 'SET_SCRIPT_CONTENT_REQ_STATUS';
export const CLEAR_SCRIPT_STRUCTURE = 'CLEAR_SCRIPT_STRUCTURE';
