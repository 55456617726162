import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  selectOptionsSource: {
    id: 'selectOptionsSource',
    defaultMessage: 'Select options source',
  },
  selectFromList: {
    id: 'selectFromList',
    defaultMessage: 'Select from the list',
  },
  selectedActorIs: {
    id: 'selectedActorIs',
    defaultMessage: 'Selected template actor is',
  },
  optSourceError_url: {
    id: 'optSourceError_url',
    defaultMessage: 'API URL is not valid',
  },
  optSourceError_convId: {
    id: 'optSourceError_convId',
    defaultMessage: 'Process ID is not valid',
  },
  optSourceError_apiLogin: {
    id: 'optSourceError_apiLogin',
    defaultMessage: 'API Login is not valid',
  },
  optSourceError_apiSecret: {
    id: 'optSourceError_apiSecret',
    defaultMessage: 'API Secret is not valid',
  },
  filterAccessDenied: {
    id: 'filterAccessDenied',
    defaultMessage: 'Filter access denied',
  },
  layerSourceAccessDenied: {
    id: 'layerSourceAccessDenied',
    defaultMessage: 'Layer access denied',
  },
  selectLayer: {
    id: 'selectLayer',
    defaultMessage: 'Select layer',
  },
  selectActors: {
    id: 'selectActors',
    defaultMessage: 'Select actors',
  },
  selectActorFilter: {
    id: 'selectActorFilter',
    defaultMessage: 'Select actor filter',
  },
  selectCurrency: {
    id: 'selectCurrency',
    defaultMessage: 'Select currency',
  },
});

export default { ...globalIntl, ...m };
