import { useContext } from 'react';
import { IntlContext } from 'IntlProvider';
import intlParse from '@control-front-end/utils/intlParse';

/**
 * Хук с контекстом локализации приложения
 */
const useIntl = () => {
  const intl = useContext(IntlContext);
  return (mesId, values, bbcode) => intlParse({ intl, mesId, values, bbcode });
};

export default useIntl;
