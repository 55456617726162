import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  selectColor: {
    id: 'selectColor_ChartAccountSelect',
    defaultMessage: 'Select color',
  },
});

export default { ...globalIntl, ...m };
