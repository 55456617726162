import React from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem, Label, Stack, Badge } from 'mw-style-react';

function FormFieldSelector({ value: valueProp, fields, onChange, ...rest }) {
  const value = fields[valueProp?.id];

  return (
    <Select
      type="autocomplete"
      autoSelect={false}
      bordered
      unspaced
      color={Select.COLOR.white}
      size="large"
      placeholder="Select Field"
      value={value?.title || ''}
      onChange={({ value }) => {
        onChange?.(fields[value] || {});
      }}
      popoverOnTop
      {...rest}
    >
      {[{ title: '---', id: 'stub' }, ...Object.values(fields)].map(
        ({ id, title, formColor, formTitle }) => (
          <MenuItem key={id} value={id} label={title} size="large">
            {formTitle ? (
              <Stack.H size="xxsmall" alignItems="center">
                <Badge
                  size="small"
                  bgColor={formColor || '#ffffff'}
                  borderColor={formColor || '#ACB3BE'}
                />
                <Label fontSize="small" value={formTitle} />
              </Stack.H>
            ) : null}
          </MenuItem>
        )
      )}
    </Select>
  );
}

FormFieldSelector.propTypes = {
  value: PropTypes.object,
  fields: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

export default FormFieldSelector;
