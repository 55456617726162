import { createReducer } from '@reduxjs/toolkit';
import { INIT_TRANSLATIONS } from '../constants';
// ------------------------------------
// Reducer - stores aplications UI translations/messages
// ------------------------------------
const initState = {};

export default createReducer(initState, {
  [INIT_TRANSLATIONS.SUCCESS](state, { payload }) {
    return { ...state, ...payload };
  },
});
