import { useState } from 'react';

/**
 * Хук toggle
 */
const useToggle = (initState = {}) => {
  const [openObj, setOpen] = useState(initState);

  const handleToggle = (key) => {
    const copyObj = { ...openObj };
    const value = copyObj[key] || false;
    copyObj[key] = !value;
    setOpen(copyObj);
  };

  const isOpen = (key) => !!openObj[key];
  return [isOpen, handleToggle];
};

export default useToggle;
