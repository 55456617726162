import { GRAPH_DISCOVERY } from '@control-front-end/common/constants/graphLayers';
import { createReducer } from '@reduxjs/toolkit';

// ------------------------------------
// Reducer
// ------------------------------------
const initState = {};

export default createReducer(initState, {
  [GRAPH_DISCOVERY.LOAD_SECTOR.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [GRAPH_DISCOVERY.LOAD_WHOLE_SKELETON.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [GRAPH_DISCOVERY.CLEAR.SUCCESS]() {
    return initState;
  },
});
