import { call, put, select, takeEvery } from 'redux-saga/effects';
import { RequestStatus } from 'constants';
import {
  GET_ACCOUNT_CURRENCIES,
  GET_ACCOUNT_CURRENCY,
  CREATE_ACCOUNT_CURRENCIES,
  UPDATE_ACCOUNT_CURRENCY,
  REMOVE_ACCOUNT_CURRENCY,
  SEARCH_ACCOUNT_CURRENCY,
  SET_ACCOUNT_CURRENCIES_REQ_STATUS,
} from '@control-front-end/common/constants/actorAccounts';
import api from '@control-front-end/common/sagas/api';
import AppUtils from '@control-front-end/utils/utils';

/**
 * Получить валюты счетов
 */
function* getAccountCurrencies({ payload, callback }) {
  const accounts = yield select((state) => state.accounts);
  const accId = accounts.active;
  const {
    list,
    limit = 20,
    offset = 0,
    endList,
    loadMore,
    withStats = false,
  } = payload;
  if (loadMore && endList) return;
  // Ставим флаг прогресса загрузки
  yield put({ type: SET_ACCOUNT_CURRENCIES_REQ_STATUS, payload: 'inProgress' });
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/currencies/${accId}`,
    queryParams: { limit, offset, withStats },
  });
  // Ставим флаг окончания загрузки
  yield put({ type: SET_ACCOUNT_CURRENCIES_REQ_STATUS, payload: 'success' });
  if (result !== RequestStatus.SUCCESS) return;
  const currenciesList = loadMore ? structuredClone(list) : [];
  currenciesList.push(...data.data);
  const currenciesPayload = {
    list: currenciesList,
    limit,
    offset: offset + limit,
    endList: !data.data.length,
  };
  if (callback) callback(currenciesPayload);
}

/**
 * Получить валюту по ID
 */
function* getAccountCurrency({ payload: currencyId, callback, errorCallback }) {
  const handleErrCodes = [403, 404];
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/currencies/single/${currencyId}`,
    handleErrCodes,
  });
  if (handleErrCodes.includes(data.statusCode) && errorCallback) {
    errorCallback();
  }
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback(data.data);
}

/**
 * Создать валюту счета
 */
export function* createAccountCurrencies({ payload, callback }) {
  const accounts = yield select((state) => state.accounts);
  const accId = accounts.active;
  const { name, type, symbol, precision } = payload;
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/currencies/${accId}`,
    body: { name, type, symbol, precision },
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback(data.data);
  return data.data;
}

/**
 * Обновить название валюты
 */
function* updateAccountCurrency({ payload, callback, onError }) {
  const { id, name, type, symbol, precision } = payload;
  const currencies = yield select((state) => state.currencies.list);
  const { result, data } = yield call(api, {
    method: 'put',
    url: `/currencies/${id}`,
    body: { name, type, symbol, precision },
  });
  if (result !== RequestStatus.SUCCESS) {
    if (onError) onError();
    return;
  }
  const list = structuredClone(currencies);
  const findIndex = list.findIndex((i) => i.id === id);
  const currency = { ...list[findIndex], ...data.data };
  list.splice(findIndex, 1, currency);
  yield put({
    type: UPDATE_ACCOUNT_CURRENCY.SUCCESS,
    payload: { list },
  });
  if (callback) callback(data.data);
  return data.data;
}

/**
 * Удалить валюту
 */
function* removeAccountCurrency({ payload: id, callback }) {
  const currenciesState = yield select((state) => state.currencies);
  const { result } = yield call(api, {
    method: 'delete',
    url: `/currencies/${id}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  const list = currenciesState.list.filter((i) => i.id !== id);
  yield put({
    type: REMOVE_ACCOUNT_CURRENCY.SUCCESS,
    payload: {
      list,
      offset: currenciesState.offset - 1,
      total: currenciesState.total - 1,
    },
  });
  if (callback) {
    callback(list);
  }
}

/**
 * Найти валюту
 */
function* searchAccountCurrency({ payload, callback }) {
  const { query, withStats = false, limit = 15, offset } = payload;
  const accounts = yield select((state) => state.accounts);
  const accId = accounts.active;
  const searchQ = AppUtils.safeSearchQuery(query);
  if (!searchQ.length) return;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/currencies/search/${accId}/${searchQ}`,
    queryParams: { withStats, limit, offset },
  });
  if (result !== RequestStatus.SUCCESS) return;
  const list = data.data;
  if (callback) {
    callback(list);
  } else {
    yield put({
      type: SEARCH_ACCOUNT_CURRENCY.SUCCESS,
      payload: { list },
    });
  }
}

function* accountCurrencies() {
  yield takeEvery(GET_ACCOUNT_CURRENCIES.REQUEST, getAccountCurrencies);
  yield takeEvery(GET_ACCOUNT_CURRENCY.REQUEST, getAccountCurrency);
  yield takeEvery(CREATE_ACCOUNT_CURRENCIES.REQUEST, createAccountCurrencies);
  yield takeEvery(UPDATE_ACCOUNT_CURRENCY.REQUEST, updateAccountCurrency);
  yield takeEvery(REMOVE_ACCOUNT_CURRENCY.REQUEST, removeAccountCurrency);
  yield takeEvery(SEARCH_ACCOUNT_CURRENCY.REQUEST, searchAccountCurrency);
}

export default accountCurrencies;
