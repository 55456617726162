import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { HIDE_NOTIFY } from 'constants';
import { Notify, PopoverBoundary } from 'mw-style-react';
import { Widget, Modal } from 'components';
import '../../../styles/main.scss';

function EmptyLayout({ children }) {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme);
  const notify = useSelector((state) => state.notify);
  const modal = useSelector((state) => state.modal);
  const widgets = useSelector((state) => state.widgets);

  /**
   * Скрыть нотификацию
   */
  const handleNotifyClose = (id) => {
    dispatch({ type: HIDE_NOTIFY.REQUEST, payload: { id } });
  };

  const renderNotififations = () => {
    const items = notify.items || [];
    return (
      <Notify styleName="notify" value={items} onClose={handleNotifyClose} />
    );
  };

  const renderModals = () => {
    return modal?.map?.((m) => <Modal key={m.name} {...m} />);
  };

  const renderWidgets = () => {
    return widgets?.map?.((m) => (m ? <Widget key={m.id} {...m} /> : null));
  };

  return (
    <PopoverBoundary.Consumer>
      {({ globalBoundaryRef }) => (
        <div
          ref={globalBoundaryRef}
          id="mainRoot"
          className={`theme-${theme} theme`}
          style={{ height: '100%' }}
        >
          {children}
          {renderNotififations()}
          {renderModals()}
          {renderWidgets()}
        </div>
      )}
    </PopoverBoundary.Consumer>
  );
}

EmptyLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default EmptyLayout;
