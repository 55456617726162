import { useState, useEffect } from 'react';

/**
 * Creates an interval for executing a callback function at a given delay.
 *
 * @param {function} callback - The function to be called at each interval.
 * @param {number} delay - The delay, in milliseconds, between each interval.
 * @return {object} An object with start, stop, reset, and isRunning properties.
 */
function useInterval(callback, delay) {
  const [isRunning, setIsRunning] = useState(true);

  useEffect(() => {
    if (isRunning) {
      const intervalId = setInterval(callback, delay);
      return () => clearInterval(intervalId);
    }
  }, [isRunning, callback, delay]);

  return {
    start: () => setIsRunning(true),
    stop: () => setIsRunning(false),
    reset: () => {
      setIsRunning(false);
      setIsRunning(true);
    },
    isRunning,
  };
}

export default useInterval;
