import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  constr: {
    id: 'constr',
    defaultMessage: 'Constructor',
  },
  preview: {
    id: 'preview',
    defaultMessage: 'Preview',
  },
  formAccessRules: {
    id: 'formAccessRules',
    defaultMessage: 'Form access',
  },
  actorsAccessRules: {
    id: 'actorsAccessRules',
    defaultMessage: 'Actors access',
  },
  actorsDefAccounts: {
    id: 'actorsDefAccounts',
    defaultMessage: 'Accounts',
  },
  copyFormId: {
    id: 'copyFormId',
    defaultMessage: 'Copy form ID',
  },
  formIdCopied: {
    id: 'formIdCopied',
    defaultMessage: 'Form ID has been copied',
  },
  uatMap: {
    id: 'uatMap',
    defaultMessage: 'UAT Map',
  },
});

export default { ...globalIntl, ...m };
