import { put, takeLatest, select } from 'redux-saga/effects';
import { SHOW_NOTIFY, HIDE_NOTIFY } from 'constants';

/**
 * Отобразить ошибку
 * @param action
 * @returns {IterableIterator<*>}
 */
function* showNotify(action) {
  const { items } = yield select((state) => state.notify);
  // Создаем новый массив
  const newItems = items.slice(0);
  const item = action.payload;
  newItems.unshift(item);
  yield put({ type: SHOW_NOTIFY.SUCCESS, payload: { items: newItems } });
}

/**
 * Скрыть ошибку
 * @param action
 * @returns {IterableIterator<*>}
 */
function* hideNotify(action) {
  const { items } = yield select((state) => state.notify);
  // Создаем новый массив
  const newItems = items.slice(0);
  const item = action.payload;
  const removeIndex = newItems.map((i) => i.id).indexOf(item.id);
  newItems.splice(removeIndex, 1);
  yield put({ type: HIDE_NOTIFY.SUCCESS, payload: { items: newItems } });
}
function* notify() {
  yield takeLatest(SHOW_NOTIFY.REQUEST, showNotify);
  yield takeLatest(HIDE_NOTIFY.REQUEST, hideNotify);
}
export default notify;
