import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  selectTags: {
    id: 'selectTags',
    defaultMessage: 'Select tags',
  },
  tagsSaved: {
    id: 'tagsSaved',
    defaultMessage: 'Tags have been successfully saved',
  },
});

export default { ...globalIntl, ...m };
