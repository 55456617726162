import {
  UPDATE_ACTOR_VIEW,
  SET_ACTOR_VIEW_REQ_STATUS,
  CLEAR_ACTOR,
} from '@control-front-end/common/constants/graphActors';
import { createReducer } from '@reduxjs/toolkit';

// ------------------------------------
// Reducer
// ------------------------------------

const initState = {
  access: [],
  defaultAccess: [],
  cachedDescription: '',
  history: [],
  reactions: [],
  breadCrumbs: [],
  attendees: [],
  suggestions: [],
  invites: [],
  newInvites: [],
  tags: [],
  location: {},
  reactionRate: 0,
  rating: 0,
  reactionsCount: 0,
  reactionsReqStatus: 'inProgress',
  reqStatus: 'success',
};

export default createReducer(initState, {
  [UPDATE_ACTOR_VIEW.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SET_ACTOR_VIEW_REQ_STATUS](state, action) {
    return { ...state, reqStatus: action.payload };
  },
  [CLEAR_ACTOR]() {
    return initState;
  },
});
