import PropTypes from 'prop-types';
import { FORM_UI } from './constatns';

/**
 * Each defined element could be turned on or disabled.
 * Also there is opportunity to set some custom node instead
 */
export const formUIPropTypes = PropTypes.shape(
  Object.keys(FORM_UI).reduce(
    (acc, key) => ({
      ...acc,
      [key]: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
    }),
    {}
  )
);
