import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Select as SelectMW, MenuItem } from 'mw-style-react';
import AutocompleteSources from '@control-front-end/common/components/AutocompleteSources';
import './Select.scss';

/**
 * Компонент Select
 */
function Select(props) {
  const {
    id,
    title,
    value,
    error,
    visibility,
    options,
    extra = {},
    isPreview = false,
    getErrorMsg,
    onChange,
    getFormData,
    formId,
    newSource = false,
    autocomplete = false,
  } = props;
  const hasOptionsSource =
    extra.optionsSource && extra.optionsSource.type !== 'manual';

  const renderItems = () => {
    if (Array.isArray(options)) {
      return options.map((i) => (
        <MenuItem key={i.value} value={i.value} label={i.title} />
      ));
    }
    return <MenuItem value={options} label={options} />;
  };

  return (
    <div onMouseOver={(e) => e.stopPropagation()}>
      {hasOptionsSource ? (
        <AutocompleteSources
          key={id}
          id={id}
          formId={formId}
          className={props.class}
          value={Array.isArray(value) ? value : [{ value, title: value }]}
          label={title}
          error={error}
          visibility={visibility}
          helperText={getErrorMsg(props)}
          onChange={onChange}
          getFormData={getFormData}
          isPreview={isPreview}
          newSource={newSource}
          resettable
          resettableSearch
        />
      ) : (
        <SelectMW
          id={id}
          bordered={true}
          styleName={cn('select')}
          label={title}
          visibility={visibility}
          type={autocomplete ? 'autocomplete' : 'default'}
          value={
            Array.isArray(value) && value.length ? value[0].value : value || ''
          }
          error={error}
          helperText={getErrorMsg(props)}
          resettable={true}
          onChange={(val) => {
            if (!Array.isArray(options)) return;
            const findV = options.filter((i) => i.value === val.value);
            onChange({ ...val, value: findV });
          }}
          popoverOnTop
        >
          {renderItems()}
        </SelectMW>
      )}
    </div>
  );
}

Select.propTypes = {
  id: PropTypes.string.isRequired,
  class: PropTypes.string.isRequired,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  error: PropTypes.bool,
  visibility: PropTypes.string,
  extra: PropTypes.object,
  onChange: PropTypes.func,
  getFormData: PropTypes.func,
  getErrorMsg: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  formId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  newSource: PropTypes.bool,
};

export default Select;
