import { defineMessages } from 'react-intl';

const m = defineMessages({
  avatar: {
    id: 'avatar_ActorCardViewSettings',
    defaultMessage: 'Avatar',
  },
  accounts: {
    id: 'accounts_ActorCardViewSettings',
    defaultMessage: 'Accounts',
  },
  formFields: {
    id: 'formFields_ActorCardViewSettings',
    defaultMessage: 'Form fields',
  },
  description: {
    id: 'description_ActorCardViewSettings',
    defaultMessage: 'Description',
  },
  widgetChat: {
    id: 'widgetChat_ActorCardViewSettings',
    defaultMessage: 'Widget Chat',
  },
  field: {
    id: 'field_ActorCardViewSettings',
    defaultMessage: 'Field',
  },
  icon: {
    id: 'icon_ActorCardViewSettings',
    defaultMessage: 'Icon',
  },
  add: {
    id: 'add_ActorCardViewSettings',
    defaultMessage: 'Add',
  },
  none: {
    id: 'none_ActorCardViewSettings',
    defaultMessage: 'None',
  },
  layer: {
    id: 'layer_ActorCardViewSettings',
    defaultMessage: 'Layer',
  },
});

export default m;
