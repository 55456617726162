import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';
import { Label as LabelMW } from 'mw-style-react';
import './Link.scss';

/**
 * Компонент Link
 */
class Link extends PureComponent {
  render() {
    const { id, visibility, align, value, title } = this.props;
    return (
      <a
        id={id}
        href={value}
        target="_blank"
        styleName={cn('link', {
          disabled: visibility === 'disabled',
          left: align === 'left',
          center: align === 'center',
          right: align === 'right',
        })}
      >
        <LabelMW value={title} />
      </a>
    );
  }
}

Link.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.string,
  align: PropTypes.string,
  visibility: PropTypes.string,
};

export default Link;
