import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useIntl from 'useIntl';
import cn from 'classnames';
import { BULK_ACTIONS_LIMIT, NOTIFY_LEVEL, SHOW_NOTIFY } from 'constants';
import { PERMISSIONS } from '@control-front-end/common/constants/permissions';
import { Label, Icon, Menu, MenuItem, Checkbox, Tooltip } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import { checkUserPermissions } from '../../selectors';
import './BulkActionsHeader.scss';
import mes from './intl';

/**
 * Bulk actions on actors/forms menu
 */
function BulkActionsHeader(props) {
  const {
    objType,
    list,
    total,
    actions,
    selectedIds,
    canRemoveAll = false,
    allExistedSelected = false,
    selectAllExisted = () => {},
    handleBulkSelect,
    handleAddAccounts = () => {},
    handleRemoveSelected = () => {},
    handleRemoveAll = () => {},
    handleShare = () => {},
    handleExport = () => {},
    handleManageTags = () => {},
  } = props;
  const t = useIntl();
  const dispatch = useDispatch();
  const [actionsMenu, toggleActionsMenu] = useState(false);
  const [selectionMenu, toggleSelectionMenu] = useState(false);
  const checkPermissions = useSelector(checkUserPermissions) || {};
  const allSelected = list.every((item) => selectedIds.includes(item.id));
  const someSelected =
    list.filter((item) => selectedIds.includes(item.id)).length > 0 &&
    !allSelected;
  const limitExceeded = selectedIds.length > BULK_ACTIONS_LIMIT;
  const showMoreActions = useMemo(
    () =>
      ['export', 'account', 'remove'].some((item) => actions.includes(item)),
    [actions]
  );

  const notifyLimitError = () => {
    dispatch({
      type: SHOW_NOTIFY.REQUEST,
      payload: {
        id: AppUtils.createRid(),
        type: NOTIFY_LEVEL.ERROR,
        label: t(mes.bulkLimitExceeded, {
          limit: BULK_ACTIONS_LIMIT,
        }),
      },
    });
  };

  useEffect(() => {
    if (limitExceeded) notifyLimitError();
    // if some actors were selected/unselected manually, reset bulk selection checkbox
    if (allExistedSelected && !allSelected) selectAllExisted(false);
  }, [selectedIds.length]);

  /**
   * Модальное окно подтверждения удаления
   */
  const handleRemoveItems = () => {
    if (allExistedSelected) {
      handleRemoveAll();
    } else {
      handleRemoveSelected();
    }
  };

  const handleMenuClick = ({ value }) => {
    if (value !== 'export' && limitExceeded) {
      notifyLimitError();
      return;
    }
    switch (value) {
      case 'export':
        handleExport();
        break;
      case 'account':
        handleAddAccounts();
        break;
      case 'remove':
        handleRemoveItems();
        break;
      case 'share':
        handleShare();
        break;
      case 'tags':
        handleManageTags();
        break;
      default:
        break;
    }
    toggleActionsMenu(false);
  };

  const renderActionsMenu = () => (
    <div onClick={(e) => e.stopPropagation()}>
      <Menu
        styleName="bulk__actions__menu"
        size="small"
        width={200}
        onClick={handleMenuClick}
        onClose={() => toggleActionsMenu(false)}
      >
        <MenuItem
          size="small"
          label={t(mes.addToExport)}
          value="export"
          visibility={
            actions.includes('export') &&
            !allExistedSelected &&
            checkPermissions([PERMISSIONS.IMPORT_EXPORT_MANAGEMENT])
              ? 'visible'
              : 'hidden'
          }
        />
        <MenuItem
          size="small"
          label={t(mes.openAccount)}
          value="account"
          visibility={
            actions.includes('account') && !allExistedSelected
              ? 'visible'
              : 'hidden'
          }
        />
        <MenuItem
          size="small"
          label={t(mes.remove)}
          value="remove"
          visibility={actions.includes('remove') ? 'visible' : 'hidden'}
        />
      </Menu>
    </div>
  );

  const handleSelectionMenuClick = ({ value }) => {
    switch (value) {
      case 'deselect':
        handleBulkSelect([]);
        selectAllExisted(false);
        break;
      case 'select_page':
        handleBulkSelect('add');
        selectAllExisted(false);
        break;
      case 'select_all':
        handleBulkSelect('add');
        selectAllExisted(true);
        break;
      default:
        break;
    }
    toggleSelectionMenu(false);
  };

  return (
    <div styleName="bulk">
      <div
        styleName={cn('bulk__selection', {
          active: !!selectionMenu && canRemoveAll,
        })}
        onClick={(e) => e.stopPropagation()}
      >
        <Checkbox
          styleName="bulk__selection__check"
          value={allSelected}
          onChange={() =>
            handleBulkSelect(allSelected || someSelected ? 'remove' : 'add')
          }
        />
        {someSelected ? <div styleName="bulk__selection__some" /> : null}
        {canRemoveAll ? (
          <div onClick={() => toggleSelectionMenu(!actionsMenu)}>
            <Icon
              styleName="bulk__selection__dropdown"
              size="micro"
              type="dropdown"
            />
            {selectionMenu ? (
              <div onClick={(e) => e.stopPropagation()}>
                <Menu
                  styleName="bulk__selection__menu"
                  size="small"
                  width={240}
                  onClick={handleSelectionMenuClick}
                  onClose={() => toggleSelectionMenu(false)}
                >
                  <MenuItem
                    size="small"
                    label={t(mes.deselectAll)}
                    value="deselect"
                    visibility={selectedIds.length ? 'visible' : 'hidden'}
                  />
                  <MenuItem
                    size="small"
                    label={t(mes.selectAllOnPage, { count: list.length })}
                    value="select_page"
                  />
                  <MenuItem
                    size="small"
                    label={t(mes.selectAllActors, { count: total })}
                    value="select_all"
                    visibility={
                      checkPermissions([PERMISSIONS.ACTORS_MANAGEMENT]) &&
                      canRemoveAll
                        ? 'visible'
                        : 'hidden'
                    }
                  />
                </Menu>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      {selectedIds.length ? (
        <Label
          styleName="bulk__selection__label selected"
          fontWeight="semibold"
          value={
            allExistedSelected
              ? t(mes.allActorsSelected, { count: total })
              : t(mes.itemsSelected, { count: selectedIds.length })
          }
        />
      ) : (
        <Label
          styleName="bulk__selection__label"
          value={t(mes[`${objType}Name`])}
        />
      )}
      {selectedIds.length ? (
        <div styleName="bulk__actions">
          {actions.includes('access') &&
          !allExistedSelected &&
          checkPermissions([PERMISSIONS.USERS_READONLY]) ? (
            <Tooltip value={t(mes.shareWith)}>
              <div onClick={() => handleMenuClick({ value: 'share' })}>
                <Icon type="share" />
              </div>
            </Tooltip>
          ) : null}
          {actions.includes('tags') && !allExistedSelected ? (
            <Tooltip value={t(mes.manageTags)}>
              <div onClick={() => handleMenuClick({ value: 'tags' })}>
                <Icon type="hash" />
              </div>
            </Tooltip>
          ) : null}
          <div onClick={() => toggleActionsMenu(!actionsMenu)}>
            <Icon
              type="more"
              visibility={showMoreActions ? 'visible' : 'hidden'}
            />
          </div>
          {actionsMenu ? renderActionsMenu() : null}
        </div>
      ) : null}
    </div>
  );
}

BulkActionsHeader.propTypes = {
  objType: PropTypes.oneOf(['actor', 'form', 'account', 'currency']),
  actions: PropTypes.arrayOf(
    PropTypes.oneOf(['export', 'account', 'remove', 'access', 'tags'])
  ).isRequired,
  selectedIds: PropTypes.array,
  list: PropTypes.array,
  total: PropTypes.number,
  canRemoveAll: PropTypes.bool,
  allExistedSelected: PropTypes.bool,
  selectAllExisted: PropTypes.func,
  handleBulkSelect: PropTypes.func.isRequired,
  handleShare: PropTypes.func,
  handleExport: PropTypes.func,
  handleAddAccounts: PropTypes.func,
  handleRemoveSelected: PropTypes.func,
  handleRemoveAll: PropTypes.func,
};

export default BulkActionsHeader;
