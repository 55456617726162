import {
  GET_EDGE_TYPES,
  GET_EDGE_TYPE,
} from '@control-front-end/common/constants/graphActors';
import { createReducer } from '@reduxjs/toolkit';

// ------------------------------------
// Reducer
// ------------------------------------

const initState = [];

export default createReducer(initState, {
  [GET_EDGE_TYPES.SUCCESS](state, action) {
    return action.payload;
  },
  [GET_EDGE_TYPE.SUCCESS](state, action) {
    const types = state.slice();
    types.push(action.payload);
    return types;
  },
});
