import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  discardChanges: {
    id: 'discardChanges',
    defaultMessage: 'Discard changes',
  },
  discard: {
    id: 'discard',
    defaultMessage: 'Discard',
  },
  discardQuestion: {
    id: 'discardQuestion',
    defaultMessage: 'Are you sure you want to discard changes and leave?',
  },
});

export default { ...globalIntl, ...m };
