import { defineMessages } from 'react-intl';

const m = defineMessages({
  title: {
    id: 'title_ConfirmWorkspaceExportModal',
    defaultMessage: 'Export Workspace',
  },
  warning: {
    id: 'warning_ConfirmWorkspaceExportModal',
    defaultMessage:
      'Are you sure you want to export all workspace "{workspaceName}"?',
  },
  warningInfo: {
    id: 'warningInfo_ConfirmWorkspaceExportModal',
    defaultMessage: 'This can take a long time',
  },
  submitButtonText: {
    id: 'submitButtonText_ConfirmWorkspaceExportModal',
    defaultMessage: 'Export',
  },
});

export default m;
