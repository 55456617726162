import React from 'react';
import { MenuItem, Select, TextField } from 'mw-style-react';
import { AMOUNT_OPER } from '@control-front-end/common/constants/actorAccounts';
import PropTypes from 'prop-types';
import useIntl from 'useIntl';
import mes from './intl';
import '../../CreateTransactionsFilter.scss';

/**
 * Компонент выбора фильтра по сумме транзакции
 * @param oper
 * @param amount
 * @param errors
 * @param onChange
 * @returns {JSX.Element}
 * @constructor
 */
function AmountValue({ oper, amount, errors, onChange }) {
  const t = useIntl();

  return (
    <div styleName="filter__row">
      <Select
        id="oper"
        styleName="filter__oper"
        label={t(mes.amount)}
        bordered={true}
        value={oper}
        onChange={onChange}
        popoverOnTop
      >
        {Object.keys(AMOUNT_OPER).map((operType) => (
          <MenuItem
            key={operType}
            value={operType}
            size="small"
            label={AMOUNT_OPER[operType]}
          />
        ))}
      </Select>
      <TextField
        id="amount"
        styleName="filter__amount"
        type="float"
        placeholder={t(mes.enterAmount)}
        bordered={true}
        value={amount}
        error={!!errors.amount}
        helperText={t(mes[errors.amount])}
        onChange={onChange}
      />
    </div>
  );
}

AmountValue.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  oper: PropTypes.string,
  errors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default AmountValue;
