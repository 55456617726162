import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  findExportItem: {
    id: 'findExportItem',
    defaultMessage: 'Find export item by name',
  },
  exportAccounts: {
    id: 'exportAccounts',
    defaultMessage: 'Export accounts with transactions and transfers',
  },
  exportUsers: {
    id: 'exportUsers',
    defaultMessage: 'Export users and their access permissions',
  },
  exportAttachments: {
    id: 'exportAttachments',
    defaultMessage: 'Export attachments',
  },
  exportCorezoidEntities: {
    id: 'exportCorezoidEntities',
    defaultMessage:
      'Export Corezoid entities (projects, processes, api gateway, etc.)',
  },
});

export default { ...globalIntl, ...m };
