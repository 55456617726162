import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  Autocomplete,
  AutocompleteItem,
  Stack,
  Icon,
  ProgressBar,
} from 'mw-style-react';
import { useSearchList } from 'hooks';
import { GET_FORM_ITEM_OPTIONS } from '@control-front-end/common/constants/forms';
import scss from './AutocompleteSources.scss';

/**
 * Component for work with options source
 */
function AutocompleteSources(props) {
  const {
    formId,
    id,
    value,
    title,
    error,
    helperText,
    visibility = 'visible',
    label,
    className,
    getFormData = () => {},
    onChange,
    styleType = 'default',
    portal = true,
    newSource = false,
    resettable = false,
    resettableSearch = false,
    renderChipsItems = () => {},
  } = props;
  const searchBox = useRef();
  const [focus, setFocus] = useState(false);
  const [valueTitle, setValueTitle] = useState(
    title ? [{ value, title }] : value
  );

  const [searchString, setSearchString] = useState('');

  const {
    list: itemsToShow,
    loading,
    makeSearch,
    onScroll,
  } = useSearchList({
    actionType: GET_FORM_ITEM_OPTIONS.REQUEST,
    skip: !formId || newSource || !focus,
    searchParams: { queryString: '' },
    settings: { step: 5 },
    formId,
    formData: getFormData(),
    itemId: id,
  });

  /**
   * Handle focus lost
   */
  const handleBlur = useCallback(() => {
    setFocus(false);
    if (resettableSearch) {
      setSearchString('');
      makeSearch({ queryString: '' });
    }
  }, []);

  /**
   * Handle focus
   */
  const handleFocus = useCallback(() => {
    setFocus(true);
    searchBox.current?.querySelector('input')?.focus();
  }, [searchBox.current]);

  const handleToggleFocus = () => (focus ? handleBlur() : handleFocus());

  /**
   * Search request handler
   */
  const handleSearch = ({ value }) => {
    const queryString = value.trimStart();
    setSearchString(queryString);
    makeSearch({ queryString });
  };

  /**
   * Search result item click handler
   */
  const handleItemClick = (item) => {
    if (className === 'multiSelect') {
      const newItems = value.slice(0);
      newItems.push(item);
      onChange({ id, value: newItems, error: false });
    } else {
      onChange({ id, value: [item], error: false });
      setValueTitle([item]);
    }
    handleBlur();
  };

  const handleResetClick = () => {
    setValueTitle('');
    onChange({ id, value: [], error: false });
  };

  const renderSearchResult = () => {
    if (!focus) return;
    const selectedItems =
      className === 'multiSelect' && Array.isArray(value) ? value : [];

    const items = itemsToShow.map((i) => (
      <AutocompleteItem
        key={i.value}
        id={i.value}
        size="small"
        value={i.title}
        onClick={() => handleItemClick(i)}
        visibility={
          selectedItems.find(({ value }) => value === i.value)
            ? 'disabled'
            : 'visible'
        }
      />
    ));
    if (searchString.length < 2 && !items.length) return;
    return (
      <div>
        {items.length ? (
          items
        ) : (
          <AutocompleteItem
            size="small"
            value="Not found"
            onClick={handleBlur}
          />
        )}
      </div>
    );
  };

  const getValueTitle = () => {
    if (focus || className === 'multiSelect') return searchString;
    return valueTitle && valueTitle.length ? valueTitle[0].title : '';
  };

  return (
    <div styleName={cn('field', { default: styleType === 'default' })}>
      <div
        ref={searchBox}
        styleName="field__content"
        onMouseOver={(e) => e.stopPropagation()}
      >
        <Autocomplete
          styleName={styleType}
          label={label}
          value={getValueTitle()}
          bordered={true}
          error={error}
          helperText={helperText}
          onChange={handleSearch}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onSearchListScroll={onScroll}
          visibility={visibility}
          searchResult={renderSearchResult}
          chipsItems={renderChipsItems}
          popoverOnTop={portal}
          popoverOptions={{
            contentClassName: scss.autocompleteContent,
          }}
          endAdornment={
            <Stack
              styleName="field__content__end"
              horizontal
              size="xsmall"
              alignItems="center"
            >
              <Icon
                size="xsmall"
                type="dropdown"
                onClick={visibility === 'visible' ? handleToggleFocus : null}
              />
              {resettable && value?.length ? (
                <Icon size="small" type="close" onClick={handleResetClick} />
              ) : null}
            </Stack>
          }
        />
        {loading ? (
          <div styleName="loader">
            <ProgressBar size="small" />
          </div>
        ) : null}
      </div>
    </div>
  );
}

AutocompleteSources.propTypes = {
  id: PropTypes.string,
  formId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  title: PropTypes.string,
  styleType: PropTypes.oneOf(['default', 'small']),
  className: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  visibility: PropTypes.string,
  helperText: PropTypes.string,
  portal: PropTypes.bool,
  newSource: PropTypes.bool,
  onChange: PropTypes.func,
  getFormData: PropTypes.func,
  renderChipsItems: PropTypes.func,
  resettableSearch: PropTypes.bool,
};

export default AutocompleteSources;
