import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Icon, Label, TextField } from 'mw-style-react';
import '../Panel.scss';
import useIntl from 'useIntl';
import mes from '../intl';

/**
 * Panel component Edit
 */
function Edit(props) {
  const t = useIntl();
  const {
    id,
    idNotChanged = false,
    regexp,
    title,
    errors = {},
    value = '',
    type = 'text',
    onChange,
    handleIdChange,
  } = props;
  const error = errors[id];

  const renderField = useCallback(
    () => (
      <TextField
        id={id}
        bordered={true}
        label={title}
        value={value}
        type={type}
        error={!!error}
        regexp={regexp}
        helperText={error || ''}
        onChange={id === 'id' ? handleIdChange : onChange}
      />
    ),
    [id, title, value, type, error, regexp]
  );

  return (
    <div styleName="panel__el">
      {renderField()}
      {idNotChanged ? (
        <div styleName="panel__el__warning">
          <Icon type="alert" size="small" color="#F8AB03" />
          <Label
            value={t(mes.recommendSetUniqueId)}
            fontSize="small"
            textColor="#7E8A9A"
          />
        </div>
      ) : null}
    </div>
  );
}

Edit.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  errors: PropTypes.object,
  regexp: PropTypes.string,
  onChange: PropTypes.func,
  handleIdChange: PropTypes.func,
};

export default Edit;
