import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useIntl, useOutsideClick, useScroll } from 'hooks';
import cn from 'classnames';
import {
  TextField,
  Icon,
  DateUtils,
  Utils,
  ProgressBar,
  Button,
  Stack,
  Space,
} from 'mw-style-react';
import { DATE_FORMAT } from 'constants';
import mes from './intl';
import scss from './Search.scss';

function Search(props) {
  const {
    placeholder,
    bordered,
    onClear,
    onClose,
    onSearch,
    onSelect,
    autoSelect,
    withSubmit,
    showLoader,
    style,
    onTop,
  } = props;
  const t = useIntl();
  const searchRef = useRef(null);
  const itemsRefs = useRef([]);
  const containerSearchList = useRef(null);
  const [query, setQuery] = useState('');
  const [activeItem, setActiveItem] = useState(-1);
  const [focus, setFocus] = useState(false);
  const { list: searchList, endList } = useSelector((state) => state.search);

  useEffect(() => {
    if (withSubmit) return;
    const timeOutId = setTimeout(() => {
      if (!query.length) return;
      onSearch(query);
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [query]);

  const handleCloseClick = () => {
    if (query.length) {
      setQuery('');
      onClear();
    } else {
      onClose();
    }
  };

  useOutsideClick({
    ref: searchRef,
    callback: () => {
      setFocus(false);
      const input = searchRef.current.getElementsByTagName('input')[0];
      if (input.value) return;
      onClose();
    },
  });

  useScroll({
    ref: containerSearchList,
    callback: () => {
      if (!query.length || endList) return;
      onSearch(query, true);
    },
  });

  // Autoscroll to the selected element from the search results when navigating by keys
  const scrollList = (item) => {
    const container = containerSearchList.current;
    const heightContainer = container.offsetHeight;
    const heightItem = item.offsetHeight;
    const scrollPosition =
      item.offsetTop < heightContainer - heightItem * 3
        ? 0
        : item.offsetTop - (heightContainer - heightItem * 3);
    Utils.scrollTo(container, scrollPosition, 100);
  };

  // Navigation through search results using hotkeys
  const selectionUsingButtons = (e) => {
    e.preventDefault();
    if (withSubmit) onSearch(query);
    if (!searchList.length) return;
    let newActiveItem = activeItem;

    if (e.key === 'ArrowDown' && activeItem < searchList.length - 1) {
      newActiveItem += 1;
    } else if (e.key === 'ArrowUp' && activeItem > 0) {
      newActiveItem -= 1;
    }

    if (!searchList[newActiveItem]) return;
    const item = itemsRefs.current[newActiveItem];

    if (e.key === 'Enter') {
      item.click();
      newActiveItem = 0;
    }

    setActiveItem(newActiveItem);
    scrollList(item);
  };

  // Handler keydown
  const handleKeyDown = (e) => {
    const hotKeys = ['ArrowDown', 'ArrowUp', 'Enter'];
    if (hotKeys.indexOf(e.key) !== -1) {
      selectionUsingButtons(e);
    }
  };

  const handleOnChange = ({ value }) => {
    setQuery(value);
    if (value.length === 0) onClear();
  };

  const getIconType = (objType) => {
    switch (objType) {
      case 'actor':
        return 'actor';
      case 'tag':
        return 'hash';
      case 'user':
        return 'client';
      default:
        return '';
    }
  };

  // Render search results
  const renderSearchResults = () => {
    const items = searchList.map((item, index) => (
      <div
        key={`${item.id}_${item.objType}`}
        ref={(el) => (itemsRefs.current[index] = el)}
        styleName={cn('search__results__item', {
          active: index === activeItem,
        })}
        onClick={() => {
          setQuery('');
          onSelect(item);
        }}
      >
        <div styleName="search__results__item__icon">
          <Icon type={getIconType(item.objType)} />
        </div>
        <div styleName="search__results__item__info">
          <div styleName="search__results__item__title">
            {item.title || item.name || item.nick}
          </div>
          <div styleName="search__results__item__extra">
            {t(mes.created)} {DateUtils.toDate(item.createdAt, DATE_FORMAT)}
            &nbsp;
            {item.ownerName ? `${t(mes.by)} ${item.ownerName}` : null}
          </div>
        </div>
      </div>
    ));
    return (
      <div
        ref={containerSearchList}
        className={cn(scss.search__results, {
          [scss.onTop]: onTop,
        })}
      >
        {items}
      </div>
    );
  };

  return (
    <div ref={searchRef} styleName="search" style={style}>
      <TextField
        styleName={cn('search__field', {
          withSubmit: query.length && withSubmit,
        })}
        leftIcon="search"
        size="large"
        placeholder={placeholder || t(mes.mainSearchPlaceholder)}
        onChange={handleOnChange}
        onFocus={() => setFocus(true)}
        autoFocus={true}
        autoSelect={autoSelect}
        bordered={bordered}
        value={query || ''}
        onClickRightIcon={handleCloseClick}
        onKeyDown={handleKeyDown}
        endAdornment={
          <Space left right size={Space.SIZE.xsmall}>
            <Stack horizontal size={Stack.SIZE.xsmall}>
              {showLoader ? (
                <ProgressBar styleName="search__progressBar" size="small" />
              ) : null}
              {query.length ? (
                <Button
                  type="text"
                  size="xsmall"
                  icon={<Icon size="small" type="close" />}
                  onClick={handleCloseClick}
                />
              ) : null}
            </Stack>
          </Space>
        }
      />
      {searchList.length && focus ? renderSearchResults() : null}
    </div>
  );
}

Search.defaultProps = {
  bordered: true,
  autoSelect: 'mount',
  withSubmit: false,
  showLoader: false,
  onTop: false,
};

Search.propTypes = {
  placeholder: PropTypes.string,
  withSubmit: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  bordered: PropTypes.bool,
  showLoader: PropTypes.bool,
  onTop: PropTypes.bool,
};

export default Search;
