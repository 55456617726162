/**
 * Adds an actor to a node if the actor is truthy, otherwise returns the original node.
 *
 * @param {object} options - The object containing the node and the actor.
 * @param {object} options.node - The node to which the actor will be added.
 * @param {object} options.actor - The actor to be added to the node.
 * @return {object} The updated node with the actor added, or the original node if actor is falsy.
 */
const addActorToNode = ({ node, actor }) =>
  actor
    ? {
        ...node,
        static: false,
        data: {
          ...node.data,
          ...actor,
          id: node.id,
          actorId: actor.actorId || actor.id,
          static: false,
        },
      }
    : node;

export default addActorToNode;
