import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  layerAccount: {
    id: 'layerAccount',
    defaultMessage: 'Set layer account',
  },
});

export default { ...globalIntl, ...m };
