import React from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  Space,
  TextField,
  Label,
  Divider,
  useObjectState,
} from 'mw-style-react';
import { useIntl } from 'hooks';
import Toggle from '@control-front-end/common/components/Toggle';

import m from './intl';

function ScriptCreditionalFieldMapper({
  title,
  placeholder,
  value,
  onChange,
  textFieldProps = {},
}) {
  return (
    <Stack
      fullWidth
      horizontal
      justifyContent="spaceBetween"
      alignItems="center"
    >
      <Label value={title} color={Label.COLOR.black} />
      <div style={{ width: '305px' }}>
        <TextField
          bordered
          unspaced
          value={value}
          placeholder={placeholder}
          color={TextField.COLOR.white}
          onChange={onChange}
          {...textFieldProps}
        />
      </div>
    </Stack>
  );
}

function ScriptCredentialsMappingMaker({ value, onChange }) {
  const t = useIntl();
  const [state, setState] = useObjectState(value, {
    transformValue: ({ value }, key) =>
      key === 'apiSecret' ? value : Number(value),
    onChange,
  });

  return (
    <Stack fullWidth divider={<Divider />}>
      <ScriptCreditionalFieldMapper
        key="api_login"
        title={t(m.apiLogin)}
        placeholder={t(m.apiLoginPlaceholder)}
        value={state.apiLogin}
        onChange={setState.apiLogin}
        textFieldProps={{ type: 'int' }}
      />
      <ScriptCreditionalFieldMapper
        key="api_secret"
        title={t(m.apiSecret)}
        placeholder={t(m.apiSecretPlaceholder)}
        value={state.apiSecret}
        onChange={setState.apiSecret}
        textFieldProps={{ type: 'password' }}
      />
      <ScriptCreditionalFieldMapper
        key="dev_proc_id"
        title={t(m.devProcId)}
        placeholder={t(m.devProcIdPlaceholder)}
        value={state.devProcId}
        onChange={setState.devProcId}
        textFieldProps={{ type: 'int' }}
      />
      <ScriptCreditionalFieldMapper
        key="prod_proc_id"
        title={t(m.prodProcId)}
        placeholder={t(m.prodProcIdPlaceholder)}
        value={state.prodProcId}
        onChange={setState.prodProcId}
        textFieldProps={{ type: 'int' }}
      />
    </Stack>
  );
}

const ScriptCredentialsPropType = PropTypes.shape({
  apiLogin: PropTypes.number,
  apiSecret: PropTypes.string,
  devProcId: PropTypes.number,
  prodProcId: PropTypes.number,
});

ScriptCredentialsMappingMaker.propTypes = {
  value: ScriptCredentialsPropType.isRequired,
  onChange: PropTypes.func.isRequired,
};

function CredentialsMappingMaker({ scripts, onChange }) {
  const t = useIntl();

  return (
    <div>
      <Space bottom>
        <Label
          value={t(m.title, { amount: scripts.length })}
          color={Label.COLOR.black}
          fontWeight="semibold"
        />
      </Space>
      <div>
        {scripts.map((item) => (
          <Toggle
            key={item.id}
            theme="dark"
            title={
              <Label
                fontWeight="semibold"
                color={Label.COLOR.black}
                value={item.title}
              />
            }
          >
            <Space top bottom>
              <ScriptCredentialsMappingMaker
                onChange={(newCredentials) => {
                  onChange(
                    scripts.map((i) =>
                      i.id === newCredentials.id ? newCredentials : i
                    )
                  );
                }}
                value={item}
                oldSettings={[].find(({ id }) => id === item.id)}
              />
            </Space>
          </Toggle>
        ))}
      </div>
    </div>
  );
}

CredentialsMappingMaker.propTypes = {
  scripts: PropTypes.arrayOf(ScriptCredentialsPropType).isRequired,
};

export default CredentialsMappingMaker;
