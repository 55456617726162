import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  attachments: {
    id: 'attachments',
    defaultMessage: 'Attachments',
  },
  script: {
    id: 'script',
    defaultMessage: 'Script',
  },
  actorCard: {
    id: 'actorCard',
    defaultMessage: 'Actor card',
  },
});

export default { ...globalIntl, ...m };
