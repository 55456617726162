import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  snippets: {
    id: 'snippets',
    defaultMessage: 'Snippets',
  },
  noSnippetsFound: {
    id: 'noSnippetsFound',
    defaultMessage: 'There are no search results for your request',
  },
  noSnippetsExist: {
    id: 'noSnippetsExist',
    defaultMessage: 'There are no available snippets',
  },
});

export default { ...globalIntl, ...m };
