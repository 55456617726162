import React from 'react';
import PropTypes from 'prop-types';
import { Label, Tooltip } from 'mw-style-react';
import UserAvatar from '@control-front-end/common/components/UserAvatar';
import useIntl from 'useIntl';
import mes from './intl';
import './Header.scss';

function Header({ title, ownerName, ownerAvatar }) {
  const t = useIntl();

  return (
    <div className="event__header" styleName="event__header">
      <Tooltip value={ownerName}>
        <UserAvatar
          styleName="event__header__avatar"
          src={ownerAvatar}
          label={ownerName}
          size="small"
        />
      </Tooltip>
      <Label
        className="event__header__title"
        styleName="event__header__title"
        fontWeight="semibold"
        placeholder={t(mes.subject)}
        value={title || t(mes.newEvent)}
      />
    </div>
  );
}

Header.propTypes = {
  title: PropTypes.string,
  ownerAvatar: PropTypes.string,
  ownerName: PropTypes.string,
};

export default Header;
