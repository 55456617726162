import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import useOutsideClick from 'useOutsideClick';
import { Icon } from 'mw-style-react';
import { FORMULA_SYMBOLS } from '@control-front-end/common/constants/regExp';
import FuncSelect from './components/FuncSelect';
import './FormulaTextInput.scss';

const FUNCTION_ARGUMENT_SEPARATORS = ',';

function FormulaTextInput({
  id,
  index: itemIndex,
  text,
  funcs,
  error,
  isLast,
  formulaItems,
  autoFocus,
  onAdd,
  onChange,
}) {
  const inputWrapRef = useRef();
  const inputRef = useRef();
  const [active, setActive] = useState(false);
  const [activeBtn, setActiveBtn] = useState(0);
  const [toggleFunc, setToggleFunc] = useState(false);
  const btns = [
    { icon: 'coin', type: 'account' },
    { icon: 'func', type: 'function' },
  ];
  useOutsideClick({ ref: inputWrapRef, callback: () => setActive(false) });

  /**
   * Управление горячими клавишами по кнопкам
   */
  const selectBtns = (e) => {
    setToggleFunc(false);
    const btnLen = btns.length - 1;
    let next;
    switch (e.key) {
      case 'ArrowDown':
        if (activeBtn === -1) setActiveBtn(0);
        break;
      case 'ArrowRight':
        next = activeBtn + 1;
        if (activeBtn !== -1 && next <= btnLen) setActiveBtn(next);
        break;
      case 'ArrowLeft':
        next = activeBtn - 1;
        if (activeBtn !== -1 && next >= 0) setActiveBtn(next);
        break;
      case 'ArrowUp':
      case 'Backspace':
        setActiveBtn(-1);
        break;
      case 'Enter':
        const type = btns[activeBtn].type;
        if (type === 'account') {
          onAdd(type);
          setActive(false);
        } else if (type === 'function') {
          setToggleFunc(true);
        }
        break;
      default:
    }
  };

  /**
   * Управление шириной поля ввода
   */
  const resizeInput = () => {
    inputRef.current.style.width = `${text.length}ch`;
  };

  useEffect(() => {
    resizeInput();
  }, [text]);

  /**
   * Изменение поля ввода
   */
  const handleChange = (e) => {
    onChange({ value: e.target.value });
  };

  /**
   * Выбрать функцию
   */
  const selectFunc = (funcItem) => {
    onChange({ value: `${text}${funcItem.title}(` });
    inputRef.current.focus();
    setToggleFunc(false);
  };

  /**
   * Check if function separator is allowed at charIndex position
   * must be preceded by function and inside brackets
   */
  const checkAreFunctionSeparatorsAllowed = (charIndex) => {
    const prevTextItems = formulaItems
      .slice(0, itemIndex)
      .filter((i) => i.type === 'text');
    const hasFunction = prevTextItems.some((i) =>
      funcs.some((func) => i.value.indexOf(func.title) !== -1)
    );
    if (!hasFunction) return false;
    const prevFormulaTillIndex =
      prevTextItems.map((i) => i.value).join('') + text.slice(0, charIndex);
    const openingBracketsCount = (prevFormulaTillIndex.match(/\(/g) || [])
      .length;
    const closingBracketsCount = (prevFormulaTillIndex.match(/\)/g) || [])
      .length;
    return openingBracketsCount > closingBracketsCount;
  };

  /**
   * Check for allowed characters
   */
  const checkRestrict = (e) => {
    const re = new RegExp(FORMULA_SYMBOLS);
    const key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (
      !re.test(key) ||
      (FUNCTION_ARGUMENT_SEPARATORS.includes(key) &&
        !checkAreFunctionSeparatorsAllowed(e.target.selectionStart))
    ) {
      e.preventDefault();
      return false;
    }
  };

  const renderBtns = () => {
    if (!active) return null;
    const map = btns.map((i, index) => (
      <Icon
        key={i.icon}
        styleName={cn({ active: index === activeBtn })}
        type={i.icon}
        onClick={() => {
          setActive(false);
          if (i.type === 'account') {
            onAdd(i.type);
          } else if (i.type === 'function') {
            setToggleFunc(true);
          }
        }}
      />
    ));
    return <div styleName="formula__input__btns">{map}</div>;
  };

  return (
    <div ref={inputWrapRef} id={`textInput_${id}`} styleName="formula__input">
      <input
        ref={inputRef}
        type="text"
        value={text}
        styleName={cn('formula__input__input', { error })}
        onChange={handleChange}
        onKeyDown={selectBtns}
        onKeyPress={checkRestrict}
        autoFocus={autoFocus && isLast}
        onFocus={() => {
          setActive(true);
          setToggleFunc(false);
        }}
        onBlur={resizeInput}
      />
      {renderBtns()}
      {toggleFunc ? (
        <FuncSelect
          funcs={funcs}
          onSelect={selectFunc}
          handleClickOutside={() => setToggleFunc(false)}
        />
      ) : null}
    </div>
  );
}

FormulaTextInput.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  text: PropTypes.string,
  funcs: PropTypes.array,
  autoFocus: PropTypes.bool,
  isLast: PropTypes.bool,
  formulaItems: PropTypes.array,
  onAdd: PropTypes.func,
  onChange: PropTypes.func,
};

export default FormulaTextInput;
