import React from 'react';
import { Stack } from 'mw-style-react';
import scss from './LayerExamplePlaceholder.scss';

function LayerExamplePlaceholder() {
  return (
    <Stack alignItems="center" justifyContent="center" fullHeight>
      <svg
        className={scss.wrap}
        xmlns="http://www.w3.org/2000/svg"
        width="188"
        height="119"
        viewBox="0 0 188 119"
        fill="none"
      >
        <path
          // eslint-disable-next-line max-len
          d="M6 5.52148L28.7127 32.2424C29.422 33.0768 30.3603 33.6849 31.4116 33.9915L55 40.8715M55 40.8715L73.0025 29.6568C75.0102 28.4061 77.5872 28.5517 79.4412 30.0207L121.85 63.6215M55 40.8715L46.1976 63.3035C45.067 66.1845 46.4879 69.4363 49.3699 70.5641L74.3268 80.3298C75.6283 80.8391 77.0733 80.8433 78.3777 80.3416L121.85 63.6215M55 40.8715L81.336 54.2105C81.7438 54.417 82.1754 54.5729 82.6211 54.6745L121.85 63.6215M121.85 63.6215L142.05 38.1216M121.85 63.6215L142.05 86.6216M142.05 86.6216L110.55 105.622M142.05 86.6216L138.55 113.622M142.05 86.6216L165.55 109.622M142.05 86.6216L181.55 91.6216"
          stroke="#ACB3BE"
          strokeWidth="0.7"
        />
        <circle
          cx="5.65078"
          cy="6.22109"
          r="5.25"
          fill="#5173EE"
          stroke="white"
          strokeWidth="0.7"
        />
        <circle
          cx="76.35"
          cy="27.9203"
          r="5.25"
          fill="#406AFF"
          stroke="white"
          strokeWidth="0.7"
        />
        <circle
          cx="142.651"
          cy="37.2211"
          r="5.25"
          fill="#406AFF"
          stroke="white"
          strokeWidth="0.7"
        />
        <circle
          cx="43.4516"
          cy="68.5209"
          r="5.25"
          fill="#B640FF"
          stroke="white"
          strokeWidth="0.7"
        />
        <circle
          cx="81.9516"
          cy="54.5209"
          r="5.25"
          fill="#4ECA01"
          stroke="white"
          strokeWidth="0.7"
        />
        <circle
          cx="120.452"
          cy="62.9213"
          r="5.25"
          fill="#40DDFF"
          stroke="white"
          strokeWidth="0.7"
        />
        <circle
          cx="76.35"
          cy="81.1205"
          r="5.25"
          fill="#FF9C40"
          stroke="white"
          strokeWidth="0.7"
        />
        <circle
          cx="29.4516"
          cy="33.5209"
          r="5.25"
          fill="#8940FF"
          stroke="white"
          strokeWidth="0.7"
        />
        <circle
          cx="54.6508"
          cy="41.2211"
          r="5.25"
          fill="#FF4062"
          stroke="white"
          strokeWidth="0.7"
        />
        <circle
          cx="141.651"
          cy="86.2211"
          r="5.25"
          fill="#FF4062"
          stroke="white"
          strokeWidth="0.7"
        />
        <circle
          cx="110.651"
          cy="105.221"
          r="5.25"
          fill="#242223"
          stroke="white"
          strokeWidth="0.7"
        />
        <circle
          cx="137.651"
          cy="113.221"
          r="5.25"
          fill="#B640FF"
          stroke="white"
          strokeWidth="0.7"
        />
        <circle
          cx="165.651"
          cy="109.221"
          r="5.25"
          fill="#4ECA01"
          stroke="white"
          strokeWidth="0.7"
        />
        <circle
          cx="181.651"
          cy="91.2211"
          r="5.25"
          fill="#2B47A7"
          stroke="white"
          strokeWidth="0.7"
        />
      </svg>
    </Stack>
  );
}

export default LayerExamplePlaceholder;
