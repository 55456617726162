import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import useIntl from 'useIntl';
import {
  Icon,
  Label,
  ToggleSwitch,
  Stack,
  Space,
  useObjectState,
} from 'mw-style-react';

import Toggle from '@control-front-end/common/components/Toggle';
import SelectActors from '@control-front-end/common/components/SelectActors';

import FormsFieldsSelector from './FormsFieldsSelector';
import AccountsSelector from './AccountsSelector';
import { ViewDataPropType } from '../../propTypes';
import m from './intl';

function ToggleSetting({ icon, title, ...rest }) {
  return (
    <Stack horizontal size={Stack.SIZE.small}>
      <Icon type={icon} />
      <Label color={Label.COLOR.black} fontWeight="semibold" value={title} />
      <ToggleSwitch {...rest} />
    </Stack>
  );
}

/** Own fields of the target actor, available to show on the actor card */
const ACTOR_FIELDS = {
  description: 'description',
  picture: 'picture',
};

const viewDataToState = ({ actorFields, ...rest }) => ({
  showAvatar: Boolean(
    actorFields.find(({ itemId }) => itemId === ACTOR_FIELDS.picture)
  ),
  showDescription: Boolean(
    actorFields.find(({ itemId }) => itemId === ACTOR_FIELDS.description)
  ),
  /**
   * accounts:  WARNING: probably will be needed some changes on the frnotend or backend sides
   * for this field because now we don't receive accounts that was saved at the card-view but abscent in the actor
   */
  ...rest,
});

const stateToViewData = (
  { showAvatar, showDescription, ...rest },
  picture,
  description
) => {
  const actorFields = [];

  if (showAvatar) {
    actorFields.push({
      itemId: ACTOR_FIELDS.picture,
      value: picture,
      icon: '',
    });
  }

  if (showDescription) {
    actorFields.push({
      itemId: ACTOR_FIELDS.description,
      value: description,
      icon: '',
    });
  }

  return {
    ...rest,
    actorFields,
  };
};

/**
 */
function ActorCardViewSettings({
  layerFormId,
  actorForms,
  actor,
  viewData,
  onChange,
}) {
  const t = useIntl();

  const [state, setObjectState, setState] = useObjectState(
    () => viewDataToState(viewData),
    {
      transformValue: ({ value }) => value,
      onChange: (newState) =>
        onChange({
          viewData: stateToViewData(newState, actor.picture, actor.description),
        }),
    }
  );

  const handleLayerChange = useCallback(
    ({ value }) =>
      setState((prevState) => ({
        ...prevState,
        actors: {
          ...prevState.actors,
          layer: value,
        },
      })),
    [setState]
  );

  const handleLayerReset = useCallback(
    () => handleLayerChange({ value: null }),
    [handleLayerChange]
  );

  return (
    <Stack size={Stack.SIZE.xlarge}>
      {/* -------- ACTOR AVATAR -------- */}
      <ToggleSetting
        icon="camera"
        title={t(m.avatar)}
        value={state.showAvatar}
        onChange={setObjectState.showAvatar}
      />
      {/* -------- LAYER -------- */}
      <SelectActors
        bordered={true}
        multiselect={false}
        formId={layerFormId}
        fullModel
        unspaced
        wrap={false}
        size="medium"
        autoFocus={false}
        value={state.actors.layer}
        onChange={handleLayerChange}
        onReset={handleLayerReset}
        resettable
        placeholder={t(m.layer)}
      />
      {/* -------- ACCOUNTS -------- */}
      <Toggle
        theme="grey"
        type="simulator"
        isOpen
        onChange={() => {}}
        title={t(m.accounts)}
      >
        <Space top>
          <AccountsSelector
            accounts={state.accounts}
            onChange={setObjectState.accounts}
          />
        </Space>
      </Toggle>
      {/* -------- FORM FIELDS -------- */}
      <Toggle
        theme="grey"
        type="simulator"
        isOpen
        onChange={() => {}}
        title={t(m.formFields)}
      >
        <Space top>
          <FormsFieldsSelector
            formsFields={state.formsFields}
            onChange={setObjectState.formsFields}
            actorForms={actorForms}
          />
        </Space>
      </Toggle>
      {/* -------- DESCRIPTION -------- */}
      <ToggleSetting
        icon="info"
        title={t(m.description)}
        value={state.showDescription}
        onChange={setObjectState.showDescription}
      />
    </Stack>
  );
}

ActorCardViewSettings.propTypes = {
  viewData: PropTypes.shape(ViewDataPropType).isRequired,
  onChange: PropTypes.func.isRequired,
  layerFormId: PropTypes.number,
  actorForms: PropTypes.arrayOf(PropTypes.shape({})),
  actor: PropTypes.shape({
    picture: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default ActorCardViewSettings;
