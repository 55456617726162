import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import { Stack, Label, Space } from 'mw-style-react';
import 'rc-slider/assets/index.css';

/**
 *  Slider to change picture opacity
 * @returns {JSX.Element}
 * @constructor
 */
function OpacitySlider({ value, min, max, onChange, style, ...props }) {
  const [opacity, setOpacity] = useState(value === undefined ? 100 : value);

  return (
    <Stack
      style={{ display: 'flex', flex: '1 1 auto', ...(style || {}) }}
      size={Stack.SIZE.none}
      {...props}
    >
      <Space left right bottom size={Space.SIZE.xsmall}>
        <Stack
          horizontal
          justifyContent="spaceBetween"
          alignItems="center"
          fullWidth
          size={Stack.SIZE.xsmall}
        >
          <Label fontWeight="semibold" value="Opacity" />
          <Label
            fontWeight="semibold"
            color={Label.COLOR.gray}
            value={`${opacity}%`}
          />
        </Stack>
      </Space>
      <Space left right size={Space.SIZE.small}>
        <Slider
          min={min}
          max={max}
          value={opacity}
          step={10}
          dots={true}
          onChange={(newOpacity) => {
            setOpacity(newOpacity);
            onChange(newOpacity);
          }}
        />
      </Space>
    </Stack>
  );
}

OpacitySlider.propTypes = {
  opacity: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

OpacitySlider.defaultProps = {
  min: 0,
  max: 100,
};

export default OpacitySlider;
