import { STATIC_LAYER } from 'constants';
import skeletones from '../skeletones';

const getSkeletone = (name, data) => {
  const skeleton = skeletones[STATIC_LAYER[name]];

  if (!skeleton) return {};

  return typeof skeleton === 'function' ? skeleton(data) : skeleton;
};

export default getSkeletone;
