import React, { useState, useRef, useEffect } from 'react';
import useIntl from 'useIntl';
import { Icon, Label, Tab, TabItem } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import JSONEditorReact from '@control-front-end/common/components/JSONEditorReact';
import ParamItem from './components/ParamItem/ParamItem';
import mes from './intl';
import './ExtraParams.scss';

const jsonToParams = (jsonValue) => {
  return Object.keys(jsonValue).map((key) => {
    return { id: AppUtils.udid(), key, value: jsonValue[key] };
  });
};

const paramsToJson = (paramsValue) => {
  const newJson = {};
  paramsValue.forEach(({ key, value }) => {
    newJson[key] = value;
  });
  return newJson;
};

function ExtraParams({ data, onChange, scrollModalToBottom }) {
  const t = useIntl();
  const editorRef = useRef();
  const isChangedRef = useRef();
  const [params, setParams] = useState(jsonToParams(data));
  const [extraActiveTab, setExtraActiveTab] = useState('params');
  const [editJson, setEditJson] = useState(data);
  const [source, setSource] = useState('');

  const saveChanges = () => {
    if (!isChangedRef.current) return;
    let newJson;
    try {
      newJson = editorRef.current.get();
    } catch (e) {
      return;
    }
    onChange({ id: 'extra', value: newJson });
  };

  useEffect(() => {
    if (!isChangedRef.current) return;
    return () => {
      saveChanges();
    };
  }, [isChangedRef.current]);

  useEffect(() => {
    if (extraActiveTab === 'params') {
      const selectedSource = source ? JSON.parse(source) : editJson;
      setParams(jsonToParams(selectedSource));
    } else {
      setSource(JSON.stringify(paramsToJson(params)));
      setEditJson(paramsToJson(params));
    }
  }, [extraActiveTab]);

  const handleChangeJson = () => {
    let newJson;
    try {
      newJson = editorRef.current.get();
    } catch (e) {
      return;
    }
    isChangedRef.current = true;
    setSource(JSON.stringify(newJson));
    onChange({ id: 'extra', value: newJson });
  };

  const handleParamChange = ({ id, action, value }) => {
    const index = params.findIndex((i) => i.id === id);
    if (index === -1) return;
    const newParams = [...params];
    switch (action) {
      case 'set value':
        newParams[index].value = value;
        break;
      case 'set key':
        if (!value) return;
        newParams[index].key = value;
        break;
      case 'delete':
        newParams.splice(index, 1);
        break;
      default:
        return;
    }
    onChange({ id: 'extra', value: paramsToJson(newParams) });
    setParams(newParams);
  };

  const handleAddClick = () => {
    const newParams = [...params, { id: AppUtils.udid(), key: '', value: '' }];
    onChange({ id: 'extra', value: paramsToJson(newParams) });
    setParams(newParams);
    scrollModalToBottom();
  };

  const renderParamItems = () => {
    return params.map((param) => (
      <ParamItem key={param.id} param={param} onChange={handleParamChange} />
    ));
  };

  const renderKeyValue = () => {
    return (
      <div styleName="params__values">
        {renderParamItems()}
        <div
          id="addExtraParamBtn"
          styleName="params__add"
          onClick={handleAddClick}
        >
          <Icon color="#1973E8" type="add" />
          <Label textColor="#1973E8" value={t(mes.addParameter)} />
        </div>
      </div>
    );
  };

  const renderCodeEditor = () => {
    return (
      <div styleName="params__editor">
        <JSONEditorReact
          jsonEditorRef={editorRef}
          schema={{}}
          json={editJson}
          text=""
          mode="code"
          mainMenuBar={true}
          indentation={2}
          onChange={handleChangeJson}
          onValidationError={(error) => {
            onChange({ id: 'extraError', value: !!error.length });
          }}
          onError={() => {}}
          onEditable={() => true}
        />
      </div>
    );
  };

  return (
    <div styleName="params">
      <Tab
        value={extraActiveTab}
        type="auto"
        onChange={({ value }) => {
          setExtraActiveTab(value);
          scrollModalToBottom();
        }}
        underline={true}
      >
        <TabItem label={t(mes.keyValue)} value="params" />
        <TabItem label={t(mes.codeEditor)} value="editor" />
      </Tab>
      {extraActiveTab === 'params' ? renderKeyValue() : renderCodeEditor()}
    </div>
  );
}

export default ExtraParams;
