import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import safeReqExp from 'safe-regex';
import cn from 'classnames';
import { TextField } from 'mw-style-react';
import './Edit.scss';

/**
 * Компонент Edit
 */
class Edit extends PureComponent {
  render() {
    const {
      id,
      title,
      value,
      visibility,
      type,
      extra,
      regexp,
      error,
      autoFocus,
      onChange,
      getErrorMsg,
    } = this.props;
    const reqExp = safeReqExp(regexp) ? regexp : undefined;
    return (
      <TextField
        id={id}
        bordered={true}
        styleName={cn('edit')}
        type={type}
        label={title}
        visibility={visibility}
        value={value || ''}
        error={error}
        regexp={reqExp}
        autoFocus={autoFocus}
        autoSelect={autoFocus}
        length={extra.length}
        multiline={extra.multiline}
        multilineType="text"
        rows={extra.rows}
        helperText={getErrorMsg(this.props)}
        onChange={onChange}
      />
    );
  }
}

Edit.defaultProps = {
  extra: {},
};

Edit.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.bool,
  regexp: PropTypes.string,
  autoFocus: PropTypes.bool,
  visibility: PropTypes.string,
  extra: PropTypes.object,
  onChange: PropTypes.func,
  getErrorMsg: PropTypes.func,
};

export default Edit;
