import { call, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import api from '@control-front-end/common/sagas/api';
import {
  GET_OBJECT_HISTORY,
  FILTER_HISTORY,
  RequestStatus,
} from '@control-front-end/common/constants';
import AppUtils from '@control-front-end/utils/utils';
import {
  FILTER_TRANSACTIONS,
  TRANSACTION_TYPES,
} from '@control-front-end/common/constants/actorAccounts';
import { makeActorPicture } from './graph/graphHelpers';

function* makeHistoryModel(models) {
  const config = yield select((state) => state.config);
  const clonedModels = structuredClone(models);
  for (const item of clonedModels) {
    item.user.avatar = AppUtils.makeUserAvatar(item.user, config);
  }
  return clonedModels;
}

/**
 * Get object history
 */
function* getObjectHistory({ payload, callback }) {
  const { objId, objType, field, from, to, limit, offset } = payload;
  const { data, result } = yield call(api, {
    method: 'get',
    url: `/history/${objType}/${objId}`,
    queryParams: { field, from, to, limit, offset },
  });
  if (result !== RequestStatus.SUCCESS) return;
  const list = yield makeHistoryModel(data.data);
  if (callback) callback(list);
}

/**
 * Get history by filter
 */
function* getFilteredHistory({ payload, callback }) {
  const {
    total,
    formField,
    from,
    to,
    limit = 15,
    offset = 0,
    actors,
    userId,
    isFormFields = false,
    loadMore = false,
  } = payload;
  const { active: accId } = yield select((state) => state.accounts);
  const { data, result } = yield call(api, {
    method: 'post',
    url: `/history/filter/${accId}`,
    queryParams: { total },
    body: {
      objIds: actors,
      field: formField?.fieldId,
      formId: formField?.formId,
      userId,
      from,
      to,
      limit,
      offset,
    },
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (isFormFields) {
    const newPayload = {};
    if (total) {
      newPayload.total = data.data.total;
    } else {
      for (const obj of data.data) {
        obj.actorPictureUrl = yield makeActorPicture({
          picture: obj.actorPicture,
          systemObjSAId: obj.systemObjSAId,
        });
        obj.accountName = obj.formTitle;
        obj.currencyName = obj.fieldTitle;
        obj.type = TRANSACTION_TYPES.COMPLETED;
      }
      newPayload.list = data.data ? yield makeHistoryModel(data.data) : [];
      newPayload.filter = { ...payload };
      newPayload.endList = data.data.length < limit;
    }
    yield put({
      type: FILTER_TRANSACTIONS.SUCCESS,
      payload: newPayload,
    });
  }
  if (total) {
    if (callback) callback();
    return;
  }
  const list = data.data ? yield makeHistoryModel(data.data) : [];
  if (callback) {
    callback({
      list,
      offset: loadMore ? offset + limit : offset,
      limit,
      endList: data.data.length < limit,
    });
  }
}

function* history() {
  yield takeLatest(GET_OBJECT_HISTORY.REQUEST, getObjectHistory);
  yield takeEvery(FILTER_HISTORY.REQUEST, getFilteredHistory);
}

export default history;
