import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalButtons,
  ModalContent,
  ProgressBar,
} from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import { MANAGE_ACCESS_RULES, NOTIFY_LEVEL, SHOW_NOTIFY } from 'constants';
import useIntl from 'useIntl';
import AccessRules from '@control-front-end/common/components/AccessRules';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import m from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import l from './ManageAccessRules.scss'; // NOSONAR

/**
 * Modal window of managing access to an object
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function ManageAccessRules(props) {
  const {
    data: { rules, objId, objType, ownerId },
    callback,
    visibility,
    onClose,
    isSubmit,
  } = props;
  const t = useIntl();
  const dispatch = useDispatch();
  const [access, setAccess] = useState(rules);
  const [noSubmit, setNoSubmit] = useState(false);

  /**
   * Notification about successful save
   */
  const notifySuccess = () => {
    dispatch({
      type: SHOW_NOTIFY.REQUEST,
      payload: {
        id: AppUtils.createRid(),
        type: NOTIFY_LEVEL.SUCCESS,
        label: t(mes.accessSaved),
      },
    });
    onClose();
  };

  /**
   * Saving all changes in access
   */
  const handleSubmit = () => {
    if (objId) {
      dispatch({
        type: MANAGE_ACCESS_RULES.REQUEST,
        payload: { body: access, objId, objType },
        callback: (newAccess, newInvites) => {
          notifySuccess();
          if (callback) {
            callback(newAccess, newInvites);
          }
        },
      });
    } else {
      // exclude users with all false privs
      const filteredAccess = access
        ? access.filter(
            (user) =>
              user.privs && Object.values(user.privs).some((i) => i === true)
          )
        : [];
      callback(filteredAccess);
    }
    onClose();
  };

  const changedAccess = access.filter((i) => !!i.action);
  const noChanges = !changedAccess.length;
  return (
    <Modal
      styleName="m.modal l.modal"
      size={objType === 'actor' ? 'xlarge' : 'large'}
      onClose={onClose}
      label={t(mes.manageAccess)}
      visibility={visibility}
    >
      <ModalContent styleName="m.modal__content l.content">
        <AccessRules
          objId={objId}
          objType={objType}
          rules={access}
          ownerId={ownerId}
          allPrivs={objType === 'actor'}
          onChange={setAccess}
          onSelect={(onSelect) => setNoSubmit(onSelect)}
        />
      </ModalContent>
      <ModalButtons styleName="m.modal__buttons">
        <Button
          label={noChanges ? t(mes.done) : t(mes.saveChanges)}
          size="large"
          onClick={handleSubmit}
          visibility={noSubmit ? 'disabled' : 'visible'}
        />
        <ProgressBar
          styleName="m.modal__loader"
          type="circle"
          size="small"
          visibility={isSubmit ? 'visible' : 'hidden'}
        />
      </ModalButtons>
    </Modal>
  );
}

ManageAccessRules.defaultProps = {
  data: { rules: [] },
};

ManageAccessRules.propTypes = {
  data: PropTypes.object,
  visibility: PropTypes.bool,
  onClose: PropTypes.func,
  isSubmit: PropTypes.bool,
  callback: PropTypes.func,
};

export default ManageAccessRules;
