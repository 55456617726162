import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  noPairCurrencies: {
    id: 'noPairCurrencies',
    defaultMessage: 'No available currencies',
  },
});

export default { ...globalIntl, ...m };
