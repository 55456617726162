import { createReducer } from '@reduxjs/toolkit';
import { SET_ACTORS_CACHE, CLEAR_ACTORS_CACHE } from '../constants/graphActors';

// ------------------------------------
// Reducer
// ------------------------------------
const initState = {};

export default createReducer(initState, {
  [SET_ACTORS_CACHE](state, action) {
    const { id, data } = action.payload;
    const reqTime = new Date().getTime();
    return { ...state, [id]: { ...data, reqTime } };
  },
  [CLEAR_ACTORS_CACHE]() {
    return initState;
  },
});
