import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  createFilter: {
    id: 'createFilter_CreateActorsFilter',
    defaultMessage: 'Create actor (Filter)',
  },
  updateFilter: {
    id: 'updateFilter_CreateActorsFilter',
    defaultMessage: 'Update actor (Filter)',
  },
  saveAndSearch: {
    id: 'saveAndSearch_CreateActorsFilter',
    defaultMessage: 'Save and search',
  },
  advancedSettings: {
    id: 'advancedSettings_CreateActorsFilter',
    defaultMessage: 'Advanced search settings',
  },
  actorStatusToggleLabel: {
    id: 'actorStatusToggleLabel_CreateActorsFilter',
    defaultMessage: 'Verification status',
  },
  labelStatus_verified: {
    id: 'labelStatusType_verified_CreateActorsFilter',
    defaultMessage: 'Verificated',
  },
  labelStatus_pending: {
    id: 'labelStatus_pending_CreateActorsFilter',
    defaultMessage: 'Pending verification',
  },
  labelStatus_rejected: {
    id: 'labelStatus_rejected_CreateActorsFilter',
    defaultMessage: 'Rejected',
  },
  labelStatus_null: {
    id: 'labelStatus_null_CreateActorsFilter',
    defaultMessage: 'Not verificated',
  },
  actorDateRange: {
    id: 'actorDateRange_CreateActorsFilter',
    defaultMessage: 'Create date range',
  },
  all: {
    id: 'all_CreateActorsFilter',
    defaultMessage: 'All',
  },
  actorForms: {
    id: 'actorForms_CreateActorsFilter',
    defaultMessage: 'Actor forms',
  },
  wrongRootForm: {
    id: 'wrongRootForm_CreateActorsFilter',
    defaultMessage: 'You have to select forms from one UAT',
  },
  mainTextActorFilter: {
    id: 'mainTextActorFilter_CreateActorsFilter',
    defaultMessage: 'Selected UAT has been deleted',
  },
  extraTextActorFilter: {
    id: 'extraTextActorFilter_CreateActorsFilter',
    defaultMessage: 'Nested forms and fields are no longer available to edit.',
  },
  ascOrder: {
    id: 'ascOrder_CreateActorsFilter',
    defaultMessage: 'In ascending order of account balance',
  },
  descOrder: {
    id: 'descOrder_CreateActorsFilter',
    defaultMessage: 'In descending order of account balance',
  },
  total: {
    id: 'total_CreateActorsFilter',
    defaultMessage: 'Total',
  },
  credit: {
    id: 'credit_CreateActorsFilter',
    defaultMessage: 'Credit',
  },
  debit: {
    id: 'debit_CreateActorsFilter',
    defaultMessage: 'Debit',
  },
  type: {
    id: 'type_CreateActorsFilter',
    defaultMessage: 'Type',
  },
  account: {
    id: 'account_CreateActorsFilter',
    defaultMessage: 'Account',
  },
  balanceFrom: {
    id: 'balanceFrom_CreateActorsFilter',
    defaultMessage: 'Balance from',
  },
  balanceTo: {
    id: 'balanceTo_CreateActorsFilter',
    defaultMessage: 'Balance to',
  },
  optional: {
    id: 'optional_CreateActorsFilter',
    defaultMessage: '(optional)',
  },
});

export default { ...globalIntl, ...m };
