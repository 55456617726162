import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Modal, ModalContent, ProgressBar } from 'mw-style-react';
import { DEL_MODAL, SHOW_NOTIFY } from 'constants';
// eslint-disable-next-line no-unused-vars
import sModal from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import sLocal from './ActorModalView.scss'; // NOSONAR

function ActorModalView({ data, onClose, visibility, dispatch }) {
  const accId = useSelector((state) => state.accounts.active);
  const [loading, setLoader] = useState(true);
  const [actorId, _setActorId] = useState(data.actorId);
  const actorIdRef = useRef(actorId);

  /**
   * Setting actorId via ref for eventListener and setState for other cases
   */
  const setActorId = (newActorId) => {
    actorIdRef.current = newActorId;
    _setActorId(newActorId);
  };

  /**
   * Handling iframe messages
   */
  const handleIframeMessage = ({ data: message }) => {
    const { type, data: mData } = message;
    switch (type) {
      case 'SHOW_ACTOR_FRAME':
        setLoader(false);
        break;
      case 'REMOVE_ACTOR_IFRAME':
        dispatch({ type: DEL_MODAL });
        break;
      case 'UPDATE_ACTOR_IFRAME':
        if (mData.actorId === actorIdRef.current) return;
        setLoader(true);
        setActorId(mData.actorId);
        break;
      case 'ACCESS_DENIED_FRAME':
        const iframeEl = document.getElementById(
          `actorIframe_${mData.actorId}`
        );
        iframeEl.src = '/access_denied';
        setLoader(false);
        break;
      case 'SHOW_NOTIFY_TOP_WINDOW':
        dispatch({
          type: SHOW_NOTIFY.REQUEST,
          payload: mData?.payload,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleIframeMessage);
    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  const renderIframe = () => (
    <iframe
      key={`${actorId}`}
      id={`actorIframe_${actorId}`}
      styleName="sLocal.content__iframe"
      title={actorId}
      src={`/actor/${accId}/view/${actorId}?iFrame=true${
        data.activeChat ? '&activeChat=true' : ''
      }`}
    />
  );

  return (
    <Modal
      styleName="sModal.modal sLocal.modal"
      size="xlarge"
      visibility={visibility}
      onClose={onClose}
    >
      <ModalContent styleName="sModal.modal__content sLocal.content">
        <div
          style={loading ? {} : { display: 'none' }}
          styleName="sLocal.content__loader"
        >
          <ProgressBar type="circle" size="large" />
        </div>
        {renderIframe()}
      </ModalContent>
    </Modal>
  );
}

ActorModalView.propTypes = {
  visibility: PropTypes.bool.isRequired,
  data: PropTypes.object,
  onClose: PropTypes.func,
};

export default ActorModalView;
