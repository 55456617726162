import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  added_access: {
    id: 'added_access',
    defaultMessage: 'Added users:',
  },
  removed_access: {
    id: 'removed_access',
    defaultMessage: 'Removed access:',
  },
  changed_access: {
    id: 'changedAccess',
    defaultMessage: 'Changed access:',
  },
  curAccess: {
    id: 'curAccess',
    defaultMessage: 'Access rules:',
  },
});

export default { ...globalIntl, ...m };
