import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Tooltip, Utils } from 'mw-style-react';
import './ActorStatusIcon.scss';

/**
 * Иконка статуса верификации актора
 */
function ActorStatusIcon({ status, size = 'medium' }) {
  if (!status) return null;

  const renderStatusIcon = () => {
    let icon;
    let color;
    switch (status) {
      case 'verified':
        icon = 'check_box';
        color = '#22C933';
        break;
      case 'rejected':
        icon = 'declined';
        color = '#DB4437';
        break;
      case 'pending':
        icon = 'clock';
        color = '#F8AB03';
        break;
      default:
        break;
    }
    if (!icon) return null;
    return (
      <Icon
        className="statusIcon"
        styleName="asi"
        type={icon}
        size={size}
        color={color}
      />
    );
  };

  return (
    <Tooltip value={Utils.toUpperLatter(status)}>{renderStatusIcon()}</Tooltip>
  );
}

ActorStatusIcon.propTypes = {
  status: PropTypes.oneOf(['verified', 'rejected', 'pending', null]),
  size: PropTypes.oneOf(['micro', 'small', 'medium', 'large', 'xlarge']),
};

export default ActorStatusIcon;
