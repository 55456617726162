import React from 'react';
import YouTube from 'react-youtube';

const ATTACHMENTS_BY_URL_TYPES = {
  youtube: ({ text, ...props }) => {
    const match = text.match(
      // eslint-disable-next-line max-len
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|watch\?(?:.*&)?v=|v\/)|youtu\.be\/|youtube-nocookie\.com\/(?:embed\/|v\/|watch\?(?:.*&)?v\/))([\w-]{11})/i
    );
    const videoId = match && match[1];

    if (videoId) {
      return (
        <div className="youtube">
          <YouTube videoId={videoId} {...props} />
        </div>
      );
    }
  },
};

/**
 * Generates a list of URL attachments based on the given text and URL types.
 *
 * @param {string} text - The text used to generate URL attachments.
 * @param {object} urlTypes - An object containing different URL types.
 * @return {array} An array of URL attachments.
 */
export default function getUrlAttachments(text, urlTypes) {
  if (!text || !Object.keys(urlTypes).length) {
    return null;
  }
  return Object.keys(urlTypes)
    .map((urlTypeKey) => {
      const Attachment = ATTACHMENTS_BY_URL_TYPES[urlTypeKey];
      return (
        <Attachment
          key={urlTypeKey}
          text={text}
          {...(typeof urlTypes[urlTypeKey] === 'object'
            ? urlTypes[urlTypeKey]
            : {})}
        />
      );
    })
    .filter(Boolean);
}
