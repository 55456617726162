import React from 'react';

import scss from '../../EventsCalendar.scss';

function EventDote({ eventId, actorBgColor }) {
  return (
    <div
      key={`event-dote-${eventId}`}
      className={scss.eventDote}
      style={{
        backgroundColor: actorBgColor,
      }}
    />
  );
}

export default EventDote;
