import { ACTORS_COLORS_PALETTE } from '@control-front-end/common/constants/graphActors';
import formGraph from './formGraph';

const getActorFormsNodeStructure = ({ title, color, forms }) => {
  return {
    root_actor: {
      title,
      color: color || ACTORS_COLORS_PALETTE.Grass,
      isNonInteractive: true,
      nodes: forms.reduce(
        (acc, form) => ({ ...acc, ...formGraph({ ...form, form }) }),
        {}
      ),
    },
  };
};

export default getActorFormsNodeStructure;
