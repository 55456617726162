import { put, call, takeLatest, delay } from 'redux-saga/effects';
import { DateUtils } from 'mw-style-react';
import Client from 'mw-fetch';
import { CHECK_VERSION, SHOW_VERSION_MODAL, SET_MODAL } from 'constants';

const INTERVAL = 60000;

const httpClient = new Client();

/**
 * Показать модалку с уведомлением о том что вышла новая версия
 * @returns {IterableIterator<*>}
 */
function* showModal({ payload: tag }) {
  yield put({
    type: SET_MODAL,
    payload: {
      name: 'InfoModal',
      data: {
        id: 'newVersion',
        title: 'newVersion',
        text: { text: 'newVersionInfo', values: { tag } },
        buttons: [
          {
            type: 'default',
            label: 'Load new version',
            onClick: () => {
              document.location.reload(true);
            },
          },
        ],
      },
    },
  });
}

/**
 * Проверка времени жизни сессии
 * @returns {IterableIterator<*>}
 */
function* getVersion() {
  try {
    const { data } = yield call(
      [httpClient, 'get'],
      `/version.json?t=${DateUtils.unixtime()}`
    );
    const curTag = data.tag;
    if (curTag && curTag !== __TAG__) {
      yield put({ type: SHOW_VERSION_MODAL, payload: curTag });
    } else {
      yield delay(INTERVAL);
      yield put({ type: CHECK_VERSION });
    }
  } catch (e) {
    yield delay(INTERVAL);
    yield put({ type: CHECK_VERSION });
  }
}

function* checkVersion() {
  yield takeLatest(CHECK_VERSION, getVersion);
  yield takeLatest(SHOW_VERSION_MODAL, showModal);
}

export default checkVersion;
