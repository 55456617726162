import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { useOutsideClick, useIntl } from 'hooks';
import { Label, Avatar, Icon, Badge } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import { WS_CLEAR_NOTIFY, WS_NEW_NOTIFY } from 'constants';
import { SET_ACTIVE_ACTOR } from '@control-front-end/common/constants/graphActors';
import history from '@control-front-end/app/src/store/history';
import mes from './intl';
import './Notifications.scss';

function Notifications({ collapsed }) {
  const t = useIntl();
  const dispatch = useDispatch();
  const wsNotify = useSelector((state) => state.wsNotify);
  const { plainMode } = useSelector((state) => state.settings);
  const { active: accId } = useSelector((state) => state.accounts);
  const [notify, setNotify] = useState(false);
  const popupRef = useRef();
  const notifyRef = useRef();

  const handleClose = () => {
    setNotify(false);
  };

  useOutsideClick({
    ref: popupRef,
    callback: (e) => {
      if (notifyRef.current?.contains(e.target)) return;
      handleClose();
    },
  });

  const markAsRead = () => {
    dispatch({ type: WS_CLEAR_NOTIFY });
    handleClose();
  };

  const getTimeLabels = () => [
    t(mes.justNow),
    t(mes.secondsAgo),
    t(mes.minuteAgo),
    t(mes.minutesAgo),
    t(mes.hourAgo),
    t(mes.hoursAgo),
    t(mes.yesterday1),
  ];

  const renderEmpty = () => {
    return (
      <div styleName="notify__empty">
        <div styleName="notify__empty__head">{t(mes.noNotify)}</div>
        <div>{t(mes.noNotifyInfo)}</div>
      </div>
    );
  };

  const renderList = () => {
    const eventViewLink = AppUtils.makeUrl(
      `/actors_graph/${AppUtils.makeShortAccountId(accId)}/view`
    );

    return wsNotify.map((i) => (
      <div
        key={i.id}
        styleName="notify__item"
        onClick={() => {
          const isEventsRoute =
            history.location.pathname.indexOf('/events/') !== -1;

          const newArr = wsNotify.filter((i) => i.actorId !== i.eventId);
          dispatch({ type: WS_NEW_NOTIFY, payload: newArr });
          handleClose();
          if (isEventsRoute && plainMode && i.rid) {
            dispatch({
              type: SET_ACTIVE_ACTOR,
              payload: { id: i.actorId, rid: i.rid },
            });
          } else {
            history.push(i.url);
          }
        }}
      >
        <div styleName="notify__item__container">
          <div styleName="notify__item__avatar">
            <Avatar src={i.ownerAvatar} />
          </div>
          <div styleName="notify__item__content">
            <div styleName="notify__item__content__row">
              <span>{i.ownerName}</span>&nbsp;
              {t(
                mes[
                  `notify_${
                    i.type === 'comment' && i.appId ? 'script' : i.type
                  }`
                ]
              )}
              &nbsp;
              <a href={`${eventViewLink}/${i.actorId}`}>{i.title}</a>
            </div>
            <div
              styleName="notify__item__content__row"
              dangerouslySetInnerHTML={{
                __html: AppUtils.makeDescription({
                  str: i.description,
                  accId,
                  chip: true,
                  noNewLines: true,
                }),
              }}
            />
            <div styleName="notify__item__content__row time">
              {AppUtils.relativeDate(i.createdAt, getTimeLabels())}
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const renderNotifications = () => (
    <div ref={popupRef} styleName="notify__popup">
      <div styleName="notify__headers">
        <div styleName="notify__btn" onClick={handleClose}>
          {t(mes.cancel)}
        </div>
        <div styleName="notify__title">
          <Label
            value={t(mes.notifications)}
            fontSize="large"
            fontWeight="semibold"
          />
        </div>
        <div
          styleName={cn('notify__btn', { hidden: !wsNotify.length })}
          onClick={markAsRead}
        >
          {t(mes.markAsRead)}
        </div>
      </div>
      <div styleName="notify__list">
        {wsNotify.length ? renderList() : renderEmpty()}
      </div>
    </div>
  );

  return (
    <div ref={notifyRef} styleName={cn('notify', { collapsed })}>
      <Icon
        styleName="notify__icon"
        type="notification"
        onClick={() => setNotify((prevNotify) => !prevNotify)}
      />
      {wsNotify.length ? <Badge styleName="notify__badge" /> : null}
      <div styleName="notify__items">
        {notify ? renderNotifications() : null}
      </div>
    </div>
  );
}

Notifications.propTypes = {
  collapsed: PropTypes.bool,
};

export default Notifications;
