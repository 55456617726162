import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  addLayers: {
    id: 'addLayers',
    defaultMessage: 'Add layer',
  },
  selectLayer: {
    id: 'selectLayer',
    defaultMessage: 'Select layer',
  },
});

export default { ...globalIntl, ...m };
