import { ACTORS_COLORS_PALETTE } from '@control-front-end/common/constants/graphActors';
import getTranslation from '@control-front-end/utils/getTranslation';
import mes from './accessGraphIntl';

const TRANSLATIONS = {
  view: mes.accessView,
  remove: mes.accessRemove,
  sign: mes.accessSign,
  execute: mes.accessExecute,
  modify: mes.accessModify,
};

const getAccessNodeStructure = ({
  name,
  access,
  id,
  defaultAccess,
  ...rest
}) => {
  return {
    root_actor: {
      title: name,
      isNonInteractive: true,
      ...rest,
      nodes: [...access, ...defaultAccess].reduce(
        (acc, { name, avatar, privs, ...rest }, index) => {
          acc[`node_user_${index}`] = {
            title: name,
            pictureUrl: avatar,
            isNonInteractive: true,
            nodes: Object.entries(privs).reduce((acc, [key, value]) => {
              if (value) {
                acc[`node_user_${index}_${key}`] = {
                  title: getTranslation(TRANSLATIONS[key]),
                  color: ACTORS_COLORS_PALETTE.Simulator,
                  isNonInteractive: true,
                };
              }
              return acc;
            }, {}),
            ...rest,
          };
          return acc;
        },
        {}
      ),
    },
  };
};

export default getAccessNodeStructure;
