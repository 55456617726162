import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  saveAsFilter: {
    id: 'saveAsFilter',
    defaultMessage: 'Save as filter',
  },
  filterName: {
    id: 'filterName',
    defaultMessage: 'Filter name',
  },
  enterUniqueName: {
    id: 'enterUniqueName',
    defaultMessage: '(Enter unique name for your filter)',
  },
  filterNamePlaceholder: {
    id: 'filterNamePlaceholder',
    defaultMessage: 'Example: System users',
  },
});

export default { ...globalIntl, ...m };
