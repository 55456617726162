import PropTypes from 'prop-types';
import React from 'react';
import {
  Modal,
  ModalButtons,
  ModalContent,
  Button,
  ProgressBar,
  withForm,
  TextField,
  Select,
  MenuItem,
} from 'mw-style-react';
import { NOTIFY_LEVEL, SHOW_NOTIFY } from 'constants';
import AppUtils from '@control-front-end/utils/utils';
import {
  CREATE_ACCOUNT_CURRENCIES,
  CURRENCY_TYPES,
  UPDATE_ACCOUNT_CURRENCY,
} from '@control-front-end/common/constants/actorAccounts';
import Yup from 'yup';
import useIntl from 'useIntl';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import sModal from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import sLocal from './CreateCurrency.scss'; // NOSONAR

function CreateCurrencyBase(props) {
  const {
    data,
    values,
    errors,
    visibility,
    onClose,
    handleSubmit,
    handleOnChange,
    isSubmit,
  } = props;
  const t = useIntl();

  return (
    <Modal
      styleName="sModal.modal"
      visibility={visibility}
      onClose={onClose}
      label={data.id ? t(mes.editCurrency) : t(mes.createNewCurrency)}
      size="large"
    >
      <ModalContent styleName="sModal.modal__content sLocal.content">
        <TextField
          id="name"
          styleName="sModal.textfield"
          size="large"
          autoFocus={true}
          bordered={true}
          label={t(mes.name)}
          value={values.name || ''}
          error={!!errors.name}
          helperText={t(mes[errors.name], { minLength: values.minLength })}
          onChange={handleOnChange}
        />
        <TextField
          id="symbol"
          styleName="sModal.textfield"
          size="large"
          label={t(mes.currencySymbol)}
          bordered={true}
          value={values.symbol || ''}
          onChange={handleOnChange}
        />
        <Select
          id="type"
          styleName="sModal.select"
          size="large"
          label={t(mes.type)}
          bordered={true}
          value={values.type}
          onChange={handleOnChange}
          popoverOnTop
        >
          {CURRENCY_TYPES.map((type) => (
            <MenuItem
              key={type}
              value={type}
              label={t(mes[`currencyType_${type}`])}
            />
          ))}
        </Select>
        <Select
          id="precision"
          styleName="sModal.select"
          size="large"
          label={t(mes.precision)}
          bordered={true}
          value={values.precision.toString()}
          onChange={handleOnChange}
          popoverOnTop
        >
          {[...new Array(5)].map((i, index) => (
            <MenuItem
              key={index}
              value={index.toString()}
              label={Number.parseFloat('1').toFixed(index)}
            />
          ))}
        </Select>
      </ModalContent>
      <ModalButtons styleName="sModal.modal__buttons">
        <Button
          label={data.btnText || t(mes.save)}
          size="large"
          onClick={handleSubmit}
          visibility={isSubmit ? 'disabled' : 'visible'}
        />
        <ProgressBar
          styleName="sModal.modal__loader"
          type="circle"
          size="small"
          visibility={isSubmit ? 'visible' : 'hidden'}
        />
      </ModalButtons>
    </Modal>
  );
}

const CreateCurrency = withForm(
  {
    mapPropsToValues(props) {
      const formData = props.data || {};
      return {
        name: formData.name || '',
        type: formData.type || 'number',
        symbol: formData.symbol || null,
        precision: formData.precision ?? 2,
      };
    },
    yup: Yup.object().shape({
      name: Yup.string().min(1).required('field_required'),
    }),
    handleSubmit: (values, dispatch, formActions, props) => {
      const { id } = props.data;
      const { name, type, symbol } = values;
      const precision = parseInt(values.precision, 10);
      dispatch({
        type: id
          ? UPDATE_ACCOUNT_CURRENCY.REQUEST
          : CREATE_ACCOUNT_CURRENCIES.REQUEST,
        payload: { id, name, type, symbol, precision },
        callback: (currency) => {
          if (!currency) {
            formActions.setSubmitting(false);
            props.onClose();
            return;
          }
          if (props.callback) {
            props.callback(currency);
          }
          props.onClose();
          dispatch({
            type: SHOW_NOTIFY.REQUEST,
            payload: {
              id: AppUtils.createRid(),
              type: NOTIFY_LEVEL.SUCCESS,
              label: id ? 'Currency was updated' : 'Currency was created',
            },
          });
        },
      });
    },
  },
  CreateCurrencyBase
);

CreateCurrencyBase.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleOnChange: PropTypes.func,
  dispatch: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  isSubmit: PropTypes.bool,
  values: PropTypes.object,
  errors: PropTypes.object,
  data: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
};

export default CreateCurrency;
