import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { RequestStatus } from 'constants';
import {
  BULK_CREATE_ACCOUNTS,
  BULK_MANAGE_ACCOUNT_TAGS,
  CHECK_ACCOUNT_PAIR,
  CREATE_ACCOUNT_CURRENCIES,
  CREATE_ACCOUNT_NAME,
  CREATE_ACTORS_ACCOUNT,
  GET_ACCOUNT,
  GET_ACCOUNT_DETAILS,
  GET_ACCOUNT_FORMULA_INFO,
  GET_ACCOUNT_ACTORS,
  GET_ACTORS_ACCOUNTS,
  GET_ALL_ACCOUNTS_TAGS,
  GET_ALL_PAIR_ACTORS_ACCOUNTS,
  LOCK_ACTORS_ACCOUNT,
  REMOVE_ACTORS_ACCOUNT,
  SAVE_ACCOUNT_PAIR,
  SAVE_ACCOUNT_ACTORS,
  SET_ACCOUNT_FORMULA,
  SET_ACTORS_ACCOUNTS_REQ_STATUS,
  SET_FORMULAS,
  TRANSFER_ACCOUNTS,
  UPDATE_ACCOUNT_CURRENCY,
  UPDATE_ACTORS_ACCOUNT,
  GET_ACTORS_ACCOUNTS_GROUPS,
  SET_ACTORS_ACCOUNTS_GROUPS_REQ_STATUS,
  UNTAGGED_ACCOUNT_GROUP,
  PERMANENT_ACCOUNTS_GROUPS,
  RECALCULATE_TREE_ACCOUNT_BALANCE,
  INHERIT_ACCOUNTS,
} from '@control-front-end/common/constants/actorAccounts';
import api from '@control-front-end/common/sagas/api';
import AppUtils from '@control-front-end/utils/utils';
import { extendModelWithAvatars } from '@control-front-end/app/src/sagas/graph/graphHelpers';
import apiBulk from './apiBulk';
import { GET_ACTORS_BALANCE } from '../constants/graphActors';
import { WS_ACTOR_BALANCE_CHANGED } from '../constants/graphRealtime';

/**
 * Update account
 */
export function* updateAccount({ accountType, key, incomeType, data }) {
  const actorsAccs = yield select((state) => state.actorsAccounts[accountType]);
  const copyAccounts = structuredClone(actorsAccs);
  const accIndex = copyAccounts.findIndex((i) => i.key === key);
  const accModel = copyAccounts[accIndex];
  if (incomeType === 'all') {
    Object.assign(accModel, data.main);
    Object.assign(accModel.debit, data.debit);
    Object.assign(accModel.credit, data.credit);
  } else Object.assign(accModel[incomeType], data);
  copyAccounts.splice(accIndex, 1, accModel);
  return copyAccounts;
}

/**
 * Create virtual account id no pair exists
 */
function addFakeAccount(groupAccounts) {
  Object.keys(groupAccounts).forEach((key) => {
    const accs = groupAccounts[key];
    if (accs.length === 1) {
      const copyAcc = { ...accs[0] };
      copyAcc.amount = 0;
      copyAcc.availableAmount = 0;
      copyAcc.holdAmount = 0;
      copyAcc.incomeType = accs[0].incomeType === 'debit' ? 'credit' : 'debit';
      accs.push(copyAcc);
    }
  });
}

/**
 * Create account model
 */
export function createAccountsModels(accounts) {
  accounts.forEach((acc) => {
    acc.key = `${acc.actorId}+${acc.nameId}+${acc.currencyId}`;
  });
  const groupAccounts = AppUtils.groupBy(accounts, 'key');
  addFakeAccount(groupAccounts);
  return Object.keys(groupAccounts).map((key) => {
    const accs = groupAccounts[key];
    const {
      currencyId,
      currencyName,
      accountName,
      nameId,
      status,
      isSystem,
      isOperationAccount,
      counterType,
      triggers,
      actor,
      user,
      privs,
    } = accs[0];
    const nAcc = {
      key,
      currencyId: currencyId.toString(),
      currencyName,
      accountName,
      nameId,
      status,
      isSystem,
      isOperationAccount,
      counterType,
      triggers,
      actor,
      user,
      privs,
    };
    accs.forEach((i) => {
      i.availableAmount = AppUtils.isUndefined(i.availableAmount)
        ? i.amount
        : i.availableAmount;
      i.holdAmount = i.holdAmount || 0;
      i.value = i.value || [];
      nAcc[i.incomeType] = i;
      if (i.connector) {
        const { picture, formPicture } = i.connector;
        let pictureUrl = picture
          ? AppUtils.makeAppUrl(`/download/${picture}`)
          : null;
        if (!pictureUrl && formPicture && formPicture.includes('static'))
          pictureUrl = formPicture;
        i.connector.pictureUrl = pictureUrl;
      }
    });
    return nAcc;
  });
}

/**
 * Add account to active group
 */
function* addAccountToList({
  actorId,
  accountType,
  actor,
  accounts,
  tags,
  triggers,
}) {
  const auth = yield select((state) => state.auth);
  const actorView = yield select((state) => state.actorView) || {};
  const accountsGroupsState = yield select(
    (state) => state.actorsAccountsGroups
  );
  const { list: groups } = accountsGroupsState;
  let activeGroup = accountsGroupsState.activeGroup;
  const isActorsAccountsRoot =
    actorView.id === actorId && activeGroup === undefined;
  if (isActorsAccountsRoot) {
    const copyGroups = groups.slice();
    // if it's first actor's account - make groups from tags
    if (!groups.length) {
      const newGroups = tags?.length
        ? tags.map((a) => ({ actorId: a.actorId, title: a.title }))
        : [UNTAGGED_ACCOUNT_GROUP];
      copyGroups.push(...newGroups);
      activeGroup = newGroups[0].actorId || newGroups[0].id;
    } else {
      // otherwise - add first it's tag and make it active to get accounts list
      const newGroup = tags.length
        ? {
            actorId: tags[0].actorId,
            title: tags[0].title,
          }
        : UNTAGGED_ACCOUNT_GROUP;
      copyGroups.push(newGroup);
      activeGroup = newGroup.actorId || newGroup.id;
    }
    yield put({
      type: GET_ACTORS_ACCOUNTS_GROUPS.SUCCESS,
      payload: { list: copyGroups, activeGroup },
    });
    return;
  }
  const matchedTag = tags.find((tag) => tag.id === activeGroup);
  // if we use tags grouping and there's active tag, add account only if it matched it
  if (groups.length && tags.length && !matchedTag) return;
  const accountsState = yield select((state) => state.actorsAccounts);
  const { total } = accountsState;
  const copyAccounts = accountsState[accountType]
    ? accountsState[accountType].slice()
    : [];
  const newOwnAccounts = accounts.filter((acc) => acc.actorId === actorId);
  newOwnAccounts.forEach((acc) => {
    if (actor) acc.actor = actor;
    if (triggers) acc.triggers = triggers;
    acc.user = auth;
  });
  const list = yield call(createAccountsModels, newOwnAccounts, 'key');
  copyAccounts.unshift(...list);
  yield put({
    type: CREATE_ACTORS_ACCOUNT.SUCCESS,
    payload: { [accountType]: copyAccounts, total: total ? total + 2 : 2 },
  });
}

/**
 * Remove group if no accounts in it
 */
function* removeEmptyGroup() {
  const actorView = yield select((state) => state.actorView) || {};
  const { activeGroup, list, total } = yield select(
    (state) => state.actorsAccountsGroups
  );
  if (!actorView?.id || activeGroup === undefined) return;
  const newList = list.filter((gr) => gr.actorId !== activeGroup);
  yield put({
    type: GET_ACTORS_ACCOUNTS_GROUPS.SUCCESS,
    payload: {
      list: newList,
      activeGroup: undefined,
      total: total - 1,
    },
  });
}

/**
 * Get list of actor's accounts
 */
function* getActorsAccountsGroups({ payload, callback }) {
  const {
    actorId,
    loadMore,
    limit: customLimit,
    offset: customOffset,
    total,
  } = payload;
  const {
    reqStatus,
    list,
    limit: defaultLimit,
    offset: defaultOffset,
    endList,
  } = yield select((state) => state.actorsAccountsGroups);
  if (reqStatus !== RequestStatus.SUCCESS || (loadMore && endList)) return;
  const limit = customLimit || defaultLimit;
  const offset = !AppUtils.isUndefined(customOffset)
    ? customOffset
    : defaultOffset;
  // Set groups request status to progress
  if (!total)
    yield put({
      type: SET_ACTORS_ACCOUNTS_GROUPS_REQ_STATUS,
      payload: 'inProgress',
    });
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/accounts/groups/${actorId}`,
    queryParams: { limit, offset, total },
  });
  // Set groups request status to success
  if (!total)
    yield put({
      type: SET_ACTORS_ACCOUNTS_GROUPS_REQ_STATUS,
      payload: RequestStatus.SUCCESS,
    });
  if (result !== RequestStatus.SUCCESS) return;
  const newPayload = {};
  if (total) {
    newPayload.total = data.data.total;
  } else {
    const newList = loadMore
      ? list.concat(data.data)
      : PERMANENT_ACCOUNTS_GROUPS.concat(data.data);
    newPayload.list = newList.map((i) =>
      i.title === UNTAGGED_ACCOUNT_GROUP.id ? UNTAGGED_ACCOUNT_GROUP : i
    );
    newPayload.limit = limit;
    newPayload.offset = offset + limit;
    newPayload.endList = !newList.length;
  }
  yield put({
    type: GET_ACTORS_ACCOUNTS_GROUPS.SUCCESS,
    payload: newPayload,
  });
  if (callback) callback(newPayload);
}

/**
 * Get list of actor's accounts
 */
function* getActorsAccounts({ payload, callback, errorCallback }) {
  const {
    actorId,
    tag,
    ungrouped,
    accountType = 'fact',
    from,
    to,
    limit,
    offset,
    query,
    localState,
    withPrivs,
    withTriggers,
    total,
  } = payload;
  // Set groups request status to progress
  if (!total && !localState)
    yield put({
      type: SET_ACTORS_ACCOUNTS_REQ_STATUS,
      payload: { [accountType]: RequestStatus.PROGRESS },
    });
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/accounts/${actorId}`,
    queryParams: {
      accountType,
      from,
      to,
      tag,
      ungrouped,
      query,
      limit,
      offset,
      total,
      withPrivs,
      withTriggers,
    },
    handleErrCodes: [403],
  });
  // Set groups request status to success
  if (!total && !localState) {
    yield put({
      type: SET_ACTORS_ACCOUNTS_REQ_STATUS,
      payload: { [accountType]: RequestStatus.SUCCESS },
    });
  }
  if (errorCallback && data.statusCode === 403) errorCallback(data);
  if (result !== RequestStatus.SUCCESS) return;
  const newPayload = { filter: { from, to, query } };
  if (total) {
    newPayload.total = data.data.total;
  } else {
    const list = yield call(createAccountsModels, data.data);
    newPayload[accountType] = [...list];
    if (callback) callback(list);
  }
  if (!localState) {
    yield put({
      type: GET_ACTORS_ACCOUNTS.SUCCESS,
      payload: newPayload,
    });
  }
}

/**
 * Get single account by ID
 */
function* getAccount({ payload, callback }) {
  const { id, withPrivs } = payload;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/accounts/single/${id}`,
    queryParams: { withPrivs },
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback(data.data);
  return data.data;
}

/**
 * WS actor's account balance changed
 */
function* actorAccountBalanceChanged({ payload }) {
  const { changed } = payload;
  const { fact, plan, settings } = yield select(
    (state) => state.actorsAccounts
  );
  if (!fact.length && !plan.length) return;
  const { actorId } = settings;
  const matchedActor = changed.find((i) => i.actorId === actorId);
  if (!matchedActor || !fact.length) return;
  const { accountId, nameId, currencyId } = matchedActor;
  const accIndex = fact.findIndex(
    (acc) => acc.nameId === nameId && +acc.currencyId === +currencyId
  );
  if (accIndex === -1) return;
  const actualAccountData = yield call(getAccount, {
    payload: { id: accountId },
  });
  const copyAccounts = fact.slice();
  const accCopy = structuredClone(copyAccounts[accIndex]);
  if (accCopy.debit.id === accountId) {
    accCopy.debit = { ...accCopy.debit, ...actualAccountData };
  } else {
    accCopy.credit = { ...accCopy.credit, ...actualAccountData };
  }
  copyAccounts.splice(accIndex, 1, accCopy);
  yield put({
    type: UPDATE_ACTORS_ACCOUNT.SUCCESS,
    payload: { fact: copyAccounts },
  });
}

/**
 * Создать счет
 * @param payload
 * @param callback
 * @param errorCallback
 */
function* createActorsAccounts({ payload, callback, errorCallback }) {
  const {
    actorId,
    actorData,
    ignoreIfExist,
    treeCalculation,
    minLimit,
    maxLimit,
    search,
    currencyId,
    nameId,
    accountType = 'fact',
    tags = [],
    triggers = [],
  } = payload;
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/accounts/${actorId}`,
    queryParams: { ignoreIfExist },
    body: {
      accountType,
      currencyId,
      nameId,
      treeCalculation,
      minLimit: minLimit !== null ? minLimit : undefined,
      maxLimit: maxLimit !== null ? maxLimit : undefined,
      search,
    },
    handleErrCodes: [403],
  });
  if (data.statusCode === 403 && errorCallback) errorCallback();
  if (result !== RequestStatus.SUCCESS) return;
  yield addAccountToList({
    actorId,
    accountType,
    actor: actorData,
    accounts: data.data,
    tags,
    triggers,
  });
  if (callback) callback(data.data);
}

/**
 * Bulk create accounts
 * @param payload
 * @param callback
 */
function* bulkCreateAccounts({ payload, callback }) {
  const {
    actors,
    accountType = 'fact',
    currencyId,
    nameId,
    treeCalculation,
    minLimit,
    maxLimit,
    search,
    returnCreated,
  } = payload;
  const responses = yield call(
    apiBulk,
    {
      method: 'post',
      url: '/accounts',
      queryParams: { returnCreated },
      body: {
        actors,
        nameId,
        currencyId,
        accountType,
        treeCalculation,
        minLimit: minLimit !== null ? minLimit : undefined,
        maxLimit: maxLimit !== null ? maxLimit : undefined,
        search,
      },
    },
    'actors'
  );
  const accounts = responses.reduce((acc, i) => acc.concat(i.data.data), []);
  if (callback) callback(accounts);
}

/**
 * Delete account
 */
function* removeActorsAccounts({ payload, callback }) {
  const { actorId, nameId, currencyId, key, accountType = 'fact' } = payload;
  const { result, data } = yield call(api, {
    method: 'delete',
    url: `/accounts/${actorId}/${currencyId}/${nameId}/${accountType}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  const accountsState = yield select((state) => state.actorsAccounts);
  const { total } = accountsState;
  const actorsAccs = accountsState[accountType];
  const copyAccounts = actorsAccs.filter((i) => i.key !== key);
  const newTotal = total ? total - 2 : 0;
  yield put({
    type: REMOVE_ACTORS_ACCOUNT.SUCCESS,
    payload: { [accountType]: copyAccounts, total: newTotal },
  });
  if (!newTotal) yield removeEmptyGroup();
  if (callback) callback(data.data);
}

/**
 * Update accounts
 */
function* updateActorsAccounts({ payload, callback }) {
  const {
    actorId,
    nameId,
    currencyId,
    key,
    accountType = 'fact',
    treeCalculation,
    minLimit,
    maxLimit,
    triggers,
    search,
  } = payload;

  const body = {
    treeCalculation,
    minLimit: minLimit !== null && minLimit !== '' ? minLimit : undefined,
    maxLimit: maxLimit !== null && maxLimit !== '' ? maxLimit : undefined,
    search,
  };
  const { result, data } = yield call(api, {
    method: 'put',
    url: `/accounts/${actorId}/${currencyId}/${nameId}/${accountType}`,
    body,
  });
  if (result !== RequestStatus.SUCCESS) return;
  const accs = yield select((state) => state.actorsAccounts[accountType]);
  const actorsAccs = accs.slice();
  const accIndex = actorsAccs.findIndex((i) => i.key === key);
  const accCopy = structuredClone(actorsAccs[accIndex]);
  accCopy.triggers = triggers;
  accCopy.debit = { ...accCopy.debit, ...body };
  accCopy.credit = { ...accCopy.credit, ...body };
  actorsAccs.splice(accIndex, 1, accCopy);
  yield put({
    type: UPDATE_ACTORS_ACCOUNT.SUCCESS,
    payload: { [accountType]: actorsAccs },
  });
  if (callback) callback(data.data);
}

/**
 * Block/unblock account
 */
function* lockActorsAccounts({ payload, callback }) {
  const { actorId, nameId, currencyId, accountType, status, key } = payload;
  const body = {
    nameId,
    currencyId,
    type: accountType,
    status,
  };
  const { result, data } = yield call(api, {
    method: 'put',
    url: `/accounts/block/${actorId}`,
    body,
  });
  if (result !== RequestStatus.SUCCESS) return;
  const setStatus = {
    main: { status },
    debit: { status },
    credit: { status },
  };
  const actorsAccs = yield updateAccount({
    accountType,
    key,
    incomeType: 'all',
    data: setStatus,
  });
  yield put({
    type: LOCK_ACTORS_ACCOUNT.SUCCESS,
    payload: { [accountType]: actorsAccs },
  });
  if (callback) callback(data.data);
}

/**
 * Get account details
 */
function* getAccountDetails({ payload, callback }) {
  const { actorId, currencyId, nameId } = payload;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/accounts/children/${actorId}`,
    queryParams: { currencyId, nameId },
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({ type: GET_ACCOUNT_DETAILS.SUCCESS, payload: data.data });
  if (callback) callback(data.data);
}

/**
 * Recalculate account balance
 */
function* recalculateBalance({ payload, callback }) {
  const { accountId } = payload;
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/accounts/recalculate_balance/${accountId}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({ type: RECALCULATE_TREE_ACCOUNT_BALANCE.SUCCESS });
  if (callback) callback(data.data);
}

/**
 * Set formula request
 */
function* setAccountFormula({ payload, callback }) {
  const { id, key, incomeType, accountType, formula } = payload;
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/accounts/formula/${id}`,
    body: { formula },
    handleErrCodes: [400],
  });
  if (result === RequestStatus.SUCCESS) {
    const copyAccounts = yield updateAccount({
      accountType,
      key,
      incomeType,
      data: {
        error: false,
        formula,
        amount: data.data.amount,
        availableAmount: data.data.amount,
      },
    });
    yield put({
      type: SET_ACCOUNT_FORMULA.SUCCESS,
      payload: { [accountType]: copyAccounts },
    });
    if (callback) callback(data.data);
    const layers = yield select((state) => state.graphLayers);
    const accParams = key.split('+');
    if (!layers || accParams.length !== 3) return;
    const actorId = accParams[0];
    const nameId = accParams[1];
    const currencyId = accParams[2];
    yield put({
      type: GET_ACTORS_BALANCE.REQUEST,
      payload: {
        layerId: layers.active,
        nameId,
        currencyId,
        actorIds: [actorId],
      },
    });
  }
  return { result, data };
}

/**
 * Set debit/credit account formulas
 */
function* setCreditDebitFormulas({ payload, formActions, callback }) {
  const { credit, debit, accountType, key } = payload;
  const [debitRes, creditRes] = yield all([
    call(setAccountFormula, {
      payload: { ...debit, key, incomeType: 'debit', accountType },
    }),
    call(setAccountFormula, {
      payload: { ...credit, key, incomeType: 'credit', accountType },
    }),
  ]);
  formActions.setSubmitting(false);
  if (
    [debitRes.result, creditRes.result].every(
      (i) => i === RequestStatus.SUCCESS
    )
  ) {
    callback();
  } else {
    const { data: dData } = debitRes;
    const { data: cData } = creditRes;
    if (dData.statusCode === 400)
      formActions.setErrors({ debitFormula: dData.message });
    if (cData.statusCode === 400)
      formActions.setErrors({ creditFormula: cData.message });
  }
}

/**
 * Get account formula info
 */

function* getAccountFormulaInfo({ payload: id, callback }) {
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/accounts/formula_info/${id}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({ type: GET_ACCOUNT_FORMULA_INFO.SUCCESS, payload: data.data });
  if (callback) callback(data.data);
}

/**
 * Transfer accounts between actors
 */
function* transferAccounts({ payload, callback }) {
  const { targetActorId, actorId, nameId, currencyId, key, replace } = payload;
  if (replace) {
    const types = ['fact', 'plan'];
    const calls = types.map((type) =>
      call(api, {
        method: 'delete',
        url: `/accounts/${targetActorId}/${currencyId}/${nameId}/${type}`,
      })
    );
    yield all(calls);
  }
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/accounts/transfer/${actorId}/${currencyId}/${nameId}`,
    body: { actorId: targetActorId },
  });
  if (result !== RequestStatus.SUCCESS) return;
  const actorsAccsFact = yield select((state) => state.actorsAccounts);
  const copyAccountsFact = actorsAccsFact.fact.filter((i) => i.key !== key);
  const copyAccountsPlan = actorsAccsFact.plan.filter((i) => i.key !== key);
  yield put({
    type: TRANSFER_ACCOUNTS.SUCCESS,
    payload: { fact: copyAccountsFact, plan: copyAccountsPlan },
  });
  const graphLayers = yield select((state) => state.graphLayers);
  if (graphLayers.active) {
    yield put({
      type: GET_ACTORS_BALANCE.REQUEST,
      payload: {
        layerId: graphLayers.active,
        nameId,
        currencyId,
        actorIds: [targetActorId, actorId],
      },
    });
  }
  if (callback) callback(data.data);
}

function* getAccountPrivs(access = []) {
  const auth = yield select((state) => state.auth);
  if (access && access.length) {
    const findUser = access.find((u) => u.userId === auth.id);
    if (findUser) return findUser.privs;
  }
  return {
    view: true,
    modify: true,
    remove: true,
    sign: false,
    execute: false,
  };
}

/**
 * Update accNames & currencies lists
 */
function* manageAccountsList({ accountName, currency, access }) {
  const auth = yield select((state) => state.auth);
  const accountNames = yield select((state) => state.accountNames);
  const currencies = yield select((state) => state.currencies);
  if (!accountNames.list.find((item) => item.id === accountName.id)) {
    const newAccName = { ...accountName, count: 0 };
    if (newAccName.userId === auth.id) newAccName.user = auth;
    yield put({
      type: CREATE_ACCOUNT_NAME.SUCCESS,
      payload: {
        list: [newAccName, ...accountNames.list],
        offset: accountNames.offset + 1,
        total: accountNames.total + 1,
      },
    });
  }
  if (!currencies.list.find((item) => item.id === currency.id)) {
    const privs = yield call(getAccountPrivs, access);
    const newCurrency = {
      ...currency,
      count: 0,
      total: 0,
      privs,
    };
    if (newCurrency.userId === auth.id) newCurrency.user = auth;
    yield put({
      type: CREATE_ACCOUNT_CURRENCIES.SUCCESS,
      payload: {
        list: [newCurrency, ...currencies.list],
        offset: currencies.offset + 1,
        total: currencies.total + 1,
      },
    });
  }
}

/**
 * Check accounts pair existence
 */
function* checkAccountsPair({ payload, callback }) {
  const accounts = yield select((state) => state.accounts);
  const accId = payload.accId || accounts.active;
  const { accountName, currencyName } = payload;
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/accounts/check_pair/${accId}`,
    body: { accountName, currencyName },
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback(data.data);
}

/**
 * Save accounts pair and share it
 */
function* saveAccountsPair({ payload, callback, errorCallback }) {
  const accounts = yield select((state) => state.accounts);
  const accId = payload.accId || accounts.active;
  const { accountName, currencyName, symbol, precision, type } = payload;
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/accounts/pair/${accId}`,
    body: { accountName, currencyName, symbol, precision, type },
    handleErrCodes: [403],
  });
  if (errorCallback && data.statusCode === 403) errorCallback();
  if (result !== RequestStatus.SUCCESS) return;
  yield call(manageAccountsList, data.data);
  if (callback) callback(data.data);
}

/**
 * Update tags/triggers in accounts pairs list
 */
function* updateAccountActors(payload) {
  const { currencyId, actors, formId } = payload;
  const systemForms = yield select((state) => state.systemForms);
  const currencies = yield select((state) => state.currencies.list);
  const list = structuredClone(currencies);
  const findIndex = list.findIndex((i) => i.id === currencyId);
  if (findIndex === -1) return;
  const key = formId === systemForms.tags.id ? 'tags' : 'triggers';
  const currency = { ...list[findIndex], [key]: actors };
  list.splice(findIndex, 1, currency);
  yield put({
    type: UPDATE_ACCOUNT_CURRENCY.SUCCESS,
    payload: { list },
  });
}

/**
 * Save account pair actors
 */
function* saveAccountActors({ payload, callback }) {
  const { nameId, currencyId, actors, formId } = payload;
  const ids = actors.map((i) => i.actorId);
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/accounts/actors/${nameId}/${currencyId}`,
    queryParams: { formId },
    body: { actors: ids },
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback(data.data);
  yield call(updateAccountActors, payload);
}

/**
 * Bulk manage accounts tags
 */
function* bulkManageAccountTags({ payload, callback }) {
  const { active: accId } = yield select((state) => state.accounts);
  const { accounts, actors } = payload;
  const ids = actors.map((i) => i.actorId);
  const { result } = yield call(api, {
    method: 'post',
    url: `/accounts/bulk_actors/${accId}`,
    body: { accounts, actors: ids },
  });
  if (result !== RequestStatus.SUCCESS) return;
  const currencies = yield select((state) => state.currencies.list);
  const list = structuredClone(currencies);
  accounts.forEach((pairId) => {
    const currencyId = pairId.split('_')[1];
    const findCurrency = list.find((i) => +i.id === +currencyId);
    if (findCurrency) findCurrency.tags = actors;
  });
  yield put({
    type: UPDATE_ACCOUNT_CURRENCY.SUCCESS,
    payload: { list },
  });
  if (callback) callback();
}

/**
 * Get account pair actors (triggers or|and tags)
 */
function* getAccountActors({ payload, callback }) {
  const { nameId, currencyId, formId } = payload;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/accounts/actors/${nameId}/${currencyId}`,
    queryParams: { formId },
    handleErrCodes: [403],
  });
  if (result !== RequestStatus.SUCCESS) return [];
  for (const item of data.data) {
    if (item.accessDenied) {
      item.actor = { id: item.id, accessDenied: true };
      continue;
    }
    yield call(extendModelWithAvatars, item.actor);
  }
  if (callback) callback(data.data);
  return { [`${nameId}_${currencyId}`]: data.data };
}

function* getAllPairsTags({ payload, callback }) {
  const reqs = payload.accounts.map(({ nameId, currencyId }) =>
    call(getAccountActors, { payload: { nameId, currencyId } })
  );
  const resp = yield all(reqs);
  const tagsObj = {};
  resp.forEach((item) => {
    const accKey = Object.keys(item)[0];
    tagsObj[accKey] = item[accKey];
  });
  if (callback) callback(tagsObj);
}

/**
 * Get all accounts for pair
 */
function* getAllPairActorsAccounts({ payload, callback }) {
  const {
    nameId,
    currencyId,
    accountType = 'fact',
    from,
    to,
    q,
    loadMore,
    limit: customLimit,
    offset: customOffset,
    withPrivs,
    total,
  } = payload;
  const actorsAccountsState = yield select((state) => state.actorsAccounts);
  const {
    limit: defaultLimit = 20,
    offset: defaultOffset = 0,
    endList,
    reqStatus,
  } = actorsAccountsState;
  const accountsList = actorsAccountsState[accountType];
  if (reqStatus !== RequestStatus.SUCCESS || (loadMore && endList)) return;
  const limit = customLimit || defaultLimit;
  const offset = !AppUtils.isUndefined(customOffset)
    ? customOffset
    : defaultOffset;
  // Ставим флаг прогресса загрузки
  if (!total)
    yield put({
      type: SET_ACTORS_ACCOUNTS_REQ_STATUS,
      payload: { [accountType]: RequestStatus.PROGRESS },
    });
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/accounts/all/${nameId}/${currencyId}`,
    queryParams: { accountType, from, to, q, limit, offset, withPrivs, total },
  });
  // Ставим флаг окончания загрузки
  if (!total)
    yield put({
      type: SET_ACTORS_ACCOUNTS_REQ_STATUS,
      payload: { [accountType]: RequestStatus.SUCCESS },
    });
  if (result !== RequestStatus.SUCCESS) return;
  const newPayload = {};
  if (total) {
    newPayload.total = data.data.total;
  } else {
    const list = yield call(createAccountsModels, data.data, 'actorId');
    newPayload[accountType] = loadMore ? accountsList.concat(list) : list;
    newPayload.offset = offset + limit;
    newPayload.endList = !data.data.length;
    if (callback) callback(list);
  }
  yield put({
    type: GET_ACTORS_ACCOUNTS.SUCCESS,
    payload: newPayload,
  });
}

/**
 * Inherit actors accounts and forms
 */
function* inheritAccounts({ payload }) {
  const { action, parents, children, handleErrCodes } = payload;
  const accounts = yield select((state) => state.accounts);
  const accId = payload.accId || accounts.active;
  const { result } = yield call(api, {
    method: 'post',
    url: `/accounts/inherit/${accId}`,
    body: { action, parents, children },
    handleErrCodes,
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({ type: INHERIT_ACCOUNTS.SUCCESS, payload });
}

function* actorsAccounts() {
  yield takeEvery(GET_ACTORS_ACCOUNTS.REQUEST, getActorsAccounts);
  yield takeEvery(GET_ACTORS_ACCOUNTS_GROUPS.REQUEST, getActorsAccountsGroups);
  yield takeEvery(BULK_CREATE_ACCOUNTS.REQUEST, bulkCreateAccounts);
  yield takeEvery(CREATE_ACTORS_ACCOUNT.REQUEST, createActorsAccounts);
  yield takeEvery(REMOVE_ACTORS_ACCOUNT.REQUEST, removeActorsAccounts);
  yield takeEvery(LOCK_ACTORS_ACCOUNT.REQUEST, lockActorsAccounts);
  yield takeEvery(UPDATE_ACTORS_ACCOUNT.REQUEST, updateActorsAccounts);
  yield takeEvery(SET_ACCOUNT_FORMULA.REQUEST, setAccountFormula);
  yield takeEvery(SET_FORMULAS.REQUEST, setCreditDebitFormulas);
  yield takeEvery(GET_ACCOUNT_FORMULA_INFO.REQUEST, getAccountFormulaInfo);
  yield takeEvery(GET_ACCOUNT_DETAILS.REQUEST, getAccountDetails);
  yield takeEvery(GET_ACCOUNT.REQUEST, getAccount);
  yield takeEvery(TRANSFER_ACCOUNTS.REQUEST, transferAccounts);
  yield takeEvery(SAVE_ACCOUNT_PAIR.REQUEST, saveAccountsPair);
  yield takeEvery(SAVE_ACCOUNT_ACTORS.REQUEST, saveAccountActors);
  yield takeEvery(BULK_MANAGE_ACCOUNT_TAGS.REQUEST, bulkManageAccountTags);
  yield takeEvery(GET_ACCOUNT_ACTORS.REQUEST, getAccountActors);
  yield takeEvery(GET_ALL_ACCOUNTS_TAGS.REQUEST, getAllPairsTags);
  yield takeEvery(
    GET_ALL_PAIR_ACTORS_ACCOUNTS.REQUEST,
    getAllPairActorsAccounts
  );
  yield takeEvery(CHECK_ACCOUNT_PAIR.REQUEST, checkAccountsPair);
  yield takeEvery(RECALCULATE_TREE_ACCOUNT_BALANCE.REQUEST, recalculateBalance);
  yield takeEvery(WS_ACTOR_BALANCE_CHANGED, actorAccountBalanceChanged);
  yield takeEvery(INHERIT_ACCOUNTS.REQUEST, inheritAccounts);
}

export default actorsAccounts;
