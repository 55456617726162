import { defineMessages } from 'react-intl';

const m = defineMessages({
  title: {
    id: 'title_CompanyCardCreationWarning',
    defaultMessage: 'Warning',
  },
  text1: {
    id: 'text1_CompanyCardCreationWarning',
    defaultMessage: "You haven't published your company yet.",
  },
  text2: {
    id: 'text2_CompanyCardCreationWarning',
    defaultMessage: 'Create your company actor and start communication.',
  },
  alternativeText1: {
    id: 'alternativeText1_CompanyCardCreationWarning',
    defaultMessage: 'Your company is not published yet.',
  },
  alternativeText2: {
    id: 'alternativeText2_CompanyCardCreationWarning',
    defaultMessage:
      'Contact your company administrator to publish company card so you can start making connections.',
  },
  confirmationText: {
    id: 'confirmationText_CompanyCardCreationWarning',
    defaultMessage: 'Go to company card creation',
  },
});

export default m;
