import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalButtons,
  ModalContent,
  ProgressBar,
  withForm,
} from 'mw-style-react';
import { BULK_MANAGE_ACCOUNT_TAGS } from '@control-front-end/common/constants/actorAccounts';
import SelectActors from '@control-front-end/common/components/SelectActors';
import { useModal, useNotifications } from 'hooks';
import useIntl from 'useIntl';
import mes from './intl';
import '../../Modal.scss';

function BulkManageTagsBase(props) {
  const {
    data,
    values,
    errors,
    handleOnChange,
    visibility,
    onClose,
    isSubmit,
  } = props;
  const t = useIntl();
  const dispatch = useDispatch();
  const { showNotification } = useNotifications();
  const systemForms = useSelector((state) => state.systemForms) || {};
  const systemFormsTags = systemForms.tags || {};
  const tags = values.tags || [];
  const { open: openSaveConfirmModal, close: closeSaveConfirmModal } = useModal(
    'InfoModal',
    {
      title: 'manageTags',
      text: 'saveTagsConfirm',
    }
  );

  const bulkSaveTags = () => {
    const actors = tags.map((item) => ({ ...item, actorId: item.id }));
    dispatch({
      type: BULK_MANAGE_ACCOUNT_TAGS.REQUEST,
      payload: {
        accounts: data.accounts.map((i) => i.objId),
        actors,
      },
      callback: () => {
        closeSaveConfirmModal();
        showNotification('success', t(mes.tagsSaved));
        onClose();
      },
    });
  };

  const handleSubmit = () => {
    openSaveConfirmModal({
      buttons: [
        {
          label: t(mes.save),
          onClick: bulkSaveTags,
        },
      ],
    });
  };

  return (
    <Modal
      styleName="modal"
      onClose={onClose}
      label={t(mes.manageTags)}
      visibility={visibility}
    >
      <ModalContent styleName="modal__content">
        <SelectActors
          id="tags"
          bordered={true}
          type="modal"
          size="large"
          multiselect={true}
          placeholder={t(mes.selectTags)}
          error={!!errors.tags}
          helperText={t(mes[errors.tags])}
          label={t(mes.tags)}
          formId={systemFormsTags.id}
          value={tags}
          exclude={tags}
          onChange={({ value }) => {
            handleOnChange({ id: 'tags', value });
          }}
        />
      </ModalContent>
      <ModalButtons styleName="modal__buttons">
        <Button
          label={t(mes.save)}
          size="large"
          onClick={handleSubmit}
          visibility={isSubmit || !tags.length ? 'disabled' : 'visible'}
        />
        <ProgressBar
          styleName="modal__loader"
          type="circle"
          size="small"
          visibility={isSubmit ? 'visible' : 'hidden'}
        />
      </ModalButtons>
    </Modal>
  );
}

BulkManageTagsBase.propTypes = {
  visibility: PropTypes.bool,
  onClose: PropTypes.func,
  handleOnChange: PropTypes.func,
  isSubmit: PropTypes.bool,
  values: PropTypes.object,
  data: PropTypes.object,
};

const BulkManageTags = withForm(
  {
    mapPropsToValues(props) {
      const formData = props.data || {};
      const { tags } = formData;
      return {
        tags: tags || [],
      };
    },
  },
  BulkManageTagsBase
);

export default BulkManageTags;
