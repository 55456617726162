import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames/bind';
import './Image.scss';

/**
 * Компонент Image
 */
class Image extends PureComponent {
  render() {
    const { id, visibility, align, title, value, extra } = this.props;
    const style = { maxWidth: extra.width, maxHeight: extra.height };
    return (
      <div
        id={id}
        styleName={cn('image', {
          hidden: visibility === 'hidden',
          disabled: visibility === 'disabled',
          left: align === 'left',
          center: align === 'center',
          right: align === 'right',
        })}
      >
        <img alt={title} src={value} style={style} />
      </div>
    );
  }
}

Image.defaultProps = {
  extra: {},
};

Image.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  value: PropTypes.string,
  align: PropTypes.string,
  visibility: PropTypes.string,
  extra: PropTypes.object,
};

export default Image;
