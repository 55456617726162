import { Utils } from 'mw-style-react';
import { ACCOUNT_TYPE } from './actorAccounts';

export const GET_TRANSACTIONS_FILTER = Utils.createRequestTypes(
  'GET_TRANSACTIONS_FILTER'
);
export const PARSE_TRANSACTIONS_FILTER = Utils.createRequestTypes(
  'PARSE_TRANSACTIONS_FILTER'
);
export const GET_TRANSACTIONS_FILTERS = Utils.createRequestTypes(
  'GET_TRANSACTIONS_FILTERS'
);
export const ADD_TRANSACTIONS_FILTER = Utils.createRequestTypes(
  'ADD_TRANSACTIONS_FILTER'
);
export const HIDE_TRANSACTIONS_FILTER = Utils.createRequestTypes(
  'HIDE_TRANSACTIONS_FILTER'
);
export const UPDATE_TRANSACTIONS_FILTER = Utils.createRequestTypes(
  'UPDATE_TRANSACTIONS_FILTER'
);
export const TRANSACTIONS_FILTER_ACCESS = Utils.createRequestTypes(
  'TRANSACTIONS_FILTER_ACCESS'
);

export const CLEAR_TRANSACTIONS_FILTERS = 'CLEAR_TRANSACTIONS_FILTERS';
export const SET_ACTIVE_TRANSACTIONS_FILTER = 'SET_ACTIVE_TRANSACTIONS_FILTER';

export const SYSTEM_TRANSACTIONS_FILTERS = [
  {
    id: ACCOUNT_TYPE.number,
    title: 'Numeric',
    isSystem: true,
    disabled: true,
    data: {},
  },
  {
    id: ACCOUNT_TYPE.formField,
    title: 'Form Fields',
    isSystem: true,
    disabled: true,
    data: {},
  },
];

export const TRANSACTION_ACTION_BY_TYPE = {
  [ACCOUNT_TYPE.number]: 'transactions',
  [ACCOUNT_TYPE.formField]: 'formFieldTransactions',
};

export const REQUIRED_FIELD_TRANSACTION_FILTERS = ['from', 'to', 'formFields'];
export const REQUIRED_TRANSACTION_FILTERS = ['from', 'to', 'accounts'];
export const TRANSACTIONS_FILTER_RANGE = 'transactionsFilterRange';
export const MAX_FILTER_PERIOD = 12 * 30.5 * 86400 * 1000;
