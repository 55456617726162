import { defineMessages } from 'react-intl';

const m = defineMessages({
  autorun: {
    id: 'autorun_ScriptChip',
    defaultMessage: 'Autorun',
  },
  usersAmount: {
    id: 'usersAmount_ScriptChip',
    defaultMessage: 'Users ({amount})',
  },
  groupsAmount: {
    id: 'groupAmount_ScriptChip',
    defaultMessage: 'Groups ({amount})',
  },
  exparationDate: {
    id: 'exparationDate_ScriptChip',
    defaultMessage: 'Exp. date: {date}',
  },
  timeout: {
    id: 'timeout_ScriptChip',
    defaultMessage: 'Timeout: {timeout} sec',
  },
});

export default m;
