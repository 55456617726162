import { WS_NEW_NOTIFY, WS_CLEAR_NOTIFY } from 'constants';
import { createReducer } from '@reduxjs/toolkit';

// ------------------------------------
// Reducer
// ------------------------------------
const initState = [];

export default createReducer(initState, {
  [WS_NEW_NOTIFY](state, action) {
    return action.payload;
  },
  [WS_CLEAR_NOTIFY]() {
    return initState;
  },
});
