import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  debitFormula: {
    id: 'debitFormula',
    defaultMessage: 'Debit formula',
  },
  creditFormula: {
    id: 'creditFormula',
    defaultMessage: 'Credit formula',
  },
  mean_func: {
    id: 'mean_func',
    defaultMessage: 'average',
  },
});

export default { ...globalIntl, ...m };
