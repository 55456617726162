import AppUtils from '../utils';

/**
 * Utility function to get the column sizes for mobile devices.
 * Replace all relative widths to 'auto'.
 * @param columnSize
 * @returns {(*|string)[]}
 */
function getMobileColumnSizes(columnSize = []) {
  return columnSize.map((width) =>
    AppUtils.isNumeric(width) ? width : 'auto'
  );
}

export default { getMobileColumnSizes };
