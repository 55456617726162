import React from 'react';
import { Badge, Tooltip } from 'mw-style-react';
import PropTypes from 'prop-types';
import './ActorColors.scss';

/**
 * Круг с цветными секторами
 */
function ActorColors({ colors = [], type = 'default' }) {
  const fieldColors = colors.filter((i) => i.type === 'field');
  const count = fieldColors.length;

  const renderColorSegment = (data, ange1, ange2) => {
    const dir = ange2 - ange1 > 0.5 ? '1' : '0';
    const angle1 = Math.PI * 2 * ange1;
    const angle2 = Math.PI * 2 * ange2;

    const rad = 350;
    const cx = 350;
    const cy = 350;

    const dx1 = Math.sin(angle1) * rad + cx;
    const dy1 = -Math.cos(angle1) * rad + cy;

    const dx2 = Math.sin(angle2) * rad + cx;
    const dy2 = -Math.cos(angle2) * rad + cy;

    return (
      <path
        key={data.itemId ? `${data.itemId}_${data.value}` : `${data.type}Color`}
        style={{ fill: data.color }}
        d={`M ${dx1},${dy1} A ${rad},${rad} 0 ${dir} 1 ${dx2},${dy2} L ${cx},${cy} Z`}
      />
    );
  };

  if (!fieldColors.length) return null;

  const getColorsLegend = () =>
    fieldColors.map((i) => (
      <div key={`${i.itemId}_${i.value}`} styleName="ac__tooltip__line">
        <Badge size="small" bgColor={i.color} borderColor={i.color} />
        &nbsp; - {i.title}
      </div>
    ));

  const renderColorsPieChart = () => (
    <svg
      className="colorChart"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      viewBox="0 0 700 700"
    >
      {count === 1
        ? renderColorSegment(fieldColors[0], 0, 0.9999)
        : fieldColors.map((item, index) =>
            renderColorSegment(item, index / count, (index + 1) / count)
          )}
    </svg>
  );

  return type === 'compact' ? (
    renderColorsPieChart()
  ) : (
    <Tooltip styleName="ac__tooltip" value={getColorsLegend()}>
      {renderColorsPieChart()}
    </Tooltip>
  );
}

ActorColors.propTypes = {
  type: PropTypes.oneOf(['compact', 'default']),
  colors: PropTypes.array,
};

export default ActorColors;
