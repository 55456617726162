import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  range_all: {
    id: 'range_all',
    defaultMessage: 'All time',
  },
  balanceDate: {
    id: 'balanceDate',
    defaultMessage: 'Balance on date',
  },
  balanceRange: {
    id: 'balanceRange',
    defaultMessage: 'Balance for period',
  },
});

export default { ...globalIntl, ...m };
