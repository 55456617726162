import {
  GET_TRANSACTIONS_FILTERS,
  UPDATE_TRANSACTIONS_FILTER,
  CLEAR_TRANSACTIONS_FILTERS,
  SET_ACTIVE_TRANSACTIONS_FILTER,
  ADD_TRANSACTIONS_FILTER,
  HIDE_TRANSACTIONS_FILTER,
  SYSTEM_TRANSACTIONS_FILTERS,
  TRANSACTIONS_FILTER_ACCESS,
} from '@control-front-end/common/constants/transactionsFilters';
import { ACCOUNT_TYPE } from '@control-front-end/common/constants/actorAccounts';
import { createReducer } from '@reduxjs/toolkit';

// ------------------------------------
// Reducer
// ------------------------------------

const initState = {
  list: SYSTEM_TRANSACTIONS_FILTERS,
  active: ACCOUNT_TYPE.number,
  limit: 100,
  offset: 0,
  total: 0,
  init: false,
  reqStatus: 'success',
  endList: false,
  savedAt: null,
};

export default createReducer(initState, {
  [GET_TRANSACTIONS_FILTERS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [ADD_TRANSACTIONS_FILTER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [UPDATE_TRANSACTIONS_FILTER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [TRANSACTIONS_FILTER_ACCESS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [HIDE_TRANSACTIONS_FILTER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SET_ACTIVE_TRANSACTIONS_FILTER](state, action) {
    return { ...state, active: action.payload };
  },
  [CLEAR_TRANSACTIONS_FILTERS]() {
    return initState;
  },
});
