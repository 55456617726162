import PropTypes from 'prop-types';
import React from 'react';
import {
  Modal,
  ModalButtons,
  ModalContent,
  Button,
  ProgressBar,
  withForm,
  Label,
  TextField,
} from 'mw-style-react';
import Yup from 'yup';
import useIntl from 'useIntl';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import sModal from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import sLocal from './SaveWithName.scss'; // NOSONAR

const DEFAULT_MIN_LENGTH = 2;

function SaveWithNameBase(props) {
  const {
    data,
    values,
    errors,
    visibility,
    onClose,
    handleSubmit,
    handleOnChange,
    isSubmit,
  } = props;
  const t = useIntl();

  return (
    <Modal
      styleName="sModal.modal"
      visibility={visibility}
      onClose={onClose}
      label={data.title}
      size="large"
    >
      <ModalContent styleName="sModal.modal__content sLocal.content">
        <div styleName="sLocal.row">
          <div>
            <Label value={t(mes.name)} fontWeight="semibold" />
            &nbsp;
          </div>
          <TextField
            id="name"
            styleName="sModal.textfield sLocal.row__field"
            autoFocus={true}
            bordered={true}
            autoSelect={true}
            size="large"
            value={values.name || ''}
            error={!!errors.name}
            helperText={t(mes[errors.name], {
              minLength: values.minLength,
              maxLength: values.maxLength,
            })}
            onChange={handleOnChange}
          />
        </div>
      </ModalContent>
      <ModalButtons styleName="sModal.modal__buttons">
        <Button
          label={data.btnText || t(mes.save)}
          size="large"
          onClick={handleSubmit}
          visibility={isSubmit ? 'disabled' : 'visible'}
        />
        <ProgressBar
          styleName="sModal.modal__loader"
          type="circle"
          size="small"
          visibility={isSubmit ? 'visible' : 'hidden'}
        />
      </ModalButtons>
    </Modal>
  );
}

const SaveWithName = withForm(
  {
    mapPropsToValues(props) {
      const formData = props.data || {};
      return {
        name: formData.name || '',
        checkErrors: formData.checkErrors,
        minLength: formData.minLength || DEFAULT_MIN_LENGTH,
        maxLength: formData.maxLength,
      };
    },
    validate: ({ name, checkErrors, minLength, maxLength }) => {
      if (name && checkErrors) {
        const error = checkErrors(name);
        return error ? { name: error } : {};
      }
      const nameLength = name.trim().length;
      if (nameLength < minLength) return { name: 'min_len' };
      if (nameLength > maxLength) return { name: 'max_len' };
      return {};
    },
    yup: Yup.object().shape({
      name: Yup.string().required('field_required'),
    }),
    handleSubmit: (values, dispatch, formActions, props) => {
      const { name } = values;
      props.onClose();
      if (typeof props.callback === 'function') {
        props.callback(name);
      }
    },
  },
  SaveWithNameBase
);

SaveWithNameBase.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleOnChange: PropTypes.func,
  dispatch: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  isSubmit: PropTypes.bool,
  values: PropTypes.object,
  errors: PropTypes.object,
  data: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
};

export default SaveWithName;
