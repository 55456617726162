import React, { useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button, Stack, Label, useObjectState } from 'mw-style-react';
import { useIntl } from 'hooks';
import SelectUsersAndGroups from '@control-front-end/common/components/SelectUsersAndGroups';
import { ParticipantsCompact } from 'components';
import UserAvatar from '@control-front-end/common/components/UserAvatar';

import m from './intl';
import scss from './ParticipantMappingMaker.scss';

/**
 * Creates a component for selecting and displaying a list of participants.
 *
 * @param {Object} props - The component's properties.
 * @param {string} props.id - The ID of the participant.
 * @param {string} props.nick - The nickname of the participant.
 * @param {string} props.avatar - The URL of the participant's avatar.
 * @param {function} props.onChange - A callback function called when the participant list changes.
 * @return {JSX.Element} A React component that displays the participant list and allows the user to map participants.
 */
function ParticipantMappingMaker({
  id,
  nick,
  type,
  avatar,
  mappedParticipants,
  onChange,
}) {
  const [state, setState] = useObjectState({
    participants: mappedParticipants || [],
    showSelector: false,
  });

  const t = useIntl();

  const selectorRef = useRef();

  const showSelector = useCallback(() => setState.showSelector(true), []);

  const hideSelector = useCallback(() => setState.showSelector(false), []);

  const selectItem = useCallback((items) => setState.participants(items));

  const deselectItem = useCallback(({ id }) =>
    setState.participants(state.participants.filter((item) => item.id !== id))
  );

  useEffect(() => {
    onChange({ id, mappedParticipants: state.participants });
  }, [state.participants]);

  return (
    <Stack horizontal fullWidth>
      <Stack horizontal alignItems="center" fullWidth>
        <div>
          <UserAvatar
            label={type === 'group' ? null : nick}
            src={avatar}
            customIcon={
              type === 'group'
                ? { type: 'persons', color: '#1973E8', size: 'small' }
                : null
            }
          />
        </div>
        <Stack size={Stack.SIZE.none}>
          <Label
            overflow={Label.OVERFLOW.cut}
            color={Label.COLOR.black}
            value={nick}
          />
          <Label
            color={Label.COLOR.gray}
            value={t(m.id, { id: String(id) })}
            fontSize="small"
          />
        </Stack>
      </Stack>
      <div style={{ width: 'fit-content' }}>
        {state.showSelector ? (
          <div className={scss.participantsSelector} ref={selectorRef}>
            <SelectUsersAndGroups
              preselectedItems={state.participants}
              handleSelect={selectItem}
              onClose={hideSelector}
              autocompleteClassName={scss.autocomplete}
              autoFocus
              editMode
            />
          </div>
        ) : (
          <Stack horizontal size={Stack.SIZE.xsmall}>
            {state.participants.length ? (
              <ParticipantsCompact
                participants={state.participants}
                onRemove={deselectItem}
              />
            ) : null}
            <Button
              size="small"
              icon="person_add"
              type="quaternary"
              onClick={showSelector}
            />
          </Stack>
        )}
      </div>
    </Stack>
  );
}

ParticipantMappingMaker.propTypes = {
  id: PropTypes.number.isRequired,
  nick: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  mappedParticipants: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
};

export default ParticipantMappingMaker;
