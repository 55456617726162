import { createReducer } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';
import { NEW_TRANSFER } from '@control-front-end/common/constants/actorAccounts';

// ------------------------------------
// Reducers for work on creation new formField transfers
// ------------------------------------
const emptyItem = {
  actor: null,
  field: null,
  value: [],
};

const initState = {
  from: emptyItem,
  to: [emptyItem],
};

export default createReducer(initState, {
  [NEW_TRANSFER.FORM_FIELD.SET](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  },
  [NEW_TRANSFER.FORM_FIELD.SOURCE.SET_ACTOR](state, action) {
    // Reset all selected fields and values
    return {
      ...state,
      from: { ...emptyItem, actor: action.payload },
      to: state.to.map(({ actor }) => ({ ...emptyItem, actor })),
    };
  },
  [NEW_TRANSFER.FORM_FIELD.SOURCE.SET_ACCOUNT](state, action) {
    return {
      ...state,
      from: { ...state.from, field: action.payload, value: emptyItem.value },
      to: state.to.map(({ actor }) => ({ ...emptyItem, actor })), // Reset "targets" selected fields and values
    };
  },
  [NEW_TRANSFER.FORM_FIELD.SOURCE.SET_VALUE](state, action) {
    const valueMap = keyBy(action.payload, 'value');
    return {
      ...state,
      from: { ...state.from, value: action.payload },
      // Validate selected values for targets and remove all itmes that are abscent in the source
      to: state.to.map((i) => ({
        ...i,
        value: i.value.filter((valueItem) => valueMap[valueItem.value]),
      })),
    };
  },
  [NEW_TRANSFER.FORM_FIELD.TARGET.ADD](state) {
    return {
      ...state,
      to: [...state.to, emptyItem],
    };
  },
  [NEW_TRANSFER.FORM_FIELD.TARGET.REMOVE](state, action) {
    return {
      ...state,
      to: state.to.filter((i, iIndex) => iIndex !== action.payload),
    };
  },
  [NEW_TRANSFER.FORM_FIELD.TARGET.SET_ACTOR](state, action) {
    const { index, value } = action.payload;
    return {
      ...state,
      to: state.to.map((i, iIndex) =>
        iIndex === index ? { ...emptyItem, actor: value } : i
      ),
    };
  },
  [NEW_TRANSFER.FORM_FIELD.TARGET.SET_ACCOUNT](state, action) {
    return {
      ...state,
      to: state.to.map((i, index) =>
        index === action.payload.index
          ? { ...i, field: action.payload.value, value: emptyItem.value }
          : i
      ),
    };
  },
  [NEW_TRANSFER.FORM_FIELD.TARGET.SET_VALUE](state, action) {
    return {
      ...state,
      to: state.to.map((i, index) =>
        index === action.payload.index
          ? { ...i, value: action.payload.value }
          : i
      ),
    };
  },
  [NEW_TRANSFER.FORM_FIELD.SOURCE.RESET](state) {
    return {
      ...state,
      from: { ...emptyItem },
      to: state.to.map(({ actor }) => ({ ...emptyItem, actor })),
    };
  },
  [NEW_TRANSFER.FORM_FIELD.TARGET.RESET](state, action) {
    const { index } = action.payload;
    return {
      ...state,
      to: state.to.map((i, iIndex) =>
        iIndex === index ? { ...emptyItem } : i
      ),
    };
  },
  [NEW_TRANSFER.FORM_FIELD.CLEAR]() {
    return initState;
  },
});
