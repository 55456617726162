import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalContent } from 'mw-style-react';
import { DEL_MODAL } from 'constants';
import FormSelection from '../../../FormSelection';
// eslint-disable-next-line no-unused-vars
import sModal from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import sLocal from './PublishForm.scss'; // NOSONAR

function PublishForm(props) {
  const {
    visibility,
    data = {},
    dispatch,
    callback,
    onClose,
    onContentChange = () => {},
  } = props;
  return (
    <Modal
      id="formModal"
      styleName="sLocal.modal"
      size="xlarge"
      visibility={visibility}
      onClose={onClose}
    >
      <ModalContent styleName="sModal.modal__content sLocal.content">
        <FormSelection
          data={data}
          onFormSave={(newForm) => {
            if (callback) callback(newForm);
            dispatch({ type: DEL_MODAL });
          }}
          onContentChange={onContentChange}
          onFormPublish={callback}
          onClose={onClose}
        />
      </ModalContent>
    </Modal>
  );
}

PublishForm.propTypes = {
  data: PropTypes.object,
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onContentChange: PropTypes.func,
  dispatch: PropTypes.func,
  callback: PropTypes.func,
};

export default PublishForm;
