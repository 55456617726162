import { Utils } from 'mw-style-react';

export const SUBSCRIBE_GRAPH = Utils.createRequestTypes('SUBSCRIBE_GRAPH');
export const SUBSCRIBE_BALANCES =
  Utils.createRequestTypes('SUBSCRIBE_BALANCES');
export const UNSUBSCRIBE_ALL_GRAPH = Utils.createRequestTypes(
  'UNSUBSCRIBE_ALL_GRAPH'
);
export const GRAPH_REALTIME_PROLONGATION = 'GRAPH_REALTIME_PROLONGATION';
export const WS_LAYER_CHANGED = 'WS_LAYER_CHANGED';
export const WS_UPDATE_BALANCES = 'WS_UPDATE_BALANCES';
export const WS_DELETE_LAYER = 'WS_DELETE_LAYER';
export const WS_UPDATE_ACTOR_ON_LAYER = 'WS_UPDATE_ACTOR_ON_LAYER';
export const WS_ACTOR_BALANCE_CHANGED = 'WS_ACTOR_BALANCE_CHANGED';
export const WS_CREATE_LAYER_AREA = 'WS_CREATE_LAYER_AREA';
export const WS_UPDATE_LAYER_AREA = 'WS_UPDATE_LAYER_AREA';
export const WS_DELETE_LAYER_AREA = 'WS_DELETE_LAYER_AREA';

export const SUBSCRIPTION_INTERVAL = 3540000; // 59 минут
export const REALTIME_EXCEPTION_LAYERS = [
  'graphRules',
  'dashboards',
  'accounts',
  'apis',
  'processes',
];
