import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  logout: {
    id: 'logout',
    defaultMessage: 'Logout',
  },
  profile: {
    id: 'profile',
    defaultMessage: 'Profile',
  },
});

export default { ...globalIntl, ...m };
