import { call, put, select, takeEvery } from 'redux-saga/effects';
import { RequestStatus } from 'constants';
import {
  GET_CONNECTORS,
  ADD_CONNECTOR,
  REMOVE_CONNECTOR,
} from '@control-front-end/common/constants/actorAccounts';
import api from '@control-front-end/common/sagas/api';
import { updateAccount } from '@control-front-end/common/sagas/actorsAccounts';
import { makeActorPicture } from './graphHelpers';

/**
 * Получить доступные коннекторы
 */
function* getConnectors({ callback }) {
  const accounts = yield select((state) => state.accounts);
  const accId = accounts.active;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/connectors/${accId}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  for (const obj of data.data) {
    obj.pictureUrl = yield makeActorPicture(obj);
  }
  yield put({
    type: GET_CONNECTORS.SUCCESS,
    payload: data.data,
  });
  if (callback) callback(data.data);
}

/**
 * Добавить коннектор к счету
 */
function* addConnector({ payload, callback, onError }) {
  const { key, accountId, incomeType, accountType, connector } = payload;
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/connectors/${accountId}`,
    body: { actorId: connector.id },
  });
  if (result !== RequestStatus.SUCCESS) {
    if (onError) onError();
    return;
  }
  const copyAccounts = yield updateAccount({
    accountType,
    key,
    incomeType,
    data: { connector, amount: data.data.amount },
  });
  yield put({
    type: ADD_CONNECTOR.SUCCESS,
    payload: { [accountType]: copyAccounts },
  });
  if (callback) callback();
}

/**
 * Удалить коннектор счета
 */
function* removeConnector({ payload, callback }) {
  const { key, accountId, incomeType, accountType } = payload;
  const { result } = yield call(api, {
    method: 'delete',
    url: `/connectors/${accountId}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  const actorsAccs = yield select((state) => state.actorsAccounts[accountType]);
  const copyAccounts = structuredClone(actorsAccs);
  const accIndex = copyAccounts.findIndex((i) => i.key === key);
  const accModel = copyAccounts[accIndex];
  delete accModel[incomeType].connector;
  yield put({
    type: REMOVE_CONNECTOR.SUCCESS,
    payload: { [accountType]: copyAccounts },
  });
  if (callback) callback();
}

function* connectors() {
  yield takeEvery(GET_CONNECTORS.REQUEST, getConnectors);
  yield takeEvery(ADD_CONNECTOR.REQUEST, addConnector);
  yield takeEvery(REMOVE_CONNECTOR.REQUEST, removeConnector);
}

export default connectors;
