import { call, select, takeLatest } from 'redux-saga/effects';
import {
  CHANGE_SYSTEM_ACTOR,
  GET_SYSTEM_ACTOR,
  SAVE_SYSTEM_ACTOR,
} from '@control-front-end/common/constants/graphActors';
import { bindAttach } from '@control-front-end/common/sagas/attachUtils';
import api from '@control-front-end/common/sagas/api';
import { RequestStatus } from 'constants';
import { createActor } from './graphNodes';
import { createMassEdges } from './graphEdges';
import { updateActorView } from '../actorView';

function makeEventModel(data) {
  return {
    actorModel: {
      title: data.title,
      description: data.description,
      formData: {
        startDate: data.startDate,
        endDate: data.endDate,
        channel: data.channel,
      },
      appId: data.appId || null,
      appSettings: data.appSettings || null,
      cardActorId: data.cardActorId || null,
      areaPicture: data.areaPicture || null,
      extraData: data.extraData || null,
      position: data.position,
    },
    access: data.access,
  };
}

const systemModels = {
  events: makeEventModel,
};

function* linkActors(actors, actorId, withEdges) {
  const edgeTypes = yield select((state) => state.edgeTypes);
  const edgeTypeH = edgeTypes.find((i) => i.name === 'hierarchy');
  const edges = actors.map((i) => ({
    source: actorId,
    target: i.id,
    edgeTypeId: edgeTypeH.id,
  }));
  yield createMassEdges({ payload: { edges, withEdges } });
}

function* saveSystemActor({ payload }) {
  const { formActions, actor, systemForm, withEdges = true } = payload;
  const systemForms = yield select((state) => state.systemForms);
  const { actorModel, access } = systemModels[systemForm](actor);
  const eventActorsPayload = {
    formId: systemForms[systemForm].id,
    access,
    ...actorModel,
    manageLayer: actor.manageLayer || false,
  };
  const newActor = yield createActor({
    payload: eventActorsPayload,
    formActions,
  });
  if (!newActor) return;
  const attaches = actor.attachments || [];
  yield call(bindAttach, attaches, newActor.id);
  if (actor.actors) {
    yield call(linkActors, actor.actors, newActor.id, withEdges);
  }
  if (formActions.callback) formActions.callback(newActor);
}

/**
 * Get system user actor
 */
export function* getSystemActor({ payload, callback, errorCallback }) {
  const { objType = 'user', objId } = payload;
  const accounts = yield select((state) => state.accounts);
  const accId = accounts.active;
  const { data, result } = yield call(api, {
    method: 'get',
    url: `/actors/system/${accId}/${objType}/${objId}`,
    handleErrCodes: [400, 403],
  });
  if (result !== RequestStatus.SUCCESS) {
    if (errorCallback) errorCallback();
    return;
  }
  if (callback) callback(data.data);

  return data.data;
}

/**
 * Change system actor
 */
function* changeSystemActor({ payload, callback }) {
  const { objType = 'user', objId, targetActorId } = payload;
  const accounts = yield select((state) => state.accounts);
  const accId = accounts.active;
  const { data, result } = yield call(api, {
    method: 'post',
    url: `/actors/change_system/${accId}`,
    body: { objType, objId, targetActorId },
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback(data.data);
  yield call(updateActorView, {
    payload: {
      actorData: {
        id: targetActorId,
        systemObjId: objId,
        systemObjType: objType,
      },
    },
  });
}

function* systemActors() {
  yield takeLatest(SAVE_SYSTEM_ACTOR.REQUEST, saveSystemActor);
  yield takeLatest(GET_SYSTEM_ACTOR.REQUEST, getSystemActor);
  yield takeLatest(CHANGE_SYSTEM_ACTOR.REQUEST, changeSystemActor);
}

export default systemActors;
