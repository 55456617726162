import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  addAccount: {
    id: 'addAccount_CreateTransfer',
    defaultMessage: 'Add account',
  },
  makeTransfer: {
    id: 'makeTransfer_CreateTransfer',
    defaultMessage: 'Make transfer',
  },
  account: {
    id: 'account_CreateTransfer',
    defaultMessage: 'Account',
  },
  value: {
    id: 'value_CreateTransfer',
    defaultMessage: 'Value',
  },
  balance: {
    id: 'balance_CreateTransfer',
    defaultMessage: 'Balance:',
  },
  actor: {
    id: 'actor_CreateTransfer',
    defaultMessage: 'Actor',
  },
  actorFrom: {
    id: 'actorFrom_CreateTransfer',
    defaultMessage: 'Actor from',
  },
  actorTo: {
    id: 'actorTo_CreateTransfer',
    defaultMessage: 'Actor to',
  },
  errorEmptyActor: {
    id: 'errorEmptyActor_CreateTransfer',
    defaultMessage: "Actor shouldn't be empty",
  },
  errorEmptyAccount: {
    id: 'errorEmptyAccount_CreateTransfer',
    defaultMessage: "Account shouldn't be empty",
  },
  errorEmptyValue: {
    id: 'errorEmptyValue_CreateTransfer',
    defaultMessage: 'Should exceed zero',
  },
  errorDifferentCurrency: {
    id: 'errorDifferentCurrency_CreateTransfer',
    defaultMessage: 'Items should have the same currency',
  },
  errorDifferentTotal: {
    id: 'errorDifferentTotal_CreateTransfer',
    defaultMessage: 'Should be equal',
  },
});

export default { ...globalIntl, ...m };
