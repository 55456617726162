import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  notifications: {
    id: 'notifications',
    defaultMessage: 'Notifications',
  },
  markAsRead: {
    id: 'markAsRead',
    defaultMessage: 'Mark as read',
  },
  noNotify: {
    id: 'noNotify',
    defaultMessage: "You don't have any notifications yet.",
  },
  noNotifyInfo: {
    id: 'noNotifyInfo',
    defaultMessage: 'Notifications about any news and updates will be here.',
  },
  notify_newEvent: {
    id: 'notify_newEvent',
    defaultMessage: 'invited you to the event',
  },
  notify_newActor: {
    id: 'notify_newActor',
    defaultMessage: 'invited you to the actor',
  },
  notify_comment: {
    id: 'notify_comment',
    defaultMessage: 'commented the event',
  },
  notify_reject: {
    id: 'notify_reject',
    defaultMessage: 'rejected the event',
  },
  notify_sign: {
    id: 'notify_sign',
    defaultMessage: 'signed the event',
  },
  notify_done: {
    id: 'notify_done',
    defaultMessage: 'executed the event',
  },
  notify_rating: {
    id: 'notify_rating',
    defaultMessage: 'rated the event',
  },
  notify_freeze: {
    id: 'notify_freeze',
    defaultMessage: 'froze the event',
  },
  notify_script: {
    id: 'notify_script',
    defaultMessage: 'added the script to the event',
  },
  notify_alt_newEvent: {
    id: 'notify_alt_newEvent',
    defaultMessage: 'Invitation to the event from',
  },
  notify_alt_newActor: {
    id: 'notify_alt_newActor',
    defaultMessage: 'Invitation to the actor from',
  },
  notify_alt_comment: {
    id: 'notify_alt_comment',
    defaultMessage: 'Comment from',
  },
  notify_alt_reject: {
    id: 'notify_alt_reject',
    defaultMessage: 'Was rejected by',
  },
  notify_alt_sign: {
    id: 'notify_alt_sign',
    defaultMessage: 'Was signed by',
  },
  notify_alt_done: {
    id: 'notify_alt_done',
    defaultMessage: 'Was done by',
  },
  notify_alt_rating: {
    id: 'notify_alt_rating',
    defaultMessage: 'Was rated by',
  },
  notify_alt_freeze: {
    id: 'notify_alt_freeze',
    defaultMessage: 'Was frozen by',
  },
  notify_alt_script: {
    id: 'notify_alt_script',
    defaultMessage: 'The script was added by',
  },
  internalError: {
    id: 'internalError',
    defaultMessage: 'Oops, something went wrong, please reload the page!',
  },
});

export default { ...globalIntl, ...m };
