import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Label, Icon } from 'mw-style-react';
import './ContextMenuItem.scss';

/**
 * Компонент меню для актора
 * @returns {*}
 * @constructor
 */
function ContextMenuItem(props) {
  const itemRef = useRef();
  const {
    children,
    icon,
    iconColor,
    iconSize,
    innerMenu,
    label,
    hotKey,
    visibility = 'visible',
    handleClick = () => {},
    handleHover = () => {},
    handleBlur = () => {},
  } = props;

  return (
    <div
      ref={itemRef}
      styleName={cn('cm__item', {
        disabled: visibility === 'disabled',
        hidden: visibility === 'hidden',
      })}
      onClick={() => {
        if (visibility === 'disabled') return;
        handleClick();
      }}
      onMouseEnter={handleHover}
      onMouseLeave={handleBlur}
    >
      <div styleName="cm__item__icon">
        <Icon type={icon} size={iconSize} color={iconColor} />
      </div>
      <div styleName="cm__item__label">
        <Label value={label} />
        <Label value={hotKey} />
        {innerMenu ? (
          <Icon type="dropdown" size="micro" styleName="cm__item__dd" />
        ) : null}
      </div>
      <div className="nested">{children}</div>
    </div>
  );
}

ContextMenuItem.propTypes = {
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  innerMenu: PropTypes.bool,
  iconSize: PropTypes.oneOf(['small', 'medium', 'large']),
  label: PropTypes.string.isRequired,
  hotKey: PropTypes.string,
  handleClick: PropTypes.func,
  handleHover: PropTypes.func,
  handleBlur: PropTypes.func,
  visibility: PropTypes.string,
  children: PropTypes.node,
};

export default ContextMenuItem;
