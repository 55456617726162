import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import getURLSettings from '@control-front-end/utils/getURLSettings';

const useURLSettings = () => {
  const { search } = useLocation();

  const urlSettings = useMemo(getURLSettings, [search]);

  return urlSettings;
};

export default useURLSettings;
