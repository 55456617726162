import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useSearchList, useIntl } from 'hooks';
import { CLEAR_USERS, GET_USERS, RECENT, SEARCH_USERS } from 'constants';
import AppUtils from '@control-front-end/utils/utils';
import mes from 'globalIntl';
import ResultList from '../ResultList';

/**
 * Component for users selection
 */
function SelectUsers({
  query,
  exclude,
  showExcluded,
  checkIsActive,
  handleSelect,
  handleDeselect,
  users: usersProp,
}) {
  const t = useIntl();
  const dispatch = useDispatch();
  const isFirstRenderRef = useRef(true);

  const [specificUsers, setSpecificUsers] = useState(usersProp);

  const specificUsersOnly = Boolean(usersProp);

  const [suggestions, setSuggestions] = useState([]);

  const [skipSearch, setSkipSearch] = useState(true);

  const {
    list: users,
    makeSearch,
    onScroll,
  } = useSearchList({
    actionType: ({ query }) =>
      query ? SEARCH_USERS.REQUEST : GET_USERS.REQUEST,
    skip: skipSearch,
    searchParams: { query: '' },
    settings: { step: 15 },
  });

  /**
   * Load users suggestions
   */
  const loadSuggestions = () => {
    dispatch({
      type: RECENT.REQUEST,
      payload: {
        objType: 'user',
      },
      callback: (data) => setSuggestions(data),
    });
  };

  useEffect(() => {
    if (specificUsersOnly) return;
    loadSuggestions();
    return () => {
      dispatch({ type: CLEAR_USERS });
    };
  }, [specificUsersOnly]);

  useEffect(() => {
    if (specificUsersOnly || isFirstRenderRef.current) return;
    makeSearch({ query });
  }, [query, specificUsersOnly]);

  // Handles users filter logic in case when specific users were specified
  useEffect(() => {
    if (!specificUsersOnly) return;
    setSpecificUsers(
      usersProp.filter(({ name }) =>
        name.toLowerCase().includes(query.toLowerCase())
      )
    );
  }, [specificUsersOnly, usersProp, exclude, query]);

  useEffect(() => {
    isFirstRenderRef.current = false;
    setSkipSearch(false);
  }, []);

  const availableUsers = showExcluded
    ? users.slice()
    : users.filter((user) => !exclude.find((u) => u.id === user.id));
  const restUsers = availableUsers.filter(
    (user) => !suggestions.find((u) => u.id === user.id)
  );
  const resultList = query.length
    ? availableUsers
    : suggestions.concat(restUsers);

  const items = (specificUsersOnly ? specificUsers : resultList).map((user) => {
    const isActive = checkIsActive(user);
    const isExcluded = exclude.find(({ userId }) => userId === user.id);
    const avatar = user.avatar || user.pictureUrl;
    return {
      ...user,
      isActive,
      isExcluded,
      userAvatar: {
        src: avatar,
        label: avatar ? '' : user.nick || user.value,
        userType: user.type,
      },
      name: user.nick || user.value,
      info: user.type === 'user' ? AppUtils.getUserLogin(user.logins) : user.id,
    };
  });

  return (
    <ResultList
      objType="user"
      title={t(mes.users)}
      list={items}
      isSearch={!!query.length}
      onScroll={onScroll}
      onSelect={handleSelect}
      onDeselect={handleDeselect}
    />
  );
}

SelectUsers.defaultProps = {
  exclude: [],
};

SelectUsers.propTypes = {
  query: PropTypes.string,
  exclude: PropTypes.array,
  users: PropTypes.arrayOf(PropTypes.object),
  showExcluded: PropTypes.bool,
  checkIsActive: PropTypes.func,
  handleSelect: PropTypes.func.isRequired,
  handleDeselect: PropTypes.func.isRequired,
};

export default SelectUsers;
