import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Label, DateUtils, Stack, Icon, Utils } from 'mw-style-react';
import cn from 'classnames';
import useIntl from 'useIntl';
import { DATE_FORMAT } from 'constants';
import UsersAvatars from '@control-front-end/common/components/UsersAvatars';
import HistoryDiffRow from '../HistoryDiffRow';
import './HistoryItem.scss';
import mes from './intl';

/**
 * History item component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function HistoryItem(props) {
  const {
    id,
    userId,
    user,
    field,
    prevValue,
    nextValue,
    createdAt,
    formTitle,
    fieldTitle,
  } = props;
  const t = useIntl();
  const [collapsed, setCollapsed] = useState(false);
  let formItemId = null;
  const isFormField = field.includes('data.');
  const isNoRootFormField = isFormField && !field.includes('__form__');
  if (isNoRootFormField) {
    formItemId = field.split('.')[1];
  } else if (isFormField) {
    formItemId = field.split(':')[1];
  }

  const fieldLabel = useMemo(
    () => (
      <Stack
        styleName={cn('historyItem__label', { compact: collapsed })}
        horizontal
        alignItems="center"
        size={Stack.SIZE.xsmall}
      >
        {formItemId ? (
          <>
            <Label color={Label.COLOR.gray} value={t(mes.h_formTitle)} />
            <Label value={formTitle} />
            <Label color={Label.COLOR.gray} value={t(mes.h_fieldTitle)} />
            <Label value={fieldTitle} />
            <Label color={Label.COLOR.gray} value={t(mes.h_field)} />
            <Label value={formItemId} />
          </>
        ) : (
          <>
            <Label color={Label.COLOR.gray} value={t(mes.h_fieldTitle)} />
            <Label value={Utils.toUpperLatter(field)} />
          </>
        )}
      </Stack>
    ),
    [field, collapsed]
  );

  return (
    <div key={`${id}_item`} styleName="historyItem">
      <Stack
        horizontal
        justifyContent="spaceBetween"
        fullWidth
        size={Stack.SIZE.medium}
      >
        <Stack horizontal size={Stack.SIZE.medium}>
          <UsersAvatars
            users={[{ userId, name: user.nick, avatar: user.avatar }]}
          />
          <Stack size={Stack.SIZE.xxsmall}>
            <Label fontWeight="semibold" value={user.nick} />
            <Label
              fontSize="small"
              color={Label.COLOR.gray}
              value={DateUtils.toDate(createdAt / 1000, DATE_FORMAT)}
            />
            {collapsed ? fieldLabel : null}
          </Stack>
        </Stack>
        <div
          styleName={cn('historyItem__collapse', { active: collapsed })}
          onClick={() => setCollapsed(!collapsed)}
        >
          <Icon type="arrow_reverse" />
        </div>
      </Stack>
      {!collapsed ? (
        <HistoryDiffRow
          key={id}
          id={id}
          field={field}
          prevValue={prevValue}
          nextValue={nextValue}
          formTitle={formTitle}
          label={fieldLabel}
        />
      ) : null}
    </div>
  );
}

HistoryItem.propTypes = {
  id: PropTypes.number.isRequired,
  user: PropTypes.shape({
    nick: PropTypes.string,
    avatar: PropTypes.string,
  }),
  userId: PropTypes.number,
  formTitle: PropTypes.string,
  fieldTitle: PropTypes.string,
  field: PropTypes.string,
  prevValue: PropTypes.any,
  nextValue: PropTypes.any,
  createdAt: PropTypes.number,
};

export default HistoryItem;
