import { createReducer } from '@reduxjs/toolkit';
import { APP_NAME } from '../constants';

// ------------------------------------
// Reducer
// ------------------------------------
const initState = {};

export default createReducer(initState, {
  [APP_NAME](state, action) {
    return action.payload;
  },
});
