import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Label, Stack, Space } from 'mw-style-react';
import useIntl from 'useIntl';
import AppUtils from '@control-front-end/utils/utils';
import {
  AccessLevel,
  GET_INVITES,
  DELETED_USER_NAME,
  ACCESS_RULES_ACTIONS,
} from 'constants';
import { useDispatch, useSelector } from 'react-redux';
import mes from './intl';
import SelectedUsers from './components/SelectedUsers';
import SelectUsersAndGroups from '../SelectUsersAndGroups';
import './AccessRules.scss';

function AccessRules({
  objId,
  objType,
  rules,
  ownerId,
  allPrivs,
  styleType = 'modal',
  disableOwner = true,
  allowInvite = true,
  onChange,
  onSelect = () => {},
}) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const t = useIntl();

  /**
   * Получить инвайты
   */
  const getObjectInvites = () => {
    if (!objId) return;
    dispatch({
      type: GET_INVITES.REQUEST,
      payload: { objId, objType },
      callback: (invites) => {
        const pureRules = rules.filter((i) => !i.isInvite);
        onChange([...invites, ...pureRules]);
      },
    });
  };

  useEffect(() => {
    getObjectInvites();
  }, [objId, objType]);

  /**
   * Задать стартовый набор прав для добавленного пользователя/группы
   */
  const makeNewRule = ({
    id,
    saId,
    nick,
    name,
    value,
    role,
    avatar,
    pictureUrl,
    userType,
    type,
    logins,
  }) => {
    const isWorkspaceOwner = Boolean(
      (role || []).find((r) => r.name === 'Owner')
    );
    const newPrivs = {
      view: true,
      modify: isWorkspaceOwner || id === ownerId,
      remove: isWorkspaceOwner || id === ownerId,
    };
    if (allPrivs) {
      newPrivs.sign = false;
      newPrivs.execute = false;
    }
    const newRule = {
      action: 'create',
      avatar: avatar || pictureUrl,
      name: nick || name || value,
      userType: userType || type,
      role,
      privs: newPrivs,
      logins,
    };
    if (userType === 'group') {
      newRule.saId = saId;
    }
    newRule.userId = id;
    newRule.id = id;
    return newRule;
  };

  /**
   * Добавление пользователей
   */
  const handleSelectUsers = (newUsers) => {
    newUsers = Array.isArray(newUsers) ? newUsers : [newUsers];
    const oldRules = rules.slice();
    const newRules = newUsers.map((user) => makeNewRule(user));
    newRules.forEach((item) => {
      const ruleIndex = oldRules.findIndex(
        (rule) =>
          rule.action === ACCESS_RULES_ACTIONS.delete &&
          rule.userId === item.userId
      );
      if (ruleIndex === -1) return;
      oldRules.splice(ruleIndex, 1);
      item.action = ACCESS_RULES_ACTIONS.update;
    });
    onChange([...newRules, ...oldRules]);
  };

  /**
   * Добавить инвайт
   */
  const handleInvite = (email, roleId) => {
    const newPrivs = {
      view: true,
      modify: false,
      remove: false,
    };
    if (allPrivs) {
      newPrivs.sign = false;
      newPrivs.execute = false;
    }
    const invite = [
      {
        action: ACCESS_RULES_ACTIONS.create,
        isInvite: true,
        isNew: true,
        name: email,
        userId: AppUtils.udid(),
        userType: 'user',
        ownerUserId: auth.id,
        privs: newPrivs,
        roleId,
      },
    ];
    onChange([...invite, ...rules]);
  };

  /**
   * Удаление пользователя
   */
  const handleDelete = (id) => {
    if (id === ownerId && disableOwner) return;
    const list = rules.slice();
    const index = list.findIndex((u) => u.userId === id);
    if (index === -1) return;
    const rule = list[index];
    if (rule.action === ACCESS_RULES_ACTIONS.create) {
      list.splice(index, 1);
    } else {
      list.splice(index, 1, { ...rule, action: ACCESS_RULES_ACTIONS.delete });
    }
    onChange(list);
  };

  /**
   * Изменение доступов для пользователя|группы
   */
  const handleAccessChange = (id, level, value) => {
    if (id === 'all') {
      const list = rules.map((rule) => {
        const isWorkspaceOwner = (rule.role || []).find(
          (r) => r.name === 'Owner'
        );
        const isOwner =
          (ownerId && rule.userId === ownerId) || isWorkspaceOwner;
        const isOwnerDefaultAccess =
          isOwner &&
          level !== AccessLevel.SIGN &&
          level !== AccessLevel.EXECUTE &&
          rule.privs[level];
        const isUserDeleted = rule.name === DELETED_USER_NAME;
        if (isOwnerDefaultAccess || isUserDeleted) return { ...rule };
        const newPrivs = { ...rule.privs, [level]: value };
        const action =
          rule.action !== ACCESS_RULES_ACTIONS.create
            ? ACCESS_RULES_ACTIONS.update
            : rule.action;
        return { ...rule, privs: newPrivs, action };
      });
      onChange(list);
    } else {
      const list = structuredClone(rules);
      const index = list.findIndex((u) => u.userId === id);
      if (index === -1) return;
      const rule = list[index];
      const newPrivs = rule.privs || {};
      newPrivs[level] = value;
      if (rule.action !== ACCESS_RULES_ACTIONS.create)
        rule.action = ACCESS_RULES_ACTIONS.update;
      const item = { ...rule, privs: newPrivs };
      list.splice(index, 1, item);
      onChange(list);
    }
  };

  return (
    <div styleName={cn('userAccess', styleType)} className="userAccess">
      <Space left right>
        <Stack size={Stack.SIZE.small}>
          <Label fontWeight="semibold" value={t(mes.addUsers)} />
          <SelectUsersAndGroups
            exclude={rules.filter(
              (i) => i.action !== ACCESS_RULES_ACTIONS.delete
            )}
            showExcluded={true}
            handleSelect={handleSelectUsers}
            handleInvite={allowInvite ? handleInvite : null}
            handleFocus={onSelect}
          />
        </Stack>
      </Space>
      <div
        styleName={cn('userAccess__list', {
          embeded: styleType === 'embeded',
        })}
      >
        <SelectedUsers
          rules={rules}
          allPrivs={allPrivs}
          ownerId={ownerId}
          disableOwner={disableOwner}
          handleAccessChange={handleAccessChange}
          handleDelete={handleDelete}
        />
      </div>
    </div>
  );
}

AccessRules.propTypes = {
  styleType: PropTypes.oneOf(['modal', 'embeded']),
  disableOwner: PropTypes.bool,
  rules: PropTypes.array,
  allPrivs: PropTypes.bool,
  allowInvite: PropTypes.bool,
  ownerId: PropTypes.number,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
};

export default AccessRules;
