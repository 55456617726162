import { defineMessages } from 'react-intl';

const m = defineMessages({
  accessView: {
    id: 'accessView',
    defaultMessage: 'VIEW',
  },
  accessRemove: {
    id: 'accessRemove',
    defaultMessage: 'REMOVE',
  },
  accessSign: {
    id: 'accessSign',
    defaultMessage: 'SIGN',
  },
  accessExecute: {
    id: 'accessExecute',
    defaultMessage: 'EXECUTE',
  },
  accessModify: {
    id: 'accessModify',
    defaultMessage: 'EDIT',
  },
});

export default m;
