import { combineReducers } from '@reduxjs/toolkit';
import appInit from '@control-front-end/common/reducers/appInit';
import translations from '@control-front-end/common/reducers/translations';
import formList from '@control-front-end/common/reducers/formList';
import formView from '@control-front-end/common/reducers/formView';
import appName from '@control-front-end/common/reducers/appName';
import windowFocused from '@control-front-end/common/reducers/windowFocused';
import config from '@control-front-end/common/reducers/config';
import auth from '@control-front-end/common/reducers/auth';
import accounts from '@control-front-end/common/reducers/accounts';
import notify from '@control-front-end/common/reducers/notify';
import users from '@control-front-end/common/reducers/users';
import groups from '@control-front-end/common/reducers/groups';
import invites from '@control-front-end/common/reducers/invites';
import settings from '@control-front-end/common/reducers/settings';
import theme from '@control-front-end/common/reducers/theme';
import location from '@control-front-end/common/reducers/location';
import modal from '@control-front-end/common/reducers/modal';
import widgets from '@control-front-end/common/reducers/widgets.js';
import starredScripts from '@control-front-end/common/reducers/starredScripts.js';
import delayedActions from '@control-front-end/common/reducers/delayedActions';
import actorsCache from '@control-front-end/common/reducers/actorsCache';
import scriptContent from '@control-front-end/common/reducers/scriptContent';
import search from '../../../common/reducers/search';
import wsNotify from './wsNotify';
import systemForms from './systemForms';
import graphRealtime from './graphRealtime';
import action from './action';
// Reactions
import reactions from '../routes/Events/reducers/reactions';
// Streams
import streams from '../routes/Events/reducers/streams';
// Files
import files from '../routes/Files/reducers/files';
// Settings
import webhooks from '../routes/Settings/reducers/webhooks';
// Import & Export
import tasks from '../routes/ImportAndExport/reducers/tasks';
// Actors Graph
import actorsList from '../routes/ActorsGraph/reducers/actorsList';
import actorView from '../routes/ActorsGraph/reducers/actorView';
import defaultActorTpl from '../routes/ActorsGraph/reducers/defaultActorTpl';
import edgeTypes from '../routes/ActorsGraph/reducers/edgeTypes';
import graphValency from '../routes/ActorsGraph/reducers/graphValency';
import actorsAccounts from '../routes/ActorsGraph/reducers/actorsAccounts';
import actorsAccountsGroups from '../routes/ActorsGraph/reducers/actorsAccountsGroups';
import transfers from '../routes/ActorsGraph/reducers/transfers';
import transactions from '../routes/ActorsGraph/reducers/transactions';
import transactionsFilters from '../routes/ActorsGraph/reducers/transactionsFilters';
import currencies from '../routes/ActorsGraph/reducers/currencies';
import accountNames from '../routes/ActorsGraph/reducers/accountNames';
import graphFolders from '../routes/ActorsGraph/reducers/graphFolders';
import newNumericTransfer from '../routes/ActorsGraph/reducers/newNumericTransfer';
import newFormFieldTransfer from '../routes/ActorsGraph/reducers/newFormFieldTransfer';
import graphDiscovery from '../routes/ActorsGraph/reducers/graphDiscovery';
import graphLayers from '../routes/ActorsGraph/reducers/graphLayers';
import layersAreas from '../routes/ActorsGraph/reducers/layersAreas';
import layerActorsNumbers from '../routes/ActorsGraph/reducers/layerActorsNumbers';
import actorsFilters from '../routes/ActorsGraph/reducers/actorsFilters';
import formsFilters from '../routes/ActorsGraph/reducers/formsFilters';

const makeRootReducer = (asyncReducers) =>
  combineReducers({
    theme,
    appName,
    windowFocused,
    appInit,
    translations,
    config,
    auth,
    modal,
    widgets,
    starredScripts,
    notify,
    users,
    groups,
    invites,
    settings,
    accounts,
    search,
    location,
    wsNotify,
    delayedActions,
    formsFilters,
    formList,
    formView,
    systemForms,
    graphRealtime,
    reactions,
    streams,
    action,
    // Scripts
    scriptContent,
    // Files
    files,
    // Settings
    webhooks,
    // Import & Export
    tasks,
    // Actors Graph
    graphFolders,
    newNumericTransfer,
    newFormFieldTransfer,
    actorsList,
    actorView,
    actorsCache,
    defaultActorTpl,
    edgeTypes,
    graphValency,
    actorsAccounts,
    actorsAccountsGroups,
    transfers,
    transactions,
    transactionsFilters,
    accountNames,
    currencies,
    graphDiscovery,
    graphLayers,
    actorsFilters,
    layersAreas,
    layerActorsNumbers,
    ...asyncReducers,
  });

export const injectReducer = (store, key, reducer) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;

  store.asyncReducers[key] = reducer;
  // store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
