import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Label,
  Avatar,
  DateUtils,
  Icon,
  Menu,
  MenuItem,
  FileUtils,
  Clipboard,
  Popover,
} from 'mw-style-react';
import cn from 'classnames';
import { DATE_FORMAT, NOTIFY_LEVEL } from 'constants';
import { useIntl, useModal, useNotifications } from 'hooks';
import tableScss from '@control-front-end/common/styles/table.scss';
import AppUtils from '@control-front-end/utils/utils';
import mes from './intl';
import scss from './AttachList.scss';

/**
 * List of attachments
 */
function AttachList({ list = [], canEdit = false, onDelete }) {
  const t = useIntl();
  const { showNotification } = useNotifications();
  const { open: openFilePreview } = useModal('FilePreview', {});

  const copyLink = (file) => {
    const link = `${document.location.origin}${file.filePath}?preview=true`;
    Clipboard.copy(link);
    showNotification(NOTIFY_LEVEL.SUCCESS, t(mes.linkCopied));
  };

  // Download attached file
  const downloadFile = (file) => {
    const filePath =
      file.filePath || AppUtils.makeAppUrl(`/download/${file.fileName}`);
    FileUtils.download(filePath, file.title);
  };

  const handleMenuClick = ({ file, value, onClose }) => {
    switch (value) {
      case 'copy_link':
        copyLink(file);
        break;
      case 'download':
        downloadFile(file);
        break;
      case 'delete':
        onDelete(file);
        break;
      default:
        break;
    }
    onClose();
  };

  // Render menu for attached file
  const renderMenu = ({ file, onClose }) => {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Menu
          className={tableScss.itemMenu}
          size="small"
          width={200}
          onClick={({ value }) => handleMenuClick({ file, value, onClose })}
          onClose={onClose}
        >
          <MenuItem
            size="small"
            label={t(mes.copyLink)}
            value="copy_link"
            visibility="visible"
          />
          <MenuItem
            size="small"
            label={t(mes.download)}
            value="download"
            visibility="visible"
          />
          <MenuItem
            size="small"
            label={t(mes.remove)}
            value="delete"
            visibility={canEdit && onDelete ? 'visible' : 'hidden'}
          />
        </Menu>
      </div>
    );
  };

  const renderFilePreview = (file) => {
    if (file.type.indexOf('image') !== -1) {
      const filePath =
        file.filePath || AppUtils.makeAppUrl(`/download/${file.fileName}`);
      return (
        <div className={scss.imgWrap}>
          <img id={file.id} className={scss.previewImg} src={filePath} alt="" />
        </div>
      );
    }

    return (
      <div className={scss.fileWrap}>
        <Icon type="attach" />
      </div>
    );
  };

  const renderRows = () => {
    return list.map((file) => (
      <TableRow
        key={file.id}
        className={cn(tableScss.row, tableScss.clickable, scss.attachRow)}
        rowSize="small"
        onClick={() => openFilePreview({ ...file, files: list })}
      >
        <TableCell className={tableScss.cell}>
          {renderFilePreview(file)}
        </TableCell>
        <TableCell className={cn(tableScss.cell, scss.title)}>
          <Label value={file.title} overflow="cut" />
        </TableCell>
        <TableCell className={tableScss.cell}>
          <Label value={FileUtils.biteToSize(file.size)} />
        </TableCell>
        <TableCell className={tableScss.cell}>
          <Label value={DateUtils.toDate(file.createdAt, DATE_FORMAT)} />
        </TableCell>
        <TableCell className={tableScss.cell}>
          {file.userAvatar ? (
            <Avatar size="small" src={file.userAvatar} label={file.name} />
          ) : null}
        </TableCell>
        <TableCell className={tableScss.menu}>
          <div onClick={(e) => e.stopPropagation()}>
            <Popover
              topLevel
              anchors={{
                binding: Popover.ANCHOR.right_bottom,
                content: Popover.ANCHOR.right_top,
              }}
              content={({ onClose }) => renderMenu({ file, onClose })}
            >
              <Icon type="more" />
            </Popover>
          </div>
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <Table
      paddingSize="small"
      className={scss.attachTable}
      columnSize={[48, '38%', 104, '30%', 48, 32]}
    >
      <TableBody>{renderRows()}</TableBody>
    </Table>
  );
}

AttachList.propTypes = {
  list: PropTypes.array.isRequired,
  canEdit: PropTypes.bool,
  onDelete: PropTypes.func,
};

export default AttachList;
