import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl, useModal, useSearchList } from 'hooks';
import { GET_GROUPS, CLEAR_GROUPS } from 'constants';
import mes from 'globalIntl';
import ResultList from '../ResultList';

/**
 * Component for selecting groups
 */
function SelectGroups({
  query,
  exclude,
  groups: groupsProp,
  showExcluded,
  checkIsActive,
  handleSelect,
  handleDeselect,
}) {
  const t = useIntl();
  const dispatch = useDispatch();
  const { openModal: openGroupDetailsModal } = useModal('UsersGroupDetails');

  const [specificGroups, setSpecificGroups] = useState(groupsProp);
  const specificGroupsOnly = Boolean(groupsProp);

  const {
    list: groups,
    makeSearch,
    onScroll,
  } = useSearchList({
    actionType: GET_GROUPS.REQUEST,
    searchParams: { q: '' },
    settings: { step: 15 },
  });

  useEffect(() => {
    if (specificGroupsOnly) return;
    makeSearch({ q: query });
    return () => {
      dispatch({ type: CLEAR_GROUPS });
    };
  }, [specificGroupsOnly, query]);

  // Handles groups filter logic in case when specific groups was specified
  useEffect(() => {
    if (!specificGroupsOnly) return;
    const availableGroups = groupsProp.filter(
      (group) => !exclude.find((r) => r.userId === group.id)
    );

    setSpecificGroups(
      availableGroups.filter(({ nick, value, name }) =>
        (nick || value || name).toLowerCase().includes(query.toLowerCase())
      )
    );
  }, [specificGroupsOnly, groupsProp, exclude, query]);

  const availableGroups = showExcluded
    ? groups.slice()
    : groups.filter(
        (group) =>
          !exclude.find(
            ({ id, userId }) => id === group.id || userId === group.id
          )
      );

  const items = (specificGroupsOnly ? specificGroups : availableGroups).map(
    (group) => {
      const isActive = checkIsActive(group);
      const isExcluded = exclude.find(
        ({ id, userId }) => id === group.id || userId === group.id
      );
      return {
        ...group,
        isActive,
        isExcluded,
        userAvatar: {
          userType: 'group',
          customIcon: { type: 'persons', color: '#1973E8' },
        },
        name: group.nick || group.name || group.value,
        info: group.id,
        count: group.userCount + group.apiCount,
      };
    }
  );

  return (
    <ResultList
      objType="group"
      title={t(mes.groups)}
      list={items}
      isSearch={!!query.length}
      onScroll={onScroll}
      onSelect={(group) => handleSelect({ ...group, userType: 'group' })}
      onDeselect={handleDeselect}
      onClickRightElement={(group) =>
        openGroupDetailsModal({
          saId: group.saId,
          name: group.nick || group.name || group.value,
        })
      }
    />
  );
}

SelectGroups.defaultProps = {
  exclude: [],
};

SelectGroups.propTypes = {
  query: PropTypes.string,
  exclude: PropTypes.array,
  groups: PropTypes.arrayOf(PropTypes.object),
  showExcluded: PropTypes.bool,
  checkIsActive: PropTypes.func,
  handleSelect: PropTypes.func.isRequired,
  handleDeselect: PropTypes.func.isRequired,
};

export default SelectGroups;
