import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  ToggleSwitch,
  Label,
  Divider,
  Checkbox,
  Stack,
} from 'mw-style-react';
import useIntl from 'useIntl';
import AppUtils from '@control-front-end/utils/utils';
import mes from './intl';
import './AccAdvancedOptions.scss';

function AccAdvancedOptions(props) {
  const { values, disabled, handleOnChange, errors, addPlan = true } = props;
  const t = useIntl();

  const getCorrectedLimitValue = (id, value) => {
    if (AppUtils.isFloatNumberInput(value)) return value.replace(',', '.');
    return AppUtils.isFloatNumberInput(values[id]) ? values[id] : null;
  };

  const handleLimitChange = ({ id, value }) => {
    handleOnChange({ id, value: getCorrectedLimitValue(id, value) });
  };

  const renderTextField = ({ id, label }) => {
    return (
      <TextField
        id={id}
        styleName="options__field"
        type="text"
        label={label}
        bordered={true}
        value={!AppUtils.isUndefined(values[id]) ? values[id] : ''}
        error={!!errors[id]}
        helperText={errors[id]}
        onChange={handleLimitChange}
        visibility={disabled ? 'disabled' : 'visible'}
        onKeyPress={(e) => {
          if (e.key === 'e') e.preventDefault();
        }}
      />
    );
  };

  return (
    <Stack size={Stack.SIZE.small}>
      <Label
        styleName="options__label"
        fontWeight="semibold"
        value={t(mes.setupLimits)}
      />
      <Stack styleName="options__limits" horizontal alignItems="center">
        {renderTextField({
          id: 'minLimit',
          label: t(mes.minAccLimit),
        })}
        {renderTextField({
          id: 'maxLimit',
          label: t(mes.maxAccLimit),
        })}
      </Stack>
      <ToggleSwitch
        id="treeCalculation"
        styleName="options__switch"
        label={t(mes.useTreeCalculation)}
        value={values.treeCalculation}
        visibility={disabled ? 'disabled' : 'visible'}
        onChange={handleOnChange}
      />
      <ToggleSwitch
        id="search"
        styleName="options__switch"
        label={t(mes.showAccInSearch)}
        value={values.search}
        visibility={disabled ? 'disabled' : 'visible'}
        onChange={handleOnChange}
      />
      {addPlan ? (
        <>
          <Divider styleName="options__divider" />
          <Label
            styleName="options__label"
            fontWeight="semibold"
            value={t(mes.planAccInfo)}
          />
          <Checkbox
            id="addPlan"
            value={values.addPlan}
            onChange={handleOnChange}
          >
            <Label value={t(mes.createWithPlan)} />
          </Checkbox>
        </>
      ) : null}
    </Stack>
  );
}

AccAdvancedOptions.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  addPlan: PropTypes.bool,
  disabled: PropTypes.bool,
  handleOnChange: PropTypes.func,
};

export default AccAdvancedOptions;
