import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  accLabel: {
    id: 'accLabel',
    defaultMessage: 'Account',
  },
  accNotSelected: {
    id: 'accNotSelected',
    defaultMessage: 'The account has not yet been selected.',
  },
});

export default { ...globalIntl, ...m };
