import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  sharedWith: {
    id: 'sharedWith',
    defaultMessage: 'Shared withs',
  },
  selectAll: {
    id: 'selectAll',
    defaultMessage: 'Select all',
  },
  searchResults: {
    id: 'searchResults',
    defaultMessage: 'Search results',
  },
  searchUserPlaceholder: {
    id: 'searchUserPlaceholder',
    defaultMessage: 'Search by name or email',
  },
  accessRights: {
    id: 'accessRights',
    defaultMessage: 'Access rights',
  },
  view: {
    id: 'view',
    defaultMessage: 'View',
  },
  execute: {
    id: 'execute',
    defaultMessage: 'Execute',
  },
  addUsers: {
    id: 'addUsers_Access_Rules',
    defaultMessage: 'Add users',
  },
});

export default { ...globalIntl, ...m };
