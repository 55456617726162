import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  formAccDisclaimer: {
    id: 'formAccDisclaimer__FormAccounts',
    defaultMessage:
      'Account changes will be applied to all actors with this form.',
  },
  addFormAcc: {
    id: 'addFormAcc__FormAccounts',
    defaultMessage: 'Add account',
  },
  removeFormAcc: {
    id: 'removeFormAcc__FormAccounts',
    defaultMessage: 'Remove account',
  },
  multivalueLabel: {
    id: 'multivalueLabel__FormAccounts',
    defaultMessage: 'Contains value',
  },
  fields: {
    id: 'fields__FormAccounts',
    defaultMessage: 'Fields',
  },
});

export default { ...globalIntl, ...m };
