import { createReducer } from '@reduxjs/toolkit';
import { GET_INVITES, SEND_INVITE, CLEAR_INVITES } from '../constants';

// ------------------------------------
// Reducer
// ------------------------------------
const initState = [];

export default createReducer(initState, {
  [GET_INVITES.SUCCESS](state, action) {
    return action.payload;
  },
  [SEND_INVITE.SUCCESS](state, action) {
    return action.payload;
  },
  [CLEAR_INVITES]() {
    return initState;
  },
});
