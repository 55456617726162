import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  Icon,
  Label,
  Stack,
  Space,
  Card,
  Button,
  Avatar,
  ProgressBar,
} from 'mw-style-react';
import { useIntl } from 'hooks';
import { COMMUNICATIONS_CONNECT } from '@control-front-end/common/constants/communications';
import AppUtils from '@control-front-end/utils/utils';
import LayerExamplePlaceholder from './LayerExamplePlaceholder';
import FormsFields from './FormsFields';
import Block from './Block';
import WidgetModeCardLayout from './WidgetModeCardLayout';
import { ViewDataPropType } from '../propTypes';
import scss from './ActorCardView.scss';
import m from './intl';

function ActorTitle({ title, pictureUrl, compact, design }) {
  const t = useIntl();
  return (
    <Block className={cn(compact ? '' : scss.growItem)} design={design}>
      <Space size="small" fullHeight={!compact}>
        <Stack
          size={compact ? 'small' : 'medium'}
          alignItems="center"
          justifyContent={compact ? 'flexStart' : 'center'}
          fullHeight={!compact}
          horizontal={Boolean(compact)}
        >
          {pictureUrl && compact ? (
            <Avatar className={scss.avatar} size="small" src={pictureUrl} />
          ) : (
            <Icon size={compact ? 'large' : 'xxlarge'} type="actor" />
          )}
          <Label
            className={!compact ? scss.title : null}
            value={title || t(m.actorNamePlaceholder)}
            fontSize={compact ? 'medium' : 'large'}
            fontWeight="semibold"
            overflow={compact ? 'cut' : 'break'}
            textAlign="center"
          />
        </Stack>
      </Space>
    </Block>
  );
}

function Accounts({ accounts, design }) {
  return (
    <Stack horizontal fullWidth size={Stack.SIZE.xxsmall}>
      {accounts.map(
        (
          {
            accountName,
            currencyName,
            displayCurrency,
            amount,
            currencyPrecision,
          },
          index
        ) => {
          let amountToDisplay = '-';
          if (amount !== undefined) {
            amountToDisplay = currencyPrecision
              ? AppUtils.roundToPrecision(amount, currencyPrecision)
              : amount;
          }
          return (
            <Block
              key={index}
              className={scss.proportionalItem}
              design={design}
            >
              <Space size="xsmall" top bottom>
                <Stack size="none" alignItems="center">
                  <Label
                    fontSize="xsmall"
                    value={`${accountName}${
                      displayCurrency ? `, ${currencyName}` : ''
                    }`}
                    overflow="cut"
                  />
                  <Label
                    fontWeight="semibold"
                    value={amountToDisplay}
                    overflow="cut"
                  />
                </Stack>
              </Space>
            </Block>
          );
        }
      )}
    </Stack>
  );
}

function Description({ description, compact, design }) {
  const t = useIntl();
  return (
    <Block
      className={cn(compact ? '' : scss.growItem, scss.description)}
      design={design}
    >
      <Space size="xsmall">
        <Stack size="xxsmall">
          <Label fontSize="xsmall" value="Description" />
          <div
            className={cn(scss.textBlock, scss.descriptionText)}
            dangerouslySetInnerHTML={{
              __html: description
                ? AppUtils.makeDescription({ str: description })
                : t(m.descriptionPlaceholder),
            }}
          />
        </Stack>
      </Space>
    </Block>
  );
}

function Layer({ layer: { layerPicture }, design }) {
  return (
    <Block
      className={cn(scss.growItem, scss.layer)}
      color={Card.COLOR.accent}
      design={design}
    >
      {layerPicture ? (
        <img
          className={cn(scss.layerImage)}
          src={AppUtils.makeAppUrl(`/download/${layerPicture}`)}
          alt="layer"
        />
      ) : (
        <LayerExamplePlaceholder />
      )}
    </Block>
  );
}

function WidgetChat({ onClick }) {
  const t = useIntl();

  return (
    <div>
      <Button
        icon="form"
        label={t(m.widgetButton)}
        fullWidth
        onClick={onClick}
        className={scss.widgetButton}
      />
    </div>
  );
}

const CAN_DISPLAY_BLOCK = {
  TITLE: () => true,
  /**
   * If there are no large blocks, like layer or description and there is actor picture
   * - then leave free space to see this picture on the background
   */
  FREE_SPACE: ({ viewData }) =>
    Boolean(viewData?.actorFields.find(({ itemId }) => itemId === 'picture')) &&
    !viewData?.actorFields.find(({ itemId }) => itemId === 'description') &&
    !viewData?.actors.layer,
  LAYER: ({ viewData }) => Boolean(viewData?.actors.layer),
  ACCOUNTS: ({ viewData }) => Boolean(viewData?.accounts.length),
  FORMS_FIELDS: ({ viewData }) => Boolean(viewData?.formsFields.length),
  DESCRIPTION: ({ viewData }) =>
    Boolean(
      viewData?.actorFields.find(({ itemId }) => itemId === 'description')
    ),
  WIDGET_CHAT: ({ viewData, forceDisplayWidgetSelector }) =>
    Boolean(viewData?.actors.widgetChat) || forceDisplayWidgetSelector,
};

const CARD_BLOCK = {
  TITLE: ({ actor, viewData, pictureUrl }) => (
    <ActorTitle
      key="TITLE"
      compact={Boolean(
        viewData?.actorFields.find(({ itemId }) => itemId === 'picture') ||
          viewData?.actors.layer
      )}
      pictureUrl={pictureUrl}
      title={actor?.title}
      design={viewData.design}
    />
  ),
  FREE_SPACE: () => <div key="FREE_SPACE" className={scss.growItem} />,
  LAYER: ({ viewData }) => (
    <Layer key="LAYER" layer={viewData.actors.layer} design={viewData.design} />
  ),
  ACCOUNTS: ({ viewData }) => (
    <Accounts
      key="ACCOUNTS"
      accounts={viewData.accounts}
      design={viewData.design}
    />
  ),
  FORMS_FIELDS: ({ viewData }) => (
    <FormsFields
      key="FORMS_FIELDS"
      formsFields={viewData.formsFields}
      design={viewData.design}
    />
  ),
  DESCRIPTION: ({ actor, viewData }) => (
    <Description
      key="DESCRIPTION"
      compact={Boolean(viewData?.actors.layer)}
      description={actor?.description}
      design={viewData.design}
    />
  ),
  WIDGET_CHAT: ({ connect, forceDisplayWidgetSelector }) => (
    <WidgetChat
      key="WIDGET_CHAT"
      onClick={forceDisplayWidgetSelector ? () => {} : connect}
    />
  ),
};

function ActorCardView({
  beforeConnect,
  viewData,
  actor,
  forceDisplayWidgetSelector,
  loading,
}) {
  const [widgetMode, setWidgetMode] = useState(false);
  const [widgetLoading, setWidgetLoading] = useState(false);
  const widgetModeRef = useRef(false);

  const dispatch = useDispatch();
  const widgetUrl = useSelector((state) => state.config.widgetUrl);

  const widgetPlaceholderRef = useRef(null);

  const widgetElementId = `CompanyCard_${actor?.ref}`;

  const pictureUrl =
    viewData?.actorFields.find(({ itemId }) => itemId === 'picture') &&
    actor?.picture
      ? AppUtils.makeAppUrl(`/download/${actor.picture}`)
      : null;

  const handleDisplayWidget = useCallback(() => {
    const widgetElement = document.createElement('div');
    widgetElement.id = widgetElementId;

    Object.assign(widgetElement.style, { height: '100%' });
    widgetPlaceholderRef.current.appendChild(widgetElement);

    window.ctrl('webWidget', 'boot', {
      actorId:
        viewData?.actors.widgetChat.actorId || viewData?.actors.widgetChat.id,
      elementId: widgetElementId,
      auth: true,
      whiteLabel: true,
      onBoot: () => {
        setWidgetLoading(false);
        setWidgetMode(true);
      },
    });
    widgetModeRef.current = true;
  }, []);

  const handleHideWidget = useCallback(() => {
    window.ctrl('webWidget', 'destroy', {
      actorId:
        viewData?.actors.widgetChat.actorId || viewData?.actors.widgetChat.id,
      elementId: widgetElementId,
    });
    setWidgetMode(false);
    widgetModeRef.current = false;
  }, []);

  useEffect(() => {
    // Kill widgets for any unmounted card
    return () => (widgetModeRef.current ? handleHideWidget() : null);
  }, []);

  // Start communication and run widget
  const startCommunication = useCallback(() => {
    setWidgetLoading(true);
    dispatch({
      type: COMMUNICATIONS_CONNECT.REQUEST,
      callback: () =>
        window.ctrl
          ? handleDisplayWidget()
          : AppUtils.setControlWidget({
              widgetUrl,
              onLoad: handleDisplayWidget,
            }),
      payload: { toRef: actor?.ref },
    });
  }, []);

  const handleConnectToTheCompany = useCallback(() => {
    if (beforeConnect) {
      beforeConnect({ connect: startCommunication });
    } else {
      startCommunication();
    }
  }, [beforeConnect]);

  return (
    <Card
      {...(pictureUrl
        ? { background: `url('${pictureUrl}') center/cover` }
        : { color: actor?.color || '#BAD5F8' })}
      withBorder={false}
      borderRadius={Card.BORDER_RADIUS.xlarge}
      className={scss.wrap}
    >
      <WidgetModeCardLayout
        hidden={!widgetMode}
        widgetPlaceholderRef={widgetPlaceholderRef}
        title={actor?.title}
        onClose={handleHideWidget}
        pictureUrl={pictureUrl}
      />
      {loading || widgetLoading ? (
        <Stack fullHeight alignItems="center" justifyContent="center">
          <ProgressBar type="circle" size="large" />
        </Stack>
      ) : (
        <Space
          style={widgetMode ? { display: 'none' } : {}}
          size={Space.SIZE.small}
          fullHeight
        >
          <Stack size={Stack.SIZE.xxsmall} fullHeight>
            {Object.keys(CARD_BLOCK)
              .filter((blockName) =>
                CAN_DISPLAY_BLOCK[blockName]({
                  viewData,
                  actor,
                  forceDisplayWidgetSelector,
                })
              )
              .map((blockName) =>
                CARD_BLOCK[blockName]({
                  viewData,
                  actor,
                  connect: handleConnectToTheCompany,
                  forceDisplayWidgetSelector,
                  pictureUrl,
                })
              )}
          </Stack>
        </Space>
      )}
    </Card>
  );
}

ActorCardView.propTypes = {
  actor: PropTypes.shape({
    picture: PropTypes.string,
    color: PropTypes.string,
    description: PropTypes.string,
    title: PropTypes.string,
  }),
  viewData: PropTypes.shape(ViewDataPropType),
  forceDisplayWidgetSelector: PropTypes.bool,
  loading: PropTypes.bool,
};

export default ActorCardView;
