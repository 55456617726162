import {
  SUBSCRIBE_GRAPH,
  UNSUBSCRIBE_ALL_GRAPH,
  SUBSCRIBE_BALANCES,
} from '@control-front-end/common/constants/graphRealtime';
import { createReducer } from '@reduxjs/toolkit';

// ------------------------------------
// Reducer
// ------------------------------------

const initState = {
  layers: [],
  balances: [],
};

export default createReducer(initState, {
  [SUBSCRIBE_GRAPH.SUCCESS](state, action) {
    return { ...state, layers: action.payload };
  },
  [SUBSCRIBE_BALANCES.SUCCESS](state, action) {
    return { ...state, balances: action.payload };
  },
  [UNSUBSCRIBE_ALL_GRAPH.SUCCESS]() {
    return initState;
  },
});
