import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  errorFound: {
    id: 'actorErrorFound',
    defaultMessage: 'Error found',
  },
  errorsFound: {
    id: 'errorsFound',
    defaultMessage: '{count} errors found',
  },
  formNoSections: {
    id: 'formNoSections',
    defaultMessage: 'There are no sections yet.',
  },
  findForm: {
    id: 'findForm',
    defaultMessage: 'Find form by name',
  },
  addForm: {
    id: 'addForm',
    defaultMessage: 'Add form',
  },
  createChildForm: {
    id: 'createChildForm',
    defaultMessage: 'Create child form',
  },
  removeFormWithChildren: {
    id: 'removeFormWithChildren',
    defaultMessage: 'Removing this form will remove its child forms:',
  },
  differentRootError: {
    id: 'differentRootError',
    defaultMessage: 'Selected form has different root form',
  },
});

export default { ...globalIntl, ...m };
