import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Modal, Stack, Button, Space, Label } from 'mw-style-react';

import { PERMISSIONS } from '@control-front-end/common/constants/permissions';
import { checkUserPermissions } from '@control-front-end/app/src/selectors/index';
import { useIntl } from 'hooks';

import m from './intl';

function CompanyCardCreationWarning({ onClose, data: { onSubmit } }) {
  const t = useIntl();

  const checkPermissions = useSelector(checkUserPermissions);

  const canManageCompanyCard = checkPermissions([
    PERMISSIONS.COMPANY_COMMUNICATIONS_MANAGEMENT,
  ]);

  return (
    <Modal onClose={onClose} label={t(m.title)}>
      <Space size={Space.SIZE.large}>
        <Stack size={Stack.SIZE.large}>
          <Label
            textAlign="center"
            value={
              <>
                {t(canManageCompanyCard ? m.text1 : m.alternativeText1)}
                <br />
                <br />
                {t(canManageCompanyCard ? m.text2 : m.alternativeText2)}
              </>
            }
          />
          {canManageCompanyCard ? (
            <Button
              size="large"
              label={t(m.confirmationText)}
              fullWidth
              onClick={onSubmit}
            />
          ) : null}
        </Stack>
      </Space>
    </Modal>
  );
}

CompanyCardCreationWarning.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
  }),
};

export default CompanyCardCreationWarning;
