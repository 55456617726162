import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  availableAmount: {
    id: 'availableAmount',
    defaultMessage: 'Available amount',
  },
  holdAmount: {
    id: 'holdAmount',
    defaultMessage: 'On hold',
  },
});

export default { ...globalIntl, ...m };
