import { createReducer } from '@reduxjs/toolkit';
import {
  FILTER_TRANSFERS,
  CLEAR_TRANSFERS,
  ADD_TRANSFER,
  FILTER_TRANSFERS_REQ_STATUS,
} from '@control-front-end/common/constants/actorAccounts';

// ------------------------------------
// Reducer
// ------------------------------------

const initState = {
  list: [],
  limit: 15,
  offset: 0,
  total: 0,
  init: false,
  reqStatus: 'success',
  endList: false,
};

export default createReducer(initState, {
  [FILTER_TRANSFERS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [FILTER_TRANSFERS_REQ_STATUS](state, action) {
    return { ...state, reqStatus: action.payload };
  },
  [ADD_TRANSFER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [CLEAR_TRANSFERS]() {
    return initState;
  },
});
