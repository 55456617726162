import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  manageActors: {
    id: 'manageActors',
    defaultMessage: 'Manage actors',
  },
  noActorsInEvent: {
    id: 'noActorsInEvent',
    defaultMessage: 'There are no actors in this event yet',
  },
});

export default { ...globalIntl, ...m };
