import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'mw-style-react';
import useIntl from 'useIntl';
import SelectAccountCurrency from '@control-front-end/common/components/SelectAccountCurrency';
import SelectAccountName from '@control-front-end/common/components/SelectAccountName';
import mes from '../TransactionsFiltersOptions/intl';
import './FilterAccPair.scss';

/**
 * Component for selecting account name & currency pair
 * @param allowNoneValue,
 * @param defaultAccName,
 * @param defaultCurrency,
 * @param handleSelect
 * @param handleClose
 * @returns {JSX.Element}
 * @constructor
 */
function FilterAccPair({
  allowNoneValue = false,
  defaultAccName,
  defaultCurrency,
  handleSelect,
  handleClose,
}) {
  const t = useIntl();
  const [currency, setCurrency] = useState(defaultCurrency);
  const [accName, setAccName] = useState(defaultAccName);

  const handleSelectAccountPair = () => {
    if (!accName || !currency) return;
    const newPair = {
      nameId: accName.id,
      accountName: accName.title,
      currencyId: currency.id,
      currencyName: currency.title,
      currencyPrecision: currency.precision,
      currencyType: currency.type,
      currencySymbol: currency.symbol,
    };
    handleSelect({ value: newPair });
  };

  return (
    <div styleName="pair">
      <div styleName="pair__block">
        <SelectAccountName
          bordered={true}
          hideCreateBtn={true}
          autoFocus={!accName}
          label={t(mes.account)}
          placeholder={t(mes.selectAccName)}
          resultLabel={t(mes.freqUsedNames)}
          value={accName}
          allowNoneValue={allowNoneValue}
          onChange={(newAccName) => {
            setAccName(newAccName);
            setCurrency(null);
          }}
        />
      </div>
      <div styleName="pair__block">
        <SelectAccountCurrency
          bordered={true}
          hideCreateBtn={true}
          label={t(mes.currency)}
          placeholder={t(mes.selectCurrency)}
          resultLabel={t(mes.availableCurrencies)}
          nameId={accName ? accName.id : null}
          value={currency}
          allowNoneValue={allowNoneValue}
          isDisabled={!accName}
          onChange={(newCurrency) => setCurrency(newCurrency)}
        />
      </div>
      <div styleName="pair__actions">
        <Button
          size="smallplus"
          type="text"
          label={t(mes.cancel)}
          fontWeight="normal"
          onClick={handleClose}
        />
        <Button
          size="smallplus"
          label={t(mes.apply)}
          fontWeight="normal"
          onClick={handleSelectAccountPair}
          visibility={currency?.id ? 'visible' : 'disabled'}
        />
      </div>
    </div>
  );
}

FilterAccPair.propTypes = {
  allowNoneValue: PropTypes.bool,
  defaultAccName: PropTypes.object,
  defaultCurrency: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default FilterAccPair;
