import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  setColor: {
    id: 'setColor',
    defaultMessage: 'Set custom color',
  },
  addAvatar: {
    id: 'addAvatar',
    defaultMessage: 'Add avatar',
  },
  clearAvatar: {
    id: 'clearAvatar',
    defaultMessage: 'Clear avatar',
  },
  resetColor: {
    id: 'resetColor',
    defaultMessage: 'Reset to form color',
  },
});

export default { ...globalIntl, ...m };
