import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  addConnector: {
    id: 'addConnector',
    defaultMessage: 'Add connector',
  },
  replaceConnector: {
    id: 'replaceConnector',
    defaultMessage: 'Replace connector',
  },
  selectAccountType: {
    id: 'selectAccountType',
    defaultMessage: 'Please select a type of account',
  },
  connectorsNotFound: {
    id: 'connectorsNotFound',
    defaultMessage: 'No connectors found',
  },
  selectConnector: {
    id: 'selectConnector',
    defaultMessage: 'Select connector',
  },
});

export default { ...globalIntl, ...m };
