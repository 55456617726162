import { createReducer } from '@reduxjs/toolkit';
import {
  ACCOUNTS,
  GET_ACTIVE_ACC,
  SET_ACTIVE_ACC,
  SET_NEW_ACC,
} from '../constants';

const initState = {
  list: [],
  active: null,
  newAccount: null,
  limit: 10,
  offset: 0,
  endList: false,
  reqStatus: 'inProgress',
};

export default createReducer(initState, {
  [ACCOUNTS.SUCCESS](state, action) {
    return {
      ...state,
      ...action.payload,
      reqStatus: 'success',
    };
  },
  [GET_ACTIVE_ACC.SUCCESS](state, action) {
    return {
      ...state,
      active: action.payload.id,
      activeAccount: action.payload,
      newAccount: null,
    };
  },
  [SET_ACTIVE_ACC](state, action) {
    return {
      ...state,
      active: action.payload.id,
      activeAccount: action.payload,
      newAccount: null,
    };
  },
  [SET_NEW_ACC](state, action) {
    return {
      ...state,
      newAccount: action.payload,
    };
  },
});
