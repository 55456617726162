import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames/bind';
import { Utils } from 'mw-style-react';
import './Row.scss';

class Row extends PureComponent {
  // Отрисовать элементы в строке
  renderItems({
    formId,
    items,
    elm,
    onChange,
    onSubmit,
    getErrorMsg,
    getFormData,
  }) {
    // Получить сумму всех весов элементов
    const sumWidth = this.getSumWidth(items);
    const rItems = [];
    let index = 0;
    for (const item of items) {
      if (!item.class) continue;
      const ItemName = Utils.toUpperLatter(item.class);
      const Item = elm[ItemName];
      if (!Item) continue;
      const w = item.w || 1;
      const relW = (w / sumWidth) * 100;
      const style = {
        width: `${relW}%`,
      };
      rItems.push(
        <div
          key={`row_${item.id}_${index}`}
          styleName="row__item"
          style={style}
        >
          <Item
            onChange={onChange.bind(this)}
            onSubmit={onSubmit.bind(this)}
            getErrorMsg={getErrorMsg.bind(this)}
            getFormData={getFormData.bind(this)}
            formId={formId}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...item}
          />
        </div>
      );
      index += 1;
    }
    return rItems;
  }

  // Возвращает сумму всех весов элементов
  getSumWidth(items) {
    return items.reduce((acc, i) => {
      const w = i.w ? i.w : 1;
      return acc + w;
    }, 0);
  }

  render() {
    const { formId, items, elm, onChange, onSubmit, getErrorMsg, getFormData } =
      this.props;
    const hidden =
      items.every((i) => i.visibility === 'hidden') || items.length === 0;
    return (
      <div
        styleName={cn('row', { hidden })}
        ref={(el) => {
          // eslint-disable-next-line react/no-unused-class-component-methods
          this.row = el;
        }}
      >
        {this.renderItems({
          formId,
          items,
          elm,
          onChange,
          onSubmit,
          getErrorMsg,
          getFormData,
        })}
      </div>
    );
  }
}

Row.propTypes = {
  formId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  items: PropTypes.array.isRequired,
  elm: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  getErrorMsg: PropTypes.func.isRequired,
  getFormData: PropTypes.func.isRequired,
};

export default Row;
