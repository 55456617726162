import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  newPicture: {
    id: 'newPicture',
    defaultMessage: 'New Picture',
  },
  whiteboardDiscl: {
    id: 'whiteboardDiscl',
    defaultMessage: 'Click space to erase draw',
  },
  saveAndClose: {
    id: 'saveAndClose',
    defaultMessage: 'Save and close',
  },
});

export default { ...globalIntl, ...m };
