import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import flatten from 'lodash/flatten';
import keyBy from 'lodash/keyBy';
import { useIntl, useNotifications } from 'hooks';
import {
  TextField,
  Button,
  Modal,
  ModalButtons,
  ModalContent,
  ProgressBar,
  Divider,
  Stack,
  Label,
  Card,
  Space,
} from 'mw-style-react';
import {
  CREATE_FIELDS_TRANSFER,
  NEW_TRANSFER,
  ADD_TRANSFER,
} from '@control-front-end/common/constants/actorAccounts';
import { GET_ITEMS_BY_IDS } from '@control-front-end/common/constants/graphActors';
import TransferSourceSettings from './TransferSourceSettings';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import m from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import l from './CreateTransfer.scss'; // NOSONAR
import TransferTargetsSettings from './TransferTargetsSettings';

const isItemFilled = (item) =>
  Boolean(item.actor?.id && item.field?.id && item.value?.length);

function CreateFormFieldTransferModal({ visibility, onClose, isSubmit, data }) {
  const t = useIntl();

  const dispatch = useDispatch();

  const [comment, setComment] = useState(data.comment || '');
  const { showNotification } = useNotifications();

  // Currently only one source item is available for form field transfers via interface
  const firstSourceItemProp = data.from?.[0];

  const newTransfer = useSelector((state) => state.newFormFieldTransfer);

  // Prefilling form fields from porps
  useEffect(() => {
    if (!firstSourceItemProp || !data.to) return;

    dispatch({
      type: GET_ITEMS_BY_IDS.REQUEST,
      payload: {
        fullModel: true,
        itemsToSearch: {
          actors: [
            firstSourceItemProp.actorId,
            ...data.to.map(({ actorId }) => actorId),
          ],
        },
      },
      callback: (actors) => {
        dispatch({
          type: NEW_TRANSFER.FORM_FIELD.SET,
          payload: {
            from: {
              actor: actors.find(
                ({ id }) => id === firstSourceItemProp.actorId
              ),
              field: null,
              value: [],
            },
            to: actors
              .filter(({ id }) => id !== firstSourceItemProp.actorId)
              .map((actor) => ({ actor, field: null, value: [] })),
          },
        });
      },
    });
  }, [firstSourceItemProp, data.to]);

  const canMakeTransfer = useMemo(() => {
    return isItemFilled(newTransfer.from) && newTransfer.to.some(isItemFilled);
  }, [newTransfer]);

  const makeTransfer = ({ callback }) => {
    // If there is values that wasn't passet to target actors - then leave them at source actor
    const allValuesToTransfer = keyBy(
      flatten(newTransfer.to.map(({ value }) => value)),
      'value'
    );
    const payload = {
      from: [
        {
          formId: newTransfer.from.field.formId,
          actorId: newTransfer.from.actor.id,
          fieldId: newTransfer.from.field.id,
          value: newTransfer.from.value
            .filter(({ value }) => allValuesToTransfer[value])
            .map(({ value }) => value),
        },
      ],
      to: newTransfer.to.filter(isItemFilled).map((item) => {
        return {
          formId: item.field.formId,
          actorId: item.actor.id,
          fieldId: item.field.id,
          value: item.value.map(({ value }) => value),
        };
      }),
      comment,
    };

    dispatch({
      type: CREATE_FIELDS_TRANSFER.REQUEST,
      payload,
      callback: (data) => {
        showNotification('success', t(mes.transferCreated));
        callback(data);
      },
    });
  };

  return (
    <Modal
      styleName="m.modal l.modal"
      size="xlarge"
      onClose={(...args) => {
        /**
         * TODO: Remove to leave edited transfer saving.
         * But issue with validathin described in CE-7168 should be resolved
         */
        dispatch({ type: NEW_TRANSFER.FORM_FIELD.CLEAR });
        onClose(...args);
      }}
      label={t(mes.makeTransfer)}
      visibility={visibility}
    >
      <ModalContent
        id="modalContent"
        styleName="m.modal__content l.modal__content"
      >
        <Stack.V>
          <Stack.H fullWidth>
            <Label
              style={{ flex: 1 }}
              value={t(mes.actorFrom)}
              fontWeight="semibold"
            />
            <Label
              style={{ flex: 1 }}
              value={t(mes.actorTo)}
              fontWeight="semibold"
            />
          </Stack.H>
          <Stack.H fullWidth>
            <div style={{ width: 'calc(50% - 8px)' }}>
              <Card>
                <Space>
                  <TransferSourceSettings
                    initialFieldId={firstSourceItemProp?.fieldId}
                  />
                </Space>
              </Card>
            </div>
            <TransferTargetsSettings
              initialFieldIds={data.to?.map(({ fieldId }) => fieldId)}
              style={{ width: 'calc(50% - 8px)' }}
            />
          </Stack.H>
          <Divider />
          <TextField
            styleName="m.textfield"
            size="large"
            bordered
            multiline
            multilineType="text"
            rows={2}
            value={comment}
            label={t(mes.comment)}
            onChange={({ value }) => setComment(value)}
          />
        </Stack.V>
      </ModalContent>
      <ModalButtons styleName="m.modal__buttons">
        <Button
          label={t(mes.makeTransfer)}
          onClick={() =>
            makeTransfer({
              callback: (newTransfer) => {
                onClose();
                dispatch({ type: NEW_TRANSFER.FORM_FIELD.CLEAR });
                dispatch({
                  type: ADD_TRANSFER.REQUEST,
                  payload: { transfer: newTransfer },
                });
              },
            })
          }
          visibility={canMakeTransfer ? 'visible' : 'disabled'}
          disabled={!canMakeTransfer}
        />
        <ProgressBar
          styleName="m.modal__loader"
          type="circle"
          size="small"
          visibility={isSubmit ? 'visible' : 'hidden'}
        />
      </ModalButtons>
    </Modal>
  );
}

CreateFormFieldTransferModal.propTypes = {
  visibility: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  isSubmit: PropTypes.bool,
  data: PropTypes.shape({
    from: PropTypes.arrayOf(PropTypes.object),
    to: PropTypes.arrayOf(PropTypes.object),
    comment: PropTypes.string,
  }).isRequired,
};

export default CreateFormFieldTransferModal;
