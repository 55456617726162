export const PERMISSIONS = {
  ACTORS_MANAGEMENT: 'actors:management',
  SYSTEM_ACTORS_MANAGEMENT: 'system_actors:management',
  EVENTS_READONLY: 'bets:readonly',
  ACCOUNTS_READONLY: 'accounts:readonly',
  ACCOUNTS_MANAGEMENT: 'accounts:management',
  TRANSACTIONS_READONLY: 'transactions:readonly',
  TRANSFERS_READONLY: 'transfers:readonly',
  TRANSFERS_MANAGEMENT: 'transfers:management',
  COMMUNICATIONS_READONLY: 'communications:readonly',
  COMPANY_COMMUNICATIONS_READONLY: 'company_communications:readonly',
  COMPANY_COMMUNICATIONS_MANAGEMENT: 'company_communications:management',
  SCRIPTS_READONLY: 'scripts:readonly',
  SCRIPTS_MANAGEMENT: 'scripts:management',
  FORMS_READONLY: 'forms:readonly',
  FORMS_MANAGEMENT: 'forms:management',
  SETTINGS_READONLY: 'settings:readonly',
  SETTINGS_MANAGEMENT: 'settings:management',
  IMPORT_EXPORT_MANAGEMENT: 'import_export:management',
  USERS_READONLY: 'viewUsers',
};
