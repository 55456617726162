import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  accountIdInfo: {
    id: 'accountIdInfo',
    defaultMessage: 'Account ID:',
  },
  correctiveDate: {
    id: 'correctiveDate',
    defaultMessage: 'Select transaction date',
  },
  correctiveAmount: {
    id: 'correctiveAmount',
    defaultMessage: 'Correction value',
  },
  totalAfterCorrective: {
    id: 'totalAfterCorrective',
    defaultMessage: 'After correction',
  },
});

export default { ...globalIntl, ...m };
