import {
  GET_STREAMS,
  CLEAR_STREAMS,
  SET_ACTIVE_STREAM,
  ADD_STREAM,
  HIDE_STREAM,
  UPDATE_STREAM,
  STREAM_ACCESS,
  SYSTEM_STREAMS,
  GET_STREAMS_UNREAD_COUNTERS,
  READ_ALL_STREAM,
} from '@control-front-end/common/constants/streams';
import { createReducer } from '@reduxjs/toolkit';

// ------------------------------------
// Reducer
// ------------------------------------

const initState = {
  list: SYSTEM_STREAMS,
  active: 'all',
  limit: 100,
  offset: 0,
  total: 0,
  init: false,
  reqStatus: 'success',
  endList: false,
};

export default createReducer(initState, {
  [GET_STREAMS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [GET_STREAMS_UNREAD_COUNTERS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [ADD_STREAM.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [HIDE_STREAM.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [UPDATE_STREAM.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [STREAM_ACCESS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [READ_ALL_STREAM.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SET_ACTIVE_STREAM](state, action) {
    return { ...state, active: action.payload };
  },
  [CLEAR_STREAMS]() {
    return initState;
  },
});
