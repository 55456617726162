import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import pickBy from 'lodash/pickBy';

import { Stack, useObjectState } from 'mw-style-react';
import SelectActorsTemplate from '@control-front-end/common/components/SelectActorsTemplate';
import getFormFieldsMap from '@control-front-end/utils/getFormFieldsMap';

import FormFieldSelector from '../FormFieldSelector';

function SelectFormAndField({ value, filterFields, onChange, ...rest }) {
  const [state, setObjectState, setState] = useObjectState(
    { form: value?.form || {}, field: value?.field || {} },
    { onChange }
  );

  const fields = useMemo(
    () => (state.form.id ? getFormFieldsMap(state.form) : {}),
    [state.form.id]
  );

  return (
    <Stack.H fullWidth {...rest}>
      <div style={{ flex: 1 }}>
        <SelectActorsTemplate
          unspaced
          bordered
          value={value?.form || state.form}
          placeholder="Select Form"
          withDefault={false}
          withRelations={true}
          fullModel={true}
          formTypes="all"
          onChange={({ value }) => {
            setState({
              form: value,
              field: {},
            });
          }}
        />
      </div>
      <div style={{ flex: 1 }}>
        <FormFieldSelector
          size="medium"
          onChange={setObjectState.field}
          visibility={state.form.id ? 'visible' : 'disabled'}
          fields={pickBy(fields, filterFields)}
          value={value?.field || state.field}
          popoverOptions={{ topLevel: false }}
        />
      </div>
    </Stack.H>
  );
}

SelectFormAndField.defaultProps = {
  filterFields: (fields) => fields,
};

SelectFormAndField.propTypes = {
  value: PropTypes.shape({
    form: PropTypes.object,
    field: PropTypes.object,
  }),
  filterFields: PropTypes.func,
  onChange: PropTypes.func,
};

export default SelectFormAndField;
