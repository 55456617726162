import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  createTransaction: {
    id: 'createTransaction_CreateFormFieldTransactionModal',
    defaultMessage: 'Create transaction',
  },
  transactionCreated: {
    id: 'transactionCreated_CreateFormFieldTransactionModal',
    defaultMessage: 'Transaction has been successfully created.',
  },
  makeTransaction: {
    id: 'makeTransaction_CreateFormFieldTransactionModal',
    defaultMessage: 'Make transaction',
  },
  cantUpdateSystemActor: {
    id: 'cantUpdateSystemActor_CreateFormFieldTransactionModal',
    defaultMessage: 'System actors fields cannot be updated by transaction.',
  },
  requiredField: {
    id: 'requiredField_CreateFormFieldTransactionModal',
    defaultMessage: 'Field is required',
  },
  incorrectData: {
    id: 'incorrectData_CreateFormFieldTransactionModal',
    defaultMessage: 'Incorrect data',
  },
});

export default { ...globalIntl, ...m };
