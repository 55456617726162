import React, { useState } from 'react';
import useIntl from 'useIntl';
import { Icon, TextField } from 'mw-style-react';
import mes from './intl';
import './ParamItem.scss';

function ParamItem({ param, onChange }) {
  const t = useIntl();
  const { id, key, value: initValue } = param;
  const [paramKey, setParamKey] = useState(key);
  const [paramValue, setParamValue] = useState(
    typeof initValue === 'object' ? JSON.stringify(initValue) : initValue
  );

  const parseValue = (value) => {
    let result;
    try {
      result = JSON.parse(value);
    } catch (e) {
      result = value;
    }
    return result;
  };

  const handleKeyChange = ({ value }) => {
    setParamKey(value);
  };

  const handleKeyBlur = () => {
    if (key && !paramKey) {
      setParamKey(key);
      return;
    }
    if (paramKey === key) return;
    onChange({ id, action: 'set key', value: paramKey });
  };

  const handleValueChange = ({ value }) => {
    const newValue = parseValue(value);
    setParamValue(value);
    onChange({ id, action: 'set value', value: newValue });
  };

  const handleDelClick = () => {
    onChange({ id, action: 'delete' });
  };

  return (
    <div id={id} styleName="param">
      <TextField
        styleName="param__field"
        label={t(mes.labelKey)}
        value={paramKey}
        onChange={handleKeyChange}
        bordered={true}
        onBlur={handleKeyBlur}
      />
      <TextField
        styleName="param__field"
        label={t(mes.labelValue)}
        value={paramValue}
        onChange={handleValueChange}
        bordered={true}
      />
      <div onClick={handleDelClick}>
        <Icon styleName="param__delete" type="trash" />
      </div>
    </div>
  );
}

export default ParamItem;
