import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useIntl from 'useIntl';
import {
  Chip,
  Chips,
  DateUtils,
  Label,
  Modal,
  ModalContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ProgressBar,
} from 'mw-style-react';
import { useModal } from 'hooks';
import { DATE_FORMAT } from 'constants';
import ActorAvatar from '@control-front-end/common/components/ActorAvatar';
import AppUtils from '@control-front-end/utils/utils';
import { GET_ACCOUNT_ACTORS } from '@control-front-end/common/constants/actorAccounts';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import sModal from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import sLocal from './ActorTriggers.scss'; // NOSONAR

/**
 * List of actor's account triggers with details
 * @param data
 * @param visibility
 * @param onClose
 * @returns {JSX.Element}
 * @constructor
 */
function ActorTriggers({ data, visibility, onClose }) {
  const { actor, currency, account } = data;
  const t = useIntl();
  const dispatch = useDispatch();
  const systemForms = useSelector((state) => state.systemForms);
  const triggersFormId = systemForms.accounttriggers.id;
  const [triggersActors, setTriggersActors] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { open: openTriggerCallsModal } = useModal('TriggerHistory', {
    actors: [actor],
    accounts: [account],
  });

  // Get actual triggers for account
  const getTriggers = useCallback(({ nameId, currencyId }) => {
    setLoading(true);
    dispatch({
      type: GET_ACCOUNT_ACTORS.REQUEST,
      payload: {
        nameId,
        currencyId,
        formId: triggersFormId,
      },
      callback: (data) => {
        setTriggersActors(data.map(({ actor }) => ({ ...actor })));
        setLoading(false);
      },
    });
  }, []);

  useEffect(() => {
    getTriggers(account);
  }, [account]);

  const handleShowTriggerCalls = useCallback(({ id, title }) => {
    openTriggerCallsModal({ trigger: { id, title } });
  }, []);

  const renderTriggerRow = (item) => {
    const {
      id,
      title,
      color,
      pictureUrl,
      data = {},
      accessDenied = false,
    } = item;
    if (accessDenied)
      return (
        <TableRow key={id} styleName="table__row">
          <TableCell styleName="table__cell long">
            <Chip
              label={t(mes.accessDenied)}
              type="rectangular"
              closeIcon={false}
            />
          </TableCell>
        </TableRow>
      );
    const {
      valueType,
      accountIncomeType,
      endDate,
      upperValue,
      lowerValue,
      periodName,
      periodValue,
    } = data;
    const triggerValue = valueType[0];
    const triggerPeriod = periodName[0] || {};
    const isAmountTrigger = triggerValue.value === 'amount';
    return (
      <TableRow
        key={id}
        styleName="table__row"
        onClick={() => handleShowTriggerCalls({ id, title })}
      >
        <TableCell styleName="table__cell long">
          <ActorAvatar
            size="small"
            type="compact"
            icon={!pictureUrl ? 'trigger' : null}
            pictureUrl={pictureUrl}
            color={color}
            colorFilled={true}
          />
          <Label value={title} />
        </TableCell>
        <TableCell styleName="table__cell long">{triggerValue.title}</TableCell>
        <TableCell styleName="table__cell medium">
          <Chips spaceSize={Chips.SIZE.xxsmall}>
            {accountIncomeType.map((i) => (
              <Label key={i.value} color={Label.COLOR.black} value={i.title} />
            ))}
          </Chips>
        </TableCell>
        <TableCell styleName="table__cell medium">
          {DateUtils.toDate(endDate.startDate, DATE_FORMAT)}
        </TableCell>
        <TableCell styleName="table__cell compact">
          {isAmountTrigger
            ? AppUtils.formattedAmount(lowerValue, currency)
            : lowerValue}
        </TableCell>
        <TableCell styleName="table__cell compact">
          {isAmountTrigger
            ? AppUtils.formattedAmount(upperValue, currency)
            : upperValue}
        </TableCell>
        <TableCell styleName="table__cell compact">
          {triggerPeriod.title}
        </TableCell>
        <TableCell styleName="table__cell compact">{periodValue}</TableCell>
      </TableRow>
    );
  };

  const renderList = () => (
    <Table styleName="table" paddingSize="small">
      <TableHead styleName="table__head" fixed={true}>
        <TableRow styleName="table__row" rowSize="small">
          <TableCell styleName="table__cell long">{t(mes.name)}</TableCell>
          <TableCell styleName="table__cell long">{t(mes.valueType)}</TableCell>
          <TableCell styleName="table__cell medium">
            {t(mes.triggerIncomeType)}
          </TableCell>
          <TableCell styleName="table__cell medium">
            {t(mes.triggerEndDate)}
          </TableCell>
          <TableCell styleName="table__cell compact">
            {t(mes.lowerValue)}
          </TableCell>
          <TableCell styleName="table__cell compact">
            {t(mes.upperValue)}
          </TableCell>
          <TableCell styleName="table__cell compact">
            {t(mes.periodName)}
          </TableCell>
          <TableCell styleName="table__cell compact">
            {t(mes.periodValue)}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{triggersActors.map(renderTriggerRow)}</TableBody>
    </Table>
  );

  return (
    <Modal
      styleName="sModal.modal sLocal.modal"
      visibility={visibility}
      onClose={onClose}
      label={t(mes.actorsTriggers, { actorTitle: actor.title })}
      size="xlarge"
    >
      <ModalContent styleName="sModal.modal__content">
        {isLoading ? (
          <div styleName="sLocal.loader">
            <ProgressBar type="circle" size="large" />
          </div>
        ) : (
          renderList()
        )}
      </ModalContent>
    </Modal>
  );
}

ActorTriggers.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.shape({
    actor: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    currency: PropTypes.object.isRequired,
  }).isRequired,
};

export default ActorTriggers;
