import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  Icon,
  MenuItem,
  Select,
  TextField,
  ColorPicker,
} from 'mw-style-react';
import { useIntl } from 'hooks';
import SelectActors from '@control-front-end/common/components/SelectActors';
import { INCOME_TYPE } from '@control-front-end/common/constants/actorAccounts';
import SelectActorsAccountWithNotify from './components/SelectActorsAccountWithNotify';
import mes from './intl';
import scss from './ChartAccountSelect.scss';

/**
 * Account item to display on chart
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function ChartAccountSelect(props) {
  const {
    item,
    error,
    isDisabled = false,
    isRemovable = true,
    showLabels = true,
    onChange,
    onRemove,
    defaultAccount,
  } = props;
  const t = useIntl();
  const [account, setAccount] = useState(item.account || defaultAccount || {});

  const handleSelectAccount = (value) => {
    const theSameAccount =
      account.nameId === value?.nameId &&
      Number(account.currencyId) === Number(value?.currencyId);

    if (account && value && !theSameAccount) {
      setAccount(value);
      onChange({
        ...item,
        account: value,
        incomeType: item.incomeType || INCOME_TYPE.total,
      });
    }
  };

  useEffect(() => {
    if (defaultAccount) {
      setAccount(defaultAccount);
      onChange({
        ...item,
        account: defaultAccount,
        incomeType: item.incomeType || INCOME_TYPE.total,
      });
    }
  }, [defaultAccount]);

  const actorAccessDenied = item.actor?.accessDenied;
  return (
    <Stack.H
      styleName="chart__source"
      fullWidth
      alignItems="center"
      size={Stack.SIZE.xsmall}
    >
      <TextField
        className={scss.colorPickerField}
        placeholder={t(mes.selectColor)}
        value={item.color || ''}
        bordered
        maskOptions={{
          mask: '#RRGGBB',
          blocks: {
            RRGGBB: {
              mask: /^[0-9a-f]{0,6}$/i,
            },
          },
        }}
        onChange={({ value }) =>
          onChange({
            ...item,
            color: value,
          })
        }
        error={false}
        colorPicker={() => (
          <ColorPicker
            value={item.color}
            onSave={(value) =>
              onChange({
                ...item,
                color: value.hex,
              })
            }
            disableAlpha
          />
        )}
      />
      <SelectActors
        type="modal"
        size="medium"
        bordered
        multiselect={false}
        fieldClassName={scss.actorSelectField}
        unspaced={!showLabels}
        label={showLabels ? t(mes.actor) : ''}
        placeholder={t(mes.selectActor)}
        value={item.actor}
        manageLayer={false}
        hideCreateBtn
        fullModel
        isDisabled={isDisabled}
        onChange={({ value }) => {
          setAccount(defaultAccount || {});
          onChange({
            ...item,
            actor: value,
            account: defaultAccount || undefined,
          });
        }}
      />
      <SelectActorsAccountWithNotify
        key={item?.actor?.id || null}
        size="medium"
        actorId={item.actor?.id || null}
        nameId={(defaultAccount || item.account || {}).nameId}
        currencyId={(defaultAccount || item.account || {}).currencyId}
        error={error}
        fieldClassName={scss.accountSelectField}
        incomeType={item.incomeType}
        liveSearch
        visibility={defaultAccount || !item?.actor?.id ? 'disabled' : 'visible'}
        title={
          defaultAccount
            ? `${defaultAccount.accountName}, ${defaultAccount.currencyName}`
            : null
        }
        unspaced={!showLabels}
        label={showLabels ? t(mes.account) : ''}
        withPrivs={false}
        onChange={handleSelectAccount}
        preselectedAccount={item.account}
      />
      <Select
        id="incomeType"
        styleName="chart__source__type"
        className={scss.typeSelectField}
        bordered
        unspaced={!showLabels}
        label={showLabels ? t(mes.type) : ''}
        value={item.incomeType}
        onChange={({ value }) =>
          onChange({ ...item, account, incomeType: value })
        }
        visibility={
          item.actor && account && !actorAccessDenied ? 'visible' : 'disabled'
        }
        popoverOnTop
      >
        <MenuItem value={INCOME_TYPE.total} label={t(mes.total)} />
        <MenuItem value={INCOME_TYPE.debit} label={t(mes.debit)} />
        <MenuItem value={INCOME_TYPE.credit} label={t(mes.credit)} />
      </Select>
      <div
        onClick={() => {
          if (isRemovable) onRemove();
        }}
      >
        <Icon
          styleName="chart__source__remove"
          type="trash"
          visibility={isRemovable ? 'visible' : 'disabled'}
        />
      </div>
    </Stack.H>
  );
}

ChartAccountSelect.propTypes = {
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  onRemove: PropTypes.func,
  isDisabled: PropTypes.bool,
  showLabels: PropTypes.bool,
  isRemovable: PropTypes.bool,
  defaultAccount: PropTypes.shape({
    nameId: PropTypes.string,
    currencyId: PropTypes.number,
    accountName: PropTypes.string,
    currencyName: PropTypes.string,
  }),
};

export default ChartAccountSelect;
