import { defineMessages } from 'react-intl';

const m = defineMessages({
  inviteUsers: {
    id: 'inviteUsers_InviteMembersModal',
    defaultMessage: 'Invite users',
  },
});

export default m;
