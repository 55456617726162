import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Label, ToggleSwitch, Stack, Space, ProgressBar } from 'mw-style-react';
import { useIntl } from 'hooks';
import FormUtils from '@control-front-end/utils/formUtils';
import OpacitySlider from '@control-front-end/common/components/OpacitySlider';
import ActorCardViewSettings from './ActorCardViewSettings';
import ActorCardView from '../ActorCardView';
import { ViewDataPropType } from '../propTypes';
import m from './intl';

const viewDataStub = {
  actorFields: [],
  formsFields: [],
  accounts: [],
  actors: { layer: null },
  design: { blockOpacity: 0.8 },
};

function ActorCardViewPreview({
  actor,
  viewData,
  forms,
  forceDisplayWidgetSelector,
}) {
  const formsData = useMemo(
    () =>
      forms.reduce(
        (acc, { form, id: formId }) => ({
          ...acc,
          ...Object.entries(FormUtils.getFormData(form) || {}).reduce(
            (acc, [itemId, value]) => ({
              ...acc,
              [`${formId}${itemId}`]: value,
            }),
            {}
          ),
        }),
        {}
      ),
    [forms]
  );

  const previewViewData = useMemo(
    () => ({
      ...viewData,
      formsFields: viewData.formsFields.map((item) => ({
        ...item,
        value:
          formsData[`${item.formId}${item.itemId}`] === undefined
            ? item.value
            : formsData[`${item.formId}${item.itemId}`],
      })),
    }),
    [formsData, viewData]
  );

  return (
    <ActorCardView
      actor={actor}
      viewData={previewViewData}
      forceDisplayWidgetSelector={forceDisplayWidgetSelector}
    />
  );
}

function ActorCardViewEditor({
  actor,
  forms,
  viewData: viewDataProp,
  onChange,
  loading,
  previewMode,
  togglePreviewMode,
  forceDisplayWidgetSelector,
}) {
  const viewData = viewDataProp || viewDataStub;

  const t = useIntl();

  const handleOpacityChange = useCallback(
    (opacityPercentage) =>
      onChange({
        viewData: {
          ...viewData,
          design: {
            ...viewData.design,
            blockOpacity: opacityPercentage / 100,
          },
        },
      }),
    [viewData]
  );

  const layerFormId = useSelector((state) => state.systemForms.layers.id);

  return loading ? (
    <Stack fullWidth fullHeight alignItems="center" justifyContent="center">
      <ProgressBar type="circle" size="large" />
    </Stack>
  ) : (
    <Space bottom size={Space.SIZE.xlarge}>
      <Stack size={Stack.SIZE.xlarge}>
        <Stack horizontal fullWidth justifyContent="spaceBetween">
          <Label
            color={Label.COLOR.black}
            fontWeight="semibold"
            value={t(m.actorsCard)}
          />
          <ToggleSwitch
            value={previewMode}
            onChange={togglePreviewMode}
            label={t(m.preview)}
          />
        </Stack>
        {previewMode ? (
          <Stack alignItems="center">
            <OpacitySlider
              value={
                typeof viewData.design?.blockOpacity === 'number'
                  ? viewData.design.blockOpacity * 100
                  : undefined
              }
              onChange={handleOpacityChange}
              style={{ width: '320px' }}
            />
            <ActorCardViewPreview
              actor={actor}
              viewData={viewData}
              forms={forms}
              forceDisplayWidgetSelector={forceDisplayWidgetSelector}
            />
          </Stack>
        ) : (
          <ActorCardViewSettings
            viewData={viewData}
            actor={actor}
            onChange={onChange}
            actorForms={forms}
            layerFormId={layerFormId}
          />
        )}
      </Stack>
    </Space>
  );
}

ActorCardViewEditor.propTypes = {
  actor: PropTypes.shape({
    picture: PropTypes.string,
    color: PropTypes.string,
    description: PropTypes.string,
    title: PropTypes.string,
    ref: PropTypes.string,
  }),
  forms: PropTypes.array.isRequired,
  previewMode: PropTypes.bool,
  togglePreviewMode: PropTypes.func,
  viewData: PropTypes.shape(ViewDataPropType),
  onChange: PropTypes.func.isRequired,
  forceDisplayWidgetSelector: PropTypes.bool,
};

export default ActorCardViewEditor;
