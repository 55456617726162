import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useModal } from 'hooks';
import AppUtils from '@control-front-end/utils/utils';
import { Label, Icon, Stack } from 'mw-style-react';
import cn from 'classnames';
import './FilesDiffValue.scss';

/**
 * Actor files change row
 * @param props
 * @returns {*}
 * @constructor
 */
function FilesDiffValue(props) {
  const { open: openFilePreview } = useModal('FilePreview', {});
  const prevValue = Array.isArray(props.prevValue) ? props.prevValue : [];
  const nextValue = Array.isArray(props.nextValue) ? props.nextValue : [];

  const addedIds = useMemo(
    () =>
      AppUtils.diffArrayObj(nextValue, prevValue, 'id').map((file) => file.id),
    [prevValue, nextValue]
  );
  const removedIds = useMemo(
    () =>
      AppUtils.diffArrayObj(prevValue, nextValue, 'id').map((file) => file.id),
    [prevValue, nextValue]
  );

  const renderFile = (file) => {
    const { title: fileTitle, type, fileName } = file;
    const filePath =
      file.filePath || AppUtils.makeAppUrl(`/download/${fileName}`);
    const isImage = type?.includes('image');
    return (
      <Stack
        key={`${props.id}_${file.id}`}
        styleName={cn('filesDiff__item', {
          removed: removedIds.includes(file.id),
          new: addedIds.includes(file.id),
        })}
        horizontal
        alignItems="center"
        size={Stack.SIZE.xsmall}
        onClick={() => openFilePreview({ ...file, files: [file] })}
      >
        <div styleName="filesDiff__item__wrap">
          {isImage ? (
            <img src={filePath} alt="" />
          ) : (
            <div styleName="filesDiff__item__file">
              <Icon type="file" />
            </div>
          )}
        </div>
        <Label overflow="cut" value={fileTitle} />
      </Stack>
    );
  };

  const renderFilesDiff = useCallback(
    (list) => (
      <Stack styleName="filesDiff__content" size={Stack.SIZE.xxsmall}>
        {list.length ? list.map((i) => renderFile(i)) : '-'}
      </Stack>
    ),
    [prevValue, nextValue]
  );

  return (
    <Stack horizontal fullWidth styleName="filesDiff">
      {renderFilesDiff(prevValue)}
      {renderFilesDiff(nextValue)}
    </Stack>
  );
}

FilesDiffValue.propTypes = {
  prevValue: PropTypes.any,
  nextValue: PropTypes.any,
};

export default FilesDiffValue;
