import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  constr: {
    id: 'constr',
    defaultMessage: 'Constructor',
  },
  preview: {
    id: 'preview',
    defaultMessage: 'Preview',
  },
  saveAndGo: {
    id: 'saveAndGo',
    defaultMessage: 'Save and go',
  },
  noFormActorsAccess: {
    id: 'noFormActorsAccess',
    defaultMessage:
      'The ability to share actors via the form editor is available only for UAT forms.',
  },
  noFormActorsAccessInfo: {
    id: 'noFormActorsAccessInfo',
    defaultMessage:
      // eslint-disable-next-line max-len
      'To share actors with this form, you need open access to the form in the "Form access" section and share actor access in the actor panel, ' +
      'or via mass actions in the Actors bag.',
  },
});

export default { ...globalIntl, ...m };
