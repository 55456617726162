import keyBy from 'lodash/keyBy';

const getActorFieldsMap = (actor) => {
  return keyBy(
    actor.forms.reduce(
      (
        acc,
        { id: formId, title: formTitle, color: formColor, isRoot, sections }
      ) => {
        acc.push(
          ...sections.reduce((acc, { title, content }) => {
            acc.push(
              ...content.reduce((acc, actorField) => {
                acc.push({
                  ...actorField,
                  key: actorField.id,
                  sectionTitle: title,
                  form: {
                    isRoot,
                    id: formId,
                    title: formTitle,
                    color: formColor,
                  },

                  // TODO: Remove fields below, use form.fieldName instead
                  formId,
                  formTitle,
                  formColor,
                });
                return acc;
              }, [])
            );
            return acc;
          }, [])
        );
        return acc;
      },
      []
    ),
    'id'
  );
};

export default getActorFieldsMap;
