import { useDispatch } from 'react-redux';
import { SET_MODAL, PM_APP_NAME } from 'constants';
import { GET_SYSTEM_ACTOR } from '@control-front-end/common/constants/graphActors';

/**
 * Ф-ции по работе со модальным окном актора
 */
const useActorModalActions = () => {
  const dispatch = useDispatch();

  /**
   * Открыть в iframe актор
   * @param actorId
   */
  const handleOpenActorModal = (actorId) => {
    const data = { actorId };
    if (window.frameElement) {
      window.parent.postMessage(
        { appName: PM_APP_NAME, type: 'UPDATE_ACTOR_IFRAME', data },
        '*'
      ); // NOSONAR
    } else {
      dispatch({
        type: SET_MODAL,
        payload: { name: 'ActorModalView', data },
      });
    }
  };

  /**
   * Открыть в iframe системный актор
   * @param objId
   * @param objType
   * @param errorCallback
   */
  const handleOpenSystemActor = ({
    objId,
    objType = 'user',
    errorCallback,
  }) => {
    dispatch({
      type: GET_SYSTEM_ACTOR.REQUEST,
      payload: { objType, objId },
      callback: (actor) => {
        const data = { actorId: actor.id };
        if (window.frameElement) {
          window.parent.postMessage(
            { appName: PM_APP_NAME, type: 'UPDATE_ACTOR_IFRAME', data },
            '*'
          ); // NOSONAR
        } else {
          dispatch({
            type: SET_MODAL,
            payload: { name: 'ActorModalView', data },
          });
        }
      },
      errorCallback,
    });
  };

  return { handleOpenActorModal, handleOpenSystemActor };
};

export default useActorModalActions;
