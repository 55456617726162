import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  prev: {
    id: 'prev',
    defaultMessage: 'Previous',
  },
  next: {
    id: 'next',
    defaultMessage: 'Next',
  },
});

export default { ...globalIntl, ...m };
