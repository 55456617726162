import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { VALENCY_LINKER_TYPES } from '@control-front-end/common/constants/graphActors';
import ValencyLinker from '@control-front-end/app/src/components/Valency/ValencyLinker';
import { makeGetActiveLayer } from '@control-front-end/app/src/selectors/graphs';

function ValencySelectorNodeMenu({ onToggle, type }) {
  const activeLayer = useSelector(makeGetActiveLayer) || {};

  return (
    <ValencyLinker
      targetId={activeLayer.id}
      type={type}
      onCancel={() => onToggle(false)}
      onSuccess={() => onToggle(false)}
    />
  );
}

ValencySelectorNodeMenu.propTypes = {
  type: PropTypes.oneOf(Object.values(VALENCY_LINKER_TYPES)).isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default ValencySelectorNodeMenu;
