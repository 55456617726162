import PropTypes from 'prop-types';
import React from 'react';
import { Select, MenuItem, Divider } from 'mw-style-react';
import { useIntl } from 'hooks';
import { FIELD_TYPE } from '@control-front-end/common/constants/forms';
import mes from '../../intl';
import './BaseItem.scss';

const ICON_BY_FIELD_TYPE = {
  check: 'checkbox_on',
  radio: 'radio_on',
  select: 'dropdown',
  multiSelect: 'list',
  edit: 'textfield',
  label: 'paragraph',
  link: 'link',
  button: 'button',
  image: 'picture',
  upload: 'attach',
  calendar: 'calendar',
};

const MENU_SECTIONS = [
  [
    FIELD_TYPE.check,
    FIELD_TYPE.radio,
    FIELD_TYPE.select,
    FIELD_TYPE.multiSelect,
  ],
  [FIELD_TYPE.edit, FIELD_TYPE.label, FIELD_TYPE.link],
  [FIELD_TYPE.button, FIELD_TYPE.image, FIELD_TYPE.upload, FIELD_TYPE.calendar],
];

function FieldTypeSelector({ type, ...rest }) {
  const t = useIntl();

  return (
    <Select bordered value={type} leftIcon={ICON_BY_FIELD_TYPE[type]} {...rest}>
      {MENU_SECTIONS.flatMap((menuItems, index) => {
        const items = menuItems.map((fieldType) => (
          <MenuItem
            key={fieldType}
            value={fieldType}
            label={t(mes[`itemClass_${fieldType}`])}
            leftIcon={ICON_BY_FIELD_TYPE[fieldType]}
          />
        ));
        return index < MENU_SECTIONS.length - 1
          ? [...items, <Divider key={index} />]
          : items;
      })}
    </Select>
  );
}

FieldTypeSelector.propTypes = {
  onChange: PropTypes.func,
  type: PropTypes.oneOf(Object.keys(FIELD_TYPE)),
};

export default FieldTypeSelector;
