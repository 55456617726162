import PropTypes from 'prop-types';
import React from 'react';
import AppUtils from '@control-front-end/utils/utils';
import OptionsSource from '../OptionsSource/OptionsSource';
import SelectOptions from '../SelectOptions/SelectOptions';
import Edit from '../Edit/Edit';
import './Select.scss';

/**
 * Component for Select form constructor item
 */
function Select(props) {
  const { extra, className, onChange } = props;
  const needOptions =
    !extra?.optionsSource || extra?.optionsSource?.type === 'manual';
  const isRadio = className === 'radio';

  const handleInitOptions = () => {
    onChange({
      fieldId: 'options',
      value: [{ value: AppUtils.createRid(), title: '' }],
    });
  };

  return (
    <div styleName="select">
      <div styleName="select__title">
        <Edit {...props} />
      </div>
      {!isRadio ? (
        <OptionsSource {...props} handleInitOptions={handleInitOptions} />
      ) : null}
      {needOptions ? (
        <SelectOptions {...props} handleInitOptions={handleInitOptions} />
      ) : null}
    </div>
  );
}

Select.propTypes = {
  id: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  extra: PropTypes.object,
  isActive: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default Select;
