import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  addedToExport: {
    id: 'addedToExport',
    defaultMessage: 'Added to export',
  },
  removeFromExport: {
    id: 'removeFromExport',
    defaultMessage: 'Remove from export',
  },
  formsExportListEmpty: {
    id: 'formsExportListEmpty',
    defaultMessage: 'List of forms for export is empty',
  },
  actorsExportListEmpty: {
    id: 'actorsExportListEmpty',
    defaultMessage: 'List of actors for export is empty',
  },
});

export default { ...globalIntl, ...m };
