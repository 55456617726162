import { createReducer } from '@reduxjs/toolkit';
import { GET_GROUPS, SET_GROUPS_REQ_STATUS, CLEAR_GROUPS } from '../constants';

// ------------------------------------
// Reducer
// ------------------------------------
const initState = {
  list: [],
  limit: 15,
  offset: 0,
  total: 0,
  reqStatus: 'success',
  endList: false,
};

export default createReducer(initState, {
  [GET_GROUPS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SET_GROUPS_REQ_STATUS](state, action) {
    return { ...state, reqStatus: action.payload };
  },
  [CLEAR_GROUPS]() {
    return initState;
  },
});
