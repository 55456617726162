import React from 'react';
import PropTypes from 'prop-types';
import {
  DateUtils,
  Stack,
  Label,
  Space,
  Card,
  Button,
  Tooltip,
} from 'mw-style-react';

import { useIntl } from 'hooks';
import UsersAvatars from '@control-front-end/common/components/UsersAvatars';
import { privs } from '@control-front-end/common/constants/propTypes';
import { DATE_FORMAT_4, CALENDAR_VIEWS } from 'constants';

import Description from '../Description';

import mes from './intl';
import scss from '../../EventsCalendar.scss';

const formatEventTimeRange = (startUnixtime, endUnixtime) => {
  const startFormatted = DateUtils.toDate(startUnixtime, DATE_FORMAT_4);
  const endFormatted = endUnixtime
    ? DateUtils.toDate(endUnixtime, DATE_FORMAT_4)
    : '';

  return `${startFormatted} - ${endFormatted}`;
};

function EventMenu({
  users = [],
  onClose,
  title,
  startUnixtime,
  endUnixtime,
  description,
  onRemove,
  eventId,
  onOpenEvent,
  onClone,
  calendarViewType,
  privs,
  accId,
}) {
  const t = useIntl();
  const isMonthGrid = calendarViewType === CALENDAR_VIEWS.dayGridMonth;

  return (
    <>
      {!isMonthGrid ? <div className={scss.fullscreenOverlay} /> : null}
      <Card className={scss.eventMenu} boxShadow="intense">
        <Space size={Space.SIZE.small}>
          <Stack.V size={Space.SIZE.xsmall}>
            <Stack.V size={Space.SIZE.micro}>
              <Stack.H size={Space.SIZE.xxsmall} style={{ alignSelf: 'end' }}>
                <Tooltip value={t(mes.openEvent)} className={scss.eventTooltip}>
                  <Button
                    icon="link_external"
                    type="quinary"
                    size="xsmall"
                    onClick={() => onOpenEvent(eventId)}
                  />
                </Tooltip>
                <Tooltip
                  value={t(mes.cloneEvent)}
                  className={scss.eventTooltip}
                >
                  <Button
                    icon="copy"
                    type="quinary"
                    size="xsmall"
                    onClick={() => {
                      onClose();
                      onClone(eventId);
                    }}
                  />
                </Tooltip>
                <Tooltip
                  value={privs.remove ? '' : t(mes.removeEventPrivsError)}
                  className={scss.eventTooltip}
                >
                  <div>
                    <Button
                      icon="trash"
                      type="quinary"
                      size="xsmall"
                      disabled={!privs.remove}
                      onClick={() => {
                        onClose();
                        onRemove(eventId);
                      }}
                    />
                  </div>
                </Tooltip>
                <Button
                  icon="close"
                  type="quinary"
                  size="xsmall"
                  onClick={onClose}
                />
              </Stack.H>
              <Label
                value={title}
                textColor="black"
                fontWeight="semibold"
                fontSize="large"
                overflow="cut"
                style={{
                  minHeight: '1.2em',
                  height: 'auto',
                  WebkitLineClamp: 3,
                }}
              />
              <Label
                value={formatEventTimeRange(startUnixtime, endUnixtime)}
                textColor="accent"
                fontSize="small"
              />
            </Stack.V>
            <Stack.V size={Space.SIZE.micro}>
              <Label
                value={t(mes.participants)}
                textColor="black"
                fontWeight="semibold"
                fontSize="small"
              />
              <UsersAvatars users={users} />
            </Stack.V>
            <Stack.V size={Space.SIZE.micro}>
              <Label
                value={t(mes.description)}
                textColor="black"
                fontWeight="semibold"
                fontSize="small"
              />
              {description ? (
                <Description description={description} accId={accId} />
              ) : null}
            </Stack.V>
          </Stack.V>
        </Space>
      </Card>
    </>
  );
}

EventMenu.propTypes = {
  users: PropTypes.array,
  title: PropTypes.string.isRequired,
  startUnixtime: PropTypes.number.isRequired,
  endUnixtime: PropTypes.number,
  description: PropTypes.string,
  eventId: PropTypes.string.isRequired,
  onOpenEvent: PropTypes.func.isRequired,
  onClone: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  calendarViewType: PropTypes.string.isRequired,
  accId: PropTypes.string,
  privs,
};

export default EventMenu;
