import { useDispatch } from 'react-redux';
import { NOTIFY_LEVEL, SHOW_NOTIFY } from 'constants';
import AppUtils from '@control-front-end/utils/utils';
import { mapKeys, mapValues } from 'lodash';

/**
 * Hook for notifications
 */
function useNotifications() {
  const dispatch = useDispatch();

  const showNotification = (type, message) => {
    dispatch({
      type: SHOW_NOTIFY.REQUEST,
      payload: {
        id: AppUtils.createRid(),
        type: NOTIFY_LEVEL[type.toUpperCase()],
        label: message,
      },
    });
  };

  return {
    showNotification, // deprecated, use showNotification with level-key
    ...mapValues(
      mapKeys(NOTIFY_LEVEL, (value, key) => key.toLowerCase()),
      (level) => {
        return (message) => showNotification(level, message);
      }
    ),
  };
}

export default useNotifications;
