import React from 'react';
import UploadBase from '@control-front-end/common/components/Upload';

/**
 * Компонент Upload
 */
function Upload({ onFormItemLoading, ...props }) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <UploadBase styleType="form" setLoading={onFormItemLoading} {...props} />
  );
}

export default Upload;
