import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { cr } from 'mw-style-react';
import { SET_MODAL } from 'constants';
import { useIntl, useSearchList } from 'hooks';
import {
  GET_ACCOUNT_CURRENCIES,
  GET_ACCOUNT_CURRENCY,
  GET_ACCOUNT_NAME_CURRENCIES,
  SEARCH_ACCOUNT_CURRENCY,
  NONE_VALUE,
} from '../../constants/actorAccounts';
import SelectEntityField from '../SelectEntityField';
import mes from './intl';

/**
 * Currencies select field
 */
function SelectAccountCurrency(props) {
  const {
    exclude = [],
    nameId = null,
    allowNoneValue = false,
    isDisabled,
    placeholder,
  } = props;
  const t = useIntl();
  const dispatch = useDispatch();
  const [stateValue, setStateValue] = useState(props.value);
  const [query, setQuery] = useState('');
  const [skipSearch, setSkipSearch] = useState(true);

  const {
    list: currencies,
    loading,
    makeSearch,
    onScroll,
  } = useSearchList({
    actionType: (sParams) =>
      cr(
        [sParams.query?.length, SEARCH_ACCOUNT_CURRENCY.REQUEST],
        [
          sParams.nameId && sParams.nameId !== NONE_VALUE.id,
          GET_ACCOUNT_NAME_CURRENCIES.REQUEST,
        ],
        [true, GET_ACCOUNT_CURRENCIES.REQUEST]
      ),
    skip: !nameId && skipSearch,
    searchParams: { query, nameId },
    settings: { step: 15 },
    localState: true,
  });

  useEffect(() => {
    if (!nameId) return;
    makeSearch({ nameId });
  }, [nameId]);

  useEffect(() => {
    const { value } = props;
    if (value?.value && !value.title) {
      dispatch({
        type: GET_ACCOUNT_CURRENCY.REQUEST,
        payload: value.id,
        callback: ({ id, title, name, type, precision, symbol }) =>
          setStateValue({
            id,
            title: title || name,
            type,
            precision,
            symbol,
          }),
      });
    }
  }, []);

  useEffect(() => {
    if (stateValue && !props.value) setStateValue(null);
  }, [props.value]);

  const handleSearch = (value) => {
    const q = value.trimStart();
    setQuery(q);
    makeSearch({ query: q });
  };

  const handleCreateClick = ({ value }) => {
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'CreateCurrency',
        data: {
          name: value || '',
          title: t(mes.createAccCurrency),
          btnText: t(mes.save),
          minLength: 1,
        },
        callback: (currency) => {
          setStateValue(currency);
          props.onChange({ ...currency, isNew: true });
        },
      },
    });
  };

  const makeAvailableList = (list) => {
    const availableList = list.filter(
      (i) => !exclude.includes(i.id.toString())
    );
    return allowNoneValue ? [NONE_VALUE, ...availableList] : availableList;
  };

  const availableCurrencies = makeAvailableList(currencies);
  const noPairCurrencies = !!nameId && !availableCurrencies.length;
  return (
    <SelectEntityField
      {...props}
      objType="currency"
      list={availableCurrencies}
      placeholder={noPairCurrencies ? t(mes.noPairCurrencies) : placeholder}
      isDisabled={noPairCurrencies || isDisabled}
      onSearch={handleSearch}
      onFocus={() => setSkipSearch(false)}
      onBlur={() => setQuery('')}
      onSearchListScroll={onScroll}
      onChange={({ value }) => {
        setStateValue(value);
        props.onChange(value);
      }}
      onCreate={handleCreateClick}
      value={stateValue}
      isLoading={loading}
    />
  );
}

SelectAccountCurrency.propTypes = {
  value: PropTypes.object,
  nameId: PropTypes.string,
  exclude: PropTypes.array,
  allowNoneValue: PropTypes.bool,
};

export default SelectAccountCurrency;
