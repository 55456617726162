import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  h_formTitle: {
    id: 'h_formTitle',
    defaultMessage: 'Form:',
  },
  h_fieldTitle: {
    id: 'h_fieldTitle',
    defaultMessage: 'Field:',
  },
  h_field: {
    id: 'h_field',
    defaultMessage: 'Field ID:',
  },
});

export default { ...globalIntl, ...m };
