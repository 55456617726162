import store from '@control-front-end/app/src/store';
import { SET_ACTIVE_ACTOR } from '@control-front-end/common/constants/graphActors';
import { WINDOW_FOCUSED } from '@control-front-end/common/constants';
import history from '@control-front-end/app/src/store/history';
import mes from '@control-front-end/app/src/components/Notifications/intl';
import getTranslation from '@control-front-end/utils/getTranslation';
import AppUtils from './utils';

/**
 * Уведомления ползователей в браузере
 * @type {Object}
 */
const TabNotify = {
  WINDOW_FOCUSED: true,

  titleCounter: 0,

  // Запросить права на уведомления
  requestNotifyPopup() {
    if (!('Notification' in window)) return;
    if (Notification.permission === 'granted') return;
    // Проверка разрешения на отправку уведомлений
    if (Notification.permission !== 'denied') {
      Notification.requestPermission();
    }
  },

  // Показать уведомление в браузере
  setPopup(notifyModel) {
    if (this.WINDOW_FOCUSED) return;
    // Проверка поддержки браузером уведомлений
    if (!('Notification' in window)) return;
    if (Notification.permission !== 'granted') return;

    const title = `${getTranslation(
      mes[
        `notify_alt_${
          notifyModel.type === 'comment' && notifyModel.appId
            ? 'script'
            : notifyModel.type
        }`
      ]
    )} ${notifyModel.ownerName}`;
    const formattedDescription = AppUtils.removeHtmlTags(
      AppUtils.removeNewLines(AppUtils.removeBbCode(notifyModel.description)),
      true
    );
    const notification = new Notification(notifyModel.title, {
      body: `${title}${
        notifyModel.description ? ':\n' : ''
      }${formattedDescription}`,
      icon: notifyModel.ownerAvatar,
    });
    notification.onclick = () => {
      window.parent.focus();
      const isEventsRoute =
        history.location.pathname.indexOf('/events/') !== -1;
      const { plainMode } = store.getState().settings;
      if (isEventsRoute && plainMode) {
        setTimeout(() => {
          store.dispatch({
            type: SET_ACTIVE_ACTOR,
            payload: { id: notifyModel.actorId },
          });
        }, 0);
      } else {
        history.push(notifyModel.url);
      }
    };
    setTimeout(() => {
      notification.close();
    }, 5000);
  },

  // старт прослушивания фокуса страницы
  listenTabFocus() {
    let Winhidden;
    let Winstate;
    let WinvisibilityChange;
    if (typeof document.hidden !== 'undefined') {
      Winhidden = 'hidden';
      WinvisibilityChange = 'visibilitychange';
      Winstate = 'visibilityState';
    } else if (typeof document.mozHidden !== 'undefined') {
      Winhidden = 'mozHidden';
      WinvisibilityChange = 'mozvisibilitychange';
      Winstate = 'mozVisibilityState';
    } else if (typeof document.msHidden !== 'undefined') {
      Winhidden = 'msHidden';
      WinvisibilityChange = 'msvisibilitychange';
      Winstate = 'msVisibilityState';
    } else if (typeof document.webkitHidden !== 'undefined') {
      Winhidden = 'webkitHidden';
      WinvisibilityChange = 'webkitvisibilitychange';
      Winstate = 'webkitVisibilityState';
    }

    document.addEventListener(
      WinvisibilityChange,
      () => {
        const state = document[Winstate];
        if (Winhidden === state || state === 'hidden') {
          this.WINDOW_FOCUSED = false;
        } else {
          this.WINDOW_FOCUSED = true;
        }

        store.dispatch({
          type: WINDOW_FOCUSED,
          payload: this.WINDOW_FOCUSED,
        });
      },
      false
    );

    window.addEventListener('blur', () => {
      this.WINDOW_FOCUSED = false;
      store.dispatch({
        type: WINDOW_FOCUSED,
        payload: false,
      });
    });

    window.addEventListener('focus', () => {
      this.WINDOW_FOCUSED = true;
      store.dispatch({
        type: WINDOW_FOCUSED,
        payload: true,
      });
    });
  },

  // изменение тайтла при новом комменте
  // в неактивном фокусе вкладки
  updateTitle() {
    if (this.WINDOW_FOCUSED) return;
    this.titleCounter += 1;
    if (this.TITLE_INTERVAL) return;
    const fixedPageTitle = document.title;
    this.TITLE_INTERVAL = setInterval(() => {
      if (this.WINDOW_FOCUSED) {
        if (this.TITLE_INTERVAL) clearInterval(this.TITLE_INTERVAL);
        document.title = fixedPageTitle;
        this.TITLE_INTERVAL = null;
        this.titleCounter = 0;
        return;
      }
      if (document.title === fixedPageTitle) {
        document.title = `${this.titleCounter} notifications`;
      } else {
        document.title = fixedPageTitle;
      }
    }, 1000);
  },
};

export default TabNotify;
