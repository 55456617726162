import {
  GET_FILES,
  CLEAR_FILES,
  SEARCH_FILES,
  SET_FILES_REQ_STATUS,
} from 'constants';
import { createReducer } from '@reduxjs/toolkit';

// ------------------------------------
// Reducer
// ------------------------------------

const initState = {
  list: [],
  limit: 15,
  offset: 0,
  loadCount: 0,
  totalTasks: 0,
  reqStatus: 'success',
  endList: false,
};

export default createReducer(initState, {
  [GET_FILES.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SEARCH_FILES.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SET_FILES_REQ_STATUS](state, action) {
    return { ...state, reqStatus: action.payload };
  },
  [CLEAR_FILES]() {
    return initState;
  },
});
