import {
  RequestStatus,
  GET_ACCESS_RULES,
  MANAGE_ACCESS_RULES,
  BULK_MANAGE_ACCESS_RULES,
  SEND_INVITE,
  CANCEL_INVITE,
  UPDATE_INVITE,
  BULK_MANAGE_PAIRS_ACCESS_RULES,
  ACCESS_RULES_ACTIONS,
} from 'constants';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import api from '@control-front-end/common/sagas/api';
import AppUtils from '@control-front-end/utils/utils';
import apiBulk from './apiBulk';

/**
 * Отправить инвайты
 */
function* handleCreateInvites({ objType, objId, invites }) {
  const invitations = invites
    .filter(({ action }) => action === 'create')
    .map(({ name, roleId, privs }) => ({
      email: name,
      login: name,
      roleId,
      objects: [{ objId, objType, privs }],
    }));
  if (!invitations.length) return;
  yield put({
    type: SEND_INVITE.REQUEST,
    payload: { invitations },
  });
}

/**
 * Удалить инвайты
 */
function* handleDeleteInvites({ invites }) {
  const invitations = invites
    .filter(({ action }) => action === 'delete')
    .map(({ inviteId }) => inviteId);
  if (!invitations.length) return;
  for (const inviteId of invitations) {
    yield put({
      type: CANCEL_INVITE.REQUEST,
      payload: inviteId,
    });
  }
}

/**
 * Обновить инвайты
 */
function* handleUpdateInvites({ objType, objId, invites }) {
  const invitations = invites
    .filter(({ action }) => action === 'update')
    .map(({ inviteId, privs }) => ({ inviteId, objId, objType, privs }));
  if (!invitations.length) return;
  for (const invite of invitations) {
    yield put({
      type: UPDATE_INVITE.REQUEST,
      payload: invite,
    });
  }
}

/**
 * Редактирование настроек доступа
 */
export function* manageAccessRules({ payload, callback }) {
  const { body, objType, objId, recursive } = payload;
  const config = yield select((state) => state.config);
  const accessR = body.filter((i) => !!i.action);
  const access = accessR.filter((i) => !i.isInvite);
  const invites = accessR.filter((i) => !!i.isInvite);
  const resultInvites = body
    .filter(
      (i) =>
        !!i.isInvite && (!i.action || i.action !== ACCESS_RULES_ACTIONS.delete)
    )
    .map(({ action, ...other }) => other);
  if (invites.length) {
    yield handleCreateInvites({ objType, objId, invites });
    yield handleUpdateInvites({ objType, objId, invites });
    yield handleDeleteInvites({ invites });
  }
  if (access.length) {
    const { result, data } = yield call(api, {
      method: 'post',
      url: `/access_rules/${objType}/${objId}`,
      queryParams: { recursive, notify: true, filterIncorrect: true },
      body: access.map(({ action, userType, userId, saId, privs }) => {
        const noPrivs = Object.keys(privs).every((level) => !privs[level]);
        return {
          action: noPrivs ? ACCESS_RULES_ACTIONS.delete : action,
          data: {
            privs,
            ...(userType === 'group' ? { groupId: saId } : { userId }),
          },
        };
      }),
    });
    if (result !== RequestStatus.SUCCESS) return;
    for (const a of data.data) {
      a.avatar = AppUtils.makeUserAvatar(a, config);
    }
    yield put({ type: MANAGE_ACCESS_RULES.SUCCESS, payload: data.data });
    if (callback) callback(data.data, resultInvites);
    return data.data;
  }
  const resultAccess = body.filter((i) => !i.isInvite);
  if (callback) callback(resultAccess, resultInvites);
  return resultAccess;
}

/**
 * Массовое редактирование настроек доступа
 */
export function* bulkManageAccessRules({ payload, callback }) {
  const config = yield select((state) => state.config);
  const responses = yield call(apiBulk, {
    method: 'post',
    url: '/access_rules/bulk',
    body: payload,
  });
  const success = responses.every((r) => r.result === RequestStatus.SUCCESS);
  if (!success) return;
  const newRules = responses.reduce((acc, i) => acc.concat(i.data.data), []);
  for (const obj of newRules) {
    obj.forEach((a) => {
      a.avatar = AppUtils.makeUserAvatar(a, config);
    });
  }
  yield put({ type: MANAGE_ACCESS_RULES.SUCCESS, payload: newRules });
  if (callback) callback(newRules);
}

/**
 * Массовое редактирование настроек доступа для пар счетов
 */
export function* bulkManagePairsAccessRules({ payload, callback }) {
  const { active: accId } = yield select((state) => state.accounts);
  const responses = yield call(apiBulk, {
    method: 'post',
    url: `/access_rules/bulk/accounts_pairs/${accId}`,
    body: payload,
  });
  const success = responses.every((r) => r.result === RequestStatus.SUCCESS);
  if (!success) return;
  if (callback) callback();
}

/**
 * Получение настроек доступа
 */
function* getAccessRules({ payload, callback, errorCallback }) {
  const config = yield select((state) => state.config);
  const { objType, objId } = payload;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/access_rules/${objType}/${objId}`,
    handleErrCodes: [403],
  });
  if (result === RequestStatus.SUCCESS) {
    for (const a of data.data) {
      a.avatar = AppUtils.makeUserAvatar(a, config);
    }
    yield put({ type: GET_ACCESS_RULES.SUCCESS, payload: data.data });
    if (callback) callback(data.data);
  } else if (errorCallback && data.statusCode === 403) {
    errorCallback();
  }
}

function* accessRules() {
  yield takeLatest(MANAGE_ACCESS_RULES.REQUEST, manageAccessRules);
  yield takeLatest(BULK_MANAGE_ACCESS_RULES.REQUEST, bulkManageAccessRules);
  yield takeLatest(
    BULK_MANAGE_PAIRS_ACCESS_RULES.REQUEST,
    bulkManagePairsAccessRules
  );
  yield takeLatest(GET_ACCESS_RULES.REQUEST, getAccessRules);
}

export default accessRules;
