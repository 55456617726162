import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  usersChipResult: {
    id: 'usersChipResult',
    defaultMessage: 'Users',
  },
  actorsChipResult: {
    id: 'actorsChipResult',
    defaultMessage: 'Actors',
  },
  eventsChipResult: {
    id: 'eventsChipResult',
    defaultMessage: 'Events',
  },
  graphsChipResult: {
    id: 'graphsChipResult',
    defaultMessage: 'Graphs',
  },
  noResultsFound: {
    id: 'noResultsFound',
    defaultMessage: 'No results',
  },
  noSuggestions: {
    id: 'noSuggestions',
    defaultMessage: 'No recent entities',
  },
});

export default { ...globalIntl, ...m };
