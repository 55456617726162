import { createReducer } from '@reduxjs/toolkit';
import { SET_LOCATION } from 'constants';

// ------------------------------------
// Reducer
// ------------------------------------

function makeLocation(pathname) {
  const pathParts = pathname.split('/');
  if (!pathParts[2]) return '';
  return pathParts[2];
}

const initState = '';

export default createReducer(initState, {
  [SET_LOCATION](state, action) {
    return makeLocation(action.payload);
  },
});
