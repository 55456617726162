// eslint-disable-next-line import/no-cycle
import AppUtils from '../utils';

export default {
  getRangePosition(selection, range) {
    const elBoundingRects = range.getClientRects();
    const rect =
      elBoundingRects[0] || selection.anchorNode.getBoundingClientRect();
    const { left: x, top: y } = rect;
    return { x, y };
  },

  setCursorToNodeStart(selection, range, node) {
    range = range.cloneRange();
    range.selectNode(node);
    range.setStart(node, 0);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
  },

  addChipField(selection, range) {
    const parentNode = range.commonAncestorContainer.parentNode;
    if (parentNode.className === 'searchChip') {
      const textNode = document.createTextNode(parentNode.innerText);
      parentNode.replaceWith(textNode);
      range.setStartAfter(textNode);
    }
    const el = document.createElement('div');
    el.innerHTML = '<div class="searchChip"/>';
    range.deleteContents();
    const frag = document.createDocumentFragment();
    const node = el.firstChild;
    const lastNode = frag.appendChild(node);
    range.insertNode(frag);
    if (lastNode) {
      AppUtils.setCursorToNodeStart(selection, range, lastNode);
    }
  },
};
