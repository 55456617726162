import { useState, useEffect } from 'react';
import { Utils } from 'mw-style-react';

/**
 * A custom hook that filters an array of items based on a search filter
 * and returns a filtered list of items and a function to update the filter value.
 *
 * @param {Array} itemsProp - The array of items to filter.
 * @param {Function} handleFilterProp - The function that defines how to filter each item.
 * @param {Object} options - An object with options for the filter.
 * @param {number} options.debounce - The number of milliseconds to debounce the filter.
 * @param {number} options.maxAmount - The maximum number of items to show.
 * @return {[Array, Function]} An array containing the filtered items and a function to update the filter value.
 */
const useFilter = (itemsProp, handleFilterProp, { debounce, maxAmount }) => {
  const [filter, setFilter] = useState();
  const [items, setItems] = useState(itemsProp);

  useEffect(() => {
    Utils.debounce(() => {
      const filteredItems = filter
        ? itemsProp.filter((item) => handleFilterProp(item, filter))
        : itemsProp;
      setItems(maxAmount ? filteredItems.slice(0, maxAmount) : filteredItems);
    }, debounce)();
  }, [itemsProp, filter, debounce]);

  return [items, setFilter];
};

export default useFilter;
