import { defineMessages } from 'react-intl';

const m = defineMessages({
  absentAccount: {
    id: 'absentAccount_SelectActorsAccountWithNotify',
    defaultMessage:
      'This account is absent on the actor. Use existing accounts for building accurate dashboards.',
  },
  accessDeniedActor: {
    id: 'accessDeniedActor_SelectActorsAccountWithNotify',
    defaultMessage:
      "Maybe, you don't have access to this actor, or it did not exist yet.",
  },
});

export default m;
