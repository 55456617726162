import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalContent } from 'mw-style-react';
import { useIntl } from 'hooks';
import mes from './intl';
import Transactions from './Transactions';
// eslint-disable-next-line no-unused-vars
import sModal from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import sLocal from './TransactionsHistory.scss'; // NOSONAR

function TransactionsHistory({ data, onClose, visibility }) {
  const t = useIntl();
  return (
    <Modal
      styleName="sModal.modal sLocal.modal"
      size="xlarge"
      onClose={onClose}
      label={`${t(mes.transactionsHistory)}: ${data.accountName} (${
        data.currencyName
      })`}
      visibility={visibility}
    >
      <ModalContent styleName="sModal.modal__content sLocal.modal__content">
        <Transactions {...data} />
      </ModalContent>
    </Modal>
  );
}

TransactionsHistory.propTypes = {
  visibility: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object,
};

export default TransactionsHistory;
