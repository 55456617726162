import { createReducer } from '@reduxjs/toolkit';
import {
  GET_ACTORS_ACCOUNTS_GROUPS,
  SET_ACTIVE_ACCOUNTS_GROUP,
  SET_ACTORS_ACCOUNTS_GROUPS_REQ_STATUS,
  CLEAR_ACCOUNTS_GROUPS,
  PERMANENT_ACCOUNTS_GROUPS,
} from '@control-front-end/common/constants/actorAccounts';

// ------------------------------------
// Reducer
// ------------------------------------

const initState = {
  activeGroup: undefined,
  list: [...PERMANENT_ACCOUNTS_GROUPS],
  limit: 15,
  offset: 0,
  total: 0,
  endList: false,
  reqStatus: 'success',
};

export default createReducer(initState, {
  [GET_ACTORS_ACCOUNTS_GROUPS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SET_ACTIVE_ACCOUNTS_GROUP](state, action) {
    return { ...state, activeGroup: action.payload.id };
  },
  [SET_ACTORS_ACCOUNTS_GROUPS_REQ_STATUS](state, action) {
    return { ...state, reqStatus: action.payload };
  },
  [CLEAR_ACCOUNTS_GROUPS]() {
    return initState;
  },
});
