import React, { useRef, useCallback } from 'react';
import { TextField, Emoji, Space, Stack } from 'mw-style-react';
import { useIntl } from 'hooks';
import m from './intl';

function EmojiTextField({ value, onChange }) {
  const textFieldRef = useRef();
  const t = useIntl();

  const handeleChange = useCallback(
    ({ value }) => {
      onChange({ value: value?.native });
    },
    [onChange]
  );

  return (
    <div ref={textFieldRef}>
      <TextField
        bordered={true}
        value={value}
        unspaced
        readOnly
        placeholder={t(m.icon)}
        endAdornment={
          <Space right size="small">
            <Stack>
              <Emoji
                bindingElementRef={textFieldRef}
                size="medium"
                onChange={handeleChange}
              />
            </Stack>
          </Space>
        }
      />
    </div>
  );
}

export default EmojiTextField;
