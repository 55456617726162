import { useState, useEffect } from 'react';
import { DateUtils, Utils } from 'mw-style-react';

const _SNIPPETS_LS_KEY_ = 'snippetsCache';
const SNIPPET_CACHE_TTL = 60 * 60 * 24;

const useCachedSnippets = () => {
  const [snippetsCache, setSnippetsCache] = useState({});

  /**
   * Получить кэшированные сниппеты из LocalStorage
   */
  const getStorageSnippets = () => {
    let cachedSnippets;
    try {
      cachedSnippets = JSON.parse(Utils.fromStorage(_SNIPPETS_LS_KEY_));
    } catch (e) {
      cachedSnippets = {};
    }
    return cachedSnippets || {};
  };

  /**
   * Получить кэшированные результаты поиска
   */
  const handleSearchCached = (query) => {
    let findKey = '';
    for (const key of Object.keys(snippetsCache)) {
      if (key === query) return snippetsCache[key].data;
      if (key.indexOf(query) !== -1) findKey = key;
    }
    return findKey.length ? snippetsCache[findKey].data : null;
  };

  /**
   * Добавить новый результат поиска в кэш
   */
  const handleUpdateCache = (query, data) => {
    if (!data?.length) return;
    const list = data.map(({ id, title, description, data }) => ({
      id,
      title,
      description,
      data,
    }));
    const expire = DateUtils.unixtime() + SNIPPET_CACHE_TTL;
    const newCache = { ...snippetsCache, [query]: { data: list, expire } };
    setSnippetsCache(newCache);
    Utils.toStorage(_SNIPPETS_LS_KEY_, JSON.stringify(newCache));
  };

  /**
   * Очистить из LocalStorage устаревшие списки сниппетов
   */
  const removeExpiredCache = () => {
    const actualCache = getStorageSnippets();
    const now = DateUtils.unixtime();
    for (const key of Object.keys(actualCache)) {
      if (actualCache[key].expire < now) delete actualCache[key];
    }
    Utils.toStorage(_SNIPPETS_LS_KEY_, JSON.stringify(actualCache));
  };

  useEffect(() => {
    const snippets = getStorageSnippets();
    setSnippetsCache(snippets);
  }, []);

  return { handleSearchCached, handleUpdateCache, removeExpiredCache };
};

export default useCachedSnippets;
