import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Label,
} from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import useIntl from 'useIntl';
import mes from './intl';
import './TableChart.scss';

/**
 * Table chart component
 */
function TableChart({
  size = 'medium',
  dataSet = [],
  typeLabel = 'count',
  showTotal,
  onElementClick,
}) {
  const t = useIntl();

  const sum = useMemo(
    () => dataSet.reduce((a, b) => a + b.value, 0),
    [dataSet]
  );

  const renderRows = () => {
    return dataSet.map((acc, index) => {
      const percentage = (acc.value / sum) * 100;
      return (
        <TableRow
          key={index}
          styleName="table__row"
          rowSize="small"
          onClick={() => onElementClick?.(index)}
        >
          <TableCell styleName="table__cell">
            <Label fontSize={size} value={acc.label} />
          </TableCell>
          <TableCell styleName="table__cell percentage">
            <Label
              fontSize={size}
              value={sum !== 0 ? `${percentage.toFixed(2)}%` : 0}
            />
          </TableCell>
          <TableCell styleName="table__cell value">
            <Label
              fontSize={size}
              overflow="cut"
              duplicatingTooltip
              value={AppUtils.toNumberFormat(acc.value)}
            />
          </TableCell>
        </TableRow>
      );
    });
  };

  const roundedSum = Math.round(sum * 100) / 100;
  return (
    <Table
      styleName="table"
      paddingSize="small"
      columnSize={['50%', '15%', '35%']}
    >
      <TableHead styleName="table__head" fixed>
        <TableRow rowSize="small">
          <TableCell styleName="table__cell">
            <Label
              fontSize={size}
              fontWeight="semibold"
              overflow="cut"
              value={t(mes.title)}
            />
          </TableCell>
          <TableCell styleName="table__cell percentage">
            <Label fontSize={size} fontWeight="semibold" value="%" />
          </TableCell>
          <TableCell styleName="table__cell value">
            <Label fontSize={size} fontWeight="semibold" value={typeLabel} />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {renderRows()}
        <TableRow
          styleName="table__row"
          rowSize="small"
          visibility={showTotal ? 'visible' : 'hidden'}
        >
          <TableCell styleName="table__cell">
            <Label fontSize={size} fontWeight="semibold" value={t(mes.total)} />
          </TableCell>
          <TableCell styleName="table__cell percentage">
            <Label fontSize={size} fontWeight="semibold" value="100%" />
          </TableCell>
          <TableCell styleName="table__cell value">
            <Label
              fontSize={size}
              fontWeight="semibold"
              overflow="cut"
              duplicatingTooltip
              value={AppUtils.toNumberFormat(roundedSum)}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

TableChart.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  dataSet: PropTypes.array.isRequired,
  typeLabel: PropTypes.string,
  showTotal: PropTypes.bool,
  onElementClick: PropTypes.func,
};

export default TableChart;
