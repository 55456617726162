import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import useIntl from 'useIntl';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  GET_SYSTEM_ACTOR,
  GET_TEMPLATE_ACTORS,
} from '@control-front-end/common/constants/graphActors';
import { Icon, Label, Divider, Badge, Tooltip } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import { FILTERS } from '@control-front-end/common/constants/filters';
import useHover from '../../hooks/useHover';
import { checkUserPermissions } from '../../../../selectors';
import '../../Menu.scss';
import mes from './intl';

function MenuItem(props) {
  const {
    menuItem,
    itemIndex,
    activePopupId,
    setActivePopupId,
    accId,
    collapsed,
  } = props;
  const t = useIntl();
  const dispatch = useDispatch();
  const menuItemBox = useRef();
  const [hoverMenuRef, isHoveredMenu] = useHover();
  const [hoverSubMenuRef, isHoveredSubMenu] = useHover();
  const systemForms = useSelector((state) => state.systemForms) || {};
  const settings = useSelector((state) => state.settings);
  const config = useSelector((state) => state.config);
  const auth = useSelector((state) => state.auth);
  const checkPermissions = useSelector(checkUserPermissions);
  const [open, setOpen] = useState(props.menuItem.open || props.open);
  const [notAllowed, setNotAllowed] = useState(false);
  const [scriptsMenu, setScriptsMenu] = useState([]);
  const { id, title, icon, visibility, permissions, children, innerLinks } =
    menuItem;
  const hasActivePopup = activePopupId === id;

  const getScriptsMenu = () => {
    const sMenus = children.filter(
      (i) =>
        !!i.system &&
        i.systemTemplate === 'scripts' &&
        config.appSettings?.show_menu_scripts?.includes(accId)
    );
    const tpl = systemForms.scripts;
    if (!sMenus.length || !tpl) return;
    dispatch({
      type: GET_TEMPLATE_ACTORS.REQUEST,
      payload: {
        formId: tpl.id,
        starred: true,
        localState: {
          list: [],
          limit: 5,
          offset: 0,
          endList: false,
          reqStatus: 'success',
        },
      },
      callback: (actors) => {
        setScriptsMenu(actors.list);
      },
    });
  };

  useEffect(getScriptsMenu, [systemForms.scripts]);

  useEffect(() => {
    if (menuItem.id !== 'files') return;
    setNotAllowed(config.fileMimeTypes === 'none');
  }, [config.fileMimeTypes]);

  useEffect(() => {
    if (menuItem.id !== 'profile') return;
    dispatch({
      type: GET_SYSTEM_ACTOR.REQUEST,
      payload: { objType: 'user', objId: auth.id },
      callback: () => setNotAllowed(false),
      errorCallback: () => setNotAllowed(true),
    });
  }, [auth.id]);

  useEffect(() => {
    if (isHoveredMenu && !hasActivePopup) setActivePopupId(id);
  }, [isHoveredMenu]);
  useEffect(() => {
    if (isHoveredSubMenu && !hasActivePopup) setActivePopupId(id);
  }, [isHoveredSubMenu]);

  const getAccLink = (url) => url.replaceAll('{{accId}}', accId);

  const checkIsActive = (url, subUrls = []) => {
    const links = [url];
    subUrls.forEach((i) => {
      links.push(getAccLink(i));
    });
    const findUrls = links.find((i) => document.location.href.includes(i));
    return !!findUrls;
  };

  const toggleSubMenu = () => setOpen(!open);

  const getFilterParams = (filterId, filterKey = '') => {
    if (!FILTERS[filterId]) return {};
    const index =
      filterKey !== ''
        ? FILTERS[filterId].findIndex((filter) => filter.key === filterKey)
        : 0;
    const { filterParams: fParams = {} } = AppUtils.getFilter(
      filterId,
      FILTERS[filterId][index].key
    );
    return fParams;
  };

  const getShowBadge = (itemId) => {
    const { forms = [], actors = [] } = settings.exportGraph || {};
    return itemId === 'import_export' && (forms.length || actors.length);
  };

  const renderScripts = () => {
    return scriptsMenu.map((i) => {
      const url = `/scripts_item/${accId}/view/${i.ref}/production/index`;
      const isActive = checkIsActive(url);
      return (
        <NavLink key={i.id} to={url} isActive={() => checkIsActive(url)}>
          <div
            styleName={cn('menuItem__row', { active: isActive })}
            onClick={() => setActivePopupId(null)}
          >
            <Label styleName="menuItem__row__label" value={i.title} />
          </div>
        </NavLink>
      );
    });
  };

  const renderStaticMenu = () =>
    children
      .filter((i) => !i.system)
      .map((item) => {
        const subMenuFilterParams = getFilterParams(
          item.filterId,
          item.filterKey
        );
        const subMenuLink = getAccLink(item.link);
        const isAllowed = checkPermissions(item.permissions);
        const isActive = checkIsActive(subMenuLink, item.innerLinks);
        const isVisible = item.visibility !== 'hidden';
        if (!isAllowed || !isVisible) return null;
        return (
          <NavLink
            key={item.id}
            to={`${AppUtils.makeUrl(subMenuLink, { ...subMenuFilterParams })}`}
            isActive={() => checkIsActive(subMenuLink, item.innerLinks)}
          >
            <div
              styleName={cn('menuItem__row', { active: isActive })}
              onClick={() => setActivePopupId(null)}
            >
              <Label
                styleName="menuItem__row__label"
                value={t(mes[item.title]) || item.title}
              />
            </div>
          </NavLink>
        );
      });

  const renderSubMenu = () => (
    <>
      {renderStaticMenu()}
      {renderScripts()}
    </>
  );

  const renderPopupSubmenu = () => {
    if (!hasActivePopup && !isHoveredMenu && !isHoveredSubMenu) return null;
    const link = getAccLink(menuItem.link);
    const subMenuFilterParams = getFilterParams(id);
    const itemMarginTop = itemIndex > 0 ? 10 : 0;
    const topOffset = 76 + itemIndex * (28 + itemMarginTop);

    return (
      <div
        ref={hoverSubMenuRef}
        styleName="menuItem__row__popup"
        style={{ top: `${topOffset}px` }}
      >
        <NavLink
          key={id}
          to={`${AppUtils.makeUrl(link, { ...subMenuFilterParams })}`}
          isActive={() => checkIsActive(link, innerLinks)}
        >
          <div styleName="menuItem__row">
            <Label
              styleName="menuItem__row__label"
              fontWeight="semibold"
              value={t(mes[title]) || title}
            />
          </div>
        </NavLink>
        <Divider />
        {renderSubMenu()}
      </div>
    );
  };

  const handleNavLinkClick = (e) => {
    if (!open) toggleSubMenu();
    if (menuItem.onClick) {
      e.preventDefault();
      menuItem.onClick();
    }
  };

  const menuLink = getAccLink(menuItem.link);
  const hasSubMenu = children && children.length > 0;
  const showBadge = getShowBadge(menuItem.id);
  const isAllowedMenu = checkPermissions(permissions) && !notAllowed;
  const filterParams = getFilterParams(id);

  if (!isAllowedMenu) return null;
  return (
    <div
      ref={menuItemBox}
      styleName={cn('menuItem', { hidden: visibility === 'hidden', collapsed })}
    >
      <NavLink
        to={`${AppUtils.makeUrl(menuLink, { ...filterParams })}`}
        isActive={() => checkIsActive(menuLink, innerLinks)}
        onClick={handleNavLinkClick}
      >
        <div
          ref={hoverMenuRef}
          styleName={cn('menuItem__row', { empty: !hasSubMenu })}
          onClick={() => {
            if (menuItem.externalLink) {
              window.open(menuItem.externalLink);
            }
          }}
        >
          {hasSubMenu ? (
            <Icon
              styleName={cn('menuItem__row__icon dropdown', {
                close: !open || collapsed,
              })}
              type="dropdown"
              size="micro"
              onClick={toggleSubMenu}
            />
          ) : null}
          <Tooltip value={!hasSubMenu ? t(mes[title]) : ''}>
            <Icon
              styleName="menuItem__row__icon"
              type={icon}
              visibility={visibility}
            />
          </Tooltip>
          {showBadge ? (
            <Badge styleName="menuItem__row__badge" size="small" />
          ) : null}
          <Label
            styleName="menuItem__row__label"
            visibility={visibility}
            value={t(mes[title]) || title}
          />
        </div>
      </NavLink>
      {hasSubMenu && open && !collapsed ? (
        <div styleName="sub__menu">{renderSubMenu()}</div>
      ) : null}
      {hasSubMenu && collapsed ? renderPopupSubmenu() : null}
    </div>
  );
}

MenuItem.propTypes = {
  accId: PropTypes.string.isRequired,
  open: PropTypes.bool,
  collapsed: PropTypes.bool,
  itemIndex: PropTypes.number,
  activePopupId: PropTypes.string,
  setActivePopupId: PropTypes.func,
  menuItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    link: PropTypes.string,
    innerLinks: PropTypes.array,
    visibility: PropTypes.string,
    icon: PropTypes.string,
    permissions: PropTypes.array,
    open: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        link: PropTypes.string,
        visibility: PropTypes.string,
      })
    ),
  }),
};

export default MenuItem;
