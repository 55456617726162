import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  Card,
  Space,
  Stack,
  Icon,
  Label,
  Button,
  TextField,
  ProgressBar,
  Divider,
  cr,
} from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import {
  ACCOUNT_CREATE,
  ACCOUNTS,
  SEARCH_ACCOUNTS,
  SET_MODAL,
  UPDATE_USER_SETTINGS,
} from 'constants';
import { useOutsideClick, useIntl, useSearchList } from 'hooks';
import EmptyList from '@control-front-end/common/components/EmptyList';
import WorkspacesList from './WorkspacesList';
import mes from './intl';
import scss from './Workspace.scss';

export function WorkspacesListWidget({ collapsed, closePopup, className }) {
  const t = useIntl();
  const dispatch = useDispatch();
  const { active: activeAccountId } = useSelector((state) => state.accounts);
  const [search, setSearch] = useState('');
  const workspacesPopup = useRef();

  const {
    list: accountsList,
    loading,
    total,
    makeSearch,
    onScroll,
  } = useSearchList({
    actionType: ({ query }) =>
      query ? SEARCH_ACCOUNTS.REQUEST : ACCOUNTS.REQUEST,
    searchParams: { query: '', withStats: true },
    localState: true,
  });

  useOutsideClick({ ref: workspacesPopup, callback: closePopup });

  const redirectToWorkspace = (accId) => {
    document.location.href = AppUtils.makeUrl(
      `/events/${AppUtils.makeShortAccountId(accId)}/list`
    );
  };

  const handleWorkspaceChange = (accId) => {
    if (!accId || accId === activeAccountId) return;
    dispatch({
      type: UPDATE_USER_SETTINGS.REQUEST,
      payload: { accId: null, activeAccId: accId },
      callback: () => redirectToWorkspace(accId),
    });
  };

  const handleSearch = ({ value }) => {
    const q = value.trim();
    setSearch(q);
    makeSearch({ query: q });
  };

  const handleCreateWorkspace = (newWorkspaceName) => {
    dispatch({
      type: ACCOUNT_CREATE.REQUEST,
      payload: {
        name: newWorkspaceName,
      },
      callback: (newAcc) => {
        handleWorkspaceChange(newAcc.id);
      },
    });
  };

  const showSaveWorkspaceModal = () => {
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'SaveWithName',
        data: {
          name: '',
          title: t(mes.newWorkspace),
          btnText: t(mes.create),
          minLength: 1,
          maxLength: 255,
        },
        callback: handleCreateWorkspace,
      },
    });
  };

  return (
    <Card
      forwardRef={workspacesPopup}
      className={cn(className, { collapsed })}
      borderRadius={Card.BORDER_RADIUS.large}
      style={{ width: 252, height: 352 }}
    >
      <Stack.V
        size="micro"
        fullWidth
        fullHeight
        justifyContent="spaceBetween"
        divider={<Divider color={Divider.COLOR.accent} />}
      >
        <Space size="xsmall" fullWidth>
          <TextField
            leftIcon="search"
            bordered
            unspaced
            resettable
            value={search}
            placeholder={t(mes.searchBy)}
            onReset={() => handleSearch({ value: '' })}
            onChange={handleSearch}
          />
        </Space>
        <Space className={scss.total} size="small">
          <Label
            value={`${t(mes.workspaces)} ${
              search.length ? accountsList.length : total || 0
            }`}
            fontWeight="semibold"
          />
        </Space>
        <Stack.V
          className={scss.workspace__list}
          size="none"
          fullWidth
          onScroll={onScroll}
        >
          {cr(
            [
              loading && !accountsList.length,
              <ProgressBar className={scss.loader} />,
            ],
            [
              accountsList.length,
              <WorkspacesList
                list={accountsList}
                activeAccountId={activeAccountId}
                handleSelect={handleWorkspaceChange}
              />,
            ],
            [
              true,
              <EmptyList
                size="small"
                objType="workspace"
                isSearch={!!search.length}
              />,
            ]
          )}
        </Stack.V>
        <Space size="xsmall" fullWidth>
          <Button
            type="quaternary"
            size="smallplus"
            fullWidth
            label={t(mes.createWorkspace)}
            onClick={showSaveWorkspaceModal}
          />
        </Space>
      </Stack.V>
    </Card>
  );
}

WorkspacesListWidget.defaultProps = {
  closePopup: () => {},
};

WorkspacesListWidget.propTypes = {
  collapsed: PropTypes.bool,
  closePopup: PropTypes.func,
};

function Workspace({ collapsed }) {
  const t = useIntl();
  const POPUP_BUTTON_ID = 'workspaces_list_button';

  const [popup, togglePopup] = useState(false);

  const { activeAccount } = useSelector((state) => state.accounts);

  const getAccLabel = () => {
    if (!collapsed) return activeAccount.name;
    return activeAccount.name
      .trim()
      .split(/\s+/)
      .map((n) => (n[0] ? n[0].toUpperCase() : ''))
      .splice(0, 2)
      .join('');
  };

  const handleSelectClick = () => {
    togglePopup(!popup);
  };

  const closePopup = (e) => {
    const parentDiv = e.target.closest('div');
    if (parentDiv.id !== POPUP_BUTTON_ID) {
      togglePopup(false);
    }
  };

  return (
    <div styleName="workspace">
      <Label
        styleName="workspace__label"
        value={t(mes.workspace)}
        fontSize="small"
        visibility={collapsed ? 'hidden' : 'disabled'}
      />
      <div styleName="workspace__select">
        <div
          styleName="workspace__select"
          id={POPUP_BUTTON_ID}
          onClick={handleSelectClick}
        >
          <Label
            styleName="workspace__select__active"
            value={getAccLabel()}
            fontSize="normal"
            visibility="visible"
          />
          <Icon
            styleName={cn('workspace__select__icon', { active: popup })}
            size="micro"
            type="dropdown"
          />
        </div>
        {popup ? (
          <WorkspacesListWidget
            closePopup={closePopup}
            collapsed={collapsed}
            className={scss.relativeToButton}
          />
        ) : null}
      </div>
    </div>
  );
}

Workspace.propTypes = {
  collapsed: PropTypes.bool,
};

export default Workspace;
