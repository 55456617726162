/**
 * The same precision as on the back end side.
 * Also resolves problem with js unacurate numbers addition, like:
 * 0.1 + 0.2 = 0.30000000000000004
 */
const MAX_NUMERIC_TRANSFER_PRECISION = 8;

export const getNumericTransferSum = (items) =>
  items.reduce(
    (acc, item) =>
      Number(
        (acc + Number.parseFloat(item || 0)).toFixed(
          MAX_NUMERIC_TRANSFER_PRECISION
        )
      ),
    0
  );
