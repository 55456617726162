import { SET_MAX_LAYER_NUMBER } from '@control-front-end/common/constants/graphLayers';
import { createReducer } from '@reduxjs/toolkit';

// ------------------------------------
// Reducer
// ------------------------------------

const initState = 0;

export default createReducer(initState, {
  [SET_MAX_LAYER_NUMBER](state, action) {
    return action.payload;
  },
});
