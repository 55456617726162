import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { GET_USER, NOTIFY_LEVEL, SHOW_NOTIFY } from 'constants';
import { Label, Tooltip, Icon, Clipboard } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import { PERMISSIONS } from '@control-front-end/common/constants/permissions';
import { GET_SYSTEM_ACTOR } from '@control-front-end/common/constants/graphActors';
import useIntl from 'useIntl';
import UserAvatar from '@control-front-end/common/components/UserAvatar';
import { useActorModalActions } from 'hooks';
import { CopyIdChip } from 'components';
import { checkUserPermissions } from '../../selectors';
import './UserPreview.scss';
import mes from '../SmartChipPreview/intl';

/**
 * Просмотр карточки пользователя
 */
function UserPreview(props) {
  const { id, saId, title, withSystemActor = true, handleClose } = props;
  const t = useIntl();
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const config = useSelector((state) => state.config);
  const accId = useSelector((state) => state.accounts.active);
  const checkPermissions = useSelector(checkUserPermissions);
  const canViewUsers = checkPermissions([PERMISSIONS.USERS_READONLY]);
  const { handleOpenActorModal } = useActorModalActions();
  const systemActorLink = user?.systemActorId
    ? document.location.origin +
      AppUtils.makeUrl(`/actors_graph/${accId}/view/${user.systemActorId}`)
    : null;

  /**
   * Получить информацию о пользователе
   */
  const getActor = () => {
    if (canViewUsers) {
      dispatch({
        type: GET_USER.REQUEST,
        payload: { userId: saId || id, type: saId ? 'sa' : undefined },
        callback: (data) => {
          if (!data?.id) {
            setUser(null);
            return;
          }
          if (withSystemActor) {
            dispatch({
              type: GET_SYSTEM_ACTOR.REQUEST,
              payload: { objType: 'user', objId: data.id },
              callback: (actor) =>
                setUser({ ...data, systemActorId: actor.id }),
              errorCallback: () => setUser(data),
            });
          } else {
            setUser(data);
          }
        },
      });
    } else {
      setUser({
        id,
        value: title,
        avatar: AppUtils.makeUserAvatar(
          { id: saId || id, type: 'user' },
          config
        ),
      });
    }
  };

  useEffect(() => {
    getActor();
  }, [id]);

  /**
   * Скопировать ссылку на системный актор пользователя
   */
  const handleCopyLink = () => {
    Clipboard.copy(systemActorLink);
    dispatch({
      type: SHOW_NOTIFY.REQUEST,
      payload: {
        id: AppUtils.createRid(),
        type: NOTIFY_LEVEL.SUCCESS,
        label: t(mes.linkCopied),
      },
    });
  };

  if (!user) return null;
  const { value: nick, avatar, type, email, systemActorId } = user;
  const avatarLarge = avatar.replace(/\/(\d{2}x\d{2})\//, '/64x64/'); // NOSONAR

  return (
    <div styleName="preview__root">
      <UserAvatar
        size={canViewUsers ? 'xlarge' : 'medium'}
        src={avatarLarge}
        label={nick}
        userType={type}
      />
      <div styleName="preview__actions">
        {systemActorId ? (
          <>
            <Tooltip value={t(mes.expandProfile)}>
              <Icon
                type="profile"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenActorModal(systemActorId);
                  handleClose();
                }}
              />
            </Tooltip>
            <Tooltip value={t(mes.copyLink)}>
              <Icon
                type="copy"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCopyLink();
                }}
              />
            </Tooltip>
            <div
              onClick={(e) => {
                e.stopPropagation();
                window.open(systemActorLink);
              }}
            >
              <Icon size="small" type="link_external" />
            </div>
          </>
        ) : null}
        <Icon
          size="small"
          type="close"
          onClick={(e) => {
            e.stopPropagation();
            handleClose();
          }}
        />
      </div>
      <div styleName="preview__info">
        <Label fontWeight="semibold" value={nick} />
        <CopyIdChip fontSize="small" type="default" id={id} />
        {type === 'user' && canViewUsers && email ? (
          <Label value={email} />
        ) : null}
      </div>
    </div>
  );
}

UserPreview.propTypes = {
  id: PropTypes.string,
  withSystemActor: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default UserPreview;
