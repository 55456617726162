import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { SET_MODAL, DEL_MODAL, REPLACE_MODAL } from 'constants';

const getModal = (state, props) => {
  const modals = state.modal || [];
  const modal = modals.find((i) => i.name === props.name);
  return !!modal;
};

const makeGetModal = createSelector([getModal], (r) => r);

const useModalDispatcher = (type, { name, data = {}, modalProps = {} }) => {
  const dispatch = useDispatch();

  const removeModalDispatcher = useCallback(
    () => dispatch({ type, payload: name }),
    [type, name]
  );

  const modalDispatcher = useCallback(
    (updatedData = {}) =>
      dispatch({
        type,
        payload: {
          name,
          replace: type === REPLACE_MODAL ? name : null,
          data: {
            ...data, // Permanent options between modal window reopenings
            ...updatedData, // Options that could be changed on modal window opened or update called
          },
          ...modalProps,
        },
      }),
    [type, data, modalProps]
  );

  return type === DEL_MODAL ? removeModalDispatcher : modalDispatcher;
};

/**
 * Hook to work with modal windows, provides functions to open, close or update modal
 *
 * @param {string} name name of the modal window
 * @param {Object} data props that go to the target modal component
 * @param {Object} modalProps service props which go to the Modal.js
 *
 * @see packages/app/src/components/Modal/Modal.js
 */
const useModal = (name, data, modalProps) => {
  const dispatchPayload = { name, data, modalProps };
  const isOpen = useSelector((state) => makeGetModal(state, { name }));

  const open = useModalDispatcher(SET_MODAL, dispatchPayload);
  const close = useModalDispatcher(DEL_MODAL, dispatchPayload);
  const update = useModalDispatcher(REPLACE_MODAL, dispatchPayload);

  return {
    // Use this when you need to set your names of functions
    isOpen,
    open,
    close,
    update,

    // In case when function name is clear from the context and there is no need in renaming
    isOpenModal: isOpen,
    openModal: open,
    closeModal: close,
    updateModal: update,
  };
};

export default useModal;
