import { defineMessages } from 'react-intl';

const m = defineMessages({
  title: {
    id: 'title_CredentialsMappingMaker',
    defaultMessage: 'Corezoid Credentials from workspace ({amount})',
  },
  apiLogin: {
    id: 'apiLogin_CredentialsMappingMaker',
    defaultMessage: 'Api login',
  },
  apiLoginPlaceholder: {
    id: 'apiLoginPlaceholder_CredentialsMappingMaker',
    defaultMessage: 'Enter API login',
  },
  apiSecret: {
    id: 'apiSecret_CredentialsMappingMaker',
    defaultMessage: 'Api secret',
  },
  apiSecretPlaceholder: {
    id: 'apiSecretPlaceholder_CredentialsMappingMaker',
    defaultMessage: 'Enter API secret',
  },
  devProcId: {
    id: 'devProcId_CredentialsMappingMaker',
    defaultMessage: 'Dev process ID',
  },
  devProcIdPlaceholder: {
    id: 'devProcIdPlaceholder_CredentialsMappingMaker',
    defaultMessage: 'Enter dev process ID',
  },
  prodProcId: {
    id: 'prodProcId_CredentialsMappingMaker',
    defaultMessage: 'Prod process ID',
  },
  prodProcIdPlaceholder: {
    id: 'prodProcIdPlaceholder_CredentialsMappingMaker',
    defaultMessage: 'Enter prod process ID',
  },
});

export default m;
