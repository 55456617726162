import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Chip, Utils } from 'mw-style-react';
import scss from './IncomeTypeChip.scss';
import { INCOME_TYPE } from '../../constants/actorAccounts';

function IncomeTypeChip({ incomeType, ...props }) {
  return (
    <Chip
      className={cn(scss.chip, scss[incomeType])}
      label={Utils.capitalize(incomeType)}
      {...props}
    />
  );
}

IncomeTypeChip.defaultProps = {
  size: 'small',
  closeIcon: false,
  fontWeight: 'normal',
  type: 'rectangular',
  unspaced: true,
};

IncomeTypeChip.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  closeIcon: PropTypes.bool,
  fontWeight: PropTypes.oneOf(['normal', 'semibold']),
  type: PropTypes.oneOf(['rectangular', 'rounded']),
  incomeType: PropTypes.oneOf([...Object.values(INCOME_TYPE), 'corrected'])
    .isRequired,
};

export default IncomeTypeChip;
