import { all, call } from 'redux-saga/effects';
import api from '@control-front-end/common/sagas/api';
import { BULK_ACTIONS_LIMIT } from 'constants';

/**
 * Вызовы API с разбивкой на подзапросы согласно лимиту
 */
function* apiBulk(apiParams, limitedBodyArrKey = null) {
  const { body } = apiParams;
  if (Array.isArray(body) && body.length > BULK_ACTIONS_LIMIT) {
    const reqCount = Math.ceil(body.length / BULK_ACTIONS_LIMIT);
    const apiCalls = [...new Array(reqCount)].map((i, index) =>
      call(api, {
        ...apiParams,
        body: body.slice(
          index * BULK_ACTIONS_LIMIT,
          index * BULK_ACTIONS_LIMIT + BULK_ACTIONS_LIMIT
        ),
      })
    );
    return yield all(apiCalls);
  }
  const limitedArray = limitedBodyArrKey ? body[limitedBodyArrKey] : null;
  if (Array.isArray(limitedArray) && limitedArray.length > BULK_ACTIONS_LIMIT) {
    const reqCount = Math.ceil(limitedArray.length / BULK_ACTIONS_LIMIT);
    const apiCalls = [...new Array(reqCount)].map((i, index) =>
      call(api, {
        ...apiParams,
        body: {
          ...body,
          [limitedBodyArrKey]: limitedArray.slice(
            index * BULK_ACTIONS_LIMIT,
            index * BULK_ACTIONS_LIMIT + BULK_ACTIONS_LIMIT
          ),
        },
      })
    );
    return yield all(apiCalls);
  }
  const resp = yield call(api, apiParams);
  return [resp];
}

export default apiBulk;
