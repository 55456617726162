import { takeLatest } from 'redux-saga/effects';
import { ELECTRON_NAVIGATE } from 'constants';
import history from '@control-front-end/app/src/store/history';

/**
 * Навигация с electon приложения
 */
function electonNavigate({ payload }) {
  const { url } = payload;
  history.push(url);
}

function* electron() {
  yield takeLatest(ELECTRON_NAVIGATE, electonNavigate);
}

export default electron;
