import { Utils } from 'mw-style-react';
import { useEffect } from 'react';

/**
 * Хук для обработки скролла
 * @param ref
 * @param callback
 * @param side
 * @param scrollDirection
 * @param customScroll
 */
function useScroll({ ref, callback, side = 'down', customScroll = false }) {
  // Обработчик скролла для блока
  const handleScroll = Utils.debounce((e) => {
    const { scrollTop, clientHeight, scrollHeight } = ref?.current || {};
    const scrollBottom = scrollHeight - (scrollTop + clientHeight);
    if (customScroll && callback) return callback(e);
    if (
      (side === 'down' && scrollBottom >= 500) ||
      (side === 'up' && scrollTop >= 100)
    )
      return;
    if (callback) callback(e);
  }, 200);

  useEffect(() => {
    if (ref.current) ref.current.addEventListener('scroll', handleScroll, true);
    return () => {
      if (ref.current)
        ref.current.removeEventListener('scroll', handleScroll, true);
    };
  });
}

export default useScroll;
