import { defineMessages } from 'react-intl';

const m = defineMessages({
  actorsCard: {
    id: 'actorsCard_ActorCardViewEditor',
    defaultMessage: 'Actors Card',
  },
  preview: {
    id: 'preview_ActorCardViewEditor',
    defaultMessage: 'Preview',
  },
});

export default m;
