import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import useIntl from 'useIntl';
import { Label } from 'mw-style-react';
import { NOTIFY_LEVEL, SHOW_NOTIFY } from 'constants';
import AppUtils from '@control-front-end/utils/utils';
import SelectActorsTemplate from '@control-front-end/common/components/SelectActorsTemplate';
import '../../CreateActorsFilter.scss';
import mes from '../../intl';

/**
 * Выбор форм фильтра
 */
function SelectForms({ uatTree, formId, error, selectedForms = [], onChange }) {
  const t = useIntl();
  const dispatch = useDispatch();

  const notifyWrongRoot = () => {
    dispatch({
      type: SHOW_NOTIFY.REQUEST,
      payload: {
        id: AppUtils.createRid(),
        type: NOTIFY_LEVEL.ERROR,
        label: t(mes.wrongRootForm),
      },
    });
  };

  const handleOnChange = ({ value }) => {
    const selectedSet = new Set();
    let rootId = value.length ? formId : null;
    for (const val of value) {
      const rootForm = val.forms ? val.forms.find((i) => !i.parentId) : null;
      if (rootId && rootForm && rootId !== rootForm.id) {
        notifyWrongRoot();
        break;
      } else {
        rootId = formId || rootForm.id;
        selectedSet.add(val.id);
      }
    }
    onChange({ id: 'formId', value: rootId });
    onChange({ id: 'selectedForms', value: Array.from(selectedSet) });
  };

  const makeValueArray = () => {
    const values = [];
    for (const sId of selectedForms) {
      const form = uatTree[sId];
      if (!form) continue;
      values.push({
        id: sId,
        value: sId,
        title: form.title,
        color: form.color,
        parentId: form.parentId,
      });
    }
    return values;
  };

  return (
    <div styleName="filter__section dynamic">
      <Label fontWeight="semibold" value={t(mes.actorForms)} />
      <SelectActorsTemplate
        bordered={true}
        value={makeValueArray()}
        withDefault={false}
        withRelations={true}
        fullModel={true}
        formTypes="all"
        multiselect={true}
        error={!!error}
        helperText={t(mes[error])}
        exclude={selectedForms.map((i) => ({ id: i }))}
        onChange={handleOnChange}
      />
    </div>
  );
}

SelectForms.propTypes = {
  formId: PropTypes.number,
  selectedForms: PropTypes.array,
  uatTree: PropTypes.object,
  onChange: PropTypes.func,
};

export default SelectForms;
