import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalContent,
  Button,
  ProgressBar,
  MenuItem,
  Select,
  Label,
  Stack,
  Divider,
  Popover,
} from 'mw-style-react';
import cn from 'classnames';
import AppUtils from '@control-front-end/utils/utils';
import { useSearchList, useIntl, useScroll } from 'hooks';
import { FILTER_HISTORY, GET_OBJECT_HISTORY, GET_USERS } from 'constants';
import EmptyList from '@control-front-end/common/components/EmptyList';
import SelectUsers from '@control-front-end/common/components/SelectUsers';
import FilterRange from '@control-front-end/app/src/components/FilterRange';
import HistoryItem from './components/HistoryItem';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import sModal from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import sLocal from './ChangesHistory.scss'; // NOSONAR

const initState = {
  list: [],
  limit: 15,
  offset: 0,
  endList: false,
  reqStatus: 'success',
};
const SAME_REQUEST_INTERVAL = 1000 * 5;
const USERS_ALL = 'all';

function ChangesHistory(props) {
  const { data, visibility, onClose, dispatch } = props;
  const { title, objType, objId, fields = [], access = [] } = data;
  const t = useIntl();
  const listRef = useRef();
  const defaultRange = AppUtils.getRangeDate('lastMonth');
  const defaultFilter = {
    field: null,
    userId: USERS_ALL,
    userName: '',
    from: defaultRange.from * 1000,
    to: defaultRange.to * 1000 + 999,
  };
  const [filter, setFilter] = useState({
    ...defaultFilter,
    field: data.field || null,
  });
  const [history, setHistory] = useState(initState);
  const [isLoading, setLoading] = useState(false);
  const { from, to, field } = filter;

  /**
   * Make history changes with grouped access items
   */
  const makeChangesGrouped = useCallback((list) => {
    const grouped = [];
    let accessChanges = [];

    const addAccessChanges = () => {
      const lastIndex = accessChanges.length - 1;
      grouped.push({
        ...accessChanges[0],
        prevValue: accessChanges[lastIndex].prevValue,
        nextValue: accessChanges[0].nextValue,
      });
      accessChanges = [];
    };

    list.forEach((i, index) => {
      if (i.field !== 'access') {
        if (accessChanges.length) addAccessChanges();
        grouped.push(i);
        return;
      }
      if (!accessChanges.length) {
        accessChanges.push(i);
      } else {
        const firstValue = accessChanges[0];
        const isSameEditRequest =
          firstValue.userId === i.userId &&
          Math.abs(firstValue.createdAt - i.createdAt) < SAME_REQUEST_INTERVAL;

        if (isSameEditRequest) {
          accessChanges.push(i);
        } else {
          addAccessChanges();
          accessChanges = [i];
        }
      }
      const isEndOfList = index === list.length - 1;
      if (isEndOfList) addAccessChanges();
    });
    return grouped;
  }, []);

  const getHistory = (loadMore = false) => {
    const { limit, offset: curOffset, endList } = history;
    if (isLoading || (loadMore && endList)) return;
    const offset = loadMore ? curOffset : 0;
    setLoading(true);
    dispatch({
      type:
        filter.userId === USERS_ALL
          ? GET_OBJECT_HISTORY.REQUEST
          : FILTER_HISTORY.REQUEST,
      payload:
        filter.userId === USERS_ALL
          ? {
              objType,
              objId,
              from,
              to,
              field,
              limit,
              offset: loadMore ? offset + limit : 0,
            }
          : {
              formField: field
                ? { fieldId: field.value, formId: field.formId }
                : null,
              from,
              to,
              limit,
              offset,
              actors: [objId],
              userId: filter.userId,
              loadMore,
            },
      callback: (data) => {
        const newList = filter.userId === USERS_ALL ? data : data.list;
        setLoading(false);
        const copyList = history.list.slice();
        const resList = loadMore ? copyList.concat(newList) : newList;
        setHistory({
          ...history,
          list: makeChangesGrouped(resList),
          offset: loadMore ? offset + limit : offset,
          endList: !newList.length,
        });
      },
    });
  };

  const { list: usersList, onScroll } = useSearchList({
    actionType: GET_USERS.REQUEST,
    searchParams: { query: '' },
    settings: { step: 15, scrollToLoad: 20 },
  });

  useEffect(() => {
    getHistory(false);
  }, [objId, filter]);

  useScroll({
    ref: listRef,
    callback: () => getHistory(true),
  });

  const renderContent = useCallback(() => {
    if (!history.list.length) return <EmptyList objType="history" />;
    return (
      <Stack size={Stack.SIZE.xxsmall} divider={<Divider />}>
        {history.list.map((i) => (
          <HistoryItem key={i.id} {...i} />
        ))}
        {isLoading ? <ProgressBar size="small" /> : null}
      </Stack>
    );
  }, [history.list, isLoading, filter]);

  const accessUserIds = access.map((i) => i.userId);
  const accessedUsers = access
    .filter((user) => user.userType !== 'group')
    .map((user) => ({
      ...user,
      id: user.userId,
      value: user.name,
    }));
  const restUsers = usersList.filter((u) => !accessUserIds.includes(u.id));
  const sortedUsersList = accessedUsers.concat(restUsers);

  return (
    <Modal
      styleName="sModal.modal sLocal.modal"
      visibility={visibility}
      onClose={onClose}
      label={t(mes.actorHistory, { title: title || 'actor' })}
      size="xlarge"
    >
      <ModalContent styleName="sModal.modal__content sLocal.history">
        <div styleName="sLocal.history__filters">
          <FilterRange
            from={from}
            to={to}
            fastNavigation
            onChange={(range) =>
              setFilter((prevFilter) => ({ ...prevFilter, ...range }))
            }
          />
          <Select
            styleName="sLocal.history__filters__field"
            bordered={true}
            value={filter.field || t(mes.all)}
            onChange={({ value }) =>
              setFilter((prevFilter) => ({ ...prevFilter, field: value }))
            }
            visibility={fields.length ? 'visible' : 'hidden'}
          >
            <MenuItem value={null} label={t(mes.all)} />
            {fields.map((f) => (
              <MenuItem
                styleName={cn('sLocal.history__filters__field__item', {
                  [sLocal.high]: !!f.formTitle,
                })}
                key={f.value}
                value={f.value}
                label={f.title}
              >
                {f.formTitle ? (
                  <Label fontSize="small" value={f.formTitle} />
                ) : null}
              </MenuItem>
            ))}
          </Select>
          <div>
            <Popover
              content={({ onClose }) => {
                return (
                  <SelectUsers
                    id="owner"
                    bordered
                    wrap
                    multiselect={false}
                    autoFocus
                    hideCreateBtn
                    fullModel
                    value={filter.userId}
                    onChange={({ value }) => {
                      setFilter((prevFilter) => ({
                        ...prevFilter,
                        userId: value.id,
                        userName:
                          sortedUsersList.find((u) => u.id === value.id)
                            ?.value || '',
                      }));
                      onClose();
                    }}
                  />
                );
              }}
            >
              <Select
                styleName="sLocal.history__filters__field"
                bordered={true}
                value={`By ${filter.userName || t(mes.all)}`}
                exclude={accessedUsers}
                onChange={({ value }) =>
                  setFilter((prevFilter) => ({
                    ...prevFilter,
                    userId: value,
                    userName:
                      sortedUsersList.find((u) => u.id === value)?.value || '',
                  }))
                }
                onMenuScroll={onScroll}
              />
            </Popover>
          </div>
          <Button
            type="secondary"
            size="smallplus"
            fontWeight="semibold"
            label={t(mes.reset)}
            iconAfter="trash"
            onClick={() => setFilter(defaultFilter)}
            visibility={
              filter.field || filter.userId !== USERS_ALL ? 'visible' : 'hidden'
            }
          />
        </div>
        <div ref={listRef} styleName="sLocal.history__log">
          {isLoading && !history.list.length ? (
            <ProgressBar size="large" />
          ) : (
            renderContent()
          )}
        </div>
      </ModalContent>
    </Modal>
  );
}

ChangesHistory.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleOnChange: PropTypes.func,
  dispatch: PropTypes.func,
  isSubmit: PropTypes.bool,
  values: PropTypes.object,
  errors: PropTypes.object,
  data: PropTypes.object,
};

export default ChangesHistory;
