import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  startToWriteHere: {
    id: 'startToWriteHere',
    defaultMessage: 'Start to write here',
  },
  assign: {
    id: 'assign',
    defaultMessage: 'Assign',
  },
  inviteToCard: {
    id: 'inviteToCard',
    defaultMessage: 'Invite',
  },
  subject: {
    id: 'subject',
    defaultMessage: 'Subject',
  },
  newEvent: {
    id: 'newEvent',
    defaultMessage: 'New event',
  },
  attaches: {
    id: 'attaches',
    defaultMessage: 'Attaches',
  },
  price: {
    id: 'price',
    defaultMessage: 'Price',
  },
  points: {
    id: 'points',
    defaultMessage: 'points',
  },
});

export default { ...globalIntl, ...m };
