import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import api from '@control-front-end/common/sagas/api';
import AppUtils from '@control-front-end/utils/utils';
import {
  GET_FORMS,
  SET_FORMS_REQ_STATUS,
  SEARCH_FORMS,
  REMOVE_FORM,
  GET_FORMS_TREE,
  BULK_REMOVE_FORMS,
  FORMS_ACCESS,
} from '../constants/forms';
import { makeFormModel } from './formView';
import { RequestStatus } from '../constants/index';

/**
 * Get list
 */
function* getForms({ payload, callback }) {
  const accounts = yield select((state) => state.accounts);
  const accId = payload.accId || accounts.active;
  const {
    loadMore,
    localState,
    withDefault,
    withTotal,
    formTypes,
    withRelations,
    limit: customLimit,
    offset: customOffset,
  } = payload;
  let formState;
  if (localState) {
    formState = localState;
  } else {
    formState = yield select((state) => state.formList);
  }
  const {
    endList,
    reqStatus,
    list,
    limit: defaultLimit,
    offset: defaultOffset,
    loadCount,
  } = formState;
  if (reqStatus === RequestStatus.PROGRESS || (loadMore && endList)) {
    if (callback) callback();
    return;
  }
  // Set forms request status to progress
  if (!localState) {
    yield put({ type: SET_FORMS_REQ_STATUS, payload: RequestStatus.PROGRESS });
  }
  const reqTime = new Date().getTime();
  const url = `/forms/templates/${accId}`;
  const limit = customLimit || defaultLimit;
  const offset = !AppUtils.isUndefined(customOffset)
    ? customOffset
    : defaultOffset;
  const { result, data } = yield call(api, {
    method: 'get',
    url,
    queryParams: {
      limit,
      offset,
      withDefault,
      withTotal,
      formTypes,
      withRelations,
    },
  });
  // Ignore response on previous request
  const { reqTime: lastReqTime } = yield select((state) => state.formList);
  if (!localState && reqTime < lastReqTime) return;
  // Set forms request status to success
  if (!localState) {
    yield put({ type: SET_FORMS_REQ_STATUS, payload: RequestStatus.SUCCESS });
  }
  if (result !== RequestStatus.SUCCESS) return;
  const newList = withTotal ? data.data.list : data.data;
  yield call(makeFormModel, newList);
  const newFormsList = loadMore ? list.concat(newList) : newList;
  const total = !AppUtils.isUndefined(data.data.total)
    ? data.data.total
    : formState.total;
  const newPayload = {
    list: newFormsList,
    limit,
    offset: offset + limit,
    loadCount: loadCount + 1,
    endList: !newList.length,
    total,
    reqTime,
  };
  if (localState && callback) {
    callback({ ...localState, ...newPayload });
    return;
  }
  yield put({
    type: GET_FORMS.SUCCESS,
    payload: newPayload,
  });
  if (callback) callback(newPayload);
}

/**
 * Search
 */
function* searchForms({ payload, callback }) {
  const accounts = yield select((state) => state.accounts);
  const accId = payload.accId || accounts.active;
  const { query, withRelations, withDefault } = payload;
  const searchQ = AppUtils.safeSearchQuery(query);
  if (!searchQ.length) return;
  // Set forms request status to progress
  yield put({ type: SET_FORMS_REQ_STATUS, payload: RequestStatus.PROGRESS });
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/forms/search/${accId}/${searchQ}`,
    queryParams: { withRelations, withDefault },
  });
  // Set forms request status to success
  yield put({ type: SET_FORMS_REQ_STATUS, payload: RequestStatus.SUCCESS });
  if (result !== RequestStatus.SUCCESS) return;
  yield call(makeFormModel, data.data);
  if (callback) {
    callback({ list: data.data });
    return;
  }
  yield put({
    type: SEARCH_FORMS.SUCCESS,
    payload: {
      list: data.data,
      offset: 0,
      loadCount: 0,
      endList: false,
    },
  });
}

/**
 * Remove form`s template
 */
function* removeForm({ payload }) {
  const { formId } = payload;
  const { result } = yield call(api, {
    method: 'delete',
    url: `/forms/${formId}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  const { list } = yield select((state) => state.formList);
  const index = list.findIndex((i) => i.id === formId);
  if (index === -1) return;
  const copyList = list.slice();
  copyList.splice(index, 1);
  yield put({
    type: REMOVE_FORM.SUCCESS,
    payload: { list: copyList },
  });
}

/**
 * Bulk remove forms
 */
export function* bulkRemoveForms({ payload, callback }) {
  const { forms } = payload;
  const { result } = yield call(api, {
    method: 'delete',
    url: `/forms/bulk`,
    body: { forms },
  });
  if (result !== RequestStatus.SUCCESS) return;
  const { list, total } = yield select((state) => state.formList);
  const newList = list.filter((i) => !forms.includes(i.id));
  yield put({
    type: BULK_REMOVE_FORMS.SUCCESS,
    payload: { list: newList, total: total - forms.length },
  });
  if (callback) callback();
}

/**
 * Change forms access
 */
function* manageFormsAccess({ payload }) {
  const { rules } = payload;
  const { list } = yield select((state) => state.formList);
  const copyList = structuredClone(list);
  for (const obj of rules) {
    const findItem = copyList.find((i) => i.id === obj.id);
    if (findItem) findItem.access = obj.access;
  }
  yield put({
    type: FORMS_ACCESS.SUCCESS,
    payload: { list: copyList },
  });
}

/**
 * Get forms tree
 */
export function* getFormsTree({ payload, callback, errorCallback }) {
  const { formId, form } = payload;
  const workspaces = yield select((state) => state.accounts);
  const newFormId = 'new';
  const reqFormId = formId || form.parentId;
  const graph = [];
  if (reqFormId) {
    const { result, data } = yield call(api, {
      method: 'get',
      url: `/forms_graph/tree/${workspaces.active}/${reqFormId}`,
      handleErrCodes: errorCallback ? [400] : [],
    });
    if (result !== RequestStatus.SUCCESS) {
      if (errorCallback) errorCallback(data);
      return;
    }
    for (const formItem of data.data) {
      const { id, parentId, privs } = formItem;
      let accessDenied = false;
      if (!privs.view) {
        accessDenied = true;
        formItem.title = `${formItem.title}`;
      }
      graph.push({
        status: 'new',
        selected: id === formId,
        data: {
          group: 'nodes',
          type: 'node',
          readOnly: true,
          accessDenied,
          ...formItem,
        },
      });
      if (parentId) {
        graph.push({
          status: 'new',
          data: {
            group: 'edges',
            type: 'edge',
            isTree: true,
            targetArrow: 'triangle',
            id: `${id}_${parentId}`,
            source: parentId,
            target: id,
          },
        });
      }
    }
  }
  if (!formId) {
    graph.push({
      status: 'new',
      selected: true,
      data: {
        id: newFormId,
        group: 'nodes',
        type: 'node',
        readOnly: true,
        ...form,
      },
    });
    if (form.parentId) {
      graph.push({
        status: 'new',
        data: {
          group: 'edges',
          type: 'edge',
          isTree: true,
          targetArrow: 'triangle',
          id: `${newFormId}_${form.parentId}`,
          source: form.parentId,
          target: newFormId,
        },
      });
    }
  }
  yield put({
    type: GET_FORMS_TREE.SUCCESS,
    payload: graph,
  });
  if (callback) callback(graph);
  return graph;
}

function* formList() {
  yield takeEvery(GET_FORMS.REQUEST, getForms);
  yield takeLatest(SEARCH_FORMS.REQUEST, searchForms);
  yield takeLatest(REMOVE_FORM.REQUEST, removeForm);
  yield takeLatest(BULK_REMOVE_FORMS.REQUEST, bulkRemoveForms);
  yield takeLatest(FORMS_ACCESS.REQUEST, manageFormsAccess);
  yield takeLatest(GET_FORMS_TREE.REQUEST, getFormsTree);
}

export default formList;
