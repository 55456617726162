import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Stack,
  Icon,
  Label,
  Menu,
  MenuItem,
  Popover,
  TextField,
  Utils,
} from 'mw-style-react';
import { useIntl } from 'hooks';
import AppUtils from '@control-front-end/utils/utils';
import mes from './intl';
import scss from './TabsSelectPopup.scss';

function TabsSelectPopup({
  title,
  tabs = [],
  activeItem,
  showSearch = true,
  onSelect,
}) {
  const t = useIntl();
  const [query, setQuery] = useState('');

  const filteredTabs = useMemo(() => {
    const searchRegExp = new RegExp(query, 'gi');
    return tabs.filter((item) => item.title.match(searchRegExp));
  }, [tabs, query]);

  const scrollToTab = (tabId) => {
    setTimeout(() => AppUtils.scrollToElement(`tab_${tabId}`), 0);
  };

  return (
    <Popover
      topLevel
      anchors={{
        binding: Popover.ANCHOR.right_bottom,
        content: Popover.ANCHOR.right_top,
      }}
      content={({ onClose }) => (
        <Card
          color={Card.COLOR.white}
          boxShadow={Card.BOX_SHADOW.light}
          borderRadius={Card.BORDER_RADIUS.large}
        >
          <Stack.V className={scss.tabsPopup} size={Stack.SIZE.small}>
            {title ? (
              <Stack.H size={Stack.SIZE.xxsmall}>
                <Label
                  value={Utils.toUpperLatter(title)}
                  fontWeight="semibold"
                />
                &nbsp;
                <Label
                  color={Label.COLOR.gray}
                  value={filteredTabs.length}
                  fontWeight="semibold"
                />
              </Stack.H>
            ) : null}
            <TextField
              leftIcon="search"
              bordered
              unspaced
              autoFocus
              value={query}
              rightIcon={query.length ? 'close' : ''}
              placeholder={t(mes.listSearchPlaceholder)}
              onChange={({ value }) => setQuery(value)}
              onClickRightIcon={() => setQuery('')}
              visibility={showSearch ? 'visible' : 'hidden'}
            />
            <Menu
              styleName="tabsPopup__list"
              className={scss.tabsPopup__list}
              onClick={({ value }) => {
                onSelect(value);
                scrollToTab(value);
                setQuery('');
                onClose();
              }}
            >
              {filteredTabs.map((i) => (
                <MenuItem
                  key={i.id}
                  id={i.id}
                  value={i.id}
                  label={i.title}
                  rightIcon={i.id === activeItem ? 'check' : null}
                />
              ))}
            </Menu>
          </Stack.V>
        </Card>
      )}
    >
      <Icon className={scss.tabsPopup__icon} type="arrow" />
    </Popover>
  );
}

TabsSelectPopup.propTypes = {
  title: PropTypes.string,
  activeItem: PropTypes.string,
  tabs: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  showSearch: PropTypes.bool,
};

export default TabsSelectPopup;
