import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  transactionAcc: {
    id: 'transactionAcc',
    defaultMessage: 'Account name',
  },
  transactionType: {
    id: 'transactionType',
    defaultMessage: 'Type',
  },
  transactionTotal: {
    id: 'transactionTotal',
    defaultMessage: 'Total',
  },
  comment: {
    id: 'comment',
    defaultMessage: 'Comment',
  },
  createCorrective: {
    id: 'createCorrective',
    defaultMessage: 'Create corrective transaction',
  },
  correctedTransaction: {
    id: 'correctedTransaction',
    defaultMessage: 'Corrected',
  },
  transactionDetails: {
    id: 'transactionDetails',
    defaultMessage: 'Transaction details',
  },
});

export default { ...globalIntl, ...m };
