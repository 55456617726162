import { useRef, useEffect } from 'react';
import { useStore } from 'react-redux';

/**
 * Subscribes to redux store events
 *
 * @param effect
 * @param type
 * @param deps
 */
function useReduxAction(effect, type, deps = []) {
  const currentValue = useRef(null);
  const store = useStore();

  const handleChange = () => {
    const state = store.getState();
    const action = state.action;
    const previousValue = currentValue.current;
    currentValue.current = action.type;
    if (previousValue !== action.type && type === action.type) {
      effect(action);
    }
  };

  useEffect(() => {
    const unsubscribe = store.subscribe(handleChange);
    return () => unsubscribe();
  }, deps);
}

export default useReduxAction;
