import getViewport from '@control-front-end/utils/getViewport';
import useWindowSize from './useWindowSize';

/**
 * Hook for getting viewport name depending on the current window size
 */
function useViewport() {
  const { width } = useWindowSize();

  return getViewport(width);
}

export default useViewport;
