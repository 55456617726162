import { call, takeLatest } from 'redux-saga/effects';
import api from '@control-front-end/common/sagas/api';
import {
  GET_FORM_ACCOUNTS,
  MANAGE_FORM_ACCOUNTS,
} from '@control-front-end/common/constants/forms';
import { RequestStatus } from '@control-front-end/common/constants/index';

/**
 * Get form default accounts
 */
function* getFormAccounts({ payload, callback }) {
  const { formId } = payload;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/form_accounts/${formId}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback(data.data);
}

/**
 * Create form default account
 */
function* createFormAccount(formId, accData) {
  const {
    accountType = 'fact',
    currencyId,
    nameId,
    treeCalculation,
    search,
    minLimit,
    maxLimit,
  } = accData;
  yield call(api, {
    method: 'post',
    url: `/form_accounts/${formId}`,
    body: {
      accountType,
      currencyId,
      nameId,
      treeCalculation,
      search,
      minLimit,
      maxLimit,
    },
  });
}

/**
 * Remove default form account
 */
function* removeFormAccount(id) {
  yield call(api, {
    method: 'delete',
    url: `/form_accounts/${id}`,
  });
}

/**
 * Manage default form accounts
 */
export function* manageFormAccounts({ payload }) {
  const { formId, formAccounts: formAccountsActions } = payload;
  for (const acc of formAccountsActions) {
    if (acc.action === 'create') {
      yield call(createFormAccount, formId, acc.data);
    } else {
      yield call(removeFormAccount, acc.data.id);
    }
  }
}

function* formAccounts() {
  yield takeLatest(GET_FORM_ACCOUNTS.REQUEST, getFormAccounts);
  yield takeLatest(MANAGE_FORM_ACCOUNTS.REQUEST, manageFormAccounts);
}

export default formAccounts;
