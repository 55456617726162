import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useIntl from 'useIntl';
import { Button, Label, Stack, Space } from 'mw-style-react';
import {
  GET_ACTOR,
  GET_TEMPLATE_ACTORS,
} from '@control-front-end/common/constants/graphActors';
import { INCOME_TYPE } from '@control-front-end/common/constants/actorAccounts';
import SelectActors from '@control-front-end/common/components/SelectActors';
import AppUtils from '@control-front-end/utils/utils';
import mes from './intl';
import scss from './DashboardFromActorFilter.scss';

const TOP_VALUES = [
  { value: 3, title: '3' },
  { value: 5, title: '5' },
  { value: 10, title: '10' },
  { value: 15, title: '15' },
  { value: 20, title: '20' },
  { value: 25, title: '25' },
  { value: 30, title: '30' },
];

/**
 * Settings panel for actors filter dashboard source
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function DashboardFromActorFilter({
  value = {},
  accounts = [],
  onChange,
  onAccountsChange,
}) {
  const t = useIntl();
  const dispatch = useDispatch();
  const systemForms = useSelector((state) => state.systemForms) || {};
  const systemFormsFilters = systemForms.actorfilters || {};
  const error =
    value.id &&
    (!value.filter?.accountNameId ||
      !value.filter?.currencyId ||
      !accounts.length);
  const currentTop = value.top || TOP_VALUES[0].value;

  const fetchActorAccounts = () => {
    const filter = { ...value.filter };
    if (!filter?.accountNameId || !filter?.currencyId) return;
    dispatch({
      type: GET_ACTOR.REQUEST,
      payload: {
        id: value.id,
      },
      callback: (filterActor) => {
        const filter = AppUtils.jsonParse(filterActor?.data?.filter, {});
        onChange({ ...value, filter });
        if (!filter?.accountNameId || !filter?.currencyId) return;
        dispatch({
          type: GET_TEMPLATE_ACTORS.REQUEST,
          payload: {
            formId: filter.formId,
            limit: value.top,
            offset: 0,
            filter,
            localState: true,
          },
          callback: (data) => {
            const newAccounts = data.list.map(({ actorId }) => ({
              actor: { id: actorId },
              color: AppUtils.getRandomColorByPalette(),
              account: {
                nameId: filter.accountNameId,
                currencyId: filter.currencyId,
              },
              incomeType: filter.incomeType || INCOME_TYPE.total,
            }));
            onAccountsChange(newAccounts);
          },
        });
      },
    });
  };

  const handleChange = ({ value: newValue }) => {
    if (!newValue) return;
    const { id, title, data } = newValue;
    const filter = AppUtils.jsonParse(data?.filter, {});
    const sourceModel = {
      id,
      title,
      top: currentTop,
      filter,
    };
    onChange(sourceModel);
  };

  const handleChangeTop = (newTop) => {
    onChange({ ...value, top: newTop });
  };

  useEffect(() => {
    fetchActorAccounts();
  }, [value?.id, value?.top]);

  const getErrorText = () => {
    if (!value.id || !error) return '';
    return !value.filter?.accountNameId || !value.filter?.currencyId
      ? t(mes.filterHasNoAccountSettings)
      : t(mes.filterHasNoAccounts);
  };

  const renderTopValues = () =>
    TOP_VALUES.map((item) => (
      <Button
        key={`top_value_${item.value}`}
        className={scss.button}
        type={item.value === currentTop ? 'default' : 'primary'}
        label={item.title}
        size="small"
        fontWeight="normal"
        onClick={() => handleChangeTop(item.value)}
      />
    ));
  return (
    <Space right left size={Space.SIZE.large}>
      <Stack.H
        className={scss.sourceType}
        alignItems="flexStart"
        fullWidth
        size={Stack.SIZE.xsmall}
      >
        <div className={scss.filterSelect}>
          <SelectActors
            key="actorFilter"
            id="actorFilter"
            bordered={true}
            type="modal"
            multiselect={false}
            error={error}
            helperText={getErrorText()}
            label={t(mes.selectActorFilterCapital)}
            placeholder={t(mes.selectActorFilter)}
            formId={systemFormsFilters.id}
            value={value}
            exclude={[]}
            withData
            onChange={handleChange}
          />
        </div>
        <div className={scss.topSelect}>
          <Label fontWeight="semibold" value={t(mes.top)} />
          <Stack.H size={Stack.SIZE.xxsmall}>{renderTopValues()}</Stack.H>
        </div>
      </Stack.H>
    </Space>
  );
}

export default DashboardFromActorFilter;
