import { defineMessages } from 'react-intl';

const m = defineMessages({
  script: {
    id: 'script_PersonalScript',
    defaultMessage: 'Script',
  },
  users: {
    id: 'users_PersonalScript',
    defaultMessage: 'Users',
  },
  settings: {
    id: 'settings_PersonalScript',
    defaultMessage: 'Settings',
  },
  autorun: {
    id: 'autorun_PersonalScript',
    defaultMessage: 'Autorun',
  },
  expirationDate: {
    id: 'expirationDate_PersonalScript',
    defaultMessage: 'Expiration Date',
  },
  expirationType: {
    id: 'expirationType_PersonalScript',
    defaultMessage: 'Expiration type',
  },
  timeout: {
    id: 'timeout_PersonalScript',
    defaultMessage: 'Timeout',
  },
  dateTime: {
    id: 'dateTime_PersonalScript',
    defaultMessage: 'Date & time',
  },
  timeoutSec: {
    id: 'timeoutSec_PersonalScript',
    defaultMessage: 'Timeout, sec',
  },
  addScript: {
    id: 'addScript_PersonalScript',
    defaultMessage: 'Add script',
  },
  editScript: {
    id: 'editScript_PersonalScript',
    defaultMessage: 'Edit script',
  },
});

export default m;
