import { call, takeLatest, select, all, put } from 'redux-saga/effects';
import api from '@control-front-end/common/sagas/api';
import {
  RECENT,
  RECENT_ACTORS,
  RECENT_ACTORS_GROUPED_BY_TPL,
  RequestStatus,
} from 'constants';
import { GET_ACTORS_LIST } from '@control-front-end/common/constants/graphActors';
import { extendModelWithAvatars, makeActorPicture } from './graph/graphHelpers';

/**
 * Get the last opened objects
 */
export function* getRecentObjs({ payload, callback }) {
  const { objType, customActor, userId, limit } = payload;
  const accounts = yield select((state) => state.accounts);
  const accId = payload.accId || accounts.active;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/suggestions/recent/${accId}`,
    queryParams: { objType, customActor, limit, userId },
  });
  if (result !== RequestStatus.SUCCESS) return;
  for (const obj of data.data) {
    obj.pictureUrl = yield makeActorPicture(obj);
  }
  if (callback) callback(data.data);
  return data.data;
}

/**
 * Get the latest open actors
 */
export function* getRecentActors({ callback }) {
  const actors = yield call(getRecentObjs, {
    payload: { objType: 'actor', limit: 15 },
  });
  for (const obj of actors) {
    yield call(extendModelWithAvatars, obj);
  }
  const newActorsList = structuredClone(actors);
  newActorsList.forEach((i) => {
    i.actorId = i.id;
  });
  yield put({
    type: GET_ACTORS_LIST.SUCCESS,
    payload: {
      list: newActorsList,
      total: newActorsList.length,
      reqStatus: RequestStatus.SUCCESS,
    },
  });
  if (callback) callback();
}

/**
 * Get the latest open actors
 */
export function* getRecentActorsByFormId({ payload, callback }) {
  const { formId, customActor, limit } = payload;
  const accounts = yield select((state) => state.accounts);
  const accId = payload.accId || accounts.active;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/suggestions/recent/${accId}`,
    queryParams: { objType: 'actor', formId, customActor, limit },
  });
  if (result !== RequestStatus.SUCCESS) return;
  for (const obj of data.data) {
    obj.pictureUrl = yield makeActorPicture(obj);
  }
  if (callback) callback(data.data);
  return data.data;
}

/**
 * Get the latest open actors for smart chips
 */
function* getRecentActorsGrouped({ callback }) {
  const systemForms = yield select((state) => state.systemForms);
  const eventsFormId = systemForms.events.id;
  const graphsFormId = systemForms.graphs.id;
  const [actorsRecent, events, graphs] = yield all([
    call(getRecentObjs, {
      payload: {
        objType: 'actor',
        limit: 20,
      },
    }),
    call(getRecentActorsByFormId, {
      payload: { formId: systemForms.events.id },
    }),
    call(getRecentActorsByFormId, {
      payload: { formId: systemForms.graphs.id },
    }),
  ]);
  const actors = actorsRecent.filter(
    (i) => i.formId !== eventsFormId && i.formId !== graphsFormId
  );
  if (callback) callback({ actors, events, graphs });
}

function* suggestions() {
  yield takeLatest(RECENT.REQUEST, getRecentObjs);
  yield takeLatest(RECENT_ACTORS.REQUEST, getRecentActors);
  yield takeLatest(
    RECENT_ACTORS_GROUPED_BY_TPL.REQUEST,
    getRecentActorsGrouped
  );
}

export default suggestions;
