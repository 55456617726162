import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, MenuItem } from 'mw-style-react';
import UserAvatar from '@control-front-end/common/components/UserAvatar';
import cn from 'classnames';
import { LOGOUT } from 'constants';
import useOutsideClick from 'useOutsideClick';
import useIntl from 'useIntl';
import mes from './intl';
import './UserProfile.scss';

function UserProfile({ collapsed }) {
  const t = useIntl();
  const dispatch = useDispatch();
  const profileBox = useRef();
  const [profileMenu, toggleProfileMenu] = useState(false);
  const auth = useSelector((state) => state.auth);
  const { active: accId } = useSelector((state) => state.accounts);
  const userRoles = auth.workspaces[accId]?.roles || [];
  const isGuest = userRoles.every((i) => i.name === 'Guest');
  const config = useSelector((state) => state.config);
  const saUrl = config.saUrl
    ? `${config.saUrl}`
    : 'https://account.corezoid.com';

  const closeMenu = () => toggleProfileMenu(false);

  useOutsideClick({ ref: profileBox, callback: closeMenu });

  const handleProfileMenuClick = ({ value }) => {
    if (value === 'logout') {
      dispatch({ type: LOGOUT.REQUEST });
    } else if (value === 'profile') {
      const profileUrl = `${saUrl}/profile`;
      window.open(profileUrl);
    } else if (value === 'users') {
      const profileUrl = `${saUrl}/workspace/${accId}/user`;
      window.open(profileUrl);
    }
    closeMenu();
  };

  return (
    <div ref={profileBox} styleName="profile">
      <div styleName="profile__avatar__box">
        <UserAvatar
          styleName="profile__avatar"
          src={auth.avatar}
          label={auth.nick}
          userType={isGuest ? 'guest' : 'user'}
          onClick={() => toggleProfileMenu((prevProfile) => !prevProfile)}
        />
      </div>
      {profileMenu ? (
        <Menu
          styleName={cn('profile__menu', { collapsed })}
          onClick={handleProfileMenuClick}
        >
          <MenuItem value="profile" label={t(mes.profile)} />
          <MenuItem value="users" label={t(mes.account)} />
          <MenuItem value="logout" label={t(mes.logout)} />
        </Menu>
      ) : null}
    </div>
  );
}

UserProfile.propTypes = {
  collapsed: PropTypes.bool,
};

export default UserProfile;
