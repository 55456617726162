import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'mw-style-react';

import { useIntl } from 'hooks';
import InviteMembers from '@control-front-end/app/src/components/InviteMembers';

import m from './intl';

function InviteMembersModal({ onClose }) {
  const t = useIntl();

  const handleClose = useCallback(() => onClose());

  return (
    <Modal size="medium" onClose={handleClose} label={t(m.inviteUsers)} strict>
      <InviteMembers onSubmit={handleClose} />
    </Modal>
  );
}

InviteMembersModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default InviteMembersModal;
