import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GET_ACTOR } from '@control-front-end/common/constants/graphActors';
import getActorFieldsMap from '@control-front-end/utils/getActorFieldsMap';

const initialFields = {};

const useActorFields = (actorId) => {
  const dispatch = useDispatch();

  const [fields, setFields] = useState(initialFields);

  useEffect(() => {
    if (!actorId) {
      setFields(initialFields);
      return;
    }

    dispatch({
      type: GET_ACTOR.REQUEST,
      payload: { id: actorId },
      callback: (actor) => setFields(getActorFieldsMap(actor)),
    });
  }, [actorId]);

  return fields;
};

export default useActorFields;
