import {
  GET_SCRIPT_STRUCTURE,
  CREATE_SCRIPT_OBJECTS,
  UPDATE_SCRIPT_OBJECTS,
  REMOVE_SCRIPT_OBJECTS,
  CLEAR_SCRIPT_STRUCTURE,
} from '@control-front-end/common/constants/scripts';
import { createReducer } from '@reduxjs/toolkit';

// ------------------------------------
// Reducer
// ------------------------------------

const initState = {
  content: {},
  reqStatus: 'success',
};

export default createReducer(initState, {
  [GET_SCRIPT_STRUCTURE.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [CREATE_SCRIPT_OBJECTS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [UPDATE_SCRIPT_OBJECTS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [REMOVE_SCRIPT_OBJECTS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [CLEAR_SCRIPT_STRUCTURE]() {
    return initState;
  },
});
