import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UPDATE_ACTOR } from '@control-front-end/common/constants/graphActors';
import {
  Modal,
  ModalButtons,
  ModalContent,
  Button,
  Stack,
} from 'mw-style-react';
import { useIntl } from 'hooks';
import SelectAccountCurrency from '@control-front-end/common/components/SelectAccountCurrency';
import SelectAccountName from '@control-front-end/common/components/SelectAccountName';
import mes from './intl';

/**
 * Set default layer account
 */
function LayerAccount(props) {
  const { data, visibility, onClose, isSubmit } = props;
  const { id: actorId, formId, handleUpdateLayer } = data;
  const t = useIntl();
  const dispatch = useDispatch();
  const [currency, setCurrency] = useState(null);
  const [accName, setAccName] = useState(null);

  /**
   * Update actor layer
   */
  const handleSubmit = useCallback(() => {
    const formData = {
      accountName: [
        { title: accName.title, value: accName.id, type: 'accountName' },
      ],
      currency: [
        { title: currency.title, value: currency.id, type: 'currency' },
      ],
    };
    dispatch({
      type: UPDATE_ACTOR.REQUEST,
      payload: {
        formId,
        actorId,
        formData,
      },
      callback: () => {
        handleUpdateLayer({ id: actorId, formData });
        onClose();
      },
    });
  }, [accName, currency]);

  return (
    <Modal
      visibility={visibility}
      label={t(mes.layerAccount)}
      size="large"
      onClose={onClose}
    >
      <ModalContent>
        <Stack.H fullWidth alignItems="center">
          <SelectAccountName
            placeholder={t(mes.enterAccName)}
            bordered={true}
            type="modal"
            size="large"
            label={t(mes.account)}
            value={accName}
            onChange={setAccName}
          />
          <SelectAccountCurrency
            placeholder={t(mes.enterAccCurrency)}
            bordered={true}
            type="modal"
            size="large"
            label={t(mes.currency)}
            value={currency}
            onChange={setCurrency}
          />
        </Stack.H>
      </ModalContent>
      <ModalButtons>
        <Button
          label={t(mes.save)}
          size="large"
          onClick={handleSubmit}
          visibility={
            isSubmit || !accName || !currency ? 'disabled' : 'visible'
          }
        />
      </ModalButtons>
    </Modal>
  );
}

LayerAccount.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  isSubmit: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    formId: PropTypes.number.isRequired,
    handleUpdateLayer: PropTypes.func.isRequired,
  }).isRequired,
};

export default LayerAccount;
