import React from 'react';
import PropTypes from 'prop-types';

const Context = React.createContext();

function IntlProviderWrapper({ children, locale, messages }) {
  return (
    <Context.Provider value={{ locale, messages }}>{children}</Context.Provider>
  );
}

IntlProviderWrapper.defaultProps = {
  messages: {},
};

IntlProviderWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string.isRequired,
  messages: PropTypes.object,
};

export { IntlProviderWrapper, Context as IntlContext };
