import { createReducer } from '@reduxjs/toolkit';
import { SEARCH, CLEAR_SEARCH, SET_SEARCH_REQ_STATUS } from '../constants';

// ------------------------------------
// Reducer
// ------------------------------------
const initState = {
  list: [],
  limit: 15,
  offset: 0,
  endList: false,
  reqStatus: 'success',
};

export default createReducer(initState, {
  [SEARCH.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SET_SEARCH_REQ_STATUS](state, action) {
    return { ...state, reqStatus: action.payload };
  },
  [CLEAR_SEARCH]() {
    return initState;
  },
});
