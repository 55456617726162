import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  makeMultipleCopies: {
    id: 'makeMultipleCopies',
    defaultMessage: 'Make multiple copies',
  },
  copiesCount: {
    id: 'copiesCount',
    defaultMessage:
      'Please specify the number of actor copies that need to be created',
  },
  createCopies: {
    id: 'createCopies',
    defaultMessage: 'Create actor copies',
  },
});

export default { ...globalIntl, ...m };
