import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  MenuItem,
  TextField,
  Calendar,
  DateUtils,
  Space,
  Label,
} from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import getTranslation from '@control-front-end/utils/getTranslation';
import { DATE_FORMAT_2 } from 'constants';
import { useIntl } from 'hooks';
import mes from './intl';
import '../../CreateActorsFilter.scss';

export const DATE_RANGES = [
  'custom',
  'today',
  'yesterday',
  'lastWeek',
  'lastMonth',
  'previousMonth',
];

const DEFAULT_ORDER_BY_OPTIONS = ['created_at', 'updated_at'].map((i) => ({
  value: i,
  label: getTranslation(mes[`order_${i}`]),
}));

function SpacedTitle({ ...props }) {
  return (
    <Space bottom size={Space.SIZE.xsmall}>
      <Label color={Label.COLOR.black} fontWeight="semibold" {...props} />
    </Space>
  );
}

function DateRange({
  from,
  to,
  orderBy,
  extraOrderByOptions,
  disabledFields,
  errors,
  onChange,
}) {
  const t = useIntl();
  const [rangeType, setRangeType] = useState('custom');
  const [order, setOrderBy] = useState(orderBy || 'created_at');
  const todayDateEnd = Math.round(
    DateUtils.endOf(new Date().getTime(), 'day') / 1000
  );

  const handleChangeDate = ({ id, value }) => {
    setRangeType('custom');
    const fDate = id === 'from' ? 'startOf' : 'endOf';
    const date = DateUtils[fDate](value.startDate * 1000, 'day');
    const unix = DateUtils.dateToUnixtime(date);
    const checkDate = id === 'from' ? to < unix : from > unix;
    onChange({ id, value: unix, error: checkDate ? 'invalidRange' : false });
  };

  const handleChangeRange = (value) => {
    const dateRange = AppUtils.getRangeDate(value);
    setRangeType(value);
    onChange({ id: 'from', value: dateRange.from });
    onChange({ id: 'to', value: dateRange.to });
  };

  const handleResetDateFrom = useCallback(
    () => onChange({ id: 'from', value: undefined, error: false }),
    [onChange]
  );

  const handleResetDateTo = useCallback(
    () => onChange({ id: 'to', value: undefined, error: false }),
    [onChange]
  );

  const handleChangeOrder = (value) => {
    setOrderBy(value);
    onChange({ id: 'orderBy', value });
  };

  return (
    <>
      <div styleName="filter__item">
        <Select
          id="fieldSearch"
          styleName="filter__select openUp"
          size="medium"
          bordered
          unspaced
          value={order}
          label={<SpacedTitle value={t(mes.orderBy)} />}
          onChange={({ value }) => handleChangeOrder(value)}
        >
          {[...DEFAULT_ORDER_BY_OPTIONS, ...extraOrderByOptions].map(
            ({ value, ...rest }) => {
              return <MenuItem key={value} value={value} {...rest} />;
            }
          )}
        </Select>
      </div>
      <div styleName="filter__item">
        <Select
          id="rangeType"
          styleName="filter__select openUp"
          size="medium"
          bordered
          unspaced
          value={rangeType}
          label={<SpacedTitle value={t(mes.actorDateRangePeriod)} />}
          onChange={({ value }) => handleChangeRange(value)}
          visibility={
            disabledFields.includes('period') ? 'disabled' : 'visible'
          }
        >
          {DATE_RANGES.map((item) => (
            <MenuItem key={item} value={item} label={t(mes[`range_${item}`])} />
          ))}
        </Select>
      </div>
      <div styleName="filter__item">
        <TextField
          styleName="filter__textfield"
          size="medium"
          bordered
          unspaced
          value={from ? DateUtils.toDate(from, DATE_FORMAT_2) : ''}
          error={!!errors.to}
          label={<SpacedTitle value={t(mes.from)} />}
          resettable
          onReset={handleResetDateFrom}
          calendar={() => (
            <Calendar
              id="from"
              styleName="filter__calendar"
              size="small"
              value={{ startDate: from }}
              onChange={handleChangeDate}
              maxDate={todayDateEnd}
            />
          )}
          visibility={disabledFields.includes('from') ? 'disabled' : 'visible'}
        />
      </div>
      <div styleName="filter__item">
        <TextField
          styleName="filter__textfield"
          size="medium"
          bordered
          unspaced
          value={to ? DateUtils.toDate(to, DATE_FORMAT_2) : ''}
          error={!!errors.to || !!errors.from}
          helperText={t(mes[errors.to || errors.from])}
          label={<SpacedTitle value={t(mes.to)} />}
          resettable
          onReset={handleResetDateTo}
          calendar={() => (
            <Calendar
              id="to"
              styleName="filter__calendar dateTo"
              size="small"
              value={{ startDate: to }}
              onChange={handleChangeDate}
              maxDate={todayDateEnd}
            />
          )}
          visibility={disabledFields.includes('to') ? 'disabled' : 'visible'}
        />
      </div>
    </>
  );
}

DateRange.defaultProps = {
  extraOrderByOptions: [],
  disabledFields: [],
};

DateRange.propTypes = {
  from: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orderBy: PropTypes.string,
  extraOrderByOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  disabledFields: PropTypes.arrayOf(PropTypes.string),
  errors: PropTypes.object,
  onChange: PropTypes.func,
};

export default DateRange;
