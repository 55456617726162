import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import { Modal, ModalContent } from 'mw-style-react';
import JSONEditorReact from '@control-front-end/common/components/JSONEditorReact';
// eslint-disable-next-line no-unused-vars
import m from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import l from './JsonPreview.scss'; // NOSONAR

/**
 * JSON full preview
 * @param data
 * @param visibility
 * @param onClose
 * @returns {JSX.Element}
 * @constructor
 */
function JsonPreview({ data, visibility, onClose }) {
  const previewRef = useRef();
  const [json, setJson] = useState({});

  useEffect(() => {
    // после завершения анимации отрисовки модального окна
    setTimeout(() => setJson(data.json || {}), 20);
  }, []);

  return (
    <Modal
      size="xlarge"
      styleName="m.modal l.modal"
      visibility={visibility}
      onClose={onClose}
      label="JSON"
    >
      <ModalContent styleName="m.modal__content l.modal__content">
        <JSONEditorReact
          jsonEditorRef={previewRef}
          schema={{}}
          text=""
          json={json}
          mode="code"
          mainMenuBar={true}
          indentation={2}
          onChange={() => {}}
          onError={() => {}}
          onEditable={() => false}
        />
      </ModalContent>
    </Modal>
  );
}

JsonPreview.propTypes = {
  visibility: PropTypes.bool.isRequired,
  data: PropTypes.object,
  onClose: PropTypes.func,
};

export default JsonPreview;
