import { useMemo } from 'react';
import { useOutside } from 'mw-style-react';

/**
 * A custom hook that triggers a callback function when a click
 * or contextmenu event happens outside the provided element.
 *
 * @param {object} param - An object containing the ref, element, and callback function.
 * @param {object} param.ref - A reference to the element.
 * @param {HTMLElement} param.element - The element to check if the click happened outside.
 * @param {array} param.triggers - An array of event types to listen for.
 * @param {function} param.callback - The callback function to execute.
 * @return {void}
 */
const useOutsideClick = ({ ref, element, triggers, callback }) => {
  const listeningElement = useMemo(() => document.getElementById('root'), []);
  useOutside({
    ref,
    element,
    callback,
    triggers: triggers || ['click', 'contextmenu'],
    listeningElement,
  });
};

export default useOutsideClick;
