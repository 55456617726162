import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  scriptTplTitle: {
    id: 'scriptTplTitle',
    defaultMessage: 'Script',
  },
  accessDeniedActor: {
    id: 'accessDeniedActor',
    defaultMessage:
      "You don't have access to view actor data. Ask the owner for access.",
  },
  fieldHistory: {
    id: 'fieldHistory',
    defaultMessage: 'Show history',
  },
  keyCopied: {
    id: 'keyCopied',
    defaultMessage: 'Key has been copied to clipboard',
  },
});

export default { ...globalIntl, ...m };
