export const URL_REGEXP =
  "((https?|ftp):\\/\\/[-A-ZА-Я0-9+&@#\\/%?=~_|!:,.;']*[-A-ZА-Я0-9+()&@#\\/%=.~_|]*)";
export const MAILTO_REGEX =
  '(\\s[a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,20})';
export const UUID =
  '^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$';
export const RE_UUID_GLOBAL =
  '[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}';
export const RE_UUID_SHORT = '[0-9a-f]{8}';
export const FORMULA_SYMBOLS = '^[0-9|\\+\\-\\*\\/\\(\\)\\.\\,]+$';
export const FLOAT_NUMBER_INPUT = '^-?([0-9]+[.,]?)?[0-9]*$';
export const RE_APP_SHORT_NAME = '^[0-9a-zA-Z_-]{5,255}$';
export const SEARCH_SMART_CHIP_REGEXP =
  /@?\s?<div\s*class="searchChip">(.*?)<\/div>/gi; // NOSONAR
export const SEARCH_HTML_TITLE = /<title[^>]*>([^<]+)<\/title>/;
export const INVITE_EMAIL_REGEXP =
  "^((([a-z]|\\d|[!#\\$%&'\\*\\+\\-\\/=\\?\\^_`{\\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\\.([a-z]|\\d|[!#\\$%&'\\*\\+\\-\\/=\\?\\^_`{\\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\\d|-|\\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\\d|-|\\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\\.?$"; // eslint-disable-line max-len
