import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  itemIdCopied: {
    id: 'itemIdCopied',
    defaultMessage: 'ID has been copied to clipboard',
  },
  itemIdEmpty: {
    id: 'itemIdEmpty',
    defaultMessage: 'ID has no value',
  },
  saveId: {
    id: 'saveId',
    defaultMessage: 'Save',
  },
  errorIdNotUnique: {
    id: 'errorIdNotUnique',
    defaultMessage: 'ID must be unique',
  },
});

export default { ...globalIntl, ...m };
