import React, { useCallback } from 'react';
import { Icon, Checkbox, Select, Stack, Button } from 'mw-style-react';
import { FilterAccPairPopover } from 'components';
import { useIntl } from 'hooks';
import { ViewDataPropType } from '../../propTypes';
import m from './intl';
import scss from './AccountsSelector.scss';

function AccountSelector({ onChange, onRemoveItem, value, index }) {
  const handeleChange = useCallback(
    ({ value }) => onChange({ value, index }),
    [index, onChange]
  );

  const handleDisplayCurrencyToggle = useCallback(
    () =>
      onChange({
        value: {
          ...value,
          displayCurrency: !value.displayCurrency,
        },
        index,
      }),
    [onChange, value, index]
  );

  const handleRemove = useCallback(
    () => onRemoveItem({ index }),
    [onRemoveItem]
  );

  return (
    <Stack horizontal fullWidth alignItems="center" size={Stack.SIZE.small}>
      <div style={{ flexGrow: 1 }}>
        <FilterAccPairPopover onChange={handeleChange}>
          <Select
            size="medium"
            value={`${value.accountName}, ${value.currencyName}`}
            bordered
            unspaced
            className={scss.select}
          />
        </FilterAccPairPopover>
      </div>
      <Checkbox
        value={value.displayCurrency}
        onChange={handleDisplayCurrencyToggle}
        unspaced
      >
        Currency
      </Checkbox>
      <Button
        type="text"
        size="xsmall"
        icon={<Icon type="trash" />}
        onClick={handleRemove}
      />
    </Stack>
  );
}

const MAX_ITEMS_AMOUNT = 3;

function AccountsSelector({ accounts, onChange }) {
  const t = useIntl();
  const handeleChange = useCallback(
    ({ value, index }) => {
      const newAccounts = [...accounts];
      newAccounts[index] = {
        ...value,
        displayCurrency: Boolean(value.displayCurrency),
      };
      onChange({
        value: newAccounts,
      });
    },
    [onChange, accounts]
  );

  const handleRemove = useCallback(
    ({ index }) => {
      const newAccounts = [...accounts];
      newAccounts.splice(index, 1);
      onChange({
        value: newAccounts,
      });
    },
    [onChange, accounts]
  );

  const handleAdd = useCallback(
    ({ value }) => {
      onChange({
        value: [
          ...accounts,
          {
            ...value,
            displayCurrency: Boolean(value.displayCurrency),
          },
        ],
      });
    },
    [onChange, accounts]
  );

  return (
    <Stack>
      {accounts.map((account, index) => {
        return (
          <AccountSelector
            key={index}
            index={index}
            value={account}
            onChange={handeleChange}
            onRemoveItem={handleRemove}
          />
        );
      })}
      {accounts.length < MAX_ITEMS_AMOUNT ? (
        <FilterAccPairPopover onChange={handleAdd}>
          <Button size="small" icon="add" label={t(m.add)} type="text" />
        </FilterAccPairPopover>
      ) : null}
    </Stack>
  );
}

AccountsSelector.propTypes = {
  accounts: ViewDataPropType.accounts,
};

export default AccountsSelector;
