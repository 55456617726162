import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import AppUtils from '@control-front-end/utils/utils';
import { SmartChipPreview } from 'components';

import { replaceImagesInDesAndDecodeHtml } from '../../utils';
import scss from '../../EventsCalendar.scss';

function Description({ description = '', accId = '' }) {
  const descriptionRef = useRef(null);

  return (
    <span ref={descriptionRef} className={scss.description}>
      <div
        dangerouslySetInnerHTML={{
          __html: replaceImagesInDesAndDecodeHtml(
            AppUtils.bbCodeToHtml(description, accId)
          ),
        }}
      />
      <SmartChipPreview accId={accId} containerRef={descriptionRef} />
    </span>
  );
}

Description.propTypes = {
  description: PropTypes.string,
  accId: PropTypes.string,
};

export default Description;
