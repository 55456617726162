import React from 'react';
import { Label } from 'mw-style-react';
import cn from 'classnames';

import scss from '../../EventsCalendar.scss';

function CutEventLabel(props) {
  return (
    <Label
      overflow="cut"
      className={cn(scss.eventLabel, scss.cutLabel)}
      {...props}
    />
  );
}

export default CutEventLabel;
