import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  actorsLayers: {
    id: 'actorsLayers',
    defaultMessage: 'Actors layers',
  },
  searchLayerByNameId: {
    id: 'searchLayerByNameId',
    defaultMessage: 'Search layer by name or ID',
  },
});

export default { ...globalIntl, ...m };
