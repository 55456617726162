import { defineMessages } from 'react-intl';

const m = defineMessages({
  selectAccount: {
    id: 'selectAccount__SelectActorsAccountPair',
    defaultMessage: 'Select account',
  },
  noAccounts: {
    id: 'noAccounts__SelectActorsAccountPair',
    defaultMessage: 'No accounts',
  },
  noAccountsWithCurrency: {
    id: 'noAccountsWithCurrency__SelectActorsAccountPair',
    defaultMessage: 'No accounts with currency {currencyName}',
  },
});

export default m;
