import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { GET_FORMS_TREE } from '@control-front-end/common/constants/forms';
import GraphEngine from '../GraphEngine';
import './FormMap.scss';

function FormMap({ formId, form, activeIds = [], handleDoubleClickNode }) {
  const graphRef = useRef();
  const graphMountFlag = useRef();
  const dispatch = useDispatch();
  const [formsGraph, setFormsGraph] = useState([]);
  const bbCodeAccessDenied = `[br][color=#b6bcc6]Access denied[/color]`;

  const getWithActiveNodes = (els) =>
    els.map((item) => {
      const el = { ...item };
      el.data.active = activeIds.includes(+el.data.id);
      return el;
    });

  useEffect(() => {
    dispatch({
      type: GET_FORMS_TREE.REQUEST,
      payload: { formId, form },
      callback: (data) => {
        data.forEach(({ data: formNode }) => {
          if (formNode.accessDenied)
            formNode.title = `${formNode.title}${bbCodeAccessDenied}`;
        });
        if (activeIds.length) {
          setFormsGraph(getWithActiveNodes(data));
        } else {
          setFormsGraph(data);
        }
      },
    });
  }, []);

  useEffect(() => {
    if (!formsGraph.length) return;
    setFormsGraph(getWithActiveNodes(formsGraph));
  }, [activeIds.length]);

  const highlightSelectedNodes = () => {
    graphRef.current.elements().removeClass('activeFormNode');
    const activeNodes = graphRef.current.elements('node[?active]');
    activeNodes.addClass('activeFormNode');
  };

  const handleDoubleClick = ({ e }) => {
    const node = e.target;
    const formId = +node.id();
    if (node.data('accessDenied')) return;
    handleDoubleClickNode(formId);
  };

  if (!formsGraph.length) return null;
  return (
    <div styleName="formMap">
      <GraphEngine
        graph={graphRef}
        graphMountFlag={graphMountFlag}
        layoutName="dagre"
        els={formsGraph}
        fit={true}
        enableBgGrid={true}
        readOnly={false}
        lasso={false}
        isLoaded={true}
        applyExtraStyles={highlightSelectedNodes}
        handleDoubleClickNode={handleDoubleClick}
      />
    </div>
  );
}

FormMap.propTypes = {
  formId: PropTypes.number,
  activeIds: PropTypes.array,
  handleDoubleClickNode: PropTypes.func,
};

export default FormMap;
