import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';
import { Icon, Label, TextField } from 'mw-style-react';
import cn from 'classnames';
import './ExpandedNodeTitle.scss';

/**
 * Компонент ExpandedNodeTitle
 */
function ExpandedNodeTitle({
  title,
  initTitle,
  isTitleEdit,
  readOnly,
  onToggleEdit,
  onChange,
  onSave,
}) {
  const titleRef = useRef();

  useEffect(() => {
    if (!isTitleEdit && title !== initTitle) onSave();
  }, [isTitleEdit]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      onToggleEdit(false);
    } else if (e.key === 'Escape') {
      onChange(initTitle);
      onToggleEdit(false);
    }
  };

  const renderTitleLabel = () => {
    return (
      <div
        styleName={cn('title__label', { canEdit: !readOnly })}
        onClick={() => onToggleEdit(true)}
      >
        <div styleName="title__label__text">
          <Label value={title} />
        </div>
        {!readOnly ? (
          <div styleName="title__label__icon">
            <Icon type="edit" size="small" />
          </div>
        ) : null}
      </div>
    );
  };

  const renderTitleEdit = () => {
    return (
      <div ref={titleRef} styleName="title__edit">
        <TextField
          styleName="title__edit__field"
          autoFocus={true}
          autoSelect="mount"
          bordered={false}
          value={title}
          onChange={({ value }) => onChange(value)}
          onKeyDown={handleKeyPress}
          onBlur={() => {
            onToggleEdit(false);
          }}
        />
      </div>
    );
  };
  return (
    <div styleName="title">
      {isTitleEdit && !readOnly ? renderTitleEdit() : renderTitleLabel()}
    </div>
  );
}

ExpandedNodeTitle.propTypes = {
  title: PropTypes.string.isRequired,
  initTitle: PropTypes.string.isRequired,
  isTitleEdit: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  onToggleEdit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ExpandedNodeTitle;
