import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import keyBy from 'lodash/keyBy';
import { Button, Card, Stack, Label, Divider, Space } from 'mw-style-react';

import { useIntl } from 'hooks';
import {
  CREATE_EDGE,
  VALENCY_GRAPH,
  VALENCY_LINKER_TYPES,
} from '@control-front-end/common/constants/graphActors';
import SelectActors from '@control-front-end/common/components/SelectActors';
import m from './intl';

function ValencyLinker({ targetId, type, onCancel, onSuccess }) {
  const t = useIntl();
  const [actor, setActor] = useState(null);

  const dispatch = useDispatch();

  const edgeTypes = useSelector((state) => state.edgeTypes);

  const valencyActors = useSelector((state) => state.graphValency.list);
  const hint = type === 'parent' ? t(m.helperTextParent) : t(m.helperTextChild);

  return (
    <Card style={{ width: '330px' }}>
      <Space>
        <Stack.H fullWidth alignItems="center">
          <Button size="xsmall" type="text" label="Cancel" onClick={onCancel} />
          <Label
            fontWeight="semibold"
            fontSize="large"
            value={t(m.createLink)}
          />
        </Stack.H>
      </Space>
      <Divider />
      <Space>
        <Stack.V>
          <SelectActors
            bordered
            multiselect={false}
            fullModel
            unspaced
            wrap={false}
            exclude={[...(actor ? [actor] : []), ...valencyActors]}
            size="medium"
            autoFocus={false}
            value={actor}
            onChange={({ value }) => setActor(value)}
            onReset={() => setActor(null)}
            resettable
            placeholder={hint}
            helperText={hint}
            popoverOnTop
            checkItemVisibility={({ privs, isDisabled }) =>
              privs.modify && !isDisabled ? 'visible' : 'disabled'
            }
          />
          <Button
            label={t(m.createLink)}
            visibility={actor ? 'visible' : 'disabled'}
            onClick={() => {
              if (!actor?.id) return;
              dispatch({
                type: CREATE_EDGE.REQUEST,
                payload: {
                  source: type === 'parent' ? actor.id : targetId,
                  target: type === 'parent' ? targetId : actor.id,
                  edgeTypeId: keyBy(edgeTypes, 'name').hierarchy.id,
                  ignoreLayerFunc: true,
                },
                callback: () => {
                  dispatch({
                    type: VALENCY_GRAPH.GET.REQUEST,
                    payload: { layerId: targetId },
                  });
                  onSuccess();
                },
              });
            }}
          />
        </Stack.V>
      </Space>
    </Card>
  );
}

ValencyLinker.propTypes = {
  type: PropTypes.oneOf(Object.values(VALENCY_LINKER_TYPES)).isRequired,
  targetId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default ValencyLinker;
