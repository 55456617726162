import {
  CLEAR_FORMS_FILTERS,
  SYSTEM_FORMS_FILTERS,
  SET_ACTIVE_FORMS_FILTER,
  ADD_FORMS_FILTER,
  HIDE_FORMS_FILTER,
} from '@control-front-end/common/constants/formsFilters';
import { createReducer } from '@reduxjs/toolkit';

// ------------------------------------
// Reducer
// ------------------------------------

const initState = {
  list: SYSTEM_FORMS_FILTERS,
  active: 'custom',
  limit: 100,
  offset: 0,
  total: 0,
  init: false,
  reqStatus: 'success',
  endList: false,
};

export default createReducer(initState, {
  [ADD_FORMS_FILTER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [HIDE_FORMS_FILTER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SET_ACTIVE_FORMS_FILTER](state, action) {
    return { ...state, active: action.payload };
  },
  [CLEAR_FORMS_FILTERS]() {
    return initState;
  },
});
