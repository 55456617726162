import { Utils } from 'mw-style-react';
import { FILTERS } from '@control-front-end/common/constants/filters';

const SETTINGS = {
  events: { filterIdKey: 'eventsKey', filterParamsKey: 'eventsParams' },
  tags: { filterIdKey: 'tagsKey', filterParamsKey: 'tagsParams' },
  files: { filterIdKey: 'filesKey', filterParamsKey: 'filesParams' },
  graph: { filterIdKey: 'graphKey', filterParamsKey: 'graphParams' },
  forms: { filterIdKey: 'formsKey', filterParamsKey: 'formsParams' },
};

const FILTERS_VALID_VALUES = {
  files: {
    orderBy: ['created_at', 'title'],
    people: ['all'],
  },
};

const getDefaultFilter = ({ type, filterId, filterIdKey }) => {
  const defaultSettings = FILTERS[type];
  const defaultFilterId =
    filterId || Utils.fromStorage(filterIdKey) || FILTERS[type][0].key;
  return defaultSettings.find((i) => i.key === defaultFilterId);
};

export default {
  // Получение настроек фильтра (сохраненные значение или значения по умолчанию)
  getFilter(type, filterId) {
    const { filterIdKey, filterParamsKey } = SETTINGS[type];
    if (
      type === 'events' &&
      (filterId === 'tags' || filterId === 'locations')
    ) {
      filterId = 'myToDoTasks';
    }
    const { visible: defaultParams, hidden: hiddenParams } = getDefaultFilter({
      type,
      filterId,
      filterIdKey,
    });
    const filterParams = {};
    let storageParams = {};
    // Парсим содержимое фильтра из localstorage
    try {
      storageParams = JSON.parse(
        Utils.fromStorage(filterParamsKey) || 'error string'
      );
    } catch (e) {
      storageParams = defaultParams;
    }
    const validFilterValues = FILTERS_VALID_VALUES[type];
    // Собираем объект с настройками фильтров
    Object.keys(defaultParams).forEach((key) => {
      const defaultValue = defaultParams[key];
      const storedValue = storageParams[key];
      if (defaultValue) {
        filterParams[key] =
          storedValue &&
          (!validFilterValues || validFilterValues[key].includes(storedValue))
            ? storedValue
            : defaultValue;
      }
    });
    // добавляем скрытые параметры
    const params = Object.assign(filterParams, hiddenParams);
    return { filterId, filterParams: params };
  },

  // Сохранение настрек раздела и фильтров к нему
  saveFilter(type, { filterId, filterParams }) {
    const { filterIdKey, filterParamsKey } = SETTINGS[type];
    if (
      type === 'events' &&
      (filterId === 'tags' || filterId === 'locations')
    ) {
      filterId = 'myToDoTasks';
    }
    if (filterId) Utils.toStorage(filterIdKey, filterId);
    if (filterParams) {
      const filterToSave = { ...filterParams };
      const validFilterValues = FILTERS_VALID_VALUES[type];
      if (validFilterValues) {
        const { visible: defaultParams } = getDefaultFilter({
          type,
          filterId,
          filterIdKey,
        });
        Object.keys(filterToSave).forEach((key) => {
          if (!validFilterValues[key]) {
            delete filterToSave[key];
            return;
          }
          if (!validFilterValues[key].includes(filterToSave[key])) {
            filterToSave[key] = defaultParams[key];
          }
        });
      }
      const str =
        typeof filterToSave === 'object'
          ? JSON.stringify(filterToSave)
          : filterParams;
      if (!str.length || str === '{}') return;
      Utils.toStorage(filterParamsKey, str);
    }
  },
};
