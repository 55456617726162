import {
  GET_FORMS,
  SEARCH_FORMS,
  CREATE_FORM,
  UPDATE_FORM,
  REMOVE_FORM,
  SET_FORMS_REQ_STATUS,
  CLEAR_FORMS,
  BULK_REMOVE_FORMS,
  FORMS_ACCESS,
} from '@control-front-end/common/constants/forms';
import { createReducer } from '@reduxjs/toolkit';

// ------------------------------------
// Reducer
// ------------------------------------

const initState = {
  list: [],
  limit: 15,
  offset: 0,
  loadCount: 0,
  totalTasks: 0,
  reqStatus: 'init',
  endList: false,
  init: false,
};

export default createReducer(initState, {
  [GET_FORMS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SEARCH_FORMS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [CREATE_FORM.SUCCESS](state, action) {
    return {
      ...state,
      ...action.payload,
      offset: state.offset + 1,
      total: state.total + 1,
    };
  },
  [UPDATE_FORM.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [FORMS_ACCESS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [REMOVE_FORM.SUCCESS](state, action) {
    return {
      ...state,
      ...action.payload,
      offset: state.offset - 1,
      total: state.total - 1,
    };
  },
  [BULK_REMOVE_FORMS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SET_FORMS_REQ_STATUS](state, action) {
    return { ...state, reqStatus: action.payload };
  },
  [CLEAR_FORMS]() {
    return initState;
  },
});
