import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  selectRange: {
    id: 'selectRange',
    defaultMessage: 'Select date range',
  },
  deletedTransaction: {
    id: 'deletedTransaction',
    defaultMessage: 'Transaction successfully deleted',
  },
  searchByAmountOrComment: {
    id: 'searchByAmountOrComment',
    defaultMessage: 'Search by ID, REF, amount or comment',
  },
});

export default { ...globalIntl, ...m };
