import { createReducer } from '@reduxjs/toolkit';
import { CONFIG, GET_CONFIG } from '../constants';

// ------------------------------------
// Reducer
// ------------------------------------
const initState = {};

export default createReducer(initState, {
  [CONFIG.SUCCESS](state, action) {
    return action.payload;
  },
  [GET_CONFIG.SUCCESS](state, action) {
    return action.payload;
  },
});
