import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Card } from 'mw-style-react';
import scss from './ActorCardView.scss';

function Block({
  className,
  design: { blockOpacity = 0.8 } = {},
  children,
  ...props
}) {
  return (
    <Card
      /**
       * "secondary" color with opacity 0.8
       * TOMAKE: Think about applying opacity on hex colors
       */
      color={`rgba(246, 247, 248, ${blockOpacity})`}
      boxShadow={Card.BOX_SHADOW.none}
      className={cn(className, scss.block)}
      withBorder={false}
      {...props}
    >
      {children}
    </Card>
  );
}

Block.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  design: PropTypes.shape({
    blockOpacity: PropTypes.number,
  }),
};

export default Block;
