import { createReducer } from '@reduxjs/toolkit';
import { SET_TAB_ID } from '../constants';
// ------------------------------------
// Reducer
// ------------------------------------
const initState = {};

export default createReducer(initState, {
  [SET_TAB_ID.SUCCESS](state, action) {
    return { ...state, tabId: action.payload };
  },
});
