import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Label,
  Badge,
  forwardRef,
  TextField,
  PortalWrapper,
} from 'mw-style-react';
import { RECENT } from 'constants';
import { SEARCH_ACTORS } from '@control-front-end/common/constants/graphActors';
import useIntl from 'useIntl';
import useOutsideClick from 'useOutsideClick';
import ActorAvatar from '@control-front-end/common/components/ActorAvatar';
import mes from './intl';
import './SelectActor.scss';

const ForwardTextField = forwardRef(TextField);

/**
 * Поиск акторов для выбора счетов
 */
function SelectActor({ id, value, error, autoFocus = false, onChange }) {
  const t = useIntl();
  const searchBox = useRef();
  const dispatch = useDispatch();
  const [actors, setActors] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [query, setQuery] = useState('');
  const [focus, setFocus] = useState(false);

  const clearSearch = (e) => {
    const actorsList = document.getElementById('actorsList');
    if (actorsList && actorsList.contains(e.target)) return;
    setQuery('');
    setFocus(false);
  };

  // Загрузить рекомендации по акторам (исключая системных)
  useEffect(() => {
    dispatch({
      type: RECENT.REQUEST,
      payload: { objType: 'actor' },
      callback: (data) => {
        setSuggestions(data);
      },
    });
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (!query.length) return;
      dispatch({
        type: SEARCH_ACTORS.REQUEST,
        payload: { query, localState: true },
        callback: (result) => {
          setActors(result);
          setFocus(true);
        },
      });
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [query]);
  useOutsideClick({ ref: searchBox, callback: clearSearch });

  const handleItemClick = (actorModel) => {
    setQuery('');
    setFocus(false);
    onChange({ id, value: actorModel });
  };

  const handleSearch = ({ value: newQuery }) => {
    setQuery(newQuery);
    if (newQuery.length < 2) setActors([]);
  };

  const renderItems = (list, title) => {
    const count = list.length;
    if (!count) return null;
    const items = list.map((i) => (
      <div
        key={i.id}
        styleName="sa__result__item"
        onClick={() => handleItemClick(i)}
      >
        <ActorAvatar
          size="small"
          type="compact"
          formType={i.formType}
          formTitle={i.formTitle}
          pictureUrl={i.pictureUrl}
          colors={i.allColors}
          status={i.status}
          colorFilled={true}
        />
        <div styleName="info">
          <Label value={i.title} />
          <div styleName="template">
            <Badge
              bgColor={i.color || '#ffffff'}
              borderColor={i.color || '#ACB3BE'}
            />
            <Label fontSize="small" value={i.formTitle} />
          </div>
        </div>
      </div>
    ));
    return (
      <>
        <div styleName="sa__result__header">
          <Label value={`${title}: ${count}`} fontSize="semibold" />
        </div>
        <div styleName="sa__result__content">{items}</div>
      </>
    );
  };

  const renderSearchResult = () => {
    if (!focus) return null;
    const isSearch = query.trim().length > 1;
    const resultList = isSearch ? actors : suggestions;
    const label = isSearch ? t(mes.searchResults) : t(mes.suggestions);

    return (
      <PortalWrapper
        root={document.getElementById('mainRoot')}
        node={searchBox.current}
        width={256}
        offsetY={-16}
      >
        <div id="actorsList" styleName="sa__result">
          {resultList.length ? renderItems(resultList, label) : null}
        </div>
      </PortalWrapper>
    );
  };

  return (
    <div ref={searchBox} styleName="sa">
      <ForwardTextField
        id={id}
        styleName="sa__field"
        size="small"
        value={focus ? query : value.title || ''}
        error={error}
        autoFocus={autoFocus}
        autoSelect={true}
        rightIcon={value.title ? 'dropdown' : null}
        placeholder={t(mes.searchActors)}
        onFocus={() => setFocus(true)}
        onChange={handleSearch}
      />
      {renderSearchResult()}
    </div>
  );
}

SelectActor.propTypes = {
  id: PropTypes.string,
  value: PropTypes.object,
  error: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
};

export default SelectActor;
