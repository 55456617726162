import React, { useRef, useMemo } from 'react';
import { Modal, ModalContent } from 'mw-style-react';
import PropTypes from 'prop-types';

import { useIntl } from 'hooks';
import { STATIC_LAYER } from 'constants';
import { GraphEngine } from 'components';

import {
  createNodesByStructure,
  getSkeletone,
} from '@control-front-end/app/src/routes/ActorsGraph/routers/Graph/components/StaticLayer/utils';
import mes from './intl';

import scss from './StaticGraph.scss';

function StaticGraph({ visibility, onClose, data }) {
  const t = useIntl();
  const graphRef = useRef();
  const graphMountFlag = useRef();
  const { title, type, payload, titleParams = {} } = data;

  const els = useMemo(
    () =>
      Object.values(
        createNodesByStructure(getSkeletone(STATIC_LAYER[type], payload))
      ),
    []
  );

  return (
    <Modal
      className={scss.modal}
      size="xlarge"
      visibility={visibility}
      onClose={onClose}
      label={t(mes[title], titleParams)}
    >
      <ModalContent>
        <GraphEngine
          graph={graphRef}
          graphMountFlag={graphMountFlag}
          layoutName="dagre"
          els={els}
          fit={true}
          enableBgGrid={true}
          readOnly={false}
          lasso={false}
          isLoaded={true}
        />
      </ModalContent>
    </Modal>
  );
}

StaticGraph.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['accessGraph', 'formGraph', 'actorFormsGraph'])
      .isRequired,
    payload: PropTypes.object.isRequired,
  }),
};

export default StaticGraph;
