import PropTypes from 'prop-types';

export const ViewDataPropType = {
  actors: PropTypes.shape({
    // TOMAKE: Describe genearl actor shape
    layer: PropTypes.object,
    // TOMAKE: Describe genearl actor shape
    widgetChat: PropTypes.shape({
      actorId: PropTypes.string,
      // ... possible another fields
    }),
  }).isRequired,
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      currencyId: PropTypes.number.isRequired,
      nameId: PropTypes.string.isRequired,
      displayCurrency: PropTypes.bool.isRequired,
      accountName: PropTypes.string.isRequired,
      currencyName: PropTypes.string.isRequired,
      amount: PropTypes.number,
      currencyPrecision: PropTypes.number,
      currencyType: PropTypes.string,
      currencySymbol: PropTypes.string,
    })
  ).isRequired,
  formsFields: PropTypes.arrayOf(
    PropTypes.shape({
      formId: PropTypes.number.isRequired,
      itemId: PropTypes.string.isRequired,
      icon: PropTypes.string,
      value: PropTypes.any, // TOMAKE: Describe possible fields values shape
      title: PropTypes.string,
    })
  ).isRequired,
  actorFields: PropTypes.arrayOf(
    PropTypes.shape({
      itemId: PropTypes.string.isRequired,
      icon: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
  design: PropTypes.shape({
    blockOpacity: PropTypes.number,
  }),
};
