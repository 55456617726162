import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Space, Icon, Label, FileUtils } from 'mw-style-react';
import useDragFiles from 'useDragFiles';
import useIntl from 'useIntl';
import AppUtils from '@control-front-end/utils/utils';
import { NOTIFY_LEVEL, SHOW_NOTIFY } from 'constants';
import mes from './intl';
import { getFileMimeTypes } from '../../selectors';
import './FilesDragZone.scss';

function FilesDragZone({
  spaceSize = 'medium',
  accept,
  multiple = false,
  containerRef,
  onUpload,
}) {
  const t = useIntl();
  const dispatch = useDispatch();
  const { maxFileSize } = useSelector((state) => state.config);
  const fileMimeTypes = useSelector(getFileMimeTypes);
  const filesAccept = accept || fileMimeTypes;

  const notifyError = (error) => {
    dispatch({
      type: SHOW_NOTIFY.REQUEST,
      payload: {
        id: AppUtils.createRid(),
        type: NOTIFY_LEVEL.ERROR,
        label: t(mes[error]),
      },
    });
  };

  const checkErrors = (files) => {
    let error = false;
    if (!multiple && files.length > 1) {
      error = 'multipleErrorText';
    } else if (accept) {
      const badType = Array.prototype.some.call(
        files,
        (i) => !FileUtils.checkFileAccept(i, filesAccept)
      );
      if (badType) error = 'fileTypeError';
    }
    if (error) notifyError(error);
    return error;
  };

  const { dragActive } = useDragFiles({
    ref: containerRef,
    callback: (files, cursorPosition) => {
      if (checkErrors(files)) return;
      onUpload(files, cursorPosition);
    },
  });

  const imageOnly = accept === 'image/*';
  return dragActive ? (
    <Space
      top
      right
      bottom
      left
      size={Space.SIZE[spaceSize]}
      styleName="dragZone"
    >
      <Space styleName="dragZone__box">
        <Icon size="xlarge" type={imageOnly ? 'picture' : 'import'} />
        <Label value={imageOnly ? t(mes.dragImage) : t(mes.dragDropFiles)} />
        <Label
          fontWeight="normal"
          fontSize="xsmall"
          value={t(mes[imageOnly ? 'imageSize' : 'fileSize'], {
            fileSize: maxFileSize / (1024 * 1024),
          })}
        />
      </Space>
    </Space>
  ) : null;
}

FilesDragZone.propTypes = {
  containerRef: PropTypes.object.isRequired,
  spaceSize: PropTypes.oneOf(['none', 'small', 'medium', 'large', 'xlarge']),
  onUpload: PropTypes.func.isRequired,
};

export default FilesDragZone;
