import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, Icon } from 'mw-style-react';
import Dashboard from '@control-front-end/common/components/Dashboard';
import AppUtils from '@control-front-end/utils/utils';
import { DASHBOARD_CHART_TYPES } from '@control-front-end/common/constants/graphActors';
import {
  INCOME_TYPE,
  COUNTER_TYPE,
} from '@control-front-end/common/constants/actorAccounts';

import './LineDashboardWidget.scss';

function LineDashboardWidget({ actorId, account, rootRef, dragRef, onClose }) {
  const staticSource = useMemo(
    () => ({
      accounts: [
        {
          actorId,
          nameId: account?.nameId,
          currencyId: account?.currencyId,
          incomeType: INCOME_TYPE.total,
          color: AppUtils.getRandomColorByPalette(),
        },
      ],
      counterType: COUNTER_TYPE.amount,
      chartType: DASHBOARD_CHART_TYPES.LINE,
      range: 'today',
      chartTitle: `${account?.accountName?.toUpperCase() || ''}, ${
        account?.currencyName || ''
      }`,
    }),
    [actorId, account]
  );

  return (
    <Card forwardRef={rootRef} styleName="chart">
      <Dashboard
        actorId={actorId}
        staticSource={staticSource}
        headerRef={dragRef}
        draggable
        actions={
          <div onClick={onClose}>
            <Icon size="small" type="close" />
          </div>
        }
      />
    </Card>
  );
}

LineDashboardWidget.propTypes = {
  actorId: PropTypes.string,
  account: PropTypes.shape({
    nameId: PropTypes.string,
    currencyId: PropTypes.string,
    accountName: PropTypes.string,
    currencyName: PropTypes.string,
  }),
  rootRef: PropTypes.shape({
    current: PropTypes.oneOfType([
      PropTypes.instanceOf(Element),
      PropTypes.oneOf([null]),
    ]),
  }),
  dragRef: PropTypes.shape({
    current: PropTypes.oneOfType([
      PropTypes.instanceOf(Element),
      PropTypes.oneOf([null]),
    ]),
  }),
  onClose: PropTypes.func.isRequired,
};

export default LineDashboardWidget;
