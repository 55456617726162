import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import {
  GET_FILES,
  SET_FILES_REQ_STATUS,
  SEARCH_FILES,
  STARRED_FILE,
  RequestStatus,
  GET_ACTOR_ATTACHMENTS,
  GET_ACTOR_REACTIONS_ATTACHMENTS,
} from '@control-front-end/common/constants';
import AppUtils from '@control-front-end/utils/utils';
import api from '@control-front-end/common/sagas/api';

/**
 * Get files list
 */
function* getFiles({ payload }) {
  const filesState = yield select((state) => state.files);
  const { endList, reqStatus, list, limit, offset, loadCount } = filesState;
  if (reqStatus !== RequestStatus.SUCCESS || endList) return;

  const { loadMore, filter } = payload;
  const auth = yield select((state) => state.auth);
  const accounts = yield select((state) => state.accounts);
  const accId = accounts.active;
  // Set files request status to inProgress
  yield put({ type: SET_FILES_REQ_STATUS, payload: 'inProgress' });
  const copyFilters = { ...filter };
  if (copyFilters.people !== 'all') {
    copyFilters.people =
      copyFilters.people === 'currentUser'
        ? auth.id.toString()
        : copyFilters.people;
    copyFilters.people = copyFilters.people
      ? copyFilters.people.split(',')
      : [];
  } else {
    delete copyFilters.people;
  }
  const url = `/attachments/${accId}`;
  const { result, data } = yield call(api, {
    method: 'get',
    url,
    queryParams: { ...copyFilters, limit, offset },
  });
  // Set files request status to success
  yield put({ type: SET_FILES_REQ_STATUS, payload: 'success' });
  if (result !== RequestStatus.SUCCESS) return;
  const config = yield select((state) => state.config);
  for (const i of data.data) {
    if (i.user) i.userAvatar = AppUtils.makeUserAvatar(i.user, config);
    if (i.fileName)
      i.filePath = yield call(AppUtils.makeAppUrl, `/download/${i.fileName}`);
  }
  const newEventsList = loadMore ? list.concat(data.data) : data.data;
  yield put({
    type: GET_FILES.SUCCESS,
    payload: {
      list: newEventsList,
      offset: offset + limit,
      loadCount: loadCount + 1,
      endList: !data.data.length,
    },
  });
}

/**
 * Get all actor attachments
 */

function* getActorAttachments({ payload, callback, errorCallback }) {
  const { actorId } = payload;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/attachments/actor/${actorId}`,
  });
  if (result !== RequestStatus.SUCCESS) {
    if (errorCallback) errorCallback(data);
    return;
  }
  if (callback) callback(data?.data);
}

/**
 * Get all attachments from actor's reactions
 */
function* getActorReactionsAttachments({ payload, callback, errorCallback }) {
  const { actorId } = payload;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/attachments/root_actor/${actorId}`,
  });
  if (result !== RequestStatus.SUCCESS) {
    errorCallback?.(data);
    return;
  }
  const updatedAttachments = data.data.slice();
  for (const i of updatedAttachments) {
    if (!i.fileName) continue;
    i.filePath = yield call(AppUtils.makeAppUrl, `/download/${i.fileName}`);
  }
  callback?.(updatedAttachments);
}

/**
 * File search
 */
function* searchFiles({ payload }) {
  const accounts = yield select((state) => state.accounts);
  const accId = accounts.active;
  const searchQ = AppUtils.safeSearchQuery(payload);
  if (!searchQ.length) return;
  // Set files request status to inProgress
  yield put({ type: SET_FILES_REQ_STATUS, payload: 'inProgress' });
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/attachments/search/${accId}/${searchQ}`,
  });
  // Set files request status to success
  yield put({ type: SET_FILES_REQ_STATUS, payload: 'success' });
  if (result !== RequestStatus.SUCCESS) return;
  const config = yield select((state) => state.config);
  for (const i of data.data) {
    if (i.userId) i.userAvatar = AppUtils.makeUserAvatar(i, config);
    if (i.fileName)
      i.filePath = yield call(AppUtils.makeAppUrl, `/download/${i.fileName}`);
  }
  yield put({
    type: SEARCH_FILES.SUCCESS,
    payload: {
      list: data.data,
      offset: 0,
      loadCount: 0,
      endList: false,
    },
  });
}

/**
 * Add/remove file from starred
 */
function* starredFiles({ payload }) {
  const { id, star } = payload;
  const { result } = yield call(api, {
    method: 'put',
    url: `/attachments/starred/${id}/${star}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  const { list } = yield select((state) => state.files);
  const newList = list.map((item) =>
    item.id === id ? { ...item, starred: star } : item
  );
  yield put({
    type: GET_FILES.SUCCESS,
    payload: { list: newList },
  });
}

function* files() {
  yield takeEvery(GET_FILES.REQUEST, getFiles);
  yield takeEvery(GET_ACTOR_ATTACHMENTS.REQUEST, getActorAttachments);
  yield takeEvery(
    GET_ACTOR_REACTIONS_ATTACHMENTS.REQUEST,
    getActorReactionsAttachments
  );
  yield takeLatest(SEARCH_FILES.REQUEST, searchFiles);
  yield takeLatest(STARRED_FILE.REQUEST, starredFiles);
}

export default files;
