import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Space, Stack, Label, Chips } from 'mw-style-react';
import { useModal, useIntl } from 'hooks';
import m from './intl';

/**
 * Renders a modal that displays a warning message for the imported file.
 *
 * @param {Object} props - The props object that contains the onClose function.
 * @return {JSX.Element} - The JSX for the ImportWarningModal component.
 */
function ImportWarningModal({
  onClose,
  data: { onSubmit, onCancel, participants, scripts },
}) {
  const handleClose = useCallback((...args) => {
    onClose(...args);
    onCancel(...args);
  });

  const t = useIntl();

  const { update: switchToImportSettingsModal } = useModal(
    'ImportSettingsModal',
    {
      onSubmit,
      onCancel,
      participants,
      scripts,
    }
  );

  const amounts = useMemo(
    () =>
      participants.reduce(
        (acc, participant) => {
          acc[participant.type === 'group' ? 'groups' : 'users'] += 1;
          return acc;
        },
        { users: 0, groups: 0, scripts: scripts?.length || 0 }
      ),
    [participants, scripts]
  );

  return (
    <Modal onClose={onClose} label={t(m.startImport)}>
      <Space size={Space.SIZE.large}>
        <Stack size={Stack.SIZE.large}>
          <Stack>
            <Label
              value={
                <>
                  {t(m.warningTextPart1)}
                  <br />
                  <br />
                  {t(m.warningTextPart2)}
                </>
              }
            />
            <Chips>
              <Label
                color={Label.COLOR.black}
                value={t(m.groups, { amount: String(amounts.groups) })}
              />
              <Label
                color={Label.COLOR.black}
                value={t(m.users, { amount: String(amounts.users) })}
              />
              <Label
                color={Label.COLOR.black}
                value={t(m.scripts, { amount: String(amounts.scripts) })}
              />
            </Chips>
          </Stack>
          <Stack>
            <Button
              type="secondary"
              size="large"
              fontWeight="normal"
              label={t(m.startMapping)}
              fullWidth
              onClick={() => switchToImportSettingsModal()}
            />
            <Button
              size="large"
              fontWeight="normal"
              label={t(m.proceed)}
              fullWidth
              onClick={handleClose}
            />
          </Stack>
        </Stack>
      </Space>
    </Modal>
  );
}

ImportWarningModal.propTypes = {
  data: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    participants: PropTypes.array.isRequired,
  }),
  onClose: PropTypes.func.isRequired,
};

export default ImportWarningModal;
