import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  MenuItem,
  Select,
  TextField,
  Calendar,
  DateUtils,
} from 'mw-style-react';
import FormUtils from '@control-front-end/utils/formUtils';
import AutocompleteSources from '@control-front-end/common/components/AutocompleteSources';
import '../../../../CreateActorsFilter.scss';
import { DATE_FORMAT_2, DATE_FORMAT_5 } from 'constants';
import useIntl from 'useIntl';
import mes from './intl';

// Time-buffer applied to time ranges of actors that was created with system Event form
const EVENT_TIME_BUFFER = 59;

/**
 * Поле выбора значения для поля фильтра
 */
function FieldValue(props) {
  const {
    id,
    value: fieldValue,
    title,
    formId,
    fieldItem = {},
    handleChangeField,
    error,
  } = props;
  const t = useIntl();
  const eventSystemForm = useSelector((state) => state.systemForms.events);

  const handleChangeDate = useCallback(
    ({ value }) =>
      handleChangeField({
        value:
          value.startDate +
          (eventSystemForm.id === formId ? EVENT_TIME_BUFFER : 0),
      }),
    []
  );

  const showTextField = (props) => (
    <TextField
      styleName="filter__textfield"
      size="medium"
      bordered
      resettable
      placeholder={t(mes.actorTmplValue)}
      label={t(mes.value)}
      onChange={handleChangeField}
      onReset={() => handleChangeField({ id, value: '' })}
      error={error}
      {...props}
    />
  );

  const showCalendar = (dateValue) => (
    <Calendar
      id="from"
      styleName="filter__calendar"
      size="small"
      onChange={handleChangeDate}
      time={fieldItem.extra?.time}
      timeZone={fieldItem.extra?.timeZone}
      value={dateValue}
    />
  );

  const showCheck = (value) => (
    <Select
      styleName="filter__select"
      type="autocomplete"
      autoSelect={false}
      size="medium"
      bordered
      resettable
      value={value}
      placeholder={t(mes.actorTmplValue)}
      onChange={handleChangeField}
      error={error}
    >
      <MenuItem value="true" label={t(mes.checkboxTrue)} />
      <MenuItem value="false" label={t(mes.checkboxFalse)} />
    </Select>
  );

  const showSelect = (value, options) => {
    const itemSourceType = FormUtils.getOptionSourceType(fieldItem);
    if (itemSourceType && itemSourceType !== 'manual') {
      return (
        <AutocompleteSources
          key={id}
          portal={true}
          id={id}
          formId={formId}
          title={title || value}
          value={fieldValue}
          onChange={(d) =>
            handleChangeField({
              value: d && d.value.length ? d.value[0].value : '',
            })
          }
          error={error}
          resettable
          resettableSearch
        />
      );
    }
    return (
      <Select
        styleName="filter__select"
        type="autocomplete"
        autoSelect={false}
        size="medium"
        bordered
        resettable
        value={value}
        onChange={handleChangeField}
        onReset={() => handleChangeField({ id, value: '' })}
        error={error}
      >
        {options.map((item) => (
          <MenuItem key={item.value} value={item.value} label={item.title} />
        ))}
      </Select>
    );
  };

  const getCalendarDate = (value, param) => {
    try {
      const parsedValue = JSON.parse(value);
      return parsedValue[param] || null;
    } catch {
      return null;
    }
  };

  const getFormattedDateLabel = (value, withTime) =>
    DateUtils.toDate(
      value,
      `${DATE_FORMAT_2}${withTime ? ` ${DATE_FORMAT_5}` : ''}`
    );
  switch (fieldItem.class) {
    case 'calendar':
      const dateValue = getCalendarDate(fieldValue, 'startDate') || fieldValue;
      return showTextField({
        value: dateValue
          ? getFormattedDateLabel(dateValue, fieldItem.extra?.time)
          : null,
        calendar: () => showCalendar(dateValue),
        readOnly: true,
      });
    case 'select':
    case 'multiSelect':
    case 'radio':
      return showSelect(fieldValue, fieldItem.options);
    case 'check':
      return showCheck(fieldValue);
    default:
      return showTextField({ value: fieldValue });
  }
}

FieldValue.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  form: PropTypes.object,
  formId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string,
  fieldItem: PropTypes.object,
  handleChangeField: PropTypes.func,
};

export default FieldValue;
