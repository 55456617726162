import keyBy from 'lodash/keyBy';
import {
  AccessLevel,
  LOGIN_TYPES,
  LOGIN_TYPE_NAMES,
  DEFAULT_OWNER_PRIVS,
} from 'constants';
import { INVITE_EMAIL_REGEXP } from '@control-front-end/common/constants/regExp';

export default {
  // Преобразовать права в роль
  privsToAccess(privs) {
    if (privs.sign) return AccessLevel.SIGN;
    if (privs.execute) return AccessLevel.EXECUTE;
    if (privs.modify) return AccessLevel.MODIFY;
    return AccessLevel.VIEW;
  },

  // Преобразовать роли для события в права
  accessToPrivs(access, isOwner) {
    switch (access) {
      case AccessLevel.VIEW:
        return {
          view: true,
          modify: !!isOwner,
          remove: !!isOwner,
          sign: false,
          execute: false,
        };
      case AccessLevel.MODIFY:
        return {
          view: true,
          modify: true,
          remove: !!isOwner,
          sign: false,
          execute: false,
        };
      case AccessLevel.REMOVE:
        return {
          view: true,
          modify: true,
          remove: true,
          sign: false,
          execute: false,
        };
      case AccessLevel.SIGN:
        return {
          view: true,
          modify: !!isOwner,
          remove: !!isOwner,
          sign: true,
          execute: false,
        };
      case AccessLevel.EXECUTE:
        return {
          view: true,
          modify: !!isOwner,
          remove: !!isOwner,
          sign: false,
          execute: true,
        };
      default:
        return {
          view: true,
          modify: false,
          remove: false,
          sign: false,
          execute: false,
        };
    }
  },

  // Преобразовать объект прав пользователя на событие в флаг доступа
  userPrivsToAccess(users) {
    return users.map((i) => {
      return {
        userId: i.userId,
        access: this.privsToAccess(i.privs),
      };
    });
  },

  // даем права на редактирование/подпись для овнера аккаунта
  fixAccOwnerPrivs(access, accOwnerId) {
    return access.map((u) => {
      if (u.userId === accOwnerId) {
        if (!u.privs.execute && !u.privs.sign) {
          u.privs.sign = true;
        }
        u.privs.modify = true;
      }
      return u;
    });
  },

  isEqualPrivs(rules) {
    if (rules.length === 1) return true;
    let privs = rules[0].privs;
    for (let i = 1; i < rules.length; i += 1) {
      const isEqual = JSON.stringify(privs) === JSON.stringify(rules[i].privs);
      if (!isEqual) return false;
      privs = rules[i].privs;
    }
    return true;
  },

  isEmail(str) {
    const emailRegex = new RegExp(INVITE_EMAIL_REGEXP);
    return emailRegex.test(String(str).toLowerCase());
  },

  getUserLogin(logins = []) {
    // Some users have only type ID, some only type name, so make it consistent
    const namedLogins = logins.map((i) => ({
      ...i,
      type_name: LOGIN_TYPES[i.type] || i.type,
    }));
    const loginsMap = keyBy(namedLogins, 'type_name');
    const findLoginType = Object.values(LOGIN_TYPE_NAMES).find(
      (type) => loginsMap[type]
    );
    return findLoginType ? loginsMap[findLoginType].login : null;
  },

  makeAuthUserAsOwner(auth) {
    return {
      avatar: auth.avatar,
      name: auth.nick,
      userId: auth.id,
      logins: auth.logins,
      userType: 'user',
      privs: DEFAULT_OWNER_PRIVS,
    };
  },
};
