import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  saUser: {
    id: 'saUser',
    defaultMessage: 'Single account user',
  },
  saUserInfo: {
    id: 'saUserInfo',
    defaultMessage:
      'This is a direct association of an actor with a user. One user can be linked to only one actor at a time',
  },
});

export default { ...globalIntl, ...m };
