import { STATIC_LAYER } from 'constants';

import myVerse from './myVerse';
import valency from './valency';
import accessGraph from './accessGraph';
import formGraph from './formGraph';
import actorFormsGraph from './actorFormsGraph';

const skeletones = {
  [STATIC_LAYER.myVerse]: myVerse,
  [STATIC_LAYER.valency]: valency,
  [STATIC_LAYER.accessGraph]: accessGraph,
  [STATIC_LAYER.formGraph]: formGraph,
  [STATIC_LAYER.actorFormsGraph]: actorFormsGraph,
};

export default skeletones;
