import { call, put, takeEvery } from 'redux-saga/effects';
import api from '@control-front-end/common/sagas/api';
import {
  GET_DASHBOARD,
  GET_DASHBOARD_ACCOUNTS,
} from '@control-front-end/common/constants/graphActors';
import { RequestStatus } from 'constants';
import AppUtils from '@control-front-end/utils/utils';

/**
 * Create single account model
 */
function makeAccountModel(key, keyAccounts, incomeType) {
  const {
    actorId,
    actorTitle,
    actorPrivs,
    currencyId,
    currencyName,
    accountName,
    nameId,
  } = keyAccounts[0];
  const nAcc = {
    id: AppUtils.udid(),
    actor: {
      id: actorId,
      title: actorTitle,
      accessDenied: actorPrivs && !actorPrivs.view,
    },
    account: {
      key,
      currencyId: currencyId.toString(),
      currencyName,
      accountName,
      nameId,
    },
    incomeType,
  };
  keyAccounts.forEach((i) => {
    nAcc.account[i.incomeType] = {
      ...i,
      availableAmount: AppUtils.isUndefined(i.availableAmount)
        ? i.amount
        : i.availableAmount,
      holdAmount: i.holdAmount || 0,
    };
  });
  return nAcc;
}

/**
 * Create dashboard accounts model
 */
function makeDashboardModel(model, sourceAccounts) {
  model.accounts.forEach((acc) => {
    acc.key = `${acc.actorId}+${acc.nameId}+${acc.currencyId}`;
  });
  const groupAccounts = AppUtils.groupBy(model.accounts, 'key');
  let accounts;
  // if sourceAccounts passed, make model accounts based on incomeType from source
  if (sourceAccounts?.length) {
    accounts = sourceAccounts.map((sAcc) => {
      const key = `${sAcc.actorId}+${sAcc.nameId}+${sAcc.currencyId}`;
      const modelAccounts =
        sAcc.incomeType === 'total'
          ? groupAccounts[key]
          : [groupAccounts[key].find((i) => i.incomeType === sAcc.incomeType)];
      return makeAccountModel(key, modelAccounts, sAcc.incomeType);
    });
  } // else make model accounts from actorId+nameId+currencyId pairs
  else
    accounts = Object.keys(groupAccounts).map((key) => {
      const keyAccounts = groupAccounts[key];
      return makeAccountModel(
        key,
        keyAccounts,
        keyAccounts.length === 2 ? 'total' : keyAccounts[0].incomeType
      );
    });
  return { ...model, accounts };
}

/**
 * Make dashboard data
 */
function makeDashboardDataset(data, sourceAccounts) {
  const dataset = structuredClone(data);
  for (const [index, metric] of dataset.entries()) {
    const precision = isNaN(metric.precision) ? 2 : metric.precision;
    if (metric.value) {
      metric.value = AppUtils.roundToPrecision(metric.value, precision);
    }
    if (metric.data) {
      for (const item of metric.data) {
        item.value = AppUtils.roundToPrecision(item.value, precision);
      }
    }
    if (sourceAccounts?.[index]?.incomeType) {
      metric.incomeType = sourceAccounts[index].incomeType;
    }
  }
  return dataset;
}

/**
 * Get dashboard dataset
 */
function* getDashboard({ payload, callback, errorCallback }) {
  const { actorId, from, to, interval, source } = payload;
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/dashboards/${actorId}`,
    queryParams: from && to ? { from, to, interval } : {},
    handleErrCodes: [400, 403],
    body: { source },
  });
  if (result !== RequestStatus.SUCCESS) {
    if (errorCallback) errorCallback(data);
    return;
  }
  const dataset = makeDashboardDataset(data.data, source.accounts);
  yield put({ type: GET_DASHBOARD.SUCCESS });
  callback(dataset);
}

/**
 * Get dashboard actor info with all accounts
 */
function* getDashboardAccounts({ payload, callback, errorCallback }) {
  const { actorId, sourceAccounts = [] } = payload;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/dashboards/info/${actorId}`,
    handleErrCodes: [400],
  });
  if (result !== RequestStatus.SUCCESS) {
    if (errorCallback) errorCallback(data);
    return;
  }
  const dashboardModel = yield call(
    makeDashboardModel,
    data.data,
    sourceAccounts
  );
  if (callback) callback(dashboardModel);
}

function* dashboardsSaga() {
  yield takeEvery(GET_DASHBOARD.REQUEST, getDashboard);
  yield takeEvery(GET_DASHBOARD_ACCOUNTS.REQUEST, getDashboardAccounts);
}

export default dashboardsSaga;
