import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  minAccLimit: {
    id: 'minAccLimit',
    defaultMessage: 'Min value',
  },
  maxAccLimit: {
    id: 'maxAccLimit',
    defaultMessage: 'Max value',
  },
  showAccInSearch: {
    id: 'showAccInSearch',
    defaultMessage: 'Enable account transactions search',
  },
  setupLimits: {
    id: 'setupLimits',
    defaultMessage: 'Setup account limits',
  },
  useTreeCalculation: {
    id: 'useTreeCalculation',
    defaultMessage: 'Use this account in tree calculations',
  },
  createWithPlan: {
    id: 'createWithPlan',
    defaultMessage: 'Create the planned account',
  },
  planAccInfo: {
    id: 'planAccInfo',
    defaultMessage:
      'You can create a planned account, allowing you to see a trend in the account',
  },
});

export default { ...globalIntl, ...m };
