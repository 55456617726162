import { Utils } from 'mw-style-react';

export const CREATE_GRAPH_FOLDER = Utils.createRequestTypes(
  'CREATE_GRAPH_FOLDER'
);
export const UPDATE_GRAPH_FOLDER = Utils.createRequestTypes(
  'UPDATE_GRAPH_FOLDER'
);
export const MANAGE_GRAPH_FOLDER = Utils.createRequestTypes(
  'MANAGE_GRAPH_FOLDER'
);
export const SEARCH_GRAPH_FOLDERS = Utils.createRequestTypes(
  'SEARCH_GRAPH_FOLDERS'
);
export const GET_GRAPH_FOLDERS = Utils.createRequestTypes('GET_GRAPH_FOLDERS');
export const GET_GRAPH_FOLDER = Utils.createRequestTypes('GET_GRAPH_FOLDER');
export const GET_GRAPH_FOLDER_LAYERS = Utils.createRequestTypes(
  'GET_GRAPH_FOLDER_LAYERS'
);
export const GRAPH_FOLDER_ACCESS = Utils.createRequestTypes(
  'GRAPH_FOLDER_ACCESS'
);

export const SET_GRAPH_FOLDERS_REQ_STATUS = 'SET_GRAPH_FOLDERS_REQ_STATUS';
export const CLEAR_GRAPH_FOLDERS = 'CLEAR_GRAPH_FOLDERS';
