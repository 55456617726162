import React from 'react';
import PropTypes from 'prop-types';
import Yup from 'yup';
import {
  Button,
  Modal,
  ModalButtons,
  ModalContent,
  ProgressBar,
  withForm,
} from 'mw-style-react';
import SelectActors from '@control-front-end/common/components/SelectActors';
import useIntl from 'useIntl';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import m from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import l from './ManageGraphFolder.scss'; // NOSONAR

function ManageGraphFolderBase(props) {
  const {
    data,
    values,
    handleSubmit,
    handleOnChange,
    visibility,
    onClose,
    isSubmit,
    errors,
  } = props;
  const t = useIntl();

  return (
    <Modal
      styleName="m.modal"
      onClose={onClose}
      label={t(mes.addLayers)}
      visibility={visibility}
    >
      <ModalContent styleName="m.modal__content">
        <div styleName="l.block">
          <SelectActors
            id="layer"
            bordered={true}
            type="modal"
            size="large"
            multiselect={false}
            error={!!errors.layer}
            helperText={errors.layer ? t(mes[errors.layer]) : ''}
            label={t(mes.selectLayer)}
            formId={data.formId}
            value={values.layer}
            exclude={data.exclude}
            onChange={handleOnChange}
          />
        </div>
      </ModalContent>
      <ModalButtons styleName="m.modal__buttons">
        <Button
          label={t(mes.save)}
          size="large"
          onClick={handleSubmit}
          visibility={isSubmit ? 'disabled' : 'visible'}
        />
        <ProgressBar
          styleName="m.modal__loader"
          type="circle"
          size="small"
          visibility={isSubmit ? 'visible' : 'hidden'}
        />
      </ModalButtons>
    </Modal>
  );
}

ManageGraphFolderBase.propTypes = {
  visibility: PropTypes.bool,
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleOnChange: PropTypes.func,
  isSubmit: PropTypes.bool,
  values: PropTypes.object,
  data: PropTypes.object,
};

const ManageGraphFolder = withForm(
  {
    yup: Yup.object().shape({
      layer: Yup.object().required('field_required'),
    }),
    handleSubmit: (values, dispatch, formActions, props) => {
      const { layer } = values;
      if (props.callback) props.callback(layer);
    },
  },
  ManageGraphFolderBase
);

export default ManageGraphFolder;
