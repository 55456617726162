import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { Icon, Label, Clipboard, TextField } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import './ItemIdEditable.scss';
import useIntl from 'useIntl';
import { NOTIFY_LEVEL, SHOW_NOTIFY } from 'constants';
import useOutsideClick from 'useOutsideClick';
import mes from './intl';

/**
 * Компонент ItemIdEditable
 */
function ItemIdEditable({ id = '', error = false, onChange }) {
  const t = useIntl();
  const dispatch = useDispatch();
  const itemId = useRef();
  const [toggleEdit, setToggleEdit] = useState(false);
  const [idValue, setIdValue] = useState(id);

  useEffect(() => {
    onChange({ value: id, errorOnly: true });
  }, []);

  const handleCloseEdit = () => {
    setToggleEdit(false);
  };

  useOutsideClick({
    ref: itemId,
    callback: () => {
      handleCloseEdit();
    },
  });

  const handleIdChange = ({ value }) => {
    setIdValue(value);
    onChange({ value });
  };

  const renderItemIdLabel = () => {
    return (
      <div styleName={cn('copy', { error })}>
        <div styleName="copy__label" onClick={() => setToggleEdit(true)}>
          <Label value={idValue} />
        </div>
        <Icon
          type="copy"
          size="medium"
          color="currentColor"
          onClick={() => {
            if (id) Clipboard.copy(id.toString());
            dispatch({
              type: SHOW_NOTIFY.REQUEST,
              payload: {
                id: AppUtils.createRid(),
                type: id ? NOTIFY_LEVEL.SUCCESS : NOTIFY_LEVEL.ERROR,
                label: id ? t(mes.itemIdCopied) : t(mes.itemIdEmpty),
              },
            });
          }}
        />
      </div>
    );
  };

  const renderItemIdEdit = () => {
    return (
      <div ref={itemId} styleName="edit">
        <TextField
          styleName="edit__field"
          autoFocus={true}
          autoSelect="mount"
          bordered={false}
          value={idValue}
          error={error}
          onChange={handleIdChange}
        />
      </div>
    );
  };
  return <div>{toggleEdit ? renderItemIdEdit() : renderItemIdLabel()}</div>;
}

ItemIdEditable.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ItemIdEditable;
