import React, { useEffect, useState, useRef, useDeferredValue } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import { useNotifications } from 'hooks';
import AppUtils from '@control-front-end/utils/utils';
import {
  Label,
  Icon,
  Utils,
  MenuItem,
  Select,
  Tooltip,
  TextField,
  Button,
  DateUtils,
  ProgressBar,
} from 'mw-style-react';
import useIntl from 'useIntl';
import useOutsideClick from 'useOutsideClick';
import SelectActors from '@control-front-end/common/components/SelectActors';
import { ACCOUNT_TYPE } from '@control-front-end/common/constants/actorAccounts';
import { TRANSACTIONS_FILTER_RANGE } from '@control-front-end/common/constants/transactionsFilters';
import { TRANSFERABLE_FIELD_TYPE } from '@control-front-end/common/constants/forms';
import {
  UPDATE_USER_SETTINGS,
  DATE_FORMAT_6,
  ACCOUNT_ROLE_ACTION,
} from '@control-front-end/common/constants';
import FilterRange from '../FilterRange';
import FilterAccPair from '../FilterAccPair';
import SelectFormAndFieldDropDown from './SelectFormAndFieldDropDown';
import './TransactionsFiltersOptions.scss';
import mes from './intl';

const NON_RESETTABLE_FIELDS = {
  accountType: 'accountType',
  formFields: 'formFields',
  accounts: 'accounts',
  from: 'from',
  to: 'to',
  rangeValue: 'rangeValue',
};

const RESETTABLE_FIELDS = {
  ref: 'ref',
  transferId: 'transferId',
  query: 'query',
};

const EXPORT_FILE_TYPES = { csv: 'csv', xls: 'xls' };
const ACC_CUR_SHORTNAME_LIMIT = 12;

export const FILTER_TYPE = {
  transaction: 'transaction',
  transfer: 'transfer',
};

function AccountPairSelector({
  account,
  pairPopup,
  togglePairPopup,
  handleSelect,
  filterPairBox,
}) {
  const t = useIntl();

  return (
    <div styleName="tf__wrap">
      <div styleName="tf__type" onClick={() => togglePairPopup(true)}>
        <Label
          styleName="tf__type__title"
          fontWeight="semibold"
          value={`${t(mes.account)}:`}
        />
        <Label
          styleName="tf__type__value"
          fontWeight="semibold"
          value={
            account ? `${account.accountName}, ${account.currencyName}` : ''
          }
        />
        <Icon type="dropdown" size="micro" />
      </div>
      {pairPopup ? (
        <div ref={filterPairBox} styleName="tf__popup">
          <FilterAccPair
            handleClose={() => togglePairPopup(false)}
            handleSelect={handleSelect}
          />
        </div>
      ) : null}
    </div>
  );
}

/**
 * Filter options for transactions and transfers
 */
function TransactionsFiltersOptions({
  filterType,
  value,
  isCustom = false,
  isHistory = false,
  isEmptyList = false,
  fixedRange = false,
  type,
  handleChange,
  handleExport,
}) {
  const t = useIntl();
  const dispatch = useDispatch();
  const { showNotification } = useNotifications();
  const filterPairBox = useRef();
  const { transactionFilter = {} } = useSelector((state) => state.settings);
  const abortController = useRef(null);
  const exportFileType =
    transactionFilter?.export?.fileType || EXPORT_FILE_TYPES.csv;
  const [isExporting, setExporting] = useState(false);
  const [query, setQuery] = useState({
    type: 'ref',
    value: '',
  });
  const [actorsPopup, toggleActorsPopup] = useState(false);
  const [pairPopup, togglePairPopup] = useState(false);
  const deferredQuery = useDeferredValue(query);
  const account = value.accounts ? value.accounts[0] : null;

  useOutsideClick({
    ref: filterPairBox,
    callback: () => togglePairPopup(false),
  });

  const handleSearch = () => {
    const { type, value: query } = deferredQuery;
    const newFilter = omit(
      structuredClone(value),
      Object.keys(RESETTABLE_FIELDS)
    );
    if (
      type === RESETTABLE_FIELDS.ref ||
      type === RESETTABLE_FIELDS.transferId
    ) {
      newFilter[type] = query;
    } else if (query.length) {
      newFilter.query = query;
    }
    handleChange(newFilter);
  };

  const handleCancelDownload = () => {
    abortController.current.abort();
    abortController.current = null;
    setExporting(false);
    showNotification('success', t(mes.exportCancelled));
  };

  useEffect(() => {
    return () => {
      if (!abortController.current) return;
      handleCancelDownload();
    };
  }, []);

  useEffect(() => {
    if (!deferredQuery.isChanged) return;
    handleSearch();
  }, [deferredQuery]);

  const handleResetFilters = () => {
    const { from, to, accounts, accountType, formFields } = value;
    const newFilter = { from, to, accounts, accountType, formFields };
    handleChange(newFilter);
  };

  const handleSelectFilter = ({ id, value: fValue }) => {
    const newFilter = structuredClone(value);
    newFilter[id] = fValue;
    handleChange(newFilter);
    togglePairPopup(false);
  };

  const unixToStr = (unixMs) => {
    return DateUtils.toDate(unixMs / 1000, DATE_FORMAT_6);
  };

  const saveFilterToSettings = (transactionFilterUpdate) => {
    dispatch({
      type: UPDATE_USER_SETTINGS.REQUEST,
      payload: {
        transactionFilter: {
          ...transactionFilter,
          ...(transactionFilterUpdate || {}),
          export: {
            ...(transactionFilter?.export || {}),
            ...(transactionFilterUpdate?.export || {}),
          },
        },
      },
    });
  };

  const handleDownloadFile = () => {
    if (isEmptyList) return;
    showNotification('success', t(mes.exportHasStarted));
    setExporting(true);
    const { controller } = handleExport({
      filter: value,
      callback: (data) => {
        if (!abortController.current) return;
        abortController.current = null;
        setExporting(false);
        if (!data?.length) return;
        const pairShortName = `${account.accountName.slice(
          0,
          ACC_CUR_SHORTNAME_LIMIT
        )}_${account.currencyName.slice(0, ACC_CUR_SHORTNAME_LIMIT)}`;
        const dateRange = `${unixToStr(value.from)}_${unixToStr(value.to)}`;
        AppUtils.createAndDownloadSheet(
          `transactions_${pairShortName}_${dateRange}`,
          exportFileType,
          data
        );
      },
      errorCallback: ({ errorDescription }) => {
        abortController.current = null;
        setExporting(false);
        showNotification('error', `${t(mes.exportError)}: ${errorDescription}`);
      },
    });
    abortController.current = controller;
  };

  const renderExportButtons = () => {
    return (
      <div styleName="tf__export">
        <div styleName="tf__export__divider" />
        {isExporting ? null : (
          <div styleName="tf__export__switch">
            <Button
              styleName="tf__export__switch__button"
              label={t(mes.csv)}
              size="small"
              fontWeight="normal"
              type={
                exportFileType === EXPORT_FILE_TYPES.csv
                  ? 'default'
                  : 'tertiary'
              }
              onClick={() =>
                saveFilterToSettings({
                  export: { fileType: EXPORT_FILE_TYPES.csv },
                })
              }
            />
            <Button
              styleName="tf__export__switch__button"
              label={t(mes.xls)}
              size="small"
              fontWeight="normal"
              type={
                exportFileType === EXPORT_FILE_TYPES.xls
                  ? 'default'
                  : 'tertiary'
              }
              onClick={() =>
                saveFilterToSettings({
                  export: { fileType: EXPORT_FILE_TYPES.xls },
                })
              }
            />
          </div>
        )}
        {isExporting ? null : (
          <Button
            styleName="tf__export__button"
            type="secondary"
            label={t(mes.export)}
            iconAfter="export_import"
            visibility={isEmptyList ? 'disabled' : 'visible'}
            onClick={handleDownloadFile}
          />
        )}
        {isExporting ? (
          <div styleName="tf__export__progress">
            <Label value={t(mes.exporting)} fontWeight="semibold" />
            <ProgressBar
              styleName="tf__export__progress__bar"
              type="circle"
              size="small"
            />
            <Button
              styleName="tf__export__button"
              type="error"
              label={t(mes.cancel)}
              visibility={isEmptyList ? 'disabled' : 'visible'}
              onClick={handleCancelDownload}
            />
          </div>
        ) : null}
      </div>
    );
  };

  const incomeTypeSelect = (
    <Select
      id="incomeType"
      styleName="tf__incomeType"
      size="medium"
      bordered={true}
      value={`Type: ${
        type !== ACCOUNT_TYPE.number
          ? t(mes.total)
          : Utils.toUpperLatter(value.incomeType || 'all')
      }`}
      onChange={handleSelectFilter}
      visibility={type !== ACCOUNT_TYPE.number ? 'disabled' : 'visible'}
    >
      <MenuItem value={null} label={t(mes.all)} />
      <MenuItem value="debit" label={t(mes.debit)} />
      <MenuItem value="credit" label={t(mes.credit)} />
    </Select>
  );

  const renderFilterAddition = () => {
    if (type === ACCOUNT_TYPE.roles)
      return (
        <Select
          id="name"
          styleName="tf__incomeType"
          bordered={true}
          value={`Role: ${t(mes[`role_${value.name}`])}`}
          onChange={handleSelectFilter}
        >
          {Object.values(ACCOUNT_ROLE_ACTION).map((item) => (
            <MenuItem key={item} value={item} label={t(mes[`role_${item}`])} />
          ))}
        </Select>
      );
    if (type === ACCOUNT_TYPE.formField && !isHistory) return null;
    if (type === ACCOUNT_TYPE.formField || isHistory) return incomeTypeSelect;
    return (
      <div styleName="tf__search">
        <Select
          id="searchType"
          styleName="tf__search__type"
          bordered={true}
          value={Utils.toUpperLatter(query.type)}
          onChange={({ value }) =>
            setQuery({ type: value, value: '', isChanged: true })
          }
        >
          <MenuItem value="query" label={t(mes.comment)} />
          <MenuItem value="ref" label={t(mes.refId)} />
          <MenuItem
            value="transferId"
            label={t(mes.transferId)}
            visibility={
              filterType === FILTER_TYPE.transaction ? 'visible' : 'hidden'
            }
          />
        </Select>
        <TextField
          styleName="tf__search__field"
          leftIcon="search"
          bordered={true}
          value={query.value}
          rightIcon={query.value?.length ? 'close' : ''}
          placeholder={t(mes[`${filterType}Search`])}
          onClickRightIcon={() =>
            setQuery({ ...query, value: '', isChanged: true })
          }
          onChange={({ value }) =>
            setQuery({ ...query, value, isChanged: true })
          }
        />
      </div>
    );
  };

  const actorsLabel = value.actors?.length
    ? value.actors.map((i) => i.title).join(', ')
    : t(mes.all);
  const isCustomized = Object.keys(
    pickBy(value, (value, key) => {
      return Boolean(value) && !NON_RESETTABLE_FIELDS[key];
    })
  ).length;

  return (
    <div styleName="tf">
      <div styleName="tf__wrap">
        <FilterRange
          from={type === ACCOUNT_TYPE.roles ? null : value.from}
          to={type === ACCOUNT_TYPE.roles ? null : value.to}
          fastNavigation={type !== ACCOUNT_TYPE.roles}
          isDisabled={fixedRange}
          onChange={(range) => {
            handleChange({ ...value, ...range });

            if (isCustom) return;

            // Remember time range for default filters only
            Utils.toSessionStorage(
              TRANSACTIONS_FILTER_RANGE,
              JSON.stringify(range)
            );
          }}
        />
      </div>
      {!isCustom && !isHistory ? (
        <>
          {ACCOUNT_TYPE.formField === type ? (
            <SelectFormAndFieldDropDown
              formId={transactionFilter[filterType]?.formId}
              fieldId={transactionFilter[filterType]?.fieldId}
              filterFields={(field) =>
                filterType === FILTER_TYPE.transfer
                  ? Boolean(TRANSFERABLE_FIELD_TYPE[field.class])
                  : true
              }
              onChange={({ field }) => {
                handleChange((prevFilter) => ({
                  ...prevFilter,
                  formFields: [
                    {
                      formId: field.formId,
                      fieldId: field.id,
                    },
                  ],
                  accounts: null,
                }));
                saveFilterToSettings({
                  [FILTER_TYPE[filterType]]: {
                    formId: field.formId,
                    fieldId: field.id,
                  },
                });
              }}
            />
          ) : (
            <AccountPairSelector
              account={account}
              pairPopup={pairPopup}
              togglePairPopup={togglePairPopup}
              filterPairBox={filterPairBox}
              handleSelect={({ value: newAccountPair }) => {
                handleChange({
                  ...structuredClone(value),
                  accounts: [newAccountPair],
                  formFields: null,
                });
                saveFilterToSettings(newAccountPair);
                togglePairPopup(false);
              }}
            />
          )}
          {incomeTypeSelect}
          <div styleName="tf__wrap">
            <div styleName="tf__type" onClick={() => toggleActorsPopup(true)}>
              <Label
                styleName="tf__type__title"
                fontWeight="semibold"
                value={t(mes.fActors)}
              />
              <Tooltip value={actorsLabel.length > 20 ? actorsLabel : ''}>
                <Label
                  styleName="tf__type__value"
                  fontWeight="semibold"
                  value={actorsLabel}
                />
              </Tooltip>
              <Icon type="dropdown" size="micro" />
            </div>
            {actorsPopup ? (
              <div styleName="tf__popup">
                <SelectActors
                  id="actors"
                  wrap={true}
                  bordered={true}
                  multiselect={true}
                  autoFocus={true}
                  hideCreateBtn={true}
                  value={value.actors || []}
                  exclude={value.actors}
                  onChange={handleSelectFilter}
                  onBlur={() => toggleActorsPopup(false)}
                />
              </div>
            ) : null}
          </div>
          <Button
            type="secondary"
            size="smallplus"
            fontWeight="semibold"
            label="Reset filters"
            iconAfter="trash"
            onClick={handleResetFilters}
            visibility={isCustomized ? 'visible' : 'hidden'}
          />
        </>
      ) : null}
      {!handleExport ? null : renderExportButtons()}
      {renderFilterAddition()}
    </div>
  );
}

TransactionsFiltersOptions.FILTER_TYPE = FILTER_TYPE;

TransactionsFiltersOptions.defaultProps = {
  filterType: FILTER_TYPE.transaction,
};

TransactionsFiltersOptions.propTypes = {
  filterType: PropTypes.oneOf(Object.values(FILTER_TYPE)),
  value: PropTypes.object,
  isCustom: PropTypes.bool,
  isHistory: PropTypes.bool,
  isEmptyList: PropTypes.bool,
  fixedRange: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleExport: PropTypes.func,
  type: PropTypes.oneOf(Object.values(ACCOUNT_TYPE)),
};

export default TransactionsFiltersOptions;
