import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import scss from './ContentWithStickyActions.scss';

/**
 * Renders a container with sticky actions and content.
 *
 * @param {object} props - The props object.
 * @param {ReactNode} props.content - The content to render inside the container.
 * @param {ReactNode} props.actions - The sticky actions to render inside the container.
 * @param {string} props.className - Additional class name to apply to the container.
 * @returns {JSX.Element} The JSX element representing the container with sticky actions and content.
 */
function ContentWithStickyActions({ content, actions, className, ...props }) {
  return (
    <div className={cn(scss.container, className)} {...props}>
      <div className={scss.content}>{content}</div>
      <div className={scss.actions}>{actions}</div>
    </div>
  );
}

ContentWithStickyActions.propTypes = {
  content: PropTypes.node.isRequired,
  actions: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default ContentWithStickyActions;
