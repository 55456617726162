import { call, fork, cancel, take } from 'redux-saga/effects';

/**
 * Redux-saga takeLatest effect with the ability to handle the task cancel event
 */
const takeControlledLatest = (patternOrChannel, saga, onCancel, ...args) =>
  fork(function* forkName() {
    let lastTask;
    while (true) {
      const action = yield take(patternOrChannel);
      if (lastTask) {
        if (onCancel) yield call(onCancel);
        yield cancel(lastTask);
      }
      lastTask = yield fork(saga, ...args.concat(action));
    }
  });

export default takeControlledLatest;
