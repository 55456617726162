import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  actorNameId: {
    id: 'actorNameId',
    defaultMessage: 'Actor name, ID',
  },
  accNameId: {
    id: 'accNameId',
    defaultMessage: 'Account name, ID',
  },
  makeCorrectiveTransaction: {
    id: 'makeCorrectiveTransaction',
    defaultMessage: 'Make corrective transaction',
  },
  transactionValue: {
    id: 'transactionValue',
    defaultMessage: 'Transaction value',
  },
  correctiveValue: {
    id: 'correctiveValue',
    defaultMessage: 'Corrective value',
  },
  afterCorrection: {
    id: 'afterCorrection',
    defaultMessage: 'After correction',
  },
  makeTransaction: {
    id: 'makeTransaction',
    defaultMessage: 'Make transaction',
  },
  currentBalance: {
    id: 'currentBalance',
    defaultMessage: 'Current balance',
  },
  balanceCorrected: {
    id: 'balanceCorrected',
    defaultMessage: 'Balance after correction',
  },
});

export default { ...globalIntl, ...m };
