import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import useIntl from 'useIntl';
import { Icon, Label, Stack, DateUtils } from 'mw-style-react';
import { DATE_FORMAT_3 } from 'constants';
import mes from './intl';
import './ScriptChip.scss';

function ScriptChip({ script, onEdit, onClose }) {
  const t = useIntl();

  const handleEditClick = useCallback(() => onEdit(script), [script]);

  return (
    <div styleName="schip">
      <div>
        <div styleName="schip__icon">
          <Icon type="form_pole" />
        </div>
      </div>
      <div styleName="schip__title">
        <Stack size={Stack.SIZE.none}>
          <div>{script.app.title}</div>
          <span>
            {script.appSettings?.users?.length ? (
              <Label
                color={Label.COLOR.gray}
                value={t(mes.usersAmount, {
                  amount: script.appSettings.users.length,
                })}
              />
            ) : null}{' '}
            {script.appSettings?.groups?.length ? (
              <Label
                color={Label.COLOR.gray}
                value={t(mes.groupsAmount, {
                  amount: script.appSettings.groups.length,
                })}
              />
            ) : null}
          </span>
          {script.appSettings?.expired && (
            <Label
              color={Label.COLOR.gray}
              value={t(mes.exparationDate, {
                date: DateUtils.toDate(
                  script.appSettings.expired,
                  DATE_FORMAT_3
                ),
              })}
            />
          )}
        </Stack>
      </div>
      <div onClick={handleEditClick}>
        <Icon styleName="schip__close" type="edit" size="small" />
      </div>
      <div>
        <Icon
          styleName="schip__close"
          type="close"
          size="small"
          onClick={onClose}
        />
      </div>
    </div>
  );
}

ScriptChip.propTypes = {
  script: PropTypes.shape({
    app: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
    appId: PropTypes.string,
    appSettings: PropTypes.shape({
      autorun: PropTypes.bool,
      expired: PropTypes.number,
      users: PropTypes.arrayOf(PropTypes.number),
      groups: PropTypes.arrayOf(PropTypes.number),
    }),
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ScriptChip;
