import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  formColor: {
    id: 'formColor',
    defaultMessage: 'Color',
  },
  formDescr: {
    id: 'formDescr',
    defaultMessage: 'Description',
  },
  formDescrInfo: {
    id: 'formDescrInfo',
    defaultMessage: '(Additional details about the form)',
  },
  formColorSelect: {
    id: 'formColorSelect',
    defaultMessage: '(Click to select color for the form)',
  },
});

export default { ...globalIntl, ...m };
