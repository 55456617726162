import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Label, Modal, ModalContent, ProgressBar } from 'mw-style-react';
import useIntl from 'useIntl';
import { GET_ACCOUNT_DETAILS } from '@control-front-end/common/constants/actorAccounts';
import { Balance, CopyIdChip } from 'components';
import DetailsList from './DetailsList';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import m from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import l from './AccountDetails.scss'; // NOSONAR

function AccountDetails({ data, onClose, visibility }) {
  const {
    currencyId,
    nameId,
    accountName,
    actorId,
    currencyName,
    debit,
    credit,
  } = data;
  const t = useIntl();
  const dispatch = useDispatch();
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const getData = () => {
    if (accounts.endList) return;
    setLoading(true);
    dispatch({
      type: GET_ACCOUNT_DETAILS.REQUEST,
      payload: {
        actorId,
        currencyId,
        nameId,
      },
      callback: (result) => {
        setAccounts(result);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    getData();
  }, [actorId]);

  const isLimitValue = (value) => {
    return !!value || value === 0;
  };

  const showLimitValue = (limitType, label) => {
    const limitValue = debit[limitType];
    return isLimitValue(limitValue) ? (
      <div>
        <Label
          styleName="l.content__header__limits__title"
          value={`${t(mes[label])}:`}
        />
        <div>
          <Label value={`${limitValue} ${currencyName}`} />
        </div>
      </div>
    ) : null;
  };

  const hasLimits =
    isLimitValue(debit.minLimit) || isLimitValue(debit.maxLimit);
  return (
    <Modal
      styleName="m.modal"
      size="xlarge"
      onClose={onClose}
      label={`${t(mes.actorsAccountDetails)}: ${accountName} (${currencyName})`}
      visibility={visibility}
    >
      <ModalContent styleName="m.modal__content l.content">
        <div styleName="l.content__header">
          <div styleName="l.content__header__details">
            <Label value={`${t(mes.actorsAccountDetails)}`} />
            <div>
              <Label
                styleName="l.content__header__details__title"
                value={`${t(mes.debitAccId)}:`}
              />
              <CopyIdChip type="default" id={debit.id} />
            </div>
            <div>
              <Label
                styleName="l.content__header__details__title"
                value={`${t(mes.creditAccId)}:`}
              />
              <CopyIdChip type="default" id={credit.id} />
            </div>
          </div>
          {hasLimits ? (
            <div styleName="l.content__header__limits">
              <Label value={`${t(mes.actorsAccountLimits)}`} />
              {showLimitValue('minLimit', 'accountMinValue')}
              {showLimitValue('maxLimit', 'accountMaxValue')}
            </div>
          ) : null}
        </div>
        <div styleName="l.content__balances">
          <Balance debit={debit} credit={credit} currencyName={currencyName} />
        </div>
        {loading ? (
          <div styleName="l.loader">
            <ProgressBar type="circle" size="large" />
          </div>
        ) : (
          <DetailsList accounts={accounts} currencyName={currencyName} />
        )}
      </ModalContent>
    </Modal>
  );
}

AccountDetails.propTypes = {
  visibility: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object,
};

export default AccountDetails;
