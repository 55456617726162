import {
  GET_LAYER,
  CREATE_LAYER,
  UPDATE_LAYER,
  REMOVE_LAYER,
  CLEAR_LAYERS,
  LAYER_ACCESS,
  PIN_LAYER,
  UNPIN_LAYER,
  CLOSE_LAYER,
  MOVE_LAYER,
  SAVE_LAYER_POSITION,
  GET_LAYER_BALANCE,
  GET_ACTOR_LAYER,
  GET_TREE_LAYER,
  SET_LAYER_PICTURE,
  STARRED_LAYER_ACTOR,
  SET_LAYERS_REQ_STATUS,
  SET_ACTIVE_LAYER,
  EDITABLE_NODE,
  SAVE_ACTOR_LAYER_SETTINGS,
  SET_LAYER_SETTINGS,
  GET_ALL_LAYERS,
  ACTOR_DECOMPOSE,
  ACTORS_AGGREGATION,
  MANAGE_LAYER_ACTORS,
  MAKE_AUTO_LAYER_PAGE,
  SET_VERTICAL_LINKS,
} from '@control-front-end/common/constants/graphLayers';
import {
  GET_ACTORS,
  CREATE_ACTOR,
  CREATE_EDGE,
  CREATE_MASS_EDGES,
  SET_ACTIVE_ELEMENT,
  UPDATE_ACTOR,
  REMOVE_ACTOR,
  UPDATE_EDGE,
  REMOVE_EDGE,
  ADD_ACTOR,
  GET_ACTORS_BALANCE,
  PASTE_TO_GRAPH,
  BULK_UPDATE_ACTORS_SETTINGS,
  BULK_UPDATE_ACTORS_FIELD,
} from '@control-front-end/common/constants/graphActors';
import { createReducer } from '@reduxjs/toolkit';

// ------------------------------------
// Reducer
// ------------------------------------

const initState = {
  active: null,
  graphFolderId: null,
  list: [],
  reqStatus: 'success',
};

export default createReducer(initState, {
  [GET_LAYER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [CREATE_LAYER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [UPDATE_LAYER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [REMOVE_LAYER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [LAYER_ACCESS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [GET_ACTORS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [CREATE_EDGE.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [CREATE_MASS_EDGES.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SET_ACTIVE_ELEMENT.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [UPDATE_EDGE.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [REMOVE_EDGE.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [ADD_ACTOR.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [UPDATE_ACTOR.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [BULK_UPDATE_ACTORS_FIELD.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [BULK_UPDATE_ACTORS_SETTINGS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [CREATE_ACTOR.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [REMOVE_ACTOR.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SET_ACTIVE_LAYER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [PIN_LAYER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [GET_ACTOR_LAYER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [GET_TREE_LAYER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SET_LAYER_PICTURE.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [UNPIN_LAYER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [CLOSE_LAYER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [MOVE_LAYER.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SAVE_LAYER_POSITION.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [GET_LAYER_BALANCE.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [GET_ACTORS_BALANCE.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [STARRED_LAYER_ACTOR.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [EDITABLE_NODE.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SAVE_ACTOR_LAYER_SETTINGS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [PASTE_TO_GRAPH.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SET_LAYER_SETTINGS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [GET_ALL_LAYERS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [ACTOR_DECOMPOSE.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [ACTORS_AGGREGATION.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [MANAGE_LAYER_ACTORS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [MAKE_AUTO_LAYER_PAGE.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SET_VERTICAL_LINKS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SET_LAYERS_REQ_STATUS](state, action) {
    return { ...state, reqStatus: action.payload };
  },
  [CLEAR_LAYERS]() {
    return initState;
  },
});
