import { createReducer } from '@reduxjs/toolkit';
import {
  CREATE_ASYNC_TASK,
  DELETE_ASYNC_TASK,
  GET_ASYNC_TASKS,
  SET_ASYNC_TASKS_REQ_STATUS,
  CLEAR_ASYNC_TASKS,
  UPDATE_ASYNC_TASK,
} from 'constants';

// ------------------------------------
// Reducer
// ------------------------------------

const initState = {
  list: [],
  limit: 15,
  offset: 0,
  loadCount: 0,
  totalTasks: 0,
  reqStatus: 'success',
  endList: false,
};

export default createReducer(initState, {
  [GET_ASYNC_TASKS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [CREATE_ASYNC_TASK.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [DELETE_ASYNC_TASK.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [UPDATE_ASYNC_TASK.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SET_ASYNC_TASKS_REQ_STATUS](state, action) {
    return { ...state, reqStatus: action.payload };
  },
  [CLEAR_ASYNC_TASKS]() {
    return initState;
  },
});
