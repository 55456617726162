import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Label, Icon, Button } from 'mw-style-react';
import useIntl from 'useIntl';
import cn from 'classnames';
import Plus from './img/icon_plus.svg';
import Search from '../Search';
import mes from './intl';
import './Header.scss';

function Header(props) {
  const {
    icon,
    title,
    createBtnLabel,
    createBtnPlus = false,
    chips,
    actions,
    onBackClick,
    onCreateClick,
    onSearch = () => {},
    onSelect = () => {},
    onClearSearch = () => {},
    search = true,
    withSearchSubmit,
    canManage,
    searchPlaceholder,
    showLoader,
    searchAutoSelect,
  } = props;
  const t = useIntl();
  const location = useLocation();
  const mounted = useRef(false);
  const [searchField, toggleSearchField] = useState(false);

  useEffect(() => {
    if (mounted.current) {
      toggleSearchField(false);
      onClearSearch();
    }
    mounted.current = true;
  }, [location.pathname]);

  const getCreateBtnLabel = () => {
    if (createBtnPlus) return '';
    return createBtnLabel || t(mes.create);
  };

  const renderBackBtn = () => (
    <Button
      styleName="header__backBtn"
      icon="arrow_thin"
      size="smallplus"
      type="tertiary"
      rounded={true}
      onClick={onBackClick}
    />
  );

  const renderCreateBtn = () => (
    <>
      <div styleName="header__actions__divider" />
      {!canManage ? (
        <Button
          styleName="header__actions__readOnly"
          size="smallplus"
          fontWeight="normal"
          label={t(mes.readOnly)}
          visibility="disabled"
        />
      ) : (
        <div styleName="header__actions__create" onClick={onCreateClick}>
          <Button
            styleName={cn('header__actions__create__btn', {
              round: createBtnPlus,
            })}
            icon={createBtnPlus ? 'plus' : null}
            size="smallplus"
            fontWeight="normal"
            label={getCreateBtnLabel()}
          />
          <img styleName="header__actions__create__icon" src={Plus} alt="" />
        </div>
      )}
    </>
  );

  return (
    <div styleName="header__wrap">
      <div styleName="header">
        {searchField ? (
          <Search
            onSearch={onSearch}
            onSelect={onSelect}
            onClear={onClearSearch}
            onClose={() => toggleSearchField(false)}
            placeholder={searchPlaceholder || t(mes.searchByNameOrId)}
            withSubmit={withSearchSubmit}
            showLoader={showLoader}
            autoSelect={searchAutoSelect}
          />
        ) : (
          <div styleName="header__title">
            {onBackClick ? renderBackBtn() : null}
            {icon ? (
              <Icon styleName="header__icon" type={icon} size="large" />
            ) : null}
            {typeof title === 'string' ? (
              <Label value={title} fontSize="xlarge" fontWeight="semibold" />
            ) : (
              title
            )}
            {chips ? <div styleName="header__chips">{chips}</div> : null}
          </div>
        )}
        <div styleName="header__actions">
          {!searchField && search ? (
            <div
              styleName="header__actions__icon"
              onClick={() => toggleSearchField(!searchField)}
            >
              <Icon type="search" size="medium" />
            </div>
          ) : null}
          {actions}
          {onCreateClick ? renderCreateBtn() : null}
        </div>
      </div>
      <div styleName="header__details">
        {chips ? <div styleName="header__chips">{chips}</div> : null}
      </div>
    </div>
  );
}

Header.defaultProps = {
  isSubtitle: false,
};

Header.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  createBtnLabel: PropTypes.string,
  search: PropTypes.bool,
  chips: PropTypes.element,
  actions: PropTypes.element,
  onBackClick: PropTypes.func,
  onCreateClick: PropTypes.func,
  onClearSearch: PropTypes.func,
  onSearch: PropTypes.func,
  onSelect: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  withSearchSubmit: PropTypes.bool,
  canManage: PropTypes.bool,
  showLoader: PropTypes.bool,
  searchAutoSelect: PropTypes.bool,
};

export default Header;
