import { cr, DateUtils } from 'mw-style-react';
import { MILLISECONDS_PER_SECOND } from 'constants';

const SECONDS_IN_A_DAY = 24 * 60 * 60;
export const DEFAULT_EVENT_BG_COLOR = '#1973e8';
const MINUTES_IN_HOUR = 60;
const ONE_AND_HALF_HOUR = 90;
export const DEFAULT_TIME_FORMAT = DateUtils.TIME_FORMATS[24];

const isMidnight = (date) => {
  return (
    date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0
  );
};

const transformEvent = ({
  title,
  data: { startDate, endDate = 0 },
  access,
  description,
  id,
  color,
  privs,
}) => {
  const start = new Date(startDate * MILLISECONDS_PER_SECOND);
  let end = endDate ? new Date(endDate * MILLISECONDS_PER_SECOND) : start;

  // Add 1 millisecond to endDate if it equals startDate to ensure proper display of events
  if (startDate === endDate) {
    end = new Date(end.getTime() + 1);
  }

  const isMultiDay =
    endDate - startDate >= SECONDS_IN_A_DAY ||
    start.getDate() !== end.getDate();
  const allDay =
    isMidnight(start) && isMidnight(end) && start.getDate() !== end.getDate();
  return {
    start,
    end,
    title,
    allDay,
    extendedProps: {
      startUnixtime: startDate,
      endUnixtime: endDate,
      users: access,
      description,
      eventId: id,
      isMultiDay,
      actorBgColor: color || DEFAULT_EVENT_BG_COLOR,
      privs,
    },
  };
};

export const transformEvents = (events) => events.map(transformEvent);

export const replaceImagesInDesAndDecodeHtml = (htmlContent) => {
  const replacedContent = htmlContent.replace(
    /<img\s+[^>]*alt=["']([^"']+)["'][^>]*>/gi,
    (match, alt) => `!image${alt}!`
  );

  const textarea = document.createElement('textarea');
  textarea.innerHTML = replacedContent;
  return textarea.value;
};

export const getNumberOfRowsBasedOnDuration = (startTime, endTime) => {
  const durationInMinutes = (endTime - startTime) / MINUTES_IN_HOUR;

  return cr(
    [durationInMinutes < MINUTES_IN_HOUR, 1],
    [durationInMinutes <= ONE_AND_HALF_HOUR, 2],
    [true, 3]
  );
};
