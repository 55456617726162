import { Utils } from 'mw-style-react';

export const ADD_FORMS_FILTER = Utils.createRequestTypes('ADD_FORMS_FILTER');
export const HIDE_FORMS_FILTER = Utils.createRequestTypes('HIDE_FORMS_FILTER');
export const CLEAR_FORMS_FILTERS = 'CLEAR_FORMS_FILTERS';
export const SET_ACTIVE_FORMS_FILTER = 'SET_ACTIVE_FORMS_FILTER';

export const SYSTEM_FORMS_FILTERS = [
  {
    id: 'custom',
    title: 'Custom Forms',
    isSystem: true,
    disabled: true,
    data: {},
  },
  {
    id: 'system',
    title: 'System forms',
    isSystem: true,
    disabled: true,
    data: {},
  },
  {
    id: 'connector',
    title: 'Connectors',
    isSystem: true,
    disabled: true,
    data: {},
  },
];
