import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  selectAll: {
    id: 'selectAll',
    defaultMessage: 'Select all',
  },
  searchResults: {
    id: 'searchResults',
    defaultMessage: 'Search results',
  },
  inviteNewUser: {
    id: 'inviteNewUser',
    defaultMessage: 'Invite new user',
  },
  searchUserPlaceholder: {
    id: 'searchUserPlaceholder',
    defaultMessage: 'Search by name or email',
  },
  addUsers: {
    id: 'addUsers',
    defaultMessage: 'Add users',
  },
});

export default { ...globalIntl, ...m };
