import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  label_amount: {
    id: 'label_amount__Dashboard',
    defaultMessage: 'Account amount',
  },
  label_count: {
    id: 'label_count__Dashboard',
    defaultMessage: 'Transactions count',
  },
  badChart: {
    id: 'badChart__Dashboard',
    defaultMessage: 'Something went wrong',
  },
  setting: {
    id: 'setting__Dashboard',
    defaultMessage: 'Settings',
  },
  accountSelectValue: {
    id: 'accountSelectValue__Dashboard',
    defaultMessage: 'Account : {value}',
  },
  accountValueTypePlaceholder: {
    id: 'accountValueTypePlaceholder__Dashboard',
    defaultMessage: 'Select account',
  },
  accountCounterType: {
    id: 'accountCounterType__Dashboard',
    defaultMessage: 'Account value type',
  },
  refresh: {
    id: 'refresh__Dashboard',
    defaultMessage: 'Refresh',
  },
  editChart: {
    id: 'editChart__Dashboard',
    defaultMessage: 'Edit chart',
  },
});

export default { ...globalIntl, ...m };
