import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, AutocompleteItem, Chip } from 'mw-style-react';
import cn from 'classnames';
import AutocompleteSources from '@control-front-end/common/components/AutocompleteSources';
import './MultiSelect.scss';

/**
 * Компонент MultiSelect
 */
function MultiSelect(props) {
  const {
    formId,
    id: itemId,
    title,
    options,
    extra = {},
    value: selected = [],
    visibility = 'visible',
    isPreview = false,
    getErrorMsg,
    error,
    onChange,
    getFormData,
  } = props;
  const [search, setSearch] = useState('');
  const [focus, setFocus] = useState(false);
  const hasOptionsSource =
    extra.optionsSource && extra.optionsSource.type !== 'manual';

  const handleSearchChange = ({ value }) => {
    setSearch(value);
  };

  const handleListItemClick = ({ id, value }) => {
    const selectedIds = Array.isArray(selected)
      ? selected.map((item) => item.value)
      : [];
    const selectedItem = selectedIds.indexOf(id);
    if (selectedItem !== -1) return;
    const newItems = Array.isArray(selected) ? selected.slice(0) : [];
    newItems.push({ value: id, title: value });
    onChange({ id: itemId, value: newItems, error: false });
    setSearch('');
  };

  const handleChipCloseClick = (id) => {
    const newItems = selected.slice(0);
    const indexToRemove = newItems.map((item) => item.value).indexOf(id);
    newItems.splice(indexToRemove, 1);
    onChange({ id: itemId, value: newItems });
  };

  const renderChipsItems = () => {
    if (!Array.isArray(selected)) return null;
    return selected.map((item, index) => (
      <Chip
        /**
         * TODO: Get back to {item.value} as React key after issue with duplicated values will be resolved
         * Currently, it is allowed to transfer item that already exist in formField value
         */
        key={index}
        styleName={cn('chip', { disabled: visibility === 'disabled' })}
        label={item.title}
        closeClick={() => handleChipCloseClick(item.value)}
        size="small"
        type="rectangular"
      />
    ));
  };

  const renderSearchResult = () => {
    if (!Array.isArray(options) || !focus) return;
    const searchRegExp = new RegExp(search, 'gi');
    const exclude = Array.isArray(selected) ? selected : [];
    const items = options
      .filter((item) => item.title.match(searchRegExp))
      .filter((item) => exclude.findIndex((i) => i.value === item.value) === -1)
      .map((item) => (
        <AutocompleteItem
          id={item.value}
          key={item.value}
          value={item.title}
          onClick={handleListItemClick}
        />
      ));
    if (!items.length) return;
    return <div>{items}</div>;
  };

  return hasOptionsSource ? (
    <AutocompleteSources
      key={itemId}
      id={itemId}
      formId={formId}
      className={props.class}
      value={selected}
      label={title}
      error={error}
      visibility={visibility}
      helperText={getErrorMsg(props)}
      renderChipsItems={renderChipsItems}
      onChange={onChange}
      getFormData={getFormData}
      isPreview={isPreview}
      resettableSearch
    />
  ) : (
    <Autocomplete
      id={itemId}
      label={title}
      value={search}
      error={error}
      bordered={true}
      visibility={visibility}
      helperText={getErrorMsg(props)}
      onChange={handleSearchChange}
      chipsItems={renderChipsItems}
      searchResult={renderSearchResult}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      popoverOnTop
    />
  );
}

MultiSelect.propTypes = {
  id: PropTypes.string.isRequired,
  class: PropTypes.string.isRequired,
  title: PropTypes.string,
  value: PropTypes.array,
  error: PropTypes.bool,
  visibility: PropTypes.string,
  extra: PropTypes.object,
  onChange: PropTypes.func,
  getFormData: PropTypes.func,
  getErrorMsg: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  formId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default MultiSelect;
