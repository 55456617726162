import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RequestStatus } from 'constants';

/**
 * Hook useLoader
 */
const useLoader = (stateName) => {
  const reqStatus = useSelector((state) => state[stateName].reqStatus);
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    setShowLoader(reqStatus !== RequestStatus.SUCCESS);
  }, [reqStatus]);
  return showLoader;
};
export default useLoader;
