import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SUPPORT_SEND,
  SHOW_NOTIFY,
  NOTIFY_LEVEL,
  RequestStatus,
} from 'constants';
import AppUtils from '@control-front-end/utils/utils';
import api from '@control-front-end/common/sagas/api';

/**
 * Отправить форму на саппорт
 */
function* supportSend({ payload, formActions }) {
  const { result } = yield call(api, {
    method: 'post',
    url: '/support',
    body: payload,
  });
  formActions.setSubmitting(false);
  if (result !== RequestStatus.SUCCESS) return;
  if (formActions) formActions.onClose();
  yield put({
    type: SHOW_NOTIFY.REQUEST,
    payload: {
      id: AppUtils.createRid(),
      type: NOTIFY_LEVEL.SUCCESS,
      label:
        'Thank you for contacting us. We will do our best to respond as soon as possible',
    },
  });
}

function* support() {
  yield takeLatest(SUPPORT_SEND.REQUEST, supportSend);
}

export default support;
