import { createReducer } from '@reduxjs/toolkit';
import { SHOW_NOTIFY, HIDE_NOTIFY, CLEAR_NOTIFY } from '../constants';
// ------------------------------------
// Reducer
// ------------------------------------
const initState = { items: [] };

export default createReducer(initState, {
  [HIDE_NOTIFY.SUCCESS](state, action) {
    return action.payload;
  },
  [SHOW_NOTIFY.SUCCESS](state, action) {
    return action.payload;
  },
  [CLEAR_NOTIFY]() {
    return initState;
  },
});
