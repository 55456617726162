import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  newActor: {
    id: 'newActor',
    defaultMessage: 'New actor',
  },
  updateTplActor: {
    id: 'updateTplActor',
    defaultMessage: 'Update actor',
  },
  actorCreated: {
    id: 'actorCreated',
    defaultMessage: "Actor '{title}' has been successfully created",
  },
  actorUpdated: {
    id: 'actorUpdated',
    defaultMessage: "Actor '{title}' has been successfully updated",
  },
  createAnotherActor: {
    id: 'createAnotherActor',
    defaultMessage: 'Create another actor',
  },
  optionalFields: {
    id: 'optionalFields',
    defaultMessage: 'Optional fields',
  },
  formAccessError: {
    id: 'formAccessError',
    defaultMessage:
      'You cannot perform this action, there is no access to one of the forms',
  },
  saveChangesAnyway: {
    id: 'saveChangesAnyway',
    defaultMessage: 'Save changes anyway',
  },
  public: {
    id: 'public_CreateActor',
    defaultMessage: 'Public',
  },
  private: {
    id: 'private_CreateActor',
    defaultMessage: 'Private',
  },
  cantSaveWithoutForm: {
    id: 'cantSaveWithoutForm',
    defaultMessage: `Can't save actor without form`,
  },
});

export default { ...globalIntl, ...m };
