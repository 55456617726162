import { SET_THEME } from 'constants';
import { createReducer } from '@reduxjs/toolkit';

// ------------------------------------
// Reducer
// ------------------------------------
const initState = 'light';

export default createReducer(initState, {
  [SET_THEME.SUCCESS](state, action) {
    return action.payload;
  },
});
