import {
  GET_REACTIONS,
  CREATE_REACTION,
  UPDATE_REACTION,
  DELETE_REACTION,
  CLEAR_REACTIONS,
  CHANGE_REACTION_ORDER,
  _REACTIONS_ORDER_,
  SET_REACTIONS_REQ_STATUS,
} from '@control-front-end/common/constants/reactions';
import { Utils } from 'mw-style-react';
import { createReducer } from '@reduxjs/toolkit';

// ------------------------------------
// Reducer
// ------------------------------------

const initState = {
  list: [],
  limit: 15,
  total: 0,
  reqStatus: 'success',
  orderValue: Utils.fromStorage(_REACTIONS_ORDER_) || 'DESC',
  lastReaction: null,
  rootActorId: null,
  startReached: false, // If "true" - all items from the list start are loaded
  endReached: false, // If "true" - all items from the list end are loaded
};

export default createReducer(initState, {
  [GET_REACTIONS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [CREATE_REACTION.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [UPDATE_REACTION.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [DELETE_REACTION.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [CHANGE_REACTION_ORDER.SUCCESS](state, action) {
    return { ...initState, orderValue: action.payload };
  },
  [SET_REACTIONS_REQ_STATUS](state, action) {
    return { ...state, ...action.payload };
  },
  [CLEAR_REACTIONS](state) {
    return { ...initState, reqTime: state.reqTime };
  },
});
