import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalContent,
  Label,
  Stack,
  Space,
  TextField,
  DateUtils,
} from 'mw-style-react';
import { useSelector } from 'react-redux';
import { useFilter, useIntl, useExpirationNotifier } from 'hooks';
import { DATE_FORMAT_3 } from 'constants';
import UserAvatar from '@control-front-end/common/components/UserAvatar';
import mes from './intl';
import scss from './PersonalScriptUsers.scss';

const USERS_AMOUNT_TO_SHOW = 10;

function PersonalScriptUsers({ onClose, data }) {
  const t = useIntl();

  const access = useSelector((state) => state.actorView?.access);

  const participants = useMemo(() => {
    // If there is no limitations - script is shared for all participants that have access to the actor
    if (!data.appSettings?.users?.length && !data.appSettings?.groups?.length) {
      return access;
    }

    // Otherwise get only target participants
    return access.filter((item) =>
      data.appSettings[
        item.userType === 'group' ? 'groups' : 'users'
      ]?.includes(item.userId)
    );
  }, [data.appSettings, access]);

  const [itemsToShow, setFilter] = useFilter(
    participants,
    ({ value, nick, name }, filter) =>
      (value || nick || name).toLowerCase().includes(filter.toLowerCase()),
    { debounce: 200, maxAmount: USERS_AMOUNT_TO_SHOW }
  );

  const expired = useExpirationNotifier(
    data.appSettings?.expired && data.appSettings.expired * 1000
  );

  return (
    <Modal onClose={onClose} label={data.script.title} visibility={true}>
      <ModalContent>
        <Stack size={Stack.SIZE.large}>
          {data.appSettings?.expired ? (
            <div>
              <Label
                fontWeight="semibold"
                value={
                  expired ? t(mes.scriptBeenExpired) : t(mes.scriptWillExpire)
                }
                color={Label.COLOR.black}
              />
              <Label
                value={DateUtils.toDate(
                  data.appSettings.expired,
                  DATE_FORMAT_3
                )}
                color={Label.COLOR.black}
              />
            </div>
          ) : null}
          <TextField
            leftIcon="search"
            bordered={true}
            placeholder={t(mes.searchByName)}
            color={TextField.COLOR.white}
            unspaced
            onChange={({ value }) => setFilter(value)}
          />
          <div>
            <Label
              color={Label.COLOR.black}
              fontWeight="semibold"
              value={t(mes.sharedWith)}
            />
            {itemsToShow.length ? null : (
              <Label color={Label.COLOR.gray} value={t(mes.noResults)} />
            )}
          </div>
          <Stack
            size={Stack.SIZE.xxsmall}
            divider={<hr className={scss.divider} />}
          >
            {itemsToShow.map((item, index) => {
              return (
                <Space key={index} top bottom size={Space.SIZE.xsmall}>
                  <Stack horizontal alignItems="center" key={item.id}>
                    {(item.userType || item.type) === 'group' ? (
                      <UserAvatar
                        size="small"
                        customIcon={{ type: 'persons', color: '#1973E8' }}
                      />
                    ) : (
                      <UserAvatar
                        size="small"
                        label={item.value}
                        src={item.avatar}
                      />
                    )}
                    <Label
                      color={Label.COLOR.black}
                      value={item.value || item.nick || item.name}
                      className={scss.userName}
                    />
                  </Stack>
                </Space>
              );
            })}
          </Stack>
        </Stack>
      </ModalContent>
    </Modal>
  );
}

PersonalScriptUsers.propTypes = {
  onClose: PropTypes.func,
  data: PropTypes.shape({
    appId: PropTypes.string,
    appSettings: PropTypes.shape({
      autorun: PropTypes.bool,
      expired: PropTypes.number,
      users: PropTypes.arrayOf(PropTypes.number),
      groups: PropTypes.arrayOf(PropTypes.number),
    }),
  }),
};

export default PersonalScriptUsers;
