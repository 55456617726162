import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Label, Select, MenuItem, Tooltip } from 'mw-style-react';
import { useIntl, useSearchList } from 'hooks';
import AppUtils from '@control-front-end/utils/utils';
import { GET_ACTORS_ACCOUNTS } from '@control-front-end/common/constants/actorAccounts';

import './SelectActorsAccountPair.scss';
import mes from './intl';

/**
 * Formatted account balance
 */
const getBalance = (account) => {
  const { currencyType, precision, symbol, currencyName } = account;
  const currencyParams = { type: currencyType, symbol, precision };
  return `${AppUtils.simpleFormattedAmount(
    account.amount,
    currencyParams
  )} ${currencyName}`;
};

/**
 * Render account balance with tooltip
 */
function Balance({ balance }) {
  return (
    <Tooltip styleName="account__balance__tooltip" value={balance}>
      <Label styleName="account__balance" value={balance} />
    </Tooltip>
  );
}

const findAccount = (accounts, { nameId, currencyId } = {}) => {
  if (!nameId || !currencyId) return null;

  return (
    accounts?.find(
      (acc) =>
        acc.nameId === nameId && Number(acc.currencyId) === Number(currencyId)
    ) || null
  );
};

const addAccountTotal = (acc) => {
  if (!acc?.debit || !acc?.credit) return acc;
  return {
    ...acc,
    total: {
      ...acc.debit,
      ...{ amount: acc.credit.amount - acc.debit.amount },
    },
  };
};

/**
 * Components for selecting actors' account
 * @returns {JSX.Element}
 * @constructor
 */
function SelectActorsAccountPair({
  actorId,
  nameId,
  currencyId,
  accountType = 'fact',
  incomeType,
  currency,
  title,
  liveSearch = false,
  onChange,
  fieldClassName = '',
  errorCallback,
  showBalance,
  disableSystemAccounts = false,
  filter,
  preselectedAccount = null,
  ...props
}) {
  const t = useIntl();

  const { list, onScroll, makeSearch } = useSearchList({
    actionType: GET_ACTORS_ACCOUNTS.REQUEST,
    settings: { step: 10, scrollToLoad: 20 },
    skip: !actorId,
    // turn off strict check list.length < limit
    // because getActorsAccounts saga returns 1 item merged from 2 of api response
    // e.g. with step 10 we get 5 items and strict check counts this as the end of list
    strictEndCheck: false,
    localState: true,
    actorId,
    accountType,
    errorCallback,
  });

  useEffect(() => {
    if (!actorId) return;
    makeSearch({ actorId });
  }, [actorId]);

  const actorAccounts = useMemo(
    () => list.filter(filter).map(addAccountTotal),
    [list]
  );

  const [activeAccount, setActiveAccount] = useState(null);

  useEffect(() => {
    const actorAccount =
      findAccount(actorAccounts, {
        nameId,
        currencyId,
      }) || addAccountTotal(preselectedAccount);
    setActiveAccount(actorAccount);
  }, [actorAccounts, nameId, currencyId]);

  const defaultTitle = activeAccount
    ? `${activeAccount.accountName}, ${activeAccount.currencyName}`
    : '';

  return (
    <div styleName="account" className={fieldClassName}>
      <Select
        styleName={cn('account__select', { showBalance })}
        size="large"
        bordered
        type={liveSearch ? 'autocomplete' : 'default'}
        autoSelect={false}
        placeholder={t(mes.selectAccount)}
        value={title || defaultTitle}
        onChange={({ value }) =>
          onChange(actorAccounts.find((i) => value === i.key))
        }
        popoverOnTop
        onMenuScroll={onScroll}
        {...props}
      >
        {actorAccounts.length ? (
          actorAccounts.map((i) => (
            <MenuItem
              key={i.key}
              value={i.key}
              size="small"
              label={`${i.accountName}, ${i.currencyName}`}
              labelTooltip
              visibility={
                (i.privs && !i.privs.modify) ||
                (i.isSystem && disableSystemAccounts)
                  ? 'disabled'
                  : 'visible'
              }
            />
          ))
        ) : (
          <MenuItem
            value={null}
            label={
              currency?.name
                ? t(mes.noAccountsWithCurrency, {
                    currencyName: currency.name,
                  })
                : t(mes.noAccounts)
            }
            labelTooltip
            visibility="disabled"
          />
        )}
      </Select>
      {activeAccount?.[incomeType] && showBalance ? (
        <Balance balance={getBalance(activeAccount[incomeType])} />
      ) : null}
    </div>
  );
}

SelectActorsAccountPair.defaultProps = {
  visibility: 'visible',
  showBalance: true,
  filter: () => true,
};

SelectActorsAccountPair.propTypes = {
  value: PropTypes.object,
  actorId: PropTypes.string,
  currencyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nameId: PropTypes.string,
  visibility: PropTypes.string,
  accountType: PropTypes.string,
  incomeType: PropTypes.string,
  withPrivs: PropTypes.bool,
  liveSearch: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  errorCallback: PropTypes.func,
  filter: PropTypes.func,
  preselectedAccount: PropTypes.object,
  disableSystemAccounts: PropTypes.bool,
};

export default SelectActorsAccountPair;
