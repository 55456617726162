import { defineMessages } from 'react-intl';

const m = defineMessages({
  userAlreadyAdded: {
    id: 'userAlreadyAdded',
    defaultMessage: 'User already added',
  },
  groupAlreadyAdded: {
    id: 'groupAlreadyAdded',
    defaultMessage: 'Group already added',
  },
});

export default m;
