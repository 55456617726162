import { DATE_FORMAT_4 } from 'constants';
import { DateUtils } from 'mw-style-react';
import {
  DEFAULT_CURRENCY_PARAMS,
  CURRENCY_TYPE,
} from '@control-front-end/common/constants/actorAccounts';

export default {
  toMoneyFormat(amount, precision = 2, decimal = '.', thousands = ',') {
    try {
      precision = Math.abs(precision);
      precision = isNaN(precision) ? 2 : precision;
      const sign = amount < 0 ? '-' : '';
      const i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(precision)), // NOSONAR
        10
      ).toString();

      const j = i.length > 3 ? i.length % 3 : 0;

      return (
        sign +
        (j ? i.slice(0, j) + thousands : '') +
        i.slice(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
        (precision
          ? decimal +
            Math.abs(amount - i)
              .toFixed(precision)
              .slice(2)
          : '')
      );
    } catch (e) {
      console.log(e); // eslint-disable-line
    }
  },

  // Форматировать число
  toNumberFormat(number, locale = 'ru-RU') {
    const formatter = new Intl.NumberFormat(locale, {
      maximumFractionDigits: 20,
    });
    return formatter.format(number);
  },

  roundToPrecision(number, precision = 2) {
    const multiplier = 10 ** precision;
    return Math.round(number * multiplier) / multiplier;
  },

  // Преобразовать баланс в формат для валюты
  formattedAmount(value, currency = DEFAULT_CURRENCY_PARAMS) {
    const { type, symbol } = currency;
    const precision = currency.precision ?? 2;
    switch (type) {
      case CURRENCY_TYPE.number:
        return this.toMoneyFormat(value, precision, ',', ' ');
      case CURRENCY_TYPE.currency:
        const formattedValue = this.toMoneyFormat(value, precision, ',', ' ');
        return `${symbol} ${formattedValue}`;
      case CURRENCY_TYPE.dateTime:
        return DateUtils.toDate(value, DATE_FORMAT_4);
      case CURRENCY_TYPE.percent:
        const percentValue = value * 100;
        return `${percentValue.toFixed(precision)}%`;
      case CURRENCY_TYPE.seconds:
        return DateUtils.toReadable(value);
      default:
        return value;
    }
  },

  // Форматированный баланс для типа валюты
  simpleFormattedAmount(value, currency = DEFAULT_CURRENCY_PARAMS) {
    const { type, precision = 2 } = currency;
    switch (type) {
      case CURRENCY_TYPE.number:
        return this.toMoneyFormat(value, precision, ',', ' ');
      case CURRENCY_TYPE.currency:
        return this.toMoneyFormat(value, precision, ',', ' ');
      case CURRENCY_TYPE.dateTime:
        return DateUtils.toDate(value, DATE_FORMAT_4);
      case CURRENCY_TYPE.percent:
        const percentValue = value * 100;
        return percentValue.toFixed(precision);
      case CURRENCY_TYPE.seconds:
        return DateUtils.toReadable(value) || 0;
      default:
        return value;
    }
  },

  // Получить разницу между unixtime
  formattedDateTimeTotal(debit, credit) {
    if (!credit || !debit) return '-';
    let total = Math.abs(credit - debit);
    let formattedDiff = credit < debit ? '-' : '';

    const years = Math.floor(total / (365 * 86400));
    if (years > 0) {
      formattedDiff += `${years} years `;
      total -= years * 365 * 86400;
    }

    const months = Math.floor(total / (30 * 86400));
    if (months > 0) {
      formattedDiff += `${months} months `;
      total -= months * 30 * 86400;
    }

    const days = Math.floor(total / 86400);
    if (days > 0) {
      formattedDiff += `${days} days `;
      total -= days * 86400;
    }

    const hours = Math.floor(total / 3600) % 24;
    if (hours > 0) {
      formattedDiff += `${hours} hours `;
      total -= hours * 3600;
    }

    const minutes = Math.floor(total / 60) % 60;
    if (minutes > 0) {
      formattedDiff += `${minutes} minutes `;
      total -= minutes * 60;
    }

    const seconds = Math.floor(total % 60);
    if (seconds > 0) {
      formattedDiff += `${seconds} seconds `;
    }
    return formattedDiff;
  },

  getTransferCurrencyParams(details) {
    if (!details) return DEFAULT_CURRENCY_PARAMS;
    const { from, to } = details;
    const accounts = [...from, ...to];
    const actualAccount = accounts.find((acc) => !acc.removed);
    if (!actualAccount) return DEFAULT_CURRENCY_PARAMS;
    const { currencyType, currencyName, precision, symbol } = actualAccount;
    return { type: currencyType, name: currencyName, symbol, precision };
  },
};
