import { useEffect } from 'react';

/**
 * Хук для обработки resize html элемента
 * @param ref
 * @param callback
 * @param deps
 */
function useResizeElement(ref, callback, deps = []) {
  useEffect(() => {
    if (!ref.current) return;
    const resizeObserver = new ResizeObserver(callback);
    resizeObserver.observe(ref.current);
    return () => {
      if (ref.current) resizeObserver.unobserve(ref.current);
    };
  }, [ref.current, ...deps]);
}

export default useResizeElement;
