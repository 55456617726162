import { defineMessages } from 'react-intl';

const m = defineMessages({
  scriptWillExpire: {
    id: 'scriptWillExpire_PersonalScriptUsers',
    defaultMessage: 'Script will expire on: ',
  },
  scriptBeenExpired: {
    id: 'scriptBeenExpired_PersonalScriptUsers',
    defaultMessage: 'Script has been expired on: ',
  },
  searchByName: {
    id: 'searchByName_PersonalScriptUsers',
    defaultMessage: 'Search by name',
  },
  sharedWith: {
    id: 'sharedWith_PersonalScriptUsers',
    defaultMessage: 'The script has been shared with: ',
  },
  noResults: {
    id: 'noResults_PersonalScriptUsers',
    defaultMessage: 'No results',
  },
});

export default m;
