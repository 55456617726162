import { Utils } from 'mw-style-react';
import createBrowserHistory from 'history/createBrowserHistory';
import { SUPPORT_URL_PARAMS } from '@control-front-end/common/constants/urlParams';

const history = createBrowserHistory();

let previous = 0;
const _push = history.push;

const appendParameterToUrl = (url, key, value) => {
  const parameterToSet = `${key}=${value}`;

  if (url?.pathname && !url?.pathname.includes(parameterToSet)) {
    const queryParams = new URLSearchParams(url.search);
    queryParams.set(key, value);
    url.search = queryParams.toString();
  } else if (typeof url === 'string' && !url.includes(parameterToSet)) {
    url += `${url.includes('?') ? '&' : '?'}${parameterToSet}`;
  }

  return url;
};

const appendParametersToUrl = (url, queryParams) => {
  return Object.entries(queryParams).reduce((acc, [key, value]) => {
    acc = appendParameterToUrl(acc, key, value);
    return acc;
  }, url);
};

history.push = (path, state) => {
  const supportUrlParams = Utils.pickBy(
    Utils.getQueryParam(history.location.search),
    (v, key) => SUPPORT_URL_PARAMS[key]
  );

  previous += 1;
  _push(appendParametersToUrl(path, supportUrlParams), state);
};

history.goBack = (params = {}) => {
  const { backSteps = 1 } = params;
  if (previous > backSteps - 1) {
    previous -= backSteps;
    history.go(-backSteps);
  } else {
    const noHistoryUrl = params.noHistoryUrl || '/';
    history.push(noHistoryUrl);
  }
};
export default history;
