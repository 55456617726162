import React, { useState, useEffect, useCallback, useMemo } from 'react';
import keyBy from 'lodash/keyBy';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { DateUtils, Stack, Label, Space } from 'mw-style-react';

import {
  REMOVE_ACTOR,
  UPDATE_ACTOR,
} from '@control-front-end/common/constants/graphActors';
import SelectUsers from '@control-front-end/common/components/SelectUsers';
import {
  DEFAULT_OWNER_PRIVS,
  SET_MODAL,
  DEL_MODAL,
  UPDATE_USER_SETTINGS,
} from 'constants';
import { useIntl, useActorModalActions } from 'hooks';

import AppUtils from '@control-front-end/utils/utils';
import { Event } from './components';
import { transformEvents } from './utils';
import BaseCalendar from './BaseCalendar';

import mes from './intl';
import './LibraryStyles.css';

const filterEventsByUserAccess = (events, users) => {
  if (!users.length) return events;
  return events.filter((event) =>
    event?.extendedProps.users.some((eventUser) =>
      users.some((listUser) => listUser.id === eventUser.userId)
    )
  );
};

const hideSelectUserPopup = () => {
  const searchListEl = document.getElementById('searchList');
  if (searchListEl) {
    searchListEl.style.display = 'none';
  }
};
function EventsCalendar({ accId, events, onCreate, setFilter, loading }) {
  const t = useIntl();
  const eventsFormId = useSelector((state) => state.systemForms.events.id);
  const [users, setUsers] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState(transformEvents(events));
  const calendarView = useSelector((state) => state.settings.calendarView);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const eventsMap = useMemo(() => keyBy(events, 'id'), [events]);
  const { handleOpenActorModal } = useActorModalActions();
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);

  useEffect(() => {
    setFilteredEvents(filterEventsByUserAccess(transformEvents(events), users));
  }, [users, events]);

  const handleSelect = ({ start, end, view }) => {
    view?.calendar?.unselect();
    onCreate({
      startDate: DateUtils.dateToUnixtime(start),
      endDate: DateUtils.dateToUnixtime(end),
    });
    hideSelectUserPopup();
  };

  useEffect(() => {
    const moreMenuPopover = document.querySelector('.fc-popover');
    const parentElement = moreMenuPopover?.parentElement;
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.removedNodes.forEach((node) => {
          if (node === moreMenuPopover) {
            setIsMoreMenuOpen(false);
          }
        });
      });
    });

    if (parentElement && isMoreMenuOpen)
      observer.observe(parentElement, {
        childList: true,
      });

    return () => {
      observer.disconnect();
    };
  }, [isMoreMenuOpen]);

  const handleChangeViewMode = (view) => {
    if (calendarView !== view) {
      dispatch({
        type: UPDATE_USER_SETTINGS.REQUEST,
        payload: {
          calendarView: view,
        },
      });
    }
  };

  const handleRemoveEvent = (eventId) => {
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'InfoModal',
        data: {
          title: 'removeActors',
          text: 'removeActorConfirm',
          buttons: [
            {
              label: t(mes.remove),
              onClick: () => {
                dispatch({
                  type: REMOVE_ACTOR.REQUEST,
                  payload: { id: eventId, manageLayer: false },
                });
                dispatch({ type: DEL_MODAL });
              },
            },
          ],
        },
      },
    });
  };

  const handleChangeEvent = ({
    event: {
      start,
      end,
      extendedProps: { eventId },
    },
  }) => {
    const curEvent = eventsMap[eventId];

    if (!curEvent) return;

    dispatch({
      type: UPDATE_ACTOR.REQUEST,
      payload: {
        ...curEvent,
        formId: eventsFormId,
        formData: {
          startDate: DateUtils.dateToUnixtime(start),
          endDate: end
            ? DateUtils.dateToUnixtime(end)
            : DateUtils.dateToUnixtime(start),
        },
      },
    });
  };
  const handleCloneEvent = (eventId) => {
    const curEvent = eventsMap[eventId];
    const newAccess = curEvent.access.map((rule) => ({
      ...rule,
      action: 'create',
    }));
    const newOwnerAccess = newAccess.find((i) => i.userId === auth.id);
    if (newOwnerAccess) {
      newOwnerAccess.privs = DEFAULT_OWNER_PRIVS;
    } else {
      newAccess.push(AppUtils.makeAuthUserAsOwner(auth));
    }
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'EventCard',
        data: {
          id: 'tmp_1',
          ownerId: auth.id,
          ownerAvatar: auth.avatar,
          title: `${curEvent.title} [CLONE]`,
          description: curEvent.description,
          access: newAccess,
          startDate: curEvent.data.startDate,
          endDate: curEvent.data.endDate,
          attachments: [],
          appId: curEvent.appId,
          cardActorId: curEvent.cardActorId,
        },
        closeConfirm: true,
      },
    });
  };

  const handleDatesSet = ({ start, end, view }) => {
    handleChangeViewMode(view.type);
    if (!start || !end) {
      return;
    }

    setFilter((currentFilte) => ({
      ...currentFilte,
      q: encodeURIComponent(
        `startDate<=${DateUtils.dateToUnixtime(
          end
        )}&endDate>=${DateUtils.dateToUnixtime(start)}`
      ),
    }));
  };

  const eventContent = useCallback(
    (data) => {
      return (
        <Event
          {...data}
          handleRemoveEvent={handleRemoveEvent}
          handleOpenEvent={handleOpenActorModal}
          handleCloneEvent={handleCloneEvent}
          isMoreMenuOpen={isMoreMenuOpen}
          accId={accId}
        />
      );
    },
    [handleRemoveEvent, handleCloneEvent, isMoreMenuOpen]
  );

  return (
    <Space
      size="xlarge"
      style={{
        overflowY: 'auto',
        flex: '1 1 auto',
      }}
    >
      <div
        style={{
          display: 'grid',
          height: '100%',
          gridTemplateRows: 'auto 1fr',
        }}
      >
        <Space top bottom size="xxsmall">
          <Stack size={Stack.SIZE.xxsmall}>
            <Label value={t(mes.meetWith)} size="large" />
            <SelectUsers
              bordered
              multiselect
              hideCreateBtn
              placeholder={t(mes.search)}
              unspaced
              fullModel
              value={users}
              exclude={users}
              onChange={({ value: users }) => {
                setUsers([...users]);
              }}
            />
          </Stack>
        </Space>
        <BaseCalendar
          loading={loading}
          events={filteredEvents}
          datesSet={handleDatesSet}
          eventContent={eventContent}
          select={handleSelect}
          eventDrop={handleChangeEvent}
          eventResize={handleChangeEvent}
          calendarView={calendarView}
          editable
          headerToolbar={{
            left: 'today prev,next',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          moreLinkClick={() => {
            setIsMoreMenuOpen(true);
          }}
        />
      </div>
    </Space>
  );
}

export default EventsCalendar;

EventsCalendar.propTypes = {
  events: PropTypes.array.isRequired,
  onCreate: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  accId: PropTypes.string.isRequired,
};
