import {
  GET_WEBHOOKS,
  CREATE_WEBHOOK,
  DELETE_WEBHOOK,
  UPDATE_WEBHOOK,
  CLEAR_WEBHOOKS,
  SET_WEBHOOKS_REQ_STATUS,
} from 'constants';
import { createReducer } from '@reduxjs/toolkit';

// ------------------------------------
// Reducer
// ------------------------------------

const initState = {
  list: [],
  actions: [],
  reqStatus: 'success',
};

export default createReducer(initState, {
  [GET_WEBHOOKS.SUCCESS](state, action) {
    return {
      ...state,
      list: action.payload.list,
      actions: action.payload.actions,
    };
  },
  [CREATE_WEBHOOK.SUCCESS](state, action) {
    return { ...state, list: action.payload.list };
  },
  [UPDATE_WEBHOOK.SUCCESS](state, action) {
    return { ...state, list: action.payload.list };
  },
  [DELETE_WEBHOOK.SUCCESS](state, action) {
    return { ...state, list: action.payload.list };
  },
  [SET_WEBHOOKS_REQ_STATUS](state, action) {
    return { ...state, reqStatus: action.payload };
  },
  [CLEAR_WEBHOOKS]() {
    return initState;
  },
});
