import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  userIsOwner: {
    id: 'userIsOwner',
    defaultMessage: '{name} is owner',
  },
  youAreOwner: {
    id: 'youAreOwner',
    defaultMessage: 'You are the owner',
  },
});

export default { ...globalIntl, ...m };
