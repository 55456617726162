import { call, put, select, takeEvery } from 'redux-saga/effects';
import AppUtils from '@control-front-end/utils/utils';
import { RequestStatus } from 'constants';
import {
  CREATE_TRANSFER,
  CREATE_FIELDS_TRANSFER,
  FILTER_TRANSFERS,
  GET_TRANSFER,
  ADD_TRANSFER,
  FILTER_TRANSFERS_REQ_STATUS,
} from '@control-front-end/common/constants/actorAccounts';
import api from '@control-front-end/common/sagas/api';
import { Utils } from 'mw-style-react';
import { makeActorPicture, makeUserWithAvatar } from './graph/graphHelpers';

/**
 * Make full transfer model
 * @param models
 */
function* makeTransfersModel(models) {
  const config = yield select((state) => state.config);
  models = Array.isArray(models) ? models : [models];
  for (const item of models) {
    item.user = makeUserWithAvatar(item.user, config);

    // Transfer source previously was a single object (Check for backward compatibility)
    item.details.from = Array.isArray(item.details.from)
      ? item.details.from
      : [item.details.from];

    for (const obj of item.details.from) {
      obj.actorPictureUrl = yield makeActorPicture({
        picture: obj.actorPicture,
        systemObjSAId: obj.systemObjSAId,
      });
      if (obj.removed) obj.actorTitle = 'Removed';
    }

    for (const obj of item.details.to) {
      obj.actorPictureUrl = yield makeActorPicture({
        picture: obj.actorPicture,
        systemObjSAId: obj.systemObjSAId,
      });
      if (obj.removed) obj.actorTitle = 'Removed';
    }
  }
  return models;
}

/**
 * Add transfer to list (if matches filter)s
 */
function* addTransfer({ payload, callback }) {
  const { transfer } = payload;
  const { details } = transfer;
  const allAccountsList = [
    ...(Array.isArray(details.from) ? details.from : [details.from]),
    ...details.to,
  ];
  const { list, limit, total, filter } = yield select(
    (state) => state.transfers
  );

  if (!filter) return;

  const { from, to, accounts, actors, formFields, incomeType, accountType } =
    filter;
  const misMatchedAccount =
    accounts &&
    accounts.every(
      (item) =>
        !allAccountsList.find(
          (acc) =>
            acc.nameId === item.nameId && +acc.currencyId === +item.currencyId
        )
    );

  const misMatchedFormFields =
    formFields &&
    formFields.every(
      (item) =>
        !allAccountsList.find(
          (acc) =>
            String(acc.formId) === String(item.formId) &&
            acc.fieldId === item.fieldId
        )
    );
  const mismatchedActor =
    actors?.length &&
    actors.every(
      (item) => !allAccountsList.find((acc) => acc.actorId === item.id)
    );
  const mismatchedIncomeType =
    incomeType && !allAccountsList.find((acc) => acc.incomeType === incomeType);
  const mismatchedAccountType =
    !formFields && accountType && accountType !== details.from[0].type;
  const isOutOfRange = transfer.createdAt < from || transfer.createdAt > to;
  if (
    misMatchedAccount ||
    misMatchedFormFields ||
    mismatchedActor ||
    mismatchedIncomeType ||
    mismatchedAccountType ||
    isOutOfRange
  )
    return;

  const copyList = list.slice();
  copyList.unshift(transfer);
  const sortedList = Utils.sort(copyList, 'createdAt', 'desc').slice(0, limit);
  yield put({
    type: ADD_TRANSFER.SUCCESS,
    payload: {
      list: sortedList,
      total: total + 1,
    },
  });

  if (callback) callback();
}

/**
 * Make transfer
 */
function* createTransfer({ payload, callback, formActions }) {
  const { active: accId } = yield select((state) => state.accounts);
  const { from, to, comment } = payload;
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/transfers/${accId}`,
    body: { type: 'finance', from, to, comment },
  });
  if (formActions) formActions.setSubmitting(false);
  if (result !== RequestStatus.SUCCESS) return;
  const [transfer] = yield call(makeTransfersModel, data.data);
  yield put({
    type: CREATE_TRANSFER.SUCCESS,
    payload: transfer,
  });
  if (formActions) formActions.onClose();
  if (callback) callback(transfer);
}

/**
 * Make fields transfer
 */
function* createFieldsTransfer({ payload, callback }) {
  const { active: accId } = yield select((state) => state.accounts);
  const { from, to, comment } = payload;
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/transfers/form_fields/${accId}`,
    body: { from, to, comment },
  });
  const [transfer] = yield call(makeTransfersModel, data.data);
  if (result !== RequestStatus.SUCCESS) return;
  yield put({
    type: CREATE_FIELDS_TRANSFER.SUCCESS,
    payload: transfer,
  });
  if (callback) callback(transfer);
}

/**
 * Filter transfers
 */
// from, to, accounts, actors, incomeType, type, amount, oper, ownerId, query, ref, transferId,
function* filterTransfers({ payload, callback }) {
  const { active: accId } = yield select((state) => state.accounts);
  const {
    limit: customLimit,
    offset: customOffset,
    total,
    ...filter
  } = payload;
  const {
    limit: defaultLimit,
    offset: defaultOffset,
    reqStatus,
  } = yield select((state) => state.transfers);
  const limit = customLimit || defaultLimit;
  const offset = !AppUtils.isUndefined(customOffset)
    ? customOffset
    : defaultOffset;
  if (reqStatus !== RequestStatus.SUCCESS) return;
  if (!total)
    yield put({ type: FILTER_TRANSFERS_REQ_STATUS, payload: 'inProgress' });
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/transfers/filter/${accId}`,
    queryParams: { total },
    body: { ...filter, limit, offset },
  });
  if (!total)
    yield put({ type: FILTER_TRANSFERS_REQ_STATUS, payload: 'success' });
  if (result !== RequestStatus.SUCCESS) return;

  const newPayload = {};
  if (total) {
    newPayload.total = data.data.total;
  } else {
    newPayload.list = yield call(makeTransfersModel, data.data);
    newPayload.filter = { ...payload };
  }
  yield put({
    type: FILTER_TRANSFERS.SUCCESS,
    payload: newPayload,
  });
  if (callback) callback(data.data);
}

/**
 * Get transfer full info
 */
function* getTransfer({ payload, callback, errorCallback }) {
  const { transferId } = payload;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/transfers/${transferId}`,
    handleErrCodes: errorCallback ? [404] : [],
  });
  if (result !== RequestStatus.SUCCESS) {
    if (data.statusCode === 404 && errorCallback) errorCallback();
    return;
  }
  const [transfer] = yield call(makeTransfersModel, data.data);
  if (callback) callback(transfer);
}

function* transfers() {
  yield takeEvery(FILTER_TRANSFERS.REQUEST, filterTransfers);
  yield takeEvery(CREATE_TRANSFER.REQUEST, createTransfer);
  yield takeEvery(CREATE_FIELDS_TRANSFER.REQUEST, createFieldsTransfer);
  yield takeEvery(GET_TRANSFER.REQUEST, getTransfer);
  yield takeEvery(ADD_TRANSFER.REQUEST, addTransfer);
}

export default transfers;
