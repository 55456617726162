const TYPE = {
  NEW_ACTOR: 'NEW_ACTOR',
  NEW_REACTION: 'NEW_REACTION',
};

const IMPORTANCE = {
  DEFAULT: 'DEFAULT',
  IMPORTANT: 'IMPORTANT',
};

const ALERT = {
  SOUND: 'SOUND',
  PUSH: 'PUSH',
};

const DEFAULT_ALERTS = {
  [TYPE.NEW_ACTOR]: {
    [IMPORTANCE.DEFAULT]: { [ALERT.PUSH]: true },
    [IMPORTANCE.IMPORTANT]: { [ALERT.PUSH]: true },
  },
};

export { TYPE, IMPORTANCE, ALERT, DEFAULT_ALERTS };
