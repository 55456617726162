import React from 'react';
import { useSelector } from 'react-redux';
import useIntl from 'useIntl';
import SelectActors from '../SelectActors';
import mes from './intl';

/**
 * Поиск CDU скриптов
 */
function SelectCDUScript(props) {
  const t = useIntl();
  const scriptsFormId = useSelector((state) => state.systemForms.scripts?.id);

  return (
    <SelectActors
      bordered
      unspaced
      multiselect={false}
      resultLabel={t(mes.scripts)}
      placeholder={t(mes.searchScriptsByName)}
      formId={scriptsFormId}
      {...props}
    />
  );
}

export default SelectCDUScript;
