import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  checkboxTrue: {
    id: 'checkboxTrue',
    defaultMessage: 'Yes',
  },
  checkboxFalse: {
    id: 'checkboxFalse',
    defaultMessage: 'No',
  },
  actorTmplValue: {
    id: 'actorTmplValue',
    defaultMessage: 'Enter value',
  },
});

export default { ...globalIntl, ...m };
