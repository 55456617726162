import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  mainTextTransactionFilter: {
    id: 'mainTextTransactionFilter',
    defaultMessage: 'Selected actor has been deleted',
  },
  extraTextTransactionFilter: {
    id: 'extraTextTransactionFilter',
    defaultMessage: 'Nested fields are no longer available to edit.',
  },
  createFilter: {
    id: 'createFilter',
    defaultMessage: 'Create actor (Filter)',
  },
  updateFilter: {
    id: 'updateFilter',
    defaultMessage: 'Update actor (Filter)',
  },
  saveAndSearch: {
    id: 'saveAndSearch',
    defaultMessage: 'Save and search',
  },
  advancedOptions: {
    id: 'advancedOptions',
    defaultMessage: 'Advanced options',
  },
  accountOptions: {
    id: 'accountOptions',
    defaultMessage: 'Account options',
  },
  accountType: {
    id: 'accountType',
    defaultMessage: 'Account type',
  },
  verificationStatus: {
    id: 'verificationStatus',
    defaultMessage: 'Verification status',
  },
  transactionStatus: {
    id: 'transactionStatus',
    defaultMessage: 'Status',
  },
  transactionIncomeType: {
    id: 'transactionIncomeType',
    defaultMessage: 'Type',
  },
  labelStatus_verified: {
    id: 'labelStatusType_verified',
    defaultMessage: 'Verificated',
  },
  labelStatus_pending: {
    id: 'labelStatus_pending',
    defaultMessage: 'Pending verification',
  },
  labelStatus_rejected: {
    id: 'labelStatus_rejected',
    defaultMessage: 'Rejected',
  },
  labelStatus_null: {
    id: 'labelStatus_null',
    defaultMessage: 'Not verificated',
  },
  enterOwnerName: {
    id: 'enterOwnerName',
    defaultMessage: 'Enter owner name',
  },
});

export default { ...globalIntl, ...m };
