import { createReducer } from '@reduxjs/toolkit';
import {
  GET_USERS,
  GET_USERS_BULK,
  GET_USERS_ROLES,
  SEARCH_USERS,
  CLEAR_USERS,
  SET_USERS_REQ_STATUS,
} from '../constants';

// ------------------------------------
// Reducer
// ------------------------------------
const initState = {
  list: [],
  limit: 15,
  offset: 0,
  total: 0,
  reqStatus: 'success',
  endList: false,
};

export default createReducer(initState, {
  [GET_USERS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [GET_USERS_BULK.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [GET_USERS_ROLES.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SEARCH_USERS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SET_USERS_REQ_STATUS](state, action) {
    return { ...state, reqStatus: action.payload };
  },
  [CLEAR_USERS]() {
    return initState;
  },
});
