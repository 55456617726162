import React, { useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon, Upload } from 'mw-style-react';
import { useModal, useIntl, useOutsideClick } from 'hooks';
import SelectActors from '@control-front-end/common/components/SelectActors';
import { getFileMimeTypes } from '../../../../selectors';
import mes from './intl';
import './Controls.scss';

function Controls({ onChange, onUpload, access, additionalControls = null }) {
  const t = useIntl();
  const actorPopupRef = useRef();
  const eventsFormId = useSelector((state) => state.systemForms.events.id);
  const fileMimeTypes = useSelector(getFileMimeTypes);
  const [openActorPopup, toggleActorPopup] = useState(false);

  const { open } = useModal('PersonalScriptModal', {
    onSubmit: (value) => onChange({ id: 'app', value }),
  });

  const openScriptModal = useCallback(() => open({ access }), [access]);

  useOutsideClick({
    ref: actorPopupRef,
    callback: () => toggleActorPopup(false),
  });

  return (
    <div styleName="event__controls">
      <div styleName="event__controls__icons">
        <Upload
          styleName="event__controls__upload"
          preview={false}
          multiple={true}
          accept={fileMimeTypes}
          onChange={(obj) => onUpload(obj, false)}
          visibility={fileMimeTypes ? 'visible' : 'hidden'}
        >
          <Icon styleName="event__controls__btn" size="medium" type="attach" />
        </Upload>
        <Upload
          styleName="event__controls__upload"
          preview={false}
          multiple={true}
          accept="image/*"
          onChange={(obj) => onUpload(obj, true)}
          visibility={
            fileMimeTypes && fileMimeTypes.includes('image/')
              ? 'visible'
              : 'hidden'
          }
        >
          <Icon styleName="event__controls__btn" size="medium" type="picture" />
        </Upload>
        <div styleName="event__controls__popup__wrap">
          <div onClick={openScriptModal}>
            <Icon
              styleName="event__controls__btn"
              size="medium"
              type="form_pole"
              visibility="visible"
            />
          </div>
        </div>
        <div styleName="event__controls__popup__wrap">
          <Icon
            styleName="event__controls__btn"
            size="medium"
            type="actor_card"
            visibility="visible"
            onClick={() => toggleActorPopup(true)}
          />
          {openActorPopup ? (
            <div ref={actorPopupRef} styleName="event__controls__popup">
              <SelectActors
                wrap={true}
                bordered={true}
                autoFocus={true}
                size="large"
                resultLabel={t(mes.actors)}
                placeholder={t(mes.searchActorPlaceholder)}
                value={[]}
                excludeFormIds={[eventsFormId]}
                onChange={({ value }) => {
                  const [actor] = value;
                  onChange({ id: 'cardActor', value: actor });
                  toggleActorPopup(false);
                }}
                handleBlur={() => toggleActorPopup(false)}
              />
            </div>
          ) : null}
        </div>
      </div>
      {additionalControls}
    </div>
  );
}

Controls.propTypes = {
  onUpload: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  access: PropTypes.arrayOf(PropTypes.object),
  additionalControls: PropTypes.node,
};

export default Controls;
