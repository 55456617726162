import { defineMessages } from 'react-intl';

const m = defineMessages({
  addScript: {
    id: 'addScript_PersonalScriptModal',
    defaultMessage: 'Add script',
  },
  editScript: {
    id: 'editScript_PersonalScriptModal',
    defaultMessage: 'Edit script',
  },
});

export default m;
