import React, { useMemo, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useLocation } from 'react-router-dom';
import { Utils, ProgressBar, DateUtils } from 'mw-style-react';

// link docs: https://fullcalendar.io/docs/views
import { CALENDAR_VIEWS } from 'constants';

import { DEFAULT_TIME_FORMAT } from './utils';

import scss from './EventsCalendar.scss';

const DEFAULT_INITIAL_VIEW = CALENDAR_VIEWS.timeGridDay;
const TIME_FORMAT_COMMON = { hour: '2-digit', minute: '2-digit' };
const EVENT_TIME_FORMATS = {
  [DateUtils.TIME_FORMATS[12]]: { ...TIME_FORMAT_COMMON, hour12: true },
  [DateUtils.TIME_FORMATS[24]]: { ...TIME_FORMAT_COMMON, hour12: false },
};
const SLOT_LABEL_FORMATS = {
  [DateUtils.TIME_FORMATS[12]]: { hour: 'numeric', hour12: true },
  [DateUtils.TIME_FORMATS[24]]: { ...TIME_FORMAT_COMMON, hour12: false },
};

/**
 * @see available props here: https://fullcalendar.io/docs#toc
 */
function BaseCalendar({
  loading,
  calendarView,
  events,
  eventContent: eventContentProps,
  initialSelectedEventIds,
  datesSet: datesSetProps,
  timeFormat = DEFAULT_TIME_FORMAT,
  ...rest
}) {
  const { search } = useLocation();
  const calendarRef = useRef(null);

  const [selectedEventId, setSelectedEventId] = useState(null);
  const [initialEventsSelectedIds, setInitialEventsSelectedIds] = useState(
    initialSelectedEventIds || []
  );
  const [scrolledToEarliestEvent, setScrolledToEarliestEvent] = useState(false);

  const handleCalendarClick = () => {
    if (initialEventsSelectedIds.length > 0) {
      setInitialEventsSelectedIds([]);
    }
  };

  const { calendarInitialView, initialDate: initialDateUnixTime } =
    Utils.getQueryParam(search);

  const initialDate = useMemo(
    () =>
      initialDateUnixTime ? new Date(Number(initialDateUnixTime) * 1000) : null,
    [initialDateUnixTime]
  );

  useEffect(() => {
    const scrollToEarliestEvent = () => {
      const calendarApi = calendarRef.current.getApi();
      const calendarContainer = calendarApi.el;

      const currentView = calendarApi.view.type;

      if (events.length === 0 || scrolledToEarliestEvent) return;

      let targetEvent;

      if (
        [CALENDAR_VIEWS.timeGridWeek, CALENDAR_VIEWS.timeGridDay].includes(
          currentView
        )
      ) {
        // Find the earliest event for the weekly and daily view
        const filteredEvents =
          initialEventsSelectedIds.length > 0
            ? events.filter((i) =>
                initialEventsSelectedIds.includes(i?.extendedProps?.eventId)
              )
            : events;

        if (filteredEvents.length === 0) return;

        targetEvent = filteredEvents.reduce((earliest, current) => {
          const earliestHours = new Date(earliest.start).getHours();
          const earliestMinutes = new Date(earliest.start).getMinutes();
          const currentHours = new Date(current.start).getHours();
          const currentMinutes = new Date(current.start).getMinutes();

          return currentHours < earliestHours ||
            (currentHours === earliestHours && currentMinutes < earliestMinutes)
            ? current
            : earliest;
        });
      } else {
        return;
      }

      // Find the DOM element corresponding to the target event
      const targetEventElement = calendarContainer.querySelector(
        `[data-event-id="${targetEvent?.extendedProps?.eventId}"]`
      );

      // Scroll to the target event
      targetEventElement?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      setScrolledToEarliestEvent(true);
    };

    const timer = setTimeout(scrollToEarliestEvent, 0);

    return () => clearTimeout(timer);
  }, [events]);

  const eventContent = (data) => {
    const { eventId } = data?.event?.extendedProps || {};
    const isEventSelected =
      selectedEventId === eventId || initialEventsSelectedIds.includes(eventId);

    return eventContentProps({
      ...data,
      isSelected: isEventSelected,
      isBlured:
        (selectedEventId && !isEventSelected) ||
        (initialEventsSelectedIds.length > 0 && !isEventSelected),
      onSelect: setSelectedEventId,
    });
  };

  return (
    <div
      style={{ height: '100%', position: 'relative' }}
      onClick={handleCalendarClick}
    >
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView={
          CALENDAR_VIEWS[calendarInitialView] ||
          calendarView ||
          DEFAULT_INITIAL_VIEW
        }
        views={{
          [CALENDAR_VIEWS.dayGridMonth]: {
            titleFormat: { year: 'numeric', month: 'long' },
          },
          [CALENDAR_VIEWS.timeGridWeek]: {
            titleFormat: { year: 'numeric', month: 'short', day: 'numeric' },
          },
        }}
        slotLabelFormat={
          SLOT_LABEL_FORMATS[timeFormat] ||
          SLOT_LABEL_FORMATS[DEFAULT_TIME_FORMAT]
        }
        eventTimeFormat={
          EVENT_TIME_FORMATS[timeFormat] ||
          EVENT_TIME_FORMATS[DEFAULT_TIME_FORMAT]
        }
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        initialDate={initialDate}
        eventContent={eventContent}
        events={events}
        scrollTime="00:00:00"
        height="100%"
        datesSet={(data) => {
          setScrolledToEarliestEvent(false);
          datesSetProps(data);
        }}
        {...rest}
      />
      {loading ? (
        <div key="loader" className={scss.loader}>
          <ProgressBar size="large" />
        </div>
      ) : null}
    </div>
  );
}

BaseCalendar.propTypes = {
  datesSet: PropTypes.func.isRequired,
  select: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  events: PropTypes.array.isRequired,
  selectable: PropTypes.bool,
  editable: PropTypes.bool,
  unselect: PropTypes.func,
  initialSelectedEventIds: PropTypes.array,
  timeFormat: PropTypes.oneOf(Object.keys(DateUtils.TIME_FORMATS)),
  eventContent: PropTypes.func.isRequired,
};

export default BaseCalendar;
