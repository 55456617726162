import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalContent } from 'mw-style-react';
import useIntl from 'useIntl';
import PersonalScript from '../../../PersonalScript/PersonalScript';
import mes from './intl';

function PersonalScriptModal({
  onClose,
  data: { onSubmit, appSettings = {}, ...rest },
}) {
  const t = useIntl();
  const handleSubmit = useCallback((...args) => {
    onSubmit(...args);
    onClose();
  });

  const scriptEditing = Object.keys(appSettings).length;

  return (
    <Modal
      onClose={onClose}
      label={t(scriptEditing ? mes.editScript : mes.addScript)}
      visibility={true}
    >
      <ModalContent>
        <PersonalScript
          {...rest}
          appSettings={appSettings}
          onSubmit={handleSubmit}
        />
      </ModalContent>
    </Modal>
  );
}

PersonalScriptModal.propTypes = {
  data: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PersonalScriptModal;
