import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  SEARCH_FORMS,
  GET_FORM,
  GET_FORMS,
  PARENT_SYSTEM_FORMS,
} from '@control-front-end/common/constants/forms';
import useIntl from 'useIntl';
import mes from './intl';
import SelectEntityField from '../SelectEntityField';

const initState = {
  list: [],
  limit: 15,
  offset: 0,
  endList: false,
  reqStatus: 'success',
};

/**
 * Поиск шаблонов акторов
 */
function SelectActorsTemplate(props) {
  const {
    exclude = [],
    withRelations = false,
    withDefault = true,
    withTotal,
    formTypes = 'system,connector',
    ignoreUatAccess = false,
    isParent = false,
    isSameRoot = false,
    rootFormId,
    onBlur,
    onCreate,
    value,
  } = props;
  const dispatch = useDispatch();
  const t = useIntl();
  const auth = useSelector((state) => state.auth);
  const [stateValue, setStateValue] = useState(value);
  const [templates, setTemplates] = useState(initState);
  const [isLoading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const excludeSet = exclude.map((i) => i.id);

  useEffect(() => {
    if (value?.id) {
      setStateValue(value);
    } else if (value?.value && !value.title) {
      dispatch({
        type: GET_FORM.REQUEST,
        payload: {
          formId: value.value,
          withRelations,
          withDefault,
          handleAccessDenied: true,
        },
        callback: (data) => {
          setStateValue({
            value: data.id,
            title: data.title,
            accessDenied: data.accessDenied,
          });
        },
      });
    } else if (value && !value.value) {
      setStateValue(null);
    }
    if (Array.isArray(value)) setStateValue(value);
  }, [value.id, value.length, value.value]);

  const getData = (loadMore = false) => {
    const localState = loadMore ? templates : initState;
    setLoading(true);
    dispatch({
      type: GET_FORMS.REQUEST,
      payload: {
        loadMore,
        localState,
        withDefault,
        formTypes,
        withTotal,
        withRelations,
      },
      callback: (data) => {
        if (data) setTemplates(data);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (query.length < 1) return;
      setLoading(true);
      dispatch({
        type: SEARCH_FORMS.REQUEST,
        payload: { query, withRelations, withDefault },
        callback: (data) => {
          setTemplates({ ...initState, ...data });
          setLoading(false);
        },
      });
    }, 500);
    return () => {
      clearTimeout(timeOutId);
    };
  }, [query]);

  const clearData = () => {
    setTemplates(initState);
  };

  const handleBlur = () => {
    setQuery('');
    clearData();
    if (onBlur) onBlur();
  };

  /**
   * Обработка поискового запроса
   * @param query
   */
  const handleSearch = (query) => {
    if (!query.length) getData();
    setQuery(query);
  };

  const handleScroll = () => {
    if (isLoading) return;
    getData(true);
  };

  /**
   * Название шаблона
   * @returns {string|*}
   */
  const getTitle = (item) => {
    if (!item.isDefault) return item.title || item.name;
    if (item.ownerId === auth.id) return 'My Default';
    return `${item.ownerName || item.userName}'s Default`;
  };

  /**
   * Получить отфильтрованый список форм
   */
  const getTplsList = () => {
    const itemsList = templates.list.filter((i) => {
      const isExcluded = excludeSet.includes(i.id);
      const isAccessible =
        ignoreUatAccess ||
        !withRelations ||
        (!!i.forms && i.forms.every((j) => !j.accessDenied));
      return !isExcluded && isAccessible;
    });
    itemsList.forEach((tpl) => {
      tpl.title = getTitle(tpl);
      if (tpl.isDisabled) return;
      if (isSameRoot && rootFormId) {
        const hasSameRoot = tpl.forms.some((i) => i.id === rootFormId);
        tpl.isDisabled = !hasSameRoot;
        return;
      }
      if (isParent && tpl.type && !PARENT_SYSTEM_FORMS.includes(tpl.title)) {
        tpl.isDisabled = true;
        tpl.title = `${tpl.title} (${t(mes.cantBeParentForm)})`;
      }
    });
    return itemsList;
  };

  return (
    <SelectEntityField
      {...props}
      objType="template"
      list={getTplsList()}
      onSearch={handleSearch}
      onFocus={() => getData()}
      onBlur={handleBlur}
      onScroll={handleScroll}
      value={stateValue}
      isLoading={isLoading}
      onCreate={onCreate}
    />
  );
}

SelectActorsTemplate.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  exclude: PropTypes.array,
  ignoreUatAccess: PropTypes.bool,
  withRelations: PropTypes.bool,
  withDefault: PropTypes.bool,
  withTotal: PropTypes.bool,
  formTypes: PropTypes.string,
  isParent: PropTypes.bool,
  isSameRoot: PropTypes.bool,
  rootFormId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBlur: PropTypes.func,
  onCreate: PropTypes.func,
};

export default SelectActorsTemplate;
