import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  dragDropFiles: {
    id: 'dragDropFiles',
    defaultMessage: 'Drag and drop files here.',
  },
  multipleErrorText: {
    id: 'multipleErrorText',
    defaultMessage: 'Only one file allowed to upload',
  },
  fileTypeError: {
    id: 'fileTypeError',
    defaultMessage: 'File type is not allowed',
  },
  fileSize: {
    id: 'fileSize',
    defaultMessage: 'Maximum upload file size: {fileSize}MB',
  },
});

export default { ...globalIntl, ...m };
