import { call, put, select, takeEvery } from 'redux-saga/effects';
import { RequestStatus } from 'constants';
import {
  GET_TRIGGER_CALLS,
  RESEND_TRIGGER_CALL,
} from '@control-front-end/common/constants/actorAccounts';
import api from '@control-front-end/common/sagas/api';
import { makeActorPicture } from './graph/graphHelpers';

/**
 * Get triggers calls history
 */
function* getTriggerCalls({ payload, callback }) {
  const { active: accId } = yield select((state) => state.accounts);
  const {
    actorIdInit,
    from,
    to,
    limit = 20,
    offset,
    ...customFilters
  } = payload;
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/triggers/calls/${accId}`,
    body: {
      actorIdInit,
      from,
      to,
      limit,
      offset,
      ...customFilters,
    },
  });
  if (result !== RequestStatus.SUCCESS) return;
  for (const obj of data.data) {
    obj.actorPictureUrl = yield makeActorPicture({
      picture: obj.actorPicture,
    });
  }
  yield put({
    type: GET_TRIGGER_CALLS.SUCCESS,
    payload: { list: data.data },
  });
  if (callback) callback(data.data);
}

/**
 * Resend trigger external call
 */
function* resendTriggerCall({ payload, callback }) {
  const { actorId, callId } = payload;
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/triggers/resend_call/${actorId}/${callId}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback(data.data);
}

function* transactions() {
  yield takeEvery(GET_TRIGGER_CALLS.REQUEST, getTriggerCalls);
  yield takeEvery(RESEND_TRIGGER_CALL.REQUEST, resendTriggerCall);
}

export default transactions;
