import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  actorTmplKey: {
    id: 'actorTmplKey',
    defaultMessage: 'Select field',
  },
  addField: {
    id: 'addField',
    defaultMessage: 'Add field',
  },
});

export default { ...globalIntl, ...m };
