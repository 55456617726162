export default {
  groupToArraysBy(list, groupKey) {
    const groups = [];
    const properties = groupKey.split('.');
    list.forEach((i) => {
      let groupKeyVal =
        properties.reduce((prev, curr) => prev?.[curr], i) || '';
      if (!groupKeyVal.toString().length) {
        groups.push({ key: null, items: [i] });
        return;
      }
      if (typeof groupKeyVal === 'object') {
        groupKeyVal = JSON.stringify(groupKeyVal);
      }
      const keyGroup = groups.find((j) => j.key === groupKeyVal);
      if (keyGroup) {
        keyGroup.items.push(i);
      } else {
        groups.push({ key: groupKeyVal, items: [i] });
      }
    });
    return groups;
  },

  groupBy(list, groupKey) {
    const group = {};
    const properties = groupKey.split('.');
    list.forEach((i) => {
      let groupKeyVal =
        properties.reduce((prev, curr) => prev?.[curr], i) || '';
      if (typeof groupKeyVal === 'object') {
        groupKeyVal = JSON.stringify(groupKeyVal);
      }
      if (group[groupKeyVal]) {
        group[groupKeyVal].push(i);
      } else {
        group[groupKeyVal] = [i];
      }
    });
    return group;
  },

  unique(arr) {
    return arr.filter((v, i, a) => a.indexOf(v) === i);
  },

  uniqueObjArray(array, field) {
    const map = new Map();
    array.forEach((i) => {
      const properties = field.split('.');
      const val = properties.reduce((prev, curr) => prev?.[curr], i);
      if (!map.has(val)) map.set(val, i);
    });
    return Array.from(map.values());
  },

  sort(arr, field, type = 'asc') {
    return arr.sort((a, b) => {
      const properties = field.split('.');
      const valA = properties.reduce((prev, curr) => prev?.[curr], a);
      const valB = properties.reduce((prev, curr) => prev?.[curr], b);
      if (valA > valB) return type === 'asc' ? 1 : -1;
      if (valA < valB) return type === 'asc' ? -1 : 1;
      return 0;
    });
  },

  findAllIndexes(arr, callback) {
    const indices = [];
    for (let i = 0, len = arr.length; i < len; i += 1) {
      if (callback(arr[i]) === true) indices.push(i);
    }
    return indices;
  },

  hasDuplicates(array) {
    return new Set(array).size !== array.length;
  },

  removeFromArrayObject(arr, field, value) {
    const arrCopy = arr.slice();
    const index = arrCopy.findIndex((i) => i[field] === value);
    if (index === -1) return arrCopy;
    arrCopy.splice(index, 1);
    return arrCopy;
  },

  replaceItemArrayObject(arr, field, value) {
    const arrCopy = arr.slice();
    const index = arrCopy.findIndex((i) => i[field] === value[field]);
    if (index === -1) {
      arrCopy.unshift(value);
    } else {
      arrCopy.splice(index, 1, value);
    }
    return arrCopy;
  },

  diffArrayObj(a, b, field) {
    return a.filter((i) => !b.some((j) => i[field] === j[field]));
  },

  nonDeepObjectDiff(a, b) {
    const changes = {};
    for (const key in b) {
      if (!a || JSON.stringify(a[key]) !== JSON.stringify(b[key])) {
        changes[key] = b[key];
      }
    }
    return changes;
  },

  recursiveFind(list, id) {
    let obj = (list || []).find((i) => i.id === id);
    if (obj) return obj;
    for (const item of list) {
      obj = this.recursiveFind(item.children, id);
      if (obj) return obj;
    }
  },

  selectWithShift(list, oldSelection, newItemIndex) {
    if (!oldSelection.length) return list.slice(0, newItemIndex + 1);
    const firstSelectedIndex = list.findLastIndex((i) =>
      oldSelection.find((j) => j.id === i.id)
    );
    const lastSelectedIndex = list.findLastIndex((i) =>
      oldSelection.findLast((j) => j.id === i.id)
    );
    const selectionStart = firstSelectedIndex === -1 ? 0 : firstSelectedIndex;
    const selectionEnd = lastSelectedIndex === -1 ? 0 : lastSelectedIndex;
    return newItemIndex >= selectionStart
      ? list.slice(selectionStart, newItemIndex + 1)
      : list.slice(newItemIndex, selectionEnd + 1);
  },

  stringifyNestedObjects(data) {
    const list = Array.isArray(data) ? data : [data];
    return list.map((item) => {
      const changedKeys = {};
      for (const key of Object.keys(item)) {
        const value = item[key];
        if (value === null) continue;
        if (typeof value === 'object') {
          changedKeys[key] = Object.keys(value).length
            ? JSON.stringify(value)
            : '';
        }
      }
      return { ...item, ...changedKeys };
    });
  },
};
