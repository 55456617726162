import keyBy from 'lodash/keyBy';

const getFormFieldsMap = (form) => {
  return form
    ? keyBy(
        form.forms.reduce(
          (
            acc,
            { id: formId, title: formTitle, color: formColor, isRoot, form }
          ) => {
            acc.push(
              ...form.sections.reduce((acc, { title, content }) => {
                acc.push(
                  ...content.reduce((acc, actorField) => {
                    acc.push({
                      ...actorField,
                      sectionTitle: title,
                      form: {
                        isRoot,
                        id: formId,
                        title: formTitle,
                        color: formColor,
                      },

                      // TODO: Remove fields below, use form.fieldName instead
                      formId,
                      formTitle,
                      formColor,
                    });
                    return acc;
                  }, [])
                );
                return acc;
              }, [])
            );
            return acc;
          },
          []
        ),
        'id'
      )
    : {};
};

export default getFormFieldsMap;
