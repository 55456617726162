import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Label, Icon, Button } from 'mw-style-react';
import cn from 'classnames';
import useIntl from 'useIntl';
import { PERMISSIONS } from '@control-front-end/common/constants/permissions';
import { checkUserPermissions } from '../../../app/src/selectors';
import mes from './intl';
import './EmptyList.scss';

const _EMPTY_ICONS_ = {
  event: 'event',
  actor: 'actor',
  graph: 'graph',
  layer: 'layer_filled',
  form: 'form_pole',
  user: 'person',
  group: 'persons',
  file: 'folder',
  account: 'coin',
  transaction: 'transaction',
  script: 'script',
  export: 'export',
  import: 'import',
  history: 'history',
  call: 'trigger',
};

const _EMPTY_ITEM_TYPE_ = {
  accName: 'account name',
  history: 'change',
};

const _PERM_ITEM_TYPE_ = {
  event: PERMISSIONS.ACTORS_MANAGEMENT,
  actor: PERMISSIONS.ACTORS_MANAGEMENT,
  graph: PERMISSIONS.ACTORS_MANAGEMENT,
  layer: PERMISSIONS.ACTORS_MANAGEMENT,
  form: PERMISSIONS.FORMS_MANAGEMENT,
  account: PERMISSIONS.ACCOUNTS_MANAGEMENT,
  script: PERMISSIONS.ACTORS_MANAGEMENT,
  export: PERMISSIONS.SETTINGS_MANAGEMENT,
  import: PERMISSIONS.SETTINGS_MANAGEMENT,
  webhook: PERMISSIONS.SETTINGS_MANAGEMENT,
};

/**
 * Универсальный компонент пустого списка
 */
function EmptyList({
  objType,
  size = 'medium',
  isSearch = false,
  handleCreate,
}) {
  const t = useIntl();
  const checkPermissions = useSelector(checkUserPermissions) || {};
  const canCreate = handleCreate && typeof handleCreate === 'function';

  const icon = _EMPTY_ICONS_[objType] || 'search';
  const type = _EMPTY_ITEM_TYPE_[objType] || objType;
  const perm = _PERM_ITEM_TYPE_[objType];

  const getAdditionalText = () => {
    if (isSearch) return t(mes.pleaseTryAnotherSearch);
    return objType === 'member'
      ? t(mes.youCanInvite)
      : t(mes.youCanCreate, { type });
  };

  const emptyListLabel = t(mes[`${type}Empty`]) || t(mes.noItemsYet, { type });
  return (
    <div styleName={cn('empty', size)}>
      <div styleName="empty__icon">
        <Icon size="xlarge" type={icon} />
      </div>
      <div styleName="empty__label">
        <Label
          fontWeight="normal"
          value={isSearch ? t(mes.noResultsFound) : emptyListLabel}
        />
        <Label
          fontWeight="normal"
          value={getAdditionalText()}
          visibility={
            checkPermissions([perm]) && canCreate ? 'visible' : 'hidden'
          }
        />
      </div>
      {checkPermissions([perm]) && canCreate && !isSearch ? (
        <Button
          size="smallplus"
          fontWeight="normal"
          label={
            type === 'member'
              ? t(mes.inviteMembers)
              : t(mes.createItem, { type })
          }
          onClick={handleCreate}
        />
      ) : null}
    </div>
  );
}

EmptyList.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  objType: PropTypes.oneOf([
    'actor',
    'event',
    'stream',
    'layer',
    'graph',
    'accName',
    'currency',
    'account',
    'transaction',
    'transfer',
    'user',
    'group',
    'credential',
    'webhook',
    'form',
    'script',
    'widget',
    'file',
    'export',
    'import',
    'workspace',
    'history',
    'call',
    'company',
  ]),
  isSearch: PropTypes.bool,
  handleCreate: PropTypes.func,
};

export default EmptyList;
