import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useNotifications, useIntl } from 'hooks';
import {
  Button as ButtonMW,
  Modal,
  ModalButtons,
  ModalContent,
  ProgressBar,
  Divider,
  Label as LabelMW,
  Stack,
  Space,
  Utils,
  withForm,
} from 'mw-style-react';
import {
  GET_ACTOR,
  UPDATE_ACTOR,
} from '@control-front-end/common/constants/graphActors'; // NOSONAR
import getActorFieldsMap from '@control-front-end/utils/getActorFieldsMap';
import { DEL_MODAL } from 'constants';
import FormUtils from '@control-front-end/utils/formUtils';
import CollapsableList from '@control-front-end/app/src/components/CollapsableList';
import {
  Button,
  Calendar,
  Check,
  Edit,
  Image,
  Label,
  Link,
  Radio,
  Select,
  MultiSelect,
  Upload,
} from '@control-front-end/forms/FormTab/Items';
import SelectActors from '@control-front-end/common/components/SelectActors';
import VisibleColumns from '../CreateActorsFilter/components/VisibleColumns';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import m from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import l from './CreateFormFieldTransaction.scss';

const FORM_ITEMS = {
  Button,
  Calendar,
  Check,
  Edit,
  Image,
  Label,
  Link,
  Radio,
  Select,
  MultiSelect,
  Upload,
};

const getFullFieldFormId = ({ form, id }) =>
  form.isRoot ? id : `__form__${form.id}:${id}`;

function CreateFormFieldTransactionModal(props) {
  const { data, values, handleOnChange, visibility, onClose, errors } = props;
  const t = useIntl();
  const dispatch = useDispatch();
  const { showNotification } = useNotifications();
  const defaultForm = useSelector((state) => state.defaultActorTpl);
  const { active: accId } = useSelector((state) => state.accounts);
  const [actorId, setActorId] = useState(data.actorId);
  const [actorData, setActorData] = useState({});
  const [formFields, setFormFields] = useState([]);
  const [fieldId, setFieldId] = useState();
  const [isLoading, setLoading] = useState(false);
  const [fieldBalance, setFieldBalance] = useState(null);
  const fieldAmount = formFields.find((i) => i.id === fieldId);

  useEffect(() => {
    if (!fieldAmount) return;
    setFieldBalance(fieldAmount);
  }, [fieldId]);

  useEffect(() => {
    if (!actorId) return;
    dispatch({
      type: GET_ACTOR.REQUEST,
      payload: { id: actorId },
      callback: (data) => {
        setActorData(data);
        const actorFields = Object.values(getActorFieldsMap(data));
        const relevantFieldId = actorFields?.find(
          (i) => i.id === props.data.fieldId
        )?.id;
        setFormFields(actorFields);
        setFieldId(relevantFieldId || null);
      },
    });
  }, [actorId]);

  const renderFieldBalance = () => {
    if (!fieldBalance) return <LabelMW value="-" />;
    return (
      <CollapsableList
        value={FormUtils.getFormItemValue(
          { ...fieldBalance, class: fieldBalance.class },
          accId,
          {
            interactiveChips: false,
          }
        )}
        previewAmount={4}
      />
    );
  };

  const handleChangeFormField = (item, fullItemId) => {
    const newFormFields = formFields.map((i) =>
      i.id === item.id ? { ...i, ...item } : i
    );
    const newActorData = { ...actorData };
    newActorData.data[fullItemId] = item.value;
    setFormFields(newFormFields);
    setActorData(newActorData);
  };

  const isEmptyValue = (value) => {
    return (
      (typeof value === 'object' && !Object.keys(value).length) ||
      !value ||
      !value.length
    );
  };

  const getErrorMsg = ({ error, value, required, type, errorMsg }) => {
    if (!error) return '';
    return required && isEmptyValue(value)
      ? t(mes.requiredField)
      : errorMsg ||
          `${t(mes.incorrectData)} [${Utils.toUpperLatter(type || '')}]`;
  };

  const renderAmount = () => {
    if (!fieldAmount?.class) return <LabelMW value="-" />;
    const FormItem = FORM_ITEMS[Utils.toUpperLatter(fieldAmount.class)];
    return (
      <div className={l.amount}>
        <FormItem
          key={fieldId}
          formId={fieldAmount.formId}
          onChange={(item) =>
            handleChangeFormField(item, getFullFieldFormId(fieldAmount))
          }
          getErrorMsg={getErrorMsg}
          isPreview={true}
          {...fieldAmount}
          title=""
        />
      </div>
    );
  };

  const checkSubmit = () => {
    if (isLoading || fieldBalance === null || !fieldAmount) return false;
    if (fieldAmount.required && !fieldAmount.value) return false;
    if (fieldBalance.class === 'multiSelect')
      return (
        fieldBalance.value.length !== fieldAmount.value.length ||
        !fieldBalance.value.every(
          (i, index) => i.value === fieldAmount.value[index].value
        )
      );
    const comparableBalance = JSON.stringify(fieldBalance.value || {});
    const comparableAmount = JSON.stringify(fieldAmount.value || {});
    return comparableBalance.localeCompare(comparableAmount) !== 0;
  };

  const handleSubmit = () => {
    if (!checkSubmit() || fieldAmount?.error) return;
    const { id, formType } = actorData;
    if (formType === 'system') {
      showNotification('error', t(mes.cantUpdateSystemActor));
      return;
    }
    const rootForm = (actorData.forms || []).find((f) => !f.parentId);
    const actorFormId = rootForm?.id || defaultForm.id;
    setLoading(true);
    dispatch({
      type: UPDATE_ACTOR.REQUEST,
      payload: {
        ...actorData,
        id,
        formId: actorFormId,
        formData: actorData.data,
      },
      callback: () => {
        data.onSuccess?.();
        showNotification('success', t(mes.transactionCreated));
        dispatch({ type: DEL_MODAL });
      },
      formActions: {
        setSubmitting: setLoading,
      },
    });
  };

  return (
    <Modal
      styleName="m.modal l.modal"
      size="medium"
      onClose={onClose}
      label={t(mes.makeTransaction)}
      visibility={visibility}
    >
      <ModalContent styleName="m.modal__content l.transaction">
        <Space bottom size={Space.SIZE.xsmall}>
          <Stack.H
            styleName="l.transaction__actor"
            fullWidth
            alignItems="center"
            justifyContent="spaceBetween"
          >
            <SelectActors
              size="large"
              unspaced
              bordered={true}
              multiselect={false}
              label={
                <Space bottom size={Space.SIZE.xsmall}>
                  <LabelMW fontWeight="semibold" value={t(mes.actor)} />
                </Space>
              }
              placeholder={t(mes.selectActor)}
              value={values.actor || actorData}
              error={!!errors.actor}
              helperText={t(mes[errors.actor])}
              manageLayer={false}
              hideCreateBtn={true}
              fullModel={true}
              isDisabled={!!data.id}
              onChange={({ value }) => {
                handleOnChange({ id: 'actor', value, error: false });
                setActorId(value.id);
              }}
            />
          </Stack.H>
        </Space>
        <Space bottom size={Space.SIZE.xsmall}>
          <Stack className={l.selectField} justifyContent="center">
            <VisibleColumns
              selectedValue={fieldId}
              formFields={formFields}
              onChange={setFieldId}
              unspaced={true}
              isSingleSelect
              label={t(mes.account)}
              multipleForms={actorData.forms?.length > 1}
              disabled={!actorData.id}
              popoverOnTop
            />
            <LabelMW fontWeight="semibold" value="Balance" />
            {renderFieldBalance()}
            <Divider />
            <LabelMW
              fontWeight="semibold"
              value={`${t(mes.value)}${fieldAmount?.required ? ' *' : ''}`}
            />
            {renderAmount()}
          </Stack>
        </Space>
      </ModalContent>
      <ModalButtons styleName="m.modal__buttons">
        <ButtonMW
          label={t(mes.makeTransaction)}
          size="large"
          onClick={handleSubmit}
          visibility={checkSubmit() ? 'visible' : 'disabled'}
        />
        <ProgressBar
          styleName="m.modal__loader"
          type="circle"
          size="small"
          visibility={isLoading ? 'visible' : 'hidden'}
        />
      </ModalButtons>
    </Modal>
  );
}

CreateFormFieldTransactionModal.propTypes = {
  visibility: PropTypes.bool,
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleOnChange: PropTypes.func,
  isSubmit: PropTypes.bool,
  values: PropTypes.object,
  errors: PropTypes.object,
};

const CreateFormFieldTransaction = withForm(
  {
    mapPropsToValues(props) {
      const formData = props.data || {};
      return {
        actor: formData.actor,
        amount: formData.amount,
      };
    },
  },
  CreateFormFieldTransactionModal
);

export default CreateFormFieldTransaction;
