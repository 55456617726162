import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Space, Label, cr } from 'mw-style-react';

import { ERROR_CODES } from 'constants';
import { GET_DASHBOARD } from '@control-front-end/common/constants/graphActors';
import { useIntl } from 'hooks';

import scss from './DashboardFallback.scss';
import mes from './intl';

function DashboardFallback({ error }) {
  const t = useIntl();

  return cr(
    [
      error?.statusCode === ERROR_CODES.accessDenied,
      <Stack
        fullWidth
        fullHeight
        alignItems="center"
        justifyContent="center"
        className={scss.errorAccessContainer}
      >
        <Space size={Space.SIZE.xxlarge}>
          <Stack size={Space.SIZE.none} alignItems="center">
            <Space bottom size={Space.SIZE.xsmall}>
              <Label
                fontSize="large"
                fontWeight="semibold"
                color={Label.COLOR.white}
                value={t(mes.accessDeniedDashboardTitle)}
              />
            </Space>
            <Label
              color={Label.COLOR.white}
              value={
                error.requestType === GET_DASHBOARD.REQUEST
                  ? t(mes.accessDeniedDashboardOrAccountsText)
                  : t(mes.accessDeniedDashboardActorFilterText)
              }
              className={scss.errorAccessTextLabel}
            />
          </Stack>
        </Space>
      </Stack>,
    ],
    [
      true,
      <Stack fullWidth fullHeight alignItems="center" justifyContent="center">
        <Label color={Label.COLOR.balck} value={t(mes.defaultError)} />
      </Stack>,
    ]
  );
}

export default DashboardFallback;

DashboardFallback.propTypes = {
  error: PropTypes.shape({
    statusCode: PropTypes.oneOf(Object.values(ERROR_CODES)),
    requestType: PropTypes.oneOf([GET_DASHBOARD.REQUEST]),
  }),
};
