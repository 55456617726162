import PropTypes from 'prop-types';
import React from 'react';

import { ACCOUNT_TYPE } from '@control-front-end/common/constants/actorAccounts';
import CreateNumericTransferModal from './CreateNumericTransferModal';
import CreateFormFieldTransferModal from './CreateFormFieldTransferModal';

const TRANSFER_MODAL_BY_ACCOUNT_TYPE = {
  [ACCOUNT_TYPE.number]: CreateNumericTransferModal,
  [ACCOUNT_TYPE.formField]: CreateFormFieldTransferModal,
};

function CreateTransfer({ data, ...rest }) {
  const Component =
    TRANSFER_MODAL_BY_ACCOUNT_TYPE[data.transferType || ACCOUNT_TYPE.number];

  return <Component data={data} {...rest} />;
}

CreateTransfer.propTypes = {
  data: PropTypes.shape({
    transferType: PropTypes.oneOf(Object.keys(TRANSFER_MODAL_BY_ACCOUNT_TYPE)),
  }),
};

export default CreateTransfer;
