import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  fieldsErrorsFound: {
    id: 'fieldsErrorsFound',
    defaultMessage: 'Errors ({count}) were found',
  },
  fieldErrorFound: {
    id: 'fieldErrorFound',
    defaultMessage: 'Error was found',
  },
});

export default { ...globalIntl, ...m };
