import { defineMessages } from 'react-intl';

const m = defineMessages({
  participants: {
    id: 'participants__eventCalendar',
    defaultMessage: 'Participants',
  },
  description: {
    id: 'description__eventCalendar',
    defaultMessage: 'Description',
  },
  cloneEvent: {
    id: 'cloneEvent_eventCalendar',
    defaultMessage: 'Clone event',
  },
  openEvent: {
    id: 'openEvent_eventCalendar',
    defaultMessage: 'Open event',
  },
  removeEventPrivsError: {
    id: 'removeEventPrivsError_eventCalendar',
    defaultMessage: 'No permissions to remove Event',
  },
});

export default m;
