import React from 'react';
import PropTypes from 'prop-types';
import { Label, TextField, ColorPicker } from 'mw-style-react';
import useIntl from 'useIntl';
import mes from './intl';
import './FormSettings.scss';

function FormSettings({ description, color, handleFormField }) {
  const t = useIntl();

  return (
    <div styleName="fs">
      <div styleName="fs__section">
        <div>
          <Label styleName="fs__section__title" value={t(mes.formDescr)} />
          &nbsp;
          <Label styleName="fs__section__desc" value={t(mes.formDescrInfo)} />
        </div>
        <TextField
          id="description"
          styleName="fs__section__textfield"
          size="large"
          value={description || ''}
          bordered={true}
          error={false}
          onChange={handleFormField}
        />
      </div>
      <div styleName="fs__section">
        <div>
          <Label styleName="fs__section__title" value={t(mes.formColor)} />
          &nbsp;
          <Label styleName="fs__section__desc" value={t(mes.formColorSelect)} />
        </div>
        <div styleName="fs__section__color">
          <TextField
            styleName="fs__section__textfield"
            size="large"
            value={color || ''}
            bordered={true}
            error={false}
            colorPicker={() => (
              <ColorPicker
                value={color}
                onChange={(value) =>
                  handleFormField({ id: 'color', value: value.hex })
                }
                disableAlpha={true}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}

FormSettings.propTypes = {
  handleFormField: PropTypes.func,
  color: PropTypes.string,
  description: PropTypes.string,
};

export default FormSettings;
