import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  appAutorun: {
    id: 'appAutorun_ActorScript',
    defaultMessage: 'Autorun',
  },
  usersAmount: {
    id: 'usersAmount_ActorScript',
    defaultMessage: 'Users ({amount})',
  },
  exparationDate: {
    id: 'exparationDate_ActorScript',
    defaultMessage: 'Exp. date: {date}',
  },
});

export default { ...globalIntl, ...m };
