import PropTypes from 'prop-types';
import React from 'react';
import {
  Modal,
  ModalButtons,
  ModalContent,
  Button,
  ProgressBar,
  withForm,
  Label,
  TextField,
  Stack,
} from 'mw-style-react';
import Yup from 'yup';
import useIntl from 'useIntl';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import sModal from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import sLocal from './MakeActorCopies.scss'; // NOSONAR

function MakeActorCopiesBase(props) {
  const {
    values,
    errors,
    visibility,
    onClose,
    handleSubmit,
    handleOnChange,
    isSubmit,
  } = props;
  const t = useIntl();

  return (
    <Modal
      styleName="sModal.modal"
      visibility={visibility}
      onClose={onClose}
      label={t(mes.makeMultipleCopies)}
      size="small"
    >
      <ModalContent styleName="sModal.modal__content">
        <Stack size={Stack.SIZE.xsmall}>
          <Label value={t(mes.copiesCount)} fontWeight="semibold" />
          <TextField
            id="count"
            styleName="sModal.textfield sLocal.count"
            size="large"
            type="int"
            autoFocus={true}
            bordered={true}
            unspaced={true}
            value={values.count || ''}
            error={!!errors.count}
            helperText={t(mes[errors.count]) || errors.count}
            placeholder="1-50"
            onChange={handleOnChange}
          />
        </Stack>
      </ModalContent>
      <ModalButtons styleName="sModal.modal__buttons">
        <Button
          label={t(mes.createCopies)}
          size="large"
          onClick={handleSubmit}
          visibility={isSubmit ? 'disabled' : 'visible'}
        />
        <ProgressBar
          styleName="sModal.modal__loader"
          type="circle"
          size="small"
          visibility={isSubmit ? 'visible' : 'hidden'}
        />
      </ModalButtons>
    </Modal>
  );
}

const MakeActorCopies = withForm(
  {
    yup: Yup.object().shape({
      count: Yup.number().min(1).max(50).required('field_required'),
    }),
    handleSubmit: (values, dispatch, formActions, props) => {
      const { count } = values;
      const { callback } = props.data;
      if (callback && typeof callback === 'function') {
        callback(+count);
      }
      props.onClose();
    },
  },
  MakeActorCopiesBase
);

MakeActorCopiesBase.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleOnChange: PropTypes.func,
  dispatch: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  isSubmit: PropTypes.bool,
  values: PropTypes.object,
  errors: PropTypes.object,
  data: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
};

export default MakeActorCopies;
