import { useEffect, useState, useRef } from 'react';

/**
 * Хук для получения координат и зума графа
 * @param graph
 */
function useGetGraphCoord(graph) {
  const mountRef = useRef();
  const [coord, setCoord] = useState(null);

  const setCoordTooltip = () => {
    const pan = graph.pan();
    const zoom = graph.zoom();
    if (mountRef.current) setCoord({ pan, zoom });
  };

  useEffect(() => {
    mountRef.current = true;
    setCoordTooltip();
    graph.on('pan zoom', setCoordTooltip);
    return () => {
      mountRef.current = false;
    };
  }, []);

  return coord;
}

export default useGetGraphCoord;
