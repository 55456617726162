import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import unionBy from 'lodash/unionBy';
import {
  Card,
  Stack,
  Space,
  Autocomplete,
  Button,
  Chip,
  Label,
  ProgressBar,
  Popover,
  Select,
  MenuItem,
  CorezoidLightTheme as theme,
  Divider,
} from 'mw-style-react';

import { useIntl, useOutsideClick } from 'hooks';
import { GET_USERS_BULK, GET_USERS_ROLES, WORKSPACE_ROLE } from 'constants';
import AppUtils from '@control-front-end/utils/utils';

import { mapKeys } from 'lodash';
import SelectUsers from './components/SelectUsers/SelectUsers';
import SelectGroups from './components/SelectGroups';
import mes from './intl';
import scss from './SelectUsersAndGroups.scss';

/**
 * Component for users and groups selection
 */
function SelectUsersAndGroups({
  exclude,
  showExcluded = false,
  disableGroups,
  preselectedItems,
  users,
  groups,
  handleSelect,
  handleInvite,
  editMode,
  error,
  autocompleteClassName,
  onClose,
  autoFocus,
  ...props
}) {
  const dispatch = useDispatch();
  const { list: usersList } = useSelector((state) => state.users);
  const t = useIntl();
  const searchRef = useRef();
  const searchResultRef = useRef();
  const inviteRef = useRef();
  const [query, setQuery] = useState('');
  const [usersRoles, setUsersRoles] = useState({});
  const [role, setRole] = useState();
  const [selected, setSelected] = useState(preselectedItems || []);
  const [isSubmitting, setSubmitting] = useState(false);

  const handleFocus = (value) => {
    if (typeof props.handleFocus === 'function') {
      props.handleFocus(value);
    }
  };

  const handleClose = () => {
    // In edit mode selected items are always staying inside the field
    if (!editMode) {
      setSelected([]);
    }
    handleFocus(false);
    onClose();
  };

  useOutsideClick({
    ref: searchRef,
    callback: (e) => {
      const groupModalEl = document.getElementById('groupDetails');
      if (
        searchResultRef.current?.contains(e.target) ||
        groupModalEl?.contains(e.target) ||
        inviteRef.current?.contains(e.target)
      )
        return;
      handleClose();
    },
  });

  useEffect(() => {
    dispatch({
      type: GET_USERS_ROLES.REQUEST,
      callback: (data) => {
        const rolesObj = mapKeys(data, (i) => i.name.toLowerCase());
        setUsersRoles(rolesObj);
        setRole(rolesObj[WORKSPACE_ROLE.GUEST]);
      },
    });
  }, []);

  /**
   * Add all selected users to access list
   */
  const handleSubmitSelection = (onSave) => {
    const usersWithoutRole = selected.filter(
      (item) =>
        item.role === undefined &&
        item.userType !== 'group' &&
        item.userType !== 'anonymous'
    );
    // if users from suggestions were selected - get and merge roles
    if (usersWithoutRole.length) {
      setSubmitting(true);
      dispatch({
        type: GET_USERS_BULK.REQUEST,
        payload: { users: usersWithoutRole.map((i) => i.id) },
        callback: ({ users }) => {
          onSave(unionBy(users, selected, 'saId'));
          setSubmitting(false);
        },
      });
    } else {
      onSave(selected);
    }
  };

  const handleSelectItem = (item) => {
    setSelected([...selected, item]);
    if (query.length) setQuery('');
  };

  const handleDeselectItem = (item) => {
    const newSelected = selected.filter((i) => i.id !== item.id);
    setSelected(newSelected);

    /**
     * If we remove items and selector isn't expanded- we should
     * trigger change callback without pressing submit button
     */
    if (!focus) {
      handleSelect(newSelected);
    }
  };

  /**
   * Render invite button with role selections
   */
  const renderInviteBtn = (closePopover) => {
    if (!handleInvite || !AppUtils.isEmail(query) || usersList.length)
      return null;
    return (
      <Space
        size="small"
        top
        bottom
        fullWidth
        style={{ backgroundColor: theme.palette.secondary }}
      >
        <Stack.H
          forwardRef={inviteRef}
          className={scss.usersResults__invite}
          size={Stack.SIZE.small}
          alignItems="center"
        >
          <Select
            color={Select.COLOR.white}
            bordered
            unspaced
            value={role?.name}
            onChange={({ value }) => setRole(value)}
          >
            {Object.values(usersRoles).map((r) => (
              <MenuItem
                key={r.id}
                size="small"
                value={r}
                label={r.name}
                activeItem={role}
                rightIcon={r.id === role.id ? 'check' : null}
              />
            ))}
          </Select>
          <Button
            icon="add"
            size="small"
            type="text"
            fontWeight="normal"
            label={t(mes.inviteNewUser)}
            onClick={() => {
              setQuery('');
              handleFocus(false);
              handleInvite(query, role.id);
              setRole(usersRoles[WORKSPACE_ROLE.GUEST]);
              closePopover();
            }}
          />
        </Stack.H>
      </Space>
    );
  };

  const renderChips = () => {
    return selected.map((item) => {
      let inGroupAmount = '';
      if (item.userCount + item.apiCount) {
        inGroupAmount = ` ( ${item.userCount + item.apiCount})`;
      }
      return (
        <Chip
          key={item.id}
          label={item.nick || item.value || `${item.name}${inGroupAmount}`}
          color={Chip.COLOR.accent}
          borderColor={Chip.COLOR.accent}
          size="small"
          type="rectangular"
          fontWeight="normal"
          closeIcon={true}
          closeClick={() => handleDeselectItem(item)}
        />
      );
    });
  };

  const checkIsActive = (item) =>
    Boolean(selected.find((i) => item.id === i.id));

  const renderLists = (closePopover) => {
    const count = selected.length;
    return (
      <Card
        forwardRef={searchResultRef}
        className={cn(scss.usersResults, {
          [scss.iframe]: !!window.frameElement,
        })}
        fullWidth
      >
        <Stack.H
          className={scss.usersResults__wrap}
          size="none"
          fullWidth
          divider={<Divider orientation="horizontal" />}
        >
          <SelectUsers
            query={query}
            exclude={exclude}
            users={users}
            showExcluded={showExcluded}
            checkIsActive={checkIsActive}
            handleSelect={handleSelectItem}
            handleDeselect={handleDeselectItem}
          />
          {disableGroups ? null : (
            <SelectGroups
              query={query}
              exclude={exclude}
              groups={groups}
              showExcluded={showExcluded}
              checkIsActive={checkIsActive}
              handleSelect={handleSelectItem}
              handleDeselect={handleDeselectItem}
            />
          )}
        </Stack.H>
        <Stack.H
          className={scss.usersResults__actions}
          size="small"
          justifyContent="flexEnd"
          alignItems="center"
          fullWidth
        >
          <Label
            className={scss.usersResults__actions__counter}
            fontWeight="semibold"
            value={`${count} selected`}
          />
          <Button
            type="text"
            size="smallplus"
            fontWeight="normal"
            label={t(mes.cancel)}
            onClick={() => {
              handleClose();
              closePopover();
            }}
          />
          <Button
            className={scss.usersResults__actions__submitBtn}
            size="smallplus"
            fontWeight="normal"
            label={t(mes.add)}
            onClick={() =>
              handleSubmitSelection((list) => {
                handleSelect(list);
                handleClose();
                closePopover();
              })
            }
            // In edit mode it's allowed to save with empty selection
            visibility={
              (isSubmitting && 'disabled') ||
              ((editMode || count) && 'visible') ||
              'hidden'
            }
          />
        </Stack.H>
        {isSubmitting ? (
          <Stack
            className={scss.usersResults__loader}
            alignItems="center"
            justifyContent="center"
            fullWidth
            fullHeight
          >
            <ProgressBar size="large" />
          </Stack>
        ) : null}
      </Card>
    );
  };

  return (
    <Popover
      content={({ onClose }) => {
        return (
          <>
            {renderInviteBtn(onClose)}
            {renderLists(onClose)}
          </>
        );
      }}
      topLevel={!window.frameElement}
      anchors={{
        binding: Popover.ANCHOR.left_bottom,
        content: Popover.ANCHOR.left_top,
      }}
      fitToBindingElementWidth
    >
      <div ref={searchRef}>
        <Autocomplete
          className={cn(scss.searchField, autocompleteClassName)}
          leftIcon="search"
          value={query}
          placeholder={t(mes.searchUserPlaceholder)}
          bordered
          unspaced
          color={Autocomplete.COLOR.white}
          onFocus={() => handleFocus(true)}
          onChange={({ value }) => setQuery(value)}
          chipsItems={renderChips}
          searchResult={() => {}}
          error={error}
          autoFocus={autoFocus}
          visibility={(isSubmitting && 'disabled') || 'visible'}
        />
      </div>
    </Popover>
  );
}

SelectUsersAndGroups.propTypes = {
  exclude: PropTypes.array,
  disableGroups: PropTypes.bool,
  users: PropTypes.arrayOf(PropTypes.object),
  groups: PropTypes.arrayOf(PropTypes.object),
  showExcluded: PropTypes.bool,
  handleSelect: PropTypes.func,
  handleInvite: PropTypes.func,
  error: PropTypes.bool,
  autocompleteClassName: PropTypes.string,
  editMode: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

SelectUsersAndGroups.defaultProps = {
  onClose: () => {},
};

export default SelectUsersAndGroups;
