import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  searchScriptsByName: {
    id: 'searchScriptsByName',
    defaultMessage: 'Search scripts by name',
  },
});

export default { ...globalIntl, ...m };
