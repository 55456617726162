import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import useIntl from 'useIntl';
import cn from 'classnames';
import { Chip, Tooltip, Clipboard, Utils } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import { NOTIFY_LEVEL, SHOW_NOTIFY, PM_APP_NAME } from 'constants';
import mes from './intl';
import './CopyIdChip.scss';

/**
 * Chip для копирования ID сущности
 * @returns {*}
 * @constructor
 */
function CopyIdChip({ id, type, fontSize, unspaced, className }) {
  const t = useIntl();
  const dispatch = useDispatch();
  const idString = id?.toString();

  /**
   * Нотификация об успешном копировании
   */
  const notifySuccess = () => {
    const payload = {
      id: AppUtils.createRid(),
      type: NOTIFY_LEVEL.SUCCESS,
      label: t(mes[`${type}IdCopied`]) || t(mes.defaultIdCopied),
    };
    if (window.frameElement) {
      window.top.postMessage(
        {
          type: 'SHOW_NOTIFY_TOP_WINDOW',
          appName: PM_APP_NAME,
          data: { payload },
        },
        window.parent.origin
      ); // NOSONAR
    } else
      dispatch({
        type: SHOW_NOTIFY.REQUEST,
        payload,
      });
  };

  return id ? (
    <Tooltip
      value={t(mes[`copy${Utils.toUpperLatter(type)}Id`]) || t(mes.copyId)}
    >
      <Chip
        styleName={cn('idChip', { unspaced })}
        className={cn('idChip', className)}
        label={AppUtils.isUuid(idString) ? idString.split('-')[0] : idString}
        size="small"
        fontSize={fontSize}
        fontWeight="normal"
        icon="copy"
        unspaced={unspaced}
        closeIcon={false}
        type="rectangular"
        onClick={(e) => {
          e.stopPropagation();
          Clipboard.copy(idString.replace(/\s/g, '').trim());
          notifySuccess();
        }}
      />
    </Tooltip>
  ) : null;
}

CopyIdChip.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  fontSize: PropTypes.oneOf(['small', 'medium']),
  unspaced: PropTypes.bool,
  type: PropTypes.oneOf([
    'default',
    'actor',
    'edge',
    'edgeType',
    'edgeTypeId',
    'template',
    'ref',
    'account',
    'transaction',
    'transfer',
  ]),
};

export default CopyIdChip;
