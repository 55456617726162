import React, { useState, useRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Menu,
  MenuItem,
  Button,
  ButtonGroup,
  PortalWrapper,
  DateUtils,
  Calendar,
} from 'mw-style-react';
import cn from 'classnames';
import AppUtils from '@control-front-end/utils/utils';
import { DEFAULT_RANGES } from '@control-front-end/common/constants/graphActors';
import { DATE_FORMAT_7, DATE_FORMAT_6 } from 'constants';
import useIntl from 'useIntl';
import useOutsideClick from 'useOutsideClick';
import mes from './intl';
import scss from './FilterRange.scss';

/**
 * Range selection component for filters
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function FilterRange(props) {
  const {
    from,
    to,
    isDisabled = false,
    dateFormat = DATE_FORMAT_7,
    rangeOptions = DEFAULT_RANGES,
    onChange,
    fastNavigation,
    fullWidth,
    rangeValue,
    embedded = false,
  } = props;
  const t = useIntl();
  const periodRef = useRef();
  const calendarRef = useRef();
  const menuRef = useRef();
  const [menu, toggleMenu] = useState(false);
  const [calendar, toggleCalendar] = useState(false);
  const todayDate = DateUtils.unixtime();

  const closeAllPopups = () => {
    toggleMenu(false);
    toggleCalendar(false);
  };

  useOutsideClick({
    ref: periodRef,
    callback: (e) => {
      if (
        calendarRef.current?.contains(e.target) ||
        menuRef.current?.contains(e.target)
      )
        return;
      closeAllPopups();
    },
  });

  /**
   * Apply range in ms
   */
  const applyFromTo = (value, rangeValue) => {
    const newRange = { rangeValue };
    if (value.from) newRange.from = value.from * 1000;
    if (value.to) newRange.to = value.to * 1000;
    onChange(newRange);
    closeAllPopups();
  };

  /**
   * Goes forward on the difference between currently selected timestamps
   */
  const shiftRangeForward = useCallback(() => {
    onChange({
      from: to + 1000,
      to: to + (to - from) + 1000,
    });
  }, [from, to, onChange]);

  /**
   * Goes backward on the difference between currently selected timestamps
   */
  const shiftRangeBackward = useCallback(() => {
    onChange({
      from: from - (to - from) - 1000,
      to: from - 1000,
    });
  }, [from, to, onChange]);

  /**
   * Select range from list
   */
  const handleChangeRange = (value) => {
    const newDateRange = value !== 'all' ? AppUtils.getRangeDate(value) : {};
    applyFromTo(newDateRange, value);
  };

  const handleClickMenu = ({ value }) => {
    if (value === 'custom') {
      toggleCalendar(true);
    } else {
      handleChangeRange(value);
      closeAllPopups();
    }
  };

  /**
   * Handle date selection in calendar
   */
  const handleChangeDate = ({ value }) => {
    const { startDate, endDate } = value;
    applyFromTo({ from: startDate, to: endDate });
  };

  const renderCalendar = () => {
    const startDate = from ? from / 1000 : todayDate;
    const endDate = to ? to / 1000 : todayDate;
    if (!menuRef.current) return null;
    const value = from
      ? { startDate, endDate }
      : { startDate: endDate, endDate };
    return (
      <PortalWrapper
        root={embedded ? menuRef.current : document.getElementById('mainRoot')}
        node={menuRef.current}
        vAlign="top"
        hAlign="left"
        width={240}
        height={416}
        offsetX={204}
      >
        <div ref={calendarRef}>
          <Calendar
            styleName="range__calendar"
            size="small"
            dateRange={true}
            value={value}
            maxDate={DateUtils.unixtime()}
            time={true}
            defaultStartTime={{ hours: 0, min: 0, sec: 0 }}
            defaultEndTime={{ hours: 23, min: 59, sec: 59 }}
            onChange={handleChangeDate}
          />
        </div>
      </PortalWrapper>
    );
  };

  const renderMenu = () => (
    <div ref={menuRef} styleName="range__menu">
      <Menu width={200} onClick={handleClickMenu}>
        {rangeOptions.map((item) => (
          <MenuItem
            styleName={cn('range__menu__item', {
              active: item === 'custom' && calendar,
            })}
            key={item}
            id={item}
            value={item}
            label={t(mes[`range_${item}`])}
            rightIcon={item === 'custom' ? 'arrow' : null}
          />
        ))}
      </Menu>
    </div>
  );

  const getFormatedRangeLabel = () => {
    const rangeLabelFormat = `${DATE_FORMAT_6} ${dateFormat}`;
    const labelFrom = DateUtils.toDate(from / 1000, rangeLabelFormat);
    const labelTo = DateUtils.toDate(to / 1000, rangeLabelFormat);
    return `${labelFrom} - ${labelTo}`;
  };

  const label = useMemo(() => getFormatedRangeLabel(), [from, to, rangeValue]);

  return (
    <div
      ref={periodRef}
      className={cn({ [scss.fullWidth]: fullWidth }, scss.range)}
    >
      <ButtonGroup
        className={cn({ [scss.fullWidth]: fullWidth })}
        type="quaternary"
      >
        {fastNavigation ? (
          <Button
            size="small"
            icon="arrow"
            styleName="range__btn"
            onClick={shiftRangeBackward}
          />
        ) : null}
        <Button
          icon="calendar"
          label={from && to ? label : t(mes.range_allTime)}
          onClick={isDisabled ? undefined : () => toggleMenu(true)}
          visibility={isDisabled ? 'disabled' : 'visible'}
          size="smallplus"
          styleName="range__mainBtn"
        />
        {fastNavigation ? (
          <Button
            size="small"
            icon="arrow_reverse"
            styleName="range__btn"
            onClick={shiftRangeForward}
          />
        ) : null}
      </ButtonGroup>
      {menu ? renderMenu() : null}
      {calendar ? renderCalendar() : null}
    </div>
  );
}

FilterRange.propTypes = {
  fullWidth: PropTypes.bool,
  from: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rangeValue: PropTypes.string,
  rangeOptions: PropTypes.array,
  isDisabled: PropTypes.bool,
  fastNavigation: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default FilterRange;
