import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Avatar, Badge } from 'mw-style-react';
import cn from 'classnames';
import ActorColors from '@control-front-end/app/src/components/ActorColors/ActorColors';
import ActorStatusIcon from './components/ActorStatusIcon';
import './ActorAvatar.scss';

const SYSTEM_FORMS_ICONS = {
  AccountTriggers: 'trigger',
  ActorFilters: 'filter',
  TransactionFilters: 'filter',
  TransferFilters: 'filter',
  CommentsWidgets: 'form',
  CompanyCard: 'company_office',
  CompanyCategories: 'checklist',
  Dashboards: 'dashboard',
  Events: 'event',
  Graphs: 'graph',
  Layers: 'layer_filled',
  Locations: 'location',
  PublishedCompanies: 'company',
  Scripts: 'form_pole',
  Snippets: 'snippet',
  Streams: 'stream',
  Tags: 'hash',
  Users: 'client',
  Widgets: 'widget',
  Transfers: 'transaction',
};

/**
 * Аватар актора со статусами
 * @returns {*}
 * @constructor
 */
function ActorAvatar(props) {
  const {
    size = 'medium',
    type = 'full',
    formType,
    formTitle,
    icon = formType === 'system' ? SYSTEM_FORMS_ICONS[formTitle] : null,
    iconLabel,
    pictureUrl,
    colorFilled,
    color,
    colors = [],
    accessDenied,
    starred,
    status,
  } = props;
  const actorColor = colors.find((i) => i.type === 'actor') || {};
  const formColor = colors.find((i) => i.type === 'form') || {};
  const mainColor = color || actorColor.color || formColor.color;
  const WHITE_COLOR = '#ffffff';
  const GRAY_COLOR = '#DDE0E5';
  const bgColor = mainColor || WHITE_COLOR;
  const borderColor = bgColor === WHITE_COLOR ? GRAY_COLOR : mainColor;
  let iconSize = size;
  if (size === 'micro') iconSize = 'small';
  else if (size === 'xxlarge') iconSize = 'xlarge';

  return (
    <div
      styleName={cn('aa__wrap', size)}
      style={colorFilled ? { border: `1px solid ${borderColor}` } : {}}
    >
      {accessDenied ? (
        <Avatar styleName="aa accessDenied" size={size}>
          <Icon type="no_eye" />
        </Avatar>
      ) : (
        <Avatar
          styleName="aa default"
          size={size}
          className="avatar"
          src={pictureUrl}
          bgColor={colorFilled ? bgColor : null}
        >
          {!pictureUrl && icon ? (
            <div styleName={cn('aa__iconWrap', { iconLabel: !!iconLabel })}>
              <Icon
                styleName={cn('aa__icon', { upload: icon === 'picture' })}
                size={iconSize}
                type={icon}
              />
              {iconLabel ? <span>{iconLabel}</span> : null}
            </div>
          ) : null}
        </Avatar>
      )}
      <ActorStatusIcon size={iconSize} status={status} />
      {type === 'full' ? (
        <>
          <div styleName="aa__colors">
            <ActorColors colors={colors} />
          </div>
          {starred ? (
            <Badge
              styleName="aa__flag"
              bgColor="#E63C3C"
              borderColor="#ffffff"
            />
          ) : null}
        </>
      ) : null}
    </div>
  );
}

ActorAvatar.propTypes = {
  size: PropTypes.oneOf([
    'micro',
    'small',
    'medium',
    'large',
    'xlarge',
    'xxlarge',
  ]),
  type: PropTypes.oneOf(['compact', 'full']),
  formType: PropTypes.string,
  formTitle: PropTypes.string,
  icon: PropTypes.string,
  pictureUrl: PropTypes.string,
  color: PropTypes.string,
  colors: PropTypes.array,
  colorFilled: PropTypes.bool,
  accessDenied: PropTypes.bool,
  starred: PropTypes.bool,
  status: PropTypes.oneOf(['verified', 'rejected', 'pending', null]),
};

export default ActorAvatar;
