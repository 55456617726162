import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  welcomeToCE: {
    id: 'welcomeToCE',
    defaultMessage: 'Welcome to Control.Events',
  },
  welcomeText: {
    id: 'welcomeText',
    defaultMessage:
      'You have been registered new workspace and now you can invite your members!',
  },
  removeChannel: {
    id: 'removeChannel',
    defaultMessage: 'Delete channel:',
  },
  removeChannelInfo: {
    id: 'removeChannelInfo',
    defaultMessage:
      'All data that was imported from this channel will be permanently deleted without restore. Are you sure?',
  },
  removeAccount: {
    id: 'removeAccount',
    defaultMessage: 'Delete workspace:',
  },
  removeAccountInfo: {
    id: 'removeAccountInfo',
    defaultMessage:
      'All data from this workspace will be permanently deleted without restore. Are you sure?',
  },
  expiredSession: {
    id: 'expiredSession',
    defaultMessage: 'Session expiration',
  },
  expiredSessionInfo: {
    id: 'expiredSessionInfo',
    defaultMessage: 'Your session has been expired. Please restart session.',
  },
  newVersion: {
    id: 'newVersion',
    defaultMessage: 'New Simulator version',
  },
  newVersionInfo: {
    id: 'newVersionInfo',
    defaultMessage:
      'New version {tag} of the Simulator.Company has been released!!! 🎉🎉🎉',
  },
  removeActors: {
    id: 'removeActors',
    defaultMessage: 'Delete actors',
  },
  cantDeleteActors: {
    id: 'cantDeleteActors',
    defaultMessage: "You can't delete these items",
  },
  noPermissions: {
    id: 'noPermissions',
    defaultMessage: 'You have no permissions',
  },
  removeActorsTitle: {
    id: 'removeActorsTitle',
    defaultMessage: 'Delete actor',
  },
  removeActorsLinks: {
    id: 'removeActorsLinks',
    defaultMessage: 'Delete selected actors and links',
  },
  removeSelectedItems: {
    id: 'removeSelectedItems',
    defaultMessage: 'Delete selected items',
  },
  removeTransactionAccount: {
    id: 'removeTransactionAccount',
    defaultMessage: 'Delete selected account',
  },
  removeCurrency: {
    id: 'removeCurrency',
    defaultMessage: 'Delete selected currency',
  },
  removeLayer: {
    id: 'removeLayer',
    defaultMessage: 'Delete layer',
  },
  removeLayers: {
    id: 'removeLayers',
    defaultMessage: 'Delete selected layers',
  },
  removeActorsGroup: {
    id: 'removeActorsGroup',
    defaultMessage: 'Delete group of actors',
  },
  removeLayerConfirm: {
    id: 'removeLayerConfirm',
    defaultMessage:
      "Are you sure you want to delete layer? You can't undo this action.",
  },
  removeExportTask: {
    id: 'removeExportTask',
    defaultMessage: 'Delete export items',
  },
  removeExportTaskConfirm: {
    id: 'removeExportTaskConfirm',
    defaultMessage:
      'Are you sure you want to delete export task for {count} items? Action cannot be undone',
  },
  removeImportTask: {
    id: 'removeImportTask',
    defaultMessage: 'Delete import items',
  },
  removeExportImportTaskConfirm: {
    id: 'removeExportImportTaskConfirm',
    defaultMessage:
      'Are you sure you want to delete {activeTab} task for {count} items? Action cannot be undone',
  },
  removeActorsConfirm: {
    id: 'removeActorsConfirm',
    defaultMessage:
      "Are you sure you want to delete {count} selected items? You can't undo this action.",
  },
  removeActorConfirm: {
    id: 'removeActorConfirm',
    defaultMessage:
      "Are you sure you want to delete selected item? You can't undo this action.",
  },
  removeFormFieldConfirm: {
    id: 'removeFormFieldConfirm',
    defaultMessage: `Are you sure you want to delete selected item? You can't undo this action.
    The account will be deleted from the form settings,
    meaning that it will be removed not only from the current actor but also from all other actors where it is added.`,
  },
  removeTransaction: {
    id: 'removeTransaction',
    defaultMessage: 'Delete transaction',
  },
  removeTransactionConfirm: {
    id: 'removeTransactionConfirm',
    defaultMessage:
      "Are you sure you want to delete transaction {title}? You can't undo this action.",
  },
  scriptDetails: {
    id: 'scriptDetails',
    defaultMessage: 'Script details',
  },
  removeScriptActor: {
    id: 'removeScriptActor',
    defaultMessage: 'Delete script',
  },
  removeGraph: {
    id: 'removeGraph',
    defaultMessage: 'Delete graph',
  },
  removeGraphInfo: {
    id: 'removeGraphInfo',
    defaultMessage: "You can't undo this action. Delete graph?",
  },
  removeLayerFromGraph: {
    id: 'removeLayerFromGraph',
    defaultMessage: 'Delete layer from graph',
  },
  removeLayerFromGraphInfo: {
    id: 'removeLayerFromGraphInfo',
    defaultMessage:
      'This layer will be removed only from this graph. Delete layer?',
  },
  deployScript: {
    id: 'deployScript',
    defaultMessage: 'Deploy changes',
  },
  deployScriptInfo: {
    id: 'deployScriptInfo',
    defaultMessage:
      'Are you sure you want to deploy all changes to Production? This action is irreversible. ',
  },
  removeTemplate: {
    id: 'removeTemplate',
    defaultMessage: 'Delete template',
  },
  removeTemplateConfirm: {
    id: 'removeTemplateConfirm',
    defaultMessage:
      "Are you sure you want to delete template? You can't undo this action.",
  },
  removeScript: {
    id: 'removeScript',
    defaultMessage: 'Delete script',
  },
  removeScriptInfo: {
    id: 'removeScriptInfo',
    defaultMessage: "You can't undo this action. Delete script?",
  },
  delOtherItems: {
    id: 'delOtherItems',
    defaultMessage: 'Delete without these items',
  },
  delFromLayer: {
    id: 'delFromLayer',
    defaultMessage: 'Delete from the layer',
  },
  actorNotAvailable: {
    id: 'actorNotAvailable',
    defaultMessage: 'Actor is no longer available',
  },
  transactionNotAvailable: {
    id: 'transactionNotAvailable',
    defaultMessage: 'Transaction is no longer available',
  },
  transactionNotAvailableInfo: {
    id: 'transactionNotAvailableInfo',
    defaultMessage: 'Transaction has been removed.',
  },
  removeFormFromActor: {
    id: 'removeFormFromActor',
    defaultMessage: 'Remove form from actor',
  },
  anotherFormConfirm: {
    id: 'anotherFormConfirm',
    defaultMessage: 'Are you sure you want to go to form?',
  },
  moveToForm: {
    id: 'moveToForm',
    defaultMessage: 'Move to form',
  },
  createUAT: {
    id: 'createUAT',
    defaultMessage: 'Creating UAT form',
  },
  createUATConfirm: {
    id: 'createUATConfirm',
    defaultMessage:
      // eslint-disable-next-line max-len
      'You have not selected a parent form and are creating a root UAT form. Are you sure you want to create a new UAT form?',
  },
  changeSystemActor: {
    id: 'changeSystemActor',
    defaultMessage: 'Change system actor',
  },
  changingSystemActor: {
    id: 'changingSystemActor',
    defaultMessage:
      // eslint-disable-next-line max-len
      'After saving the changes, this actor will become a new system actor for the selected user. The current actor will no longer be a system.',
  },
  saveTagsConfirm: {
    id: 'saveTagsConfirm',
    defaultMessage:
      'Are you sure you want to to save changes? All previous tags will be overwritten by the new ones.',
  },
  removedAccess: {
    id: 'removedAccess',
    defaultMessage: 'Someone just took away your access.',
  },
  accessRules: {
    id: 'accessRules',
    defaultMessage: 'Actor access',
  },
  removeFile: {
    id: 'removeFile',
    defaultMessage: 'Delete selected file',
  },
  removeFileConfirm: {
    id: 'removeFileConfirm',
    defaultMessage:
      "Are you sure you want to delete {type} '{title}'? You can't undo this action.",
  },
});

export default { ...globalIntl, ...m };
