import { createReducer } from '@reduxjs/toolkit';
import { SET_STARRED_SCRIPTS } from 'constants';

// ------------------------------------
// Reducer
// ------------------------------------

/**
 * The starredScriptsReducer manages the state of starred scripts for the user.
 * It handles an array of script objects, each representing a script that the user has starred.
 */

const initialState = { total: 0, list: [] };

export default createReducer(initialState, {
  [SET_STARRED_SCRIPTS](state, action) {
    return { ...action.payload };
  },
});
