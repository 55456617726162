import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  accessToSelected: {
    id: 'accessToSelected',
    defaultMessage: 'Access to all selected items ({count}) will be provided',
  },
});

export default { ...globalIntl, ...m };
