import {
  call,
  put,
  takeEvery,
  takeLatest,
  select,
  all,
} from 'redux-saga/effects';
import api from '@control-front-end/common/sagas/api';
import AppUtils from '@control-front-end/utils/utils';
import {
  SHOW_NOTIFY,
  NOTIFY_LEVEL,
  RequestStatus,
  ACCESS_RULES_ACTIONS,
} from 'constants';
import {
  GET_SCRIPT,
  GET_SCRIPTS,
  GET_SCRIPT_ENVS,
  CREATE_SCRIPT,
  UPDATE_SCRIPT_ENV,
  DEPLOY_SCRIPT,
  SET_STARRED_ACTOR,
  GET_STARRED_ACTORS,
  UPDATE_SCRIPT_BAR_SETTINGS,
} from '../constants/scripts';
import { CREATE_ACTOR_LIST } from '../constants/graphActors';
import { manageAccessRules } from './accessRules';

/**
 * Create a script model
 */
function* makeScriptModel(models) {
  const config = yield select((state) => state.config);
  models = Array.isArray(models) ? models : [models];
  for (const model of models) {
    if (model.picture) {
      model.pictureUrl = AppUtils.makeAppUrl(`/download/${model.picture}`);
    }
    model.ownerAvatar = AppUtils.makeUserAvatar(model, config);
    if (!model.access) {
      model.access = [];
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const a of model.access) {
      a.avatar = AppUtils.makeUserAvatar(a, config);
    }
  }
}

/**
 * Manage access settings for a script
 */
function* manageAccess(script, access = []) {
  const rules = yield manageAccessRules({
    payload: {
      body: access,
      objType: 'actor',
      objId: script.id,
    },
  });
  if (!rules || !rules.length) return script.access || rules;
}

/**
 * Get a script by ID
 */
function* getScript({ payload: scriptId, callback, errorCallback }) {
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/applications/${scriptId}`,
    handleErrCodes: [400, 403],
  });
  if (result === RequestStatus.SUCCESS) {
    yield call(makeScriptModel, data.data);
    yield put({
      type: GET_SCRIPT.SUCCESS,
      payload: {
        list: [data.data],
      },
    });
    if (callback) callback(data.data);
  } else if (errorCallback) {
    errorCallback(data);
  }
}

/**
 * Set starred actors for a script
 */
function* setStarredActor({ payload, callback }) {
  const { users = [], actorId } = payload;
  const { result } = yield call(api, {
    method: 'post',
    url: `/actors/set_starred/${actorId}`,
    body: users,
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback();
}

/**
 * Update custom bar settings for a script
 */
function* updateScriptBarSettings({ payload }) {
  const { id, access, customBarUsers } = payload;
  // Check if customBarSettingsUsers is empty
  if (!customBarUsers || customBarUsers.size === 0) {
    return;
  }

  // Filter out users who have the action 'create' and are not already in the access list
  const newAccessUsers = Array.from(customBarUsers.values()).filter(
    (user) =>
      user.action === ACCESS_RULES_ACTIONS.create &&
      !access.some((accessUser) => accessUser.userId === user.userId)
  );

  // Manage access rules if there are users to be processed
  if (newAccessUsers.length > 0) {
    yield manageAccessRules({
      payload: {
        body: newAccessUsers,
        objType: 'actor',
        objId: id,
      },
    });
  }

  const usersForStarred = Array.from(customBarUsers.values()).filter(
    ({ action }) =>
      action === ACCESS_RULES_ACTIONS.create ||
      action === ACCESS_RULES_ACTIONS.delete
  );

  // Update starred actors if there are users to be processed
  if (usersForStarred.length > 0) {
    yield setStarredActor({
      payload: {
        actorId: id,
        users: usersForStarred.map(({ userId, starred = true, action }) => ({
          userId,
          starred: action === ACCESS_RULES_ACTIONS.delete ? false : starred,
        })),
      },
    });
  }
}

/**
 * Get a list of scripts
 */
function* getScripts({ payload = {}, callback, errorCallback }) {
  const { offset = 0, search = '', limit = 10 } = payload;
  const systemForms = yield select((state) => state.systemForms);
  const scriptsFormId = systemForms.scripts.id;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/actors_filters/${scriptsFormId}?starred=true`,
    queryParams: { offset, limit, search },
  });
  if (result === RequestStatus.SUCCESS) {
    yield call(makeScriptModel, data.data.list);
    if (callback) callback(data.data);
  } else if (errorCallback) {
    errorCallback(data);
  }
}

/**
 * Get starred actors for a script
 */
function* getStarredActors({ payload, callback }) {
  const { actorId } = payload;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/actors/starred/${actorId}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback(data?.data || []);
}

/**
 * Create a new script
 */
function* createScript({ payload, formActions, callback }) {
  const {
    title,
    ref,
    picture,
    description,
    sharedWith,
    corezoidCredentials,
    access,
    customBarUsers,
  } = payload;
  const accounts = yield select((state) => state.accounts);
  const accId = accounts.active;
  const { list } = yield select((state) => state.actorsList);
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/applications/${accId}`,
    body: {
      title,
      ref,
      picture,
      description,
      sharedWith,
      corezoidCredentials,
    },
  });
  if (result !== RequestStatus.SUCCESS) {
    if (formActions) formActions.setSubmitting(false);
    return;
  }
  const newScript = { ...data.data };
  yield call(makeScriptModel, newScript);
  // Add access rules to the script
  const newAccess = yield call(manageAccess, newScript, access);
  const newList = list.slice();
  if (newAccess) newScript.access = newAccess;
  newList.unshift(newScript);
  yield put({
    type: CREATE_ACTOR_LIST.SUCCESS,
    payload: { list: newList },
  });
  if (formActions && typeof formActions.callback === 'function') {
    formActions.callback({ id: newScript.id });
  }
  if (customBarUsers.length > 0) {
    yield setStarredActor({
      payload: {
        actorId: newScript.id,
        users: customBarUsers.map(({ userId, starred = true }) => ({
          userId,
          starred,
        })),
      },
    });
  }
  if (callback) callback(data.data);
}

/**
 * Update a single environment of a script
 */
function* updateScriptEnv({ payload }) {
  const { scriptId, envId, credentials } = payload;
  const { result, data } = yield call(api, {
    method: 'put',
    url: `/applications/env/${scriptId}/${envId}`,
    body: credentials,
  });
  if (result !== RequestStatus.SUCCESS) return;
  return data.data;
}

/**
 * Update environments of a script
 */
function* updateScriptEnvs({ payload, formActions, callback }) {
  const reqs = payload.envs.map((env) =>
    call(updateScriptEnv, { payload: env })
  );
  const resp = yield all(reqs);
  if (formActions) formActions.onClose();
  if (callback) callback(resp);
}

/**
 * Deploy a script
 */
function* deployScript({ payload, callback }) {
  const { scriptId, sourceEnvId, targetEnvId } = payload;
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/applications/deploy/${scriptId}`,
    body: { sourceEnvId, targetEnvId },
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({
    type: SHOW_NOTIFY.REQUEST,
    payload: {
      id: AppUtils.createRid(),
      type: NOTIFY_LEVEL.SUCCESS,
      label: 'Deployment completed successfully.',
    },
  });
  if (callback) callback(data);
}

/**
 * Get environments of a script
 */
function* getScriptEnvs({ payload, callback }) {
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/applications/envs/${payload}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({
    type: GET_SCRIPT_ENVS.SUCCESS,
    payload: data.data,
  });
  if (callback) callback(data.data);
}

function* scripts() {
  yield takeEvery(GET_SCRIPT.REQUEST, getScript);
  yield takeEvery(GET_SCRIPTS.REQUEST, getScripts);
  yield takeEvery(GET_SCRIPT_ENVS.REQUEST, getScriptEnvs);
  yield takeEvery(DEPLOY_SCRIPT.REQUEST, deployScript);
  yield takeLatest(CREATE_SCRIPT.REQUEST, createScript);
  yield takeLatest(UPDATE_SCRIPT_ENV.REQUEST, updateScriptEnvs);
  yield takeEvery(SET_STARRED_ACTOR.REQUEST, setStarredActor);
  yield takeEvery(GET_STARRED_ACTORS.REQUEST, getStarredActors);
  yield takeEvery(UPDATE_SCRIPT_BAR_SETTINGS.REQUEST, updateScriptBarSettings);
}

export default scripts;
