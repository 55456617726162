import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Stack, Icon, ColorPicker, cr } from 'mw-style-react';
import scss from './FormOptionColor.scss';

function FormOptionColor({ value, onChange }) {
  const [picker, togglePicker] = useState(false);

  const handleClickPicker = () => {
    togglePicker(!picker);
  };

  return (
    <Stack.H
      className={scss.colorOption}
      size={Stack.SIZE.xsmall}
      alignItems="center"
    >
      <div
        className={scss.sample}
        style={{ backgroundColor: value, borderColor: value }}
        onClick={handleClickPicker}
      />
      <Icon type="dropdown" size="micro" onClick={handleClickPicker} />
      {cr([
        picker,
        <ColorPicker
          className={scss.picker}
          value={value}
          onChange={onChange}
          onClose={handleClickPicker}
          disableAlpha={true}
        />,
      ])}
    </Stack.H>
  );
}

FormOptionColor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default FormOptionColor;
