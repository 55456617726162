import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  GET_GRAPH_FOLDER,
  GET_GRAPH_FOLDERS,
  UPDATE_GRAPH_FOLDER,
  MANAGE_GRAPH_FOLDER,
  GRAPH_FOLDER_ACCESS,
  GET_GRAPH_FOLDER_LAYERS,
} from '@control-front-end/common/constants/graphFolders';
import AppUtils from '@control-front-end/utils/utils';
import { RequestStatus } from 'constants';
import api from '@control-front-end/common/sagas/api';
import { updateAutoLayerEls } from './graphHelpers';
import { getActor, getLinkedActors } from './graphNodes';

/**
 * Создать модель графа
 */
export function* makeFolderModel(models) {
  const config = yield select((state) => state.config);
  const auth = yield select((state) => state.auth);
  models = Array.isArray(models) ? models : [models];
  for (const model of models) {
    model.ownerAvatar = AppUtils.makeUserAvatar(model, config);
    if (!model.access) {
      model.access = [AppUtils.makeAuthUserAsOwner(auth)];
    }
    for (const a of model.access) {
      a.avatar = AppUtils.makeUserAvatar(a, config);
    }
    model.pictureUrl = model.picture
      ? AppUtils.makeAppUrl(`/download/${model.picture}`)
      : null;
  }
}

/**
 * Изменить права доступа на граф
 */
function* manageGraphFolderAccess({ payload }) {
  const { graphFolderId, access } = payload;
  const { list } = yield select((state) => state.graphFolders);
  const newList = list.slice();
  const index = newList.findIndex((i) => i.id === graphFolderId);
  newList.splice(index, 1, { ...list[index], access });
  yield put({
    type: GRAPH_FOLDER_ACCESS.SUCCESS,
    payload: { list: newList },
  });
  yield updateAutoLayerEls({
    layerId: 'graphs',
    id: graphFolderId,
    status: 'updated',
    data: { access },
  });
}

/**
 * Обновить граф
 */
export function* updateGraphFolder({ graphFolderId, data }) {
  const { list } = yield select((state) => state.graphFolders);
  const copyList = structuredClone(list);
  const index = copyList.findIndex((i) => i.id === graphFolderId);
  if (index === -1) return;
  const updatedFolder = { ...copyList[index], ...data };
  yield call(makeFolderModel, updatedFolder);
  copyList.splice(index, 1, updatedFolder);
  yield put({
    type: UPDATE_GRAPH_FOLDER.SUCCESS,
    payload: { list: copyList },
  });
  yield updateAutoLayerEls({
    layerId: 'graphs',
    id: graphFolderId,
    status: 'updated',
    data: { title: name, name },
  });
}

/**
 * Добавить/удалить слои с графа
 */
export function* manageGraphFolder({ payload, callback, errorCallback }) {
  const { action, graphFolderId, layerId } = payload;
  const accounts = yield select((state) => state.accounts);
  const accId = accounts.active;
  const edgeTypes = yield select((state) => state.edgeTypes);
  const edgeTypeH = edgeTypes.find((i) => i.name === 'hierarchy');
  const body = {
    source: graphFolderId,
    target: layerId,
    edgeTypeId: edgeTypeH.id,
  };
  const { result, data } = yield call(api, {
    method: action === 'create' ? 'post' : 'delete',
    url:
      action === 'create'
        ? `/actors_graph/link/${accId}`
        : '/actors_graph/actors_link',
    body,
    handleErrCodes: errorCallback ? [404] : [],
  });
  if (
    result !== RequestStatus.SUCCESS &&
    (action === 'create' || data.statusCode !== 404)
  )
    return;
  const { list: graphFoldersList } = yield select(
    (state) => state.graphFolders
  );
  const list = structuredClone(graphFoldersList);
  const graphFolder = list.find((i) => i.id === graphFolderId);
  if (graphFolder) {
    graphFolder.total =
      action === 'create' ? graphFolder.total + 1 : graphFolder.total - 1;
    yield put({ type: MANAGE_GRAPH_FOLDER.SUCCESS, payload: { list } });
  }
  if (callback) callback();
}

/**
 * Получить актор-граф
 */
export function* getGraphFolder({
  payload: graphFolderId,
  callback,
  errorCallback,
}) {
  const graphFolder = yield getActor({
    payload: { id: graphFolderId },
    errorCallback,
  });
  yield put({
    type: GET_GRAPH_FOLDERS.SUCCESS,
    payload: { list: [graphFolder] },
  });
  if (callback) callback(graphFolder);
  return graphFolder;
}

/**
 * Получить список доступных слоев графа
 */
export function* getGraphFolderLayers({ payload, callback }) {
  const {
    graphFolderId,
    list,
    limit = 10,
    offset = 0,
    starred,
    loadMore,
  } = payload;
  const systemForms = yield select((state) => state.systemForms);
  const layersForm = systemForms.layers || {};
  const formId = layersForm.id;
  const { list: graphLayers, total } = yield getLinkedActors({
    payload: {
      id: graphFolderId,
      formId,
      starred,
      limit,
      offset,
    },
  });
  if (callback) {
    const newList = loadMore ? list.concat(graphLayers) : graphLayers;
    callback({
      list: newList,
      offset: offset + limit,
      endList: !graphLayers.length,
      total,
    });
  }
  return graphLayers.map((layer) => ({ ...layer, graphFolderId }));
}

function* graphFolders() {
  yield takeEvery(GET_GRAPH_FOLDER.REQUEST, getGraphFolder);
  yield takeEvery(MANAGE_GRAPH_FOLDER.REQUEST, manageGraphFolder);
  yield takeLatest(GRAPH_FOLDER_ACCESS.REQUEST, manageGraphFolderAccess);
  yield takeLatest(GET_GRAPH_FOLDER_LAYERS.REQUEST, getGraphFolderLayers);
}

export default graphFolders;
