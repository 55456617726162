import { Utils } from 'mw-style-react';

export const COMMUNICATIONS_PUBLISH = Utils.createRequestTypes(
  'COMMUNICATIONS_PUBLISH'
);
export const COMMUNICATIONS_CONNECT = Utils.createRequestTypes(
  'COMMUNICATIONS_CONNECT'
);
export const COMMUNICATIONS_ACTORS = Utils.createRequestTypes(
  'COMMUNICATIONS_ACTORS'
);
export const COMMUNICATIONS_CONNECTED = Utils.createRequestTypes(
  'COMMUNICATIONS_CONNECTED'
);
