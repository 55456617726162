import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Button,
  Stack,
  Space,
  Label,
  TextField,
  MenuItem,
  Select,
} from 'mw-style-react';

import { useIntl } from 'hooks';
import {
  GET_USERS_ROLES,
  SEND_INVITE,
  NOTIFY_LEVEL,
  SHOW_NOTIFY,
} from 'constants';
import AppUtils from '@control-front-end/utils/utils';

import m from './intl';

function ItemWithTitle({ children, title }) {
  return (
    <Stack size="xsmall">
      <Label color={Label.COLOR.black} fontWeight="semibold" value={title} />
      {children}
    </Stack>
  );
}

function InviteMembers({ onSubmit }) {
  const [role, setRole] = useState();
  const [userLogin, setUserLogin] = useState();
  const [usersRoles, setUsersRoles] = useState([]);

  const t = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: GET_USERS_ROLES.REQUEST,
      callback: setUsersRoles,
    });
  }, []);

  const handleSubmit = useCallback(() => {
    if (!userLogin || !role) return null;
    dispatch({
      type: SEND_INVITE.REQUEST,
      payload: {
        invitations: [
          {
            login: userLogin,
            roleId: role.id,
          },
        ],
      },
      callback: () => {
        dispatch({
          type: SHOW_NOTIFY.REQUEST,
          payload: {
            id: AppUtils.createRid(),
            type: NOTIFY_LEVEL.SUCCESS,
            label: t(m.successNotifText, { userLogin }),
          },
        });
        onSubmit();
      },
    });
  }, [role, userLogin]);

  const handleUserLoginChange = useCallback(
    ({ value }) => setUserLogin(value),
    []
  );

  const handleRoleChange = useCallback(({ value }) => setRole(value), []);

  return (
    <Space size="large">
      <Stack>
        <Label value={t(m.initialPhrase)} />
        <ItemWithTitle title={t(m.selectUsers)}>
          <TextField
            bordered
            unspaced
            color={TextField.COLOR.white}
            placeholder={t(m.enterText)}
            value={userLogin}
            onChange={handleUserLoginChange}
          />
        </ItemWithTitle>
        <ItemWithTitle title={t(m.role)}>
          <Select
            size="medium"
            color={Select.COLOR.white}
            bordered
            unspaced
            popoverOnTop
            placeholder={t(m.chooseRole)}
            value={role?.name}
            onChange={handleRoleChange}
          >
            {usersRoles.map((role) => (
              <MenuItem key={role.id} label={role.name} value={role} />
            ))}
          </Select>
        </ItemWithTitle>
        <Button
          visibility={userLogin && role ? 'visible' : 'disabled'}
          onClick={handleSubmit}
          label={t(m.submitText)}
        />
      </Stack>
    </Space>
  );
}

InviteMembers.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default InviteMembers;
