import { useEffect } from 'react';

/**
 * Custom hook useOnlineReconnect triggers a callback function when the internet connection is restored.
 * @param callback A callback function to be executed upon internet reconnection.
 * @param reconnectionDelay Optional delay (ms) before executing the callback for stability (default: 2000ms).
 *                           If null, the callback is executed immediately.
 */

const DEFAULT_RECONNECTION_DELAY_MS = 3000;

const useOnlineReconnect = (
  callback,
  reconnectionDelay = DEFAULT_RECONNECTION_DELAY_MS
) => {
  useEffect(() => {
    const handleOnline = () => {
      if (typeof callback !== 'function') return;

      if (reconnectionDelay === null) {
        // Execute callback immediately if reconnectionDelay is explicitly null
        callback();
      } else {
        // Otherwise, use setTimeout to delay the execution
        setTimeout(callback, reconnectionDelay);
      }
    };

    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('online', handleOnline);
    };
  }, [callback, reconnectionDelay]);
};

export default useOnlineReconnect;
