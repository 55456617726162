import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, useActorFields } from 'hooks';
import keyBy from 'lodash/keyBy';
import flatten from 'lodash/flatten';
import pickBy from 'lodash/pickBy';
import {
  Button,
  Stack,
  Label,
  Icon,
  Card,
  Space,
  Select,
} from 'mw-style-react';
import SelectActors from '@control-front-end/common/components/SelectActors';
import { NEW_TRANSFER } from '@control-front-end/common/constants/actorAccounts';
import FormFieldSelector from '@control-front-end/app/src/components/FormFieldSelector';
import { TRANSFERABLE_FIELD_TYPE } from '@control-front-end/common/constants/forms';
import FieldValue from './FieldValue';
import mes from './intl';
import scss from './CreateTransfer.scss';

const checkSourceType = (field, sourceType) => {
  if (!sourceType) {
    return !field?.extra?.optionsSource?.type;
  }
  return field?.extra?.optionsSource?.type === sourceType;
};

function TransferTargetItem({
  index,
  valuesOptions,
  fieldSourceType,
  sourceActor,
  initialFieldId,
  ...rest
}) {
  const t = useIntl();

  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);

  const targetsState = useSelector((state) => state.newFormFieldTransfer.to);

  const targetItemState = targetsState[index];

  const actorFields = useActorFields(targetItemState.actor?.id);

  const transferActorFields = useMemo(() => {
    return pickBy(
      actorFields,
      (item) =>
        TRANSFERABLE_FIELD_TYPE[item.class] &&
        checkSourceType(item, fieldSourceType)
    );
  }, [actorFields, fieldSourceType]);

  useEffect(() => {
    if (transferActorFields[initialFieldId] && !initialized) {
      dispatch({
        type: NEW_TRANSFER.FORM_FIELD.TARGET.SET_ACCOUNT,
        payload: { index, value: transferActorFields[initialFieldId] },
      });
      setInitialized(true);
    }
  }, [initialFieldId, transferActorFields, initialized]);

  return (
    <Card {...rest}>
      <Space>
        <Stack.V fullWidth style={{ position: 'relative' }}>
          <div style={{ flex: 1 }}>
            <SelectActors
              size="large"
              type="modal"
              bordered
              unspaced
              resettable
              onReset={() => {
                dispatch({
                  type: NEW_TRANSFER.FORM_FIELD.TARGET.RESET,
                  payload: { index },
                });
              }}
              multiselect={false}
              placeholder={t(mes.selectActor)}
              label={
                <Select.Label value={t(mes.actor)} color={Label.COLOR.gray} />
              }
              value={targetItemState.actor}
              exclude={sourceActor ? [sourceActor] : []}
              manageLayer={false}
              hideCreateBtn={true}
              fullModel={true}
              onChange={({ value }) => {
                dispatch({
                  type: NEW_TRANSFER.FORM_FIELD.TARGET.SET_ACTOR,
                  payload: { index, value },
                });
              }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <FormFieldSelector
              onChange={(value) => {
                dispatch({
                  type: NEW_TRANSFER.FORM_FIELD.TARGET.SET_ACCOUNT,
                  payload: { index, value },
                });
              }}
              visibility={targetItemState.actor?.id ? 'visible' : 'disabled'}
              fields={transferActorFields}
              value={targetItemState.field}
              label={
                <Select.Label value={t(mes.account)} color={Label.COLOR.gray} />
              }
            />
          </div>
          <div style={{ flex: 1 }}>
            <FieldValue
              fieldClass={targetItemState.field?.class}
              value={targetItemState.value}
              options={valuesOptions}
              label={
                <Select.Label value={t(mes.value)} color={Label.COLOR.gray} />
              }
              visibility={targetItemState.field?.id ? 'visible' : 'disabled'}
              onChange={({ value }) => {
                dispatch({
                  type: NEW_TRANSFER.FORM_FIELD.TARGET.SET_VALUE,
                  payload: {
                    index,
                    value: Array.isArray(value) ? value : [value],
                  },
                });
              }}
            />
          </div>
          <div
            style={{ position: 'absolute', top: '-8px', right: '-8px' }}
            onClick={() =>
              targetsState.length !== 1 &&
              dispatch({
                type: NEW_TRANSFER.FORM_FIELD.TARGET.REMOVE,
                payload: index,
              })
            }
          >
            <Icon
              type="trash"
              className={cn([scss.removeBtn], {
                [scss.disabled]: targetsState.length === 1,
              })}
            />
          </div>
        </Stack.V>
      </Space>
    </Card>
  );
}

TransferTargetItem.propTypes = {
  index: PropTypes.number,
  valuesOptions: PropTypes.array,
  fieldSourceType: PropTypes.string,
  sourceActor: PropTypes.object,
  initialFieldId: PropTypes.string,
};

function TransferTargetsSettings({ initialFieldIds, ...props }) {
  const t = useIntl();

  const dispatch = useDispatch();

  const targetsState = useSelector((state) => state.newFormFieldTransfer.to);

  const sourceState = useSelector((state) => state.newFormFieldTransfer.from);

  const fieldSourceType = sourceState.field?.extra?.optionsSource?.type;

  const freeValues = useMemo(() => {
    const sourceValuesMap = keyBy(sourceState.value, 'value');
    const allSelectedValuesMap = keyBy(
      flatten(targetsState.map(({ value }) => value)),
      'value'
    );

    const result = pickBy(
      sourceValuesMap,
      ({ value }) => !allSelectedValuesMap[value]
    );

    return Object.values(result);
  }, [sourceState, targetsState]);

  return (
    <Stack.V {...props}>
      <Stack.V size={Stack.SIZE.small}>
        {targetsState.map((item, index) => (
          <TransferTargetItem
            key={index}
            index={index}
            valuesOptions={freeValues}
            fieldSourceType={fieldSourceType}
            sourceActor={sourceState.actor}
            initialFieldId={initialFieldIds?.[index]}
            {...item}
          />
        ))}
      </Stack.V>
      <div>
        <Button
          type="text"
          size="smallplus"
          fontWeight="normal"
          icon="add"
          label={t(mes.addAccount)}
          onClick={() => dispatch({ type: NEW_TRANSFER.FORM_FIELD.TARGET.ADD })}
        />
      </div>
    </Stack.V>
  );
}

TransferTargetsSettings.propTypes = {
  initialFieldIds: PropTypes.arrayOf(PropTypes.string),
};

export default TransferTargetsSettings;
