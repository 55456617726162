import { call, takeEvery, select, put } from 'redux-saga/effects';
import { NOTIFY_LEVEL, RequestStatus, SHOW_NOTIFY } from 'constants';
import { FileUtils } from 'mw-style-react';
import {
  ADD_ACTOR_ATTACHES,
  DOWNLOAD_ACTOR_ATTACHES,
  REMOVE_ACTOR_ATTACHES,
  WS_ZIP,
} from '@control-front-end/common/constants/graphActors';
import {
  makeAttach,
  bindAttach,
  unBindAttach,
} from '@control-front-end/common/sagas/attachUtils';
import api from '@control-front-end/common/sagas/api';
import AppUtils from '@control-front-end/utils/utils';
import getTranslation from '@control-front-end/utils/getTranslation';
import mes from 'globalIntl';
import { updateActorView } from './actorView';

const ZIP_STATUSES = {
  completed: 'completed',
  failed: 'failed',
};

/**
 * Attach attachment to actor
 */
function* addActorAttachments({ payload, callback }) {
  const { actorId, files } = payload;
  // создание аттачей
  const attachments = yield call(makeAttach, files, true);
  // привязка аттачей
  yield call(bindAttach, attachments, actorId);
  // Обновить модель актора
  const actorView = yield select((state) => state.actorView);
  if (actorView.id !== actorId) return;
  const actor = { ...actorView };
  const newAttachments = actor.attachments ? actor.attachments.slice() : [];
  newAttachments.unshift(...attachments);
  yield call(updateActorView, {
    payload: { actorData: { ...actor, attachments: newAttachments } },
  });
  if (callback) callback(attachments);
}

/**
 * Unpin attachment from actor
 */
function* deleteActorAttachments({ payload }) {
  const { actorId, files } = payload;
  const filesToDelete = Array.isArray(files) ? files : [files];
  // удаление аттачей
  if (filesToDelete.length) {
    yield call(unBindAttach, filesToDelete, actorId);
  }
  // Обновить модель актора
  const actorView = yield select((state) => state.actorView);
  if (actorView.id !== actorId) return;
  if (actorView.attachments && actorView.attachments.length) {
    const updAttachments = actorView.attachments.filter(
      (i) => !filesToDelete.find((u) => u.id === i.id)
    );
    yield call(updateActorView, {
      payload: {
        actorData: {
          ...actorView,
          attachments: updAttachments,
        },
      },
    });
  }
}

/**
 * Init download attachments
 */
function* downloadActorAttachments({ payload, callback }) {
  const { actorId } = payload;
  const { result } = yield call(api, {
    method: 'post',
    url: `/attachments/zip/${actorId}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({ type: DOWNLOAD_ACTOR_ATTACHES.SUCCESS });
  if (callback) callback();
}

/**
 * WS download attachments
 */
function* wsDownloadAttachments({ payload }) {
  const { status, title, details = {} } = payload;
  if (status === ZIP_STATUSES.completed) {
    const filePath = AppUtils.makeAppUrl(`/download/${details.fileName}`);
    FileUtils.download(filePath, title);
  } else {
    yield put({
      type: SHOW_NOTIFY.REQUEST,
      payload: {
        id: AppUtils.createRid(),
        type: NOTIFY_LEVEL.ERROR,
        label: getTranslation(mes.downloadFilesFailed),
      },
    });
  }
}

function* actorsAttaches() {
  yield takeEvery(ADD_ACTOR_ATTACHES.REQUEST, addActorAttachments);
  yield takeEvery(REMOVE_ACTOR_ATTACHES.REQUEST, deleteActorAttachments);
  yield takeEvery(DOWNLOAD_ACTOR_ATTACHES.REQUEST, downloadActorAttachments);
  yield takeEvery(WS_ZIP, wsDownloadAttachments);
}

export default actorsAttaches;
