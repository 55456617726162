import addActorToNode from './addActorToNode';
import createNodesByStructure from './createNodesByStructure';
import getSkeletone from './getSkeletone';

export { default as getSkeletone } from './getSkeletone';
export { default as addActorToNode } from './addActorToNode';
export { default as createNodesByStructure } from './createNodesByStructure';

export default {
  addActorToNode,
  createNodesByStructure,
  getSkeletone,
};
