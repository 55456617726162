import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import { ACCOUNT_TYPE } from '@control-front-end/common/constants/actorAccounts';

function AccountAmountValue({
  amount,
  commission,
  valueType,
  currencyParams,
  showCommission,
  fontWeight,
}) {
  if (
    ![null, undefined].includes(amount) &&
    valueType === ACCOUNT_TYPE.number
  ) {
    return (
      <>
        <Label
          fontWeight={fontWeight}
          overflow="cut"
          duplicatingTooltip
          value={AppUtils.simpleFormattedAmount(amount, currencyParams)}
        />
        {commission && showCommission ? (
          <Label
            fontSize="small"
            value={AppUtils.toMoneyFormat(commission, currencyParams.precision)}
          />
        ) : null}
      </>
    );
  }
  return null;
}

AccountAmountValue.propTypes = {
  amount: PropTypes.number,
  commission: PropTypes.number,
  valueType: PropTypes.oneOf(Object.values(ACCOUNT_TYPE)),
  currencyParams: PropTypes.object,
  fontWeight: PropTypes.oneOf(['normal', 'semibold']),
};

AccountAmountValue.defaultProps = {
  align: 'vertical',
  fontWeight: 'normal',
  showCommission: true,
  valueType: ACCOUNT_TYPE.number,
};

export default AccountAmountValue;
