import { useEffect, useState } from 'react';

/**
 * A hook that returns a boolean indicating whether a given timestamp has expired or not.
 *
 * @param {number} timestamp - The timestamp to check against the current time.
 * @return {boolean} A boolean indicating whether the timestamp has expired.
 */
const useExpirationNotifier = (timestamp) => {
  const [expired, setExpired] = useState(
    timestamp ? Date.now() > timestamp : null
  );

  /**
   * If there is expiration timestamp and it's not expired yet
   * than add timeout to change expired state when time comes
   */
  useEffect(() => {
    if (expired === true || expired === null) {
      return;
    }
    const timeoutId = setTimeout(
      () => setExpired(true),
      timestamp - Date.now()
    );

    return () => clearTimeout(timeoutId);
  }, [timestamp]);

  return expired;
};

export default useExpirationNotifier;
