import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TextField, MenuItem, Select } from 'mw-style-react';
import {
  GET_ACTOR,
  GET_ACTOR_BY_REF,
  GET_ITEMS_BY_IDS,
} from '@control-front-end/common/constants/graphActors';
import {
  RE_UUID_GLOBAL,
  URL_REGEXP,
} from '@control-front-end/common/constants/regExp';
import SelectActors from '@control-front-end/common/components/SelectActors';
import useIntl from 'useIntl';
import mes from './intl';
import './OptionsSource.scss';

export const SOURCES = [
  { value: 'manual', title: 'Manual' },
  { value: 'layer', title: 'Graph Layer' },
  { value: 'actorFilter', title: 'Actor filter' },
  { value: 'actors', title: 'Separate actors' },
  { value: 'currencies', title: 'Currencies' },
  { value: 'accountNames', title: 'Account names' },
  { value: 'workspaceMembers', title: 'Workspace members' },
  { value: 'api', title: 'API' },
  { value: 'corezoidSyncApi', title: 'Corezoid Sync API' },
];

/**
 * Компонент выбора источника данных для Select, Radio, MultiSelect
 */
function OptionsSource({
  id,
  isActive,
  extra = {},
  errors = {},
  onChange,
  handleInitOptions,
}) {
  const t = useIntl();
  const { optionsSource = { type: 'manual', value: null } } = extra;
  const [valueTitle, setValueTitle] = useState({});
  const [arrValueTitle, setArrValueTitle] = useState([]);
  const systemForms = useSelector((state) => state.systemForms) || {};
  const systemFormsLayers = systemForms.layers || {};
  const systemFormsFilters = systemForms.actorfilters || {};
  const dispatch = useDispatch();

  const errorCallback = (errId) => {
    setValueTitle({ id: null, title: t(mes.accessDenied) });
    const newErrors = errors || {};
    newErrors.id = errId;
    onChange({ fieldId: 'extra', id, value: extra, errors: newErrors });
  };

  useEffect(() => {
    const { optionsSource: source } = extra;
    if (!source || !source.value || !source.type || source.type === 'manual')
      return;
    switch (source.type) {
      case 'layer':
        dispatch({
          type: GET_ACTOR.REQUEST,
          payload: { id: source.value.id },
          callback: (layer) => {
            setValueTitle({ id: layer.id, name: layer.title });
          },
          errorCallback: () => errorCallback(t(mes.layerSourceAccessDenied)),
        });
        break;
      case 'actors':
      case 'actorFilter':
        // для совместимости после миграции фильтров в акторы
        const actorIdRegex = new RegExp(`(${RE_UUID_GLOBAL})`);
        if (source.value.id && !actorIdRegex.test(source.value.id)) {
          dispatch({
            type: GET_ACTOR_BY_REF.REQUEST,
            payload: {
              formId: systemFormsFilters.id,
              ref: source.value.id,
            },
            callback: (actor) => {
              setValueTitle(actor);
              const newExtra = { ...extra };
              newExtra.optionsSource.value = { id: actor.id };
              onChange({ fieldId: 'extra', id, value: newExtra });
            },
            errorCallback: (data) => setValueTitle(data),
          });
        } else {
          dispatch({
            type: GET_ITEMS_BY_IDS.REQUEST,
            payload: {
              itemsToSearch: {
                actors: source.value.ids || [source.value.id],
              },
            },
            callback: (actors) => {
              if (source.type === 'actorFilter') setValueTitle(actors[0]);
              else setArrValueTitle(actors);
            },
          });
        }
        break;
      default:
    }
  }, []);

  // Смена типа источника
  const handleSourceTypeChange = ({ value }) => {
    const newExtra = { ...extra };
    newExtra.optionsSource = { type: value, value: null };
    onChange({ fieldId: 'extra', id, value: newExtra });
    setValueTitle({});
    setArrValueTitle([]);
    if (value === 'manual') {
      handleInitOptions();
    }
  };

  // Смена значения источника (templateId, URL, ...)
  const handleChangeValue = ({ id: fieldId, value, error }) => {
    const newExtra = { ...extra };
    newExtra.optionsSource = extra.optionsSource || {};
    if (!newExtra.optionsSource.value) {
      newExtra.optionsSource.value = { [fieldId]: value };
    } else {
      newExtra.optionsSource.value[fieldId] = value;
    }
    const newErrors = errors || {};
    const errKey = `options:${fieldId}`;
    if (error) {
      newErrors[errKey] = t(mes[`optSourceError_${fieldId}`]);
    } else {
      delete newErrors[errKey];
    }
    onChange({ fieldId: 'extra', id, value: newExtra, errors: newErrors });
  };

  const getOptionsFieldError = (key) => errors[`options:${key}`];

  const renderSourceSettings = () => {
    const { type = 'manual', value = {} } = optionsSource;
    switch (type) {
      case 'api':
        return (
          <TextField
            styleName="source__edit"
            id="url"
            regexp={URL_REGEXP}
            error={!!getOptionsFieldError('url')}
            placeholder="API URL"
            helperText={errors ? getOptionsFieldError('url') : ''}
            value={value ? value.url : ''}
            onChange={handleChangeValue}
          />
        );
      case 'corezoidSyncApi':
        return (
          <>
            <TextField
              styleName="source__edit"
              id="convId"
              type="int"
              error={!!getOptionsFieldError('convId')}
              placeholder="Process ID"
              helperText={errors ? getOptionsFieldError('convId') : ''}
              value={value ? value.convId : ''}
              onChange={handleChangeValue}
            />
            <TextField
              styleName="source__edit"
              id="apiLogin"
              type="int"
              error={!!getOptionsFieldError('apiLogin')}
              placeholder="API Login"
              helperText={errors ? getOptionsFieldError('apiLogin') : ''}
              value={value ? value.apiLogin : ''}
              onChange={handleChangeValue}
            />
            <TextField
              styleName="source__edit"
              id="apiSecret"
              type="password"
              error={!!getOptionsFieldError('apiSecret')}
              placeholder="API Secret"
              helperText={errors ? getOptionsFieldError('apiSecret') : ''}
              value={value ? value.apiSecret : ''}
              onChange={handleChangeValue}
            />
          </>
        );
      case 'layer':
        return (
          <div styleName="source__select">
            <SelectActors
              key={type}
              id="id"
              bordered={true}
              type="modal"
              multiselect={false}
              label={t(mes.selectLayer)}
              placeholder={t(mes.selectFromList)}
              error={!!getOptionsFieldError('id')}
              helperText={errors ? getOptionsFieldError('id') : ''}
              formId={systemFormsLayers.id}
              value={valueTitle}
              exclude={[]}
              onChange={({ value: layer }) => {
                setValueTitle(layer);
                handleChangeValue({ id: 'id', value: layer.id });
              }}
            />
          </div>
        );
      case 'actors':
        return (
          <div styleName="source__select">
            <SelectActors
              key={type}
              id="ids"
              bordered={true}
              placeholder={t(mes.selectFromList)}
              label={t(mes.selectActors)}
              value={arrValueTitle}
              exclude={arrValueTitle}
              error={!!getOptionsFieldError('ids')}
              helperText={errors ? getOptionsFieldError('ids') : ''}
              onChange={({ value: actors }) => {
                setArrValueTitle(actors);
                const valA = actors.map((i) => i.id);
                handleChangeValue({ id: 'ids', value: valA });
              }}
            />
          </div>
        );
      case 'actorFilter':
        return (
          <div styleName="source__select">
            <SelectActors
              key={type}
              id="id"
              bordered={true}
              type="modal"
              multiselect={false}
              label={t(mes.selectActorFilter)}
              placeholder={t(mes.selectFromList)}
              error={!!getOptionsFieldError('id')}
              helperText={errors ? getOptionsFieldError('id') : ''}
              formId={systemFormsFilters.id}
              value={valueTitle}
              exclude={[]}
              onChange={({ value: filter }) => {
                setValueTitle(filter);
                handleChangeValue({ id: 'id', value: filter.id });
              }}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const renderSourceSelect = () => {
    let menu;
    if (isActive) {
      menu = SOURCES.map((item) => (
        <MenuItem key={item.value} label={item.title} value={item.value} />
      ));
    } else {
      const findItem =
        SOURCES.find((item) => item.value === optionsSource.type) || {};
      menu = (
        <MenuItem
          key={findItem.value}
          label={findItem.title}
          value={findItem.value}
        />
      );
    }
    return (
      <Select
        styleName="source__type"
        label={t(mes.selectOptionsSource)}
        bordered={false}
        value={optionsSource.type}
        onChange={handleSourceTypeChange}
      >
        {menu}
      </Select>
    );
  };

  return (
    <div styleName="source">
      {renderSourceSelect()}
      {renderSourceSettings()}
    </div>
  );
}

OptionsSource.propTypes = {
  id: PropTypes.string,
  isActive: PropTypes.bool,
  errors: PropTypes.object,
  extra: PropTypes.object,
  onChange: PropTypes.func,
  handleInitOptions: PropTypes.func,
};

export default OptionsSource;
