import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  addUsers: {
    id: 'addUsers_Custom_Bar',
    defaultMessage: 'Add users',
  },
  addUsersTooltip: {
    id: 'addUsersTooltip_Custom_Bar',
    defaultMessage:
      'For all users in the selected groups, this script will be displayed in the input field for quick execution.',
  },
  barIcon: {
    id: 'barIcon_Custom_Bar',
    defaultMessage: 'Bar icon',
  },
  barIconTooltip: {
    id: 'barIconTooltip_Custom_Bar',
    defaultMessage:
      'This icon will be displayed in the input field for quick script execution.',
  },
  emojiTabName: { id: 'barIconTooltip_emojiTabName', defaultMessage: 'Emoji' },
  imageTabName: { id: 'barIconTooltip_imageTabName', defaultMessage: 'Image' },
});

export default { ...globalIntl, ...m };
