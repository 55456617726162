import React from 'react';
import PropTypes from 'prop-types';
import useIntl from 'useIntl';
import { Checkbox, Label } from 'mw-style-react';
import '../../CreateActorsFilter.scss';
import mes from '../../intl';

function ActorsStatus({ statuses = [], statusTypes, onChange }) {
  const t = useIntl();

  const handleChangeActorStatus = ({ statusType, value }) => {
    let newStatuses = statuses.slice();
    if (value) {
      newStatuses.push(statusType);
    } else {
      newStatuses = statuses.filter((i) => i !== statusType);
    }
    onChange({ id: 'status', value: newStatuses });
  };

  return (
    <div styleName="filter__section dynamic">
      <div styleName="filter__col">
        <span styleName="label">{t(mes.actorStatusToggleLabel)}</span>
        <div styleName="filter__checkboxes">
          {statusTypes.map((statusType) => (
            <Checkbox
              key={statusType}
              value={statuses.includes(statusType)}
              visibility="visible"
              onChange={({ value }) => {
                handleChangeActorStatus({
                  statusType,
                  value,
                });
              }}
            >
              <Label value={t(mes[`labelStatus_${statusType}`])} />
            </Checkbox>
          ))}
        </div>
      </div>
    </div>
  );
}

ActorsStatus.propTypes = {
  statuses: PropTypes.array,
  statusTypes: PropTypes.array,
  onChange: PropTypes.func,
};

export default ActorsStatus;
