import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import { useIntl } from 'hooks';
import FormUtils from '@control-front-end/utils/formUtils';
import { Icon, Label } from 'mw-style-react';
import Form from '@control-front-end/forms';
import { DEL_MODAL, REPLACE_MODAL, SET_MODAL } from 'constants';
import { DEFAULT_FORM_TITLE } from '@control-front-end/common/constants/forms';
import Title from '@control-front-end/common/components/Title';
import AccessRules from '@control-front-end/common/components/AccessRules';
import Panel from './components/Panel';
import FormEdit from './components/FormEdit';
import FormSettings from './components/FormSettings';
import FormAccounts from './components/FormAccounts';
import FormMap from '../FormMap';
import FormActions from './FormActions';
import mes from './intl';
import './FormConstructor.scss';

function FormConstructor(props) {
  const {
    action = 'constructor',
    isNew = false,
    form,
    formId,
    titlePortal,
    canEdit,
    defaultTitle,
    titleType = 'primary',
    handleFormField,
  } = props;
  const t = useIntl();
  const dispatch = useDispatch();

  const goToForm = (nextFormId) => {
    dispatch({
      type: REPLACE_MODAL,
      payload: {
        modalId: formId,
        name: 'PublishForm',
        visible: true,
        replace: 'PublishForm',
        data: {
          step: 'CreateForm',
          formId: nextFormId,
        },
        closeConfirm: true,
      },
    });
  };

  const showConfirmAnotherForm = (nextFormId) => {
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'InfoModal',
        data: {
          title: 'moveToForm',
          text: 'anotherFormConfirm',
          buttons: [
            {
              label: t(mes.ok),
              onClick: () => {
                goToForm(nextFormId);
                dispatch({ type: DEL_MODAL });
              },
            },
          ],
        },
      },
    });
  };

  const renderTitlePortal = () => {
    if (!titlePortal) return;
    return ReactDOM.createPortal(
      <Title
        title={form.title || defaultTitle || t(mes[DEFAULT_FORM_TITLE])}
        type={titleType}
        canEdit={canEdit}
        readOnly={!canEdit}
        isNew={isNew && form.title === t(mes[DEFAULT_FORM_TITLE])}
        nullable={true}
        singleLine={true}
        onSubmit={(value) => {
          handleFormField({
            id: 'title',
            value: value || t(mes[DEFAULT_FORM_TITLE]),
          });
        }}
      />,
      titlePortal
    );
  };

  const renderConstructor = () => {
    const { activeSection, activeItem, showPanel, errorsChecked, isIframe } =
      props;
    const section = form.sections[activeSection];
    const item = section?.content?.[activeItem] || {};

    return (
      <div styleName="cf__editor">
        <div styleName="cf__editor__main">
          <FormEdit
            isNew={!formId}
            isIframe={isIframe}
            activeSection={activeSection}
            activeItem={activeItem}
            sections={form.sections}
            errors={item.errors}
            errorsChecked={errorsChecked}
            parentId={props.parentId}
            formRef={props.formRef}
            onChange={props.handleOnChangeItem}
            onParentIdChange={props.handleChangeParentId}
            onRefChange={props.handleChangeRef}
            onActive={props.handelOnActive}
            onCreateSection={props.handleAddSection}
            onRemoveSection={props.handleRemoveSection}
            onSortSection={props.handleSortSection}
            onRenameSection={props.handleRenameSection}
            onCreateItem={props.handleAddItem}
            onRemoveItem={props.handleRemoveItem}
            onSortItem={props.handleSortItem}
            onShowPanel={props.handleTogglePanel}
            showPanel={showPanel}
          />
        </div>
        {showPanel && section ? (
          <div styleName="cf__editor__panel">
            <Panel
              sections={form.sections}
              onChange={props.handleOnChangeItem}
              onShowPanel={props.handleTogglePanel}
              {...item}
              sectionIndex={activeSection}
              itemIndex={activeItem}
            />
          </div>
        ) : null}
      </div>
    );
  };

  const renderSettings = () => {
    return (
      <FormSettings
        color={form.color}
        description={form.description}
        handleFormField={props.handleFormField}
      />
    );
  };

  const renderMap = () => (
    <FormMap
      formId={formId}
      form={form}
      handleDoubleClickNode={showConfirmAnotherForm}
    />
  );

  const renderPreview = () => {
    const { form } = props.getForm();
    return (
      <div styleName="cf__preview">
        <div styleName="cf__preview__main">
          <Form
            form={form}
            formId={formId}
            type="modal"
            openAllSections={true}
            showNavigation={true}
            isPreview={true}
          />
        </div>
      </div>
    );
  };

  const renderAccess = () => {
    const { access, ownerId, handleChangeAccess } = props;
    return (
      <div styleName="cf__access">
        <AccessRules
          objId={formId}
          objType="formTemplate"
          styleType="embeded"
          rules={access}
          ownerId={ownerId}
          onChange={handleChangeAccess}
        />
      </div>
    );
  };

  const renderActorsAccess = () => {
    const { parentId, actorsAccess, ownerId, handleChangeActorsAccess } = props;
    return (
      <div styleName="cf__access">
        {!parentId ? (
          <AccessRules
            objId={formId}
            objType="templateActors"
            styleType="embeded"
            rules={actorsAccess}
            ownerId={ownerId}
            disableOwner={false}
            onChange={handleChangeActorsAccess}
          />
        ) : (
          <div styleName="cf__access__noUat">
            <Icon size="xlarge" type="form_pole" />
            <Label value={t(mes.noFormActorsAccess)} />
            <Label value={t(mes.noFormActorsAccessInfo)} />
          </div>
        )}
      </div>
    );
  };

  const renderAccounts = () => {
    const { formAccounts, handleChangeAccounts } = props;
    const formFields = [];
    form.sections.forEach((sec, sectionIndex) => {
      const allItems = FormUtils.getSectionItems(sec).map((i, itemIndex) => ({
        ...i,
        itemIndex,
        sectionIndex,
      }));
      formFields.push(...allItems);
    });
    return (
      <FormAccounts
        formAccounts={formAccounts}
        formFields={formFields}
        handleChangeAccounts={handleChangeAccounts}
        handleDeleteField={props.handleRemoveItem}
        handleDeleteSection={props.handleRemoveSection}
      />
    );
  };

  const renderContent = () => {
    switch (action) {
      case 'constructor':
        return renderConstructor();
      case 'preview':
        return renderPreview();
      case 'map':
        return renderMap();
      case 'access':
        return renderAccess();
      case 'actorsAccess':
        return renderActorsAccess();
      case 'accounts':
        return renderAccounts();
      case 'settings':
        return renderSettings();
      default:
        break;
    }
  };

  return (
    <>
      {renderTitlePortal()}
      {renderContent()}
    </>
  );
}

FormConstructor.defaultProps = {
  action: 'constructor',
  titleType: 'primary',
  isNew: false,
};

FormConstructor.propTypes = {
  action: PropTypes.oneOf([
    'constructor',
    'preview',
    'map',
    'settings',
    'access',
    'actorsAccess',
    'accounts',
  ]),
  formId: PropTypes.number,
  parentId: PropTypes.number,
  showPanel: PropTypes.bool,
  form: PropTypes.object,
  access: PropTypes.array,
  actorsAccess: PropTypes.array,
  formAccounts: PropTypes.array,
  canEdit: PropTypes.bool,
  ownerId: PropTypes.number,
  activeSection: PropTypes.number,
  activeItem: PropTypes.number,
  defaultTitle: PropTypes.string,
  titleType: PropTypes.string,
  titlePortal: PropTypes.any,
  isNew: PropTypes.bool,
  isIframe: PropTypes.bool,
  errorsChecked: PropTypes.bool,
  handleTogglePanel: PropTypes.func,
  handleOnChangeItem: PropTypes.func,
  handelOnActive: PropTypes.func,
  handleSortSection: PropTypes.func,
  handleRenameSection: PropTypes.func,
  handleAddSection: PropTypes.func,
  handleRemoveSection: PropTypes.func,
  handleAddItem: PropTypes.func,
  handleRemoveItem: PropTypes.func,
  handleSortItem: PropTypes.func,
  handleFormField: PropTypes.func,
  handleChangeParentId: PropTypes.func,
  handleChangeRef: PropTypes.func,
  handleChangeAccess: PropTypes.func,
  handleChangeActorsAccess: PropTypes.func,
  handleChangeAccounts: PropTypes.func,
  getForm: PropTypes.func,
};

export default FormActions(FormConstructor);
