import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button, Stack, Space, Card, Label, Popover } from 'mw-style-react';
import UserAvatar from '@control-front-end/common/components/UserAvatar';
import scss from './ParticipantsCompact.scss';

/**
 * Renders a list of participants with possibility to remove them
 *
 * @param {Object} participants - An array of participant objects.
 * @param {Function} onRemove - A function to remove a participant.
 * @return {JSX.Element} A React component representing the participants list.
 */
function ParticipantsList({ participants, onRemove, className }) {
  return (
    <Card color={Card.COLOR.secondary} className={cn(className, scss.listWrap)}>
      <Space>
        <Stack>
          {participants.map((item) => {
            const { id, nick, value, name, avatar, pictureUrl, type } = item;
            return (
              <Stack
                size={Stack.SIZE.xsmall}
                horizontal
                alignItems="center"
                fullWidth
                key={id}
              >
                <Stack
                  size={Stack.SIZE.xsmall}
                  alignItems="center"
                  horizontal
                  fullWidth
                >
                  <div>
                    <UserAvatar
                      userType={type}
                      label={type === 'group' ? null : nick || value || name}
                      src={avatar || pictureUrl}
                      customIcon={
                        type === 'group'
                          ? { type: 'persons', color: '#1973E8', size: 'small' }
                          : null
                      }
                    />
                  </div>
                  <Label
                    overflow={Label.OVERFLOW.cut}
                    color={Label.COLOR.black}
                    value={nick || value || name}
                  />
                </Stack>
                <Button
                  size="xxsmall"
                  icon="close"
                  type="text"
                  onClick={() => onRemove(item)}
                />
              </Stack>
            );
          })}
        </Stack>
      </Space>
    </Card>
  );
}

ParticipantsList.propTypes = {
  participants: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired,
};

/**
 * Renders a compact list of participants with the option to show a popover with the full list.
 *
 * @param {Array} participants - An array of participants to display
 * @param {Function} onRemove - A callback function to remove a participant
 * @param {number} maxPreviewAmount - The maximum amount of participants to show in the compact list
 * @return {JSX.Element|null} A JSX element representing the list of participants or null if there are no participants
 */
function ParticipantsCompact({ participants, onRemove, maxPreviewAmount }) {
  const previewItems = participants.slice(0, maxPreviewAmount);
  const restAmount = participants.length - maxPreviewAmount;

  if (restAmount > 0) {
    previewItems.push({ id: 'count', text: `+${restAmount}` });
  }

  return participants.length ? (
    <Popover
      content={
        <ParticipantsList
          className={scss.list}
          participants={participants}
          onRemove={onRemove}
        />
      }
      padding={8}
    >
      <div className={scss.preview}>
        {previewItems.map(({ id, nick, value, pictureUrl, avatar, text }) => (
          <UserAvatar
            key={id}
            className={scss.previewItem}
            label={nick || value}
            src={pictureUrl || avatar}
          >
            {text}
          </UserAvatar>
        ))}
      </div>
    </Popover>
  ) : null;
}

ParticipantsCompact.defaultProps = {
  maxPreviewAmount: 4,
};

ParticipantsCompact.propTypes = {
  participants: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired,
  maxPreviewAmount: PropTypes.number,
};

export default ParticipantsCompact;
