import { createSelector } from '@reduxjs/toolkit';

const getPrivs = (state) => state.formView.privs;
const getIsSystem = (state) => state.formView.type === 'system';

export const getCanEditForm = createSelector(
  [getPrivs, getIsSystem],
  (privs, isSystem) => {
    if (isSystem) return false;
    if (!privs) return true;
    return privs.modify;
  }
);
