import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  PIN_LAYER,
  UNPIN_LAYER,
} from '@control-front-end/common/constants/graphLayers';
import { RequestStatus } from 'constants';
import api from '@control-front-end/common/sagas/api';
import { updateLayerProp } from './layerHelpers';

/**
 * Закрепить слой
 */
function* pinLayer({ payload }) {
  const { layer } = payload;
  const { result } = yield call(api, {
    method: 'post',
    url: `/graph_layers/pin/${layer.id}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  let copyList;
  const { list } = yield select((state) => state.graphLayers);
  const layerIndex = list.findIndex((i) => i.id === layer.id);
  if (layerIndex === -1) {
    copyList = list.slice();
    copyList.push({ ...layer, type: 'default', pinned: true });
  } else {
    copyList = yield call(updateLayerProp, {
      layerId: layer.id,
      propId: 'pinned',
      value: true,
    });
  }
  yield put({
    type: PIN_LAYER.SUCCESS,
    payload: { list: copyList },
  });
}

/**
 * Открепить слой
 */
function* unpinLayer({ payload }) {
  const { layer } = payload;
  const { result } = yield call(api, {
    method: 'delete',
    url: `/graph_layers/pin/${layer.id}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  const list = yield call(updateLayerProp, {
    layerId: layer.id,
    propId: 'pinned',
    value: false,
  });
  yield put({
    type: UNPIN_LAYER.SUCCESS,
    payload: { list },
  });
}

function* layerPin() {
  yield takeEvery(PIN_LAYER.REQUEST, pinLayer);
  yield takeEvery(UNPIN_LAYER.REQUEST, unpinLayer);
}

export default layerPin;
