import Client from 'mw-fetch';
import { call, put, select } from 'redux-saga/effects';
import {
  APP_AUTH_KEY,
  SSO_AUTH_KEY,
  SHOW_NOTIFY,
  NOTIFY_LEVEL,
  SHOW_SESSION_MODAL,
  RequestStatus,
} from 'constants';
import { Utils } from 'mw-style-react';
import mes from '@control-front-end/app/src/components/Notifications/intl';
import getTranslation from '@control-front-end/utils/getTranslation';
import AppUtils from '@control-front-end/utils/utils';
import { SEARCH_HTML_TITLE } from '../constants/regExp';

// Error display
function* sendError({ message, statusCode }) {
  let id = AppUtils.createRid();
  if (!message) {
    id = 'internalError';
    message = getTranslation(mes.internalError);
  }
  yield put({
    type: SHOW_NOTIFY.REQUEST,
    payload: {
      id,
      type: NOTIFY_LEVEL.ERROR,
      label: message,
      statusCode,
    },
  });
}

// Сформировать строку авторизации
function makeAuthHeader() {
  if (window[SSO_AUTH_KEY]) return window[SSO_AUTH_KEY];
  const appAuthStr = Utils.fromStorage(APP_AUTH_KEY);
  return appAuthStr ? `Bearer ${appAuthStr}` : null;
}

function* getConnector(reqType) {
  const appInit = yield select((state) => state.appInit);
  const authStr = makeAuthHeader();
  const authHeader = authStr ? { Authorization: authStr } : {};
  if (reqType === 'multipart') {
    return new Client({
      headers: {
        'Content-Type': 'multipart/form-data',
        'Control-Tab-Id': appInit.tabId,
        ...authHeader,
      },
    });
  }
  return new Client({
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Control-Tab-Id': appInit.tabId,
      ...authHeader,
    },
  });
}

function* api({
  method = 'get',
  url = '',
  body = {},
  reqType = 'json',
  queryParams = {},
  handleErrCodes = [],
}) {
  if (!url) return;
  url = AppUtils.makeAppUrl(url, queryParams);
  let response = {};
  try {
    // Вызов АПИ
    const client = yield call(getConnector, reqType);
    response = yield call([client, method], url, body);
    // Парсинг результатов от api
    switch (response.result) {
      case RequestStatus.SUCCESS:
        break;
      case RequestStatus.ERROR:
        const throwError = !handleErrCodes.includes(response.data.statusCode);
        if (throwError) {
          yield sendError({
            message: response.data.message,
            statusCode: response.data.statusCode,
          });
        }
        break;
      case RequestStatus.UNAUTH:
        yield put({ type: SHOW_SESSION_MODAL });
        break;
      default:
        break;
    }
  } catch (e) {
    yield sendError({
      message: e.message,
      statusCode: '50X',
    });
  }

  return response;
}

export async function chunks({
  method = 'get',
  url = '',
  body = {},
  queryParams = {},
  handler,
  controller,
}) {
  if (!url) return;
  url = AppUtils.makeAppUrl(url, queryParams);
  const authStr = makeAuthHeader();
  const authHeader = authStr ? { Authorization: authStr } : {};
  const response = await fetch(url, {
    method,
    headers: {
      ...authHeader,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    signal: controller.signal,
  });
  const reader = response.body.getReader();
  const decoder = new TextDecoder('utf-8');
  let dataBuffer = new Uint8Array([]);

  // eslint-disable-next-line
  async function readChunk() {
    try {
      const { done, value } = await reader.read();
      if (!done) {
        const newData = new Uint8Array(value);
        dataBuffer = new Uint8Array([...dataBuffer, ...newData]);
        await readChunk();
      }
      const decodedValue = decoder.decode(dataBuffer);
      const mergedValue = decodedValue.replaceAll('][', ',');
      if (!AppUtils.isJSON(mergedValue)) {
        const errorTitle = decodedValue.match(SEARCH_HTML_TITLE)?.[1];
        handler({
          error: true,
          errorDescription:
            errorTitle || `${getTranslation(mes.invalidJson)}: ${mergedValue}`,
        });
        return;
      }
      handler({
        value: JSON.parse(mergedValue),
        error: false,
      });
    } catch (exception) {
      if (exception.name === 'AbortError') return;
      handler({
        error: true,
        errorDescription: exception.message,
      });
    }
  }

  await readChunk();
}

export default api;
