// eslint-disable-next-line import/no-cycle
import { HOT_KEYS } from '@control-front-end/common/constants';
import AppUtils from '../utils';

export default {
  isDeleteBtn(e) {
    return (
      (AppUtils.isMacLike() && e.keyCode === HOT_KEYS.Backspace) ||
      e.code === HOT_KEYS.Delete
    );
  },
  ignoreKeyDown(e) {
    const { tagName, isContentEditable } = document.activeElement;
    return (
      !this.isDeleteBtn(e) ||
      tagName === 'INPUT' ||
      tagName === 'TEXTAREA' ||
      isContentEditable
    );
  },

  ignoreHotKeys() {
    const { tagName, isContentEditable } = document.activeElement;
    return tagName === 'INPUT' || tagName === 'TEXTAREA' || isContentEditable;
  },

  isCtrlOrMetaKey(e) {
    return e.ctrlKey || e.metaKey;
  },
};
