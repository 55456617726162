import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  createTransaction: {
    id: 'createTransaction',
    defaultMessage: 'Create transaction',
  },
  sameCreatedAt: {
    id: 'sameCreatedAt',
    defaultMessage: 'Same as creation date',
  },
  resetDate: {
    id: 'resetDate',
    defaultMessage: 'Reset to default',
  },
  not_zero: {
    id: 'not_zero',
    defaultMessage: 'AMOUNT can not be 0',
  },
  extraParams: {
    id: 'extraParams',
    defaultMessage: 'Extra parameters',
  },
  makeTransaction: {
    id: 'makeTransaction',
    defaultMessage: 'Make transaction',
  },
});

export default { ...globalIntl, ...m };
