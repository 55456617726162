import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  triggerCalls: {
    id: 'triggerCalls',
    defaultMessage: 'Trigger Calls',
  },
  requestResent: {
    id: 'requestResent',
    defaultMessage: 'Request has been successfully resent',
  },
});

export default { ...globalIntl, ...m };
