import { Utils } from 'mw-style-react';
import { FIELD_ACCOUNT_GROUP } from '@control-front-end/common/constants/forms';

// Accounts groups (by account tag)
export const GET_ACTORS_ACCOUNTS_GROUPS = Utils.createRequestTypes(
  'GET_ACTORS_ACCOUNTS_GROUPS'
);
export const SET_ACTORS_ACCOUNTS_GROUPS_REQ_STATUS =
  'SET_ACTORS_ACCOUNTS_GROUPS_REQ_STATUS';
export const SET_ACTIVE_ACCOUNTS_GROUP = 'SET_ACTIVE_ACCOUNTS_GROUP';
export const CLEAR_ACCOUNTS_GROUPS = 'CLEAR_ACCOUNTS_GROUPS';

export const GET_ACTORS_ACCOUNTS = Utils.createRequestTypes(
  'GET_ACTORS_ACCOUNTS'
);
export const CREATE_ACTORS_ACCOUNT = Utils.createRequestTypes(
  'CREATE_ACTORS_ACCOUNT'
);
export const BULK_CREATE_ACCOUNTS = Utils.createRequestTypes(
  'BULK_CREATE_ACCOUNTS'
);
export const REMOVE_ACTORS_ACCOUNT = Utils.createRequestTypes(
  'REMOVE_ACTORS_ACCOUNT'
);
export const LOCK_ACTORS_ACCOUNT = Utils.createRequestTypes(
  'LOCK_ACTORS_ACCOUNT'
);
export const UPDATE_ACTORS_ACCOUNT = Utils.createRequestTypes(
  'UPDATE_ACTORS_ACCOUNT'
);
export const GET_ACCOUNT_CURRENCIES = Utils.createRequestTypes(
  'GET_ACCOUNT_CURRENCIES'
);
export const GET_ACCOUNT_CURRENCY = Utils.createRequestTypes(
  'GET_ACCOUNT_CURRENCY'
);
export const CREATE_ACCOUNT_CURRENCIES = Utils.createRequestTypes(
  'CREATE_ACCOUNT_CURRENCIES'
);
export const UPDATE_ACCOUNT_CURRENCY = Utils.createRequestTypes(
  'UPDATE_ACCOUNT_CURRENCY'
);
export const REMOVE_ACCOUNT_CURRENCY = Utils.createRequestTypes(
  'REMOVE_ACCOUNT_CURRENCY'
);
export const SEARCH_ACCOUNT_CURRENCY = Utils.createRequestTypes(
  'SEARCH_ACCOUNT_CURRENCY'
);
export const GET_ACCOUNT_NAMES = Utils.createRequestTypes('GET_ACCOUNT_NAMES');
export const GET_ACCOUNT_NAME = Utils.createRequestTypes('GET_ACCOUNT_NAME');
export const GET_ACCOUNT_NAME_CURRENCIES = Utils.createRequestTypes(
  'GET_ACCOUNT_NAME_CURRENCIES'
);
export const CREATE_ACCOUNT_NAME = Utils.createRequestTypes(
  'CREATE_ACCOUNT_NAME'
);
export const UPDATE_ACCOUNT_NAME = Utils.createRequestTypes(
  'UPDATE_ACCOUNT_NAME'
);
export const REMOVE_ACCOUNT_NAME = Utils.createRequestTypes(
  'REMOVE_ACCOUNT_NAME'
);
export const SEARCH_ACCOUNT_NAME = Utils.createRequestTypes(
  'SEARCH_ACCOUNT_NAME'
);
export const CHECK_ACCOUNT_PAIR =
  Utils.createRequestTypes('CHECK_ACCOUNT_PAIR');
export const SAVE_ACCOUNT_PAIR = Utils.createRequestTypes('SAVE_ACCOUNT_PAIR');
export const SAVE_ACCOUNT_ACTORS = Utils.createRequestTypes(
  'SAVE_ACCOUNT_ACTORS'
);
export const BULK_MANAGE_ACCOUNT_TAGS = Utils.createRequestTypes(
  'BULK_MANAGE_ACCOUNT_TAGS'
);
export const GET_ACCOUNT_ACTORS =
  Utils.createRequestTypes('GET_ACCOUNT_ACTORS');
export const GET_ALL_ACCOUNTS_TAGS = Utils.createRequestTypes(
  'GET_ALL_ACCOUNTS_TAGS'
);
export const GET_ALL_PAIR_ACTORS_ACCOUNTS = Utils.createRequestTypes(
  'GET_ALL_PAIR_ACTORS_ACCOUNTS'
);
export const GET_TRIGGER_CALLS = Utils.createRequestTypes('GET_TRIGGER_CALLS');
export const RESEND_TRIGGER_CALL = Utils.createRequestTypes(
  'RESEND_TRIGGER_CALL'
);
export const CREATE_TRANSACTION =
  Utils.createRequestTypes('CREATE_TRANSACTION');
export const UPDATE_TRANSACTION =
  Utils.createRequestTypes('UPDATE_TRANSACTION');
export const GET_TRANSACTIONS = Utils.createRequestTypes('GET_TRANSACTIONS');
export const GET_CHILD_TRANSACTIONS = Utils.createRequestTypes(
  'GET_CHILD_TRANSACTIONS'
);
export const SEARCH_TRANSACTIONS = Utils.createRequestTypes(
  'SEARCH_TRANSACTIONS'
);
export const FILTER_TRANSACTIONS = Utils.createRequestTypes(
  'FILTER_TRANSACTIONS'
);
export const EXPORT_TRANSACTIONS = Utils.createRequestTypes(
  'EXPORT_TRANSACTIONS'
);
export const ADD_TRANSACTION = Utils.createRequestTypes('ADD_TRANSACTION');
export const GET_ACCOUNT_DETAILS = Utils.createRequestTypes(
  'GET_ACCOUNT_DETAILS'
);
export const RECALCULATE_TREE_ACCOUNT_BALANCE = Utils.createRequestTypes(
  'RECALCULATE_TREE_ACCOUNT_BALANCE'
);
export const GET_SINGLE_TRANSACTION = Utils.createRequestTypes(
  'GET_SINGLE_TRANSACTION'
);
export const GET_TRANSACTION_FULL = Utils.createRequestTypes(
  'GET_TRANSACTION_FULL'
);
export const GET_FORM_FIELD_TRANSACTION = Utils.createRequestTypes(
  'GET_FORM_FIELD_TRANSACTION'
);

export const GET_COORDINATES_TRANSACTIONS = Utils.createRequestTypes(
  'GET_COORDINATES_TRANSACTIONS'
);

export const GET_ACTOR_ACCOUNTS_ROLES = Utils.createRequestTypes(
  'GET_ACTOR_ACCOUNTS_ROLES'
);
export const GET_ACCOUNTS_ROLES_TRANSACTIONS = Utils.createRequestTypes(
  'GET_ACCOUNTS_ROLES_TRANSACTIONS'
);
// ---------------------------------------
// ------- New Transfer -------
const TRANSFER_ITEM_ACTIONS = {
  SET_ACTOR: 'SET_ACTOR',
  SET_ACCOUNT: 'SET_ACCOUNT',
  SET_VALUE: 'SET_VALUE',
  ADD: 'ADD',
  REMOVE: 'REMOVE',
  RESET: 'RESET',
};

export const MAX_TRANSFER_AMOUNT = 9223372036854775808;

export const TRANSFER_SIDE = { SOURCE: 'SOURCE', TARGET: 'TARGET' };

export const NEW_TRANSFER = Utils.mapPathsToLeaves(
  {
    FORM_FIELD: {
      [TRANSFER_SIDE.SOURCE]: TRANSFER_ITEM_ACTIONS,
      [TRANSFER_SIDE.TARGET]: TRANSFER_ITEM_ACTIONS,
      SET: 'SET',
      CLEAR: 'CLEAR',
    },
    NUMERIC: {
      [TRANSFER_SIDE.SOURCE]: TRANSFER_ITEM_ACTIONS,
      [TRANSFER_SIDE.TARGET]: TRANSFER_ITEM_ACTIONS,
      SET: 'SET',
      CLEAR: 'CLEAR',
    },
  },
  'NEW_TRANSFER'
);

// Transfers
export const FILTER_TRANSFERS = Utils.createRequestTypes('FILTER_TRANSFERS');
export const CREATE_TRANSFER = Utils.createRequestTypes('CREATE_TRANSFER');
export const CREATE_FIELDS_TRANSFER = Utils.createRequestTypes(
  'CREATE_FIELDS_TRANSFER'
);
export const GET_TRANSFER = Utils.createRequestTypes('GET_TRANSFER');
export const ADD_TRANSFER = Utils.createRequestTypes('ADD_TRANSFER');
export const FILTER_TRANSFERS_REQ_STATUS = 'FILTER_TRANSFERS_REQ_STATUS';
export const CLEAR_TRANSFERS = 'CLEAR_TRANSFERS';

export const FILTER_TRANSACTIONS_REQ_STATUS = 'FILTER_TRANSACTIONS_REQ_STATUS';
export const GET_ACCOUNT = Utils.createRequestTypes('GET_ACCOUNT');
export const GET_CONNECTORS = Utils.createRequestTypes('GET_CONNECTORS');
export const ADD_CONNECTOR = Utils.createRequestTypes('ADD_CONNECTOR');
export const REMOVE_CONNECTOR = Utils.createRequestTypes('REMOVE_CONNECTOR');
export const SET_ACCOUNT_FORMULA = Utils.createRequestTypes(
  'SET_ACCOUNT_FORMULA'
);
export const GET_ACCOUNT_FORMULA_INFO = Utils.createRequestTypes(
  'GET_ACCOUNT_FORMULA_INFO'
);
export const SET_FORMULAS = Utils.createRequestTypes('SET_FORMULAS');
export const TRANSFER_ACCOUNTS = Utils.createRequestTypes('TRANSFER_ACCOUNTS');
export const INHERIT_ACCOUNTS = Utils.createRequestTypes('INHERIT_ACCOUNTS');
export const WS_DELETE_TRANSACTION = 'WS_DELETE_TRANSACTION';

export const SET_ACTORS_ACCOUNTS_REQ_STATUS = 'SET_ACTORS_ACCOUNTS_REQ_STATUS';
export const SET_ACCOUNT_CURRENCIES_REQ_STATUS =
  'SET_ACCOUNT_CURRENCIES_REQ_STATUS';
export const SET_ACCOUNT_NAMES_REQ_STATUS = 'SET_ACCOUNT_NAMES_REQ_STATUS';
export const CLEAR_ACTORS_ACCOUNTS = 'CLEAR_ACTORS_ACCOUNTS';
export const CLEAR_TRANSACTIONS = 'CLEAR_TRANSACTIONS';
export const CLEAR_ACCOUNT_CURRENCIES = 'CLEAR_ACCOUNT_CURRENCIES';
export const CLEAR_ACCOUNT_NAMES = 'CLEAR_ACCOUNT_NAMES';
export const CURRENCY_TYPE = {
  number: 'number',
  percent: 'percent',
  currency: 'currency',
  dateTime: 'dateTime',
  seconds: 'seconds',
};
export const CURRENCY_TYPES = Object.keys(CURRENCY_TYPE);
export const ACCOUNTS_SORT_ORDERS = [
  'nameAsc',
  'nameDesc',
  'totalAsc',
  'totalDesc',
];
export const DEFAULT_CURRENCY_PARAMS = {
  type: 'number',
  symbol: '',
  precision: 2,
};

export const NONE_VALUE = { id: 'none', name: 'None' };

export const INCOME_TYPE = { total: 'total', credit: 'credit', debit: 'debit' };

export const COUNTER_TYPE = { count: 'count', amount: 'amount' };

export const ACCOUNT_TYPE = {
  number: 'number',
  formField: 'formField',
  coordinates: 'coordinates', // Now it's separate account type but probably formField will be used instead
  roles: 'roles',
};

export const PairStatus = Object.freeze({
  EXIST: 'ok',
  NOT_EXIST: 'notExist',
  ACCESS_DENIED: 'accessDenied',
});

export const TRANSACTION_TYPES = Object.freeze({
  AUTHORIZED: 'authorized',
  DECLINED: 'declined',
  COMPLETED: 'completed',
  CANCELED: 'canceled',
  REVERSED: 'reversed',
  MIN_LIMIT_CHANGED: 'minLimitChanged',
  MAX_LIMIT_CHANGED: 'maxLimitChanged',
});

export const AMOUNT_OPER = Object.freeze({
  eq: '=',
  ne: '!=',
  gte: '>=',
  gt: '>',
  lt: '<',
  lte: '<=',
});

export const ACCOUNT_COUNTER_TYPE = Object.freeze({
  AMOUNT: 'amount',
  COUNTER: 'counter',
  UNIQ_COUNTER: 'uniqCounter',
});

export const ACCOUNTS_GROUP = {
  [FIELD_ACCOUNT_GROUP.system]: FIELD_ACCOUNT_GROUP.system,
  [FIELD_ACCOUNT_GROUP.formFields]: FIELD_ACCOUNT_GROUP.formFields,
  untagged: 'untagged',
};

export const UNTAGGED_ACCOUNT_GROUP = {
  actorId: null,
  id: ACCOUNTS_GROUP.untagged,
  title: 'Untagged',
};

export const PERMANENT_ACCOUNTS_GROUPS = [
  {
    actorId: null,
    id: FIELD_ACCOUNT_GROUP.system,
    title: 'System',
  },
  {
    actorId: null,
    id: FIELD_ACCOUNT_GROUP.formFields,
    title: 'Form Fields',
  },
];
