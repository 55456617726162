import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Select,
  MenuItem,
  Radio,
  RadioItem,
  Button,
  Modal,
  ModalButtons,
  ModalContent,
  ProgressBar,
  withForm,
  Utils,
} from 'mw-style-react';
import useIntl from 'useIntl';
import AppUtils from '@control-front-end/utils/utils';
import { SHOW_NOTIFY, NOTIFY_LEVEL } from 'constants';
import {
  GET_CONNECTORS,
  ADD_CONNECTOR,
  REMOVE_CONNECTOR,
} from '@control-front-end/common/constants/actorAccounts';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import m from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import l from './AddConnector.scss'; // NOSONAR

function AddConnectorBase(props) {
  const {
    data: { action, debit, credit },
    values,
    visibility,
    onClose,
    isSubmit,
    handleSubmit,
    handleOnChange,
  } = props;
  const t = useIntl();
  const dispatch = useDispatch();
  const [connectors, setConnectors] = useState([]);

  useEffect(() => {
    dispatch({
      type: GET_CONNECTORS.REQUEST,
      callback: (list) => setConnectors(list),
    });
  }, []);

  const renderConnectors = () => {
    if (!connectors.length) {
      return (
        <MenuItem
          styleName="l.connector__empty"
          label={t(mes.connectorsNotFound)}
        />
      );
    }
    return connectors.map((i) => (
      <MenuItem key={i.id} label={i.title} avatar={i.pictureUrl} value={i.id} />
    ));
  };

  return (
    <Modal
      styleName="m.modal"
      size="medium"
      onClose={onClose}
      label={
        action === 'create'
          ? t(mes.addConnector)
          : `${t(mes.replaceConnector)} (${Utils.toUpperLatter(
              values.incomeType
            )})`
      }
      visibility={visibility}
    >
      <ModalContent styleName="m.modal__content">
        <Select
          id="connector"
          styleName="m.select"
          size="large"
          type="autocomplete"
          label={t(mes.selectConnector)}
          bordered={true}
          value={values.connector.id}
          onChange={({ id, value }) => {
            if (!value) return;
            const newConnector = connectors.find((i) => i.id === value);
            handleOnChange({ id, value: newConnector, error: false });
          }}
          popoverOnTop
          padding={4}
        >
          {renderConnectors()}
        </Select>
        <Radio
          id="incomeType"
          styleName="l.connector__type"
          value={values.incomeType}
          align="vertical"
          label={t(mes.selectAccountType)}
          onChange={handleOnChange}
          visibility={action === 'create' ? 'visible' : 'hidden'}
        >
          <RadioItem
            label={t(mes.debit)}
            value="debit"
            visibility={
              (action === 'create' && debit.connector) || debit.formula
                ? 'disabled'
                : 'visible'
            }
          />
          <RadioItem
            label={t(mes.credit)}
            value="credit"
            visibility={
              (action === 'create' && credit.connector) || credit.formula
                ? 'disabled'
                : 'visible'
            }
          />
        </Radio>
      </ModalContent>
      <ModalButtons styleName="m.modal__buttons">
        <Button
          label={
            action === 'create' ? t(mes.addConnector) : t(mes.replaceConnector)
          }
          size="large"
          onClick={handleSubmit}
          visibility={isSubmit || !values.connector.id ? 'disabled' : 'visible'}
        />
        <ProgressBar
          styleName="m.modal__loader"
          type="circle"
          size="small"
          visibility={isSubmit ? 'visible' : 'hidden'}
        />
      </ModalButtons>
    </Modal>
  );
}

AddConnectorBase.propTypes = {
  visibility: PropTypes.bool,
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleOnChange: PropTypes.func,
  isSubmit: PropTypes.bool,
  data: PropTypes.object,
  values: PropTypes.object,
};

const AddConnector = withForm(
  {
    mapPropsToValues(props) {
      const formData = props.data || {};
      return {
        incomeType: formData.incomeType,
        connector: { id: formData.actorId },
      };
    },
    handleSubmit: (values, dispatch, formActions, props) => {
      const { action, debit, credit, key, accountType } = props.data;
      const { incomeType, connector } = values;
      const accountId = values.incomeType === 'debit' ? debit.id : credit.id;
      const addConnector = () => {
        dispatch({
          type: ADD_CONNECTOR.REQUEST,
          payload: { accountId, key, accountType, incomeType, connector },
          callback: () => {
            props.onClose();
            dispatch({
              type: SHOW_NOTIFY.REQUEST,
              payload: {
                id: AppUtils.createRid(),
                type: NOTIFY_LEVEL.SUCCESS,
                label: 'Connector has been successfully added.',
              },
            });
          },
          onError: () => {
            formActions.setSubmitting(false);
          },
        });
      };
      if (action === 'create') {
        addConnector();
      } else {
        dispatch({
          type: REMOVE_CONNECTOR.REQUEST,
          payload: { accountId, key, accountType, incomeType },
          callback: () => {
            addConnector();
          },
        });
      }
    },
  },
  AddConnectorBase
);

export default AddConnector;
