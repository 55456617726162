import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  actorsAccountDetails: {
    id: 'actorsAccountDetails',
    defaultMessage: 'Account details',
  },
  debitAccId: {
    id: 'debitAccId',
    defaultMessage: 'Debit account ID',
  },
  creditAccId: {
    id: 'creditAccId',
    defaultMessage: 'Credit account ID',
  },
  actorsAccountLimits: {
    id: 'actorsAccountLimits',
    defaultMessage: 'Account limits',
  },
  accountMinValue: {
    id: 'accountMinValue',
    defaultMessage: 'Min value',
  },
  accountMaxValue: {
    id: 'accountMaxValue',
    defaultMessage: 'Max value',
  },
});

export default { ...globalIntl, ...m };
