import { useRef, useCallback } from 'react';

const useThrottle = (fn, wait, option = { leading: false }) => {
  const timerId = useRef();
  const lastArgs = useRef();

  // create a memoized debounce
  return useCallback(
    (...args) => {
      const { leading } = option;
      if (!timerId.current && leading) {
        fn.apply(this, args);
      } else {
        lastArgs.current = args;
      }
      const waitFunc = () => {
        if (!leading && lastArgs.current) {
          fn.apply(this, lastArgs.current);
          lastArgs.current = null;
          timerId.current = setTimeout(waitFunc, wait);
        } else {
          timerId.current = null;
        }
      };
      if (!timerId.current) {
        timerId.current = setTimeout(waitFunc, wait);
      }
    },
    [fn, wait, option]
  );
};

export default useThrottle;
