import React from 'react';
import PropTypes from 'prop-types';
import { Label, Icon, Space, Stack, Button } from 'mw-style-react';
import './ErrorBanner.scss';

/**
 * Component for displaying a banner when an actor has been deleted
 */
function ErrorBanner({
  iconType = 'reject',
  mainText,
  extraText,
  button,
  buttonText,
  handleClick,
}) {
  return (
    <div styleName="banner">
      <Stack horizontal size="none">
        <Space styleName="banner__icon" size="small">
          <Icon type={iconType} size="medium" />
        </Space>
        <Space size="medium" left right>
          <Stack size="xxsmall">
            <Label fontSize="large" fontWeight="semibold" value={mainText} />
            <Label fontSize="small" fontWeight="normal" value={extraText} />
          </Stack>
        </Space>
      </Stack>
      {button ? (
        <Button
          type="error"
          label={buttonText}
          fontWeight="normal"
          onClick={handleClick}
        />
      ) : null}
    </div>
  );
}

ErrorBanner.propTypes = {
  iconType: PropTypes.string,
  mainText: PropTypes.string,
  extraText: PropTypes.string,
  button: PropTypes.bool,
  buttonText: PropTypes.string,
  handleClick: PropTypes.func,
};

export default ErrorBanner;
