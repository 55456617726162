import { call, put, takeLatest, select } from 'redux-saga/effects';
import {
  GET_WEBHOOKS,
  CREATE_WEBHOOK,
  DELETE_WEBHOOK,
  UPDATE_WEBHOOK,
  SET_WEBHOOKS_REQ_STATUS,
  RequestStatus,
} from 'constants';
import api from '@control-front-end/common/sagas/api';
import AppUtils from '@control-front-end/utils/utils';

/**
 * Получить список
 */
function* getWebhooks() {
  const accounts = yield select((state) => state.accounts);
  const config = yield select((state) => state.config);
  const accId = accounts.active;
  // Ставим флаг прогресса загрузки
  yield put({ type: SET_WEBHOOKS_REQ_STATUS, payload: 'inProgress' });
  const { result: resultApp, data: dataApp } = yield call(api, {
    method: 'get',
    url: `/webhooks/actions/${accId}`,
  });
  if (resultApp !== RequestStatus.SUCCESS) return;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/webhooks/${accId}`,
  });
  data.data.forEach((user) => {
    user.ownerAvatar = AppUtils.makeUserAvatar(user, config);
    if (user.ownerId === 0) user.ownerAvatar = config.systemUserAvatar;
  });
  // Ставим флаг окончания загрузки
  yield put({ type: SET_WEBHOOKS_REQ_STATUS, payload: 'success' });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({
    type: GET_WEBHOOKS.SUCCESS,
    payload: {
      list: data.data,
      actions: dataApp.data,
    },
  });
}

/**
 * Создать
 */
function* createWebhook({ payload, formActions }) {
  const accounts = yield select((state) => state.accounts);
  const { list } = yield select((state) => state.webhooks);
  const config = yield select((state) => state.config);
  const accId = accounts.active;
  const url = `/webhooks/${accId}`;
  const { result, data } = yield call(api, {
    method: 'post',
    url,
    body: payload,
  });

  if (formActions) formActions.setSubmitting(false);
  if (result !== RequestStatus.SUCCESS) return;
  if (formActions) formActions.onClose();
  data.data.ownerAvatar = AppUtils.makeUserAvatar(data.data, config);
  const newList = list.slice();
  newList.unshift(data.data);
  yield put({
    type: CREATE_WEBHOOK.SUCCESS,
    payload: {
      list: newList,
    },
  });
}

/**
 * Обновить
 */
function* updateWebhook({ payload, formActions }) {
  const { id } = payload;
  const { list } = yield select((state) => state.webhooks);
  const url = `/webhooks/${id}`;
  const { result, data } = yield call(api, {
    method: 'put',
    url,
    body: payload,
  });

  if (formActions) formActions.setSubmitting(false);
  if (result !== RequestStatus.SUCCESS) return;
  if (formActions) formActions.onClose();
  const newList = structuredClone(list);
  const index = newList.findIndex((i) => i.id === id);
  const oldKey = newList[index];
  const { title, actions } = data.data;
  newList[index] = Object.assign(oldKey, { title, actions });
  yield put({
    type: UPDATE_WEBHOOK.SUCCESS,
    payload: {
      list: newList,
    },
  });
}

/**
 * Удалить
 */
function* deleteWebhook({ payload }) {
  const { id } = payload;
  const { list } = yield select((state) => state.webhooks);
  const { result } = yield call(api, {
    method: 'delete',
    url: `/webhooks/${id}`,
  });

  if (result !== 'success') return;
  const newList = list.slice();
  const index = newList.findIndex((i) => i.id === id);
  newList.splice(index, 1);
  yield put({
    type: DELETE_WEBHOOK.SUCCESS,
    payload: {
      list: newList,
    },
  });
}

function* webhooks() {
  yield takeLatest(GET_WEBHOOKS.REQUEST, getWebhooks);
  yield takeLatest(CREATE_WEBHOOK.REQUEST, createWebhook);
  yield takeLatest(UPDATE_WEBHOOK.REQUEST, updateWebhook);
  yield takeLatest(DELETE_WEBHOOK.REQUEST, deleteWebhook);
}

export default webhooks;
