import { select, throttle } from 'redux-saga/effects';
import AppUtils from '@control-front-end/utils/utils';
import { MAKE_SOUND, SOUND_CATEGORIES } from 'constants';

/**
 * Play sound
 */
function* makeSound({ payload: { type } }) {
  const { sound } = yield select((state) => state.settings);

  // Make sure you are enabled graph sounds as a separate setting
  if (SOUND_CATEGORIES.graphActions.includes(type) && !sound) return;

  AppUtils.makeSound(type);
}

function* soundSaga() {
  yield throttle(10, MAKE_SOUND, makeSound);
}

export default soundSaga;
