import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  displayedColumns: {
    id: 'displayedColumns',
    defaultMessage: 'Columns to display in actors bag',
  },
  selectDisplayedFields: {
    id: 'selectDisplayedFields',
    defaultMessage: 'Select field name to display',
  },
  selectAccount: {
    id: 'selectAccount__VisibleColumns',
    defaultMessage: 'Select account',
  },
});

export default { ...globalIntl, ...m };
