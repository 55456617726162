import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Chip } from 'mw-style-react';
import FormUtils from '@control-front-end/utils/formUtils';
import CollapsableList from '@control-front-end/app/src/components/CollapsableList';

function FieldValues({ value = [], ...rest }) {
  const accId = useSelector((state) => state.accounts.active);

  const actorItems = useMemo(
    () =>
      (Array.isArray(value) ? value : [value]).map((item) =>
        item.type === 'actor' ? (
          <div key={item.value} onClick={(e) => e.stopPropagation()}>
            {FormUtils.getActorChip(item, accId)}
          </div>
        ) : (
          <Chip
            key={item.value}
            unspaced
            size="small"
            type="rectangular"
            fontWeight="normal"
            closeIcon={false}
            label={item.title}
          />
        )
      ),
    [value]
  );

  return actorItems.length ? (
    <CollapsableList value={actorItems} {...rest} />
  ) : null;
}

FieldValues.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default FieldValues;
