import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import {
  Modal,
  ModalButtons,
  ModalContent,
  Button,
  ProgressBar,
  withForm,
  Label,
  TextField,
  Checkbox,
  Utils,
} from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import Yup from 'yup';
import { CREATE_WEBHOOK, UPDATE_WEBHOOK } from 'constants';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import sModal from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import sLocal from './CreateWebhook.scss'; // NOSONAR

class CreateWebhook extends PureComponent {
  constructor(props) {
    super(props);
    this.handleOnActionChange = this.handleOnActionChange.bind(this);
  }

  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  handleOnActionChange({ id, value }) {
    const { values, handleOnChange } = this.props;
    const selected = values.actions.slice();

    const index = selected.indexOf(id);
    // добавить
    if (value && index === -1) {
      selected.push(id);
    }
    // удалить
    if (!value && index !== -1) {
      selected.splice(index, 1);
    }
    handleOnChange({ id: 'actions', value: selected });
  }

  render() {
    const {
      values,
      data,
      errors,
      webhooks,
      visibility,
      onClose,
      handleSubmit,
      handleOnChange,
      isSubmit,
    } = this.props;
    const editMode = data && data.id;
    return (
      <Modal
        styleName="sModal.modal"
        visibility={visibility}
        onClose={onClose}
        label={editMode ? this.i(mes.updateWebhook) : this.i(mes.createWebhook)}
        size="large"
      >
        <ModalContent styleName="sModal.modal__content sLocal.content">
          <div styleName="sLocal.row">
            <div>
              <Label
                value={this.i(mes.enterWebhookUrl1)}
                fontWeight="semibold"
              />
              &nbsp;
              <Label
                value={this.i(mes.enterWebhookUrl2)}
                styleName="sLocal.row__label__disabled"
              />
            </div>
            <TextField
              styleName="sModal.textfield sLocal.row__field"
              autoFocus={true}
              id="url"
              label=""
              placeholder={this.i(mes.enterWebhookUrlPlaceholder)}
              bordered={true}
              size="large"
              value={values.url || ''}
              helperText={errors.url ? this.i(mes[errors.url]) : ''}
              error={!!errors.url}
              onChange={handleOnChange}
              visibility={editMode ? 'disabled' : 'visible'}
            />
          </div>
          <div styleName="sLocal.row">
            <div styleName="sLocal.row__label">
              <Label value={this.i(mes.actions1)} fontWeight="semibold" />
              &nbsp;
              <Label
                value={this.i(mes.actions2)}
                styleName="sLocal.row__label__disabled"
              />
              {errors.actions ? (
                <Label
                  styleName="sLocal.row__label__helper"
                  value={this.i(mes[errors.actions])}
                  fontSize="small"
                  error={true}
                />
              ) : null}
            </div>
            <div styleName="sLocal.row__container">
              {webhooks.actions.map((i, index) => (
                <Checkbox
                  styleName="sLocal.row__check"
                  key={index}
                  id={i.name}
                  value={values.actions.includes(i.name)}
                  onChange={this.handleOnActionChange}
                  error={!!errors.actions}
                >
                  <div styleName="sLocal.row__check__item">
                    <Label
                      styleName="sLocal.row__check__item__label"
                      fontWeight="semibold"
                      value={Utils.toUpperLatter(i.name)}
                    />
                    <Label
                      styleName="sLocal.row__label__disabled"
                      value={i.description}
                    />
                  </div>
                </Checkbox>
              ))}
            </div>
          </div>
        </ModalContent>
        <ModalButtons styleName="sModal.modal__buttons">
          <Button
            label={
              editMode ? this.i(mes.updateWebhook) : this.i(mes.createWebhook)
            }
            size="large"
            onClick={handleSubmit}
            visibility={isSubmit ? 'disabled' : 'visible'}
          />
          <ProgressBar
            styleName="sModal.modal__loader"
            type="circle"
            size="small"
            visibility={isSubmit ? 'visible' : 'hidden'}
          />
        </ModalButtons>
      </Modal>
    );
  }
}

const FormCreateWebhook = withForm(
  {
    mapPropsToValues(props) {
      const formData = props.data || {};
      return {
        url: formData.url,
        actions: formData.actions || [],
      };
    },
    yup: Yup.object().shape({
      url: Yup.string().required('field_required').url('valid_url'),
      actions: Yup.array().required('field_required'),
    }),
    handleSubmit: (values, dispatch, formActions, props) => {
      formActions.onClose = props.onClose;
      const payload = { ...values };
      payload.id = props.data ? props.data.id : null;
      dispatch({
        type: payload.id ? UPDATE_WEBHOOK.REQUEST : CREATE_WEBHOOK.REQUEST,
        payload,
        formActions,
      });
    },
  },
  CreateWebhook
);

CreateWebhook.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleOnChange: PropTypes.func,
  dispatch: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  isSubmit: PropTypes.bool,
  values: PropTypes.object,
  errors: PropTypes.object,
  webhooks: PropTypes.object,
  data: PropTypes.object,
};

CreateWebhook.contextTypes = {
  intl: intlShape,
};

export default injectIntl(FormCreateWebhook);
