import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  keyValue: {
    id: 'keyValue',
    defaultMessage: 'Key - Value',
  },
  codeEditor: {
    id: 'codeEditor',
    defaultMessage: 'Code editor',
  },
  addParameter: {
    id: 'addParameter',
    defaultMessage: 'Add parameter',
  },
});

export default { ...globalIntl, ...m };
