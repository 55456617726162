import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import {
  TextField,
  Select,
  MenuItem,
  Autocomplete,
  AutocompleteItem,
  Chip,
} from 'mw-style-react';
import { FIELD_TYPE } from '@control-front-end/common/constants/forms';

function MultiSelectItem({ value, options: optionsProp, onChange, ...rest }) {
  const options = optionsProp || [];
  const optionsMap = useMemo(() => keyBy(options, 'value'), [options]);

  const valuesMap = keyBy(value, 'value');

  const [searchString, setSearchString] = useState('');

  const handleListItemClick = ({ id }) => {
    onChange({
      value: Object.values({
        ...valuesMap,
        [id]: optionsMap[id],
      }),
    });
    setSearchString('');
  };

  const renderSearchResult = () => {
    const searchRegExp = new RegExp(searchString, 'gi');

    const items = options
      .filter(
        (item) => !valuesMap[item.value] && item.title.match(searchRegExp)
      )
      .map((item, index) => (
        <AutocompleteItem
          /**
           * TODO: Get back to {item.value} as React key after issue with duplicated values will be resolved
           * Currently, it is allowed to transfer item that already exist in formField value
           */
          key={index}
          value={item.title}
          id={item.value}
          size="small"
          onClick={handleListItemClick}
        />
      ));

    return (
      <div>{items.length ? items : <AutocompleteItem value="------" />}</div>
    );
  };

  const handleChipCloseClick = (value) => {
    onChange({ value: Object.values(omit(valuesMap, [value])) });
  };

  return (
    <Autocomplete
      color={TextField.COLOR.white}
      placeholder="Enter value"
      value={searchString}
      size="large"
      onChange={({ value }) => setSearchString(value)}
      chipsItems={() =>
        Object.values(valuesMap).map((item) => (
          <Chip
            key={item.value}
            label={item.title}
            type="rectangular"
            size="small"
            closeClick={() => handleChipCloseClick(item.value)}
          />
        ))
      }
      searchResult={renderSearchResult}
      popoverOnTop
      bordered
      unspaced
      {...rest}
    />
  );
}

MultiSelectItem.defaultProps = {
  options: [],
};

MultiSelectItem.propTypes = {
  options: PropTypes.array,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

function SelectItem({ value, options, onChange, ...rest }) {
  const optionsMap = useMemo(() => keyBy(options, 'value'), [options]);

  return (
    <Select
      visibility={options.length > 1 ? 'visible' : 'disabled'}
      bordered
      unspaced
      resettable
      color={Select.COLOR.white}
      size="large"
      placeholder="Enter value"
      value={value[0]?.title || ''}
      onChange={({ value }) => onChange({ value: optionsMap[value] || [] })}
      popoverOnTop
      {...rest}
    >
      {options.map(({ value, title }) => (
        <MenuItem key={value} value={value} label={title} size="large" />
      ))}
    </Select>
  );
}

SelectItem.defaultProps = {
  options: [],
};

SelectItem.propTypes = {
  options: PropTypes.array,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

const _ITEMS_ = {
  [FIELD_TYPE.radio]: SelectItem,
  [FIELD_TYPE.select]: SelectItem,
  [FIELD_TYPE.multiSelect]: MultiSelectItem,
};

function FieldValue({ fieldClass = FIELD_TYPE.select, ...rest }) {
  const Item = _ITEMS_[fieldClass];

  return Item ? <Item {...rest} /> : null;
}

FieldValue.propTypes = {
  fieldClass: PropTypes.oneOf(Object.keys(_ITEMS_)),
};

export default FieldValue;
