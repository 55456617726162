import React from 'react';
import { useSelector } from 'react-redux';
import CDU from 'control-cdu';
import { Card, Stack, Icon, Label, Space, Utils } from 'mw-style-react';
import { ErrorBoundary } from 'react-error-boundary';

import ActorAvatar from '@control-front-end/common/components/ActorAvatar';
import AppUtils from '@control-front-end/utils/utils';
import { APP_AUTH_KEY } from 'constants';

import scss from './ScriptWidget.scss';

const MemoCDU = React.memo(CDU, () => {
  return true;
});

function ScriptWidget({
  rootRef,
  dragRef,
  onClose,
  title,
  accId,
  scriptRef,
  formType,
  formTitle,
  pictureUrl,
  allColors,
  status,
  color,
  actorId,
}) {
  const config = useSelector((state) => state.config);
  const textColor = color ? AppUtils.getContrastYIQ(color) : 'white';
  return (
    <Card
      fullHeight
      fullWidth
      forwardRef={rootRef}
      borderRadius="large"
      withBorder={false}
      boxShadow="intense"
      className={scss.scriptCard}
    >
      <Space
        size="small"
        forwardRef={dragRef}
        className={scss.header}
        style={{ backgroundColor: color }}
      >
        <Stack.H fullWidth alignItems="center" justifyContent="spaceBetween">
          <Stack.H alignItems="center">
            <Icon size="large" type="drag" colorType="white" />
            <ActorAvatar
              size="small"
              type="compact"
              formType={formType}
              formTitle={formTitle}
              pictureUrl={pictureUrl}
              colors={allColors}
              status={status}
              colorFilled
            />
            <Label value={title} overflow="cut" color={textColor} />
          </Stack.H>
          <Icon
            size="small"
            className={scss.close}
            type="close"
            colorType="white"
            onClick={onClose}
          />
        </Stack.H>
      </Space>
      <Stack fullWidth className={scss.widgetContent}>
        {accId ? (
          <ErrorBoundary
            FallbackComponent={({ error }) => (
              <Space size="medium">
                <Stack.H fullWidth alignItems="center">
                  <Icon type="alert" error />
                  <Label value={error.message} color="red" />
                </Stack.H>
              </Space>
            )}
          >
            <MemoCDU
              auth={Utils.fromStorage(APP_AUTH_KEY)}
              workspace={accId}
              app={scriptRef}
              context={{ actorId }}
              stage="production"
              page="index"
              widgetUrl={config.widgetUrl}
            />
          </ErrorBoundary>
        ) : null}
      </Stack>
    </Card>
  );
}

export default ScriptWidget;
