import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  addSection: {
    id: 'addSection',
    defaultMessage: '+ Add new section',
  },
  section: {
    id: 'section',
    defaultMessage: 'Section',
  },
  addTitle: {
    id: 'addTitle',
    defaultMessage: 'Field title',
  },
  addLabel: {
    id: 'addLabel',
    defaultMessage: 'Label',
  },
  addOption: {
    id: 'addOption',
    defaultMessage: '+ Add option',
  },
  answerOption: {
    id: 'answerOption',
    defaultMessage: 'Answer option',
  },
  calendarConstr: {
    id: 'calendarConstr',
    defaultMessage: 'Day, month, year',
  },
  checkPlaceholder: {
    id: 'checkPlaceholder',
    defaultMessage: 'Checkbox label',
  },
  linkTitle: {
    id: 'linkTitle',
    defaultMessage: 'Link title',
  },
  linkUrl: {
    id: 'linkUrl',
    defaultMessage: 'Link URL',
  },
  imageTitle: {
    id: 'imageTitle',
    defaultMessage: 'Image title',
  },
  imageUrl: {
    id: 'imageUrl',
    defaultMessage: 'Image URL',
  },
  buttonLabel: {
    id: 'buttonLabel',
    defaultMessage: 'Button label',
  },
  itemClass_edit: {
    id: 'itemClass_edit',
    defaultMessage: 'Text field',
  },
  itemClass_select: {
    id: 'itemClass_select',
    defaultMessage: 'Dropdown',
  },
  itemClass_multiSelect: {
    id: 'itemClass_multiSelect',
    defaultMessage: 'MultiSelect',
  },
  itemClass_label: {
    id: 'itemClass_label',
    defaultMessage: 'Text paragraph',
  },
  itemClass_link: {
    id: 'itemClass_link',
    defaultMessage: 'Link',
  },
  itemClass_radio: {
    id: 'itemClass_radio',
    defaultMessage: 'Multiple choice',
  },
  itemClass_image: {
    id: 'itemClass_image',
    defaultMessage: 'Image',
  },
  itemClass_upload: {
    id: 'itemClass_upload',
    defaultMessage: 'File upload',
  },
  itemClass_check: {
    id: 'itemClass_check',
    defaultMessage: 'Checkbox',
  },
  itemClass_calendar: {
    id: 'itemClasss_calendar',
    defaultMessage: 'Calendar',
  },
  itemClass_button: {
    id: 'itemClass_button',
    defaultMessage: 'Button',
  },
  addItemDown: {
    id: 'addItemDown',
    defaultMessage: 'Add item below',
  },
  addItemRow: {
    id: 'addItemRow',
    defaultMessage: 'Add item in the row',
  },
  required: {
    id: 'required',
    defaultMessage: 'Required',
  },
  item: {
    id: 'item',
    defaultMessage: 'Item',
  },
  enterSectionTitle: {
    id: 'enterSectionTitle',
    defaultMessage: 'Enter section title',
  },
  itemIdCopied: {
    id: 'itemIdCopied',
    defaultMessage: 'ID has been copied to clipboard',
  },
  notUniqueId: {
    id: 'notUniqueId',
    defaultMessage: 'ID must be unique',
  },
  selectParentForm: {
    id: 'selectParentForm',
    defaultMessage: 'Parent Form:',
  },
  noParentForUAT: {
    id: 'noParentForUAT',
    defaultMessage: "UAT form can't have parent",
  },
  moveItemUp: {
    id: 'moveItemUp',
    defaultMessage: 'Move field up',
  },
  moveItemDown: {
    id: 'moveItemDown',
    defaultMessage: 'Move field down',
  },
  moveSectionUp: {
    id: 'moveSectionUp',
    defaultMessage: 'Move section up',
  },
  moveSectionDown: {
    id: 'moveSectionDown',
    defaultMessage: 'Move section down',
  },
  formRef: {
    id: 'formRef',
    defaultMessage: 'Reference ID:',
  },
});

export default { ...globalIntl, ...m };
