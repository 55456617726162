import { defineMessages } from 'react-intl';

const m = defineMessages({
  startImport: {
    id: 'startImport_AccessRulesMapperWarningModal',
    defaultMessage: 'Start import',
  },
  warningTextPart1: {
    id: 'warningTextPart1_AccessRulesMapperWarningModal',
    defaultMessage:
      'The imported file contains access settings for items. You can map access rules accordingly.',
  },
  warningTextPart2: {
    id: 'warningTextPart2_AccessRulesMapperWarningModal',
    defaultMessage:
      // eslint-disable-next-line max-len
      'Please ensure that you have mapped access rules accordingly before importing, otherwise all relevant access rights will be granted to the user who initiates the import.',
  },
  groups: {
    id: 'groups_AccessRulesMapperWarningModal',
    defaultMessage: 'Groups ({amount})',
  },
  users: {
    id: 'users_AccessRulesMapperWarningModal',
    defaultMessage: 'Users ({amount})',
  },
  scripts: {
    id: 'scripts_AccessRulesMapperWarningModal',
    defaultMessage: 'Scripts ({amount})',
  },
  startMapping: {
    id: 'startMapping_AccessRulesMapperWarningModal',
    defaultMessage: 'Mapping access rules and proceed',
  },
  proceed: {
    id: 'proceed_AccessRulesMapperWarningModal',
    defaultMessage: 'Proceed without mapping',
  },
});

export default m;
