import { RequestStatus } from 'constants';
import {
  GET_ACTORS_ACCOUNTS,
  CREATE_ACTORS_ACCOUNT,
  REMOVE_ACTORS_ACCOUNT,
  UPDATE_ACTORS_ACCOUNT,
  LOCK_ACTORS_ACCOUNT,
  SET_ACTORS_ACCOUNTS_REQ_STATUS,
  CLEAR_ACTORS_ACCOUNTS,
  CREATE_TRANSACTION,
  ADD_CONNECTOR,
  SET_ACCOUNT_FORMULA,
  REMOVE_CONNECTOR,
  TRANSFER_ACCOUNTS,
  GET_ACTORS_ACCOUNTS_GROUPS,
} from '@control-front-end/common/constants/actorAccounts';
import { createReducer } from '@reduxjs/toolkit';

// ------------------------------------
// Reducer
// ------------------------------------

const initState = {
  plan: [],
  fact: [],
  settings: {},
  limit: 20,
  offset: 0,
  total: 0,
  endList: false,
  // Object used to manage requests statues for all accountType independently
  reqStatuses: {
    plan: RequestStatus.SUCCESS,
    fact: RequestStatus.SUCCESS,
  },
  reqStatus: RequestStatus.SUCCESS,
};

export default createReducer(initState, {
  [GET_ACTORS_ACCOUNTS_GROUPS.SUCCESS](state, action) {
    return { ...initState, groups: action.payload };
  },
  [GET_ACTORS_ACCOUNTS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [CREATE_ACTORS_ACCOUNT.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [REMOVE_ACTORS_ACCOUNT.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [UPDATE_ACTORS_ACCOUNT.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [LOCK_ACTORS_ACCOUNT.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [CREATE_TRANSACTION.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [ADD_CONNECTOR.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SET_ACCOUNT_FORMULA.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [REMOVE_CONNECTOR.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [TRANSFER_ACCOUNTS.SUCCESS](state, action) {
    return { ...state, ...action.payload };
  },
  [SET_ACTORS_ACCOUNTS_REQ_STATUS](state, { payload }) {
    // In case when we want to set all requset status fileds at once
    if (typeof payload === 'string') {
      return {
        ...state,
        reqStatus: payload,
        reqStatuses: { plan: payload, fact: payload },
      };
    }

    /**
     * Otherwise update each separate and general request statuses.
     * General reqStatus field can be SUCCESS only if all specific request statuses are also SUCCESS
     */
    const reqStatuses = { ...state.reqStatuses, ...payload };

    const nonSuccessStatus = Object.values(reqStatuses).find(
      (item) => item !== RequestStatus.SUCCESS
    );

    return {
      ...state,
      reqStatus: nonSuccessStatus || RequestStatus.SUCCESS,
      reqStatuses,
    };
  },
  [CLEAR_ACTORS_ACCOUNTS]() {
    return initState;
  },
});
