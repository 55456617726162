function similarText(first, second, percent) {
  if (
    first === null ||
    second === null ||
    typeof first === 'undefined' ||
    typeof second === 'undefined'
  ) {
    return 0;
  }
  first += '';
  second += '';
  let pos1 = 0;
  let pos2 = 0;
  let max = 0;
  const firstLength = first.length;
  const secondLength = second.length;
  let p;
  let q;
  let l;
  let sum;
  for (p = 0; p < firstLength; p += 1) {
    for (q = 0; q < secondLength; q += 1) {
      for (
        l = 0;
        p + l < firstLength &&
        q + l < secondLength &&
        first.charAt(p + l) === second.charAt(q + l);
        l += 1
      ) {
        // eslint-disable-line max-len
        // break up this crazy for loop and put the logic in its body
      }
      if (l > max) {
        max = l;
        pos1 = p;
        pos2 = q;
      }
    }
  }
  sum = max;
  if (sum) {
    if (pos1 && pos2) {
      sum += similarText(first.substr(0, pos1), second.substr(0, pos2));
    }
    if (pos1 + max < firstLength && pos2 + max < secondLength) {
      sum += similarText(
        first.substr(pos1 + max, firstLength - pos1 - max),
        second.substr(pos2 + max, secondLength - pos2 - max)
      );
    }
  }
  if (!percent) {
    return sum;
  }
  return (sum * 200) / (firstLength + secondLength);
}

export default { similarText };
