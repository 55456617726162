import { call, put, select, takeLatest } from 'redux-saga/effects';
import api from '@control-front-end/common/sagas/api';
import AppUtils from '@control-front-end/utils/utils';
import { pick } from 'lodash';
import {
  ACCOUNTS,
  SEARCH_ACCOUNTS,
  GET_ACTIVE_ACC,
  SET_NEW_ACC,
  RequestStatus,
  ACCOUNT_CREATE,
} from '../constants';

/**
 * Получить активный аккаунт
 */
function* getActiveAccount({ payload, callback }) {
  const { query } = payload;
  const request = {
    method: 'get',
    url: `/workspaces/active`,
  };
  if (query) {
    request.queryParams = { query };
  }
  const { result, data } = yield call(api, request);
  if (result !== RequestStatus.SUCCESS) return;
  yield put({ type: GET_ACTIVE_ACC.SUCCESS, payload: data.data });
  if (callback) callback();
}

function* setAccountsList(payload, newAccId) {
  const {
    list,
    limit = 10,
    offset = 0,
    endList = false,
    loadMore = false,
  } = payload;
  yield put({
    type: ACCOUNTS.SUCCESS,
    payload: { list: list.slice(), limit, offset, endList },
  });
  if (endList || loadMore) return;
  if (newAccId) {
    yield call(getActiveAccount, { payload: { query: newAccId } });
    yield put({ type: SET_NEW_ACC, payload: newAccId });
  }
}

/**
 * Get workspaces list
 */
function* getAccounts({ payload = {}, callback }) {
  const { loadMore = false } = payload;
  const accountsState = yield select((state) => state.accounts);
  if (loadMore && accountsState.endList) return;
  const queryParams = pick({ ...accountsState, ...payload }, [
    'limit',
    'offset',
    'withStats',
  ]);
  const { result, data } = yield call(api, {
    method: 'get',
    url: '/workspaces',
    queryParams,
  });
  if (result !== RequestStatus.SUCCESS) return;
  const accountsList = loadMore ? structuredClone(accountsState.list) : [];
  const newList = data.data.list || data.data;
  accountsList.push(...newList);
  if (data.data.length || loadMore) {
    yield call(setAccountsList, {
      list: accountsList,
      offset: queryParams.offset + queryParams.limit,
      endList: newList.length < queryParams.limit,
      loadMore,
    });
  }
  if (callback) callback(data.data);
}

/**
 * Искать аккаунт по части имени
 */
function* searchAccounts({ payload, callback }) {
  const { query, limit = 15, offset = 0, withStats = false } = payload;
  const searchQ = AppUtils.safeSearchQuery(query);
  if (!searchQ.length) return;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/workspaces/search/${searchQ}`,
    queryParams: { limit, offset, withStats },
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({ type: SEARCH_ACCOUNTS.SUCCESS, payload: data.data });
  if (callback) callback(data.data);
}

/**
 * Создать аккаунт
 */
function* createAccount({ payload, formActions, callback }) {
  const { result, data } = yield call(api, {
    method: 'post',
    url: '/workspaces',
    body: payload,
  });
  if (formActions) formActions.setSubmitting(false);
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback(data.data);
}

function* accounts() {
  yield takeLatest(ACCOUNTS.REQUEST, getAccounts);
  yield takeLatest(SEARCH_ACCOUNTS.REQUEST, searchAccounts);
  yield takeLatest(GET_ACTIVE_ACC.REQUEST, getActiveAccount);
  yield takeLatest(ACCOUNT_CREATE.REQUEST, createAccount);
}

export default accounts;
