import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  createNewCurrency: {
    id: 'createNewCurrency',
    defaultMessage: 'Create new currency',
  },
  editCurrency: {
    id: 'editCurrency',
    defaultMessage: 'Edit currency',
  },
  currencySymbol: {
    id: 'currencySymbol',
    defaultMessage: 'Currency symbol',
  },
  precision: {
    id: 'precision',
    defaultMessage: 'Precision',
  },
  currencyType_number: {
    id: 'currencyType_number',
    defaultMessage: 'Number',
  },
  currencyType_percent: {
    id: 'currencyType_percent',
    defaultMessage: 'Percent',
  },
  currencyType_currency: {
    id: 'currencyType_currency',
    defaultMessage: 'Currency',
  },
  currencyType_dateTime: {
    id: 'currencyType_dateTime',
    defaultMessage: 'Date & Time',
  },
  currencyType_seconds: {
    id: 'currencyType_seconds',
    defaultMessage: 'Seconds',
  },
});

export default { ...globalIntl, ...m };
