import React from 'react';
import PropTypes from 'prop-types';
import ClampLines from 'react-clamp-lines';
import { useIntl } from 'hooks';
import mes from './intl';
import scss from './CollapsibleText.scss';

/**
 * Component for multiline text display with ellipsis with 'Show more' button
 * @param id
 * @param text
 * @param lines
 * @param innerElement
 * @param buttons
 * @param props (see https://github.com/zoltantothcom/react-clamp-lines#props to use all available props)
 * @returns {Element}
 * @constructor
 */
function CollapsibleText({ id, text, lines, innerElement, buttons, ...props }) {
  const t = useIntl();

  return (
    <ClampLines
      className={scss.collabsibleText}
      id={id}
      text={text}
      lines={lines}
      ellipsis="..."
      innerElement={innerElement}
      moreText={t(mes.showMore)}
      lessText={t(mes.showLess)}
      buttons={buttons}
      stopPropagation={true}
      {...props}
    />
  );
}

CollapsibleText.defaultProps = {
  lines: 3,
  innerElement: 'div',
  buttons: true,
};

CollapsibleText.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
export default CollapsibleText;
