import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Modal, Stack, Space, Label, Button } from 'mw-style-react';
import { useIntl } from 'hooks';
import m from './intl';

function ConfirmWorkspaceExportModal({ onClose, data: { onSubmit } }) {
  const t = useIntl();

  const workspaceName = useSelector(
    (state) => state.accounts?.activeAccount.name
  );

  const handleClose = useCallback(() => onClose(), []);

  const handleSubmit = useCallback(() => {
    onClose();
    onSubmit();
  }, []);

  return (
    <Modal size="medium" onClose={handleClose} label={t(m.title)}>
      <Space size="large">
        <Stack size="large">
          <Stack size="xsmall">
            <Label
              color={Label.COLOR.black}
              fontWeight="semibold"
              value={t(m.warning, { workspaceName })}
            />
            <Label color={Label.COLOR.black} value={t(m.warningInfo)} />
          </Stack>
          <Button
            fullWidth
            onClick={handleSubmit}
            label={t(m.submitButtonText)}
          />
        </Stack>
      </Space>
    </Modal>
  );
}

ConfirmWorkspaceExportModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
  }),
};

export default ConfirmWorkspaceExportModal;
