import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  newEvents: {
    id: 'newEvents',
    defaultMessage: 'new events',
  },
  evOwner: {
    id: 'evOwner',
    defaultMessage: 'events owner',
  },
  tipAccOwner: {
    id: 'tipAccOwner',
    defaultMessage: 'Event from workspace owner',
  },
  tipExecutor: {
    id: 'tipExecutor',
    defaultMessage: 'You have to execute or sign this event',
  },
  tipOverdue: {
    id: 'tipOverdue',
    defaultMessage: 'The event is overdue',
  },
  notification: {
    id: 'notification',
    defaultMessage: 'notification',
  },
  clickMapLocation: {
    id: 'clickMapLocation',
    defaultMessage: 'Click to open the map',
  },
});

export default { ...globalIntl, ...m };
