import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Utils } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import { getItemAvailableFields, setDefaultValue } from './SetDefaultValue';

/**
 * Все обработчики конструктора форм
 * @param CreateForm
 * @returns {FormActions}
 */
export default function formActions(CreateForm) {
  class FormActions extends Component {
    constructor(props) {
      super(props);
      const DEFAULT_FORM = {
        title: 'Unnamed Form',
        description: '',
        sections: [
          {
            content: [
              {
                id: this.makeItemID('item'),
                key: AppUtils.udid(),
                idNotChanged: true,
                class: 'edit',
              },
            ],
          },
        ],
        settings: {},
        color: AppUtils.getRandomPastelColor(),
      };

      this.state = {
        id: 'init',
        showPanel: false,
        form: Object.keys(props.formData).length
          ? this.fixRowItemsOrder(props.formData)
          : DEFAULT_FORM,
        parentId: props.parentId || null,
        formRef: props.formData.ref || null,
        activeSection: props.activeSection || 0,
        activeItem: props.activeItem || 0,
        access: props.access || [],
        actorsAccess: props.actorsAccess || [],
        formAccounts: props.formAccounts || [],
        isChanged: false,
      };
      this.handleTogglePanel = this.handleTogglePanel.bind(this);
      this.handleOnChangeItem = this.handleOnChangeItem.bind(this);
      this.handleChangeParentId = this.handleChangeParentId.bind(this);
      this.handleChangeRef = this.handleChangeRef.bind(this);
      this.handelOnActive = this.handelOnActive.bind(this);
      this.handleSortSection = this.handleSortSection.bind(this);
      this.handleRenameSection = this.handleRenameSection.bind(this);
      this.handleAddSection = this.handleAddSection.bind(this);
      this.handleRemoveSection = this.handleRemoveSection.bind(this);
      this.handleAddItem = this.handleAddItem.bind(this);
      this.handleRemoveItem = this.handleRemoveItem.bind(this);
      this.handleSortItem = this.handleSortItem.bind(this);
      this.handleFormField = this.handleFormField.bind(this);
      this.handleChangeAccess = this.handleChangeAccess.bind(this);
      this.handleChangeActorsAccess = this.handleChangeActorsAccess.bind(this);
      this.handleChangeAccounts = this.handleChangeAccounts.bind(this);
      this.getForm = this.getForm.bind(this);
      this.setFlagSaved = this.setFlagSaved.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
      const { actorsAccess, formAccounts } = props;
      if (
        !state.actorsAccess.length &&
        actorsAccess.length !== state.actorsAccess.length
      ) {
        return { actorsAccess };
      }
      if (
        !state.formAccounts.length &&
        formAccounts.length !== state.formAccounts.length
      ) {
        return { formAccounts };
      }
      return null;
    }

    componentDidUpdate(prevProps, prevState) {
      if (!prevState.isChanged && this.state.isChanged) {
        this.props.onContentChange();
      }
    }

    // Выравниваем порядо элементов строк, чтобы они были в списки один под другим
    fixRowItemsOrder(f) {
      const form = structuredClone(f);
      form.sections.forEach((section) => {
        const newItems = [];
        const addedRows = [];
        const items = section.content;
        items.forEach((item) => {
          item.key = AppUtils.udid();
          if (!item.row) {
            newItems.push(item);
          } else if (!addedRows.includes(item.row)) {
            const rowItems = items.filter((i) => i.row === item.row);
            addedRows.push(item.row);
            newItems.push(...rowItems);
          }
        });
        section.content = newItems;
      });
      return form;
    }

    // Создать id элемента
    makeItemID(className) {
      return `${className}_${AppUtils.udid()}`;
    }

    // Открыть закрыть панель
    handleTogglePanel() {
      this.setState((state) => ({ showPanel: !state.showPanel }));
    }

    // Обновить поля формы
    handleFormField({ id, value }) {
      const { form } = this.state;
      form[id] = value;
      this.setState({ form, isChanged: true });
    }

    // Установить поля по умолчанию для item
    setFieldDefValues() {
      const { form, activeSection, activeItem } = this.state;
      const newForm = setDefaultValue({ form, activeSection, activeItem });
      this.setState({ form: newForm, isChanged: true });
    }

    _getCorrectValue(item) {
      const valueType = {
        multiSelect: 'array',
        calendar: 'object',
        check: 'boolean',
      };
      if (valueType[item.class]) {
        switch (valueType[item.class]) {
          case 'array':
            return Array.isArray(item.value) ? item.value : [];
          case 'object':
            return typeof item.value === 'object' ? item.value : {};
          case 'boolean':
            return typeof item.value === 'boolean' ? item.value : false;
          default:
            return item.value;
        }
      }
      return item.value;
    }

    // Удалить лишние поля с items
    _clearExtraItemFields(item) {
      const exFields = getItemAvailableFields(item);
      exFields.push('row');
      for (const key of Object.keys(item)) {
        if (key === 'class') continue;
        if (!exFields.includes(key)) delete item[key];
      }
      item.value = this._getCorrectValue(item);
      delete item.extra;
      return item;
    }

    // Сформировать модель счетов по умолчанию
    _makeDefaultAccounts() {
      const { formAccounts } = this.state;
      return formAccounts
        .filter((acc) => {
          const { action, nameId, currencyId } = acc;
          const isPairSelected = !!nameId && !!currencyId;
          return !!action && isPairSelected;
        })
        .map((acc) => {
          const { action, ...data } = acc;
          return { action, data };
        });
    }

    // Проверка на ошибки в OptionSource
    hasOptionSourceError({ extra, errors }) {
      if (!extra || !extra.optionsSource) return false;
      const { type, value } = extra.optionsSource;
      const fieldMap = {
        manual: [],
        currencies: [],
        accountNames: [],
        workspaceMembers: [],
        api: [{ key: 'url', message: 'Please enter API URL' }],
        layer: [{ key: 'id', message: 'Please select a layer' }],
        actors: [{ key: 'ids', message: 'Please select actors' }],
        actorFilter: [{ key: 'id', message: 'Please select a filter' }],
        corezoidSyncApi: [
          { key: 'convId', message: 'Please enter Process ID' },
          { key: 'apiLogin', message: 'Please enter API Login' },
          { key: 'apiSecret', message: 'Please enter API Secret' },
        ],
      };
      const checkF = fieldMap[type];
      for (const f of checkF) {
        if ((!value || !value[f.key]) && !errors[f.key]) return f;
      }
    }

    // [PUBLIC API] Получить ошибки в полях формы
    getFormEditorErrors() {
      const { form } = this.state;
      const formErrors = [];
      const uniqueIds = new Set();
      form.sections.forEach((section, index) => {
        if (!section.title || !section.title.length) {
          formErrors.push({
            key: '',
            id: index + 1,
            title: `Section ${index + 1}`,
            message: 'Please enter section title',
          });
        }
        section.content.forEach(
          ({
            id,
            title,
            errors: itemErrors = {},
            value: itemValue,
            extra,
            class: className,
          }) => {
            if (!itemErrors.id && uniqueIds.has(id)) {
              formErrors.push({
                id,
                title,
                key: 'id',
                message: 'ID must be unique',
              });
            } else uniqueIds.add(id);
            const copyErrors = { ...itemErrors };
            const error = this.hasOptionSourceError({
              extra,
              errors: itemErrors,
            });
            if (error) {
              copyErrors[`options:${error.key}`] = error.message;
              this.handleOnChangeItem({
                id,
                value: itemValue,
                errors: copyErrors,
              });
            } else if (
              className === 'link' &&
              !itemValue.length &&
              !itemErrors
            ) {
              const errorMessage = 'Please enter valid URL';
              formErrors.push({
                id,
                title: title || 'Link',
                key: 'value',
                message: errorMessage,
              });
              this.handleOnChangeItem({
                id,
                value: itemValue,
                errors: { value: errorMessage },
              });
            } else if (
              className === 'calendar' &&
              (extra?.minDate > itemValue.endDate ||
                extra?.maxDate < itemValue.startDate)
            ) {
              const errorMessage = 'Please enter default value in valid range';
              formErrors.push({
                id,
                title,
                key: 'value',
                message: errorMessage,
              });
              this.handleOnChangeItem({
                id,
                value: itemValue,
                errors: {
                  value: errorMessage,
                },
              });
            }
            Object.keys(copyErrors).forEach((key) => {
              const theSameError = formErrors.find(
                (error) =>
                  error.key === key && error.message === copyErrors[key]
              );

              if (theSameError) {
                return;
              }

              formErrors.push({ id, title, key, message: copyErrors[key] });
            });
          }
        );
      });
      return formErrors;
    }

    // [PUBLIC API] Получить json формы
    getForm() {
      const {
        form,
        parentId,
        formRef,
        activeSection,
        activeItem,
        access,
        actorsAccess,
        isChanged,
      } = this.state;
      const newForm = setDefaultValue({ form, activeSection, activeItem });
      newForm.parentId = parentId;
      newForm.ref = formRef;
      const formAccounts = this._makeDefaultAccounts();
      // Удалить поля лишние формы
      this._clearExtraFormFields(newForm);
      return { form: newForm, access, actorsAccess, formAccounts, isChanged };
    }

    // [PUBLIC API] Установить флаг того что форма сохранена
    setFlagSaved() {
      this.setState({ isChanged: false });
    }

    // Изменить настройки поля формы
    handleOnChangeItem({ id, value, errors }) {
      const { form, activeSection, activeItem } = this.state;
      const section = form.sections[activeSection];
      let item = { ...section.content[activeItem] };
      item[id] = value;
      if (id === 'class') item = this._clearExtraItemFields(item);
      if (id === 'id') item.idNotChanged = false;
      if (errors) {
        item.errors = errors;
      } else {
        delete item.errors;
      }
      section.content.splice(activeItem, 1, item);
      this.setState({ form, isChanged: true });
    }

    // Изменить активный элемент
    handelOnActive({ sectionIndex, itemIndex }) {
      this.setState({ activeSection: sectionIndex, activeItem: itemIndex });
    }

    // Добавить новую секцию
    handleAddSection() {
      const { form } = this.state;
      const { sections } = form;
      const copySections = sections.slice();
      const secLen = copySections.length;
      const id = this.makeItemID('item');
      copySections.push({
        content: [{ id, idNotChanged: true, class: 'edit' }],
      });
      form.sections = copySections;
      this.setFieldDefValues();
      this.setState({
        form,
        isChanged: true,
        activeSection: secLen,
        activeItem: 0,
      });
    }

    // Удалить секцию
    handleRemoveSection(sectionIndex) {
      const { form, showPanel } = this.state;
      const { sections } = form;
      sections.splice(sectionIndex, 1);
      const activeSection = sectionIndex - 1 < 0 ? 0 : sectionIndex - 1;
      this.setState({ form, isChanged: true, activeSection, activeItem: 0 });
      if (sections.length && showPanel) this.handleTogglePanel();
    }

    // Сортировка секций
    handleSortSection(sectionIndex, newIndex, activeIndex) {
      const { form } = this.state;
      const { sections } = form;
      const copySections = sections.slice();
      const secLen = copySections.length;
      if (newIndex > secLen - 1 || newIndex < 0) return;
      copySections.splice(newIndex, 0, copySections.splice(sectionIndex, 1)[0]);
      form.sections = copySections;
      this.setState({
        form,
        isChanged: true,
        activeSection: activeIndex || newIndex,
        activeItem: 0,
      });
    }

    // Переименование секции
    handleRenameSection(sectionIndex, title) {
      const { form } = this.state;
      const { sections } = form;
      const copySections = sections.slice();
      const section = copySections[sectionIndex];
      section.title = title;
      form.sections = copySections;
      this.setState({
        form,
        isChanged: true,
        activeSection: sectionIndex,
        activeItem: 0,
      });
    }

    // Найти пограничные индексы строки
    findRowIndexRange({ items, rowId }) {
      const rowItems = items.filter((i) => i.row === rowId);
      const sortRowItems = Utils.sort(rowItems.slice(), 'itemIndex');
      const countRowItems = sortRowItems.length;
      const minIndex = sortRowItems[0].itemIndex;
      const maxIndex = sortRowItems[countRowItems - 1].itemIndex;
      return { minIndex, maxIndex, countRowItems: sortRowItems.length };
    }

    // Получить новый индекс перемещаемого элемента
    getNextPositionInfo({ section, itemIndex, position }) {
      const curItem = section.content[itemIndex];
      // индекс первого элемента перемещаемой строки строки
      let rowStartIndex = itemIndex;
      // граничная позиция item в строке в зависимости от направления перемещения
      let curPos = itemIndex;
      // количество перемещаемых элементов в строке
      let countMovedItems = 1;
      // Если перемещаемый item находится в строке, то пересчитываем показатели
      // rowStartIndex, curPos, countMovedItems
      if (curItem.row) {
        const { minIndex, maxIndex, countRowItems } = this.findRowIndexRange({
          items: section.content,
          rowId: curItem.row,
        });
        rowStartIndex = minIndex;
        countMovedItems = countRowItems;
        curPos = position === 'up' ? minIndex : maxIndex;
      }
      // Находим элемент следующий за строкой перемещаемого item
      const nextIndex = position === 'up' ? curPos - 1 : curPos + 1;
      const nextItem = section.content[nextIndex];
      // Количество элементов в следующей строке
      let lenNextRow = 1;
      // Индекс назначения, куда надо переместить элементы
      let targetIndex =
        position === 'up' ? rowStartIndex - 1 : rowStartIndex + lenNextRow;
      // Если следующая строка - row, то пересчитываем значение targetIndex
      if (nextItem && nextItem.row) {
        const { minIndex, countRowItems } = this.findRowIndexRange({
          items: section.content,
          rowId: nextItem.row,
        });
        lenNextRow = countRowItems;
        targetIndex = position === 'up' ? minIndex : rowStartIndex + lenNextRow;
      }
      return { rowStartIndex, targetIndex, countMovedItems };
    }

    // Добавить новый элемент в секцию
    handleAddItem({ sectionIndex, itemIndex, isRow }) {
      const { form } = this.state;
      const section = form.sections[sectionIndex];
      const sectionLen = section.content.length;
      const id = this.makeItemID('item');
      const newItem = {
        id,
        key: AppUtils.udid(),
        idNotChanged: true,
        class: 'edit',
      };
      const isLastItem = sectionLen - 1 === itemIndex;
      let newItemIndex;
      if (isRow) {
        const curItem = { ...section.content[itemIndex] };
        const row = curItem.row || this.makeItemID('row');
        curItem.row = row;
        newItem.row = row;
        section.content.splice(itemIndex, 1, curItem);
        newItemIndex = itemIndex + 1;
      } else if (!isLastItem) {
        const { targetIndex } = this.getNextPositionInfo({
          section,
          itemIndex,
          position: 'down',
        });
        newItemIndex = targetIndex;
      } else {
        newItemIndex = itemIndex + 1;
      }
      section.content.splice(newItemIndex, 0, newItem);
      this.setFieldDefValues();
      this.setState({ form, isChanged: true, activeItem: newItemIndex });
    }

    // Удалить элемент из секции
    handleRemoveItem({ sectionIndex, itemIndex }) {
      const { form } = this.state;
      const section = form.sections[sectionIndex];
      const item = section.content[itemIndex];
      if (form.sections.length === 1 && section.content.length === 1) return;
      section.content.splice(itemIndex, 1);
      // Если в строке остался только один элемент, то удаляем из него поле row
      if (item && item.row) {
        const rowItems = section.content.filter((i) => i.row === item.row);
        if (rowItems.length === 1) {
          delete rowItems[0].row;
        }
      }
      const countItems = section.content.length;
      if (countItems === 0) {
        this.handleRemoveSection(sectionIndex);
      } else {
        const activeItem = itemIndex - 1 < 0 ? 0 : itemIndex - 1;
        this.setState({ form, isChanged: true, activeItem });
      }
    }

    // Сортировка элементов в секции
    handleSortItem({ sectionIndex, itemIndex, position }) {
      const { form } = this.state;
      const section = form.sections[sectionIndex];
      const copyItems = section.content.slice();
      const curItem = copyItems[itemIndex];
      const { id } = curItem;
      const { rowStartIndex, targetIndex, countMovedItems } =
        this.getNextPositionInfo({
          section,
          itemIndex,
          position,
        });
      const movedItems = copyItems.splice(rowStartIndex, countMovedItems);
      copyItems.splice(targetIndex, 0, ...movedItems);
      section.content = copyItems;
      const activeItem = copyItems.findIndex((i) => i.id === id);
      this.setState({ form, isChanged: true, activeItem });
    }

    // Установить состояние доступов
    handleChangeAccess(access) {
      this.setState({ access });
    }

    // Установить состояние доступов к акторам темплейта
    handleChangeActorsAccess(access) {
      this.setState({ actorsAccess: access });
    }

    // Обработка изменений дефолтных счетов
    handleChangeAccounts(accounts) {
      this.setState({ formAccounts: accounts });
    }

    // Установка родительской формы
    handleChangeParentId(formId) {
      this.setState({ parentId: formId });
    }

    // Установка ref формы
    handleChangeRef(formRef) {
      this.setState({ formRef });
    }

    // Удалить поля лишние формы
    _clearExtraFormFields(form) {
      form.sections.forEach((section) => {
        section.content.forEach((item) => {
          delete item.itemIndex;
          delete item.sectionIndex;
          delete item.errors;
          delete item.error;
        });
      });
    }

    render() {
      return (
        <CreateForm
          {...this.props}
          {...this.state}
          handleTogglePanel={this.handleTogglePanel}
          handleOnChangeItem={this.handleOnChangeItem}
          handelOnActive={this.handelOnActive}
          handleSortSection={this.handleSortSection}
          handleRenameSection={this.handleRenameSection}
          handleAddSection={this.handleAddSection}
          handleRemoveSection={this.handleRemoveSection}
          handleAddItem={this.handleAddItem}
          handleRemoveItem={this.handleRemoveItem}
          handleSortItem={this.handleSortItem}
          handleFormField={this.handleFormField}
          handleChangeAccess={this.handleChangeAccess}
          handleChangeActorsAccess={this.handleChangeActorsAccess}
          handleChangeAccounts={this.handleChangeAccounts}
          handleChangeParentId={this.handleChangeParentId}
          handleChangeRef={this.handleChangeRef}
          getForm={this.getForm}
          setFlagSaved={this.setFlagSaved}
        />
      );
    }
  }

  FormActions.propTypes = {
    formData: PropTypes.object,
    access: PropTypes.array,
    actorsAccess: PropTypes.array,
    formAccounts: PropTypes.array,
    onContentChange: PropTypes.func,
  };

  return FormActions;
}
