import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Icon, Chip, Utils, TextField, Tooltip } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import cn from 'classnames';
import { intlShape, injectIntl } from 'react-intl';
import mes from './intl';
import './Title.scss';

class Title extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
    };
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleInputKeyUp = this.handleInputKeyUp.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.title !== state.value && !state.edit) {
      return { value: props.title };
    }
    return null;
  }

  // Ф-ция локализации
  i(mesId, values) {
    return AppUtils.getMes(this.context)(mesId, values);
  }

  handleOnClick(canEdit) {
    if (!canEdit) return;
    this.setState({ edit: true });
  }

  handleOnChange({ value }) {
    this.setState({ value });
  }

  handleOnCancel() {
    this.setState({ edit: false });
  }

  handleInputKeyUp(e) {
    if (['Enter'].includes(e.key)) {
      this.handleOnSubmit();
    }
    if (['Escape'].includes(e.key)) {
      this.handleOnCancel();
    }
  }

  handleOnSubmit() {
    const { onSubmit, nullable } = this.props;
    let { value } = this.state;
    value = value.trim();
    if (!nullable && !value.length) return;
    onSubmit(value);
    this.handleOnCancel();
  }

  render() {
    const {
      title,
      canEdit,
      singleLine,
      defTitle,
      nullable,
      type,
      readOnly,
      isNew,
    } = this.props;
    const { edit, value } = this.state;
    const titleLabel = title || defTitle;
    const valueWithLinks = AppUtils.hilightLinks(
      // Allow all tags but  sanitize dangerous attributes.
      Utils.sanitizeHtml(Utils.stripHtml(Utils.nToBr(titleLabel)), {
        allowedTags: false,
      })
    );
    return (
      <div styleName={cn('title', { edit, singleLine })} className="title">
        {edit ? (
          <div
            style={{ width: '100%' }}
            onClick={(e) => {
              // однострочный режим. не передаем наверх клик по инпуту.
              if (singleLine) e.stopPropagation();
            }}
          >
            <TextField
              styleName="title__editor"
              autoFocus={true}
              autoSelect="mount"
              bordered={true}
              error={!nullable ? value.length === 0 : false}
              onChange={this.handleOnChange}
              onBlur={this.handleOnSubmit}
              value={value}
              onKeyUp={this.handleInputKeyUp}
            />
          </div>
        ) : (
          <div
            key="preview"
            styleName={cn('title__preview', type, {
              owner: canEdit,
              singleLine,
            })}
            onClick={(e) => {
              e.stopPropagation();
              if (e.target.tagName !== 'A') this.handleOnClick(canEdit);
            }}
          >
            {readOnly ? (
              <Chip
                styleName="title__preview__readOnly"
                type="rectangular"
                size="small"
                label={this.i(mes.readOnly)}
                fontSize="small"
                fontWeight="normal"
                closeIcon={false}
              />
            ) : null}
            <Tooltip value={titleLabel}>
              <div
                styleName={cn('title__preview__content', { isNew })}
                dangerouslySetInnerHTML={{
                  __html: valueWithLinks,
                }}
              />
            </Tooltip>
            <Icon type="edit" size="small" />
          </div>
        )}
      </div>
    );
  }
}

Title.defaultProps = {
  title: '',
  canEdit: false,
  readOnly: false,
  singleLine: false,
  nullable: false,
  isNew: false,
  type: 'primary',
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
  readOnly: PropTypes.bool,
  type: PropTypes.string,
  singleLine: PropTypes.bool,
  defTitle: PropTypes.string,
  nullable: PropTypes.bool,
  isNew: PropTypes.bool,
};

Title.contextTypes = {
  intl: intlShape,
};

export default injectIntl(Title);
