import { SET_MODAL, DEL_MODAL, REPLACE_MODAL } from 'constants';
import { createReducer } from '@reduxjs/toolkit';

// ------------------------------------
// Reducer
// ------------------------------------
const initState = null;

export default createReducer(initState, {
  [SET_MODAL](state, action) {
    return !state ? [action.payload] : [...state, action.payload];
  },
  [DEL_MODAL](state, action) {
    if (action.payload) {
      return state.filter((i) => i.name !== action.payload);
    }
    const copyState = state ? state.slice() : [];
    copyState.pop();
    return copyState.length > 0 ? copyState : null;
  },
  [REPLACE_MODAL](state, action) {
    const copyState = state ? state.slice() : [];
    const index = copyState.findIndex((i) => i.name === action.payload.replace);
    copyState.splice(index, 1, action.payload);
    return copyState;
  },
});
