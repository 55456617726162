/**
 * Creates nodes based on the given tree structure.
 *
 * @param {Object} tree - The tree structure to create nodes from
 * @param {Object} options - Additional options for creating nodes
 * @param {Object} options.parent - The parent node information
 * @param {Object} options.acc - The accumulated nodes
 * @return {Object} The accumulated nodes with the newly created nodes
 */
const createNodesByStructure = (
  tree,
  { parent = {}, acc = {}, replaceAll = false } = {}
) => {
  return Object.entries(tree).reduce((acc, [id, value]) => {
    const { nodes, position, absolutePositioning, ...rest } = value || {};

    const nodePosition = absolutePositioning
      ? position
      : {
          x: (position?.x || 0) + (parent.position?.x || 0),
          y: (position?.y || 0) + (parent.position?.y || 0),
        };

    const newNode = {
      id,
      status: 'new',
      group: 'nodes',
      type: 'node',
      position: nodePosition,
      static: true,
      replaceAll,
      data: {
        id,
        position: nodePosition,
        group: 'nodes',
        type: 'node',
        static: true,
        ...rest,
      },
    };
    acc[id] = newNode;

    if (parent.id) {
      acc[`edge_${parent.id}_${id}`] = {
        id: `edge_${parent.id}_${id}`,
        status: 'new',
        type: 'edge',
        group: 'edges',
        edgeType: 'hierarchy',
        edgeId: `edge_${parent.id}_${id}`,
        source: parent.id,
        target: id,
        title: '',
        data: {
          id: `edge_${parent.id}_${id}`,
          type: 'edge',
          group: 'edges',
          edgeType: 'hierarchy',
          edgeId: `edge_${parent.id}_${id}`,
          source: parent.id,
          target: id,
          title: '',
          isNonInteractive: true,
        },
      };
    }

    if (nodes) {
      createNodesByStructure(nodes, { parent: newNode, acc });
    }
    return acc;
  }, acc);
};

export default createNodesByStructure;
