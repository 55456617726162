import React from 'react';
import { useSelector } from 'react-redux';
import { Utils } from 'mw-style-react';
import { Route, useLocation } from 'react-router-dom';

function WrapRouter({ component: Component, ...props }) {
  const accounts = useSelector((state) => state.accounts);
  const { search } = useLocation();
  const queryParams = Utils.getQueryParam(search);
  delete queryParams.tmpsid;

  return (
    <Route
      {...props}
      render={(p) => {
        return (
          <Component
            {...props}
            {...p}
            queryParams={queryParams}
            accId={accounts.active}
          />
        );
      }}
    />
  );
}

export default WrapRouter;
