import { defineMessages } from 'react-intl';

const m = defineMessages({
  accessDeniedDashboardTitle: {
    id: 'accessDeniedDashboardTitle__DashboardFallback',
    defaultMessage: 'Access Denied',
  },
  accessDeniedDashboardActorFilterText: {
    id: 'accessDeniedDashboardActorFilterText__DashboardFallback',
    defaultMessage:
      'You do not have access to the actorFilter on which this dashboard is built',
  },
  accessDeniedDashboardOrAccountsText: {
    id: 'accessDeniedDashboardOrAccountsText__DashboardFallback',
    defaultMessage: 'You do not have access to the dashboard or accounts.',
  },
  defaultError: {
    id: 'defaultError__DashboardFallback',
    defaultMessage: 'Something went wrong. Please try again.',
  },
});

export default m;
