import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  enterWebhookUrl1: {
    id: 'enterWebhookUrl1',
    defaultMessage: 'URL',
  },
  enterWebhookUrl2: {
    id: 'enterWebhookUrl2',
    defaultMessage:
      '(Actions from your workspace will be performed on this link)',
  },
  enterWebhookUrlPlaceholder: {
    id: 'enterWebhookUrlPlaceholder',
    defaultMessage: 'Example: https://yourwebsite.com',
  },
  actions1: {
    id: 'actions1',
    defaultMessage: 'Actions',
  },
  actions2: {
    id: 'actions2',
    defaultMessage: '(Choose 1 or more actions which you want to track)',
  },
  uploadImage: {
    id: 'uploadImage',
    defaultMessage: 'Upload image',
  },
});

export default { ...globalIntl, ...m };
