import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  Label,
  Icon,
  Tooltip,
  useObjectState,
  Popover,
  Button,
} from 'mw-style-react';
import useIntl from 'useIntl';
import useOutsideClick from 'useOutsideClick';
import SelectActors from '@control-front-end/common/components/SelectActors';
import { useSelector } from 'react-redux';
import FilterRange from '../../../../../FilterRange';
import FilterAccPair from '../../../../../FilterAccPair';
import './TriggerFilters.scss';
import mes from './intl';

/**
 * Filters for triggers' calls
 */
function TriggerFilters({ value, handleChange }) {
  const t = useIntl();
  const filterPairBox = useRef();
  const triggersFormId = useSelector(
    (state) => state.systemForms.accounttriggers.id
  );
  const [state, setState] = useObjectState({
    showTriggersPopup: false,
    showPairPopup: false,
  });
  const account = value.accounts ? value.accounts[0] : null;
  const isCustomizedFilter =
    value.trigger || value.actors?.length || value.accounts?.length;

  const hidePairPopup = useCallback(() => setState.showPairPopup(false), []);
  const showPairPopup = useCallback(() => setState.showPairPopup(true), []);

  const hideTriggersPopup = useCallback(
    () => setState.showTriggersPopup(false),
    []
  );
  const showTriggersPopup = useCallback(
    () => setState.showTriggersPopup(true),
    []
  );

  useOutsideClick({ ref: filterPairBox, callback: hidePairPopup });

  const handleResetFilters = () => {
    const { from, to } = value;
    const defaultFilterSet = { from, to };
    handleChange(defaultFilterSet);
  };

  const handleSelectFilter = ({ id, value: fValue }) => {
    const newFilter = structuredClone(value);
    newFilter[id] = fValue;
    handleChange(newFilter);
    hidePairPopup();
  };

  const actorsLabel = value.actors?.length
    ? value.actors.map((i) => i.title).join(', ')
    : t(mes.all);

  return (
    <Stack.H styleName="tf" alignItems="center" size={Stack.SIZE.xsmall}>
      <FilterRange
        from={value.from}
        to={value.to}
        fastNavigation
        onChange={(range) => handleChange({ ...value, ...range })}
      />
      <div styleName="tf__wrap">
        <Stack.H
          styleName="tf__type"
          alignItems="center"
          size={Stack.SIZE.xxsmall}
          onClick={showTriggersPopup}
        >
          <Label fontWeight="semibold" value={t(mes.fActorTrigger)} />
          <Label
            styleName="tf__type__value"
            fontWeight="semibold"
            value={value.trigger ? value.trigger.title : t(mes.all)}
          />
          <Icon type="dropdown" size="micro" />
        </Stack.H>
        {state.showTriggersPopup ? (
          <div styleName="tf__popup">
            <SelectActors
              id="trigger"
              wrap={true}
              bordered={true}
              multiselect={false}
              autoFocus={true}
              hideCreateBtn={true}
              value={value.trigger}
              formId={triggersFormId}
              onChange={handleSelectFilter}
              onBlur={hideTriggersPopup}
            />
          </div>
        ) : null}
      </div>
      <div styleName="tf__wrap">
        <Stack.H
          styleName="tf__type"
          alignItems="center"
          size={Stack.SIZE.xxsmall}
          onClick={showPairPopup}
        >
          <Label fontWeight="semibold" value={`${t(mes.account)}:`} />
          <Label
            styleName="tf__type__value"
            fontWeight="semibold"
            value={
              account
                ? `${account.accountName}, ${account.currencyName}`
                : t(mes.all)
            }
          />
          <Icon type="dropdown" size="micro" />
        </Stack.H>
        {state.showPairPopup ? (
          <div ref={filterPairBox} styleName="tf__popup">
            <FilterAccPair
              handleClose={hidePairPopup}
              handleSelect={({ value }) => {
                handleSelectFilter({ id: 'accounts', value: [value] });
              }}
            />
          </div>
        ) : null}
      </div>
      <Popover
        content={
          <SelectActors
            id="actors"
            wrap={true}
            bordered={true}
            multiselect={true}
            autoFocus={true}
            hideCreateBtn={true}
            value={value.actors || []}
            exclude={value.actors}
            excludeFormIds={[triggersFormId]}
            onChange={handleSelectFilter}
          />
        }
        topLevel
        anchors={{
          binding: Popover.ANCHOR.left_top,
          content: Popover.ANCHOR.left_top,
        }}
      >
        <Stack.H
          styleName="tf__type"
          alignItems="center"
          size={Stack.SIZE.xxsmall}
        >
          <Label fontWeight="semibold" value={t(mes.fActors)} />
          <Tooltip value={actorsLabel.length > 20 ? actorsLabel : ''}>
            <Label
              styleName="tf__type__value"
              fontWeight="semibold"
              value={actorsLabel}
            />
          </Tooltip>
          <Icon type="dropdown" size="micro" />
        </Stack.H>
      </Popover>
      <Button
        type="secondary"
        size="smallplus"
        fontWeight="semibold"
        label="Reset filters"
        iconAfter="trash"
        onClick={handleResetFilters}
        visibility={isCustomizedFilter ? 'visible' : 'hidden'}
      />
    </Stack.H>
  );
}

TriggerFilters.propTypes = {
  value: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
};

export default TriggerFilters;
