import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';
import { Radio as RadioMW, RadioItem } from 'mw-style-react';
import './Radio.scss';

/**
 * Компонент Radio
 */
class Radio extends PureComponent {
  renderItems(options) {
    return options.map((i) => (
      <RadioItem key={i.value} value={i.value} label={i.title} />
    ));
  }

  render() {
    const {
      id,
      visibility,
      title,
      value,
      options,
      align,
      error,
      getErrorMsg,
      onChange,
    } = this.props;
    return (
      <div styleName={cn('radio')}>
        <RadioMW
          id={id}
          visibility={visibility}
          label={title}
          value={value}
          align={align}
          error={error}
          helperText={getErrorMsg(this.props)}
          onChange={onChange}
        >
          {this.renderItems(options)}
        </RadioMW>
      </div>
    );
  }
}

Radio.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.string,
  align: PropTypes.string,
  visibility: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  error: PropTypes.bool,
  getErrorMsg: PropTypes.func,
  onChange: PropTypes.func,
};

export default Radio;
