import AppUtils from './utils';

/**
 * Функция для парсинга локализаций
 * @param intl
 * @param mesId
 * @param values
 * @param bbcode
 * @returns {*}
 */
export default function intlParse({ intl, mesId, values = {}, bbcode = true }) {
  const { messages } = intl;
  // eslint-disable-next-line prefer-regex-literals
  const re = new RegExp(/(\{[a-zA-Z1-9_]*\})/, 'gi'); // NOSONAR
  try {
    let text = messages[mesId.id] || mesId.defaultMessage;
    if (values && Object.keys(values).length) {
      text = text.replace(re, (match, p1) => {
        const key = p1.replace('{', '').replace('}', '');
        return bbcode ? AppUtils.bbCodeToHtml(values[key]) : values[key];
      });
    }
    return text;
  } catch (e) {
    return mesId;
  }
}
