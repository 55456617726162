import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  shortName: {
    id: 'shortName',
    defaultMessage: 'Shortname',
  },
  sharedWith: {
    id: 'sharedWith',
    defaultMessage: 'Shared with',
  },
  corCredentials: {
    id: 'corCredentials',
    defaultMessage: 'Corezoid credentials',
  },
  apiLogin: {
    id: 'apiLogin',
    defaultMessage: 'API login',
  },
  apiSecret: {
    id: 'apiSecret',
    defaultMessage: 'API secret',
  },
  companyId: {
    id: 'companyId',
    defaultMessage: 'Company ID',
  },
  alias: {
    id: 'alias',
    defaultMessage: 'Alias',
  },
  devProcId: {
    id: 'devProcId',
    defaultMessage: 'Dev process ID',
  },
  prodProcId: {
    id: 'prodProcId',
    defaultMessage: 'Prod process ID',
  },
  shortNameHint: {
    id: 'shortNameHint',
    defaultMessage:
      // eslint-disable-next-line max-len
      'A unique name for this script. It must only contain letters, numbers, dashes, underscores and must be at least 5 characters long.',
  },
});

export default { ...globalIntl, ...m };
