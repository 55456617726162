import cn from 'classnames';

import { NODE_WIDGET } from '@control-front-end/app/src/components/GraphEngine/ExpandedActors/NodeWidget/constants';
import getTranslation from '@control-front-end/utils/getTranslation';

import extendActorGraph from '../utils/extendActorGraph';
import scss from './styles.scss';
import m from './valencyIntl';

const addNewParentNode = {
  id: 'add_new_parent',
  title: getTranslation(m.addNewParent),
  iconClassName: scss.plusIcon,
  className: cn(scss.menuNode, scss.dashedBorder),
  widget: {
    name: NODE_WIDGET.valencySelector,
    opened: false,
    type: 'parent',
  },
  isNonInteractive: true,
};

const addNewChildNode = {
  id: 'add_new_child',
  title: getTranslation(m.addNewChild),
  iconClassName: scss.plusIcon,
  className: cn(scss.menuNode, scss.dashedBorder),
  widget: {
    name: NODE_WIDGET.valencySelector,
    opened: false,
    type: 'child',
  },
  isNonInteractive: true,
};

const getValencySkeleton = ({ nodeId, els, canEdit, settings }) => {
  const copyEls = structuredClone(els);
  copyEls.forEach((i) => {
    i.replaceAll = settings.replaceAll;
    i.data.isNonInteractive = true;
  });
  if (!canEdit) return copyEls;

  return extendActorGraph({
    nodeId,
    els: copyEls,
    children: [addNewChildNode],
    parents: [addNewParentNode],
    settings,
  });
};

export default getValencySkeleton;
