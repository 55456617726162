import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import AppUtils from '@control-front-end/utils/utils';
import {
  Icon,
  Label,
  Button,
  Upload,
  UploadItem,
  ProgressBar,
} from 'mw-style-react';
import useIntl from 'useIntl';
import useOutsideClick from 'useOutsideClick';
import { UPLOAD_FILE } from 'constants';
import mes from './intl';
import './ImageUpload.scss';

/**
 * Загрузка изображения для актора
 */
function ImageUpload({ handleUpload, handleClose }) {
  const ref = useRef();
  const t = useIntl();
  useOutsideClick({ ref, callback: handleClose });
  const dispatch = useDispatch();
  const config = useSelector((state) => state.config);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSaveImage = () => {
    setLoading(true);
    dispatch({
      type: UPLOAD_FILE.REQUEST,
      payload: {
        files,
        callback: (attachments) => {
          const img = attachments[0];
          AppUtils.getImgSize(img.fileName, ({ width, height }) => {
            handleUpload({
              pictureObject: { img: img.fileName, width, height },
              title: img.title,
              callback: () => setLoading(false),
            });
            handleClose();
          });
        },
      },
    });
  };

  return (
    <div styleName="li">
      <div styleName="li__upload" ref={ref}>
        {files.length ? (
          <div styleName="li__upload__preview">
            <UploadItem
              styleName="li__upload__preview__item"
              {...files[0]}
              onRemove={() => setFiles([])}
            />
            {loading ? (
              <ProgressBar
                styleName="li__upload__loader"
                type="circle"
                size="large"
              />
            ) : null}
            <Button
              styleName="li__upload__btn"
              label={t(mes.proceed)}
              fontWeight="normal"
              onClick={handleSaveImage}
              visibility={loading ? 'disabled' : 'visible'}
            />
          </div>
        ) : null}
        <div styleName="li__upload__browse">
          <Icon styleName="li__upload__icon" size="medium" type="picture" />
          <Label
            styleName="li__upload__info"
            fontWeight="normal"
            fontSize="xsmall"
            value={t(mes.imageSize, {
              fileSize: config.maxFileSize / (1024 * 1024),
            })}
          />
          <Upload
            styleName="li__upload__box"
            multiple={false}
            accept="image/*"
            value={files}
            onChange={({ value }) => setFiles(value)}
            maxFileSize={config.maxFileSize}
            label={t(mes.dragImage)}
            browseLinkLabel={t(mes.btnBrowseLabel)}
          />
        </div>
      </div>
    </div>
  );
}

ImageUpload.propTypes = {
  handleUpload: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ImageUpload;
