import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Label,
  Icon,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  DateUtils,
  Menu,
  MenuItem,
} from 'mw-style-react';
import { DATE_FORMAT } from 'constants';
import SelectActors from '@control-front-end/common/components/SelectActors';
import UserAvatar from '@control-front-end/common/components/UserAvatar';
import useIntl from 'useIntl';
import useOutsideClick from 'useOutsideClick';
import ActorAvatar from '@control-front-end/common/components/ActorAvatar';
import '../../Additions.scss';
import mes from './intl';

function CardActorInfo({ cardActor, onChange }) {
  const t = useIntl();
  const actorPopupRef = useRef();
  const [menu, toggleMenu] = useState(false);
  const [openActorPopup, toggleActorPopup] = useState(false);
  const eventsFormId = useSelector((state) => state.systemForms.events.id);

  useOutsideClick({
    ref: actorPopupRef,
    callback: () => toggleActorPopup(false),
  });

  const handleMenuClick = ({ value }) => {
    switch (value) {
      case 'replace':
        toggleActorPopup(true);
        break;
      case 'remove':
        onChange({ id: 'cardActor', value: null });
        break;
      default:
        break;
    }
    toggleMenu(false);
  };

  const renderMenu = () => {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Menu
          styleName="table__menu"
          size="small"
          width={200}
          onClick={handleMenuClick}
          onClose={() => toggleMenu(false)}
        >
          <MenuItem size="small" label={t(mes.replace)} value="replace" />
          <MenuItem size="small" label={t(mes.remove)} value="remove" />
        </Menu>
      </div>
    );
  };

  return (
    <Table paddingSize="small" styleName="table">
      <TableHead styleName="table__head">
        <TableRow styleName="table__row" rowSize="small">
          <TableCell width="32" />
          <TableCell width="auto" />
          <TableCell width="180" />
          <TableCell width="50" />
          <TableCell width="20" />
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow styleName="table__row" rowSize="small">
          <TableCell>
            <ActorAvatar
              formType={cardActor.formType}
              formTitle={cardActor.formTitle}
              colorFilled={true}
              pictureUrl={cardActor.pictureUrl}
              colors={cardActor.allColors}
              status={cardActor.status}
            />
          </TableCell>
          <TableCell styleName="table__cell title">
            <Label value={cardActor.title} />
          </TableCell>
          <TableCell>
            <Label value={DateUtils.toDate(cardActor.createdAt, DATE_FORMAT)} />
          </TableCell>
          <TableCell>
            {cardActor.ownerAvatar ? (
              <UserAvatar
                size="small"
                src={cardActor.ownerAvatar}
                label={cardActor.ownerName}
              />
            ) : null}
          </TableCell>
          <TableCell styleName="table__menu__wrap">
            <Icon
              type="more"
              styleName="table__row__icon"
              onClick={(e) => {
                e.stopPropagation();
                toggleMenu(true);
              }}
            />
            {menu ? renderMenu() : null}
            {openActorPopup ? (
              <div ref={actorPopupRef} styleName="table__menu">
                <SelectActors
                  wrap={true}
                  bordered={true}
                  autoFocus={true}
                  resultLabel={t(mes.actors)}
                  placeholder={t(mes.searchActorPlaceholder)}
                  value={[]}
                  excludeFormIds={[eventsFormId]}
                  onChange={({ value }) => {
                    const [actor] = value;
                    onChange({ id: 'cardActor', value: actor });
                    toggleActorPopup(false);
                  }}
                />
              </div>
            ) : null}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

CardActorInfo.propTypes = {
  cardActor: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default CardActorInfo;
