import { Utils } from 'mw-style-react';

// FORMS
export const GET_FORMS = Utils.createRequestTypes('GET_FORMS');
export const GET_FORM = Utils.createRequestTypes('GET_FORM');
export const SEARCH_FORMS = Utils.createRequestTypes('SEARCH_FORMS');
export const REMOVE_FORM = Utils.createRequestTypes('REMOVE_FORM');
export const CREATE_FORM = Utils.createRequestTypes('CREATE_FORM');
export const UPDATE_FORM = Utils.createRequestTypes('UPDATE_FORM');
export const FORMS_ACCESS = Utils.createRequestTypes('FORMS_ACCESS');
export const BULK_REMOVE_FORMS = Utils.createRequestTypes('BULK_REMOVE_FORMS');
export const GET_FORM_ITEM_OPTIONS = Utils.createRequestTypes(
  'GET_FORM_ITEM_OPTIONS'
);
export const GET_FORM_ITEMS_TITLES = Utils.createRequestTypes(
  'GET_FORM_ITEMS_TITLES'
);
export const CLEAR_FORMS = 'CLEAR_FORMS';
export const CLEAR_FORM = 'CLEAR_FORM';
export const SET_FORMS_REQ_STATUS = 'SET_FORMS_REQ_STATUS';
export const SET_FORM_REQ_STATUS = 'SET_FORM_REQ_STATUS';
export const SET_SYSTEM_FORMS = 'SET_SYSTEM_FORMS';

export const DEFAULT_FORM_TITLE = 'unnamedForm';

// FORMS ACCOUNTS
export const GET_FORM_ACCOUNTS = Utils.createRequestTypes('GET_FORM_ACCOUNTS');
export const MANAGE_FORM_ACCOUNTS = Utils.createRequestTypes(
  'MANAGE_FORM_ACCOUNTS'
);

// FORMS TREES
export const GET_FORMS_TREE = Utils.createRequestTypes('GET_FORMS_TREE');

export const FORM_TYPE = {
  system: 'system',
};

export const KEY_FORM_PREFIX = '__form__';
export const FIELD_PATH_PREFIX = 'data.';
export const PARENT_SYSTEM_FORMS = ['Graphs', 'Layers', 'Events', 'State'];

export const FIELD_TYPE = {
  check: 'check',
  radio: 'radio',
  select: 'select',
  multiSelect: 'multiSelect',
  edit: 'edit',
  label: 'label',
  link: 'link',
  button: 'button',
  image: 'image',
  upload: 'upload',
  calendar: 'calendar',
};

export const TRANSFERABLE_FIELD_TYPE = {
  // Temporary disabled, for correct work rework should be made on the backend side
  // [FIELD_TYPE.radio]: FIELD_TYPE.radio,
  [FIELD_TYPE.select]: FIELD_TYPE.select,
  [FIELD_TYPE.multiSelect]: FIELD_TYPE.multiSelect,
};

export const FIELD_ACCOUNT_GROUP = {
  system: 'system',
  formFields: 'formFields',
};
