import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  formsTab: {
    id: 'formsTab',
    defaultMessage: 'Fields and sections',
  },
  actorsMap: {
    id: 'actorsMap',
    defaultMessage: 'Actors Map',
  },
  actorsCard: {
    id: 'actorsCard',
    defaultMessage: 'Actors Card',
  },
});

export default { ...globalIntl, ...m };
