export const FILTERS = {
  files: [
    {
      key: 'my',
      visible: {
        orderBy: 'created_at',
        people: 'all',
      },
    },
    {
      key: 'starred',
      visible: {
        orderBy: 'created_at',
        people: 'all',
      },
      hidden: {
        starred: true,
      },
    },
  ],
  tags: [
    {
      key: 'my',
      visible: {
        orderBy: 'created_at',
        people: 'all',
      },
    },
  ],
  graph: [
    {
      key: 'my',
      visible: {
        vertexTypes: 'all',
        period: 'all',
        limit: '5',
      },
    },
    {
      key: 'hr',
      visible: {
        period: 'all',
      },
      hidden: {
        vertexTypes: 'user',
      },
    },
    {
      key: 'domains',
      visible: {
        vertexTypes: 'tag',
        period: 'all',
      },
      hidden: {
        tagName: 'domain',
        crossLinks: true,
        except: '^((?!\\.).)*$',
      },
    },
  ],
  forms: [
    {
      key: 'my',
      visible: [],
      hidden: [],
    },
  ],
  formAnswers: [
    {
      key: 'my',
      visible: {
        people: 'all',
      },
    },
  ],
};

export const SYSTEM_SEARCH_FILTER = {
  id: 'search',
  title: 'Search results',
  isSystem: true,
  disabled: true,
  data: {},
};
