import {
  ADD_LAYERS_AREA,
  CLEAR_NEW_LAYER_AREAS,
} from '@control-front-end/common/constants/graphLayers';
import AppUtils from '@control-front-end/utils/utils';
import { createReducer } from '@reduxjs/toolkit';

// ------------------------------------
// Reducer
// ------------------------------------

const initState = {
  lastUpdate: null,
  list: [],
};

export default createReducer(initState, {
  [ADD_LAYERS_AREA.SUCCESS](state, action) {
    return {
      ...state,
      ...action.payload,
      lastUpdate: AppUtils.udid(),
    };
  },
  [CLEAR_NEW_LAYER_AREAS.SUCCESS](state, action) {
    return {
      ...state,
      ...action.payload,
      lastUpdate: AppUtils.udid(),
    };
  },
});
