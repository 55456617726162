import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  apiKeys: {
    id: 'apiKeys',
    defaultMessage: 'API Keys',
  },
  permissions: {
    id: 'permissions',
    defaultMessage: 'Permissions',
  },
  userCustomPrivs: {
    id: 'userCustomPrivs',
    defaultMessage: 'User has also permission to: {privs}',
  },
  signedStat: {
    id: 'signedStat',
    defaultMessage: 'Signed / All',
  },
  doneStat: {
    id: 'doneStat',
    defaultMessage: 'Done / All',
  },
  noGroupAccess: {
    id: 'noGroupAccess',
    defaultMessage: "You don't have permission to view this group.",
  },
});

export default { ...globalIntl, ...m };
