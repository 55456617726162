import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { PortalWrapper } from 'mw-style-react';
import { CopyIdChip } from 'components';

function ActorModalIdChip({ actorId, formId }) {
  const [chipPortalEl, setChipPortalEl] = useState(null);
  const actorFiltersFormId = useSelector(
    (state) => state.systemForms.actorfilters.id
  );
  const modalId =
    formId === actorFiltersFormId
      ? 'createActorsFilterModal'
      : 'createActorModal';

  useEffect(() => {
    if (!actorId) return;
    // получаем элемент заголовка модального окна
    // после завершения анимации отрисовки модального окна
    setTimeout(() => {
      const modalEl = document.getElementById(modalId);
      if (!modalEl) return;
      const modalLabel = modalEl.firstChild.firstChild;
      setChipPortalEl(modalLabel);
    }, 100);
  }, [actorId]);

  if (!chipPortalEl) return null;
  return (
    <PortalWrapper root={chipPortalEl} position="relative">
      <CopyIdChip type="actor" id={actorId} />
    </PortalWrapper>
  );
}

ActorModalIdChip.propTypes = {
  actorId: PropTypes.string,
  formId: PropTypes.number,
};

export default ActorModalIdChip;
