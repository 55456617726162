import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Stack, cr } from 'mw-style-react';
import cn from 'classnames';
import scss from './CollapsableList.scss';

function CollapsableList({
  size,
  value = [],
  btnClassName,
  listClassName,
  previewAmount,
  onClick,
  ...rest
}) {
  const [expand, setExpand] = useState(false);

  const values = Array.isArray(value) ? value : [value];

  useEffect(() => setExpand(false), [value]);

  if (!values.length) return null;

  return (
    <Stack.V fullWidth alignItems="flexStart" size={size} {...rest}>
      <Stack.V
        fullWidth
        size={size}
        className={cn(scss.collapsibleList, listClassName)}
      >
        {expand ? values : values.slice(0, previewAmount)}
      </Stack.V>
      {cr([
        values.length > previewAmount,
        <Button
          className={btnClassName}
          size="xsmall"
          type="text"
          label={expand ? 'Show less' : 'Show more'}
          iconAfter={expand ? 'arrow' : 'arrow_reverse'}
          onClick={(...args) => {
            setExpand((expand) => !expand);
            onClick?.(...args);
          }}
        />,
      ])}
    </Stack.V>
  );
}

CollapsableList.defaultProps = {
  size: Stack.SIZE.medium,
  previewAmount: 2,
};

CollapsableList.propTypes = {
  size: PropTypes.oneOf(Object.values(Stack.SIZE)),
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
  listClassName: PropTypes.string,
  btnClassName: PropTypes.string,
  onClick: PropTypes.func,
  previewAmount: PropTypes.number,
};

export default CollapsableList;
